import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import AddStrainModal from "../../containers/growhouse/AddStrainModal";
import AddGroupModal from "../../containers/growhouse/AddGroupModal";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const selectSource = [
	{ label: "Seed", value: "0", key: 0 },
	{ label: "Clone", value: "1", key: 1},
	{ label: "Tissue Culture", value: "2", key: 2 }
];

class AddPlantModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getStrainsList = this.getStrainsList.bind(this);
		this.getGroupsList = this.getGroupsList.bind(this);
		this.calculateHarvestDate = this.calculateHarvestDate.bind(this);
		
		this.state = {
			modalOpenAdd: false,
			modalOpenGroup: false,
			selectStrainType:null,
			selectGroupType:null,
			selectVendorType:null,
			selectSourceIdValue:null,
			selectRoomType:null,
			selectTableType:null,
			single_barcode_value:"1",
			multiple_barcode_value:"2",
			single_barcode:true,
			multiple_barcode:false,
			selectSourceType : 	{ label: "Seed", value: "0", key: 0 },
			startDate: null,
			startHarvest: null,
			room_id: null,
			table_id: null,
			selectRoom:[],
			selectTable:[],
			selectVendor:[],
			selectSourceId:[],
			harvest_date:""
		};
	
	}
	
	handleChangeDate = date => {
		this.setState({
			startDate: date
		},()=>{
			this.calculateHarvestDate();
		});
	};
	
	calculateHarvestDate = () => {		
		var harvest_date='';
		const startDate = this.state.startDate;
		const selectStrainType = this.state.selectStrainType;
		if(startDate!=null && selectStrainType!=null){
			if(selectStrainType.harvest_duration!='0' && selectStrainType.harvest_duration!=''){
				harvest_date = moment(startDate).add(parseInt(selectStrainType.harvest_duration),'w').format("MM/DD/YYYY HH:mm");
			}
		}		
		this.setState({
			harvest_date
		});
	};
	
	showRadio = e =>{	
		if(e.currentTarget.value==='1'){				
			this.setState({ single_barcode:true,multiple_barcode:false })
		}else if(e.currentTarget.value==='2'){				
			this.setState({ single_barcode:false,multiple_barcode:true })	
		}
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalGroup = () => {
		this.setState({
			modalOpenGroup: !this.state.modalOpenGroup
		});
	};

	componentDidMount() {		
		var available=[];
		var strains=[];
		var groups=[];
		var rooms=[];
		var tables=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-data-for-add-plant',formData).then((res)=>{
			var data = res.data;
		
			available=data.available;			
			strains=data.strains;			
			groups=data.groups;			
			rooms=data.rooms;			
			tables=data.tables;			
			
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
		    groups = groups.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			rooms = rooms.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			tables = tables.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			var selectVendor = data.vendors.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			var selectSourceId = data.mother_plants.map((element,index)=>{
				element.label=element.barcode;
				element.value=element.barcode;
				element.key=index;
				return element;
			});
			
			const room_id = this.props.room_id;
			const table_id = this.props.table_id;
			
			this.getRoomData(room_id);
				
			this.setState({			
				selectStrain:strains,
				selectGroup:groups,
				selectRoom:rooms,
				selectTable:tables,
				selectVendor,
				selectSourceId,
				room_id,
				table_id,
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	getStrainsList() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-strains-list',formData).then((res)=>{
			var data = res.data;
			var selectStrainType = this.state.selectStrainType;
			
			var strains=data.strains;
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			if(strains.length){
				selectStrainType=strains[0];
			}
				
			this.setState({			
				selectStrain:strains,
				selectStrainType
			},()=>{
				this.calculateHarvestDate();
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	getGroupsList() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-group-list',formData).then((res)=>{
			var data = res.data;
			var selectGroupType = this.state.selectGroupType;
			
			var groups=data.groups;
			groups = groups.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			if(groups.length){
				selectGroupType=groups[0];
			}
				
			this.setState({			
				selectGroup:groups,
				selectGroupType
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}	
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("add-plant").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);            
			
			axios.post(REST_API_END_POINT+'growhouse/add-plant',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("add-plant").disabled=false;
					this.props.toggleModal();
					
					const room_id = this.props.room_id;
					const table_id = this.props.table_id;
				
					this.getRoomData(room_id);
					this.setState({
						selectStrainType:null,
						selectGroupType:null,
						selectVendorType:null,
						selectRoomType:null,
						selectTableType:null,
						single_barcode:true,
						multiple_barcode:false,
						selectSourceType : 	{ label: "Seed", value: "0", key: 0 },
						startDate: null,
						harvest_date:"",
						room_id,
						table_id,
					});
					
					this.props.dataListRender();
				}else{
					document.getElementById("add-plant").disabled=false;
					swal("Warning",data.msg,"warning");
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-plant").disabled=false;
			});

		}
	}	
	
	getRoomData(room_id){
		var tables=[];
		var facility_id = localStorage.getItem('facility_id');		
		facility_id =  dataCrypto.decrypt(facility_id);			
		var formData = new FormData();
		formData.append('room_id',room_id);
		
		axios.post(REST_API_END_POINT+'growhouse/get-active-tables-by-room',formData).then((res)=>{
			var data = res.data;
						
			if(data.tables){
				tables=data.tables;
				tables = tables.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				this.setState({
					selectTable:tables,
				});
			}else{
				this.setState({
					selectTable:tables,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);			
		});	
	}
	
	
  render() {
       const {selectStrain,selectGroup,selectRoom,selectTable, selectVendor, selectSourceId} = this.state;  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add Plant
			</ModalHeader>
			<AvForm
				id="plantForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
			<ModalBody>
				<Row>
					<Colxx xxs="2">
						<Label>Barcode Type</Label>
					</Colxx>
					<Colxx xxs="10">
						<Label>&nbsp;&nbsp;
							<CustomInput
								id="single_barcode"
								type="radio"
								name="barcode_type"
								value={this.state.single_barcode_value}
								checked={this.state.single_barcode}
								className="d-inline-block"	
								onChange={(e) => this.showRadio(e)}	
							/>
							{" "}Single Barcode
						</Label>&nbsp;&nbsp;						
						<Label>
						  <CustomInput
								id="multiple_barcode"
								type="radio"
								name="barcode_type"
								className="d-inline-block"
								value={this.state.multiple_barcode_value}
								checked={this.state.multiple_barcode}						
								className="d-inline-block"
								onChange={(e) => this.showRadio(e)}									
						  />
							{" "}Multiple Barcode
						</Label>
					</Colxx>
				</Row>	
				<Row className="mt-2">
					<Colxx lg="12">
						<span className="h8">Source</span>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="source"
							id="source"
							value={this.state.selectSourceType}						
							options={selectSource}
							onChange={selectSourceType => { this.setState({ selectSourceType }); }}
						/>
					</Colxx>					
				</Row>
				<Row className="mt-2">
					<Colxx lg="6">
						<Label >Strain</Label>
						 <Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="strain_id"
							id="strain_id"
							placeholder="--Select Strain--"
							value={this.state.selectStrainType}						
							options={selectStrain}
							onChange={selectStrainType => { this.setState({ selectStrainType },()=>{ this.calculateHarvestDate(); }) }}					
						/>
					</Colxx>
					{this.props.user_privileges.indexOf(17)!==-1 ? <Colxx lg="6">
						<Button
							color="primary"
							size="sm"
							className=" mt-4"
							onClick={this.toggleModalAdd}>
							Add Strain
						</Button>					
					</Colxx>:""}					
				</Row>
				<Row>																	
					<Colxx lg="12"  className="mt-2">											
						<AvGroup className="error-t-negative">
						  <Label>Qty<span className="text-danger">*</span></Label>
						  <AvField
							id="quantity"
							name="quantity"
							type="number"
							validate={{
								required: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}	
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row className="">
					<Colxx lg="6">
						<Label>Group</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="group_id"
							id="group_id"
							placeholder="--Select Group--"
							value={this.state.selectGroupType}						
							options={selectGroup}
							onChange={selectGroupType => { this.setState({ selectGroupType }) }}					
						/>
					</Colxx>
					{this.props.user_privileges.indexOf(24)!==-1 ? <Colxx lg="6">
						<Button
							color="primary"
							size="sm"
							className=" mt-4"
							onClick={this.toggleModalGroup}>
							Add Group
						</Button>					
					</Colxx>:""}					
				</Row>
					<Row className="mt-4">
						<Colxx lg="6">
							<Label >Birth date</Label>
							<DatePicker
								className="p-0"
								selected={this.state.startDate}
								onChange={this.handleChangeDate}
								showTimeSelect
								placeholderText="Date"
								dateFormat="MM/DD/YYYY HH:mm"
								id="birth_date"
								name="birth_date"
								autoComplete = "off"
								maxDate={moment()}
							/>
						</Colxx>
						<Colxx lg="6">
							<Label >Expected Harvest Date</Label>
							<AvField
								id="expected_harvest_date"
								name="expected_harvest_date"
								type="text"
								readOnly={true}
								value={this.state.harvest_date}
							/>
						</Colxx>					
					</Row>
					<Row className="mt-4">
					<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>Rooms<span className="text-danger">*</span></Label>
								<AvField
									type="select"
									name="room_id"
									id="room_id"
									placeholder="--Select Warehouse--"
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
									value={this.state.room_id}
									onChange={(e)=>{
										this.setState({
											room_id:e.target.value
										},()=>{
											this.getRoomData(this.state.room_id)
										});
									}}
								>
								<option value="" >--Select Room--</option>
								{selectRoom.map(opt=>{
									return <option key={opt.key} value={opt.value} >{opt.label}</option>
								})}
								</AvField>						  
							</AvGroup>
						</Colxx>
						<Colxx lg="6" className="mb-2">						
							<AvGroup className="error-t-negative">
								<Label>Table<span className="text-danger">*</span></Label>
								<AvField
									type="select"
									name="table_id"
									id="table_id"
									placeholder="--Select Table--"
									value={this.state.table_id}
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
								>
									<option value="" >--Select Table Type--</option>
									{selectTable.map(opt=>{
										return <option key={opt.key} value={opt.value} >{opt.label}</option>
									})}		
								</AvField>
							</AvGroup>								
						</Colxx>
					</Row>								
					<Row className="mt-3">
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Position<span className="text-danger">*</span></Label>
							  <AvField
								id="position"
								name="position"
								type="number"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									min: {
										value: 1,
										errorMessage: "Position must be greater than or equal to 1"
									}
								}}
							  />
							</AvGroup>
						</Colxx>	
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>Vendor</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="vendor_id"
									id="vendor_id"
									placeholder="--Select Vendor--"
									value={this.state.selectVendorType}						
									options={selectVendor}
									onChange={selectVendorType => { this.setState({ selectVendorType }) }}
								/>
							</AvGroup>
							
						</Colxx>
						<Colxx lg="12">
							<div id="sourceview" style={this.state.selectSourceType!=null ? (this.state.selectSourceType.value!='1' ? {display:'none'}:{}):{display:'none'}}>						
								<AvGroup className="error-t-negative">
									<Label>Source ID</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="source_id"
										id="source_id"
										placeholder="--Select Source--"
										value={this.state.selectSourceIdValue}						
										options={selectSourceId}
										onChange={selectSourceIdValue => { this.setState({ selectSourceIdValue }) }}
									/>
								</AvGroup>
							</div>
						</Colxx>
						
					</Row>																
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-plant">
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
		   
			<AddStrainModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.getStrainsList}
			/>
			
			<AddGroupModal
				modalOpen={this.state.modalOpenGroup}
				toggleModal={this.toggleModalGroup}
				dataListRender={this.getGroupsList}
			/>
	  </Modal>
	  );
  }
};

export default AddPlantModal;
