import React, { Component, Fragment } from "react";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import Barcode from 'react-barcode';

import {LBS_CONSTANT} from "../../constants/defaultValues";

export default class PrintPackageUIDContent extends Component{
	constructor(props) {
		super(props);
	}

	render() {
		const { package_details } = this.props;
		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 2.2in 1.5in !important;
						  margin:0;
						}
					}
				`}} />
				{package_details!=null ? <div className="print_pos_label" style={{padding:'15px 3px 2px 3px'}} >                  
					<div style={{width:'3.1in', height:'1.9in', fontFamily: 'sans-serif'}} className="panel-body pl-2">
						<p className="mt-0 mb-2 font-weight-bold text-uppercase" style={{fontSize:'20px'}}>{package_details.product_name}</p>
						<div className="d-flex">
							<div className="mt-0 mb-2" style={{fontSize:'17px'}}>THC {package_details.thc}% CBD {package_details.cbd}% Cannabinoids {package_details.cannabinoids}% </div>
							<div className="mt-0 mb-2 font-weight-bold" style={{fontSize:'17px', width:'90px'}}>{package_details.qty_type=="1" ? <Fragment>{package_details.quantity}<br/>Grams</Fragment>:<Fragment>{Math.round(parseFloat(package_details.quantity)/LBS_CONSTANT)}<br/>Lbs</Fragment>}</div>
						</div>
						<Barcode value={package_details.batch_uid} height={50} width={1.65} margin={0} marginTop={5} displayValue={false} />
						<p className="mt-1" style={{fontSize:'20px'}}>{(package_details.batch_uid.match(/.{1,4}/g)).join(' ')}</p>
					</div>
				</div>:""}
			</div>
		);
  }
};
