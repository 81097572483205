import React, { Component } from "react";
import { AvForm, AvField, AvGroup,AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddResult extends Component{

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);	
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			document.getElementById('import_file_error').classList.remove("d-block"); 
			document.getElementById('import_file_error').classList.add("d-none");
			
			document.getElementById("send-to-lab").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("addResultForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			formData.append('bucket_id',this.props.bucket_data.bucket_id);		
			
			axios.post(REST_API_END_POINT+'distillate-bucket/add-test-result',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("send-to-lab").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("send-to-lab").disabled=false;
			});

		}else if (errors.indexOf('files[]') !== -1) {				
			document.getElementById('import_file_error').classList.add("d-block"); 
			document.getElementById('import_file_error').classList.remove("d-none");
		}
	}
	
	onMultiFileChange = e => {
		switch (e.target.name){
			case 'files[]':
			if(e.target.files[0]){
				var filenames_obj=e.target.files;
				var filenames=[];
				for (var i = 0; i < filenames_obj.length; i++) {
					filenames.push(filenames_obj[i].name);
				}
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=filenames.join(',');
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		}
	};


	render() {	
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add Test Results
				</ModalHeader>
				<AvForm
					id="addResultForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>			
						<Row >
							<Colxx lg="12" className="mb-2">						
								<AvGroup className="error-t-negative">
									<Row className="align-items-center">
										<Colxx md="2">
											<Label>Documents
												<div className="invalid-feedback d-none " id="import_file_error">Please choose the file</div>
											</Label>
										</Colxx>
										<Colxx md="10">	
											<InputGroup className="mb-3">
												<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
												<AvInput tag={CustomInput}
													type="file"
													name="files[]"
													id="test_document"
													accept="image/*,application/pdf"
													multiple 
													validate={{
														required: {
															value: true,
															errorMessage: "Please choose the file to import"
														}
													}}
													onChange={this.onMultiFileChange}
												/>
											</InputGroup>
										</Colxx>
									</Row>
								</AvGroup>
							</Colxx>
						</Row>
						
						<Row>
							<Colxx lg="12" className="mt-0 mb-2">
								<div className="d-flex align-items-center">
									<Label className="mr-3">Test</Label>
									<AvRadioGroup
										inline
										className="d-inline-block mb-0"
										name="test_result"
										validate={{
										  required: {
											value: true,
											errorMessage: "Please select at least one test"
										  }
										}}
										defaultValue="2"
									>
										
										<AvRadio customInput label="Passed" value="2" className="mb-1"/>
										<AvRadio customInput label="Failed" value="3" className="mb-1"/>
									</AvRadioGroup >
								</div>
							</Colxx>					
						</Row>
						
						<Row className="mt-3">
							<Colxx lg="12">
								<AvGroup className="mb-0">
									<Row className="align-items-center">
										<Colxx md="2">
											<Label>THC %</Label>
										</Colxx>
										<Colxx md="10">
											<AvField
												id="thc_percentage"
												name="thc_percentage"
												type="number"
												validate={{
													min: {
														value: "0",
														errorMessage: "Value should be greater than or equal to 0"
													}
												}}
											/>
										</Colxx>
									</Row>
								</AvGroup>
							</Colxx>									
						</Row>					
					</ModalBody>
					<ModalFooter  className="justify-content-start">
						<Button color="primary" id="send-to-lab">
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default AddResult;
