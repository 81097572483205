const requestdata = [
{
    id: 1,
    name: "Assignment One", 
	request_type: "RFP",   
	latest_updated: "3 Months ago",   	   
	type: {
      typeid: 1,
      typeColor: "success",
    },   	   
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Assignment One",
	request_type: "RFP",   
	latest_updated: "3 Months ago",
	type: {
      typeid: 2,
      typeColor: "danger",
    },
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  },
  {
    id: 3,
    name: "Assignment One",
	request_type: "RFP",   
	latest_updated: "3 Months ago",
	type: {
      typeid: 3,
      typeColor: "secondary",
    },
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  },
  {
    id: 4,
    name: "Assignment One",
	request_type: "RFP",   
	latest_updated: "3 Months ago",
	type: {
      typeid: 4,
      typeColor: "warning",
    },
	status: {
      statusid: 1,
      statusColor: "success",
    }
  }
]

export default requestdata
