import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class SubFolders extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			subfolders:[]
		};
	}

	componentDidMount() {
		var user_id = localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
        formData.append('facility_id',this.props.match.params.facility_id);
             
		axios.post(REST_API_END_POINT+'task/get-all-sub-folders',formData).then((res)=>{
		 	var data = res.data;
		    var subfolders=data.sub_folders;
				
		 	this.setState({ 
			 	subfolders,
			});			
		})
	}
  
	render() {	   	
		var subfolders=this.state.subfolders;
		
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.sub-folders" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>	
				
				<Row className=" ">
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
				</Row>
			
				<Row className="mb-2">
					{subfolders.map((row,index)=>{
						return (<Colxx md="3" key={index} className="mb-3 ">					
								<Card className="p-4 job-folders">
									<NavLink to={"/app/jobs/all-jobs/"+this.props.match.params.facility_id+"/"+row.id} className="d-block"> 
										<p className="w-100 d-flex mb-0"><i className="fas fa-folder mr-2 d-inline-block" /> <span className="d-inline-block">{row.name}</span></p>			
									</NavLink>
								</Card>
							</Colxx>)
						}
					)}
				</Row>		
			</Fragment>
		);
	}
}
