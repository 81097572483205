import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput, Label, InputGroup, InputGroupAddon } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import axios from 'axios';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, BASE_URL} from "../../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Settings extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			configurations: null,
			inclusive_taxes:false,
			blocking:true
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.updateConfiguration = this.updateConfiguration.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{ 
			let inclusive_taxes =  res.data.configurations.inclusive_taxes==1;
			this.setState({ configurations: res.data.configurations, blocking:false, inclusive_taxes });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	updateConfiguration = (event, errors, values)  => {
		if (errors.length === 0) {
			document.getElementById("update-configurations").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("updateSettingsForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('inclusive_taxes',this.state.inclusive_taxes ? 1:0);
			
			axios.post(REST_API_END_POINT+'point-of-sale/update-settings',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					swal("Success",data.msg,"success");
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				document.getElementById("update-configurations").disabled=false;
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-configurations").disabled=false;
			});
		}
	}
	
		
	render() {
		
		const configurations = this.state.configurations	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.settings" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row>	
										<Colxx xxs="12">
											<div id="api_message"></div>
										</Colxx> 
										<Colxx xxs="12">
											<AvForm												
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.updateConfiguration}
												id="updateSettingsForm"
											  >
												
												<Row>
													<Colxx lg="12" className="mb-2">
														<Label>													  
															<Switch
																className="custom-switch custom-switch-primary im-inline-block"
																checked={this.state.inclusive_taxes}
																onChange={status => { this.setState({ inclusive_taxes:!this.state.inclusive_taxes }) }}
															/>
															<span className="h6 ml-4">Inclusive Taxes</span>
														</Label>
													</Colxx>
												</Row>
												<Row className="mt-4">
													<Colxx lg="12">
														<Button color="primary" id="update-configurations" >Update</Button>
													</Colxx>
												</Row>
										   </AvForm>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
