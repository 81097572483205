import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Table,Card
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
  
class AddWarehouseModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
 
		this.state = {
			rows:[],
			selectShelfTypes:[],
			
		};
	}
	
	componentDidMount() {		
		var shelf=[];
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'warehouse/get-all-shelf-types',formData).then((res)=>{
			var data = res.data;
		
			shelf=data.shelf_types;			
			
			shelf.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectShelfTypes:shelf,				
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	
    handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-warehouses").disabled=true;
			
			if(this.state.rows.length==0){
				swal("Warning","Please add atleast one row","warning");
				document.getElementById("add-warehouses").disabled=false;
				return false;
			}
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("warehouseForm"));
			formData.append(' shelf_type_id',this.props.shelf_type_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'warehouse/add-warehouse',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-warehouses").disabled=false;
				this.props.toggleModal();
				this.setState({
					rows:[],			
				});	
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-warehouses").disabled=false;
			});

		}
	}

	
	addItem(){		
		var rows = this.state.rows;
		var row={};
		row.id=rows.length;
		row.capacity='';
		row.type_of_shelf=null;
		rows.push(row);
		this.setState({
			rows
		});
	}
	
	removeItem(row){
		var rows = this.state.rows;
		rows = rows.filter(data=>{ return row.id!==data.id });
		this.setState({ 
			rows
		});
	}

  render() {
		var { selectShelfTypes } = this.state;
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Warehouse
		  </ModalHeader>
		  <AvForm
		        id="warehouseForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z0-9 ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>				
	
				<hr/>
				<Row>							
					<Colxx lg="12">
						<h2>Capacity</h2>
					</Colxx>
				</Row>
				<Row>							
					<Colxx lg="12">
						<Button
							color="primary"
							size="sm"
							className=""
							onClick={(e) => this.addItem()}
							>
							Add Row
						</Button>
					</Colxx>
				</Row>
				
				{this.state.rows.map((row,index)=>{
					return (
						<Card key={index} className="pl-4 pr-4 pb-4 mt-3" >
						<h4 className="mt-4 font-weight-bold">Row {index+1}</h4>
						<Row >
							<Colxx lg="4">
								<AvGroup className="error-t-negative">
									<Label>No. Of Shelves<span className="text-danger">*</span></Label>
									<AvField
										id={"no_of_tables_"+row.id}
										name={"capacity["+row.id+"]"}
										type="number"
										autoComplete="off"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  },
										  min: {
											value: 0,
											errorMessage: "Value should be greater than 0."
										  }
										}}
									/>
								</AvGroup>							
							</Colxx>
							<Colxx lg="7">
								<AvGroup className="error-t-negative">
									<Label>Type of Shelf<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name={"type_of_shelf["+row.id+"]"}
										id={"type_of_shelf_"+row.id}
										placeholder="--Select Shelf Type--"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
									>
									<option value="" >--Select Shelf Type--</option>
									{selectShelfTypes.map(opt=>{
										return <option key={opt.key} value={opt.value} >{opt.label}</option>
									})}
									</AvField>
								</AvGroup>	
							</Colxx>
							<Colxx lg="1">
							{(this.state.rows.length-1)==row.id ? <div className="remove-section float-right" onClick={e=>this.removeItem(row)} ><i className="simple-icon-close"></i></div>:""}
							</Colxx>
							
						</Row></Card>
					)
				})}
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary"  id="add-warehouses" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddWarehouseModal;
