const myjobs = [
{
  id:"2",
  name:"John",
  address:"Sample Address",
  phone:"9496351674",
  email:"hari@skyniche.com",
  registereddate:"2019-04-12 07:16:16",
},
{
  id:"1",
  name:"Mike",
  address:"California",
  phone:"9496351674",
  email:"sreejish@skyniche.com",
  registereddate:"2019-04-12 07:13:37",
},

]

export default myjobs

