import React, { Fragment, Component, Children } from "react";
import {
    Row, Container, Button
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";


class HomePageSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div>
                <Container>
                    <Row className={`${this.props.class} text-center py-5`} >
                        <Colxx xxs="12">
                            {
                                this.props.children ?
                                    this.props.children
                                    :
                                    <h1>
                                        CHECK OUT OUR NEW ONLINE STORE!
                                    </h1>
                            }
                        </Colxx>
                        <Colxx xxs="12">
                            <Button>Try A KAVA on us!</Button>
                        </Colxx>
                    </Row>
                </Container>
            </div>

        );
    }
};

export default HomePageSection;
