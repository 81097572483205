import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input, CustomInput, Label } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import swal from 'sweetalert';

export default class ReverseTaxCalculator extends Component {
	
	constructor(props) {
		super(props);
		this.state={
			tax:"",
			price:""
		}
	}	
	
	render(){
		var unit_price = 0;
		const { tax,price } = this.state;
		if(tax!="" && price!=""){
			unit_price = (100*parseFloat(price))/(100+parseFloat(tax));
		}
		return (
			<Fragment>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.reverse-tax-calculator" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row className="mb-3">	
										<Colxx md="4">
											<Label>Sales Tax %</Label>
											<Input 
												type="number"
												min="0"
												value={this.state.tax}
												onChange={(e)=>{
													this.setState({ tax:e.target.value });
												}}
											/>
										</Colxx>
									</Row>
									<Row className="mb-3">	
										<Colxx md="4">
											<Label>Final Price</Label>
											<Input 
												type="number"
												min="0"
												value={this.state.price}
												onChange={(e)=>{
													this.setState({ price:e.target.value });
												}}
											/>
										</Colxx>
									</Row>
									<Row>
										<Colxx md="2" className="mb-3">
											<Label>Unit Price</Label>
											<Input 
												type="number"
												min="0"
												disabled={true}
												value={unit_price.toFixed(2)}												
											/>
										</Colxx>
										<Colxx md="2" className="mb-3">
											<Label>Tax Amount</Label>
											<Input 
												type="number"
												min="0"
												disabled={true}
												value={(price-unit_price).toFixed(2)}												
											/>
										</Colxx>
									</Row>
									
								</CardBody>
							</Card>
						</Colxx>
					</Row>
			</Fragment>
		);
	}
}
