import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
  
class EditShelfTypesModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			shelf_types:null,		
		};	
	}

	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let shelf_types; 					
            if (prevProps.shelf_type_id!== this.props.shelf_type_id && this.props.shelf_type_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('shelf_type_id',this.props.shelf_type_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'warehouse/get-shelf-type-by-id',formData).then((res)=>{					
					var data = res.data;
					shelf_types=data.shelf_type;
				
					this.setState({ 
						shelf_types					
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {			
			document.getElementById("update-shelftypes").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("shelftypeForm"));
			formData.append(' shelf_type_id',this.props.shelf_type_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'warehouse/edit-shelf-type',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-shelftypes").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-shelftypes").disabled=false;
			});

		}
	} 

 
	render() {
 
		if(this.state.shelf_types!=null){
			var shelf_types=this.state.shelf_types;
		}else{
			var shelf_types;
		}
  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Shelf Type
		  </ModalHeader>
		  <AvForm
		        id="shelftypeForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							value={ shelf_types?((shelf_types.name!=null)?shelf_types.name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<hr/>
				<Row>
					<Colxx lg="12">
						<h2>Capacity</h2>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Rows<span className="text-danger">*</span></Label>
						  <AvField
							id="capacity_rows"
							name="capacity[0]"
							type="number"
							autoComplete="off"
							value={ shelf_types?((shelf_types.capacity_detail!=null)?(shelf_types.capacity_detail.split(","))[0]:''):'' }
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								},						  											
								min:{
									value: 0,
									errorMessage: "Value should be greater than 0."
								}
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Columns<span className="text-danger">*</span></Label>
							<AvField
							id="capacity_rows"
							name="capacity[1]"
							type="number"
							autoComplete="off"
							value={ shelf_types?((shelf_types.capacity_detail!=null)?(shelf_types.capacity_detail.split(","))[1]:''):'' }
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								},						  											
								min:{
									value: 0,
									errorMessage: "Value should be greater than 0."
								}
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
			
						
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-shelftypes" >
			  <IntlMessages id="customer.update"  />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditShelfTypesModal;
