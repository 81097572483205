import React, { Component } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";

import {Card, CardBody} from "reactstrap";
import {
	Collapse,
	Input,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import RemoveSection from "../../components/template/RemoveSection";

import {	
  CustomInput,  
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TooltipItemText from "../../components/common/TooltipItemText";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const selectData = [
    { label: "-Select Inspections-", value: "select", key: 0 },
    { label: "New Inspections", value: "new", key: 1 }
  ];
 const selectData1 = [
    { label: "-Select Agreements-", value: "selectagreement", key: 0 },
    { label: "New Agreements", value: "newagreement", key: 1 }
  ];
  const selectData2 = [
    { label: "-Select Agreements-", value: "selectagreement", key: 0 },
    { label: "New Agreements", value: "newagreement", key: 1 }
  ];
class AddAgent extends Component{
  constructor(props) {
    super(props);
	
	this.toggleFirstTab = this.toggleFirstTab.bind(this);
    this.toggleSecondTab = this.toggleSecondTab.bind(this);
	
    this.state = {
		 activeFirstTab: "1",
		activeSecondTab: "1",
		startTime: null,
		startDate: null,
		collapse: false,
		accordion: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],

	 
    };
  } 
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }
  
  toggleAccordion = tab => {
		const prevState = this.state.accordion;
		const state = prevState.map((x, index) => (tab === index ? !x : false));
		this.setState({
		  accordion: state
		});
    };
	 
  
  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  backdrop="static"
		  size="md"
		>
		
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Agent
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}>
		  <ModalBody className="with-switch">
			
				<Row >																							
					<Colxx lg="12" className="mb-2 mt-2" >							
						<div lg="12" >
							<Row>
								<Colxx lg="12">																																																									
									<Row>																		
										<Colxx lg="6"  className="mb-2">
											<Input
												type="text"
												name="text"
												id="exampleText"
												placeholder="First Name"/>
										</Colxx>
										<Colxx lg="6"  className="mb-2">						
											<Input
												type="text"
												name="text"
												id="exampleText"
												placeholder="Last Name"/>
										</Colxx>		
									</Row>
									<Row>																		
										<Colxx lg="6"  className="mb-2">
											<Input
												type="text"
												name="text"
												id="exampleText"
												placeholder="Email"/>
										</Colxx>
										<Colxx lg="6"  className="mb-2">						
											<Input
												type="text"
												name="text"
												id="exampleText"
												placeholder="CC Email"/>
										</Colxx>		
									</Row>
									<Row>																		
										<Colxx lg="6"  className="mb-2">
											<Input
												type="text"
												name="text"
												id="exampleText"
												placeholder="Phone"/>
										</Colxx>
										<Colxx lg="6"  className="mb-2">
											<Input
												type="text"
												name="text"
												id="exampleText"
												placeholder="Agency Name"/>
										</Colxx>
									</Row>
									<Row>
										<Colxx lg="12" className="mb-2">
											<AvForm
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
												>
												<AvGroup className="error-l-125 error-t-negative">
													<Label>Profile Image</Label>
													<InputGroup className="mb-3">
													  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
													  <CustomInput
														type="file"
														name="profile_pic"
														id="profile_pic"
														accept="image/*"
														onChange={this.onChange}
													  />
													</InputGroup>
												</AvGroup>
											</AvForm>
										</Colxx>
									</Row>
								</Colxx>
							</Row>																																																											
						</div>																																																																																																				 								 											
					</Colxx>
				</Row>																		                     
		  </ModalBody>
		  </AvForm>
		  <ModalFooter className="text-center d-block">
			<NavLink to="#" className="btn btn-primary btn-lg btn-shadow" >Add</NavLink>			
		  </ModalFooter>
	  </Modal>
	  );
  }
};

export default AddAgent;
