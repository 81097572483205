import React, { Component, Fragment } from "react";
import { Row, Card, Button, ButtonGroup, CardTitle, CardBody, FormGroup, Input, CustomInput, Badge } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import AddJobModal from "../../../containers/myjobs/AddJobModal";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AllJobs extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender=this.dataListRender.bind(this);
		this.changeStatus=this.changeStatus.bind(this);
		
		this.state = {
			jobs:[],
			modalOpenAdd:false,
			search:'',
			status:0,
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	componentDidMount(){
		this.dataListRender();
	} 
	
	dataListRender() {
		var user_id = localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
	    formData.append('module_id',this.props.match.params.module_id);
		formData.append('facility_id',this.props.match.params.facility_id);
		formData.append('status',this.state.status);
           
		axios.post(REST_API_END_POINT+'task/get-all-tasks',formData).then((res)=>{
		 	var data = res.data;
		    var jobs=[];
			if(data.status==1){
				jobs=data.jobs;
			}
				
		 	this.setState({ 
			 	jobs,
			});			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	changeStatus = (status) => {
		this.setState({
			status
		},()=>{
			this.dataListRender();
		});
	};
  
	render() {	  
	  	var jobs=this.state.jobs;
		var module_id = this.props.match.params.module_id;
		var facility_id = this.props.match.params.facility_id;
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.all-jobs" match={this.props.match} />
						<Button																		 
							  color="primary"
							  size="md"
							  className="float-right"
							  style={{minWidth:'130px'}}
							  onClick={this.toggleModalAdd}
							>
							Add	Job						  
						</Button>
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<Row className=" ">
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
				</Row>
				<Card className="rounded-corners" style={{minHeight:'450px'}}>
					<Row>
						<Colxx xxs="12">				
							<ButtonGroup className="ml-3 mt-3 sm-responsive-btn">
								<Button color="light" className=" mb-2" onClick={()=>{ this.changeStatus(0); }} >
								  All Jobs
								</Button>{" "}
								<Button color="primary" className=" mb-2" onClick={()=>{ this.changeStatus(1); }} >
								  New
								</Button>{" "}
								<Button color="info" className=" mb-2" onClick={()=>{ this.changeStatus(2); }} >
								  In Progress
								</Button>{" "}
								<Button color="success" className=" mb-2" onClick={()=>{ this.changeStatus(3); }} >
								 Completed
								</Button>{" "}
							   <Button color="warning" className=" mb-2"  onClick={()=>{ this.changeStatus(4); }} >
								  On Hold
								</Button>{" "}
								<Button color="danger" className=" mb-2" onClick={()=>{ this.changeStatus(5); }} >
								  Cancelled
								</Button>{" "}
							</ButtonGroup>
						</Colxx>
					</Row>
					
					<Row className="mb-4 pl-4 pr-4">
						{jobs.map((row,index)=>{
							return (<Colxx lg="4" xxl="3" className="mt-4" key={index} > 
										<Card>
											<Row>
												<Colxx lg="12">
													<div className={"p-4 text-center "+row.status_class} >
														<div className="card-title">
															<h1 className="mb-0" style={{color: "white"}} >{row ? ((row.name!=null)?row.name:''):'' }</h1>
															<p className="mb-0" style={{color: "white"}} >{row.complete}% Complete</p>
														</div>
													</div>
												</Colxx>
											</Row>
											<Row className="mt-4">
												<Colxx lg="4" className="text-center">
													<h5>{row.no_of_tasks}</h5>
													<p>Task in total</p>			
												</Colxx>
												<Colxx lg="4" className="text-center"  style={{borderLeft:"1px solid #d2d1d1", borderRight:"1px solid #d2d1d1" }}>
												   <h5>{row.no_of_completed_tasks}</h5>
												   <p>Completed Tasks</p>	
												</Colxx>
												<Colxx lg="4" className="text-center">
												  <h5 >{row.no_of_pending_tasks}</h5>
												  <p>Pending Tasks</p>				
												</Colxx>
											</Row>
											<div className="mb-3 mt-3 text-center">
												<NavLink to={"/app/jobs/view-job/"+row.ID} className={"mb-2 btn "+row.status_class} >View</NavLink>
											</div>
										</Card>
									</Colxx>)
							}
						)}
					</Row>
					
					<AddJobModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender={this.dataListRender}
						module_id= {module_id}
						facility_id={facility_id}
					/>
				</Card>
			
			</Fragment>
		);
	}
}
