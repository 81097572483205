import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';


import myjobs from "./myjobs";
import allfolders from "./allfolders";
import yourassignedjobs from "./yourassignedjobs";
import subfolders from "./sub-folders";
import alljobs from "./alljobs";
import viewjob from "./viewjob";
import taskactivities from "./taskactivities";




const Myjobs = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}/all-folders`} />
			<Route path={`${match.url}/all-folders`} component={allfolders} />
			<Route path={`${match.url}/your-assigned-jobs`} component={yourassignedjobs} />
			<Route path={`${match.url}/sub-folders/:facility_id`} component={subfolders} />
			<Route path={`${match.url}/all-jobs/:facility_id/:module_id`} component={alljobs} />
			<Route path={`${match.url}/view-job/:task_id`} component={viewjob} />
			<Route path={`${match.url}/task-activities/:task_id`} component={taskactivities} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Myjobs;