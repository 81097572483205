import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import moment from 'moment';
import swal from 'sweetalert';
  
export default class DeliveryModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state={
			delivery_date:null
		}
	}
	
	handleChangeDate = date =>{
		this.setState({ delivery_date:date })
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			if(this.state.delivery_date!=null){
				document.getElementById("submit-delivery").disabled=true;
				this.props.submitDelivery(this.state.delivery_date);
			}else{
				swal("Warning!","Please select the delivery date","warning");
			}
		}
	}


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Choose Delivery Date
		  </ModalHeader>
		  <AvForm
		        className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<Label>Choose Date<span className="text-danger">*</span></Label>
							<DatePicker
								id="delivery_date"
								name="delivery_date"
								autoComplete="off"
								className="mb-5"
								minDate={moment()}
								selected={this.state.delivery_date}
								placeholderText=""
								showTimeSelect
								dateFormat="YYYY-MM-DD HH:mm"
								onChange={ this.handleChangeDate }
							/>
					</Colxx>					
				</Row>
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="submit-delivery">
			  <IntlMessages id="customer.submit" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
