import React, { Component, Fragment  } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card,Input,
  InputGroupAddon,CardBody,
	CardHeader, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import AddStrainModal from "../../../containers/inventory/AddStrainModal";
import AddGroupModal from "../../../containers/growhouse/AddGroupModal";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import data from "../../../data/harvest";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import Notes from "../../../components/editPlant/Notes";
import Reminder from "../../../components/editPlant/Reminder";
import Additives from "../../../components/editPlant/Additives";
import Documents from "../../../components/editPlant/Documents";
import Ingridients from "../../../components/editPlant/Ingridients";
import Nutrients from "../../../components/editPlant/Nutrients";

import PrintBarcode from "../../../components/editPlant/PrintBarcode";
import PrintTag from "../../../components/editPlant/PrintTag";

import WastePlantModal from "../../../containers/growhouse/WastePlantModal";
import WastePhasePlantModal from "../../../containers/growhouse/WastePhasePlantModal";
import HarvestPlantModal from "../../../containers/growhouse/HarvestPlantModal";
import EditCurePlantModal from "../../../containers/growhouse/EditCurePlantModal";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from 'moment';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

  
   
class EditPlantModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.toggleFirstTab = this.toggleFirstTab.bind(this);
	this.toggleSecondTab = this.toggleSecondTab.bind(this);
	this.handleChangeDate = this.handleChangeDate.bind(this);
	this.calculateHarvestDate = this.calculateHarvestDate.bind(this);
	this.showButton = this.showButton.bind(this);
	this.dataListRender = this.dataListRender.bind(this);
	this.getStrainsList = this.getStrainsList.bind(this);
	this.getGroupsList = this.getGroupsList.bind(this);
	
	
	this.state = {
		activeFirstTab: "1",
		activeSecondTab: "1",
		modalOpenAdd: false,
		modalOpenGroup: false,
		selectVendorType:[],
		selectAdditivesType:null,
		files:"",
		strain:"",
		startDate: null,
		startHarvest: "",
		selectStrain:[],
		selectPhase:[],
		selectVendor:[],
		plant_id:0,
		harvest_date:"",
		selectStrainType:null,
		user_privileges:[]
	
	};
	
  }
    onChangeHandler = e => {
    this.setState({ files: e.target.files });
    };
	handleCheckClick = () => {
	  this.setState({ checked: !this.state.checked });
	}
	
  
    toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalGroup = () => {
		this.setState({
		  modalOpenGroup: !this.state.modalOpenGroup
		});
	};
	toggleModalAddTask = () => {
		this.setState({
			modalOpenAddTask: !this.state.modalOpenAddTask
			});
	};

    toggleModalHarvest = (e,plant_id=0) => {
		this.setState({	
		    plant_id:parseInt(plant_id),
			modalOpenHarvest: !this.state.modalOpenHarvest
			});
	};
	toggleModalCure = (e,plant_id=0) => {
		this.setState({	
			plant_id:parseInt(plant_id),
			modalOpenCure: !this.state.modalOpenCure
		});
	};
		
	toggleModalAddPhase = () => {
			this.setState({
				modalOpenAddPhase: !this.state.modalOpenAddPhase
			});
	};	
	
    toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
		this.setState({
        activeFirstTab: tab
      });
     }
    }
	toggleSecondTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
			activeSecondTab: tab
		  });
		}
	}

	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
	
    dataListRender() {

        let plant;
        let phasetype;
        var phase=[];		
		var available=[];
		var table_types=[];
		var available=[];
		var strains=[];
		var groups=[];
		var rooms=[];
		var tables=[];
		var phases=[];
		var vendors=[];
		var strain_name=[];
		var phase_name=[];
		var group_name=[];
		

		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('plant_id',this.props.match.params.plant_id );
		
		axios.post(REST_API_END_POINT+'growhouse/get-data-for-add-plant',formData).then((res)=>{			
			
			var data = res.data;
		
			available=data.available;			
			strains=data.strains;			
			groups=data.groups;			
			rooms=data.rooms;			
			tables=data.tables;			
			phases=data.phases;			
			vendors=data.vendors;			
			
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
		    groups = groups.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			rooms = rooms.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			tables = tables.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			phases = phases.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});	
			
			vendors = vendors.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});	
			
			this.setState({
				selectStrain:strains,
				selectGroup:groups,
				selectRoom:rooms,
				selectPhase:phases,				
				selectTable:tables,				
				selectVendor:vendors,				
			});
			axios.post(REST_API_END_POINT+'growhouse/get-plant-detail',formData).then((res)=>{
				var data = res.data;
				plant = data.plant;
				
				phasetype = plant.phase;
				
				plant.print = {
					barcode:plant.barcode,
					birth_date:plant.birth_date,
					position:plant.position,
					strain:'',
					phase:'',
					group:'',
					room:'',
					vendor:'',
				};
				
				var strain = strains.find(row=>{ return row.value==plant.strain })
				if(strain!=null){
					plant.print.strain = strain.name;
					strain_name = strain.name;
				}
				
				var phase = phases.find(row=>{ return row.value==plant.phase })
				if(phase!=null){
					
					plant.print.phase = phase.name;
					phase_name = phase.name;
					
				}
				
				var group = groups.find(row=>{ return row.value==plant.group_type })
				if(group!=null){
					plant.print.group = group.name;
					group_name = group.name;
				}
				
				var room = rooms.find(row=>{ return row.value==plant.room })
				if(room!=null){
					plant.print.room = room.name;					
				}
				
				var vendor = vendors.find(row=>{ return row.value==plant.vendor })
				if(vendor!=null){
					plant.print.vendor = vendor.name;
				}
				
			    var startDate = plant.birth_date!=null ? moment(plant.birth_date):null;
			    var startHarvest = plant.expected_harvest_date_str;
				
				this.setState({
					plant,
					strain:strains.find(row=>{ return row.value==plant.strain }),
					phase:phases.find(row=>{ return row.value==plant.phase }),
					group_type:groups.find(row=>{ return row.value==plant.group_type }),
					room:rooms.find(row=>{ return row.value==plant.room }),
					table:tables.find(row=>{ return row.value==plant.table_id }),
					vendor:vendors.find(row=>{ return row.value==plant.vendor }),
					startDate,
					harvest_date:startHarvest,
					phasetype,
					strain_name,
					phase_name,
					group_name
					
				},()=>{
					this.getRoomData(plant.room);
				});
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
			
				
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});		
	
	}
  
    handleChangeDate = date => {
		this.setState({
			startDate: date
		},()=>{
			this.calculateHarvestDate();
		});
	};
	
	calculateHarvestDate = () => {		
		var harvest_date;
		const startDate = this.state.startDate;
		const strain = this.state.strain;
		if(startDate!=null && strain!=null){
			if(strain.harvest_duration!='0' && strain.harvest_duration!=''){
				harvest_date = moment(startDate).add(parseInt(strain.harvest_duration),'w').format("MM/DD/YYYY HH:mm");
			}
		}		
		this.setState({
			harvest_date
		});
	};
	
	getStrainsList() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'inventory/get-strains-list',formData).then((res)=>{
			var data = res.data;
			var strain = this.state.strain;
			
			var strains=data.strains;
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			if(strains.length){
				strain=strains[0];
			}
				
			this.setState({			
				selectStrain:strains,
				strain
			},()=>{
				this.calculateHarvestDate();
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	getGroupsList() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-group-list',formData).then((res)=>{
			var data = res.data;
			var group_type = this.state.group_type;
			
			var groups=data.groups;
			groups = groups.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			if(groups.length){
				group_type=groups[0];
			}				
			this.setState({			
				selectGroup:groups,
				group_type
			});
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	getRoomData(room_id){
		var tables=[];
		var facility_id = localStorage.getItem('facility_id');		
		facility_id =  dataCrypto.decrypt(facility_id);			
		var formData = new FormData();
		formData.append('room_id',room_id);
		
		axios.post(REST_API_END_POINT+'growhouse/get-active-tables-by-room',formData).then((res)=>{
			var data = res.data;
			
			if(data.tables){
				tables=data.tables;
				tables = tables.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				let plant = this.state.plant;
				let table = tables.find(row=>{ return row.value==plant.table_id })
				this.setState({
					table,
					selectTable:tables,
				});				
			}else{
				this.setState({
					selectTable:tables,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);			
		});	
	}
  	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
		
			if(this.state.table==null){
				swal("Warning","Please select the table","warning");
				return;
			}
			
			document.getElementById("update-plant").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_id',this.props.match.params.plant_id);
            
			
			axios.post(REST_API_END_POINT+'growhouse/edit-plant',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				document.getElementById("update-plant").disabled=false;
				this.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-plant").disabled=false;
			});

		}
	}

    showButton = e =>{
		var elements = document.querySelectorAll('select[name="source"]');
		var value=e.value;
		
	    if(value=='1'){
			document.getElementById('sourceview').style.display='none';
			
		
		}else if(value=='2'){
			document.getElementById('sourceview').style.display='none';		
		}else if(value=='3'){
			document.getElementById('sourceview').style.display='none';	
		}
		else if(value=='4'){
			document.getElementById('sourceview').style.display='none';
		}
		else if(value=='5'){
			document.getElementById('sourceview').style.display='block';
		}else {			
			document.getElementById('sourceview').style.display='none';
		}
	}
  
  
  
  render() {
	  
	const {selectStrain,selectGroup,selectRoom,selectTable,selectPhase,selectVendor,phasetype,strain_name,phase_name,group_name} = this.state;  
	   
	    if(this.state.plant!=null){
			var plant=this.state.plant;
		}else{
			var plant;
		}
	  
	  return (
	  <Fragment>
			<Row>
			  <Colxx xxs="12">
				<Breadcrumb heading="menu.edit-plant" match={this.props.match} />
				<Separator className="mb-5" />
			  </Colxx>
				
			</Row>
			<AvForm
		        id="plantForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<Card>
			<CardBody>
				{plant ?<Row>
					<Colxx lg="12">
						<div className="text-center float-left">
							<Barcode value={plant.barcode} height={40} displayValue={false} />
							<p className="h5">{(plant.barcode.match(/.{1,4}/g)).join(' ')}</p>
						</div>
					</Colxx>
				</Row>:""}
				
				{phasetype==6?
					<Row>
						<Colxx xxs="2"  className="mt-4">
							<Label>Strain</Label>
						</Colxx>
						<Colxx xxs="2"  className="mt-4">
							<Label>{strain_name ? ((strain_name!=null)?strain_name:''):'' }</Label>
							<Input type="hidden" name="strain_id" value={this.state.strain!=null ? this.state.strain.value:0} />
						</Colxx>
					</Row>:
					<Row className="mt-2">
						<Colxx xxs="6">
							<AvGroup className="error-t-negative">								
								<Label >Strain</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="strain_id"
									id="strain_id"
									value={this.state.strain}						
									options={selectStrain}
									onChange={strain => { this.setState({ strain }) }}					
								/>								
							</AvGroup>	
						</Colxx>	
						{this.state.user_privileges.indexOf(17)!==-1 ? <Colxx xxs="6">
							<Button
								color="primary"
								size="sm"
								className=" mt-4"
								onClick={this.toggleModalAdd}>
								Add Strain
							</Button>					
						</Colxx>:""}					
					</Row>}
				
				{phasetype==6? <Row>
					<Colxx xxs="2"  className="mt-2">
						<Label>Phase</Label>
					</Colxx>
					<Colxx xxs="2"  className="mt-2">
						<Label>Drying</Label>
						<Input type="hidden" name="phase_id" value={this.state.phase!=null ? this.state.phase.value:6} />
					</Colxx>
				</Row>:<Row>
					<Colxx lg="6"  className="mt-2">			
						<AvGroup className="error-t-negative">
							<Label>Phase</Label>
							<Select							    
								type="select"
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="phase_id"
								id="phase_id"
								value={this.state.phase}						
								options={selectPhase}
								onChange={phase => { this.setState({ phase }) }}					
							/>
						</AvGroup>
					</Colxx>
				</Row>}
				
				{phasetype==6 ? <Row>
					<Colxx xxs="2"  className="mt-2">
						<Label>Group</Label>
					</Colxx>
					<Colxx xxs="2"  className="mt-2">
						<Label>{group_name ? ((group_name!=null)?group_name:''):'' }</Label>
						<Input type="hidden" name="group_id" value={this.state.group_type!=null ? this.state.group_type.value:""} />
					</Colxx>
				</Row>:<Row className="mt-2">
					<Colxx xxs="6">
						<Label>Group</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="group_id"
							id="group_id"					
							value={this.state.group_type}						
							options={selectGroup}
							onChange={group_type => { this.setState({ group_type }) }}					
						/>
					</Colxx>
					{this.state.user_privileges.indexOf(24)!==-1 ? <Colxx xxs="6">
						<Button
							color="primary"
							size="sm"
							className=" mt-4"
							onClick={this.toggleModalGroup}>
							Add Group
						</Button>					
					</Colxx>:""}					
				</Row>}
				
				{phasetype==6? <Row>
					<Colxx xxs="2"  className="mt-2">
						<Label>Birth date</Label>
					</Colxx>
					<Colxx xxs="2"  className="mt-2">
						<Label>{this.state.startDate!=null ? moment(this.state.startDate).format("MM/DD/YYYY HH:mm"):'' }</Label>
						<Input type="hidden" name="birth_date" value={this.state.startDate!=null ? moment(this.state.startDate).format("MM/DD/YYYY HH:mm"):''} />
						<Input type="hidden" name="expected_harvest_date" value={this.state.harvest_date} />
					</Colxx>
				</Row>:<Row className="mt-2">
					<Colxx lg="6" className="mt-2">
						 <Label >Birth date</Label>
						 <DatePicker
							className="p-0"
							selected={this.state.startDate}
							onChange={this.handleChangeDate}
							placeholderText="Date"
							dateFormat="MM/DD/YYYY HH:mm"
							id="birth_date"
							name="birth_date"
							autoComplete = "off"
							maxDate={moment()}
						  />
					</Colxx>
					<Colxx lg="6" className="mt-2">
						<Label >Expected Harvest Date</Label>
						<AvField
							id="expected_harvest_date"
							name="expected_harvest_date"
							type="text"
							readOnly={true}
							value={this.state.harvest_date}
						/>
					</Colxx>				
				</Row>}
				<Row className="mt-4">
					<Colxx lg="6" className="mb-2">						
						<Label>Room</Label>
						 <Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="room_id"
							id="room_id"
							value={this.state.room}						
							options={selectRoom}
							onChange={room => { this.setState({ room },()=>{ this.getRoomData(room.value)	}) }}
						/>			
					</Colxx>
					<Colxx lg="6" className="mb-2">						
						<Label>Table</Label>
						 <Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"						
							name="table_id"
							id="table_id"
							value={this.state.table}						
							options={selectTable}
							onChange={table => { this.setState({ table }) }}
						/>			
					</Colxx>
				</Row>								
				<Row className="mt-3">
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Position<span className="text-danger">*</span></Label>
							  <AvField
								id="position"
								name="position"
								type="number"
								value={ plant?((plant.position!=null)?plant.position:''):'' }
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},								  
									min: {
										value: 1,
										errorMessage: "Position must be greater than or equal to 1"
									}
								}}
							  />
							</AvGroup>
						</Colxx>	
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>Vendor</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="vendor_id"
									id="vendor_id"
									value={this.state.vendor}						
									options={selectVendor}
									onChange={vendor => { this.setState({ vendor }) }}
								/>
							</AvGroup>
						</Colxx>					
				</Row>
				
				<div id="api_message"></div>

				{plant ? <Row className="mt-4">					
					<Colxx lg="12">
						<Button
							  id="update-plant"					
							  color="primary"
							  size="sm"
							  className="mr-2"							  
							>
							  Save							  
						</Button>{" "}{this.state.user_privileges.indexOf(12)!==-1 ? <Fragment><Button																		 
								color="danger"
								size="sm"
								className="mr-2"
								type="button"
								onClick={this.toggleModalAddTask}					  
							>
							  Waste Plant							  
						</Button>{" "}<Button																		 
								color="danger"
								size="sm"
								className="mr-2"
								type="button"
								onClick={this.toggleModalAddPhase}					  
							>
							  Waste Plant / Phase							  
						</Button></Fragment>:""}{" "}{this.state.user_privileges.indexOf(14)!==-1 ? <Fragment><Button																		 
								color="light"
								size="sm"
								className="mr-2"
								type="button"
								onClick={()=>{ setTimeout(function(){ document.getElementById('print-tag-hidden').click() }, 500) }}
							>
							  Print Tag							  
						</Button>{" "}<Button																		 
								color="light"
								size="sm"
								className="mr-2"
								type="button"
								onClick={()=>{ setTimeout(function(){ document.getElementById('print-barcode-hidden').click() }, 500) }}
							>
							  Print Barcode							  
						</Button></Fragment>:""}{" "}
						{phasetype==5 && this.state.user_privileges.indexOf(15)!==-1 ? <Button
							  id="sourceview" 
							  color="primary"
							  size="sm"
							  className="mr-2"	
							  type="button"
							  onClick={(e)=>{ this.toggleModalHarvest(e,this.props.match.params.plant_id) }}								  
						>Harvest							  
						</Button>:""}{" "}
						{phasetype==6 && this.state.user_privileges.indexOf(16)!==-1 ? <Button
								id="cureview" 
								color="primary"
								size="sm"
								className="mr-2"
								type="button"
								onClick={(e)=>{ this.toggleModalCure(e,this.props.match.params.plant_id) }}								  
							>
							Cure
						</Button>:""}
					</Colxx>
				</Row>:""}
					
			</CardBody>
			</Card>
		</AvForm>
			<Card className="mt-4">
				<Row>
					<Colxx lg="12" className="page-tabs">
						<div className="p-4">								
							<CardHeader>
							  <Nav tabs className="card-header-tabs ">
								<NavItem>
								  <NavLink
									className={classnames({
									  active: this.state.activeFirstTab === "1",
									  "nav-link": true
									})}
									onClick={() => {
									  this.toggleFirstTab("1");
									}}
									to="#"
								  >
									Notes
								  </NavLink>
								</NavItem>
								<NavItem>
								  <NavLink
									className={classnames({
									  active: this.state.activeFirstTab === "2",
									  "nav-link": true
									})}
									onClick={() => {
									  this.toggleFirstTab("2");
									}}
									to="#"
								  >
									Remainder
								  </NavLink>
								</NavItem>
								<NavItem>
								  <NavLink
									className={classnames({
									  active: this.state.activeFirstTab === "3",
									  "nav-link": true
									})}
									onClick={() => {
									  this.toggleFirstTab("3");
									}}
									to="#"
								  >
									Additives
								  </NavLink>
								</NavItem>
								<NavItem>
								  <NavLink
									className={classnames({
									  active: this.state.activeFirstTab === "4",
									  "nav-link": true
									})}
									onClick={() => {
									  this.toggleFirstTab("4");
									}}
									to="#"
								  >
									Documents
								  </NavLink>
								</NavItem>
								<NavItem>
								  <NavLink
									className={classnames({
									  active: this.state.activeFirstTab === "5",
									  "nav-link": true
									})}
									onClick={() => {
									  this.toggleFirstTab("5");
									}}
									to="#"
								  >
									Ingredients
								  </NavLink>
								</NavItem>
								<NavItem>
								  <NavLink
									className={classnames({
									  active: this.state.activeFirstTab === "6",
									  "nav-link": true
									})}
									onClick={() => {
									  this.toggleFirstTab("6");
									}}
									to="#"
								  >
									Nutrients
								  </NavLink>
								</NavItem>
							  </Nav>
							</CardHeader>
						</div>	
					</Colxx>
				</Row>
				<TabContent activeTab={this.state.activeFirstTab}>
					  <TabPane tabId="1">
						<Row className="p-0">
						   <Colxx lg="12">                    
							<Notes  plant_id={this.props.match.params.plant_id} phase_id={this.state.phase} dataListRender = {this.dataListRender} user_privileges={this.state.user_privileges} />                      
						  </Colxx>
						</Row>
					  </TabPane>
                </TabContent>
				<TabContent activeTab={this.state.activeFirstTab}>
					  <TabPane tabId="2">
						<Row className="p-0">
						   <Colxx lg="12">                    
							<Reminder  plant_id={this.props.match.params.plant_id} dataListRender = {this.dataListRender}  user_privileges={this.state.user_privileges} />                       
						  </Colxx>
						</Row>
					  </TabPane>
                </TabContent>
				<TabContent activeTab={this.state.activeFirstTab}>
					  <TabPane tabId="3">
						<Row className="p-0">
						   <Colxx lg="12">                    
							<Additives plant_id={this.props.match.params.plant_id} dataListRender = {this.dataListRender} user_privileges={this.state.user_privileges} />                       
						  </Colxx>
						</Row>
					  </TabPane>
                </TabContent>
				<TabContent activeTab={this.state.activeFirstTab}>
					  <TabPane tabId="4">
						<Row className="p-0">
						   <Colxx lg="12">                    
							<Documents plant_id={this.props.match.params.plant_id} dataListRender = {this.dataListRender} user_privileges={this.state.user_privileges} />                       
						  </Colxx>
						</Row>
					  </TabPane>
					</TabContent>
				<TabContent activeTab={this.state.activeFirstTab}>
					  <TabPane tabId="5">
						<Row className="p-0">
						   <Colxx lg="12">                    
							<Ingridients plant_id={this.props.match.params.plant_id} dataListRender = {this.dataListRender} user_privileges={this.state.user_privileges} />                       
						  </Colxx>
						</Row>
					  </TabPane>
                </TabContent>
				<TabContent activeTab={this.state.activeFirstTab}>
					  <TabPane tabId="6">
						<Row className="p-0">
						   <Colxx lg="12">                    
							<Nutrients plant_id={this.props.match.params.plant_id} dataListRender = {this.dataListRender} user_privileges={this.state.user_privileges} />                       
						  </Colxx>
						</Row>
					  </TabPane>
                </TabContent>	
				</Card>
				<AddStrainModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.getStrainsList}
				/>
				<AddGroupModal
					modalOpen={this.state.modalOpenGroup}
					toggleModal={this.toggleModalGroup}
					dataListRender={this.getGroupsList}
				/>
				
				<WastePlantModal
					modalOpen={this.state.modalOpenAddTask}
					toggleModal={this.toggleModalAddTask}
					dataListRender={this.dataListRender}
					plant_id={this.props.match.params.plant_id}
					strain_id ={this.state.strain}
					phase_id={this.state.phase}
					table={this.state.table}
				/>  
				<WastePhasePlantModal
					modalOpen={this.state.modalOpenAddPhase}
					toggleModal={this.toggleModalAddPhase}
					dataListRender={this.dataListRender}
					plant_id={this.props.match.params.plant_id}
					strain_id ={this.state.strain}
					phase_id={this.state.phase}
				/> 	
				<HarvestPlantModal
					modalOpen={this.state.modalOpenHarvest}
					toggleModal={this.toggleModalHarvest}
					dataListRender={this.dataListRender}
					strain_name ={this.state.strain}
					plant_id={this.state.plant_id}		 
				/>	
				<EditCurePlantModal
					modalOpen={this.state.modalOpenCure}
					toggleModal={this.toggleModalCure}
					dataListRender={this.dataListRender}
					plant_id={this.state.plant_id}
				/>

				{plant ? <div className={"d-none"}>
					<ReactToPrint
						trigger={() => <span className="d-block cursor-pointer" id="print-barcode-hidden" >Print</span>}
						content={() => this.componentRefBarcode}
						copyStyles={true}
						pageStyle={""}
					/>
					<PrintBarcode 
						ref={el => (this.componentRefBarcode = el)} 
						barcode={plant.barcode}
					/>
				</div>:""}
				
				{plant ? <div className={"d-none"}>
					<ReactToPrint
						trigger={() => <span className="d-block cursor-pointer" id="print-tag-hidden" >Print</span>}
						content={() => this.componentRefTag}
						copyStyles={true}
						pageStyle={""}
					/>
					<PrintTag 
						ref={el => (this.componentRefTag = el)}
						plant_print={plant.print}
					/>
				</div>:""}
	   </Fragment>
	  );
  }
};

export default EditPlantModal;
