import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import AddStrainModal from "../../containers/inventory/AddStrainModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
const selectData = [
    { label: "$", value: "0", key: 0 },
    { label: "%", value: "1", key: 1 }
  ];
 
  const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline", 
  "list",
  "bullet",
  "indent", 
];

class ViewWasteModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
 modalOpenAdd: false,		
		};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			View Waste
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Barcode</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>barcode</Label>						  
						</AvGroup>
					</Colxx>					
				</Row>								
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Product Name</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Cannaplex Sticky Fields</Label>						  
						</AvGroup>
					</Colxx>					
				</Row>		
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Quantity</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>5.00</Label>						  
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Reason for Waste</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Returned product from inventory transfer</Label>						  
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Reason for Rejection</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Test</Label>						  
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Status</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Rejected</Label>						  
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Added by</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label></Label>						  
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>Added at</Label>						  
						</AvGroup>
					</Colxx>
					<Colxx xxs="6">
						<AvGroup className="error-t-negative">
						  <Label>04/22/2019 07:48</Label>						  
						</AvGroup>
					</Colxx>					
				</Row>
					
		  </ModalBody>
		  <ModalFooter>
			
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default ViewWasteModal;
