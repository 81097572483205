import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,Label,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddGroupModal from "../../../containers/wholesale/AddGroupModal";
import EditPartnerModal from "../../../containers/wholesale/EditPartnerModal";
import ReactTable from "react-table";

import data from "../../../data/partner";

export default class AllGroups extends Component {
	
	constructor(props) {
		super(props);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		 modalOpenAdd: false,
		 modalOpenAddResult: false,
		  modalOpenStaff: false,
		  data:data,
		  search: '',
		};
	}
	
	 toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
     
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.groups" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		<Card className="p-4">
		   <Row>
			 
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add Group						  
						</Button>
							<AddGroupModal
							modalOpen={this.state.modalOpenAdd}
							toggleModal={this.toggleModalAdd}
						  />							
					</div>
					 
					
					
			  </Colxx>
			</Row>
			<Row className="mt-2">			 
			  <Colxx xxs="6">					
					<div className="mb-4 dropdown-edit">
						<Card className="p-4">
							<Row>
								<Colxx lg="12" className="text-center">
									<i className="iconsminds-male-2" style={{fontSize:'25px'}} />
								</Colxx>
							</Row>
							<Row>
								<Colxx lg="12" className="text-center">
									<NavLink to="/app/wholesale/groups-list"><h3>Web Desiging</h3></NavLink>
								</Colxx>
							</Row>
							<Row>
								<Colxx lg="12" className="text-center">
									<h8>4 Vendors</h8>
								</Colxx>
							</Row>
							<Row>
								<Colxx lg="12" className="text-center">
									<h8>California</h8>
								</Colxx>
							</Row>
						</Card>						
					</div>					
			  </Colxx>
			  <Colxx xxs="6">					
					<div className="mb-4 dropdown-edit">
						<Card className="p-4">
							<Row>
								<Colxx lg="12" className="text-center">
									<i className="iconsminds-male-2" style={{fontSize:'25px'}} />
								</Colxx>
							</Row>
							<Row>
								<Colxx lg="12" className="text-center">
									<NavLink to="/app/wholesale/groups-list"><h3>Web Desiging</h3></NavLink>
								</Colxx>
							</Row>
							<Row>
								<Colxx lg="12" className="text-center">
									<h8>4 Vendors</h8>
								</Colxx>
							</Row>
							<Row>
								<Colxx lg="12" className="text-center">
									<h8>California</h8>
								</Colxx>
							</Row>
						</Card>						
					</div>
					
			  </Colxx>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
