import React, { Component, Fragment } from "react";
import { Row, FormGroup,Input,
    DropdownItem,
    DropdownMenu, Badge,Label,Button } from "reactstrap";
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";
import ViewAgreement from "../../containers/wholesale/ViewAgreement";
import UploadFileModal from "../../containers/wholesale/UploadFileModal";
import data from "../../data/document";
import dataAgree from "../../data/agreement";
import dataFiles from "../../data/files";

class Documents extends Component {
	constructor(props) {
		super(props);
   
		this.state = {
			data:data,
			search: '',
			dataAgree:dataAgree,
			searchAgree: '',
			modalOpenView:false,
			modalOpenAdd:false,
		};
	}
	 toggleModalView = () => {
		this.setState({
		  modalOpenView: !this.state.modalOpenView
		});
	};
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		let dataAgree = this.state.dataAgree
		if (this.state.searchAgree) {
			dataAgree = dataAgree.filter(row => {
				return row.send_on.includes(this.state.searchAgree) || row.recipient.includes(this.state.searchAgree) || row.subject.includes(this.state.searchAgree)
			})
		}
		
	const dataTableRequested = [
	  {
		Header: "Name",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Uploaded",
		accessor: "uploaded",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Issue date",
		accessor: "issue_date",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Expiry date",
		accessor: "expiry_date",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Status",
		accessor: "status",
		Cell: props => <Badge color={props.value.statusColor} pill>{props.value.statusid===1?"Uploaded":""}</Badge>
	  },
	  {
		Header: "",
		accessor: "",
		Cell: props => <NavLink to="#" className="btn btn-sm btn-secondary">View</NavLink>
	  }
	];
	const dataTableAgreement = [
	  {
		Header: "Agreement",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Sent",
		accessor: "uploaded",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Signed",
		accessor: "issue_date",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Status",
		accessor: "status",
		Cell: props => <Badge color={props.value.statusColor} pill>{props.value.statusid===1?"Completed":""}</Badge>
	  },
	  {
		Header: "",
		accessor: "",
		Cell: props => <NavLink to="#" onClick={this.toggleModalView} className="btn btn-sm btn-secondary">View</NavLink>
	  }
	];
	const dataTableFiles = [
	  {
		Header: "No.",
		accessor: "id",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Name",
		accessor: "name",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Uploaded On",
		accessor: "uploaded_on",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Uploaded By",
		accessor: "uploaded_by",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "",
		accessor: "",
		Cell: props => <NavLink to="#" className="btn btn-sm btn-secondary">View</NavLink>
	  }
	];
	
	
    return (
      <Fragment>
	  <h3 className="font-weight-bold">Requested Documents</h3>
        <Row>        
          <Colxx xxs="12">
             	<div className="mb-4 dropdown-edit">
					
					<FormGroup row>
                        <Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search"
							  placeholder="Search"
							  value={this.state.search}
							  onChange={e => this.setState({search: e.target.value})}
							/>
                      </Colxx>
					  </FormGroup>			
					<ReactTable
					  data={data}
					  columns={dataTableRequested}
					  defaultPageSize={2}
					  filterable={false}
					  showPageJump={false}
					  PaginationComponent={DataTablePagination}
					  showPageSizeOptions={true}
					  defaultSorted={[{id: "send_on",desc: true}]}
					/>
				</div>
          </Colxx>
        </Row>
		
		<h3 className="font-weight-bold">Agreements</h3>
        <Row>        
          <Colxx xxs="12">
             	<div className="mb-4 dropdown-edit">
					<ViewAgreement
					modalOpen={this.state.modalOpenView}
					toggleModal={this.toggleModalView}
				  />
					<FormGroup row>
                        <Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search"
							  placeholder="Search"
							  value={this.state.search}
							  onChange={e => this.setState({search: e.target.value})}
							/>
                      </Colxx>
					  </FormGroup>			
					<ReactTable
					  data={dataAgree}
					  columns={dataTableAgreement}
					  defaultPageSize={2}
					  filterable={false}
					  showPageJump={false}
					  PaginationComponent={DataTablePagination}
					  showPageSizeOptions={true}
					  defaultSorted={[{id: "send_on",desc: true}]}
					/>
				</div>
				 
          </Colxx>
        </Row>
		
		<h3 className="font-weight-bold">Files</h3>
        <Row>        
          <Colxx xxs="12">
				<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className="mb-2"
							  onClick={this.toggleModalAdd}
							>
							  Upload new File							  
						</Button>
														
					</div>
             	<div className="mb-4 dropdown-edit">
					<UploadFileModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
				  />
					<FormGroup row>
                        <Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search"
							  placeholder="Search"
							  value={this.state.search}
							  onChange={e => this.setState({search: e.target.value})}
							/>
                      </Colxx>
					  </FormGroup>			
					<ReactTable
					  data={dataFiles}
					  columns={dataTableFiles}
					  defaultPageSize={2}
					  filterable={false}
					  showPageJump={false}
					  PaginationComponent={DataTablePagination}
					  showPageSizeOptions={true}
					  defaultSorted={[{id: "send_on",desc: true}]}
					/>
				</div>
				 
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

export default Documents;
