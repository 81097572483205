import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input,
    DropdownItem,
	CustomInput, Badge,ButtonDropdown,DropdownToggle,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddGroupModal from "../../../containers/growhouse/AddGroupModal";
import RemoveGroupModal from "../../../containers/growhouse/RemoveGroupModal";
import ReactTable from "react-table";

import data from "../../../data/alltable";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Group extends Component {
	
	constructor(props) {
		super(props);
		
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		
		
		this.state = {		  
		  modalOpenTable: false,
		  modalOpenEditTable: false,
		  modalOpenPlantTable: false,
		  modalOpenHarvest: false,
		  modalOpenCure: false,
		  modalOpenCut: false,
		  dropdownSplitOpen: false,
		  data:data,
		  search: '',
		  groups:[],
		  removeIds:[],
		  selectedIds:[],
		  user_privileges:[],
		  selectAll:0
		};
	}
	toggleModalGroup = () => {
		this.setState({
		  modalOpenGroup: !this.state.modalOpenGroup
		});
	};

	toggleModalRemoveGroup = (e,removeIds=[]) => {
		this.setState({
			removeIds,
			modalOpenRemoveGroup: !this.state.modalOpenRemoveGroup
		});
	};
	
	toggleSplit = () => {
    this.setState(prevState => ({
      dropdownSplitOpen: !prevState.dropdownSplitOpen
    }));
    };
	
  	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.groups.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}

	
    dataListRender() {		
		var groups=[];
		var facility_id = localStorage.getItem('facility_id');		
		facility_id =  dataCrypto.decrypt(facility_id);
		
        var formData = new FormData();		
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'growhouse/groups',formData).then((res)=>{
			var data = res.data;
			
				groups=data.groups;
				
				this.setState({
					groups: groups,
					selectAll:0,
					selectedIds:[]
				});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}

	deleteItem = (e,selectedIds,status)  => {
			var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="groups";
		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one group","warning");
			return false;
		}
		
		if(selectedIds.length>1){
			msg_word="plants";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been removed.";
            failure_msg="Failed to remove "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {			
			this.toggleModalRemoveGroup(e,selectedIds);
		});
	}
	

	activeItem=(e,group_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(group_id);
			
		}else{
		  var x=selectedIds.indexOf(group_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.groups.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	render() {
	  let data = this.state.groups
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.created_at.includes(this.state.search) 
			})
		}
		
	const dataTableColumns = [
		
	     {
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"groups_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
		},
	  {
		Header: "Name",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>	
	  },
	  {
		Header: "Created On",
		accessor: "created_at",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	
	 	  	  
	 
	];
    return (
      <Fragment>
        <Row>
			<Colxx xxs="12">
				<Breadcrumb heading="menu.groups" match={this.props.match} />
				<Separator className="mb-5" />
			  </Colxx>		    
        </Row>
		
		<Card className="p-4">
			<Row>
				<Colxx xxs="12">
					<div id="api_message"></div>					
					<div className="float-sm-right mb-2">							
						{" "}{this.state.user_privileges.indexOf(24)!==-1 ?<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalGroup}
							>
							  Add 					  
						</Button>:""}																		
						   {" "}{this.state.user_privileges.indexOf(26)!==-1 ?<Button
							color="primary"
							size="sm"
							className=""
							id="delete-account"
                            onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 						
						  >
							Delete
					  </Button>:""}
					
					</div>
						  
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
				</Colxx>
			  					 
						{this.state.user_privileges.indexOf(24)!==-1 ?<AddGroupModal
							modalOpen={this.state.modalOpenGroup}
							toggleModal={this.toggleModalGroup}
							plant_id ={this.state.plant_id}
							dataListRender = {this.dataListRender}
						/>:""}
						
						{this.state.user_privileges.indexOf(26)!==-1 ?<RemoveGroupModal
							modalOpen={this.state.modalOpenRemoveGroup}
							toggleModal={this.toggleModalRemoveGroup}
							dataListRender = {this.dataListRender}
							group_ids={this.state.removeIds}
						/>:""}
			</Row>
		</Card>
      </Fragment>
    );
  }
}
