import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row, Card, CardHeader, CardBody, CustomInput,
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter,
	Label,
	Nav,NavItem,NavLink, TabContent, TabPane
} from "reactstrap";
import classnames from "classnames";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import SingleDrawerCheckOff from "../../../components/drawer/SingleDrawerCheckOff";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class CloseDrawerModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			drawer:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			},
			activeFirstTab:"1",
			blocking:true,
			assigned_to:"",
			drawer_starting_cash:{USD: "0.00", CAD: "0.00", JMD: "0.00"},
			credit_card_total:{USD: "0.00", CAD: "0.00", JMD: "0.00"},
			customer_order_details: {USD: [], CAD: [], JMD: []},
			drawer_closing_total: {USD: {}, CAD: {}, JMD: {}},
			drawer_sales_cash_total: {USD: {}, CAD: {}, JMD: {}}
		};
		this.CloseDrawer = this.CloseDrawer.bind(this);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){		
			if (prevProps.drawer_id!== this.props.drawer_id && this.props.drawer_id!=0) {
				this.setState({ blocking:true })
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('drawer_id',this.props.drawer_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'drawer/get-drawer-checkoff',formData).then((res)=>{					
					var data = res.data;
				    let drawer_starting_cash=data.drawer_starting_cash;
				    let credit_card_total=data.credit_card_total;
				    let customer_order_details=data.customer_order_details;
				    let drawer_closing_total=data.drawer_closing_total;
				    let drawer_sales_cash_total=data.drawer_sales_cash_total;
					
				    let drawer=data.drawer_total;
					this.setState({
						drawer_starting_cash,
						credit_card_total,
						customer_order_details,
						drawer_closing_total,
						drawer_sales_cash_total,
						blocking:false
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					this.setState({ blocking:false })
				});
            }
        }
    }
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
	
	CloseDrawer = (e)  => {
		if(this.state.assigned_to!=""){
			var success_msg="The shift has been closed successfully.";
			var failure_msg="Failed to close shift.";
		  
			swal({
			  title: "Do you want to close this shift?",
			  text: "",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
				if (willDelete) {
						document.getElementById("close-shift").disabled=true;
								
						var user_id = localStorage.getItem('user_id');
						var facility_id = localStorage.getItem('facility_id');
						facility_id =  dataCrypto.decrypt(facility_id);
						var formData = new FormData(document.getElementById("closeShiftForm"));
						
						formData.append('user_id',user_id);
						formData.append('facility_id',facility_id);
						formData.append('drawer_id',this.props.drawer_id);
						
						axios.post(REST_API_END_POINT+'drawer/close-shift',formData).then((res)=>{
							var data = res.data;
						
							document.getElementById("close-shift").disabled=false;
							
							var api_message=document.getElementById("api_message");
							if(data.status==1){
								api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
							}else{
								api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
							}
							
							window.setTimeout(function() {
								JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
									JQUERY_MODULE.$(this).remove(); 
								});
							}, 5000);
							this.setState({ assigned_to:"" });
							this.props.toggleModal();
							this.props.dataListRender();
							
						}).catch(e =>{
							console.log("Addition failed , Error ", e);
							document.getElementById("close-shift").disabled=false;
						});
					
				}else{
					swal("Your operation is canceled!");
				}
			});
		}else{
			swal("Warning!","Please select a bud-tender","warning");
		}
	}
	
	render() {
	  		
		const { drawer_starting_cash, credit_card_total, customer_order_details, drawer_closing_total, drawer_sales_cash_total } = this.state;	
		var drawer = this.state.drawer;	
		
		if(this.state.drawer!=null){
			var total = parseInt(drawer['1_s'])+parseInt(drawer['2_s'])*2+parseInt(drawer['5_s'])*5+parseInt(drawer['10_s'])*10+parseInt(drawer['20_s'])*20+parseInt(drawer['50_s'])*50+parseInt(drawer['100_s'])*100+parseInt(drawer['cents'])*0.01+parseInt(drawer['nickles'])*0.05+parseInt(drawer['dimes'])*0.1+parseInt(drawer['quarters'])*0.25+drawer['50_cents']*0.5+parseInt(drawer['1_d'])
		}else{
			var total=0;
		}
		
		const currencies = this.props.currencies;
	
		return (	
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Close Shift
				</ModalHeader>
				<AvForm
					id="closeShiftForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row>
							<Colxx xxs="12">
								<AvGroup className="error-t-negative">
									<Label>Select Bud-tender</Label>
									<AvField
										type="select"
										name="assigned_to"
										id="assigned_to"
										placeholder="--Select Staff--"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
										value={this.state.assigned_to}
										onChange={(e)=>{
											this.setState({
												assigned_to:e.target.value
											});
										}}
									>
									<option value="" >--Select Staff--</option>
									{this.props.staffs.map(opt=>{
										return <option key={opt.key} value={opt.value} >{opt.label}</option>
									})}
									</AvField>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12" className="page-tabs">
								<div className="pb-4 pl-2 pr-2">								
									<CardHeader className="d-none">
										<Nav tabs className="card-header-tabs ">
											{currencies.map((row,index)=>{
												return(<NavItem className="cursor-pointer" key={index}>
													<NavLink
														className={classnames({
														  active: this.state.activeFirstTab === (index+1).toString(),
														  "nav-link": true
														})}
														onClick={() => {
														  this.toggleFirstTab((index+1).toString());
														}}
														to="#"
													>
														{row.country_name+" - "+row.currency_code}
													</NavLink>
												</NavItem>)
											})}
										</Nav>
									</CardHeader>
									<CardBody className="p-0">
										{currencies.map((row,index)=>{
											return(<TabContent activeTab={this.state.activeFirstTab} key={index}>
												<TabPane tabId={(index+1).toString()}>
													{!this.state.blocking ? <SingleDrawerCheckOff 
														selected_currency={currencies.find(r=>{ return r.currency_code==row.currency_code })}
														drawer_starting_cash={drawer_starting_cash[row.currency_code]}
														credit_card_total={credit_card_total[row.currency_code]}
														customer_order_details={customer_order_details[row.currency_code]}
														drawer_closing_total={drawer_closing_total[row.currency_code]}
														drawer_sales_cash_total={drawer_sales_cash_total[row.currency_code]}
														drawer_id={this.props.drawer_id}
														readOnly={false}
													/>:""}
												</TabPane>
											</TabContent>)
										})}
									</CardBody>	
								</div>	
							</Colxx>
						</Row>
					
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="close-shift" onClick={event =>this.CloseDrawer(event)} >
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default  CloseDrawerModal;
