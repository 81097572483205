import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, CardImg, Badge, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import queryString from 'query-string';
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";

import classnames from "classnames";
import { NavLink } from "react-router-dom";

import Memberships from "../../containers/admin/Memberships";
import Payments from "../../containers/admin/Payments";

import { REST_API_END_POINT, BASE_URL,themeColor } from "../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import { connect } from "react-redux";

import { loginAsSubscriber } from "../../redux/actions";

const period_types = {
	'1':'Day',
	'2':'Month',
	'3':'Year',
}

class UserDetail extends Component {
	
	constructor(props) {
		super(props);

		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		this.LoginAsSubscriber = this.LoginAsSubscriber.bind(this);
		this.state = {
			activeFirstTab:'1',
			user_data:null,
			blocking:true
		};
	}
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
		  this.setState({
			activeFirstTab: tab
		  });
		}
	}
	
	componentDidMount() {
		
		var formData = new FormData();
		formData.append('user_id',this.props.match.params.id);
		
		axios.post(REST_API_END_POINT+'admin/subscriber-get-by-id',formData).then((res)=>{ 
			if(res.data.status==1){
				this.setState({ user_data: res.data.data, blocking:false });
			}else{
				swal("Failed to load the profile!", "Please refresh the page.", "error");
				this.setState({ blocking:false });					
			}			
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}
	

	LoginAsSubscriber() {
		if (this.state.user_data !== null) {
			this.setState({ blocking:true });	
			this.props.loginAsSubscriber({user_id:this.state.user_data.id,from_id:1}, this.props.history);
		}
	}	
		
	render() {
		const { user_data } = this.state;
		
		var company_logo=BASE_URL+'company/avatar.png';
		var plan_image = BASE_URL+'subscriptions/default_plan.jpg';
		
		if(user_data!=null){
			if(user_data.company_logo!=null && typeof user_data.company_logo!=='undefined' ){		
				company_logo=BASE_URL+"company/"+user_data.company_logo;		
			}
			
			if(typeof user_data.subscriber_plan!=='undefined'){		
				if(user_data.subscriber_plan.plan_image!="" && typeof user_data.subscriber_plan.plan_image!=='undefined'){		
					plan_image=BASE_URL+'subscriptions/'+user_data.subscriber_plan.plan_image;
				}
			}
		}

		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.user-detail" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="4" md="12" className="mb-4">
							<Card className="h-100">
								<CardBody>
									<h2>Personal Detail</h2>
									<hr/>
									<div className="subscriber-user-wrapper d-flex flex-wrap">
										<div className="text-center mb-3">
											{user_data!=null ? <CardImg top alt={user_data.company_name} src={company_logo} />:""}
										</div>
										<div className="ml-3 mb-3 subscriber-user-detail">
											<h3>{user_data!=null ? user_data.first_name+" "+user_data.last_name:""}</h3>
											Email: <a href={user_data!=null ? "mailto:"+user_data.email:"mailto:"} >{user_data!=null ? user_data.email:""}</a><br/>
											Phone: {user_data!=null ? user_data.phone:""}<br/>
											Company Name: {user_data!=null ? user_data.company_name:""}<br/>
											Status:  {user_data!=null ? (parseInt(user_data.status)===1 ? <Badge color={"success"} pill>Active</Badge>:(parseInt(user_data.status)===3 ? <Badge color={"danger"} pill>Inactive</Badge>:<Badge color={"primary"} pill>Unverified</Badge>)):""}<br/>
										</div>
									</div>
								</CardBody>
							</Card>
						</Colxx>
						<Colxx lg="4" md="12" className="mb-4">
							<Card className="h-100">
								<CardBody>
									<h2>Current Plan</h2>
									<hr/>
									<div className="subscriber-user-wrapper d-flex flex-wrap">
										<div className="text-center mb-3">
											{user_data!=null ? <CardImg top alt={user_data.subscriber_plan.name} src={plan_image} />:""}
										</div>
										<div className="ml-3 mb-3 subscriber-user-detail">
											<h3>{user_data!=null ? user_data.subscriber_plan.name:""}</h3>
											Price: <strong>${user_data!=null ? user_data.subscriber_plan.price:"0"}</strong><br/>
											Period: {user_data!=null ? user_data.subscriber_plan.period:"0"}{" "}{user_data!=null ? period_types[user_data.subscriber_plan.period_type.toString()]+(user_data.subscriber_plan.period!=1 ? "s":""):""} <br/>
											Status: {user_data!=null ? (parseInt(user_data.subscriber_plan.status)===1 ? <Badge color={"success"} pill>Active</Badge>:<Badge color={"danger"} pill>Inactive</Badge>):""}<br/>
										</div>
									</div>
								</CardBody>
							</Card>
						</Colxx>
						<Colxx lg="4" md="12" className="mb-4">
							<Card className="h-100">
								<CardBody>
									<h2>Login As</h2>
									<hr/>
									<div className="subscriber-user-wrapper text-center">
										<Button color="primary" size="xl" className="mt-5 mb-4" onClick={this.LoginAsSubscriber}>Login as subscriber</Button>
									</div>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
					
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardHeader>
									<Nav tabs className="card-header-tabs ">
										<NavItem>
											<NavLink
												className={classnames({
												  active: this.state.activeFirstTab === "1",
												  "nav-link": true
												})}
												onClick={() => {
												  this.toggleFirstTab("1");
												}}
												to="#"
											>
												Memberships
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
												  active: this.state.activeFirstTab === "2",
												  "nav-link": true
												})}
												onClick={() => {
												  this.toggleFirstTab("2");
												}}
												to="#"
											>
												Payments
											</NavLink>
										</NavItem>
									</Nav>
								</CardHeader>
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="1">
										<Row>
											<Colxx sm="12">
												<CardBody>
													<Memberships 
														memberships={user_data!=null ? user_data.memberships:[]} 
													/>
												</CardBody>
											</Colxx>
										</Row>
									</TabPane>
									<TabPane tabId="2">
										<Row>
											<Colxx sm="12">
												<CardBody>
													<Payments 
														payments={user_data!=null ? user_data.payments:[]} 
													/>
												</CardBody>
										  </Colxx>
										</Row>
									</TabPane>
								</TabContent>  
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginAsSubscriber
  }
)(UserDetail);

