import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import {Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";

import { CalendarToolbar } from "../../components/CalendarToolbar";
import IntlMessages from "../../helpers/IntlMessages";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const localizer = momentLocalizer(moment);

class CalendarX extends React.Component {
	constructor(props) {
    super(props);
	   this.state = { 
        calendarData:[]
       };
	}
	
	handleSelect = ({ start, end }) => {
		this.props.modalEvent();
	}
    
    componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'customer/express-orders-data',formData).then((res)=>{
			var data = res.data;
			this.setState({calendarData:data.orders});
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
    }
  
    render() {
      return (
        <Card>
          <CardBody>
           
            <Calendar
                selectable
                localizer={localizer}
                style={{ minHeight: "800px" }}
                events={this.state.calendarData}
                views={["month","week"]}
                className="dashboard_calender"
                onSelectEvent={event => alert(event.title)}
                onSelectSlot={this.handleSelect}
                components={{
                    toolbar: CalendarToolbar
                }}
            />
          </CardBody>
        </Card>
      );
    }
};
export default CalendarX;
