import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, DEFAULT_JAR_TYPES} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);	
	}
	  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {			
			document.getElementById("add-dependencies").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("dependencyForm"));
			formData.append('facility_id',facility_id);
			
			axios.post(REST_API_END_POINT+'processor/is-equipment-dependency-name-exist',formData).then((res)=>{
				var data = res.data;
				
				if(!data.status){
					var formData = new FormData(document.getElementById("dependencyForm"));
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);

					axios.post(REST_API_END_POINT+'processor/add-equipment-dependency',formData).then((res)=>{
						var data = res.data;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						}
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("add-dependencies").disabled=false;
						this.props.toggleModal();
						this.props.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("add-dependencies").disabled=false;
					});
				}else{
					swal("Equipment dependency name already exist! Please use another name");
					document.getElementById("add-dependencies").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-dependencies").disabled=false;
			});
		}
	}
  
	render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
	     	Add Equipment Dependency
		  </ModalHeader>
			<AvForm
				id="dependencyForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }								  
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-dependencies" >
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
	  </Modal>
	  );
  }
};

export default AddModal;
