import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input,  DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddWarehouseModal from "../../../containers/warehouse/AddWarehouseModal";
import EditWarehouseModal from "../../../containers/warehouse/EditWarehouseModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Warehouselistview extends Component {
	
	constructor(props) {
		super(props);
	    this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
				 
		 modalOpenAdd: false,
		 modalOpenEdit: false,
		 warehouses:[],
		 search: '',
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
		toggleModalEdit = (e,warehouse_id=0) => {
		this.setState({
			warehouse_id: parseInt(warehouse_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
    toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.warehouses.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
    
	componentDidMount(){
	   this.dataListRender();
	}
	
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="shelftype";
		
		if(selectedIds.length>1){
			msg_word="allwarehouses";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-allwarehouses").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('warehouse_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'warehouse/delete-warehouses',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-allwarehouses").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-allwarehouses").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	 activeItem=(e,allwarehouse_ids)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(allwarehouse_ids);
			
		}else{
		  var x=selectedIds.indexOf(allwarehouse_ids);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.allwarehouses.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
		
		dataListRender() {
			
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('warehouse_id',this.props.match.params.warehouse_id );
		
		
		axios.post(REST_API_END_POINT+'warehouse/view-warehouse',formData).then((res)=>{
			var data = res.data;
		   
			if(data.warehouses){
				this.setState({
					allwarehouses: data.warehouses,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					allwarehouses: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  render() {
	  let data = this.state.allwarehouses
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
       const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"equipment_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
	  {
		Header: "Name",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Shelf No",
		accessor: "capacity",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	 
	  {
		Header: "Created at",
		accessor: "created_at",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
      {
		Header: "Status",
		accessor: "status",
		Cell: props =>  <Badge color={props.value.statusColor} pill>{props.value.statusid===1?"Active":""}</Badge>
	  },	  
	  {
		Header: "Options",
		accessor: "id",
	   Cell: props => <Button color="primary" size="xs" className="mb-2"  onClick={(e)=>this.toggleModalEdit(e,props.value)} >Edit</Button>
	                  
	  },
       {
		Header: "",
		accessor: "id",
		sortable: false,
		Cell: props => <Fragment> <NavLink to={"/app/warehouse/warehouseview/"+props.value} className="mb-2 btn btn-xs btn-secondary ">View</NavLink></Fragment>
	  }
	];
    return (
      <Fragment>
        <Row>
		
          <Colxx xxs="12">
            <Breadcrumb heading="menu.all-warehouses" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			   <Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx> 
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New 							  
						</Button>																		
						{" "}<Button
						    id="delete-allwarehouses"
							color="primary"
							size="sm"
							className=""
                            onClick={event =>this.deleteItem(event,this.state.selectedIds,2)}								
						  >
							<IntlMessages id="pages.delete" />
					  </Button>
					</div>
					
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
			  </Colxx>
			     <AddWarehouseModal
							modalOpen={this.state.modalOpenAdd}
							toggleModal={this.toggleModalAdd}
							dataListRender={this.dataListRender}
						  />
						  
				<EditWarehouseModal
							modalOpen={this.state.modalOpenEdit}
							toggleModal={this.toggleModalEdit}
							dataListRender={this.dataListRender}
							warehouse_id={this.state.warehouse_id}
						  />		  
						  
			</Row>
		</Card>
      </Fragment>
    );
  }
}
