import React, { Component, Fragment } from "react";
import { Row, FormGroup,Input,
    DropdownItem,
    DropdownMenu, Badge } from "reactstrap";
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import data from "../../data/payments";

class Payments extends Component {
	constructor(props) {
		super(props);
   
		this.state = {
			data:data,
			search: ''
		};
	}
	
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
	  {
		Header: "Title",
		accessor: "title",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Amount",
		accessor: "amount",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Paid On",
		accessor: "date",
		Cell: props => <p className="text-muted">{props.value}</p>
	  }
	];
	
	
    return (
      <Fragment>
        <Row>
          
          <Colxx xxs="12">
             	<div className="mb-4 dropdown-edit">
					
					<FormGroup row>
                        <Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search"
							  placeholder="Search"
							  value={this.state.search}
							  onChange={e => this.setState({search: e.target.value})}
							/>
                      </Colxx>
					  </FormGroup>			
					<ReactTable
					  data={data}
					  columns={dataTableColumns}
					  defaultPageSize={2}
					  filterable={false}
					  showPageJump={false}
					  PaginationComponent={DataTablePagination}
					  showPageSizeOptions={true}
					  defaultSorted={[{id: "send_on",desc: true}]}
					/>
				</div>
          </Colxx>
        </Row>
      </Fragment>
    );
  }
}

export default Payments;
