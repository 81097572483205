import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import ChartComponent, { Chart } from "react-chartjs-2";
import { centerTextPlugin, chartTooltip } from "../../../../components/charts/util";
import { makeColorGradient } from "../../../../helpers/Utils";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

const doughnutChartOptions = {
	legend: {
		position: 'bottom',
		labels: {
			padding: 30,
			usePointStyle: true,
			fontSize: 12
		}
	},
	responsive: true,
	maintainAspectRatio: false,
	title: {
		display: false
	},
	cutoutPercentage: 70,
	layout: {
		padding: {
			bottom: 20
		}
	},
	tooltips: chartTooltip
}

export default class PlantSummary extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			phases:[],
			strains:[],
			rooms:[],
			search: '',
			strain_id: null,
			room_id: null,
			blocking: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.strain_id!=null){
			formData.append('strain_id',this.state.strain_id.value);
		}else{
			formData.append('strain_id',0);
		}
		
		if(this.state.room_id!=null){
			formData.append('room_id',this.state.room_id.value);
		}else{
			formData.append('room_id',0);
		}
		
		axios.post(REST_API_END_POINT+'report/plant-summary',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				
				var phases = data.phases;
				phases = phases.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				phases.unshift({ label:"All", value:0, key:0 });
				
				var strains = data.strains;
				strains = strains.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				strains.unshift({ label:"All", value:0, key:0 });
				
				var rooms = data.rooms;
				rooms = rooms.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				rooms.unshift({ label:"All", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					phases,
					strains,
					rooms,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					strains:[],
					rooms:[],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		var i=1;
		for(let row of reports){
			formated_data.push([
				row.phase,
				row.plant_count
			])
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		const { phases, strains, rooms } = this.state;
		
		const labels = phases.filter(row=>{ return row.value!=0 }).map(row=>row.label);
		const chartColors = phases.filter(row=>{ return row.value!=0 }).map(row=>row.color);
		
		var chart_data_values = [
			reports.filter(row=>{ return row.phase=="Germination" }).length ? reports.filter(row=>{ return row.phase=="Germination" }).map(r=>parseInt(r.plant_count)).reduce((total,r)=>{ return total+r }):0,
			reports.filter(row=>{ return row.phase=="Seedling" }).length ? reports.filter(row=>{ return row.phase=="Seedling" }).map(r=>parseInt(r.plant_count)).reduce((total,r)=>{ return total+r }):0,
			reports.filter(row=>{ return row.phase=="Vegetative" }).length ? reports.filter(row=>{ return row.phase=="Vegetative" }).map(r=>parseInt(r.plant_count)).reduce((total,r)=>{ return total+r }):0,
			reports.filter(row=>{ return row.phase=="Pre-Flowering" }).length ? reports.filter(row=>{ return row.phase=="Pre-Flowering" }).map(r=>parseInt(r.plant_count)).reduce((total,r)=>{ return total+r }):0,
			reports.filter(row=>{ return row.phase=="Flowering" }).length ? reports.filter(row=>{ return row.phase=="Flowering" }).map(r=>parseInt(r.plant_count)).reduce((total,r)=>{ return total+r }):0,
			reports.filter(row=>{ return row.phase=="Drying" }).length ? reports.filter(row=>{ return row.phase=="Drying" }).map(r=>parseInt(r.plant_count)).reduce((total,r)=>{ return total+r }):0
		]
		const chart_data = {
		  labels: labels,
		  datasets: [
			{
			  label: '',
			  borderColor: chartColors,
			  backgroundColor: chartColors,
			  borderWidth: 2,
			  data: chart_data_values
			}
		  ]
		}
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.plant-summary" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="5">
								<Label>Strain</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="strain_id"
									id="strain_id"
									placeholder="All"
									value={this.state.strain_id}						
									options={strains}
									onChange={strain_id => { this.setState({ strain_id }) }}
								/>
							</Colxx>
							<Colxx lg="5">
								<Label>Room</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="room_id"
									id="room_id"
									placeholder="All"
									value={this.state.room_id}						
									options={rooms}
									onChange={room_id => { this.setState({ room_id }) }}
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm>
						{chart_data_values.filter(r=>{ return r>0 }).length ? <Row className="mt-4">
							<Colxx xxs="12" className="report-chart-container">	
								<ChartComponent
									ref={ref => (this.chart_instance = ref && ref.chart_instance)}
									type={"doughnut"}
									plugins={[centerTextPlugin]}
									options={{
									  ...doughnutChartOptions
									}}
									data={chart_data}
								  />
							</Colxx>
						</Row>:""}						
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Phase</th>
												<th>Plant Count</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<h4><b>Total Plants: {reports.length ? reports.map(r=>parseInt(r.plant_count)).reduce((total,r)=>{ return total+r }):0}</b></h4>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
