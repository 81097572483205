import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {	
  CustomInput,
  Button,
  Label,
  Card,
  Input, 
} from "reactstrap";
import swal from 'sweetalert';
import IntlMessages from "../../helpers/IntlMessages";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {Timeline, TimelineEvent} from 'react-event-timeline'

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class RecentActivities extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recent_activities:[],
			page_no:0,
			show_load_more:true,
			blocking:true,
		};
		this.LoadMore = this.LoadMore.bind(this);
	}
	
	componentDidMount() {
		var user_id = localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);

		axios.post(REST_API_END_POINT+'profile/recent-activities',formData).then((res)=>{
			var data = res.data;
			var recent_activities=[];
			var show_load_more = true;
			var page_no = 1;
			if(data.status==1){
				recent_activities=data.recent_activities;				
				if(recent_activities.length<10){
					show_load_more=false;
				}
			}else{
				show_load_more = false;
			}
			this.setState({ 
				recent_activities,
				show_load_more,
				page_no,
				blocking:false,
			});			
		})
	}
	
	LoadMore(){
		this.setState({ blocking:true });
		
		var page_no = this.state.page_no;
		var user_id = localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		formData.append('page_no',page_no);

		axios.post(REST_API_END_POINT+'profile/old-activities',formData).then((res)=>{
			var data = res.data;
			var recent_old_activities=[];
			var show_load_more = true;
			
			if(data.status==1){
				page_no++;
				recent_old_activities=data.recent_activities;				
				if(recent_old_activities.length<10){
					show_load_more=false;
				}
			}else{
				show_load_more = false;
			}
			var recent_activities = this.state.recent_activities;
			for(let recent_old_activity of recent_old_activities){
				recent_activities.push(recent_old_activity);
			}
			
			this.setState({ 
				recent_activities,
				show_load_more,
				page_no,
				blocking:false,				
			});			
		})
	}
  
	render() {
		const user_data = this.props.user_data; 
		const { show_load_more, recent_activities } = this.state;
		
		return( 
			<Fragment>
				<h2>Recent Activities</h2>
				<hr/>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<div className="mb-4 dropdown-edit">
						<Timeline>
							{recent_activities.map((row,index)=>{
								return(<TimelineEvent
									title={moment(row.timestamp*1000).format('MM/DD/YYYY')}
									subtitle={moment(row.timestamp*1000).format('hh:mm a')}
									icon={<i className="simple-icon-layers" />}
									iconStyle={{width: '42px', height: '42px', fontSize: '0.8rem'}}
									contentStyle={{backgroundColor: 'transparent', boxShadow: 'none', whiteSpace: 'pre', fontSize: '0.9rem', color: 'black',marginTop: '0', marginBottom: '0', padding: '0.7em 0em'}}
									bubbleStyle={{background: '#0096ef', border: '2px solid #0580ca', color: '#fff', width: '40px', height: '40px', left: '-5px'}}
									titleStyle={{color: 'black', fontSize: '1rem', fontWeight: '700'}}
									subtitleStyle={{ fontSize: '0.7rem' }}
									key={"recent_activity-"+index}
								>
									{row.message}
								</TimelineEvent>)
							})}
						</Timeline>
						
						{show_load_more ? <div className="mt-3 text-center">
							<Button color="primary" size="sm" onClick={this.LoadMore} >Load Old Activities</Button>
						</div>:""}
					</div>
				</BlockUi>				
			</Fragment>
		);
	}
  
};
