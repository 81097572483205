import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input,  DropdownItem,CustomInput, Badge, DropdownMenu,Label } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddPlantModal from "../../../containers/growhouse/AddPlantModal";
import AddAdditiveModal from "../../../containers/growhouse/AddAdditiveModal";

import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class Allplants extends Component {
	
	constructor(props) {
		super(props);
		
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		 		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenTable: false,
			modalOpenRemoveTableModal: false,
			search: '',			
			selectedIds:[],
			selectAll:0,
			removeIds:[],
			plant:[],
			plant_ids:'',
			room_id:'',
			table_id:'',
			plant_id:'',
			tables:[],
			rooms:[],
			plants:[],
			user_privileges:[],
			selectRooms:[],
		};
	}

	toggleModalAddAdditive = (e,plant_ids=[]) => {
		
		this.setState({
			plant_ids:plant_ids.join(","),
			modalOpenAddAdditive: !this.state.modalOpenAddAdditive
		});
	};
	
	toggleModalTable = () => {
		this.setState({
		  modalOpenTable: !this.state.modalOpenTable
		});
	};
  
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.plants.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    componentDidMount(){		
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
	
    dataListRender() {	

		var plants=[];
        var rooms=[];		
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('room_id',this.state.room_id);
		
		
		
		axios.post(REST_API_END_POINT+'growhouse/all-plants',formData).then((res)=>{
			var data = res.data;
			
			    plants=data.plants;	
				rooms=data.rooms;
			
			
				rooms = rooms.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
		
				plants = plants.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				this.setState({
			        plants: data.plants,
					table_id:data.table_id,
                    selectRooms:rooms,					
					selectAll:0,
					selectedIds:[],
					removeIds:[],					
				});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}	
	
	
    movePhase = (e,selectedIds)  => {
		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return;
		}
       
        var msg="Move to next phase?";
        var success_msg="The plant has been moved to next phase.";
        var failure_msg="Failed to move to next phase.";
       
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("move-phase").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var plant;
					var formData = new FormData();
					formData.append('plant_ids',selectedIds.join(","));
					formData.append('current_user_id',user_id);
					formData.append('facility_id',facility_id);
					
					
					axios.post(REST_API_END_POINT+'growhouse/move-to-next-phase',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("move-phase").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("move-phase").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	

	addadditivePlant = (e,selectedIds)  => {
		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return false;
		}

      this.toggleModalAddAdditive(e,selectedIds);
		
	}

    activeItem=(e,table_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(table_id);
			
		}else{
		  var x=selectedIds.indexOf(table_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.plants.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	render() {
		
		let data = this.state.plants;		
		const { selectRooms } = this.state;
		
		if (this.state.search){
			data = data.filter(row => {
				if(row.group_name==null){
					row.group_name="";
				}
				if(row.strain==null){
					row.strain="";
				}
				return row.barcode.includes(this.state.search) || row.strain.toLowerCase().includes(this.state.search) || row.phase.toLowerCase().includes(this.state.search) || row.group_name.toLowerCase().includes(this.state.search)|| row.room_name.toLowerCase().includes(this.state.search)|| row.table_name.toLowerCase().includes(this.state.search)|| row.birth_date.includes(this.state.search)
			});
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"plants_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Strain",
				accessor: "strain",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Phase",
				accessor: "phase",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Birth Date",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
		   
			{
				Header: "Group",
				accessor: "group_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},    
			{
				Header: "Room",
				accessor: "room_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Table",
				accessor: "table_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
		];
		
    return (
      <Fragment>
        <Row>
			<Colxx xxs="12">
				<Breadcrumb heading="menu.all-plants" match={this.props.match} />	
				<Separator className="mb-5" />
			</Colxx>
        </Row>
		
		<Card className="p-4">
			<Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>				
		    </Row>
			<Row>			
				<Colxx xxs="4" className="ml-4 mb-4">
					<AvForm
						id="roomForm"
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					>
						<Row className="mb-4">
							<Colxx lg="8">
								<AvGroup className="error-t-negative">
									<Label>Rooms<span className="text-danger">*</span></Label>
										<AvField
											type="select"
											name="room_id"
											id="room_id"
											placeholder="--Select Room--"
											validate={{
											  required: {
												value: this.state.specific_room,
												errorMessage: "This field is required."
											  }
											}}
											value={this.state.room_id}
											onChange={(e)=>{
												this.setState({
													room_id:e.target.value
												});
				
											}}
										>
										<option value="" >--Select Room--</option>
										{selectRooms.map(opt=>{
											return <option key={opt.key} value={opt.value} >{opt.label}</option>
										})}
										</AvField>						  
								</AvGroup>
							</Colxx>
							<Colxx lg="4" className="mt-4">
								<Button color="primary"  onClick={this.dataListRender} >
									Go
								</Button>
							</Colxx>	
						</Row>
					</AvForm>
				</Colxx>

				<Colxx xxs="12">	
					<div className="float-sm-right mb-2">
						{" "}{this.state.user_privileges.indexOf(10)!==-1 ?<Button																		 
							  color="primary"
							  size="sm"
							  className="mb-2"
							  onClick={this.toggleModalTable}
							>
							Add Plant							  
						</Button>:""}{" "}
						{this.state.user_privileges.indexOf(11)!==-1 ? <Button
					        id="move-phase"
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={event =>this.movePhase(event,this.state.selectedIds)} 	 								
						>
							Move to Next phase
						</Button>:""}{" "}{this.state.user_privileges.indexOf(144)!==-1 ?<Button
							id="delete-room"
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={(e)=> this.addadditivePlant(e,this.state.selectedIds) }								
						>
							Add Additives
						</Button>:""}
						{" "}
					</div>
					
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
									type="text"
									name="table_search"
									id="table_search"
									placeholder="Search"
									value={this.state.search}
									onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
				</Colxx>
				
				{this.state.user_privileges.indexOf(10)!==-1 ? <AddPlantModal
					modalOpen={this.state.modalOpenTable}
					toggleModal={this.toggleModalTable}
					dataListRender = {this.dataListRender}
					room_id={this.state.room_id}
					table_id={this.state.table_id}
					user_privileges={this.state.user_privileges}
				/>:""}

				{this.state.user_privileges.indexOf(144)!==-1 ?<AddAdditiveModal
					modalOpen={this.state.modalOpenAddAdditive}
					toggleModal={this.toggleModalAddAdditive}
					dataListRender = {this.dataListRender}
					plant_ids={this.state.plant_ids}
				/>:""}
	
			</Row>
		</Card>
      </Fragment>
    );
  }
}
