import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./MenuCart";
import { deleteFromCart } from "../../redux/cart/cartActions";
import TopnavEasyAccess from "./Topnav.EasyAccess";

const HeaderCart = ({
  currency,
  cartData,
  deleteFromCart,
  iconWhiteClass
}) => {
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      	<div className="same-style cart-wrap d-none d-md-block">
			<button className="icon-cart" onClick={e => handleClick(e)}>
			  <i className="iconsminds-shopping-cart" />
			  <span className="count-style">
				{cartData && cartData.length ? cartData.length : 0}
			  </span>
			</button>
			{/* menu cart */}
			<MenuCart
			  cartData={cartData}
			  currency={currency}
			  deleteFromCart={deleteFromCart}
			/>
			
			<TopnavEasyAccess />
		</div>
		<div className="same-style cart-wrap d-block d-md-none mt-2 ml-4 ">
			<Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
			  <i className="iconsminds-shopping-cart" />
			  <span className="count-style">
				{cartData && cartData.length ? cartData.length : 0}
			  </span>
			</Link>
			<TopnavEasyAccess />
		</div>
		<div className="same-style mobile-off-canvas d-block d-md-none">
			<button
			  className="mobile-aside-button"
			  onClick={() => triggerMobileMenu()}
			>
			  <i className="fas fa-bars"></i>
			</button>
		</div>
    </div>
  );
};

HeaderCart.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderCart);
