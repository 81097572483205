import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardBody, Table, Input
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';

import { zeroPad } from "../../helpers/Utils";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

import InvoiceModalContent from "../pointofsale/InvoiceModalContent";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddToAccountModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			order_data: null,
			order_products: null,
			facility_data: null,
			currencies: [],
			accounts: [],
			currencies: [],
			distributions: []
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
	
    }
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'account/data-for-add-to-account',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data,
							order_products:data.order_products,
							facility_data:data.facility_data,
							currencies:data.currencies,
							accounts:data.accounts,
							distributions:data.distributions
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {	
			
			document.getElementById("add-to-account").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("addToAccountForm"));
			
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);
			
			axios.post(REST_API_END_POINT+'account/add-to-account',formData).then((res)=>{
				var data = res.data;
				document.getElementById("add-to-account").disabled=false;
				if(data.status==1){
					swal("Success!",data.msg,'success');
					
					this.props.toggleModal();					
					this.props.dataListRender();
				}else{
					swal("Warning!",data.msg,'warning');
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-to-account").disabled=false;
			});

		}
	}
		
    render() {
		const { order_data, currencies, accounts  } = this.state;
		var current_currency_symbol="$";
		var p_discount=0;
		var total=0;
		var share_total_amount=0;
		if(order_data!=null){
			var current_currency = currencies.find(row=>{ return row.currency_code==order_data.currency_code });			
			if(current_currency!=null){
				current_currency_symbol = current_currency.currency_symbol;
			}
			for(let row of this.state.order_products){
				p_discount+=parseFloat(row.product_discount)+parseFloat(row.coupon_discount);
			}
			total = parseFloat(order_data.total)-parseFloat(p_discount);
		}
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add to Account
				</ModalHeader>
				
				<AvForm
					id="addToAccountForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
				
					<ModalBody >
						<InvoiceModalContent
							ref={el => (this.componentRef = el)}
							{...this.state}
							removeNotice={true}
						/>
						<Table >
							<thead>
								<tr>                       
									<th className="text-muted mb-2">
										ACCOUNT NAME
									</th>
									<th className="text-muted mb-2">
										DISTRIBUTION
									</th>
									<th className="text-muted mb-2">
										SUBTOTAL
									</th>
									<th className="text-muted mb-2">
										SHARE
									</th>
									<th className="text-muted mb-2">
										TOTAL
									</th>
								</tr>
							</thead>
							
							<tbody>
								{accounts.map((row,index)=>{
									share_total_amount = parseFloat(row.share_total_amount);
									return(<tr key={index}>
										<td>{row.account_name}</td>
										<td>{row.distribution_name}</td>
										<td>{current_currency_symbol+""+number_format(parseFloat(row.sub_total),2,'.','')}</td>
										<td>{(row.share_type===1 ? current_currency_symbol:"")+""+number_format(parseFloat(row.share_amount),2,'.','')+(row.share_type===2 ? "%":"")}</td>
										<td>
											{current_currency_symbol+""+number_format(parseFloat(row.share_total_amount),2,'.','')}
											<Input type="hidden" name={"sub_total["+index+"]"} id={"sub_total_"+index} value={row.sub_total} />
											<Input type="hidden" name={"distribution_id["+index+"]"} id={"distribution_id_"+index} value={row.distribution_id} />
											<Input type="hidden" name={"account_id["+index+"]"} id={"account_id_"+index} value={row.account_id} />
											<Input type="hidden" name={"account_share["+index+"]"} id={"account_share_"+index} value={row.share_amount} />
											<Input type="hidden" name={"account_share_type["+index+"]"} id={"account_share_type_"+index} value={row.share_type} />
											<Input type="hidden" name={"account_total["+index+"]"} id={"account_total_"+index} value={row.share_total_amount} />
										</td>
									</tr>)
								})}
							</tbody>
						</Table>
						{share_total_amount!=total ? <p className="text-danger">The share total must be equal to the sub total after discount (${total.toFixed(2)}).</p>:""}
						
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="add-to-account" disabled={share_total_amount!=total} >
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
