import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditSackModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {	
			sacks:null,		
		};	
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){   
        if(this.props.modalOpen){
			let sacks; 					
            if (prevProps.sack_id!== this.props.sack_id && this.props.sack_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('sack_id',this.props.sack_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'processor/get-sack-by-id',formData).then((res)=>{
				    var data = res.data;
					sacks=data.sack;
					this.setState({ 
						sacks						
					});					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
            }
        }
    } 

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-sacks").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("sackForm"));
			formData.append('sack_id',this.props.sack_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'processor/edit-sack',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-sacks").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-sacks").disabled=false;
			});

		}
	} 
	
	onChange = e => {
		switch (e.target.name) {
		  case 'thumbnail':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
  
	render(){
		if(this.state.sacks!=null){
			var sacks=this.state.sacks;
		}else{
			var sacks;
		}
  
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Edit Sock
			</ModalHeader>
			<AvForm
				id="sackForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								value={ sacks?((sacks.name!=null)?sacks.name:''):'' }							
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<Row>
								<Colxx lg="4">
									<AvGroup className="error-t-negative">
										<Label>Sock Capacity</Label>						 
									</AvGroup>
								</Colxx>
								<Colxx lg="4">
									<AvGroup className="error-t-negative">
										<Label>Lbs<span className="text-danger">*</span></Label>
										<AvField
											id="capacity_lbs"
											name="capacity_lbs"
											type="number"
											autoComplete="off"
											value={ sacks?((sacks.lbs!=null)?sacks.lbs:''):'' }
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  },
											  min:{
												value: "0",
												errorMessage: "Value should be greater than 0."
											  }
											}}
										/>
									</AvGroup>
								</Colxx>
								<Colxx lg="4">
									<AvGroup className="error-t-negative">
										<Label>Grams<span className="text-danger">*</span></Label>
										<AvField
											id="capacity_grams"
											name="capacity_grams"
											type="number"
											autoComplete="off"
											value={ sacks?((sacks.grams!=null)?sacks.grams:''):'' }
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  },											  
											  min:{
												value: "0",
												errorMessage: "Value should be greater than 0."
											  },										  
											  max:{
												value: "454",
												errorMessage: "Value should be less than 454."
											  }
											}}
										/>
									</AvGroup>
								</Colxx>
							</Row>
						</Colxx>
					</Row>
					{sacks ? (sacks.thumbnail!=null ? <Row className="mt-4">
						<Colxx lg="12" >
							<div className="d-flex justify-content-start">
								<div className="text-center img-view-wrapper">
									<img src={BASE_URL+'processor/'+sacks.thumbnail} alt="Image" className="img-responsive" />
								</div>
							</div>
						</Colxx>
					</Row>:""):""}
					<Row className="mt-4">
						<Colxx lg="12" className="mb-2">						
							<AvGroup className="error-l-125 error-t-negative">
								<Label>Thumbnail (Recommended size 320x320)</Label>
								<InputGroup className="mb-3">
									<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
									<CustomInput
										type="file"
										id=""
										name="thumbnail"
										accept="image/*"
										onChange={this.onChange}
									/>
								</InputGroup>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-sacks" >
					  <IntlMessages id="customer.save" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default EditSackModal;
