import PropTypes from "prop-types";
import React from "react";
import ShopSearch from "./product/ShopSearch";
import ShopCategories from "./product/ShopCategories";

const ShopSidebar = ({
  uniqueCategories,
  setCategoryParams,
  setSubCategoryParams,
  shopSearchSubmit,
  sideSpaceClass,
  toggle }) => {

  return (

    <div className={`sidebar-style ${sideSpaceClass ? sideSpaceClass : ""}`}>
      {/* shop search */}
      <ShopSearch shopSearchSubmit={shopSearchSubmit} />

      {/* filter by categories */}
      <ShopCategories
        categories={uniqueCategories}
        setCategoryParams={setCategoryParams}
        setSubCategoryParams={setSubCategoryParams}
      />

    </div>
  );
};

ShopSidebar.propTypes = {
  setCategoryParams: PropTypes.func,
  setSubCategoryParams: PropTypes.func,
  uniqueCategories: PropTypes.array,
  sideSpaceClass: PropTypes.string
};

export default ShopSidebar;
