import React, { Component } from "react";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input, FormGroup, Badge
} from "reactstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import printJS from 'print-js';
import { isMobile, isAndroid } from "react-device-detect";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, REST_API_BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

class ViewSackModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {	
			sacks:null,
			sack_packages:[]			
		};	
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){   
        if(this.props.modalOpen){
			if (prevProps.sack_id!== this.props.sack_id && this.props.sack_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('sack_id',this.props.sack_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'processor/view-sack-by-id',formData).then((res)=>{
				    var data = res.data;
					let sacks=data.sack;
					let sack_packages=data.packages;
					this.setState({ 
						sacks,
						sack_packages
					});					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
            }
        }
    } 
	
	printLabel(id){
		printJS(REST_API_BASE_URL+"print-sack-label/"+id);
    }
  
	render(){
		if(this.state.sacks!=null){
			var sacks=this.state.sacks;
		}else{
			var sacks;
		}
		
		let data = this.state.sack_packages;
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search)
			})
		}
	  
		const dataTableColumns = [			  
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <span>{props.original.barcode}{" "}{moment(parseInt(props.original.timestamp)*1000).format('YYYY-MM-DD')===moment().format('YYYY-MM-DD') ? <Badge color="success">New</Badge>:""}</span>
			},
			{
				Header: "Available",
				accessor: "quantity",
				Cell: props => <p className="list-item-heading">{props.value+' Grams'}</p>
			},
			{
				Header: "Filed Date",
				accessor: "timestamp",
				Cell: props => <p className="list-item-heading">{moment(parseInt(props.value)*1000).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Time In Stock",
				accessor: "time_in_room",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Options",
				accessor: "id",
				Cell: props => isMobile || isAndroid ? <a href={REST_API_BASE_URL+"print-sack-label/"+props.value} target="_blank" className="btn btn-sm btn-primary " >Print Barcode</a>:<Button color="primary" size="sm" onClick={()=>{ this.printLabel(props.value) }} >Print Barcode</Button>
			}
		];	
		
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				View Sock
			</ModalHeader>
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Name</Label>
						</Colxx>
						<Colxx lg="9">
							<p className="mb-0">{ sacks?((sacks.name!=null)?sacks.name:''):'' }</p>
						</Colxx>				
					</Row>
					<Row>
						<Colxx lg="3">
							<Label>Sock Capacity</Label>
						</Colxx>
						<Colxx lg="9">
							<p className="mb-0">{ sacks ? (sacks.lbs+" Lbs "+sacks.grams+" Grams "):'' }</p>
						</Colxx>				
					</Row>
					<hr/>
					<Row>
						<Colxx lg="12">
							<div className="mb-4 dropdown-edit small-padd-table">	
								<FormGroup row>
									<Colxx lg="6">
										<Input
											type="text"
											name="package_table_search"
											id="package_table_search"
											placeholder="Search"
											value={this.state.search}
											onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
									</Colxx>
								</FormGroup>
								<ReactTable
									data={data}
									TbodyComponent={CustomTbodyComponent}
									columns={dataTableColumns}
									defaultPageSize={10}
									minRows = {2}
									filterable={false}
									showPageJump={false}
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{id: "id",desc: true}]}
								/>
							</div>
						</Colxx>
					</Row>
			
					
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			
		</Modal>
	  );
  }
};

export default ViewSackModal;
