import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Card,
  Row
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { NavLink } from "react-router-dom";
import data from "../../data/help";

class AddGetStarted extends Component{
  constructor(props) {
    super(props);
	console.log(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }

  
  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  backdrop="static"
		  size="lg"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Welcome to Report OX
		  </ModalHeader>
		  
		  <ModalBody>
				<h4>Pick a topic or search for step-by-step instructions</h4>
				<InputGroup className="mb-3">
                  <InputGroupAddon addonType="prepend"><span className="input-group-text"><i className="iconsminds-magnifi-glass" /></span></InputGroupAddon>
                  <Input name="search" placeholder="Search" />				  
                </InputGroup>
				<ol className="help-target breadcrumb p-0">
					<li className="breadcrumb-item">
						<a className="active" aria-current="page" href="#">
							<span>All</span>
						</a>
					</li>
					<li className="active breadcrumb-item" aria-current="page"><span>People</span></li>
				</ol>
				<div className="help-support-container">
					{data.map((item, index) => {
					  return (						
						<Card className="m-2" key={index}>
							<Row className="p-4">
								<div className="left-content col-1">
									<i className="simple-icon-support" />
								</div>
								<div className="right-content col-11">
									<h5>{item.title}</h5>
									<p>{item.content}</p>
								</div>
							</Row>
						</Card>						
					  );
					})}
					
				</div>
		  </ModalBody>
	  </Modal>
	  );
  }
};

export default AddGetStarted;
