import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class RemoveGroupModal extends Component{
	constructor(props) {
		super(props);    
		this.removeItem = this.removeItem.bind(this);
	}
	
	removeItem(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("delete-group").disabled=true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("RemoveForm"));
			formData.append('group_ids',this.props.group_ids);
			formData.append('current_user_id',user_id);
			formData.append('facility_id',facility_id);

			axios.post(REST_API_END_POINT+'growhouse/delete-groups',formData).then((res)=>{
				var data = res.data;
			
				document.getElementById("delete-group").disabled=false;
				
				var api_message=document.getElementById("api_message");
				
			   if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
				}			
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove(); 
					});
				}, 5000);
				this.props.dataListRender();
				this.props.toggleModal();
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("delete-group").disabled=false;
			});
		}
	}

    render() {

	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Delete Groups
		  </ModalHeader>
			<AvForm
		        id="RemoveForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.removeItem}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Reason<span className="text-danger">*</span></Label>
								<AvField
									id="delete_reason"
									name="delete_reason"
									type="textarea"
									validate={{
										required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
								/>	
							</AvGroup>
						</Colxx>	
					</Row>											
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="delete-group">
						<IntlMessages id="customer.submit"  />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default RemoveGroupModal;
