import React, { Component,Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { Colxx } from "../../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import { getCurrencySymbol, getCustomerWord } from "../../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

const selectData = [
    { label: "Inactive", value: "0", key: 0 },
    { label: "Active", value: "1", key: 1 }
  ];
  
class EditCustomerPointsModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		let currencySymbol = getCurrencySymbol();
		this.state = {		
			selectStatus:{ label: "Active", value: "1", key: 1 },
			customer_point:null,
			currencySymbol,
		};	
	}	
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-customer-point").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("customerpointForm"));
			formData.append('price_point_id',this.props.price_point_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'point-of-sale/edit-price-point',formData).then((res)=>{
				var data = res.data;
				
				var customer_point=this.state.customer_point;
				customer_point.price_points=values.price_points;
				customer_point.discount=values.discount;
				this.setState({ customer_point:customer_point });
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-customer-point").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-customer-point").disabled=false;
			});

		}
	}
   
	componentDidUpdate(prevProps, prevState, snapshot){
	   
        if(this.props.modalOpen){
			let customer_point; 		  
            if (prevProps.price_point_id !== this.props.price_point_id) {
				var formData = new FormData();
				formData.append('price_point_id',this.props.price_point_id);
	
				axios.post(REST_API_END_POINT+'point-of-sale/get-customer-point-by-id',formData).then((res)=>{
					var data = res.data;
					var status=1;
					customer_point=data.points;
					
					selectData.forEach((element,index) => {
                       if(element.value==customer_point.status){
							status=element;
						}
					});
					this.setState({ 
						customer_point,
						status,
					});
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});	
			}
		}
	}
	
	render() {
		if(this.state.customer_point!=null){
			var customer_point=this.state.customer_point;
		}else{
           var customer_point;
		}
	   
		return (
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit {customerWord} Points
				</ModalHeader>
				<AvForm
					id="customerpointForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>{customerWord} Points<span className="text-danger">*</span></Label>
								  <AvField
									id="price_points"
									name="price_points"
									type="number"
									autoComplete="off"
									min='0'
									value={ customer_point?((customer_point.price_points!=null)?customer_point.price_points:''):'' }							
									validate={{
											required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
										
								  />
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-t-negative">
								  <Label>Discount ({this.state.currencySymbol})<span className="text-danger">*</span></Label>
								  <AvField
									id="discount"
									name="discount"
									type="number"
									autoComplete="off"
									min='0'
									value={ customer_point?((customer_point.discount!=null)?customer_point.discount:''):'' }
									validate={{
											required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
										
								  />
								</AvGroup>
							</Colxx>
						</Row>								
						<Row>
							<Colxx lg="12">
								<div className="mt-3">
									<Label >Status</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="status"
										id="status"
										value={this.state.status}						
										options={selectData}
										onChange={status => { this.setState({ status:status }) }}
									/>
								</div>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="update-customer-point">Update</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditCustomerPointsModal;
