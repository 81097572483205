import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import UserLayout from "../../layout/UserLayout";

import login from "./login";
import register from "./register";
import forgotPassword from "./forgot-password";
import resetPassword from "./reset-password";
import accountVerified from "./account-verified";

const User = ({ match,history,location }) => {
	if(typeof history!='undefined'){
		if(history.action=="REPLACE" && history.location.pathname=="/user/login"){
			if(typeof location.state!='undefined'){
				if(typeof location.state.from!='undefined'){
					var current_back = JSON.parse(JSON.stringify(location.state.from));
					var redirect_url = "";
					if(typeof current_back.state!='undefined'){
						if(typeof current_back.state.from!='undefined'){							
							redirect_url = current_back.state.from.pathname;
						}else if(typeof current_back.state.pathname!='undefined'){
							redirect_url = current_back.state.pathname;
						}
					}else if(typeof current_back.pathname!='undefined'){
						redirect_url= current_back.pathname;
					}	
					if(redirect_url!=null && redirect_url!='' && redirect_url!='/app' && redirect_url!='/administrator'){
						localStorage.setItem('redirect_url',redirect_url);
					}
				}
			}
		}
	}
  return (
    <UserLayout>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/login`} />
        <Route path={`${match.url}/login`} component={login} />
        <Route path={`${match.url}/register`} component={register} />
		<Route path={`${match.url}/forgot-password`} component={forgotPassword} />
        <Route path={`${match.url}/reset-password/:reset_code`} component={resetPassword} />
        <Route path={`${match.url}/account-verified/:passed?`} component={accountVerified} />
        <Redirect to="/error" />
      </Switch>
    </UserLayout>
  );
};

export default User;
