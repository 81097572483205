import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
	Row,Card,
  CustomInput,
  Button,
  Modal,Input,
  ModalHeader,
  ModalBody,
  ModalFooter,FormGroup,
  Label,  
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx} from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class EditUserroleModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {		
			userrole:null,
			Permissions:[],
			selected_permissions:[],		
		};	
	}

	componentDidMount() {	
		var Permissions=[];

		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'staff/get-data-for-add-user-role',formData).then((res)=>{
			var data = res.data;
		
			this.setState({
				Permissions:data.staff_permissions,					
			});			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
	}
  
  	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let userrole; 					
            if (prevProps.userrole_id !== this.props.userrole_id && this.props.userrole_id!=0) {				
				var formData = new FormData();
				formData.append('user_role_id',this.props.userrole_id);
				axios.post(REST_API_END_POINT+'staff/get-user-role-by-id',formData).then((res)=>{
					
					var data = res.data;
					
					userrole=data.user_role;
										
					this.setState({ 
						userrole,
						selected_permissions:userrole.user_permissions					
                  	});								
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
 
	render() {

		const { Permissions,index,bind  } = this.state;
	 
		if(this.state.userrole!=null){
			var userrole=this.state.userrole;
		}else{
			var userrole;
		}
  

	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={(e)=>{ this.setState({ selected_permissions:[] }); this.props.toggleModal(e); }}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit User Role
		  </ModalHeader>
		  <AvForm
		   id="userForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>			
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Role Name</Label>
						  <p>{userrole?((userrole.name!=null)?userrole.name:''):''}</p>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Pay Rate</Label>
						  <p>{userrole?((userrole.pay_rate!=null)?userrole.pay_rate:''):''}</p>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Permissions</Label>
						</AvGroup>
					</Colxx>
				</Row>
				{this.state.selected_permissions.length ? <Row>				
					<Colxx lg="12"  >
						<Row>
							{this.state.Permissions.map((row,index)=>{
								if(this.state.selected_permissions.indexOf(row.upriv_id)!=-1){
									return <Colxx lg="4" className="mb-2"  key={index} ><i className="fas fa-check text-primary" />{" "}<span>{row.upriv_name}</span></Colxx>
								}else{ return "" }
							})}
						</Row>
					</Colxx>
				</Row>:""}
			
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" outline onClick={(e)=>{ this.setState({ selected_permissions:[] }); this.props.toggleModal(e); }}>
				  <IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		</AvForm>
	  </Modal>
	  );
  }
};

export default EditUserroleModal;
