import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, CustomInput, Badge } from "reactstrap";
import swal from 'sweetalert';
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddNewCustomerPointsModal from "../../../containers/customer/customer-points/AddNewCustomerPointsModal";
import EditCustomerPointsModal from "../../../containers/customer/customer-points/EditCustomerPointsModal";
import ReactTable from "react-table";
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class CustomerPos extends Component {
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);//bind the function if it invoked to another component
		this.state = {
		  activeFirstTab: "1",		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			data:[],
			search: '',
			points: [],
			selectedIds:[],
			price_point_id:0,
			selectAll: 0
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.points.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        if(status===1){
            msg="Activate the customer points ?";
            success_msg="The customer points has been activated.";
            failure_msg="Failed to activate customer points";
        }else if(status===2){
            msg="Delete the customer points ?";
            success_msg="The customer points has been deleted.";
            failure_msg="Failed to delete customer points";
        }else{
            msg="Deactivate the customer points ?";
            success_msg="The customer points has been deactivated.";
            failure_msg="Failed to deactivate customer points";
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("active-customer-point").disabled=true;
				document.getElementById("inactive-customer-point").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('price_point_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'point-of-sale/delete-price-point',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("active-customer-point").disabled=false;
			            document.getElementById("inactive-customer-point").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("active-customer-point").disabled=false;
			            document.getElementById("inactive-customer-point").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,price_point_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(price_point_id);
			
		}else{
		  var x=selectedIds.indexOf(price_point_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.points.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,price_point_id=0) => {
		if(price_point_id!=0){
			this.setState({
				price_point_id: parseInt(price_point_id),
			});
		}
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};  
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-all-price-points',formData).then((res)=>{
			var data = res.data;
		
			if(data.result){
				this.setState({
					points: data.result,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					points: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    componentDidMount(){  
		this.dataListRender();		   
	}
	  
  
	render() {
		let data = this.state.points
	  
		if (this.state.search) {
			data = data.filter(row => {
				return  row.price_points.includes(this.state.search) || row.discount.includes(this.state.search) || row.status.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Customer Points",
				accessor: "price_points",		
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Discount",
				accessor: "discount",
				Cell: props => <p className="text-muted">${props.value}</p>
			},	  	  
			{
				Header: "Status",
				accessor: "status",
				Cell: props =>  <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
			},
			{
				Header: "",
				accessor: "id",
				sortable: false,
				Cell: props => <Button color="primary" size="sm" onClick={(e)=> this.toggleModalEdit(e,props.value) } >Edit</Button> 
			}
		];
		
    return (
      <Fragment>
        <Row>
			<Colxx xxs="12">
				<Breadcrumb heading="menu.customer-pos" match={this.props.match} />
				<Separator className="mb-5" />
			</Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				
				<Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  <IntlMessages id="pages.add-new" />							  
						</Button>{" "}<Button
							color="primary"
							id="active-customer-point"
							size="sm"
							className=""			
                            onClick={event =>this.deleteItem(event,this.state.selectedIds,1)} >
							<IntlMessages id="pages.active" />
						</Button>{" "}<Button
							color="primary"
							id="inactive-customer-point"
							size="sm"
							className=""
							onClick={event =>this.deleteItem(event,this.state.selectedIds,0)} >
							<IntlMessages id="pages.inactive" />
						</Button>
					</div>
					
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						  </Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
			  </Colxx>
			</Row>
		</Card>
		
		<AddNewCustomerPointsModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
			  />
		<EditCustomerPointsModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				price_point_id={this.state.price_point_id}
				dataListRender={this.dataListRender}
			 />
      </Fragment>
    );
  }
}
