import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddRecipeModal from "../../../containers/processor/AddRecipeModal";
import EditRecipeModal from "../../../containers/processor/EditRecipeModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import moment from 'moment';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class allRecipe extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		
		this.state = {
			modalOpenAdd: false,
			modalOpenEdit: false,
			search: '',
			recipes:[],
			recipe_id:0
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,recipe_id =0) => {
		this.setState({
			recipe_id: parseInt(recipe_id),
			modalOpenEdit: !this.state.modalOpenEdit
		});
	};
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.rooms.forEach(x => {
                newSelected.push(x.recipe_id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

   	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="recipe";
		
		if(selectedIds.length>1){
			msg_word="recipes";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-allrecipes").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('recipe_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'processor/delete-recipes',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-allrecipes").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-allrecipes").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	} 
    
    activeItem=(e,recipe_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(recipe_id);
			
		}else{
		  var x=selectedIds.indexOf(recipe_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.recipes.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	componentDidMount() {
		this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/get-all-recipes',formData).then((res)=>{
			var data = res.data;
		
			if(data.recipes){
				this.setState({
					recipes: data.recipes,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					recipes: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	render() {
		let data = this.state.recipes;
		if (this.state.search) {
			data = data.filter(row => {
				return row.recipe_name.toLowerCase().includes(this.state.search) || row.created_by.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "recipe_id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"recipe_"+rowInfo.original.recipe_id} onChange={event =>this.activeItem(event,rowInfo.original.recipe_id) } checked={this.state.selectedIds.includes(rowInfo.original.recipe_id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "recipe_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Percentage",
				accessor: "percentage",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Created by",
				accessor: "created_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Created at",
				accessor: "created_at",
				Cell: props => <p className="text-muted">{moment(props.value*1000).format("MM/DD/YYYY HH:mm")}</p>
			},
			{
				Header: "Options",
				accessor: "recipe_id",
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalEdit(e,props.value) }}>
									<span className="d-inline-block">Edit</span>
								</DropdownItem>
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.deleteItem(e,[props.value],2) }} >
									<span className="d-inline-block">Delete</span>
								</DropdownItem>								
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];
    return (
		<Fragment>
			<Row>
			  <Colxx xxs="12">
				<Breadcrumb heading="menu.crc-recipes" match={this.props.match} />
				<Separator className="mb-5" />
			  </Colxx>
			</Row>
		
			<Card className="p-4">
				<Row>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
					
					<Colxx xxs="12">
						<div className="float-sm-right">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={this.toggleModalAdd}
								>
								  Add New 							  
							</Button>																		
							{" "}<Button
								color="primary"
								size="sm"
								id="delete-allrecipes"
								onClick={(e)=>{ this.deleteItem(e,this.state.selectedIds,2) }}
							  >
								<IntlMessages id="pages.delete" />
						  </Button>
						</div>
						
						<div className="mb-4 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
									/>
								</Colxx>
							</FormGroup>			
							
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								filterable={false}
								showPageJump={false}
								responsive
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>
						
					</Colxx>
					
					<EditRecipeModal
						modalOpen={this.state.modalOpenEdit}
						toggleModal={this.toggleModalEdit}
						dataListRender={this.dataListRender}
						recipe_id={this.state.recipe_id}
					/>
					
					<AddRecipeModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender={this.dataListRender}
					/>
				</Row>
			</Card>
		</Fragment>
    );
  }
}
