import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";
import { AvForm, AvInput, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  Button,
  Label, InputGroup, InputGroupAddon, CustomInput
} from "reactstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";

import { ReactFormBuilder } from 'react-form-builder2';
import 'react-form-builder2/dist/app.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const formOptions=[
      {
        key: 'TextInput',
        canHaveAnswer: true,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
        name: 'Text Input',
        label: 'Placeholder Label',
        icon: 'fas fa-font',
        field_name: 'text_input_',
      },
	  {
        key: 'EmailInput',
        canHaveAnswer: true,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
        name: 'Email Input',
        label: 'Placeholder Label',
        icon: 'far fa-envelope',
        field_name: 'email_input_',
      },
      {
        key: 'NumberInput',
        canHaveAnswer: true,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
        name: 'Number Input',
        label: 'Placeholder Label',
        icon: 'fas fa-plus',
        field_name: 'number_input_',
      },
	  {
        key: 'Dropdown',
        canHaveAnswer: true,
		canPopulateFromApi:false,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
		canHaveOptionCorrect:false,
		canHaveOptionValue:false,
        name: 'Dropdown',
        icon: 'far fa-caret-square-down',
        label: 'Placeholder Label',
        field_name: 'dropdown_',
        options: [],
      },
      {
        key: 'Checkboxes',
        canHaveAnswer: true,
		canPopulateFromApi:false,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
		canHaveOptionCorrect:false,
		canHaveOptionValue:false,
        name: 'Checkboxes',
        icon: 'far fa-check-square',
        label: 'Placeholder Label',
        field_name: 'checkboxes_',
        options: [],
      },
      {
        key: 'RadioButtons',
        canHaveAnswer: true,
		canPopulateFromApi:false,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
		canHaveOptionCorrect:false,
		canHaveOptionValue:false,
        name: 'Multiple Choice',
        icon: 'far fa-dot-circle',
        label: 'Placeholder Label',
        field_name: 'radiobuttons_',
        options: [],
      },
      {
        key: 'TextArea',
        canHaveAnswer: true,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
        name: 'Multi-line Input',
        label: 'Placeholder Label',
        icon: 'fas fa-text-height',
        field_name: 'text_area_',
      },
      {
        key: 'DatePicker',
        canDefaultToday: false,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
        canReadOnly: false,
        dateFormat: 'MM/dd/yyyy',
        timeFormat: 'hh:mm aa',
        showTimeSelect: false,
        showTimeSelectOnly: false,
        name: 'Date',
        icon: 'far fa-calendar-alt',
        label: 'Placeholder Label',
        field_name: 'date_picker_',
      },
	  {
        key: 'StaffDropdown',
        canHaveAnswer: true,
		canPopulateFromApi:false,
		canHavePageBreakBefore:false,
		canHaveAlternateForm:false,
		canHaveOptionCorrect:false,
		api_url:"/staff/get-active-staffs",
        name: 'Staff Dropdown',
        icon: 'fas fa-user',
        label: 'Placeholder Label',
        field_name: 'staff_',
        options: [],
      }
    ];

export default class CustomFields extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {

		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		return( 
			<Fragment>
				<h2>Custom Fields</h2>
				<hr/>
				<div id="api_custom_fields"></div>				
				
				<div className="mb-4 dropdown-edit tridant-form-builder">					
					<ReactFormBuilder
						url={REST_API_END_POINT+'visitor/get-visitor-form?facility_id='+facility_id}
						toolbarItems={formOptions}
						saveUrl={REST_API_END_POINT+'visitor/update-visitor-form?facility_id='+facility_id}
					/>
				</div>       
			</Fragment>
		);
	}
  
};
