import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Card, CardBody, CardTitle, CardSubtitle, Input, Label, Button} from "reactstrap";

import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import VisitorHistory from "../../../containers/visitors/VisitorHistory";
import DatePicker from "../../../components/common/CustomDateTimePicker";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class Dashboard extends Component {
	constructor(props) {
		super(props);		
		
		this.state = {		 		 
			counts: {
				total:0,
				checkedin:0
			},
			graph_data: null,
			pick_a_date:"3",
			start_date:moment().subtract(30, 'days'),
			end_date:moment()
		};
		this.dataListRender = this.dataListRender.bind(this);
	}
	
	componentDidMount(){  
		this.dataListRender();
	}
	
	dataListRender(){  
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('pick_a_date',this.state.pick_a_date);
		
		if(this.state.pick_a_date=="4"){
			formData.append('start_date',moment(this.state.start_date).format("YYYY-MM-DD"));
			formData.append('end_date',moment(this.state.end_date).format("YYYY-MM-DD"));
		}
		
		axios.post(REST_API_END_POINT+'visitor/get-dashboard-data',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				this.setState({
					counts: data.counts,
					graph_data: data.graph,
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleChangeStartDate = date => {
		this.setState({
			start_date: date
		});
	};
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	};
	
  render() {
    //const {messages} = this.props.intl;
	const { counts, graph_data } = this.state;
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.dashboard" match={this.props.match}/>
					<Separator className="mb-5" />
				</Colxx>
			</Row>
		
			<Row >
				<Colxx xxs="12">
					<div className="float-right">
						<Label>Pick a Date</Label>
						<Input 
							type="select" 
							name="pick_a_date"
							value={this.state.pick_a_date}
							onChange={(e)=>{ this.setState({ pick_a_date:e.target.value },()=>{
								this.dataListRender();
							});  }}
						>
							<option value="1">Today</option>
							<option value="2">Last 7 Days</option>
							<option value="3">Last 30 Days</option>
							<option value="4">Custom</option>
						</Input>
						{this.state.pick_a_date==="4" ? <Row className="mt-2">
							<Colxx sm="5" >	
								<DatePicker
									id="start_date"
									name="start_date"
									selected={this.state.start_date}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
									onChange={this.handleChangeStartDate}
								/>
							</Colxx>
							<Colxx sm="5" >	
								<DatePicker
									id="end_date"
									name="end_date"
									selected={this.state.end_date}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
									onChange={this.handleChangeEndDate}
								/>
							</Colxx>
							<Colxx sm="2" >
								<Button color="primary" size="sm" className="mt-1" onClick={this.dataListRender} >Search</Button>
							</Colxx>
						</Row>:""}
					</div>
				</Colxx>
			</Row>	
			<Row className="icon-cards-row mt-4 mb-3">
				<Colxx xxs="6" sm="6" >		  
					<Card >
						<CardBody className="text-center">
							<i className="simple-icon-people" />
							<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>Total Visitors</p>
							<p className="lead text-center">{counts.total}</p>
						</CardBody>
					</Card>
				</Colxx>
				<Colxx xxs="6" sm="6">		  
					<Card >
						<CardBody className="text-center">
							<i className="simple-icon-people" />
							<p className="card-text font-weight-semibold mb-0" style={{height:"auto"}}>Checked In Visitors</p>
							<p className="lead text-center">{counts.checkedin}</p>
						</CardBody>
					</Card>
				</Colxx>
			</Row>
		
			<Row>
				<Colxx sm="12" xl="12" className="pr-0">
					<div className="mb-4">
						{graph_data!=null ? <VisitorHistory data={graph_data} />:""}
					</div>
				</Colxx>
			</Row>
		</Fragment>
    );
  }
}

export default injectIntl(Dashboard);