const customer = [
{
    vendor_id: 1,
    vendor_name: "Volkan",
    last_name: "Tazegül",
	email_id: "volkan.tazegül@example.com",
    phone: "(320)-301-1432",
    img:"/assets/img/men/96.jpg",
    vendor_address:"Cecilia Chapman 711-2880 Nulla St.",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 2,
    vendor_name: "Leo",
    last_name: "Pierce",
	email_id: "leo.pierce@example.com",
    phone: "0737-232-385",
    img:"/assets/img/men/8.jpg",
    vendor_address:"Mankato Mississippi 96522 (257) 563-7401",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 3,
    vendor_name: "Nanna",
    last_name: "Pedersen",
	email_id: "nanna.pedersen@example.com",
    phone: "43672992",
    img:"/assets/img/women/68.jpg",
    vendor_address:"Frederick Nebraska 20620 (372) 587-2335",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 4,
    vendor_name: "Amelia",
    last_name: "Mercier",
	email_id: "amelia.mercier@example.com",
    phone: "(168)-747-5950",
    img:"/assets/img/women/91.jpg",
    vendor_address:"Calista Wise 7292 Dictum Av.",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 5,
    vendor_name: "Sarah",
    last_name: "Oliver",
	email_id: "sarah.oliver@example.com",
    phone: "0761-814-654",
    img:"/assets/img/women/73.jpg",
    vendor_address:"Theodore Lowe Ap #867-859 Sit Rd.",
    clients_agent:1,
    sellers_agent:1,
	status: 2,
	statusColor: "danger"
  },
  {
    vendor_id: 6,
    vendor_name: "Sheryl",
    last_name: "Carr",
	email_id: "sheryl.carr@example.com",
    phone: "0731-134-689",
    img:"/assets/img/women/75.jpg",
    vendor_address:"Azusa New York 39531",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 7,
    vendor_name: "Miguel",
    last_name: "Sanz",
	email_id: "miguel.sanz@example.com",
    phone: "636-912-353",
    img:"/assets/img/men/71.jpg",
    vendor_address:"San Antonio MI 47096",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 8,
    vendor_name: "Benjamin",
    last_name: "Beck",
	email_id: "benjamin.beck@example.com",
    phone: "0459-316-814",
    img:"/assets/img/men/61.jpg",
    vendor_address:"Tamuning PA 10855",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 9,
    vendor_name: "Emeline",
    last_name: "Charles",
	email_id: "emeline.charles@example.com",
    phone: "(311)-279-7621",
    img:"/assets/img/women/90.jpg",
    vendor_address:"Corona New Mexico 08219",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
   {
    vendor_id: 10,
    vendor_name: "Melany",
    last_name: "Wijngaard",
	email_id: "melany.wijngaard@example.com",
    phone: "(727)-033-9347",
    img:'/assets/img/women/70.jpg',
    vendor_address:'Muskegon KY 12482',
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 11,
    vendor_name: "Alizee",
    last_name: "Leroux",
	email_id: "alizee.leroux@example.com",
    phone: "06-89-25-69-04",
    img:"/assets/img/women/83.jpg",
    vendor_address:"Santa Rosa MN 98804",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 12,
    vendor_name: "Nicole",
    last_name: "Curtis",
	email_id: "nicole.curtis@example.com",
    phone: "081-784-8292",
    img:"/assets/img/women/41.jpg",
    vendor_address:"Aaron Hawkins 5587 Nunc.",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 13,
    vendor_name: "Becky",
    last_name: "Holmes",
	email_id: "becky.holmes@example.com",
    phone: "081-326-3849",
    img:"/assets/img/women/19.jpg",
    vendor_address:"Bandera South Dakota 45149",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 14,
    vendor_name: "Mathew",
    last_name: "Richards",
	email_id: "mathew.richards@example.com",
    phone: "0457-239-253",
    img:"/assets/img/men/15.jpg",
    vendor_address:"Woodruff SC 49854",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 15,
    vendor_name: "James",
    last_name: "Warren",
	email_id: "james.warren@example.com",
    phone: "0789-598-522",
    img:"/assets/img/men/43.jpg",
    vendor_address:"Bethlehem Utah 02913",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 16,
    vendor_name: "Kasper",
    last_name: "Klessens",
	email_id: "kasper.klessens@example.com",
    phone: "(958)-321-7397",
    img:"/assets/img/men/2.jpg",
    vendor_address:"Leilani Boyer 557-6308 Lacinia Road",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 17,
    vendor_name: "Mia",
    last_name: "Li",
	email_id: "mia.li@example.com",
    phone: "168-828-2650",
    img:"/assets/img/women/28.jpg",
    vendor_address:"Ap #285-7193 Ullamcorper Avenue",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 18,
    vendor_name: "Mustafa",
    last_name: "Hamzaoğlu",
	email_id: "mustafa.hamzaoğlu@example.com",
    phone: "(645)-838-2501",
    img:"/assets/img/men/54.jpg",
    vendor_address:"Fort Dodge GA 20783",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 19,
    vendor_name: "Alma",
    last_name: "Thomsen",
	email_id: "alma.thomsen@example.com",
    phone: "85041366",
    img:"/assets/img/women/55.jpg",
    vendor_address:"Daly City Ohio 90255",
    clients_agent:1,
    sellers_agent:1,
	status: 1,
	statusColor: "success"
  },
  {
    vendor_id: 20,
    vendor_name: "Cassandre",
    last_name: "Leroux",
	email_id: "cassandre.leroux@example.com",
    phone: "06-09-96-38-18",
    img:"/assets/img/women/25.jpg",
    vendor_address:"Gardena Colorado 37547",
    clients_agent:1,
    sellers_agent:1,
	status: 2,
	statusColor: "danger"
  }
]

export default customer
