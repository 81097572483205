import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Paginator from 'react-hooks-paginator';
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import ShopSidebar from "../components/front-end/ShopSidebar";
import ShopTopbar from "../components/front-end/ShopTopbar";
import ShopProducts from "../components/front-end/ShopProducts";

import {REST_API_END_POINT,dataEncryptKey } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { seo } from "../helpers/Utils";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class Shop extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		  sortType:'asc', 
		  sortValue:'default',
		  category_id:'',
		  search:'',
		  productCount:0,
		  offset:0,
		  currentPage:1,
		  pageLimit:9,
		  currentData:[],
		  uniqueCategories:[]
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.getSortParams = this.getSortParams.bind(this);
		this.sortProducts = this.sortProducts.bind(this);
		this.setCategoryParams = this.setCategoryParams.bind(this);
		this.shopSearchSubmit = this.shopSearchSubmit.bind(this);
		this.setCurrentPage = this.setCurrentPage.bind(this);
		this.setOffset = this.setOffset.bind(this);
	}
  
	componentDidMount() {
		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var user_id = localStorage.getItem('user_id');
		if(typeof user_id=='undefined' || user_id==null){
			localStorage.setItem('redirect_url','/shop');
		}
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id){
			this.dataListRender();
		}else{
			window.location='/';
		}
	}
	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);		
		formData.append('category_id',this.props.match.params.id);		
		if(this.state.search!==''){
			formData.append('search',this.state.search);			
		}
		formData.append('pageLimit',this.state.pageLimit);
		formData.append('currentPage',this.state.currentPage);
		
		axios.post(REST_API_END_POINT+'front-end/shop',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){	
				var uniqueCategories = data.categories;
				
				let current = uniqueCategories.find(r=>{ return r.category_id==this.props.match.params.id });
				if(current){
					seo({
						title: current.name+' | '+projectTitle,
						metaDescription: 'Shop with us'
					});
				}
		
				this.setState({
			        productCount: parseInt(data.product_count),
			        currentData: data.products,
			        uniqueCategories
				},()=>{
					this.sortProducts();
				});
			}else{
				this.setState({
					currentData: [],
					uniqueCategories: []
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	getSortParams = (sortType, sortValue) => {		
		this.setState({ sortType, sortValue },()=>{
			this.sortProducts();
		})
	}
	
	sortProducts = () => {
		const sortValue = this.state.sortValue;
		var currentData = this.state.currentData;
		if(sortValue=='default'){
			currentData = currentData.sort((a, b) => parseInt(a.timestamp) < parseInt(b.timestamp) ? 1 : (parseInt(a.timestamp) > parseInt(b.timestamp) ? -1 : 0))
		}else if(sortValue=='priceLowToHigh'){
			currentData = currentData.sort((a, b) => parseFloat(a.price) > parseFloat(b.price) ? 1 : (parseFloat(a.price) < parseFloat(b.price) ? -1 : 0))
		}else if(sortValue=='priceHighToLow'){
			currentData = currentData.sort((a, b) => parseFloat(a.price) < parseFloat(b.price) ? 1 : (parseFloat(a.price) > parseFloat(b.price) ? -1 : 0))
		}
		currentData = JSON.parse(JSON.stringify(currentData));
		this.setState({ currentData })
	}
	
	setCategoryParams = (category_id) => {
		this.setState({ category_id },()=>{
			this.dataListRender();
		})
	}
	
	shopSearchSubmit(event, errors, values) {
		if(errors.length==0){
			this.setState({ search:values.shop_search },()=>{
				this.dataListRender();
			})
		}
	}
	
	setCurrentPage(currentPage) {
		this.setState({ currentPage },()=>{
			this.dataListRender();
		})
	}
	
	setOffset(offset) {
		this.setState({ offset })
	}

	render() {
		var product_category = "";
		let current = this.state.uniqueCategories.find(r=>{ return r.category_id==this.props.match.params.id });
		if(current!=null){
			product_category = current.name
		}
		return (
			<Fragment>
				<ToastProvider placement="bottom-left">
					<div className="main-pages-layout">
						<main>
							<HomeHeader />
								<div className="shop-area pt-5 pb-5 main-shop-listing">
									
									<div className="container product-by-category-list">
										{product_category!="" ? <h3 className="my-3 h2 font-weight-bold text-center">{product_category}</h3>:""}
										<div className="row">
											<div className="col-lg-6 ">
												{/* shop sidebar */}
												<ShopSidebar uniqueCategories={this.state.uniqueCategories} setCategoryParams={this.setCategoryParams} shopSearchSubmit={this.shopSearchSubmit} sideSpaceClass="mr-30"/>
											</div>
											<div className="col-lg-6 mt-4">
												{/* shop topbar default */}
												<ShopTopbar getFilterSortParams={this.getSortParams} productCount={this.state.productCount} sortedProductCount={this.state.currentData.length} />
											</div>
											<div className="col-lg-12">
												{/* shop page content default */}
												<ShopProducts layout={'grid three-column'} products={this.state.currentData} />

												{/* shop product pagination */}
												{this.state.productCount>9 ? <div className="pro-pagination-style text-center mt-30">
													<Paginator
														totalRecords={this.state.productCount}
														pageLimit={this.state.pageLimit}
														pageNeighbours={2}
														setOffset={this.setOffset}
														currentPage={this.state.currentPage}
														setCurrentPage={this.setCurrentPage}
														pageContainerClass="mb-0 mt-0"
														pagePrevText="«"
														pageNextText="»"
													/>
												</div>:""}
											</div>
										</div>
									</div>
								</div>
							<HomeFooter />
						</main>	
					</div>
				</ToastProvider>
			</Fragment>	
		);
	}
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(Shop);
