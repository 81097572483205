import React, { Component, Fragment } from "react";
import {
  Row,FormGroup, Input,
  CustomInput,
  Button,
  Modal,Nav, NavItem, TabContent, TabPane,Card,
  CardBody,CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DataTablePagination from "../../components/DatatablePagination";
import classnames from "classnames";

import AllPackages from "../../containers/pointofsale/AllPackages";
import FinishedProducts from "../../containers/pointofsale/FinishedProducts";

import { NavLink } from "react-router-dom";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT, themeColor} from "../../constants/defaultValues";

import ReactTable from "react-table";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const qty_types = {
				'0' : 'mg', 
				'1' : 'g', 
				'2' : 'oz', 
				'3' : 'units', 
				'4' : 'mg', 
				'5' : 'mg', 
				'6' : 'mg', 
				'7' : 'mg', 
				'8' : '',
				'9' : 'units'
			};
			
export default class GetPackagesModal extends Component{
	constructor(props) {
		super(props);
		
        this.state = {	
			activeFirstTab: "1",
			product:null,
			all_packages:[],
			finished_products:[],
			blocking:true,
		};		
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){		
			if (prevProps.product_id!== this.props.product_id && this.props.product_id!=0) {
				this.setState({ blocking:true })
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('product_id',this.props.product_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'inventory-transfer/get-packages',formData).then((res)=>{					
					var data = res.data;
				    if(data.status==1){
						var product=data.product;
						var all_packages=data.all_packages;
						var finished_products=data.finished_products;
						
						all_packages = all_packages.map(row=>{
							var qty_type='Units';
							var quantity = (parseFloat(row['quantity'])).toFixed(2);
								
							if(product['qty_type']==1){
								qty_type='Grams';
								quantity = row['quantity'];
							}else if(product['qty_type']==2){
								qty_type='Lbs';	
								quantity = (parseFloat(row['quantity'])/LBS_CONSTANT).toFixed(2);
							}
							
							row.available = quantity+" "+qty_type;
							return row;
						});	

						finished_products = finished_products.map(row=>{							
							var qty_type=qty_types[row['variation_type']];
							var capacity = parseFloat(row['capacity']).toFixed(2);
								
							if(row['variation_type']==2){	
								if(row['capacity']==0.125){
									capacity = '1/8';
								}else if(row['capacity']==0.25){
									capacity = '1/4';
								}else if(row['capacity']==0.5){
									capacity = '1/2';
								}else{
									capacity=row['capacity'];
								}
							}
							
							row.available = row['variation_name']+" ("+capacity+" "+qty_type+")";
							return row;
						});	
						
						this.setState({
							product,
							all_packages,
							finished_products,
							blocking:false
						});
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					this.setState({ blocking:false })
				});
            }
        }
    }
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
		  this.setState({
			activeFirstTab: tab
		  });
		}
	}

	render() {
		var product_type=1;
		if(this.state.product!=null){
			product_type=parseInt(this.state.product.product_type);
		}
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Choose Package
				</ModalHeader>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
					<ModalBody>					
						<Row>
							<Colxx lg="12" className="page-tabs">
								<CardHeader>
									<Nav tabs className="card-header-tabs ">
										{product_type==1 ? <NavItem>
											<NavLink
												className={classnames({
												  active: this.state.activeFirstTab === "1",
												  "nav-link": true
												})}
												onClick={() => {
												  this.toggleFirstTab("1");
												}}
												to="#"
											>
												All Packages
											</NavLink>
										</NavItem>:""}
										<NavItem>
											<NavLink
												className={classnames({
												  active: this.state.activeFirstTab === (product_type==1 ? "2":"1"),
												  "nav-link": true
												})}
												onClick={() => {
												  this.toggleFirstTab((product_type==1 ? "2":"1"));
												}}
												to="#"
											>
												Finished Products
											</NavLink>
										</NavItem>
									</Nav>
								</CardHeader>
								
								<TabContent activeTab={this.state.activeFirstTab}>
									{product_type==1 ? <TabPane tabId="1">
										<Row>
											<Colxx sm="12">
												<CardBody className="p-0 small-padd-table">
													<AllPackages 
														all_packages={this.state.all_packages}
														product={this.state.product}
														addToCart={this.props.addToCart}
													/>
												</CardBody>
											</Colxx>
										</Row>
									</TabPane>:""}
									
									<TabPane tabId={product_type==1 ? "2":"1"}>
										<Row>
											<Colxx sm="12">
												<CardBody className="p-0 small-padd-table">
													<FinishedProducts
														finished_products={this.state.finished_products}
														product={this.state.product}
														addToCart={this.props.addToCart}
													/>
												</CardBody>
											</Colxx>
										</Row>
									</TabPane>
								</TabContent>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</BlockUi>
			</Modal>
		);
	}	
};
