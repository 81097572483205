import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  Button,
  Label 
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';

export default class ProfileData extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("submit-profile").disabled=true;
			
			var user_id = localStorage.getItem('user_id');			
			var formData = new FormData(document.getElementById("profileForm"));
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'profile/update-user',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_profile");
				api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				if(data.status==1){
					document.getElementById("user_profile_name").innerHTML=values.first_name+" "+values.last_name;
					this.props.updateUser(values);
				}
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("submit-profile").disabled=false;
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("submit-profile").disabled=false;
			});

		}
	} 
  
	render() {
		const user_data = this.props.user_data  
		
		
		return( 
			<Fragment>
				<h2>Profile Info</h2>
				<hr/>
				<div id="api_message_profile"></div>				
				
				<div className="mb-4 dropdown-edit">
					<AvForm
						id="profileForm"
						ref={c => (this.form = c)}
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					  >
						<Row className="mt-2">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>{user_data!=null ? (user_data.user_type==1 || user_data.user_type==2 ? "First":""):""} Name<span className="text-danger">*</span></Label>
									<AvField
										id="first_name"
										name="first_name"
										type="text"
										autoComplete="off"
										value={user_data!=null ? user_data.first_name:""}
										validate={{
										  required: {
											value: true,
											errorMessage: "Please enter the first name"
										  },
										  pattern: {
											value: "^[a-zA-Z ,.'-]+$",
											errorMessage: "Invalid first name"
										  },
										  minLength: {
											value: 2,
											errorMessage:
											  "Your first name must be between 2 and 100 characters"
										  },
										  maxLength: {
											value: 100,
											errorMessage:
											  "Your first name must be between 2 and 100 characters"
										  }
										}}
									/>
								</AvGroup>
							</Colxx>
							
							{user_data!=null ? (user_data.user_type==1 || user_data.user_type==2 ? <Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Last Name<span className="text-danger">*</span></Label>
									<AvField
										id="last_name"
										name="last_name"
										type="text"
										autoComplete="off"
										value={user_data!=null ? user_data.last_name:""}
										validate={{
										  required: {
											value: true,
											errorMessage: "Please enter the last name"
										  },
										  pattern: {
											value: "^[a-zA-Z ,.'-]+$",
											errorMessage: "Invalid last name"
										  },
										  minLength: {
											value: 2,
											errorMessage:
											  "Your last name must be between 2 and 100 characters"
										  },
										  maxLength: {
											value: 100,
											errorMessage:
											  "Your last name must be between 2 and 100 characters"
										  }
										}}
									/>
								</AvGroup>
							</Colxx>:""):""}
							
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Email<span className="text-danger">*</span></Label>
									<AvField
										id="email"
										name="email"
										type="email"
										autoComplete="off"
										value={user_data!=null ? user_data.email:""}
										validate={{
										  required: {
											value: true,
											errorMessage: "Please enter the email"
										  }
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label><IntlMessages id="customer.phone" /><span className="text-danger">*</span></Label>
									<AvField
										id="phone"
										name="phone"
										type="text"
										autoComplete="off"
										value={user_data!=null ? user_data.phone:""}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											pattern: {
												value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
												errorMessage: "Invalid phone number"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							
						</Row>
						<Row className="mt-2 mb-4">	
							<Colxx xxs="6">
								<Button	id="submit-profile"	color="primary" >Submit</Button>					
							</Colxx>					
						</Row>
					</AvForm>
				</div>       
			</Fragment>
		);
	}
  
};
