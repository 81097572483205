import React from "react";
import {
  Row,
  Card,
  CardBody,
  CardImg,
  CardText,
  Badge,
  DropdownItem, DropdownMenu,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { Colxx } from "../../components/common/CustomBootstrap";
import { BASE_URL } from "../../constants/defaultValues";

import ItemDropdown from "../../components/customer/ItemDropdown";

const UserCard = ({ user, toggleModalEdit, toggleModalChangePassword, activateRow, resendConfirmEmail }) => {
	var company_logo=BASE_URL+'company/avatar.png';
	
	if(user.company_logo!=null && typeof user.company_logo!=='undefined' ){		
		company_logo=BASE_URL+"company/"+user.company_logo;		
	}
  return (
    <Colxx sm="6" lg="4" xl="3" className="mb-4" key={user.id}>
        <Card className="h-100">
          <div className="position-relative">
            <div className="w-sm-100 text-center">
              <NavLink to={"/administrator/user-detail/"+user.id} ><CardImg top className="company-logo-user" alt={user.company_name} src={company_logo} /></NavLink>
            </div>
            <Badge
              color={parseInt(user.status)==1?"success": (parseInt(user.status)===0 ? "primary":"danger")}
              pill
              className="position-absolute badge-top-left"
			  style={{fontSize:'100%'}}
            >
              {parseInt(user.status)===1?"Active":(parseInt(user.status)===0 ? "Unverified":"Inactive")}
            </Badge>
          </div>
          <CardBody className="image-view-card-body">
            <Row>
              <Colxx xxs="12" className="mb-3">
                <h4 className="image-view-title"><div className="w-sm-100"><NavLink to={"/administrator/user-detail/"+user.id} >{user.company_name}</NavLink></div></h4>
                <CardText className="text-muted mb-0">
                  Name: {user.first_name+" "+user.last_name}<br/>
                  Email: <a href={"mailto:"+user.email} >{user.email}</a><br/>
                  Phone: {user.phone} <br/><br/>
				  <strong className="text-primary" style={{fontSize:'1.2rem'}}>Membership : {typeof user.subscriber_plan_name!='undefined' && user.subscriber_plan_name!=null ? user.subscriber_plan_name:"Trial"}</strong>
                </CardText>	
				<div className="action-btns subscriber-action">
				  <ItemDropdown item={<DropdownMenu right>
					<DropdownItem>
						<NavLink to={"/administrator/user-detail/"+user.id} className="d-block" ><i className="simple-icon-eye" />{" "}<span className="d-inline-block">View</span></NavLink>
					</DropdownItem>
					<DropdownItem className="cursor-pointer" onClick={(e) => toggleModalEdit(e,user.id)}>
						<i className="simple-icon-pencil" />{" "}<span className="d-inline-block">Edit</span>
					</DropdownItem>
					<DropdownItem onClick={(e) => toggleModalChangePassword(e,user.id)}>
						<i className="simple-icon-lock" />{" "}<span className="d-inline-block">Change Password</span>
					</DropdownItem>
					<DropdownItem onClick={(e) => resendConfirmEmail(user.id)}>
						<i className="simple-icon-envelope" />{" "}<span className="d-inline-block">Resend Email</span>
					</DropdownItem>
					<DropdownItem onClick={() => activateRow(user.id,parseInt(user.status))}>
						<i className={parseInt(user.status)==3?"simple-icon-check":"simple-icon-ban"} />{" "}<span className="d-inline-block">{parseInt(user.status)==3?"Activate":"Deactivate"}</span>
					</DropdownItem>
					{/*<DropdownItem onClick={() => activateRow(user.id,2)}>
					 	<i className="simple-icon-trash" />{" "}<span className="d-inline-block">Delete</span>
					</DropdownItem> */}
				  </DropdownMenu>} />
              </div>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
    </Colxx>
  );
};

export default React.memo(UserCard);
