import React from "react";
import {
  Row,
  Card,
  CardBody,
  CardSubtitle,
  CardImg,
  CardText,
  CustomInput,
  Badge,
  Button
} from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../../components/common/CustomBootstrap";

const ImageListView = ({ user, isSelect, collect, onCheckItem }) => {
  return (
    <Colxx sm="6" lg="4" xl="3" className="mb-3" key={user.id}>
      <ContextMenuTrigger id="menu_id" data={user.id} collect={collect}>
        <Card
          onClick={event => onCheckItem(event, user.id)}
          className={classnames({
            active: isSelect
          })}
        >
          <div className="position-relative">
            <NavLink to={`?p=${user.id}`} className="w-40 w-sm-100">
              <CardImg top alt={user.title} src={user.img} />
            </NavLink>
            <Badge
              color={user.statusColor}
              pill
              className="position-absolute badge-top-left"
            >
              {user.status===1?"Active":"Inactive"}
            </Badge>
          </div>
          <CardBody className="image-view-card-body">
            <Row>
              <Colxx xxs="2">
                <CustomInput
                  className="item-check mb-0"
                  type="checkbox"
                  id={`check_${user.id}`}
                  checked={isSelect}
                  onChange={() => {}}
                  label=""
                />
              </Colxx>
              <Colxx xxs="10" className="mb-3">
                <CardSubtitle className="image-view-title"><NavLink to="/app/editprofile" className="w-16 w-sm-100">{user.first_name}{" "}{user.last_name}</NavLink></CardSubtitle>
                <CardText className="text-muted text-small mb-0 font-weight-light">
                  {user.email}<br/>
                  {user.phone}
                </CardText>
				<div className="action-btns mt-3 w-100 w-sm-100">
					<Button className="mr-1" size="sm" color="success" title="Edit"><i className="simple-icon-pencil"></i></Button>
					<Button className="mr-1" size="sm" color="danger" title="Deactivate" ><i className="simple-icon-ban"></i></Button>
					<Button className="mr-1" size="sm" color="danger" title="Delete" ><i className="simple-icon-trash"></i></Button>
				</div>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
      </ContextMenuTrigger>
    </Colxx>
  );
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ImageListView);
