import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import {REST_API_END_POINT} from "../../constants/defaultValues";
import axios from 'axios';
import {ChromePicker} from 'react-color';

class EditPartnerModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {			
		startTime: null,
		startDate: null,
		background: null
		};	

		
  }

  typeService= async (values)=>{

	let singleType =  this.props.singleType;
	const color = this.state.background ==null ? singleType.bg_color : this.state.background;

	var formData = new FormData();
	formData.append('partner_type_id',singleType.id);
	formData.append('name',values.skill_name);
	formData.append('bg_color',color);

	formData.append('user_id',1);

	try {
	   const url = `${REST_API_END_POINT}/edit-partner-type`  
	   const res = await axios.post(url,formData);
	   console.log(res.data);
	   console.log(res.data.status)
	   if(res.data.status==1){
		   this.props.reloadType();
	   }
    }catch (error) {
	console.log(Object.keys(error), error.message); 
    }
  }
	
  
   handleSubmit(event, errors, values) {
    console.log(errors);
	console.log(values);
	
    if (errors.length === 0) {
	  this.typeService(values);	
	  this.props.toggleModal();
	  this.setState({background:null});
    }
  }
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };

  handleChangeComplete = (color) => {

	this.setState({ background: color.hex });
	
  };

  cancelClick(){
	this.props.toggleModal()
	this.setState({ background: null });

  }


  


  render() {
	  let singleType =  this.props.singleType;	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Partner Type
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="skill_name"
							name="skill_name"
							type="text"
							value = {singleType.name}
							validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}	
						  />
						  <Label>Color<span className="text-danger">*</span></Label>
						  {/* singleType.bg_color */}
						  <ChromePicker
						    color={ this.state.background == null ? singleType.bg_color : this.state.background  }
						    onChangeComplete={ this.handleChangeComplete }
						   />
						</AvGroup>
					</Colxx>
					
				</Row>								
																			
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="customer.save" />
			</Button>
			<Button color="secondary" outline onClick={()=>this.cancelClick()}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditPartnerModal;
