import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon, Input, Media
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, REST_API_BASE_URL} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"],
    ["clean"]
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image"
];

class AddTaskModal extends Component{

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			textQuillStandart:''
		};
	}
	
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-task").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("taskForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('task_id',this.props.task_id);
			formData.append('description',this.state.textQuillStandart);

			axios.post(REST_API_END_POINT+'task/add-job-task',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-task").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-task").disabled=false;
			});

		}
	}

      render() {
      
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Task
		  </ModalHeader>
		  <AvForm
                id="taskForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Task Title<span className="text-danger">*</span></Label>
							  <AvField
								id="title"
								name="title"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "Please enter the title"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "Your title must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "Your title must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<Label>Task Outline<span className="text-danger">*</span></Label>
							<ReactQuill
							  theme="snow"
							  value={this.state.textQuillStandart}
							  onChange={this.handleChangeQuillStandart}
							  modules={quillModules}
							  formats={quillFormats}
							/>	
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<Label className="d-block mt-3"><strong>Assign Users</strong></Label>
							{this.props.task_members.map((r,index)=>{
								return(<Media key={index}>
									<Media left className="mr-2">
										<Media object width="50" src={REST_API_BASE_URL+"uploads/users/"+r.profile_pic} alt={r.username} />
									</Media>
									<Media body>
										<p className="mb-1">{r.username}</p>
										<Input type="checkbox" name={"user_"+r.ID} value="1" className="ml-2" />									
									</Media>
								</Media>)
							})}							
						</Colxx>
					</Row>
					  
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-task" >
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddTaskModal;
