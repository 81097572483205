import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { zeroPad } from "../../../helpers/Utils";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

const equipment_types = [
	{ label:"--Select Equipment Type--", value:"0", key:0 },
    { label: "Extractor", value:"1", key: 0 },
	/*{ label:"CRC", value:"6", key: 5},*/
	{ label:"Oven", value:"4", key: 1},
	/*{ label:"MCP", value:"5", key: 2},
	{ label:"Evaporator", value:"2", key: 3},
	{ label:"Distillator", value:"3", key: 4},*/  
];

export default class ProductionProcess extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			authorize_bys:[],
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			equipment_type: { label:"--Select Equipment Type--", value:"0", key:0 },
			authorize_by: { label:"--Select Authority--", value:0, key:0 },
			blocking: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}

		if(this.state.equipment_type!=null){
			formData.append('equipment_type',this.state.equipment_type.value);
		}else{
			formData.append('equipment_type',0);
		}
		
		if(this.state.authorize_by!=null){
			formData.append('authorize_by',this.state.authorize_by.value);
		}else{
			formData.append('authorize_by',0);
		}

		
		axios.post(REST_API_END_POINT+'report/production-process',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				var authorize_bys = data.authorize_bys;
				authorize_bys = authorize_bys.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				authorize_bys.unshift({ label:"--Select Authority--", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					authorize_bys,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					authorize_bys: [],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', 'pdf', 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		for(let row of reports){
			let process_time ='';
			if(row.is_crc=="0"){
				let H = Math.floor(parseFloat(row['process_time'])/3600);
				let i = parseInt((parseFloat(row['process_time'])/60)%60);
				let s = parseFloat(row['process_time'])%60;
				
				process_time = zeroPad(H,2)+":"+zeroPad(i,2)+":"+zeroPad(s,2);
			}
			
			formated_data.push([
				row.equipment_name,
				row.batch_type,
				row.batch_uid,
				row.yield,
				row.is_crc=="0" ? moment(row.start_time*1000).format('MM/DD/YYYY HH:mm'):"",
				row.is_crc=="0" ? moment(row.end_time*1000).format('MM/DD/YYYY HH:mm'):"",
				process_time,
				row.operated_by
			])
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		const { authorize_bys } = this.state;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.production-process" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="6">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							
							<Colxx lg="6">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>Equipment Type</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="equipment_type"
									id="equipment_type"
									placeholder="All"
									value={this.state.equipment_type}						
									options={equipment_types}
									onChange={equipment_type => { this.setState({ equipment_type }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label>Authorized By</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="authorize_by"
									id="authorize_by"
									placeholder="All"
									value={this.state.authorize_by}						
									options={authorize_bys}
									onChange={authorize_by => { this.setState({ authorize_by }) }}
								/>
							</Colxx>
							<Colxx lg="4">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Equipment Name</th>
												<th>Batch Type</th>											  
												<th>Batch UID</th>
												<th>Yield (%)</th>
												<th>Start Time</th>
												<th>End Time</th>											  
												<th>Process Time</th>
												<th>Operated By</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
