import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DataTablePagination from "../../components/DatatablePagination";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
const selectData = [
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }
  ];
 
const selectShelf = [
    { label: "Warehouse 1", value:"11", key: 0 },
	{ label:"--Select Warehouse--", value:"", key: 1},
    
  ];
  const selectWarehouse = [
   
	
	{ label:"4x4 Shelfs", value:"1", key: 0},
	{ label:"New Shelf 4x7", value:"2", key: 1},
	{ label:"Shelf new", value:"4", key: 2},
	{ label:"Shelf A", value:"5", key: 3},
    
  ];

class EditVisitorsModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.state = {	
		startTime: null,
		startDate: null,
		 selectShelfType:{ label: "Warehouse 1", value:"11", key: 0 },
		 selectWarehouseType:{ label: "--Select Warehouse--", value:"11", key: 0 },
		};
  }
	handleChangeDate = date => {
		this.setState({
		  startDate: date
		});
	  };
	  handleChangeTime = time => {
		this.setState({
		  startTime: time
		});
	  };
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  onChange = e => {
    switch (e.target.name) {
      case 'license_img':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}

const dataTableColumns = [
		
	  {
		Header: "",
		accessor: "",
		width:50,
		Cell: props => <FormGroup><CustomInput type="checkbox" id="exampleCustomCheckbox"/></FormGroup>
	  },
	  {
		Header: "ID",
		accessor: "id",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Reason",
		accessor: "reason",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
      {
		Header: "Patient Name",
		accessor: "patientname",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
      {
		Header: "Employee Name",
		accessor: "employeename",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
      {
		Header: "Requested Date",
		accessor: "requesteddate",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
      {
		Header: "Reason",
		accessor: "reason",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
      {
		Header: "Visit Time In",
		accessor: "visittimein",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
      {
		Header: "Visit Time Out",
		accessor: "visittimeout",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },

	];
 
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Visitors
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
		 <Row>
		
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name</Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							readOnly="readonly"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Agency Name</Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							readOnly="readonly"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<AvForm
					className="av-tooltip tooltip-right-bottom mt-2"
					onSubmit={this.handleSubmit}
					>
					<Label><IntlMessages id="Address" /></Label>	&nbsp;&nbsp;
					<AvGroup className="error-t-negative">
					  <AvField
						id="address"
						name="address"
						type="textarea"
						validate={{
							  required: {
								value: true, 
								errorMessage: "This field is required."
							  }
							}}
						value={this.state.textarea_content}
						placeholder=""
						rows="3"
						validate={{
						  minLength: {
							value: 10,
							errorMessage:
							  "Your message must have minimum of 10 characters"
						  },
						}}
					  />
					</AvGroup>					
				</AvForm>
        <Row>
		<Colxx lg="12">
		<AvGroup className="error-t-negative">
		<label>ID Proof</label><br/>
		<Button color="primary" >
			  <IntlMessages id="View" />
			</Button>
			</AvGroup>
        </Colxx>			
        </Row>		
	 <Row>
		<Colxx lg="12">
		<AvGroup className="error-t-negative">
		<label>Signature</label><br/>
		<Button color="primary" >
			  <IntlMessages id="View" />
			</Button>
			</AvGroup>
        </Colxx>			
        </Row>	
        <Row>
		<Colxx lg="6">
		<AvGroup className="error-t-negative">
						  <Label>Phone</Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							readOnly="readonly"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
		</Colxx>
        <Colxx lg="6">
		<AvGroup className="error-t-negative">
						  <Label>Email</Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							readOnly="readonly"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
		                </Colxx>
                    </Row><br/>
                        <ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={2}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>					
							
		  </ModalBody>
		  </AvForm>
	  </Modal>
	  );
  }
};

export default EditVisitorsModal;
