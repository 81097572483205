import React, { Component } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";

import {Card, CardBody} from "reactstrap";
import {
	Collapse,
	Input,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import RemoveSectionInspection from "../../components/template/RemoveSectionInspection";
import AddInspector from "../../containers/calendar/AddInspector";
import AddClient from "../../containers/calendar/AddClient";
import AddAgent from "../../containers/calendar/AddAgent";
import AddContractors from "../../containers/calendar/AddContractors";
import AddEventModal from "../../containers/calendar/AddEventModal";

import {	
  CustomInput,  
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TooltipItemText from "../../components/common/TooltipItemText";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const selectData = [
    { label: "-Select Inspections-", value: "select", key: 0 },
    { label: "New Inspections", value: "new", key: 1 }
  ];
 const selectData1 = [
    { label: "-Select Agreements-", value: "selectagreement", key: 0 },
    { label: "New Agreements", value: "newagreement", key: 1 }
  ];
  const selectData2 = [
    { label: "-Select Agreements-", value: "selectagreement", key: 0 },
    { label: "New Agreements", value: "newagreement", key: 1 }
  ];
class AddInspection extends Component{
  constructor(props) {
    super(props);
	
	this.toggleFirstTab = this.toggleFirstTab.bind(this);
    this.toggleSecondTab = this.toggleSecondTab.bind(this);
	
    this.state = {
		modalOpenAdd: false,
		modalOpenClient: false,
		modalOpenAgent: false,
		modalOpenContractors: false,
		modalOpenEvent: false,
		 activeFirstTab: "1",
		activeSecondTab: "1",
		startTime: null,
		startDate: null,
		collapse: false,
		accordion: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		collapse1: false,
		accordion1: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		collapse2: false,
		accordion2: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		collapse3: false,
		accordion3: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		collapse4: false,
		accordion4: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],

	 
    };
  } 
  
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalClient = () => {
		this.setState({
		  modalOpenClient: !this.state.modalOpenClient
		});
	};
	toggleModalAgent = () => {
		this.setState({
		  modalOpenAgent: !this.state.modalOpenAgent
		});
	};
	toggleModalContractors = () => {
		this.setState({
		  modalOpenContractors: !this.state.modalOpenContractors
		});
	};
	toggleModalEvent = () => {
		this.setState({
		  modalOpenEvent: !this.state.modalOpenEvent
		});
	};
	
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }
  handleChangeDate = date => {
    this.setState({
      startDate: date
    });
  };
  handleChangeTime = time => {
    this.setState({
      startTime: time
    });
  };
  toggleAccordion = tab => {
		const prevState = this.state.accordion;
		const state = prevState.map((x, index) => (tab === index ? !x : false));
		this.setState({
		  accordion: state
		});
    };
	toggleAccordion1 = tab => {
		const prevState = this.state.accordion1;
		const state = prevState.map((x, index) => (tab === index ? !x : false));
		this.setState({
		  accordion1: state
		});
    };
	toggleAccordion2 = tab => {
		const prevState = this.state.accordion2;
		const state = prevState.map((x, index) => (tab === index ? !x : false));
		this.setState({
		  accordion2: state
		});
    };
	toggleAccordion3 = tab => {
		const prevState = this.state.accordion3;
		const state = prevState.map((x, index) => (tab === index ? !x : false));
		this.setState({
		  accordion3: state
		});
    };
	toggleAccordion4 = tab => {
		const prevState = this.state.accordion4;
		const state = prevState.map((x, index) => (tab === index ? !x : false));
		this.setState({
		  accordion4: state
		});
    };
	
	 showRevertChanges = e =>{
		document.getElementById('show_services').style.display='block';
		document.getElementById('show_revert').style.display='none';
		
  }
  showServicesChanges = e =>{
		document.getElementById('show_revert').style.display='block';
		document.getElementById('show_services').style.display='none';
		
  }
  showFee = e =>{
		document.getElementById('show_fee').style.display='block';
		
  }
   showEmailClient = e =>{
	if(e.currentTarget.textContent==='Show Email Settings'){
		 e.currentTarget.innerHTML='Hide Email Settings';
		document.getElementById('show_emailclient').style.display='block';
	}else{
		 e.currentTarget.innerHTML='Show Email Settings';
		document.getElementById('show_emailclient').style.display='none';
		
	}
  }
  showEmailAgent = e =>{
	if(e.currentTarget.textContent==='Show Email Settings'){
		 e.currentTarget.innerHTML='Hide Email Settings';
		document.getElementById('show_emailagent').style.display='block';
	}else{
		 e.currentTarget.innerHTML='Show Email Settings';
		document.getElementById('show_emailagent').style.display='none';
		
	}
  }
  showEmailContractors = e =>{
	if(e.currentTarget.textContent==='Show Email Settings'){
		 e.currentTarget.innerHTML='Hide Email Settings';
		document.getElementById('show_emailcontractors').style.display='block';
	}else{
		 e.currentTarget.innerHTML='Show Email Settings';
		document.getElementById('show_emailcontractors').style.display='none';
		
	}
  }
  showEmailInspectors = e =>{
	if(e.currentTarget.textContent==='Show Email Settings'){
		 e.currentTarget.innerHTML='Hide Email Settings';
		document.getElementById('show_emailinspectors').style.display='block';
	}else{
		 e.currentTarget.innerHTML='Show Email Settings';
		document.getElementById('show_emailinspectors').style.display='none';
		
	}
  }
  showAddAgent = e =>{
	if(e.currentTarget.textContent==='Add new agent'){
		 e.currentTarget.innerHTML='Search for an Agent';
		document.getElementById('show_client').style.display='block';
		document.getElementById('show_search_agent').style.display='none';
	}else{
		 e.currentTarget.innerHTML='Add new agent';
		document.getElementById('show_client').style.display='none';
		document.getElementById('show_search_agent').style.display='block';
	}
  }
  
  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  backdrop="static"
		  size="md"
		>
		
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Inspection
		  </ModalHeader>
		  
		  <ModalBody className="with-switch">
			
				<Row >																							
					<Colxx lg="12" className="mb-2 mt-2" >							
						<div lg="12" >
							<Row>
								<Colxx lg="12">																
									<Colxx lg="12" className="mb-4">
										<Card>
											<CardBody className="p-0">					  
												<Button
												  block
												  color="outline-primary"
												  className="text-left font-weight-bold rounded-0"
												  onClick={() => this.toggleAccordion(0)}
												  aria-expanded={this.state.accordion[0]}
												>
												<i className="iconsminds-calendar-4" /> Appointment Information
												</Button>
												<Collapse isOpen={this.state.accordion[0]}>
													<div className="p-4">
														<span className="h6 ">Location</span>
														<Row className="mb-4">																
															<Colxx lg="6">
																<Input
																	type="text"
																	name="text"
																	id="exampleText"
																	placeholder="Address"/>
															</Colxx>
															<Colxx lg="6">						
																<Input
																	type="text"
																	name="text"
																	id="exampleText"
																	placeholder="Zip/Postal Code"/>
															</Colxx>																	
														</Row>
														<span className="h6 mt-4">Date and Time</span>
														<Row>
															<Colxx lg="6">
																<div className="form-group has-float-label">
																	<DatePicker
																		className="mb-5"
																		selected={this.state.startDate}
																		onChange={this.handleChangeDate}
																		placeholderText="Date"
																		dateFormat="LLL"
																	  />
																	  
																</div>
															</Colxx>
															<Colxx lg="6">
																<div className="form-group has-float-label">
																	<DatePicker
																		className="mb-5"
																		placeholderText="Time"
																		selected={this.state.startTime}
																		onChange={this.handleChangeTime}
																		showTimeSelect
																		showTimeSelectOnly
																		timeIntervals={15}
																		dateFormat="hh:mm a"
																		timeCaption="Time"
																	  />
																	  
																</div>
															</Colxx>																		
														</Row>	
														<span className="h6 mt-4">Additional Information</span>															
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-4" >
																<Label>													  
																	<Switch
																		className="custom-switch custom-switch-primary im-inline-block"
																		defaultChecked={true}
																		onChange={this.switchChange}											
																		/>
																	<span className="h6 ml-4">Confirmed Inspection</span>
																</Label>&nbsp;&nbsp;&nbsp;&nbsp;																	
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-4" >
																<Label>													  
																	<Switch
																		className="custom-switch custom-switch-primary im-inline-block"
																		defaultChecked={true}
																		onChange={this.switchChange}											
																		/>
																	<span className="h6 ml-4">Disable Automated Notifications</span>
																</Label>&nbsp;&nbsp;&nbsp;&nbsp;
																
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-4" >
																<Label>													  
																	<Switch
																		className="custom-switch custom-switch-primary im-inline-block"
																		defaultChecked={true}
																		onChange={this.switchChange}											
																		/>
																	<span className="h6 ml-4">Occupied?</span>
																</Label>
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-4" >
																<Label>													  
																	<Switch
																		className="custom-switch custom-switch-primary im-inline-block"
																		defaultChecked={true}
																		onChange={this.switchChange}											
																		/>
																	<span className="h6 ml-4">Utilities On?</span>
																</Label>							
															</Colxx>
														</Row>
														<span className="h6 mb-3">Inspection notes</span>
														<Row>
															<Colxx lg="12" className="mb-2 mt-2">																										 																				
																<AvForm
																		className="av-tooltip tooltip-right-bottom"
																		onSubmit={this.handleSubmit}
																		>
																		<AvGroup className="error-t-negative">
																		  <AvField
																			name="text_message"
																			type="textarea"
																			value={this.state.textarea_content}
																			placeholder="These notes are private and only visible by you - use for access notes, entry codes, personal notes etc."
																			rows="3"
																			validate={{
																			  minLength: {
																				value: 10,
																				errorMessage:
																				  "Your message must have minimum of 10 characters"
																			  },
																			}}
																		  />
																		</AvGroup>					
																</AvForm>			
															</Colxx>				
														</Row>
													</div>
												</Collapse>			
											</CardBody>
										</Card>
										<Card>
											<CardBody className="p-0">
												<Button
												  block
												  color="outline-primary"
												  className="text-left font-weight-bold rounded-0"
												   onClick={() => this.toggleAccordion(1)}
												  aria-expanded={this.state.accordion[1]}
												>
												  <i className="iconsminds-handshake" /> People
												</Button>
												<Collapse isOpen={this.state.accordion[1]}>
													<div className="p-4">
														<Row>																							
															<Colxx lg="8" className="mb-2">								
																<span className="h5 ">Inspector</span>	&nbsp; &nbsp;																													
															</Colxx>					
														</Row>															
														<Row >																		
															<Colxx lg="6"  className="mb-2">
																<Input
																	type="text"
																	name="text"
																	id="exampleText"
																	placeholder="Search for an inspector"/>
															</Colxx>
															<Colxx lg="6" className="mb-2" >							
																<Button																		 
																	  color="primary"
																	  className="btn-shadow btn-lg"
																	  onClick={this.toggleModalAdd}
																	>
																	  Add new inspector								  
																</Button>																		
																   <AddInspector
																	modalOpen={this.state.modalOpenAdd}
																	toggleModal={this.toggleModalAdd}
																  />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">INSPECTOR</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
																
															</Colxx>
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">INSPECTOR</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-2" >							
																<Button
																	  size="sm"
																	  color="primary"
																	  className="btn-shadow"
																	  onClick={(e) => this.showEmailInspectors(e)}
																	>
																	  Show Email Settings								  
																	</Button>
															</Colxx>					
														</Row>
														<Row id="show_emailinspectors" style={{display:'none'}}>																							
															<Colxx lg="12" className="mb-2 mt-2" >							
																<div lg="12" >
																	<Row>
																		<Colxx lg="12">						
																			<h3 className="font-weight-bold text-uppercase ml-3">
																				<i className="iconsminds-gears" />
																				{" "}Communication Settings			
																			</h3>
																			<Colxx lg="12" className="mb-4">																																																																																																															
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion4(0)}
																					   aria-expanded={this.state.accordion4[0]}
																					>
																					  <i className="iconsminds-file" />  Report
																					</Button>
																					<Collapse isOpen={this.state.accordion4[0]}>
																					<div className="p-4">																																																																																																																										
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Notify me when the client views the report.</span>
																								</Label>
																							</Colxx>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<p className="text-muted"> Inspector will receive an email when the buyer is viewing the report</p>
																							</Colxx>
																						</Row>
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Notify me when the agent views the report.</span>
																								</Label>
																							</Colxx>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<p className="text-muted"> Inspector will receive an email when the agent is viewing the report</p>
																							</Colxx>
																						</Row>
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Notify me when the contractors views the report.</span>
																								</Label>
																							</Colxx>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<p className="text-muted"> Inspector will receive an email when the contractors is viewing the report</p>
																							</Colxx>
																						</Row>
																					  </div>
																					</Collapse>
																				  </CardBody>
																				</Card>																																																																							 								 		
																			</Colxx>
																		</Colxx>
																	</Row>							
																</div>
															</Colxx>					
														</Row>
														
														
														<hr/>
														<Row>																							
															<Colxx lg="8" className="mb-2">								
																<span className="h5 ">Client</span>	&nbsp; &nbsp;																													
															</Colxx>					
														</Row>															
														<Row >																		
															<Colxx lg="6"  className="mb-2">
																<Input
																	type="text"
																	name="text"
																	id="exampleText"
																	placeholder="Search for a client"/>
															</Colxx>
															<Colxx lg="6" className="mb-2" >							
																<Button																		 
																	  color="primary"
																	  className="btn-shadow btn-lg"
																	  onClick={this.toggleModalClient}
																	>
																	  Add new client								  
																</Button>																		
																   <AddClient
																	modalOpen={this.state.modalOpenClient}
																	toggleModal={this.toggleModalClient}
																  />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">CLIENT</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
																
															</Colxx>
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">CLIENT</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-2" >							
																<Button
																	  size="sm"
																	  color="primary"
																	  className="btn-shadow"
																	  onClick={(e) => this.showEmailClient(e)}
																	>
																	  Show Email Settings								  
																	</Button>
															</Colxx>					
														</Row>
														<Row id="show_emailclient" style={{display:'none'}}>																							
															<Colxx lg="12" className="mb-2 mt-2" >							
																<div lg="12" >
																	<Row>
																		<Colxx lg="12">						
																			<h3 className="font-weight-bold text-uppercase ml-3">
																				<i className="iconsminds-gears" />
																				{" "}Communication Settings			
																			</h3>
																			<Colxx lg="12" className="mb-4">
																				<Card>
																					<CardBody className="p-0">					  
																						<Button
																						  block
																						  color="outline-primary"
																						  className="text-left font-weight-bold rounded-0"
																						  onClick={() => this.toggleAccordion1(3)}
																						  aria-expanded={this.state.accordion1[3]}
																						>
																						  <i className="iconsminds-calendar-4" /> Scheduling
																						</Button>
																						<Collapse isOpen={this.state.accordion1[3]}>
																							<div className="p-4">
																								<Row>
																									<Colxx lg="9" className="mb-2">
																										<Label>													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<span className="h6 ml-4">Inspection confirmation email for clients</span>
																										</Label>
																									</Colxx>
																								</Row>																																																					
																								<hr/>
																								
																								<Row>
																									<Colxx lg="9" className="mb-2">
																										<Label>													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<span className="h6 ml-4">Send client a confirmation email on reschedule</span>
																										</Label>
																									</Colxx>																
																								</Row>																																																
																							</div>
																						</Collapse>			
																					 </CardBody>
																				</Card>
																				<Card>
																					<CardBody className="p-0">
																						<Button
																						  block
																						  color="outline-primary"
																						  className="text-left font-weight-bold rounded-0"
																						   onClick={() => this.toggleAccordion1(4)}
																						  aria-expanded={this.state.accordion1[4]}
																						>
																						  <i className="iconsminds-handshake" /> Inspection Agreements & Fees
																						</Button>
																							<Collapse isOpen={this.state.accordion1[4]}>
																								<div className="p-4">
																									<Row>
																										<Colxx lg="9" className="mb-2">
																											<Label>													  
																												<Switch
																													className="custom-switch custom-switch-primary im-inline-block align-top"
																													defaultChecked={true}
																													onChange={this.switchChange}											
																												  />
																												<div className="col-lg-10 h6 d-inline-block ml-4">Send client an email when an Inspection Agreement is added later (ie after initial booking).</div>
																											</Label>
																										</Colxx>																	
																									</Row>
																									<Row>
																										<Colxx lg="9" className="mb-2">
																											<Label>													  
																												<Switch
																													className="custom-switch custom-switch-primary im-inline-block align-top"
																													defaultChecked={true}
																													onChange={this.switchChange}											
																												  />
																												<p className="col-lg-10 h6 d-inline-block ml-4">Send client an email when a new fee is added (ie after initial booking).</p>
																											</Label>
																										</Colxx>																	
																									</Row>
																								  </div>
																							</Collapse>
																					</CardBody>
																				</Card>
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion1(5)}							
																					   aria-expanded={this.state.accordion1[5]}
																					>
																					  <i className="iconsminds-left-1" />  Pre-Inspection
																					</Button>
																						<Collapse isOpen={this.state.accordion1[5]}>
																							<div className="p-4">
																								<h4 className="text-uppercase">Emails</h4>						
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_e1}
																												onChange={pre_client_number_selectedOption_e1 => { this.setState({ pre_client_number_selectedOption_e1 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_e1}
																												onChange={pre_client_type_selectedOption_e1 => { this.setState({ pre_client_type_selectedOption_e1 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_e2}
																												onChange={pre_client_number_selectedOption_e2 => { this.setState({ pre_client_number_selectedOption_e2 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_e2}
																												onChange={ pre_client_type_selectedOption_e2 => { this.setState({ pre_client_type_selectedOption_e2 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Three</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={ this.state.pre_client_number_selectedOption_e3 }
																												onChange={ pre_client_number_selectedOption_e3 => { this.setState({ pre_client_number_selectedOption_e3 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={ this.state.pre_client_type_selectedOption_e3 }
																												onChange={ pre_client_type_selectedOption_e3 => { this.setState({ pre_client_type_selectedOption_e3 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<hr/>
																								<h4 className="text-uppercase">Text</h4>						
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_t1}
																												onChange={pre_client_number_selectedOption_t1 => { this.setState({ pre_client_number_selectedOption_t1 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_t1}
																												onChange={pre_client_type_selectedOption_t1 => { this.setState({ pre_client_type_selectedOption_t1 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_t2}
																												onChange={pre_client_number_selectedOption_t2 => { this.setState({ pre_client_number_selectedOption_t2 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_t2}
																												onChange={ pre_client_type_selectedOption_t2 => { this.setState({ pre_client_type_selectedOption_t2 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>	
																								<p>Text messages send within 10 minutes of your target time. We recommend putting your phone number in the text messages you automatically send so that agents and buyers can respond to you.<br/>
																									We'll forward responses to our system number to you automatically if we can. We'll use this number to deliver responses: 
																									<NavLink to="/app/dashboard" className="btn btn-link btn-xs" >
																										Update your direct number here.
																									</NavLink>
																								</p>
																							  </div>
																						</Collapse>
																					</CardBody>
																				</Card>																																																		
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion1(6)}
																					   aria-expanded={this.state.accordion1[6]}
																					>
																					  <i className="iconsminds-file" />  Report
																					</Button>
																					<Collapse isOpen={this.state.accordion1[6]}>
																					<div className="p-4">
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										defaultChecked={true}
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Report ready: Client email</span>
																								</Label>
																							</Colxx>														
																						</Row>																																		
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Report ready: Client text</span>
																								</Label>
																							</Colxx>
																						</Row>																						
																						<hr/>																																												
																					  </div>
																					</Collapse>
																				  </CardBody>
																				</Card>									
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion1(7)}
																					   aria-expanded={this.state.accordion1[7]}
																					>
																					  <i className="iconsminds-left-1" />  Post-Inspection
																					</Button>
																					<Collapse isOpen={this.state.accordion1[7]}>
																						<div className="p-4">
																							<h4 className="text-uppercase">Emails</h4>						
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_e1}
																											onChange={pre_client_number_selectedOption_e1 => { this.setState({ pre_client_number_selectedOption_e1 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_e1}
																											onChange={pre_client_type_selectedOption_e1 => { this.setState({ pre_client_type_selectedOption_e1 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_e2}
																											onChange={pre_client_number_selectedOption_e2 => { this.setState({ pre_client_number_selectedOption_e2 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_e2}
																											onChange={ pre_client_type_selectedOption_e2 => { this.setState({ pre_client_type_selectedOption_e2 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Three</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={ this.state.pre_client_number_selectedOption_e3 }
																											onChange={ pre_client_number_selectedOption_e3 => { this.setState({ pre_client_number_selectedOption_e3 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={ this.state.pre_client_type_selectedOption_e3 }
																											onChange={ pre_client_type_selectedOption_e3 => { this.setState({ pre_client_type_selectedOption_e3 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<hr/>
																							<h4 className="text-uppercase">Text</h4>						
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_t1}
																											onChange={pre_client_number_selectedOption_t1 => { this.setState({ pre_client_number_selectedOption_t1 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_t1}
																											onChange={pre_client_type_selectedOption_t1 => { this.setState({ pre_client_type_selectedOption_t1 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>															
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_t2}
																											onChange={pre_client_number_selectedOption_t2 => { this.setState({ pre_client_number_selectedOption_t2 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_t2}
																											onChange={ pre_client_type_selectedOption_t2 => { this.setState({ pre_client_type_selectedOption_t2 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>															
																							</Row>	
																							<p>Text messages send within 10 minutes of your target time. We recommend putting your phone number in the text messages you automatically send so that agents and buyers can respond to you.<br/>
																								We'll forward responses to our system number to you automatically if we can. We'll use this number to deliver responses: 
																								<NavLink to="/app/dashboard" className="btn btn-link btn-xs" >
																									Update your direct number here.
																								</NavLink>
																							</p>
																						  </div>
																					</Collapse>
																				  </CardBody>
																				</Card>																																												 								 		
																			</Colxx>
																		</Colxx>
																	</Row>							
																</div>
															</Colxx>					
														</Row>	
																																										
														<hr/>
														<Row>																							
															<Colxx lg="8" className="mb-2">								
																<span className="h5 ">Agent</span>	&nbsp; &nbsp;																													
															</Colxx>					
														</Row>															
														<Row >																		
															<Colxx lg="6"  className="mb-2">
																<Input
																	type="text"
																	name="text"
																	id="exampleText"
																	placeholder="Search for an agent"/>
															</Colxx>
															<Colxx lg="6" className="mb-2" >							
																<Button																		 
																	  color="primary"
																	  className="btn-shadow btn-lg"
																	  onClick={this.toggleModalAgent}
																	>
																	  Add new agent								  
																</Button>																		
																   <AddAgent
																	modalOpen={this.state.modalOpenAgent}
																	toggleModal={this.toggleModalAgent}
																  />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">AGENT</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
																
															</Colxx>
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">AGENT</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-2" >							
																<Button
																	  size="sm"
																	  color="primary"
																	  className="btn-shadow"
																	  onClick={(e) => this.showEmailAgent(e)}
																	>
																	  Show Email Settings								  
																	</Button>
															</Colxx>					
														</Row>
														<Row id="show_emailagent" style={{display:'none'}}>																							
															<Colxx lg="12" className="mb-2 mt-2" >							
																<div lg="12" >
																	<Row>
																		<Colxx lg="12">						
																			<h3 className="font-weight-bold text-uppercase ml-3">
																				<i className="iconsminds-gears" />
																				{" "}Communication Settings			
																			</h3>
																			<Colxx lg="12" className="mb-4">
																				<Card>
																					<CardBody className="p-0">					  
																						<Button
																						  block
																						  color="outline-primary"
																						  className="text-left font-weight-bold rounded-0"
																						  onClick={() => this.toggleAccordion2(0)}
																						  aria-expanded={this.state.accordion2[0]}
																						>
																						  <i className="iconsminds-calendar-4" /> Scheduling
																						</Button>
																						<Collapse isOpen={this.state.accordion2[0]}>
																							<div className="p-4">
																								<Row>
																									<Colxx lg="9" className="mb-2">
																										<Label>													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<span className="h6 ml-4">Inspection confirmation email for agents</span>
																										</Label>
																									</Colxx>
																								</Row>																																																					
																								<hr/>
																								
																								<Row>
																									<Colxx lg="9" className="mb-2">
																										<Label>													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<span className="h6 ml-4">Send agents a confirmation email on reschedule</span>
																										</Label>
																									</Colxx>																
																								</Row>																																																
																							</div>
																						</Collapse>			
																					 </CardBody>
																				</Card>
																				<Card>
																					<CardBody className="p-0">
																						<Button
																						  block
																						  color="outline-primary"
																						  className="text-left font-weight-bold rounded-0"
																						   onClick={() => this.toggleAccordion2(1)}
																						  aria-expanded={this.state.accordion2[1]}
																						>
																						  <i className="iconsminds-handshake" /> Inspection Agreements & Fees
																						</Button>
																							<Collapse isOpen={this.state.accordion2[1]}>
																								<div className="p-4">
																									<Row>
																										<Colxx lg="9" className="mb-2">
																											<Label>													  
																												<Switch
																													className="custom-switch custom-switch-primary im-inline-block align-top"
																													defaultChecked={true}
																													onChange={this.switchChange}											
																												  />
																												<div className="col-lg-10 h6 d-inline-block ml-4">Send agents an email when an Inspection Agreement is added later (ie after initial booking).</div>
																											</Label>
																										</Colxx>																	
																									</Row>
																									<Row>
																										<Colxx lg="9" className="mb-2">
																											<Label>													  
																												<Switch
																													className="custom-switch custom-switch-primary im-inline-block align-top"
																													defaultChecked={true}
																													onChange={this.switchChange}											
																												  />
																												<p className="col-lg-10 h6 d-inline-block ml-4">Send agents an email when a new fee is added (ie after initial booking).</p>
																											</Label>
																										</Colxx>																	
																									</Row>
																								  </div>
																							</Collapse>
																					</CardBody>
																				</Card>
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion2(2)}							
																					   aria-expanded={this.state.accordion2[2]}
																					>
																					  <i className="iconsminds-left-1" />  Pre-Inspection
																					</Button>
																						<Collapse isOpen={this.state.accordion2[2]}>
																							<div className="p-4">
																								<h4 className="text-uppercase">Emails</h4>						
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_e1}
																												onChange={pre_client_number_selectedOption_e1 => { this.setState({ pre_client_number_selectedOption_e1 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_e1}
																												onChange={pre_client_type_selectedOption_e1 => { this.setState({ pre_client_type_selectedOption_e1 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_e2}
																												onChange={pre_client_number_selectedOption_e2 => { this.setState({ pre_client_number_selectedOption_e2 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_e2}
																												onChange={ pre_client_type_selectedOption_e2 => { this.setState({ pre_client_type_selectedOption_e2 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Three</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={ this.state.pre_client_number_selectedOption_e3 }
																												onChange={ pre_client_number_selectedOption_e3 => { this.setState({ pre_client_number_selectedOption_e3 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={ this.state.pre_client_type_selectedOption_e3 }
																												onChange={ pre_client_type_selectedOption_e3 => { this.setState({ pre_client_type_selectedOption_e3 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<hr/>
																								<h4 className="text-uppercase">Text</h4>						
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_t1}
																												onChange={pre_client_number_selectedOption_t1 => { this.setState({ pre_client_number_selectedOption_t1 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_t1}
																												onChange={pre_client_type_selectedOption_t1 => { this.setState({ pre_client_type_selectedOption_t1 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_t2}
																												onChange={pre_client_number_selectedOption_t2 => { this.setState({ pre_client_number_selectedOption_t2 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_t2}
																												onChange={ pre_client_type_selectedOption_t2 => { this.setState({ pre_client_type_selectedOption_t2 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>	
																								<p>Text messages send within 10 minutes of your target time. We recommend putting your phone number in the text messages you automatically send so that agents and buyers can respond to you.<br/>
																									We'll forward responses to our system number to you automatically if we can. We'll use this number to deliver responses: 
																									<NavLink to="/app/dashboard" className="btn btn-link btn-xs" >
																										Update your direct number here.
																									</NavLink>
																								</p>
																							  </div>
																						</Collapse>
																					</CardBody>
																				</Card>																																																		
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion2(3)}
																					   aria-expanded={this.state.accordion2[3]}
																					>
																					  <i className="iconsminds-file" />  Report
																					</Button>
																					<Collapse isOpen={this.state.accordion2[3]}>
																					<div className="p-4">
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										defaultChecked={true}
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Report ready: Agents email</span>
																								</Label>
																							</Colxx>														
																						</Row>																																		
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Report ready: Agents text</span>
																								</Label>
																							</Colxx>
																						</Row>																						
																						<hr/>																																												
																					  </div>
																					</Collapse>
																				  </CardBody>
																				</Card>									
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion2(4)}
																					   aria-expanded={this.state.accordion2[4]}
																					>
																					  <i className="iconsminds-left-1" />  Post-Inspection
																					</Button>
																					<Collapse isOpen={this.state.accordion2[4]}>
																						<div className="p-4">
																							<h4 className="text-uppercase">Emails</h4>						
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_e1}
																											onChange={pre_client_number_selectedOption_e1 => { this.setState({ pre_client_number_selectedOption_e1 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_e1}
																											onChange={pre_client_type_selectedOption_e1 => { this.setState({ pre_client_type_selectedOption_e1 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_e2}
																											onChange={pre_client_number_selectedOption_e2 => { this.setState({ pre_client_number_selectedOption_e2 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_e2}
																											onChange={ pre_client_type_selectedOption_e2 => { this.setState({ pre_client_type_selectedOption_e2 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Three</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={ this.state.pre_client_number_selectedOption_e3 }
																											onChange={ pre_client_number_selectedOption_e3 => { this.setState({ pre_client_number_selectedOption_e3 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={ this.state.pre_client_type_selectedOption_e3 }
																											onChange={ pre_client_type_selectedOption_e3 => { this.setState({ pre_client_type_selectedOption_e3 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<hr/>
																							<h4 className="text-uppercase">Text</h4>						
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_t1}
																											onChange={pre_client_number_selectedOption_t1 => { this.setState({ pre_client_number_selectedOption_t1 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_t1}
																											onChange={pre_client_type_selectedOption_t1 => { this.setState({ pre_client_type_selectedOption_t1 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>															
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_t2}
																											onChange={pre_client_number_selectedOption_t2 => { this.setState({ pre_client_number_selectedOption_t2 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_t2}
																											onChange={ pre_client_type_selectedOption_t2 => { this.setState({ pre_client_type_selectedOption_t2 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>															
																							</Row>	
																							<p>Text messages send within 10 minutes of your target time. We recommend putting your phone number in the text messages you automatically send so that agents and buyers can respond to you.<br/>
																								We'll forward responses to our system number to you automatically if we can. We'll use this number to deliver responses: 
																								<NavLink to="/app/dashboard" className="btn btn-link btn-xs" >
																									Update your direct number here.
																								</NavLink>
																							</p>
																						</div>														
																					</Collapse>
																				  </CardBody>
																				</Card>																																												 								 		
																			</Colxx>
																		</Colxx>
																	</Row>							
																</div>
															</Colxx>					
														</Row>	
														
														<hr/>
														<Row>																							
															<Colxx lg="8" className="mb-2">								
																<span className="h5 ">Contractors</span>	&nbsp; &nbsp;																													
															</Colxx>					
														</Row>															
														<Row >																		
															<Colxx lg="6"  className="mb-2">
																<Input
																	type="text"
																	name="text"
																	id="exampleText"
																	placeholder="Search for a contractors"/>
															</Colxx>
															<Colxx lg="6" className="mb-2" >							
																<Button																		 
																	  color="primary"
																	  className="btn-shadow btn-lg"
																	  onClick={this.toggleModalContractors}
																	>
																	  Add new contractors								  
																</Button>																		
																   <AddContractors
																	modalOpen={this.state.modalOpenContractors}
																	toggleModal={this.toggleModalContractors}
																  />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">CONTRACTORS</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
																
															</Colxx>
															<Colxx lg="6">
																<div className= "d-inline-block">
																	<i className="iconsminds-male-2" style={{fontSize:'60px'}} />		
																</div>							
																<div className= "d-inline-block">
																	<span className="h8 text-muted">CONTRACTORS</span><br/>
																	<span className="h6 font-weight-text">Tameka Person</span><br/>								
																	<span className="h8 text-primary"><i className="iconsminds-envelope"/>{" "}tdejarnett@hotmail.com</span>										
																</div>
																<RemoveSectionInspection />
															</Colxx>
														</Row>
														<Row>																							
															<Colxx lg="6" className="text-left mb-2 mt-2" >							
																<Button
																	  size="sm"
																	  color="primary"
																	  className="btn-shadow"
																	  onClick={(e) => this.showEmailContractors(e)}
																	>
																	  Show Email Settings								  
																	</Button>
															</Colxx>					
														</Row>
														<Row id="show_emailcontractors" style={{display:'none'}}>																							
															<Colxx lg="12" className="mb-2 mt-2" >							
																<div lg="12" >
																	<Row>
																		<Colxx lg="12">						
																			<h3 className="font-weight-bold text-uppercase ml-3">
																				<i className="iconsminds-gears" />
																				{" "}Communication Settings			
																			</h3>
																			<Colxx lg="12" className="mb-4">
																				<Card>
																					<CardBody className="p-0">					  
																						<Button
																						  block
																						  color="outline-primary"
																						  className="text-left font-weight-bold rounded-0"
																						  onClick={() => this.toggleAccordion3(0)}
																						  aria-expanded={this.state.accordion3[0]}
																						>
																						  <i className="iconsminds-calendar-4" /> Scheduling
																						</Button>
																						<Collapse isOpen={this.state.accordion3[0]}>
																							<div className="p-4">
																								<Row>
																									<Colxx lg="9" className="mb-2">
																										<Label>													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<span className="h6 ml-4">Inspection confirmation email for contractors</span>
																										</Label>
																									</Colxx>
																								</Row>																																																					
																								<hr/>
																								
																								<Row>
																									<Colxx lg="9" className="mb-2">
																										<Label>													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<span className="h6 ml-4">Send contractors a confirmation email on reschedule</span>
																										</Label>
																									</Colxx>																
																								</Row>																																																
																							</div>
																						</Collapse>			
																					 </CardBody>
																				</Card>
																				<Card>
																					<CardBody className="p-0">
																						<Button
																						  block
																						  color="outline-primary"
																						  className="text-left font-weight-bold rounded-0"
																						   onClick={() => this.toggleAccordion3(1)}
																						  aria-expanded={this.state.accordion3[1]}
																						>
																						  <i className="iconsminds-handshake" /> Inspection Agreements & Fees
																						</Button>
																							<Collapse isOpen={this.state.accordion3[1]}>
																								<div className="p-4">
																									<Row>
																										<Colxx lg="9" className="mb-2">
																											<Label>													  
																												<Switch
																													className="custom-switch custom-switch-primary im-inline-block align-top"
																													defaultChecked={true}
																													onChange={this.switchChange}											
																												  />
																												<div className="col-lg-10 h6 d-inline-block ml-4">Send contractors an email when an Inspection Agreement is added later (ie after initial booking).</div>
																											</Label>
																										</Colxx>																	
																									</Row>
																									<Row>
																										<Colxx lg="9" className="mb-2">
																											<Label>													  
																												<Switch
																													className="custom-switch custom-switch-primary im-inline-block align-top"
																													defaultChecked={true}
																													onChange={this.switchChange}											
																												  />
																												<p className="col-lg-10 h6 d-inline-block ml-4">Send contractors an email when a new fee is added (ie after initial booking).</p>
																											</Label>
																										</Colxx>																	
																									</Row>
																								  </div>
																							</Collapse>
																					</CardBody>
																				</Card>
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion3(2)}							
																					   aria-expanded={this.state.accordion3[2]}
																					>
																					  <i className="iconsminds-left-1" />  Pre-Inspection
																					</Button>
																						<Collapse isOpen={this.state.accordion3[2]}>
																							<div className="p-4">
																								<h4 className="text-uppercase">Emails</h4>						
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_e1}
																												onChange={pre_client_number_selectedOption_e1 => { this.setState({ pre_client_number_selectedOption_e1 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_e1}
																												onChange={pre_client_type_selectedOption_e1 => { this.setState({ pre_client_type_selectedOption_e1 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_e2}
																												onChange={pre_client_number_selectedOption_e2 => { this.setState({ pre_client_number_selectedOption_e2 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_e2}
																												onChange={ pre_client_type_selectedOption_e2 => { this.setState({ pre_client_type_selectedOption_e2 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Three</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={ this.state.pre_client_number_selectedOption_e3 }
																												onChange={ pre_client_number_selectedOption_e3 => { this.setState({ pre_client_number_selectedOption_e3 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={ this.state.pre_client_type_selectedOption_e3 }
																												onChange={ pre_client_type_selectedOption_e3 => { this.setState({ pre_client_type_selectedOption_e3 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<hr/>
																								<h4 className="text-uppercase">Text</h4>						
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_t1}
																												onChange={pre_client_number_selectedOption_t1 => { this.setState({ pre_client_number_selectedOption_t1 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_t1}
																												onChange={pre_client_type_selectedOption_t1 => { this.setState({ pre_client_type_selectedOption_t1 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>
																								<Row>
																									<Colxx lg="5"  className="mb-2">
																										<Label className="col-lg-12 p-0">													  
																											<Switch
																												className="custom-switch custom-switch-primary im-inline-block align-top"
																												defaultChecked={true}
																												onChange={this.switchChange}											
																											  />
																											<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																										</Label>
																									</Colxx>
																									<Colxx lg="7" className="mb-2">
																										<Row className="remaider_options">
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-5"
																												classNamePrefix="react-select"
																												name="pre_client_number"
																												value={this.state.pre_client_number_selectedOption_t2}
																												onChange={pre_client_number_selectedOption_t2 => { this.setState({ pre_client_number_selectedOption_t2 }) }}
																												options={this.remaider_num_options}
																											/>
																											
																											<Select
																												components={{ Input: CustomSelectInput }}
																												className="react-select col-lg-7"
																												classNamePrefix="react-select"
																												name="pre_client_type"
																												value={this.state.pre_client_type_selectedOption_t2}
																												onChange={ pre_client_type_selectedOption_t2 => { this.setState({ pre_client_type_selectedOption_t2 }) }}
																												
																												options={[
																												{ label: "Hour(s)", value: "1", key: 0 },
																												{ label: "Day(s)", value: "2", key: 1 },
																												{ label: "Week(s)", value: "3", key: 2 },
																												{ label: "Month(s)", value: "4", key: 3 }
																											  ]}
																											/>
																										</Row>
																									</Colxx>
																								</Row>	
																								<p>Text messages send within 10 minutes of your target time. We recommend putting your phone number in the text messages you automatically send so that agents and buyers can respond to you.<br/>
																									We'll forward responses to our system number to you automatically if we can. We'll use this number to deliver responses: 
																									<NavLink to="/app/dashboard" className="btn btn-link btn-xs" >
																										Update your direct number here.
																									</NavLink>
																								</p>
																							  </div>
																						</Collapse>
																					</CardBody>
																				</Card>																																																		
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion3(3)}
																					   aria-expanded={this.state.accordion3[3]}
																					>
																					  <i className="iconsminds-file" />  Report
																					</Button>
																					<Collapse isOpen={this.state.accordion3[3]}>
																					<div className="p-4">
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										defaultChecked={true}
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Report ready: Agents email</span>
																								</Label>
																							</Colxx>														
																						</Row>																																		
																						<Row>
																							<Colxx lg="6" xxs="6" className="mb-2">
																								<Label>													  
																									<Switch
																										className="custom-switch custom-switch-primary im-inline-block"
																										onChange={this.switchChange}											
																									  />
																									<span className="h6 ml-4">Report ready: Agents text</span>
																								</Label>
																							</Colxx>
																						</Row>																						
																						<hr/>																																												
																					  </div>
																					</Collapse>
																				  </CardBody>
																				</Card>									
																				<Card>
																					<CardBody className="p-0">
																					<Button
																					  block
																					  color="outline-primary"
																					  className="text-left font-weight-bold rounded-0"
																					   onClick={() => this.toggleAccordion3(4)}
																					   aria-expanded={this.state.accordion3[4]}
																					>
																					  <i className="iconsminds-left-1" />  Post-Inspection
																					</Button>
																					<Collapse isOpen={this.state.accordion3[4]}>
																						<div className="p-4">
																							<h4 className="text-uppercase">Emails</h4>						
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_e1}
																											onChange={pre_client_number_selectedOption_e1 => { this.setState({ pre_client_number_selectedOption_e1 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_e1}
																											onChange={pre_client_type_selectedOption_e1 => { this.setState({ pre_client_type_selectedOption_e1 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_e2}
																											onChange={pre_client_number_selectedOption_e2 => { this.setState({ pre_client_number_selectedOption_e2 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_e2}
																											onChange={ pre_client_type_selectedOption_e2 => { this.setState({ pre_client_type_selectedOption_e2 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Three</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={ this.state.pre_client_number_selectedOption_e3 }
																											onChange={ pre_client_number_selectedOption_e3 => { this.setState({ pre_client_number_selectedOption_e3 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={ this.state.pre_client_type_selectedOption_e3 }
																											onChange={ pre_client_type_selectedOption_e3 => { this.setState({ pre_client_type_selectedOption_e3 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>
																							</Row>
																							<hr/>
																							<h4 className="text-uppercase">Text</h4>						
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder One</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_t1}
																											onChange={pre_client_number_selectedOption_t1 => { this.setState({ pre_client_number_selectedOption_t1 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_t1}
																											onChange={pre_client_type_selectedOption_t1 => { this.setState({ pre_client_type_selectedOption_t1 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>															
																							</Row>
																							<Row>
																								<Colxx lg="5"  className="mb-2">
																									<Label className="col-lg-12 p-0">													  
																										<Switch
																											className="custom-switch custom-switch-primary im-inline-block align-top"
																											defaultChecked={true}
																											onChange={this.switchChange}											
																										  />
																										<p className="col-lg-8 h6 d-inline-block ml-4">Reminder Two</p>
																									</Label>
																								</Colxx>
																								<Colxx lg="7" className="mb-2">
																									<Row className="remaider_options">
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-5"
																											classNamePrefix="react-select"
																											name="pre_client_number"
																											value={this.state.pre_client_number_selectedOption_t2}
																											onChange={pre_client_number_selectedOption_t2 => { this.setState({ pre_client_number_selectedOption_t2 }) }}
																											options={this.remaider_num_options}
																										/>
																										
																										<Select
																											components={{ Input: CustomSelectInput }}
																											className="react-select col-lg-7"
																											classNamePrefix="react-select"
																											name="pre_client_type"
																											value={this.state.pre_client_type_selectedOption_t2}
																											onChange={ pre_client_type_selectedOption_t2 => { this.setState({ pre_client_type_selectedOption_t2 }) }}
																											
																											options={[
																											{ label: "Hour(s)", value: "1", key: 0 },
																											{ label: "Day(s)", value: "2", key: 1 },
																											{ label: "Week(s)", value: "3", key: 2 },
																											{ label: "Month(s)", value: "4", key: 3 }
																										  ]}
																										/>
																									</Row>
																								</Colxx>															
																							</Row>	
																							<p>Text messages send within 10 minutes of your target time. We recommend putting your phone number in the text messages you automatically send so that agents and buyers can respond to you.<br/>
																								We'll forward responses to our system number to you automatically if we can. We'll use this number to deliver responses: 
																								<NavLink to="/app/dashboard" className="btn btn-link btn-xs" >
																									Update your direct number here.
																								</NavLink>
																							</p>
																						</div>														
																					</Collapse>
																				  </CardBody>
																				</Card>																																												 								 		
																			</Colxx>
																		</Colxx>
																	</Row>							
																</div>
															</Colxx>					
														</Row>
													</div>
												</Collapse>
											</CardBody>
										</Card>
										<Card>
											<CardBody className="p-0">
												<Button
												  block
												  color="outline-primary"
												  className="text-left font-weight-bold rounded-0"
												   onClick={() => this.toggleAccordion(2)}							
												   aria-expanded={this.state.accordion[2]}
												>
												  <i className="iconsminds-left-1" />  Services
												</Button>
											<Collapse isOpen={this.state.accordion[2]}>
												<div className="p-4">
													<div id="show_services" >
														<Row>																							
															<Colxx lg="12" className="mt-4 mb-2">								
																<span className="h5 ">Services</span>														
															</Colxx>											
														</Row>
														<Row>					
															<Colxx lg="6" className="mb-2">
																<Row>
																	<Colxx lg="12"  className="mb-2">				
																		<Select
																			components={{ Input: CustomSelectInput }}
																			className="react-select"
																			classNamePrefix="react-select"
																			isMulti
																			name="form-field-name"
																			value={this.state.selectedOptions2}
																			onChange={this.handleChangeMulti2}
																			options={selectData2}
																		  />
																	</Colxx>
																</Row>
																<Row>
																	<Colxx lg="12"  className="mb-2">
																		<Input
																			type="text"
																			name="text"
																			id="exampleText"
																			placeholder="Discount Code"/>
																	</Colxx>
																</Row>
															</Colxx>
															<Colxx lg="6" className="mb-2">
																<Card className="p-4">
																	<span className=" h6">Service Charges</span>
																	<Row>																							
																		<Colxx lg="12" className="text-left mb-2" >
																			<strong>Total</strong><strong className="float-right">$0.00</strong>
																		</Colxx>
																		
																		<Colxx lg="12" className="text-left  mb-2" >
																		<span>Duration</span>
																		
																		</Colxx>											
																																							
																		<Colxx lg="6" className="text-left  mb-2" >
																			<span>Templates</span>
																			
																		</Colxx>
																		<Colxx lg="6" className="text-left  mb-2" >
																			<span>Agreements</span>
																			
																		</Colxx>										
																	</Row>	
																	<div className="text-center mt-2" >	
																		<Button
																			  size="sm"
																			  color="primary"
																			  className="btn-shadow"
																			  onClick={(e) => this.showServicesChanges(e)}
																			>
																			  Manual Edit								  
																		</Button>
																	</div>
																</Card>
																
															</Colxx>
														</Row>
													</div>
												<div id="show_revert" style={{display:'none'}}>
													<Row>
														<Colxx lg="12" className="mb-2 mt-4">
															<Row>																							
																<Colxx lg="8" className="mt-4 mb-2">								
																	<span className="h5 ">Templates, Agreements, Duration and Fees</span>														
																</Colxx>											
															</Row>
															<Row>																							
																<Colxx lg="3" className="mb-2">								
																	<span className="h8">Templates</span> &nbsp;																																		
																</Colxx>	
																<Colxx lg="3" className="mb-2">								
																	<span className="h8">Agreements</span> &nbsp;																												
																</Colxx>
																<Colxx lg="3" className="mb-2">								
																	<span className="h8">Duration</span> &nbsp;																																
																</Colxx>	
															</Row>	
															<Row>
																<Colxx lg="3"  className="mb-2">				
																	<Select
																		components={{ Input: CustomSelectInput }}
																		className="react-select"
																		classNamePrefix="react-select"
																		isMulti
																		name="form-field-name"
																		value={this.state.selectedOptions}
																		onChange={this.handleChangeMulti}
																		options={selectData}
																	  />
																</Colxx>
																<Colxx lg="3"  className="mb-2">				
																	<Select
																		components={{ Input: CustomSelectInput }}
																		className="react-select"
																		classNamePrefix="react-select"
																		isMulti
																		name="form-field-name"
																		value={this.state.selectedOptions1}
																		onChange={this.handleChangeMulti1}
																		options={selectData1}
																	  />
																</Colxx>
																<Colxx lg="3"  className="mb-2">				
																
																	<InputGroup className="mb-3 p-0">
																	  <Input name="2.5" type="number" />									
																	  <InputGroupAddon addonType="append">
																		hrs									
																	  </InputGroupAddon>
																	</InputGroup>	
																</Colxx>
															</Row>
															<Row>																							
																<Colxx lg="6" className="mb-2">								
																	<span className="h5 ">Fees</span> &nbsp;																											
																</Colxx>														
															</Row>
															<Card id="show_fee" style={{display:'none'}} className="p-4 mb-2">
																<Row >
																	<Colxx lg="12" className="mb-2">
																		<Row>																													
																			<Colxx lg="3" className="mb-2">								
																				<span className="h8">Line item</span> &nbsp;																			
																			</Colxx>	
																			<Colxx lg="3" className="mb-2">								
																				<span className="h8">Pretax Amount</span> &nbsp;																				
																			</Colxx>
																			<Colxx lg="3" className="mb-2">								
																				<span className="h8">Tax</span> &nbsp;																				
																			</Colxx>
																			<Colxx lg="3" className="mb-2">
																			<RemoveSectionInspection />
																			</Colxx>
																		</Row>
																		<Row>
																			<Colxx lg="3"  className="mb-2">				
																					<Input
																					type="text"
																					name="text"
																					id="exampleText"
																					placeholder=""/>
																				</Colxx>
																				<Colxx lg="3"  className="mb-2">				
																					<InputGroup className="mb-3 p-0">
																					<InputGroupAddon addonType="prepend">$</InputGroupAddon>
																					<Input name="2.5" type="number" />																		  
																					</InputGroup>
																				</Colxx>
																				<Colxx lg="3"  className="mb-2">											
																					<InputGroup className="mb-3 p-0">
																					<InputGroupAddon addonType="prepend">$</InputGroupAddon>
																					<Input name="2.5" type="number" />																		  
																				</InputGroup>	
																				</Colxx>
																		</Row>
																		<Row>
																			<Colxx lg="3"  className="mb-2">				
																				<AvForm
																					className="av-tooltip tooltip-right-bottom"
																					onSubmit={this.handleSubmit}
																					>
																					<AvGroup className="error-t-negative">
																					  <AvField
																						name="text_message"
																						type="textarea"
																						value={this.state.textarea_content}
																						placeholder="Pricing Details"
																						rows="3"
																						validate={{
																						  minLength: {
																							value: 10,
																							errorMessage:
																							  "Your message must have minimum of 10 characters"
																						  },
																						}}
																					  />
																					</AvGroup>					
																				</AvForm>		
																			</Colxx>
																			<Colxx lg="3"  className="mb-2">				
																				<AvForm
																					className="av-tooltip tooltip-right-bottom"
																					onSubmit={this.handleSubmit}
																					>
																					<AvGroup className="error-t-negative">
																					  <AvField
																						name="text_message"
																						type="textarea"
																						value={this.state.textarea_content}
																						placeholder="Tax Description"
																						rows="3"
																						validate={{
																						  minLength: {
																							value: 10,
																							errorMessage:
																							  "Your message must have minimum of 10 characters"
																						  },
																						}}
																					  />
																					</AvGroup>					
																				</AvForm>
																			</Colxx>
																			<Colxx lg="3"  className="mb-2">											
																				<AvForm
																					className="av-tooltip tooltip-right-bottom"
																					onSubmit={this.handleSubmit}
																					>
																					<AvGroup className="error-t-negative">
																					  <AvField
																						name="text_message"
																						type="textarea"
																						value={this.state.textarea_content}
																						placeholder="Notes for client invoice"
																						rows="3"
																						validate={{
																						  minLength: {
																							value: 10,
																							errorMessage:
																							  "Your message must have minimum of 10 characters"
																						  },
																						}}
																					  />
																					</AvGroup>					
																				</AvForm>	
																			</Colxx>
																		</Row>								
																	</Colxx>
																</Row>
															</Card>
															<Row>																							
																<Colxx lg="4" className="text-left mb-2" >
																	<span>Total</span>
																	<InputGroup className="mb-3 p-0">
																		<InputGroupAddon addonType="prepend">$</InputGroupAddon>
																		<Input name="2.5" type="number" />																		  
																	</InputGroup>	
																</Colxx>					
															</Row>
															<Row>																							
																<Colxx lg="8" className="text-left mb-2" >							
																	<Button
																		  size="sm"
																		  color="primary"
																		  className="btn-shadow"
																		  onClick={(e) => this.showFee(e)}
																		>
																		  Fees								  
																		</Button>
																</Colxx>					
															</Row>
															<Row>																							
																<Colxx lg="8" className="text-center mb-2" >							
																	<Button
																		  size="sm"
																		  color="primary"
																		  className="btn-shadow"
																		  onClick={(e) => this.showRevertChanges(e)}
																		>
																		  Revert Changes								  
																		</Button>
																</Colxx>					
															</Row>
														</Colxx>
													</Row>
												</div>
												<Row>																							
													<Colxx lg="8" className="text-left mb-2 mt-4" >
														<span className="h5 ">Payment</span>
													</Colxx>					
												</Row>	
												<Row>																							
													<Colxx lg="6" className="text-left mb-2 mt-4" >
														<Label>													  
															<Switch
																className="custom-switch custom-switch-primary im-inline-block"
																defaultChecked={true}
																onChange={this.switchChange}											
																/>
															<span className="h6 ml-4">Require payment to release report(s)</span>
														</Label>&nbsp;&nbsp;&nbsp;&nbsp;
														
													</Colxx>
													<Colxx lg="6" className="mb-2">								
														<span className="h8">Payment notes</span> &nbsp;														
													
														<AvForm
																className="av-tooltip tooltip-right-bottom"
																onSubmit={this.handleSubmit}
																>
																<AvGroup className="error-t-negative">
																  <AvField
																	name="text_message"
																	type="textarea"
																	value={this.state.textarea_content}
																	placeholder="i.e. 'Client will pay on-site','Received in cash',etc"
																	rows="3"
																	validate={{
																	  minLength: {
																		value: 10,
																		errorMessage:
																		  "Your message must have minimum of 10 characters"
																	  },
																	}}
																  />
																</AvGroup>					
														</AvForm>			
													</Colxx>				
												</Row>																																																																																													
											</div>
											</Collapse>
										</CardBody>
									</Card>																																																																																				 								 		
									</Colxx>
									</Colxx>
								</Row>							
							</div>
						</Colxx>					
				</Row>							
                      
		  </ModalBody>
		 
		  <ModalFooter className="text-center d-block">
				
				<Button																		 
					  color="primary"
					  className="btn-shadow btn-lg"
					  onClick={this.toggleModalEvent}
					>
					  Add a Schedule								  
				</Button>																		
				   <AddEventModal
					modalOpen={this.state.modalOpenEvent}
					toggleModal={this.toggleModalEvent}
				  />
		  </ModalFooter>
	  </Modal>
	  );
  }
};

export default AddInspection;
