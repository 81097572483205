import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody } from "reactstrap";
import queryString from 'query-string';
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";

import classnames from "classnames";
import { NavLink } from "react-router-dom";

import { REST_API_END_POINT,themeColor } from "../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';
import { NotificationManager } from "../../components/common/react-notifications";

import PlanCard from "../../containers/admin/PlanCard";
import AddSubscriptionPlanModal from "../../containers/admin/AddSubscriptionPlanModal";
import EditSubscriptionPlanModal from "../../containers/admin/EditSubscriptionPlanModal";

export default class Subscriptions extends Component {
	
	constructor(props) {
		super(props);

		this.toggleModalEdit=this.toggleModalEdit.bind(this);
		this.dataListRender=this.dataListRender.bind(this);
		this.activateRow=this.activateRow.bind(this);
		this.state = {
			blocking: false,
			modalOpen: false,
			modalOpenEdit: false,
			subscriptions: [],
			subscription_id:0
		};
	}
	
	componentDidMount() {
		this.dataListRender();	
	}
	
	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen
		});
	};
	
	toggleModalEdit = (e,subscription_id=0) => {
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
			subscription_id
		});
	};
	
	activateRow = (row_id,status,sales=0) => {
		var formData = new FormData();
		formData.append('subscription_id',row_id);
		
		if(sales>0){
			if(status==1){
				swal("You can't deactivate this plan, because it has subscribers under it!");
			}else if(status==2){
				swal("You can't delete this plan, because it has subscribers under it!");
			}
			return;
		}
		var data_upload={};
		var msg="";
		if(status==1){
			formData.append('status',0);
			msg="You want to deactivate this subscription plan!"
		}else if(status==2){
			formData.append('status',2);
			msg="You want to delete this subscription plan!"
		}else{
			formData.append('status',1);
			msg="You want to activate this subscription plan!"			
		}
		swal({
		  title: "Are you sure?",
		  text: msg,
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then((willDelete) => {
		  if (willDelete) {
			axios.post(REST_API_END_POINT+'admin/delete-subscription',formData).then((data)=>{ 
				if(data.data.status==1){
					this.dataListRender();
					if(status==1){
						swal("Success!", "The subscription plan has been deactivated successfully!", "success");
					}else if(status==2){
						swal("Success!", "The subscription plan has been deleted successfully!", "success");
					}else{
						swal("Success!", "The subscription plan has been activated successfully!", "success");
					}								
				}else{
					if(status==1){
						swal("Failed to deactivate subscription plan!", "Please try after sometime.", "error");
					}else if(status==2){
						swal("Failed to delete subscription plan!", "Please try after sometime.", "error");
					}else{
						swal("Failed to activate subscription plan!", "Please try after sometime.", "error");
					}		
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		  } else {
			swal("You have canceled the operation!");
		  }
		});

		
	};
	
	dataListRender = () => {
		this.setState({
			blocking:true
		});
		axios.get(REST_API_END_POINT+'admin/subscriptions?orderBy=timestamp').then((res)=>{ 
			if(res.data.status==1){
				var subscriptions = res.data.data;
				this.setState({
					subscriptions,
					blocking:false
				});
			}else{
				this.setState({ blocking:false });
			}			
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
			NotificationManager.error("Failed to load the subscription plans! Please check your internet connection.","",2000,null,null,"filled");
			this.setState({ blocking:false });
		});
		
	};
	  
		
	render() {
		
		var {subscriptions} = this.state;
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.subscription-plans" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>

				<Row className="people-layout">          		  
					<Colxx lg="12" className="page-tabs">
						<Row className="mb-4">
							<Colxx lg="12">
								<Button
									color="primary"
									className="float-right"
									onClick={this.toggleModal}
								  >
									Add Subscription Plan
								</Button>
							</Colxx>
						</Row>
						<Row>
							{subscriptions.map((row,index)=>{
								return <PlanCard 
									key={index} 
									user={row}  
									toggleEditModal={this.toggleModalEdit}
									activateRow={this.activateRow}
									/>
							})}
						</Row>
					</Colxx>
                </Row>
				<AddSubscriptionPlanModal
					modalOpen={this.state.modalOpen}
					toggleModal={this.toggleModal}
					dataListRender={this.dataListRender}
				  />
				<EditSubscriptionPlanModal
					modalOpen={this.state.modalOpenEdit}
					toggleModal={this.toggleModalEdit}
					dataListRender={this.dataListRender}
					subscription_id={this.state.subscription_id}
				  />
				</BlockUi>  
			</Fragment>
			
		);
	}
}
