import React, { Component } from "react";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import Barcode from 'react-barcode';

export default class PrintBatchUIDContent extends Component{
	constructor(props) {
		super(props);
	}

	render() {
		const { record_batch_uid, crude_oil } = this.props;
		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 2.2in 1.1in !important;
						  margin:0;
						}
					}
				`}} />
				<div className="print_pos_label" style={{padding:'15px 3px 2px 3px'}} >                  
					<div style={{width:'2.95in', height:'1.4in', fontFamily: 'sans-serif'}} className="panel-body pl-2">
						<p className="mt-0 mb-2 font-weight-bold" style={{fontSize:'20px'}}>{crude_oil} GRAMS</p>
						
						<Barcode value={record_batch_uid} height={50} width={1.65} margin={0} marginTop={5} displayValue={false} />
						<p className="mt-1" style={{fontSize:'20px'}}>{(record_batch_uid.match(/.{1,4}/g)).join(' ')}</p>
					</div>
				</div>
			</div>
		);
  }
};
