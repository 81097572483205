import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Card, CardBody, CardTitle,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";

class BankModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			modalOpenAdd: false,
			modalOpenDoc: false,
		};

	}

	handleSubmit(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			this.props.toggleModal();
		}
	}
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalDoc = () => {
		this.setState({
			modalOpenDoc: !this.state.modalOpenDoc
		});
	};
	onChange = e => {
		switch (e.target.name) {
			case 'profile_pic':
				if (e.target.files[0]) {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
		}
	};


	render() {
		return (

			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Bank Information Stage
				</ModalHeader>
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>


						<Row className="mt-4">
							<Colxx lg="12" className="mb-2">
								<AvGroup>
									<Label>Stage Name</Label>
									<AvField
										name="suggestion"
										type="text"
										rows="2"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the  message"
											},
											minLength: {
												value: 2,
												errorMessage:
													"The message must have atleast 2 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>



						<Colxx xxs="12" className="mb-2">
							<Card>
								<CardBody>
									<CardTitle className="mb-0">
										<h6>Bank details</h6>
									</CardTitle>
									<p>Vendors will asked to provide tax information</p>
								</CardBody>
							</Card>
						</Colxx>



						<Row className="mt-4">
							<Colxx xxs="12">

								<CardBody>
									<CardTitle>
										<IntlMessages id="form-components.rad" />
									</CardTitle>

									<div>


										<CustomInput
											type="radio"
											id="exCustomRadio"
											name="customRadio"
											label="Automatic Stage"
										/>
										<CustomInput
											type="radio"
											id="exCustomRadio2"
											name="customRadio"
											label="Manual Review Stage"
										/>

									</div>

									<Row className="mt-4">

										<p>Vendors will automatically procced to the next stage

										</p>  </Row>
								</CardBody>

							</Colxx>
						</Row>




						<Row className="mt-4">
							<Colxx lg="12" className="mb-2">
								<AvGroup>
									<Label>Message</Label>
									<AvField
										name="suggestion"
										type="textarea"
										rows="6"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the  message"
											},
											minLength: {
												value: 2,
												errorMessage:
													"The message must have atleast 2 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>


					</ModalBody>
					<ModalFooter>
						<Button color="primary" >
							<IntlMessages id="customer.save" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default BankModal;
