const officialrequests = [
{
  operation:"completed an order #74",
  doneby:"Motagistics",
  filedateandtime:"08-26-2019 07:24:12",
 
},
{
  operation:"A new order # 74 has been placed by Neerav",
  doneby:"Motagistics",
  filedateandtime:"08-26-2019 07:21:40",
 
},
{
  operation:"The shelf type Shelf A has been updated",
  doneby:"Motagistics",
  filedateandtime:"08-26-2019 03:12:47",
 
},
{
  operation:"A new strain fg has been added .",
  doneby:"Motagistics",
  filedateandtime:"08-23-2019 07:51:43",
 
},
{
  operation:"A new strain sff has been added .",
  doneby:"Motagistics",
  filedateandtime:"08-23-2019 07:35:19",
 
},

]

export default officialrequests

