import React,{ Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  Table
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";

export default class PrintAccount extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		var orders = this.props.orders;
			
		return (
			<Row className="invoice-react">
				<Colxx xxs="12" className="mb-4">
					<Card className="mb-5 invoice-contents width-auto">
						<h2 style={{fontSize:'22px'}}>Account: {this.props.account!=null ? this.props.account.name:""}</h2>
						<CardBody className="d-flex flex-column justify-content-between">
							<Table>
								<thead>
									<tr>
										<th>Order ID</th>
										<th>Customer Name</th>
										<th>Amount Received</th>
										<th>Added By</th>
										<th>Added On</th>
									</tr>
								</thead>
								<tbody>
									{orders.map((order,index)=>{
										return(<tr key={index}>
										<th>{order.order_number}</th>
										<th>{order.customer_name}</th>
										<th>{order.account_total}</th>
										<th>{order.added_by}</th>
										<th>{order.added_on}</th>
									</tr>)
									})}
								</tbody>
							</Table>
							
						</CardBody>
					</Card>
				</Colxx>
			</Row>
		)
	
	}
}



