import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input,  DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";

import uniqueId from 'lodash/uniqueId';
import { Draggable, Droppable } from 'react-drag-and-drop';
import TooltipItemContent from "../../../components/common/TooltipItemContent";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddPlantModal from "../../../containers/growhouse/AddPlantModal";
import MovePlantModal from "../../../containers/growhouse/MovePlantModal";
import CurePlantModal from "../../../containers/growhouse/CurePlantModal";
import RemoveTableModal from "../../../containers/growhouse/RemoveTableModal";
import HarvestModal from "../../../containers/growhouse/HarvestModal";
import AddAdditiveModal from "../../../containers/growhouse/AddAdditiveModal";

import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class Tablesin extends Component {
	
	constructor(props) {
		super(props);
		
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		 		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenTable: false,
			modalOpenRemoveTableModal: false,
			search: '',			
			selectedIds:[],
			selectAll:0,
			removeIds:[],
			plants:[],
			plant_ids:'',
			room_id:'',
			table_id:'',
			plant_id:'',
			tables:[],
			rooms:[],
			user_privileges:[],
			grid_view:true,
			occupy:null,
			table:null,
		};
	}
	
	toggleView =(grid_view) => {
		this.setState({
			grid_view
		});
	};
	
	toggleModalCure = (e,plant_ids=[]) => {
		this.setState({
			plant_ids:plant_ids.join(","),
			modalOpenCure: !this.state.modalOpenCure
		});
	};
	
	toggleModalMovePlant = (e,plant_ids=[]) => {		
		this.setState({
			plant_ids:plant_ids.join(","),
			modalOpenMovePlant: !this.state.modalOpenMovePlant
		});
	};
	
	toggleModalAddAdditive = (e,plant_ids=[]) => {
		
		this.setState({
			plant_ids:plant_ids.join(","),
			modalOpenAddAdditive: !this.state.modalOpenAddAdditive
		});
	};
	
	toggleModalRemoveTable = (e,removeIds=[]) => {
		this.setState({
			removeIds,
			modalOpenRemoveTableModal: !this.state.modalOpenRemoveTableModal
		});
	};
	
	toggleModalHarvestPlant = (e,plant_ids=[]) => {
		this.setState({
			plant_ids:plant_ids.join(","),
			modalOpenHarvestPlant: !this.state.modalOpenHarvestPlant
		});
	};
	
	toggleModalEdit = (e,table_id =0) => {
		this.setState({
		  table_id: parseInt(table_id),
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
		
	toggleModalTable = () => {
		this.setState({
		  modalOpenTable: !this.state.modalOpenTable
		});
	};
  
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.plants.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    componentDidMount(){		
		var table=null;	
		
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('table_id',this.props.match.params.table_id);
		
		axios.post(REST_API_END_POINT+'growhouse/get-table-by-id',formData).then((res)=>{
			var data = res.data;			
				table=data.table;

				this.setState({
					table:table,					
				});
	   }).catch(e =>{			
     		console.log("Addition failed , Error ", e);
		});
		
	   this.dataListRender();
	}
	
    dataListRender() {		
		var plants=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('table_id',this.props.match.params.table_id);
		
		axios.post(REST_API_END_POINT+'growhouse/plants-by-table',formData).then((res)=>{
			var data = res.data;
			let occupy=data.occupy;	
			if(data.plants){
							
				let table=data.table;				
				plants=data.plants;				
				plants = plants.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				this.setState({
			        plants: data.plants,
					occupy,
					table,
					selectAll:0,
					selectedIds:[],
					removeIds:[],					
				});
			}else{
				this.setState({
					plants: [],	
					occupy,					
					selectAll:0,
					selectedIds:[],
					removeIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}	
	
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="plant";
		
		if(selectedIds.length>1){
			msg_word="plants";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-room").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('table_ids',JSON.stringify(selectedIds));
					formData.append('room_id',this.props.match.params.room_id );
					formData.append('status',status);
					formData.append('current_user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'growhouse/delete-plants',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-room").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-room").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	
    	
    movePhase = (e,selectedIds)  => {
		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return;
		}
       
        var msg="Move to next phase?";
        var success_msg="The plant has been moved to next phase.";
        var failure_msg="Failed to move to next phase.";
       
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("move-phase").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var plant;
					var formData = new FormData();
					formData.append('plant_ids',selectedIds.join(","));
					formData.append('current_user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'growhouse/move-to-next-phase',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("move-phase").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("move-phase").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	


    removeItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="plant";
		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return false;
		}
		
		if(selectedIds.length>1){
			msg_word="plants";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Remove the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been removed.";
            failure_msg="Failed to remove "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {	
			if (willDelete) {
				this.toggleModalRemoveTable(e,selectedIds);
			}
		});
	}
	
	
	
	movePlant = (e,selectedIds)  => {		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return false;
		}
		this.toggleModalMovePlant(e,selectedIds);		
	}
	
	addadditivePlant = (e,selectedIds)  => {		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return false;
		}
		this.toggleModalAddAdditive(e,selectedIds);		
	}
	
	harvestPlant = (e,selectedIds)  => {		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return false;
		}
		this.toggleModalHarvestPlant(e,selectedIds);		
	}
	
	curePlant = (e,selectedIds)  => {		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one plant","warning");
			return false;
		}
		this.toggleModalCure(e,selectedIds);		
	}
	
	
    activeItem=(e,table_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(table_id);
			
		}else{
		  var x=selectedIds.indexOf(table_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.plants.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	onPlantDrop=(data,e)=>{
		if(this.state.user_privileges.indexOf(11)!==-1){
			var position = e.target.getAttribute('data-position');
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			if(position!=null){
				var formData = new FormData();
				formData.append('plant_id',data.plants);
				formData.append('position',position);
				formData.append('table_id',this.props.match.params.table_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'growhouse/update-plant-position',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					if(data.status==1){
						this.dataListRender();
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		}else{
			swal("Warning!","You don't have permission to perform this action");
		}
	}
	
	render() {
		var data = this.state.plants;
		if (this.state.search) {
			data = data.filter(row => {
				if(row.strain_name==null){
					row.strain_name='';
				}
				if(row.barcode==null){
					row.barcode='';
				}
				return row.barcode.toLowerCase().includes(this.state.search.toLowerCase()) || row.strain_name.toLowerCase().includes(this.state.search.toLowerCase()) 
			})
		}
		
	const dataTableColumns = [		
		{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"plants_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
		},
		{
			Header: "Barcode",
			accessor: "barcode",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Strain",
			accessor: "strain_name",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
		{
			Header: "Phase",
			accessor: "phase_name",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
		{
			Header: "Birth Date",
			accessor: "birth_date",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
	    {
			Header: "Time in Room",
			accessor: "time_in_room",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
	    {
			Header: "Position",
			accessor: "position",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
	    {
			Header: "Group",
			accessor: "group_name",
			Cell: props => <p className="text-muted">{props.value}</p>
		},    
		{
			Header: "Reminder",
			accessor: "remainder",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: props => <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
		},	  	  
		{
			Header: "Options",
			accessor: "id",
			Cell: props => <Fragment>
					{this.state.user_privileges.indexOf(11)!==-1 || this.state.user_privileges.indexOf(12)!==-1 ? <div className="action-btns p-0">
						<ItemDropdown item={<DropdownMenu >
							{this.state.user_privileges.indexOf(11)!==-1 ? <DropdownItem className="cursor-pointer p-0" >
								<NavLink to={"/app/growhouse/edit-plant/"+props.value} className="d-block py-2 px-4">	
									<span className="d-inline-block">Edit</span>
								</NavLink>	
							</DropdownItem>:""}
							{this.state.user_privileges.indexOf(12)!==-1 ? <DropdownItem className="cursor-pointer" onClick={event =>this.removeItem(event,[props.value],2)} >
								<span className="d-inline-block" >Remove</span>
							</DropdownItem>:""}
						</DropdownMenu>} />
					</div>:""}
				</Fragment>
	    }
	];
	
	var GridContent=[];
	let i;
	
	if(this.state.table!=null){
		var j=0;
		var k=1;
		var rows=[10,10];
		if(this.state.table['capacity_detail']!=null){
			rows=this.state.table['capacity_detail'].split(',');
		}
		
		if(rows.length){
			for(i=0;i<rows[0];i++){			
				GridContent.push(<div key={uniqueId()} className="row special-full-row icon-cards-row mb-2" ></div>);
				let p;
				for(p=0;p<rows[1];p++){
					if(j<data.length && k==data[j]['position']){
						let plant_id = data[j]['id'];
						let barcode = data[j]['barcode'];
						let plant_status = data[j]['status'];
						GridContent.push(<Card className="mb-3 mr-3 growplant-width" key={uniqueId()}>
							<TooltipItemContent key={'tip'+plant_id} item={{
											  placement: "top",
											  id: 'tip'+plant_id,
											  content: <CardBody className="text-center pt-1 pb-1">
															<Draggable type="plants" data={plant_id}>
																<FormGroup className="mb-2">
																	<CustomInput type="checkbox" id={"plant_"+plant_id} onChange={event =>this.activeItem(event,plant_id) } checked={this.state.selectedIds.includes(plant_id)} />
																</FormGroup>
																{this.state.user_privileges.indexOf(11)!==-1 ?<NavLink to={"/app/growhouse/edit-plant/"+plant_id} className="d-block">
																	<img  alt="plant" src={"/assets/img/phases/"+data[j]['phase']+".png"} className="img-responsive p-0" />
																</NavLink>:<div><img  alt="plant" src={"/assets/img/phases/"+data[j]['phase']+".png"} className="img-responsive p-0" /></div>}
															</Draggable>
														</CardBody>,
											  body: <div className="text-left"><strong>Barcode: {barcode}</strong><br/>Birth Date : {data[j]['birth_date']}<br/>Position : {data[j]['position']}<br/>Phase : {data[j]['phase_name']}</div>,
											  TipClassName:'bg-theme-color'
											}} id={'tip'+plant_id} />
													  
						</Card>);
						j++;
					}else{
						GridContent.push(<Card className="mb-3 mr-3 growplant-width center-content-grow" key={uniqueId()} >
							<CardBody className="text-center pt-1 pb-1 center-position-number">
								<Droppable types={['plants']} data-position={k} onDrop={this.onPlantDrop.bind(this)}>
									<h1>{k}</h1>
								</Droppable>
							</CardBody>						  
						</Card>);
					}
					k++;
				}
			}
		}
	}
	
    return (
      <Fragment>
        <Row>
			<Colxx xxs="12">
				<Breadcrumb heading_text={this.state.table!=null ? this.state.table.name:""} match={this.props.match} />	
				<Separator className="mb-5" />
			</Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				<Colxx xxs="12">
				{this.state.occupy!=null ? (this.state.occupy.table_capacity==this.state.occupy.plant_count? <Button																		 
							  color="danger"
							  size="sm"
							  className="mb-3"
							> Table is fully occupied
						</Button>:""):""}
				</Colxx>
				<Colxx xxs="12">
					<div className="float-sm-right mb-2">
						{" "}{this.state.occupy!=null && this.state.user_privileges.indexOf(10)!==-1 ? (this.state.occupy.table_capacity==this.state.occupy.plant_count? "":<Button																		 
							  color="primary"
							  size="sm"
							  className="mb-2"
							  onClick={this.toggleModalTable}
							>
							Add Plant							  
						</Button>):""}
						{" "}{this.state.user_privileges.indexOf(11)!==-1 ? <Button																		 
							  color="primary"
							  size="sm"
							  className="mb-2"
					          onClick={(e)=> this.movePlant(e,this.state.selectedIds) }
							>
							Move							  
						</Button>:""}{" "}{this.state.user_privileges.indexOf(15)!==-1 ? <Button
						   style={{display:'none'}}	
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={(e)=> this.toggleModalHarvestPlant(e,this.state.selectedIds) }	
						>
							Harvest Plants
						</Button>:""}{" "}{this.state.user_privileges.indexOf(11)!==-1 ?<Button
					        id="move-phase"
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={event =>this.movePhase(event,this.state.selectedIds)} 	 								
						>
							Move to Next phase
						</Button>:""}{" "}{this.state.user_privileges.indexOf(144)!==-1 ?<Button
							id="delete-room"
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={(e)=> this.addadditivePlant(e,this.state.selectedIds) }								
						>
							Add Additives
						</Button>:""}{" "}{this.state.user_privileges.indexOf(15)!==-1 ? <Button					         
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={(e)=> this.harvestPlant(e,this.state.selectedIds) }							
						>
							Harvest
						</Button>:""} {" "}{this.state.user_privileges.indexOf(16)!==-1 ? <Button					      
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={(e)=> this.curePlant(e,this.state.selectedIds) }						
						>
							Cure
						</Button>:""}{" "}{this.state.user_privileges.indexOf(12)!==-1 ? <Button
							id="remove-plant"
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={event =>this.removeItem(event,this.state.selectedIds,2)} 	 								
						>
							Remove
						</Button>:""}{" "}{/*<Button
							id="delete-room"
							style={{display:'none'}}	
							color="primary"
							size="sm"
							className="mb-2"
                            onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
						>
							Create Task
						</Button>*/}
					</div>
					
					<div className="mb-4 dropdown-edit" style={{minHeight:'400px',display: (!this.state.grid_view ? "none":"block")}}>
						<FormGroup row>
							<Colxx lg="4" xxl="2" className="pr-0 pl-0">
								<div>
									<Button
										color="primary"
										size="sm"
										className={this.state.grid_view ? "":"opacity-list-type-reduce" }
										onClick={event =>this.toggleView(true)}					
									  >
										<i className="fas fa-th-large"></i>
									</Button>{" "}
									<Button
										color="primary"
										size="sm"
										className={!this.state.grid_view ? "":"opacity-list-type-reduce" }
										onClick={event =>this.toggleView(false)}
									  >
										<i className="fas fa-list"></i>
									</Button>
								</div>
							</Colxx>								
						</FormGroup>
						<Row className="icon-cards-row mt-4 mb-2 w-100">
							{GridContent}							
						</Row> 
					</div>
					<div className="mb-4 dropdown-edit" style={this.state.grid_view ? {display:"none"}:{}}>	
						<FormGroup row>
							<Colxx lg="4" xxl="2" className="pr-0 pl-0">
								<div>
									<Button
										color="primary"
										size="sm"
										className={this.state.grid_view ? "":"opacity-list-type-reduce" }
										onClick={event =>this.toggleView(true)}					
									  >
										<i className="fas fa-th-large"></i>
									</Button>{" "}
									<Button
										color="primary"
										size="sm"
										className={!this.state.grid_view ? "":"opacity-list-type-reduce" }
										onClick={event =>this.toggleView(false)}
									  >
										<i className="fas fa-list"></i>
									</Button>
								</div>
							</Colxx>
							<Colxx lg="6">
								<Input
									type="text"
									name="table_search"
									id="table_search"
									placeholder="Search"
									value={this.state.search}
									onChange={e => this.setState({search: e.target.value})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
				</Colxx>
				
				{this.state.table ? <AddPlantModal
					modalOpen={this.state.modalOpenTable}
					toggleModal={this.toggleModalTable}
					dataListRender = {this.dataListRender}
					room_id={this.state.table.room_id}
					table_id={this.state.table.id}			
					user_privileges={this.state.user_privileges}			
				/>:""}
				  
				<MovePlantModal
					modalOpen={this.state.modalOpenMovePlant}
					toggleModal={this.toggleModalMovePlant}
					dataListRender = {this.dataListRender}
					plant_ids={this.state.plant_ids}	
					room_id ={this.state.table?this.state.table.room_id:0}
					table_id ={this.state.table?this.state.table.id:0}
				/>
				  
				<HarvestModal
					modalOpen={this.state.modalOpenHarvestPlant}
					toggleModal={this.toggleModalHarvestPlant}
					dataListRender = {this.dataListRender}
					room_id ={this.state.table?this.state.table.room_id:0}
					table_id ={this.state.table?this.state.table.id:0}
					plant_ids={this.state.plant_ids}
				/>

				<CurePlantModal
					modalOpen={this.state.modalOpenCure}
					toggleModal={this.toggleModalCure}
					dataListRender = {this.dataListRender}
					plant_ids={this.state.plant_ids}
					table_id ={this.state.table?this.state.table.id:0}
				/>

				<AddAdditiveModal
					modalOpen={this.state.modalOpenAddAdditive}
					toggleModal={this.toggleModalAddAdditive}
					dataListRender = {this.dataListRender}
					plant_ids={this.state.plant_ids}
				/>
				
				<RemoveTableModal
					modalOpen={this.state.modalOpenRemoveTableModal}
					toggleModal={this.toggleModalRemoveTable}
					dataListRender = {this.dataListRender}
					plant_ids={this.state.removeIds}
					table_id={this.state.table?this.state.table.id:0}
				/>
				
			</Row>
		</Card>
      </Fragment>
    );
  }
}
