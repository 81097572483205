import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { NavLink } from "react-router-dom";

class AddGetStarted extends Component{
  constructor(props) {
    super(props);
	console.log(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }

  
  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  backdrop="static"
		  size="lg"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Create an organization
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Label>Each company within an organization will function just like your current company does now. 
				There will be a primary subscription, sub-inspectors, and support staff unique to that company. 
				Templates and agreements can be copied from other companies, but then operate independently. 
				Check out 
				<NavLink to="#" className="h6 text-primary"> our article on franchise & multi-location tools </NavLink>
				for more details.</Label>
				 
				<AvGroup className="error-t-negative">
				  <Label>Organization Name</Label>
				  <AvField
					name="firstname"
					type="text"
					validate={{
					  required: {
						value: true,
						errorMessage: "Please enter the first name"
					  },
					  pattern: {
						value: "^[a-zA-Z ,.'-]+$",
						errorMessage: "Invalid first name"
					  },
					  minLength: {
						value: 2,
						errorMessage:
						  "Your first name must be between 2 and 100 characters"
					  },
					  maxLength: {
						value: 100,
						errorMessage:
						  "Your first name must be between 2 and 100 characters"
					  }
					}}
				  />
				</AvGroup>															
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="Create Organization" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="pages.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddGetStarted;
