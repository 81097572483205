import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import moment from "moment";
import { getCurrencySymbol } from "../../../helpers/Utils";
import {	
  CustomInput,
  Button,
  Label,FormGroup,
  Card, Table, 
  Input, Badge,
  DropdownMenu, DropdownItem,
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT, INVENTORY_SOURCES} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ItemDropdown from "../../../components/customer/ItemDropdown";

import AddPricePointModal from "./PricePoints/AddPricePointModal";
import EditPricePointModal from "./PricePoints/EditPricePointModal";

const variation_types = {
						'0' : 'Milligram', 
						'1' : 'Gram', 
						'2' : 'OZ', 
						'3' : 'Unit', 
						'4' : 'Pen', 
						'5' : 'Cartridge', 
						'6' : 'Shatter', 
						'7' : 'Roll', 
						'8' : 'Case',
						'9' : 'Battery'
					};
const qty_types = {
				'0' : 'mg', 
				'1' : 'g', 
				'2' : 'oz', 
				'3' : 'units', 
				'4' : 'mg', 
				'5' : 'mg', 
				'6' : 'mg', 
				'7' : 'mg', 
				'8' : '',
				'9' : 'units'
			};


var dataCrypto = new SimpleCrypto(dataEncryptKey);

const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

export default class PricePoints extends Component {
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		
		let currencySymbol = getCurrencySymbol();
		this.state = {
			selectedIds: [],
			selectAll: 0,
			modalOpenAddPricePoint: false,
			modalOpenEditPricePoint: false,
			price_point:null,
			search:"",
			currencySymbol
		};

		this.toggleModalAddPricePoint = this.toggleModalAddPricePoint.bind(this);
		this.toggleModalEditPricePoint = this.toggleModalEditPricePoint.bind(this);
		
		this.activeItem = this.activeItem.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.props.price_points.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	activeItem=(e,variation_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(variation_id);			
		}else{
			var x=selectedIds.indexOf(variation_id);
			if(x>=0){
				selectedIds.splice(x,1);
			}
		}
		if(selectedIds.length!=0 && selectedIds.length==this.props.price_points.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAddPricePoint = () => {
		this.setState({
			modalOpenAddPricePoint: !this.state.modalOpenAddPricePoint
		});
	};
	
	toggleModalEditPricePoint = (e,price_point=null) => {
		this.setState({
			price_point: price_point,
			modalOpenEditPricePoint: !this.state.modalOpenEditPricePoint,
		});
	};
	
	deletePricePoints = ()  => {		
		var selectedIds = this.state.selectedIds;
		if(selectedIds.length<1){
			swal("Warning","Please select at least one price point","warning");
			return;
		}
		
        swal({
          title: "Are you sure?",
          text: "Delete the price points?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-price-points").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);	
					
					var formData = new FormData();							
					formData.append('facility_id',facility_id);			
					formData.append('price_point_ids',JSON.stringify(selectedIds));
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/delete-price-points',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-price-points").disabled=false;
						
						var api_message=document.getElementById("api_message_points");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.props.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-price-points").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
  
	render() {
		var data = [];
		if(typeof this.props.price_points!='undefined'){
			data = JSON.parse(JSON.stringify(this.props.price_points));	
			data = data.map(row=>{
				
				return row;
			});
		}
		
		if (this.state.search) {
			data = data.filter(row => {				
				row.start_date=moment(row.start_date).format('MM/DD/YYYY');
				row.end_date=moment(row.end_date).format('MM/DD/YYYY');				
				return row.money_spend.toString().includes(this.state.search) || row.price_points.toString().toLowerCase().includes(this.state.search) || row.start_date.includes(this.state.search) || row.end_date.includes(this.state.search) 
			})
		}
	  
		const dataTableColumns = [			  
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"id"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Money Spend",
				accessor: "money_spend",
				Cell: props => <p className="list-item-heading">{this.state.currencySymbol}{props.value}</p>,
			},
			{
				Header: "Price Point",
				accessor: "price_points",
				Cell: props => <p className="list-item-heading">{parseInt(props.value)}</p>
			},
			{
				Header: "Start Date",
				accessor: "start_date",
				Cell: props => <p className="list-item-heading">{moment(props.value).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "End Date",
				accessor: "end_date",
				Cell: props => <p className="list-item-heading">{moment(props.value).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Created Date",
				accessor: "timestamp",
				Cell: props => <p className="list-item-heading">{moment(parseInt(props.value)*1000).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Options",
				accessor: "id",
				width:70,
				sortable: false,
				Cell: props => <Fragment>
						<Button
							  color="primary"
							  size="sm"
							  className=""
							  onClick={(e)=>{ this.toggleModalEditPricePoint(e,props.original) }}
							>
							Edit
						</Button>
					</Fragment>
			}
	];
	var product_type=1;
	if(this.props.product!=null){
		product_type=parseInt(this.props.product.product_type);
	}
	
    return( 
		<Fragment>
			<div id="api_message_points"></div>
			<div className="float-sm-right">
				{" "}<Button
					  color="primary"
					  size="sm"
					  className=""
					  onClick={this.toggleModalAddPricePoint}
					>
					Add Price Point
				</Button>
				{" "}<Button
					color="primary"
					size="sm"
					id="delete-price-points"
					className=""
					onClick={this.deletePricePoints}
				  >
					Delete Price Points
				</Button>
			</div>
			<div className="mb-4 dropdown-edit small-padd-table">	
				<FormGroup row>
					<Colxx lg="6">
						<Input
							type="text"
							name="price_point_table_search"
							id="price_point_table_search"
							placeholder="Search"
							value={this.state.search}
							onChange={e => this.setState({search: e.target.value.toLowerCase()})}
						/>
					</Colxx>
				</FormGroup>
				<ReactTable
					data={data}
					TbodyComponent={CustomTbodyComponent}
					columns={dataTableColumns}
					defaultPageSize={10}
					minRows = {2}
					filterable={false}
					showPageJump={false}
					PaginationComponent={DataTablePagination}
					showPageSizeOptions={true}
					defaultSorted={[{id: "id",desc: false}]}
				/>
			</div>
			
			{this.props.product!=null ? <AddPricePointModal
				modalOpen={this.state.modalOpenAddPricePoint}
				toggleModal={this.toggleModalAddPricePoint}
				product={this.props.product}				
				dataListRender={this.props.dataListRender}
			/>:""}
			
			{this.props.product!=null && this.state.price_point!=null ? <EditPricePointModal
				modalOpen={this.state.modalOpenEditPricePoint}
				toggleModal={this.toggleModalEditPricePoint}
				product={this.props.product}
				price_point={this.state.price_point}
				dataListRender={this.props.dataListRender}
			/>:""}
			
		</Fragment>
    );
  }
  
};
