import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, TabContent, TabPane } from "reactstrap";
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Orders from "../../components/customer/Orders";
import AddGetStarted from "../../containers/customer/AddGetStarted";
import Breadcrumb from "../../containers/navs/Breadcrumb";

import classnames from "classnames";
import { NavLink } from "react-router-dom";

export default class AllOrders extends Component {
	
	constructor(props) {
		super(props);

		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		  modalOpen: false,
		  modalOpenStaff: false,
		};
	}
	
	toggleModal = () => {
		this.setState({
		  modalOpen: !this.state.modalOpen
		});
	};
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
  
  
 
  
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="sidebar.allorders" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>

		
        <Row>
          		  
		  <Colxx lg="12" className="page-tabs">
              <Card className="mb-4">
                <CardHeader>
					<Nav tabs className="card-header-tabs ">
						<NavItem>
						  <NavLink
							className={classnames({
							  active: this.state.activeFirstTab === "1",
							  "nav-link": true
							})}
							onClick={() => {
							  this.toggleFirstTab("1");
							}}
							to="#"
						  >
							All Orders
						  </NavLink>
						</NavItem>																				 
					</Nav>
                </CardHeader>

                <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
							<AllOrders {...this.props} />
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>                  				  
                </TabContent>
              </Card>
            </Colxx>

        </Row>
      </Fragment>
    );
  }
}
