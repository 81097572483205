import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  Button,
  Label, 
} from "reactstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';

export default class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("submit-password").disabled=true;
			
			var user_id = localStorage.getItem('user_id');			
			var formData = new FormData(document.getElementById("passwordForm"));
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'profile/change-password',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_password");
				api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				this.form && this.form.reset();
				
				document.getElementById("submit-password").disabled=false;
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("submit-password").disabled=false;
			});

		}
	} 
  
	render() {
		const user_data = this.props.user_data  
		
		
		return( 
			<Fragment>
				<h2>Set New Password</h2>
				<hr/>
				<div id="api_message_password"></div>				
				
				<div className="mb-4 dropdown-edit">
					<AvForm
						id="passwordForm"
						ref={c => (this.form = c)}
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					  >
						<Row className="mt-2">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Current Password<span className="text-danger">*</span></Label>
									<AvField
										id="current_password"
										name="current_password"
										type="password"
										autoComplete="off"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the current password"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>New Password<span className="text-danger">*</span></Label>
									<AvField
										id="password"
										name="password"
										type="password"
										autoComplete="off"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the new password"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Repeat Password<span className="text-danger">*</span></Label>
									<AvField
										id="confirm_password"
										name="confirm_password"
										type="password"
										autoComplete="off"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the confirm password"
											},
											match:{
												value:'password',
												errorMessage: "New password and confirm password must be the same"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							
						</Row>
						<Row className="mt-2 mb-4">	
							<Colxx xxs="6">
								<Button	id="submit-password" color="primary" >Submit</Button>					
							</Colxx>					
						</Row>
					</AvForm>
				</div>       
			</Fragment>
		);
	}
  
};
