import React, { Component } from "react";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input, Media
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';

class PrintContent extends Component{
		
    render() {
		const { visitor_data } = this.props;
		
		return (
			<div className="visitor-print-badge">
				<Media className="align-items-center">
					<Media left className="mr-3 visitor-print-badge-img">
						<Media object title={visitor_data.name} src={visitor_data.profile_pic_url} alt={visitor_data.name} />
					</Media>
					<Media left className="mr-3" style={{ width:'120px' }}>
						<h3 className="mb-0">{visitor_data.name}</h3>
						<p className="text-muted mb-0">{visitor_data.phone}</p>
					</Media>
					<Media left>
						<Barcode value={visitor_data.barcode} height={50} displayValue={false} />
					</Media>
				</Media>
			</div>
		);
	}
};

export default  class PrintBadgeModal extends Component{
	
	constructor(props) {
		super(props);	
    }
	
	render(){
		const { modalOpen, toggleModal, visitor_data } = this.props;
		return (	  
			<Modal
			  isOpen={modalOpen}
			  toggle={toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={toggleModal}>
					Print Badge
				</ModalHeader>
			 
				<ModalBody>
					<div className="mb-3 d-flex justify-content-center">
					{visitor_data!=null ? <PrintContent
							ref={el => (this.componentRef = el)}
							visitor_data={visitor_data}
						/>:""}
					</div>
					<style dangerouslySetInnerHTML={{__html: `
						@media print {
							@page {
							  size: 347px 76px;
							  margin:0;
							}
						}
					`}} />
					<div className="text-center">
						<ReactToPrint
							trigger={() => <Button color="primary" ><IntlMessages id="customer.print" /></Button>}
							content={() => this.componentRef}
							copyStyles={true}
							pageStyle={""}
						/>
					</div>
				</ModalBody>
			</Modal>
		);
	}
};
