import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";

import ReactTable from "react-table";



import data from "../../../data/customfieldtemplate";

export default class Customfieldtemplate extends Component {
	
	constructor(props) {
		super(props);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		 modalOpenAdd: false,
		 modalOpenAddResult: false,
		  modalOpenStaff: false,
		  data:data,
		  search: '',
		};
	}
	
	 toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
     
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
		
	   {
		Header: "",
		accessor: "status",
		Cell: props => <Badge color={props.value.statusColor} pill>{props.value.statusid===1?"Active":"InActive"}</Badge>

	  },	
	  {
		Header: "",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "",
		accessor: "stage",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },

	  {
		Header: "",
		accessor: "vendor",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "",
		accessor: "team",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	
	   	  
	 {
		Header: "",
		accessor: "",
		Cell: props => <Fragment> <NavLink to="/app/account/account-invoice" className="mb-2 btn btn-xs btn-primary "><i class="fas fa-edit"></i></NavLink><NavLink to="/app/account/add-to-account" className="mb-2 btn btn-xs btn-secondary ml-2"><i class="fas fa-ban"></i></NavLink><NavLink to="/app/account/add-to-account" className="mb-2 btn btn-xs btn-danger ml-2"><i class="fas fa-trash-alt"></i></NavLink></Fragment>
	  }
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.customfieldtemplate" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		<Card className="p-4">
		   <Row>
			  
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New 						  
						</Button>					
					</div>
					
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={5}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
			  </Colxx>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
