import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";

// get product cart quantity
const getProductCartQuantity = (cartItems, product) => {
  let productInCart = cartItems.filter(
    single =>
      single.id === product.id 
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.filter(
        single =>
          single.id === product.id
      )[0].quantity;
    } else {
      return cartItems.filter(single => product.id === single.id)[0].quantity;
    }
  } else {
    return 0;
  }
};

function ProductModal(props) {
  const { product } = props;
  const { currency } = props;
  const { finalproductprice } = props;
  
  const [productStock, setProductStock] = useState( parseInt(product.product_stock) );
  const [quantityCount, setQuantityCount] = useState(1);
  const [finalProductPrice, setFinalProductPrice] = useState(finalproductprice);
  const [variationId, setVariation] = useState(product.variation_id);

  const addToCart = props.addtocart;
  const addToast = props.addtoast;
  const cartItems = props.cartitems;

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
  );

  return (
    <Fragment>
      <Modal
		isOpen={props.show}
		toggle={props.onHide}
        className="product-quickview-modal-wrapper"
		size="lg"
      >
        <ModalHeader toggle={props.onHide}>
			
		</ModalHeader>
		<ModalBody>			
			  <div className="row">
				<div className="col-md-5 col-sm-12 col-xs-12">
				  <div className="product-large-image-wrapper">
				   	<div >
					  <div className="single-image">
						<img
						  src={product.thumbnail_url}
						  className="img-fluid"
						  alt=""
						/>
					  </div>
					</div>
				  </div>
				  
				</div>
				<div className="col-md-7 col-sm-12 col-xs-12">
				  <div className="product-details-content quickview-content">
					<h2 dangerouslySetInnerHTML={{ __html: product.name }}></h2>
					<div className="product-details-price">                 
						<span>{currency.currencySymbol}{finalProductPrice.toFixed(2)} </span>
					</div>
					<div className="pro-details-list">
					  {product.description ? (
						  <div dangerouslySetInnerHTML={{ __html: product.description }} ></div>
						) : (
						  ""
						)}
					</div>
					
					{product.variations.length ? <div className="pro-variation-list">
						{productStock && productStock < 20 ? <div className="text-danger mb-2">Only {productStock} is available</div>:(productStock ? <div className="text-success font-weight-bold mb-2">In Stock</div>:"")}
						<Input type="select" name="variation_id" className="form-control" value={variationId} onChange={e=>{
							let variation_id = e.target.value;
							let variation = product.variations.find(r=>{ return r.variation_id==variation_id });
							if(variation){
								setFinalProductPrice(variation.price);
								setProductStock(parseInt(variation.real_stock));
								setVariation(variation_id);
							}
						}}>
							{product.variations.map((row,i)=>{
								return(<option value={row.variation_id} key={i}>{row.variation_name}</option>)
							})}
						</Input>
					</div>:""}
					
					  <div className="pro-details-quality">
						<div className="cart-plus-minus">
						  <button
							onClick={() =>
							  setQuantityCount(
								quantityCount > 1 ? quantityCount - 1 : 1
							  )
							}
							className="dec qtybutton"
						  >
							-
						  </button>
						  <input
							className="cart-plus-minus-box"
							type="text"
							value={quantityCount}
							readOnly
						  />
						  <button
							onClick={() =>
							  setQuantityCount(
								quantityCount < productStock - productCartQty
								  ? quantityCount + 1
								  : quantityCount
							  )
							}
							className="inc qtybutton"
						  >
							+
						  </button>
						</div>
						<div className="pro-details-cart btn-hover">
						  {productStock && productStock > 0 ? (
							<button
							  onClick={() =>{
								addToCart(
								  product,
								  addToast,
								  quantityCount,
								  variationId
								);
								props.onHide();
							  }}
							  disabled={productCartQty >= productStock}
							>
							  {" "}
							  Add To Cart{" "}
							</button>
						  ) : (
							<button disabled>Out of Stock</button>
						  )}
						</div>
					  </div>
					
				  </div>
				</div>
			  </div>
			</ModalBody>
		</Modal>
    </Fragment>
  );
}

ProductModal.propTypes = {
  addtoast: PropTypes.func,
  addtocart: PropTypes.func,
  cartitems: PropTypes.array,
  currency: PropTypes.object,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    cartitems: state.cartData
  };
};

export default connect(mapStateToProps)(ProductModal);
