import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  Card
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectStatus = [
    { label: "Active", value: "1", key: 0 },
    { label: "Inactive", value: "0", key: 1 }
];

class EditNewRoomModal  extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			room_no: '',
			readOnly: true,
			rows:[],
			room_structures:[],
			selectShelfTypes:[],
			selectTable:[],	
		};
	
    }
	
    componentDidMount() {		
		var available=[];
		var table_types=[];
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-data-for-add-room',formData).then((res)=>{
			var data = res.data;
		
			if(data.available){
				available=data.available;			
				table_types=data.table_types;			
				
				table_types = table_types.map((element,index)=>{
					element.label=element.table_name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				this.setState({
					room_structures:available,
					selectTable:table_types,
				});
			}else{
				this.setState({
					room_structures:[],
					selectTable:table_types,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let room; 					
            if (prevProps.room_id!== this.props.room_id && this.props.room_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('room_id',this.props.room_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'growhouse/get-room',formData).then((res)=>{
					
					var data = res.data;
					room=data.room;
					
					var rows = [];
					
					var room_capacity = room.room_capacity.split(",");
					for(let capacity of room_capacity){
						let row={};
						row.id=rows.length;
						row.capacity=capacity;
						rows.push(row);
					}
					
					var statusType = selectStatus.find(r=>{ return r.value==room.status });
					var room_no = room.position; 
					
					this.setState({ 
						room,
						rows,
						room_no,
						statusType
					});					
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-room").disabled=true;
			
			if(this.state.rows.length==0){
				swal("Warning","Please add atleast one row","warning");
				document.getElementById("add-room").disabled=false;
				return false;
			}
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("roomForm"));
			formData.append('room_id',this.props.room_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'growhouse/edit-rooms',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-room").disabled=false;
				this.props.toggleModal();
				this.setState({
					rows:[],			
				});	
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-room").disabled=false;
			});

		}
	}
	
	addItem(){		
		var rows = this.state.rows;
		var row={};
		row.id=rows.length;
		row.capacity='';
		row.type_of_shelf=null;
		rows.push(row);
		this.setState({
			rows
		});
	}
	
	removeItem(row){
		var rows = this.state.rows;
		rows = rows.filter(data=>{ return row.id!==data.id });
		this.setState({ 
			rows
		});
	}
	
    handleClick = (room_no) => {
        this.setState({
			room_no
        });
    };
	
    render() {
	    if(this.state.room!=null){
			var room=this.state.room;
		}else{
			var room;
		}
  
        var {  selectTable } = this.state;
	  
	   var room_structures= this.state.room_structures;
				
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Room
		  </ModalHeader>
		  <AvForm
		         id="roomForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							value={room?((room.name!=null)?room.name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z0-9 ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
			
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Room No.<span className="text-danger">*</span></Label>
						  <AvField
							id="room_no"
							name="room_no"
							type="number"
							value={this.state.room_no}
							readOnly={true}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please enter numeric value"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<hr/>
				   <Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Status<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="status"
								id="status"
								value={this.state.statusType}						
								options={selectStatus}
								onChange={statusType => { this.setState({ statusType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>	
				<h5>Choose any one of Available Room</h5>
				{room_structures.map((row,index)=>{
					return (<span key={index} className={"w-40px p-2 m-1 border d-inline-block text-center cursor-pointer "+(this.state.room_no==row.value ? "room-active":"")} onClick={()=>{this.handleClick(row.value)}} >
							{row.value}
							</span>)
				})}
				
			    <Row>							
					<Colxx lg="12">
						<h2>Row</h2>
					</Colxx>
				</Row>
				<Row>							
					<Colxx lg="12">
						<Button
							color="primary"
							size="sm"
							className=""
							onClick={(e) => this.addItem()}
							>
							Add Row
						</Button>
					</Colxx>
				</Row>
				
				{this.state.rows.map((row,index)=>{
					return (
						<Card key={index} className="pl-4 pr-4 pb-4 mt-3" >
						<h4 className="mt-4 font-weight-bold">Row {index+1}</h4>
						<Row className="align-items-center">
							<Colxx lg="4">
								<AvGroup className="error-t-negative">
									<Label>No. Of Tables<span className="text-danger">*</span></Label>
									<AvField
										id={"no_of_tables_"+row.id}
									    name={"no_of_tables["+row.id+"]"}
										type="number"
										autoComplete="off"
										value={ row.capacity }
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  },
										  min: {
											value: 0,
											errorMessage: "Value should be greater than 0."
										  }
										}}
									/>
								</AvGroup>							
							</Colxx>					
							<Colxx lg="1">
							{(this.state.rows.length-1)==row.id ? <div className="remove-section float-right" onClick={e=>this.removeItem(row)} ><i className="simple-icon-close"></i></div>:""}
							</Colxx>
							
						</Row></Card>
					)
				})}					
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-room" >
			  <IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditNewRoomModal;
