import React, { Component } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";

import {
	Input,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class AddEventModal extends Component{
  constructor(props) {
    super(props);
	
	this.toggleFirstTab = this.toggleFirstTab.bind(this);
    this.toggleSecondTab = this.toggleSecondTab.bind(this);
	
    this.state = {
		 activeFirstTab: "1",
		activeSecondTab: "1",
		startTime: null,
		startDate: null
	 
    };
  } 
  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
  toggleSecondTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeSecondTab: tab
      });
    }
  }
  handleChangeDate = date => {
    this.setState({
      startDate: date
    });
  };
  handleChangeTime = time => {
    this.setState({
      startTime: time
    });
  };
  
  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  backdrop="static"
		  size="md"
		>
		
		  <ModalHeader toggle={this.props.toggleModal}>
			Schedule Event
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}>
		  <ModalBody className="with-switch">
			
                        <Row>
				
							<Colxx lg="12"  className="mb-2">
								<Input
									type="text"
									name="text"
									id="exampleText"
									placeholder="Name"/>
							</Colxx>
									
						</Row>
						
						<Row>							
							<Colxx lg="12"  className="mb-2">				
									<AvGroup className="error-t-negative">
									  <AvField
										name="text_message"
										type="textarea"
										value={this.state.textarea_content}
										placeholder="Description"
										rows="3"
										validate={{
										  minLength: {
											value: 10,
											errorMessage:
											  "Your message must have minimum of 10 characters"
										  },
										}}
									  />
									</AvGroup>		
							</Colxx>
						</Row>
						<Row>							
							<Colxx lg="12"  className="mb-2">
								<span className="h8">From</span>								
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<div className="">
									<DatePicker
									  selected={this.state.startDate}
									  onChange={this.handleChangeDate}
									  placeholderText="Date"
									/>
								</div>
							</Colxx>
							<Colxx lg="6"  className="mb-2">
								
								<DatePicker
									className=""
									placeholderText="8:00am"
									selected={this.state.startTime}
									onChange={this.handleChangeTime}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={15}
									dateFormat="hh:mm a"
									timeCaption="Time"
								  />				
							
							</Colxx>
							
						</Row>
						<Row>							
							<Colxx lg="12"  className="mb-2">
								<span className="h8">To</span>								
							</Colxx>
							<Colxx lg="6" className="mb-2">
								<div className="">
									<DatePicker
									  selected={this.state.startDate}
									  onChange={this.handleChangeDate}
									  placeholderText="Date"
									/>
								</div>
							</Colxx>
							<Colxx lg="6"  className="mb-2">
								
								<DatePicker
									className=""
									placeholderText="8:00am"
									selected={this.state.startTime}
									onChange={this.handleChangeTime}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={15}
									dateFormat="hh:mm a"
									timeCaption="Time"
								  />				
							
							</Colxx>
							
						</Row>	
						
						<Row>							
							<Colxx lg="12"  className="mb-2 text-left mt-4">								
									<Button color="secondary" outline onClick={this.props.toggleModal}>
									  <IntlMessages id="pages.close" />
									</Button>{"  "}&nbsp;&nbsp;
									<Button color="primary">
									  <IntlMessages id="pages.submit" />
									</Button>{" "}
							</Colxx>																		
						</Row>								
                      
		  </ModalBody>
		  </AvForm>
		  
	  </Modal>
	  );
  }
};

export default AddEventModal;
