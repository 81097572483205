import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";

import DocViewer, { PDFRenderer, PNGRenderer, JPGRenderer } from "react-doc-viewer";

const ViewFileModal = ({modalOpen, toggleModal, doc_url}) =>{
	const docs = [
		{ uri: doc_url }
	];
	  
	return (	  
		<Modal
		  isOpen={modalOpen}
		  toggle={toggleModal}
		  size="lg"
		>
			<ModalBody>
			{doc_url!=null ? <DocViewer 
			pluginRenderers={[PDFRenderer, PNGRenderer, JPGRenderer]}
			config={{
				header: {
					disableHeader: true,
					disableFileName: true,
					retainURLParams: false
				}
			}}
			documents={docs} />:""}
			</ModalBody>
			<ModalFooter className="justify-content-start">
				<Button color="secondary" outline onClick={toggleModal}>
					<IntlMessages id="customer.close" />
				</Button>{" "}
			</ModalFooter>
		</Modal>
	);
};

export default ViewFileModal;