import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Table } from "reactstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { Separator, Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";

class OutgoingInvoice extends Component {
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.account-invoice" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
             
        <Row className="invoice-react">
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-5 invoice-contents">
              <CardBody className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row justify-content-between pt-2 pb-2">
                    <div className="d-flex align-self-center font-weight-bold h6">
                     MOTAGISTICS 501
                    </div>
                    <div className="d-flex w-30 text-right ">
                      <p className="text-small text-semi-muted mb-0">
                       Address Line 1, Address Line 2<br/>
					   (123) 456-7890<br/>
						501@motagistics.com<br/>
						Lic No: xx-12345678<br/>
                      </p>
                    </div>
                  </div>
                  <div className="border-bottom pt-4 mb-5" />

                  <div className="d-flex flex-row justify-content-between mb-5">
                    <div className="d-flex flex-column w-70 mr-2 p-4 text-semi-muted bg-semi-muted">
                      <p className="mb-0">Ntasha </p>
                      <p className="mb-0">
                        75 Iron Point Circle Folsom, CA 95630<br/>
						Tel: 892-131-7304
                      </p>
                    </div>
                    <div className="d-flex w-30 flex-column text-right p-4 text-semi-muted bg-semi-muted">
                      <p className="mb-0">Invoice #: 741</p>
                      <p className="mb-0">DATE: December 04, 2018</p>
                    </div>
                  </div>

                  <Table borderless>
                    <thead>
                      <tr>
                        <th className="text-muted text-extra-small mb-2">
                          SALES PERSON
                        </th>
                        <th className="text-muted text-extra-small mb-2">
                          P.O. NUMBER
                        </th>
                        <th className="text-muted text-extra-small mb-2">
                          REQUISITIONER
                        </th>
						<th className="text-muted text-extra-small mb-2">
                          SHIPPED VIA
                        </th>
						<th className="text-muted text-extra-small mb-2">
                          F.O.B. POINT
                        </th>
						<th className="text-muted text-extra-small mb-2">
                          TERMS
                        </th>						
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Due in 14 days</td>                        
                      </tr>                                                     
                    </tbody>
                  </Table>
				  <Table borderless>
                    <thead>
                      <tr>                       
						<th className="text-muted text-extra-small mb-2">
                          	QUANTITY
                        </th>
						<th className="text-muted text-extra-small mb-2">
                          	DESCRIPTION
                        </th>
						<th className="text-muted text-extra-small mb-2">
                          	UNIT PRICE
                        </th>
						<th className="text-muted text-extra-small mb-2">
                          	TOTAL
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.00</td>
                        <td>SAMPLE PRODUCT 2 (100 MG)</td>
                        <td>$12.00</td>
                        <td>$12.60</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-right"></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-right"></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-right"></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td className="text-right"></td>
                      </tr> 
					  <tr>
                        <td></td>
                        <td></td>
                        <td className="text-right"></td>
                      </tr>                                          
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex flex-column">
                  <div className="border-bottom pt-3 mb-5" />
                  <Table borderless className="d-flex justify-content-end">
                    <tbody>
                      <tr>
                        <td className="text-semi-muted">Subtotal :</td>
                        <td className="text-right">$ 61.82</td>
                      </tr>
                      <tr>
                        <td className="text-semi-muted">Sales Tax :</td>
                        <td className="text-right">$ 61.82</td>
                      </tr>
                      <tr className="font-weight-bold">
                        <td className="text-semi-muted">Grand Total :</td>
                        <td className="text-right">$ 68.14</td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="border-bottom pt-3 mb-5" />
                  <p className="text-muted text-small text-center">
                    Payment in full due in 14 days<br/>
					If you have any questions concerning this invoice, contact Dawn {"<"}501@motagistics.com{">"}{" "}
                  </p>
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

      </Fragment>
    );
  }
}
export default OutgoingInvoice;
