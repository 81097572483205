import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);



class EditVehicleModal  extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {			
		vehicles:null		
	};
	
  }

	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let vehicles; 					
            if (prevProps.vehicle_id!== this.props.vehicle_id && this.props.vehicle_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('vehicle_id',this.props.vehicle_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'transportation/get-vehicle-by-id',formData).then((res)=>{
					
					var data = res.data;
					vehicles=data.vehicle;
					
					this.setState({ 
						vehicles
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    } 
  
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-vehicle").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("vehicleForm"));
			formData.append('vehicle_id',this.props.vehicle_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'transportation/edit-vehicle',formData).then((res)=>{
				var data = res.data;
							
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-vehicle").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-vehicle").disabled=false;
			});

		}
	}  

  
  render() {

		if(this.state.vehicles!=null){
			var vehicles=this.state.vehicles;
		}else{
			var vehicles;
		}  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit New Vehicles
		  </ModalHeader>
			<AvForm
				id="vehicleForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Vehicle Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="vehicle_name"
							type="text"
							autoComplete="off"
							value={ vehicles?((vehicles.vehicle_name!=null)?vehicles.vehicle_name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
							
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Vehicle Make<span className="text-danger">*</span></Label>
						  <AvField
							id="make"
							name="vehicle_make"
							type="text"
							autoComplete="off"
							value={ vehicles?((vehicles.vehicle_make!=null)?vehicles.vehicle_make:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
							
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Vehicle Model<span className="text-danger">*</span></Label>
						  <AvField
							id="model"
							name="vehicle_model"
							type="text"
							autoComplete="off"
							value={ vehicles?((vehicles.vehicle_model!=null)?vehicles.vehicle_model:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Vehicle LIC. Plate#<span className="text-danger">*</span></Label>
						  <AvField
							id="plate"
							name="no_plate"
							type="text"
							autoComplete="off"
								value={ vehicles?((vehicles.no_plate!=null)?vehicles.no_plate:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
							
						  />
						</AvGroup>
					</Colxx>					
				</Row>												
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-vehicle" >
				<IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditVehicleModal ;
