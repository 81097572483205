import React, { Component } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Rating from "../../components/common/FilledRating";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class CheckoutModal extends Component{
	constructor(props) {
		super(props);
		this.state={
			rating:0
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);	
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			if(this.state.rating==0){
				swal("Warning!", "Please select a rating!", "warning");				
				return;
			}
			
			document.getElementById("checkout-visitor").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("checkoutForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('rating',this.state.rating);
			formData.append('phone',this.props.visitor_data.phone);

			axios.post(REST_API_END_POINT+'visitor/checkout',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("checkout-visitor").disabled=false;
				this.setState({ rating:0 });
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("checkout-visitor").disabled=false;
			});

		}
	}
	
	render() {
		const visitor_data = this.props.visitor_data;
		
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
				id="checkoutForm"
			>
				<ModalBody className="visitor-feedback-form">
					{visitor_data!=null ? <h2 className="text-center font-weight-bold visitor-name-style" >{visitor_data.name}</h2>:""}
					<p className="text-center">It was good having you here.<br/>Kindly share your experience with us.</p>
					<div className="d-flex justify-content-center mb-3">
						<Rating total={5} rating={parseInt(this.state.rating)} onRate={({rating})=>{ this.setState({ rating }) }} />
					</div>
					<Row>		
						<Colxx lg="12">							
							<AvField
								name="feedback_message"
								type="textarea"
								placeholder="Leave a comment"
							/>
						</Colxx>					
					</Row>
					<div className="text-center">
						<Button color="primary" id="checkout-visitor" >
							Submit
						</Button>
					</div>
				</ModalBody>
			</AvForm>
	  </Modal>
	  );
  }
};