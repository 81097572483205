import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter, Card, CardHeader,  FormGroup, Input,
	Label, Table, Nav,NavItem, TabContent, TabPane, NavLink
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import classnames from "classnames";
import { uuid } from 'uuidv4';
import swal from 'sweetalert';

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

import ProductGrid from "../../components/kitchen/ProductGrid";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const QTY_TYPES = [
    { label: "Milligram", value: "0", key: 0 },
    { label: "Gram", value: "1", key: 1 },
    { label: "Unit", value: "2", key: 2 }
];
  
export default class EditRecipeModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.addProductToRecipe = this.addProductToRecipe.bind(this);
		this.removeRecipe = this.removeRecipe.bind(this);
		
		this.state = {
			products:[],
			categories:[],
			recipe:[],
			recipe_data:null,
			activeFirstTab: "1",
		};	
	}
	
	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'kitchen/get-data-add-recipe',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var products=data.products;
				var categories=data.categories;
				
				this.setState({
					products,
					categories
				});	
			}else{
				this.setState({
					products:[],
					categories:[]
				});
			}			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			if (prevProps.recipe_id!== this.props.recipe_id && this.props.recipe_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('recipe_id',this.props.recipe_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'processor/get-recipe',formData).then((res)=>{
					
					var data = res.data;
					var recipe_data=data.recipe;
					var ingredients=data.ingredients;
					
					var recipe = [];
					
					for(let ingredient of ingredients){
						let product_data={};
						product_data.id=ingredient.product_id;
						product_data.name=ingredient.name;
						product_data.qty=parseFloat(ingredient.quantity);
						product_data.product_type=parseInt(ingredient.product_type);
						product_data.ingredient_id=ingredient.ingredient_id;
						if(ingredient.variation_id!==null){							
							product_data.variations=ingredient.variations;
							product_data.variation_id=ingredient.variation_id;
						}else{
							product_data.variations=[];
							product_data.variation_id=null;			
						}
						if(ingredient.qty_type=="2"){
							product_data.qty_type="2";
							product_data.qty_types=QTY_TYPES.filter(row=>{ return row.value=="2" });
						}else{
							product_data.qty_type=ingredient.qty_type;
							product_data.qty_types=QTY_TYPES.filter(row=>{ return row.value!="2" });
						}
						product_data.insert_id=uuid();
						
						recipe.push(product_data);
					}
					
					this.setState({ 
						recipe,
						recipe_data
					});					
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
	
	async addProductToRecipe(e,product){
		var recipe = this.state.recipe;
		var product_data={};
		product_data.id=product.id;
		product_data.name=product.name;
		product_data.qty=1;
		product_data.product_type=parseInt(product.product_type);
		product_data.ingredient_id="";
		if(product_data.product_type===2){
			var formData = new FormData();
			formData.append('product_id',product.id);
			await axios.post(REST_API_END_POINT+'kitchen/get-variations',formData).then((res)=>{
				var data = res.data;
				if(data.status==1){
					product_data.variations=data.variations;
					product_data.variation_id=data.variations[0].variation_id;
				}else{
					product_data.variations=[];
					product_data.variation_id=null;
				}				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});			
		}else{
			product_data.variations=[];
			product_data.variation_id=null;			
		}
		if(product.qty_type=="3"){
			product_data.qty_type="2";
			product_data.qty_types=QTY_TYPES.filter(row=>{ return row.value=="2" });
		}else{
			product_data.qty_type="0";
			product_data.qty_types=QTY_TYPES.filter(row=>{ return row.value!="2" });
		}
		product_data.insert_id=uuid();
		
		if(recipe.find(row=>{ return row.id==product.id })==null){
			recipe.push(product_data);
		}else{
			swal("Warning!","This product is alrady added to recipe","warning");
		}
		this.setState({ recipe });
	}
	
	removeRecipe(insert_id){
		var recipe = this.state.recipe;
		recipe = recipe.filter(row=>{ return row.insert_id!==insert_id });
		this.setState({ recipe });
	}
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			if(this.state.recipe.length==0){
				swal("Warning!","Please add at least one ingredient","warning");
				return;
			}
			
			document.getElementById("edit-recipe").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var ingredient_ids = this.state.recipe.map(row=>row.ingredient_id);
			
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			formData.append('recipe_id',this.props.recipe_id);	
			formData.append('recipe_name',values.recipe_name);
			formData.append('percentage',values.percentage);
			formData.append('recipe',JSON.stringify(this.state.recipe));		
			formData.append('ingredient_ids',JSON.stringify(ingredient_ids));		
			
			axios.post(REST_API_END_POINT+'processor/edit-recipe',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("edit-recipe").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("edit-recipe").disabled=false;
			});

		}
	}

	render() {	
		const {  categories, products, recipe_data } = this.state;
		let data = JSON.parse(JSON.stringify(this.state.recipe));
	  
		if (this.state.search) {
			data = data.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Product",
				accessor: "name",
				Cell: (props) => <p className="list-item-heading mt-1">{props.value}</p>
			},
			{
				Header: "Quantity",
				accessor: "qty",
				Cell:  (props) => {
							return(<Fragment>
								<Row className="align-items-center">
									<Colxx lg="6" className="pr-0">
										<AvGroup >
											<AvField
												name={"quantity_"+props.original.insert_id}
												id={"quantity_"+props.original.insert_id}
												type="number"
												className="w-100 mt-1" 
												value={props.value.toString()}
												min="0"
												onChange={(e)=>{
													const value = e.target.value;										
												}}
												onBlur={(e)=>{
													const value = e.target.value;
													var recipe = this.state.recipe;

													if(value!='' && parseFloat(value)>=0){	
														recipe = recipe.map(r=>{
															if(r.insert_id==props.original.insert_id){
																r.qty=parseFloat(e.target.value)
															}
															return r;
														});
													}
													this.setState({ recipe });
												}}
											/>
										</AvGroup>
									</Colxx>
									<Colxx lg="6">
										<AvGroup >
											{props.original.product_type==1 ? <AvField
												name={"qty_type_"+props.original.insert_id}
												id={"qty_type_"+props.original.insert_id}
												type="select"
												className="w-100 mt-1" 
												value={props.original['qty_type']}
												onChange={(e)=>{
													const value = e.target.value;
													var recipe = this.state.recipe;
													recipe = recipe.map(r=>{
														if(r.insert_id==props.original.insert_id){
															r['qty_type']=parseInt(e.target.value);
														}
														return r;
													});
													this.setState({ recipe });
												}}
											>
												{props.original.qty_types.map((v,i)=>{
													return <option value={v.value} key={i}>{v.label}</option>
												})}									
											</AvField>:<AvField
												name={"variation_id_"+props.original.insert_id}
												id={"variation_id_"+props.original.insert_id}
												type="select"
												className="w-100 mt-1" 
												value={props.original['variation_id']}
												onChange={(e)=>{
													const value = e.target.value;
													var recipe = this.state.recipe;
													recipe = recipe.map(r=>{
														if(r.insert_id==props.original.insert_id){
															r['variation_id']=parseInt(e.target.value);
														}
														return r;
													});
													this.setState({ recipe });
												}}
											>
												{props.original.variations.map((v,i)=>{
													return <option value={v.variation_id} key={i}>{v.variation_name}</option>
												})}									
											</AvField>}
										</AvGroup>
									</Colxx>	
								</Row>	
				            </Fragment>)
				}							
			},
			{
				Header: "",
				accessor: "insert_id",
				width:60,
				Cell: props =><Fragment>
								<span className="pl-1 pr-1 text-danger cursor-pointer cart-op-icons" onClick={(e)=>{ this.removeRecipe(props.value) }} ><i className="fa fa-times" /></span>
							</Fragment>
			}
		];
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Recipe
				</ModalHeader>
				<AvForm
					id="recipeForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="6">
								<AvGroup >
									<Label>Recipe Name<span className="text-danger">*</span></Label>
									<AvField
										id="recipe_name"
										name="recipe_name"
										type="text"
										autoComplete="off"
										value={recipe_data!=null ? recipe_data.recipe_name:""}
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  },
										  minLength: {
											value: 2,
											errorMessage:
											  "The name must be between 2 and 100 characters"
										  },
										  maxLength: {
											value: 100,
											errorMessage:
											  "The name must be between 2 and 100 characters"
										  }
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup >
									<Label>CRC Percentage<span className="text-danger">*</span></Label>
									<AvField
										id="percentage"
										name="percentage"
										type="number"
										value={recipe_data!=null ? recipe_data.percentage:""}
										autoComplete="off"
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											min: {
												value: "0",
												errorMessage: "Value should be greater than or equal to 0."
											},
											max: {
												value: "100",
												errorMessage: "Value should be less than or equal to 100."
											},
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">
								<Card className="mt-3">
									<CardHeader>
										<Nav tabs className="card-header-tabs ">
											{categories.map((row,index)=>{
												return(<NavItem className="cursor-pointer" key={index}>
													<NavLink
														className={classnames({
														  active: this.state.activeFirstTab === (index+1).toString(),
														  "nav-link": true
														})}
														onClick={() => {
														  this.toggleFirstTab((index+1).toString());
														}}
														to="#"
													>
														{row.name}
													</NavLink>
												</NavItem>)
											})}
										</Nav>
									</CardHeader>
									
									<div className="pos-product-wrapper kitchen-product-wrapper">
										{categories.map((row,index)=>{
											const filtered_products = products.filter(r=>{ return r.category_id==row.category_id })
											return(<TabContent activeTab={this.state.activeFirstTab} key={index}>
												<TabPane tabId={(index+1).toString()}>
													<ProductGrid 
														products={filtered_products}
														addProductToRecipe={this.addProductToRecipe}
													/>
												</TabPane>
											</TabContent>)
										})}
									</div>
								</Card>
							</Colxx>
							<Colxx lg="6" >
								 <Card className="mt-3">
									<div className="mb-4 pt-4 pb-4 pr-2 pl-2 dropdown-edit">						
										<FormGroup row>
											<Colxx lg="6">
												<Input
												  type="text"
												  name="table_search"
												  id="table_search"
												  placeholder="Search"
												  value={this.state.search}
												  onChange={e => this.setState({search: e.target.value})}
												/>
											</Colxx>
										</FormGroup>			
										
										<ReactTable
											data={data}
											columns={dataTableColumns}
											defaultPageSize={10}
											minRows = {2}
											filterable={false}
											showPageJump={false}
											PaginationComponent={DataTablePagination}
											showPageSizeOptions={true}
											defaultSorted={[{id: "send_on",desc: false}]}
											className="align-items-center-table"
										/>
									</div>
								</Card>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="edit-recipe">
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
