import React, { Component, Fragment } from "react";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import ReactToPrint from 'react-to-print';
import { isMobile, isAndroid } from "react-device-detect";

import printJS from 'print-js'

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import Receipt from "../../components/pos/Receipt";
import PrintPatientLabel from "../../components/pos/PrintPackage";
import PrintProductLabel from "../../components/pos/PrintProductLabel";

import {REST_API_END_POINT, dataEncryptKey,JQUERY_MODULE, REST_API_BASE_URL, themeColor } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PrintLabelModal extends Component{
	constructor(props) {
		super(props);
	
		this.state = {
			order_data: null,
			order_products: null,
			whole_order_data: null,
			facility_data: null,
			currencies: [],
			blocking:false
		};
		this.sendEmail = this.sendEmail.bind(this);
		this.sendSMS = this.sendSMS.bind(this);
		this.printCustomerLabel = this.printCustomerLabel.bind(this);
		this.printProductLabel = this.printProductLabel.bind(this);
		this.printReceipt = this.printReceipt.bind(this);
    }
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'point-of-sale/print-labels',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data,
							order_products:data.order_products,
							whole_order_data:data.whole_order_data,
							facility_data:data.facility_data,
							currencies:data.currencies
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	printCustomerLabel(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		printJS(REST_API_BASE_URL+"print-customer-label/"+this.props.order_id+"/"+facility_id);
    }
	
	printProductLabel(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		printJS(REST_API_BASE_URL+"print-product-label/"+this.props.order_id+"/"+facility_id);
    }
	
	printReceipt(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		printJS(REST_API_BASE_URL+"print-receipt/"+this.props.order_id+"/"+facility_id);
    }
	
	sendEmail(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('order_id',this.props.order_id);
		formData.append('facility_id',facility_id);
		this.setState({ blocking:true });
		axios.post(REST_API_END_POINT+'point-of-sale/send-receipt-email',formData).then((res)=>{			
			var data = res.data;
			if(data.status==1){
				swal("Success!",data.msg,"success");
				this.setState({ blocking:false })
			}else{				
				swal("Warning!","There is no such order","warning");
			}					
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});    
    }
	
	sendSMS(){      
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('order_id',this.props.order_id);
		formData.append('facility_id',facility_id);
		this.setState({ blocking:true });
		axios.post(REST_API_END_POINT+'point-of-sale/send-receipt-sms',formData).then((res)=>{			
			var data = res.data;
			if(data.status==1){
				swal("Success!",data.msg,"success");				
			}else if(data.status==2){
				let extra_msg="";
				if(typeof data.msg!='undefined'){
					extra_msg=data.msg;
				}
				swal("Warning!","Failed to send text message: "+extra_msg,"warning");
			}
			this.setState({ blocking:false })
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false })
		});    
    }
	
    render() {
		const { order_data, order_products, whole_order_data, facility_data, currencies } = this.state;
		const { modal_head, success_msg, is_budtender, is_order_list } = this.props;
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					{modal_head}
				</ModalHeader>
				
				<ModalBody >
					{typeof success_msg!="undefined" ? <Row className="mb-3">
						<Colxx md="12" className="text-center text-danger h4">{success_msg}</Colxx>
					</Row>:""}
					{/*<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
						{isMobile || isAndroid ? <Row>
							<Colxx md="12" className="text-center">
								{/*typeof is_budtender=="undefined" || facility_id==10 ? <a href={REST_API_BASE_URL+"print-customer-label/"+this.props.order_id+"/"+facility_id} target="_blank" className="ml-2 mb-2 btn btn-primary " >Print Customer Label</a>:""}
								{typeof is_budtender=="undefined" || facility_id==10 ? <a href={REST_API_BASE_URL+"print-product-label/"+this.props.order_id+"/"+facility_id} target="_blank" className="ml-2 mb-2 btn btn-primary " >Print Product Label</a>:""*
								{typeof is_budtender!=="undefined" || typeof is_order_list!=="undefined" || facility_id==10 ? <a href={REST_API_BASE_URL+"print-receipt/"+this.props.order_id+"/"+facility_id} target="_blank" className="ml-2 mb-2 btn btn-primary " >Print Receipt</a>:""}
								{order_data!=null && (typeof is_budtender!=="undefined" || typeof is_order_list!=="undefined" || facility_id==10) ? (order_data.customer_email!=null ? <Button color="primary" className="ml-2 mb-2" onClick={this.sendEmail} >Email Receipt</Button>:""):""}
								{order_data!=null && (typeof is_budtender!=="undefined" || typeof is_order_list!=="undefined" || facility_id==10) ? (order_data.customer_phone!=null ? <Button color="primary" className="ml-2 mb-2" onClick={this.sendSMS} >Text Receipt</Button>:""):""}
							</Colxx>
						</Row>:<Row>
							<Colxx md="12" className="text-center">
								{/*typeof is_budtender=="undefined" || facility_id==10 ? <Button color="primary" className="ml-2 mb-2" onClick={this.printCustomerLabel} >Print Customer Label</Button>:""}
								{typeof is_budtender=="undefined" || facility_id==10 ? <Button color="primary" className="ml-2 mb-2" onClick={this.printProductLabel} >Print Product Label</Button>:""*
								{typeof is_budtender!=="undefined" || typeof is_order_list!=="undefined" || facility_id==10 ? <Button color="primary" className="ml-2 mb-2" onClick={this.printReceipt} >Print Receipt</Button>:""}
								{order_data!=null && (typeof is_budtender!=="undefined" || typeof is_order_list!=="undefined" || facility_id==10) ? (order_data.customer_email!=null ? <Button color="primary" className="ml-2 mb-2" onClick={this.sendEmail} >Email Receipt</Button>:""):""}
								{order_data!=null && (typeof is_budtender!=="undefined" || typeof is_order_list!=="undefined" || facility_id==10) ? (order_data.customer_phone!=null ? <Button color="primary" className="ml-2 mb-2" onClick={this.sendSMS} >Text Receipt</Button>:""):""}
							</Colxx>
						</Row>}
					</BlockUi>*/}
				</ModalBody>
				<ModalFooter className="justify-content-center">					
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.close" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
	}
};
