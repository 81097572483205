import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput, Label } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";


import ContactFormBuilder from "../../../components/website/ContactForm";

import axios from 'axios';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor} from "../../../constants/defaultValues";

export default class ContactForm extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			configurations: {}
		};
	}

	render() {
		
		return (
			<Fragment>				
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.contact-form" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12" className="page-tabs">
						<Card className="mb-4">								
							<CardBody>
								<ContactFormBuilder />
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}
