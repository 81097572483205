import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, Button, CardBody, FormGroup, Input, DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";

import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { zeroPad } from "../../../helpers/Utils";

import InvoiceModal from "../../../containers/inventory-transfer/InvoiceModal";
import PrintShippingManifestModal from "../../../containers/inventory-transfer/PrintShippingManifestModal";
import CompleteOrderModal from "../../../containers/inventory-transfer/CompleteOrderModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AllOutgoingTransfer extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleModalInvoice = this.toggleModalInvoice.bind(this);
		this.toggleModalPrintShippingManifest = this.toggleModalPrintShippingManifest.bind(this);
		
		this.state = {
			modalOpenInvoice:false,
			modalOpenPrintShippingManifest:false,
			modalOpenComplete: false,
			selectAll:0,
			search: '',
			inventory_transfers:[],
			order_id:0
		};
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory-transfer/get-all-outgoing-inventory-transfers',formData).then((res)=>{
			var data = res.data;
		
			if(data.inventory_transfers){
				this.setState({
					inventory_transfers: data.inventory_transfers,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					inventory_transfers: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.inventory_transfers.forEach(x => {
				if(x.status!=3 && x.status!=4){
					newSelected.push(x.order_id);
				}
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }	
	
    activeItem=(e,transfer_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(transfer_id);
			
		}else{
		  var x=selectedIds.indexOf(transfer_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.inventory_transfers.filter(r=>{ return r.status!=3 && r.status!=4 }).length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalInvoice = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenInvoice: !this.state.modalOpenInvoice
		});
	};
	
	toggleModalPrintShippingManifest = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenPrintShippingManifest: !this.state.modalOpenPrintShippingManifest
		});
	};
	
	toggleModalComplete = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenComplete: !this.state.modalOpenComplete
		});
	};
		
	cancelOrders = (e,selectedIds)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one order","warning");
			return;
		}
		
		var msg="Cancel these orders?";
        var success_msg="The orders has been canceled.";
        var failure_msg="Failed to cancel orders";
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("cancel-orders").disabled=true;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('order_ids',JSON.stringify(selectedIds));
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'inventory-transfer/cancel-orders',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("cancel-orders").disabled=false;					
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
					this.props.dataListRender();
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("cancel-orders").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
  
    render() {
		let data = this.state.inventory_transfers;
		if (this.state.search) {
			data = data.filter(row => {
				return row.facility_name.toLowerCase().includes(this.state.search.toLowerCase()) || row.payment_mode.toLowerCase().includes(this.state.search.toLowerCase()) || row.order_number.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					if(rowInfo.original.status!=3 && rowInfo.original.status!=4){
						return ( <FormGroup>
							<CustomInput type="checkbox" id={"transfer_"+rowInfo.original.order_id} onChange={event =>this.activeItem(event,rowInfo.original.order_id) } checked={this.state.selectedIds.includes(rowInfo.original.order_id)} />
							</FormGroup> );
					}else{
						return "";
					}
				}
			},
			{
				Header: "Order ID",
				accessor: "order_number",
				Cell: props => <p className="list-item-heading mb-0">{zeroPad(props.value,6)}</p>
			},
			{
				Header: "Facility Name",
				accessor: "facility_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Order Date",
				accessor: "timestamp",
				Cell: props => <p className="list-item-heading">{moment(props.value*1000).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Payment Mode",
				accessor: "payment_mode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Payment Status",
				accessor: "payment_status",
				Cell: props => <p className="list-item-heading">{props.value==1 ? 'Paid':'Unpaid'}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => {			
					if(props.value==0){
						return(<Badge color="warning" pill>Waiting for Approval</Badge>);
					}else if(props.value==1){
						return(<Badge color="primary" pill>Approved</Badge>);
					} else if(props.value==2){
						return(<Badge color="primary" pill>Processed</Badge>);
					} else if(props.value==3){
						return(<Badge color="success" pill>Completed</Badge>);
					} else {
						return(<Badge color="danger" pill>Canceled</Badge>);
					}
				}
			},	  	  
			{
				Header: "Actions",
				accessor: "order_id",
				Cell: props => <Fragment>
					<div className="action-btns p-0">
						<ItemDropdown item={<DropdownMenu >
							<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalInvoice(e,props.value) }} >
								<span className="d-inline-block">Invoice</span>
							</DropdownItem>
							<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalPrintShippingManifest(e,props.value) }} >
								<span className="d-inline-block">Print Shipping Manifest</span>
							</DropdownItem>
							{props.original.status==2 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalComplete(e,props.value) }} >
								<span className="d-inline-block" >Complete Order</span>
							</DropdownItem>:""}
						</DropdownMenu>} />
					</div>
				</Fragment>
			}
		];
		
    return (
		<Fragment>
			<Row>
			  <Colxx xxs="12">
				<Breadcrumb heading="menu.outgoing-transfer" match={this.props.match} />
				<Separator className="mb-5" />
			  </Colxx>
			</Row>	
			<Card className="p-4">
			   <Row>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx> 
					<Colxx xxs="12">
						<div className="float-sm-right">
							{" "}<NavLink to="/app/inventory/select-facility" className="btn-primary btn-sm btn">
								<span className="d-inline-block">Create Order</span>
							</NavLink>{" "}<Button																		 
									color="primary"
									size="sm"
									id="cancel-orders"
									onClick={(e)=>{ this.cancelOrders(e,this.state.selectedIds) }}
								>
								  Cancel Orders							  
							</Button>																								   
						</div>
					
						<div className="mb-4 dropdown-edit">
							
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
									/>
							  </Colxx>
							  </FormGroup>			
							<ReactTable
							  data={data}
							  columns={dataTableColumns}
							  defaultPageSize={10}
							  filterable={false}
							  showPageJump={false}
							  PaginationComponent={DataTablePagination}
							  showPageSizeOptions={true}
							  defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>
					</Colxx>
				</Row>
				
				<InvoiceModal
					modalOpen={this.state.modalOpenInvoice}
					toggleModal={this.toggleModalInvoice}
					order_id={this.state.order_id}
				/>
				
				<PrintShippingManifestModal
					modalOpen={this.state.modalOpenPrintShippingManifest}
					toggleModal={this.toggleModalPrintShippingManifest}
					order_id={this.state.order_id}
				/>
				
				<CompleteOrderModal
					modalOpen={this.state.modalOpenComplete}
					toggleModal={this.toggleModalComplete}
					dataListRender={this.dataListRender}
					order_id={this.state.order_id}
				/>
			</Card>
      </Fragment>
    );
  }
}
