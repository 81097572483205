import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';



import allOrders from "./allOrders";
import customerEditOrder from "./customerEditOrder";
import ConvertToQuotation from '../../../containers/estimate/ConvertToQuotation';
import Quotation from './Quotation'
const Estimates = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
          <Route path={`${match.url}/quotation/:order_id`} component={Quotation} />
          <Route path={`${match.url}/all-estimates`} component={allOrders} />
		  <Route path={`${match.url}/edit/:order_id`} component={customerEditOrder} />
		  <Route path={`${match.url}`} component={allOrders} />
     
          <Redirect to="/error" />

        </Switch>
    </div>
);
export default Estimates;