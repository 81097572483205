import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input,  DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";

import AddTableModal from "../../../containers/growhouse/AddTableModal";
import EditTableViewModal from "../../../containers/growhouse/EditTableViewModal";

import HarvestTableModal from "../../../containers/growhouse/HarvestTableModal";
import CutTableModal from "../../../containers/growhouse/CutTableModal";
import TableCureModal from "../../../containers/growhouse/TableCureModal";

import uniqueId from 'lodash/uniqueId';
import { Draggable, Droppable } from 'react-drag-and-drop';
import TooltipItemContent from "../../../components/common/TooltipItemContent";

import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { getCookie, setCookie } from "../../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class Tables extends Component {
	
	constructor(props) {
		super(props);
		
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.AddPlantToTable = this.AddPlantToTable.bind(this);
		
		this.state = {		 		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenTable: false,
			search: '',
			table_ids:'',
			room_id:'',
			room:null,
			selectedIds:[],
			user_privileges:[],
			grid_view:true,
			occupy:null,
		};
	}
	
	toggleView =(grid_view) => {
		this.setState({
			grid_view
		});
	};
	
	toggleModalEdit = (e,table_id =0) => {
		this.setState({
		  table_id: parseInt(table_id),
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
	
	toggleModalTable = () => {
		this.setState({
		  modalOpenTable: !this.state.modalOpenTable
		});
	};
	
	AddPlantToTable = (e,table_ids=[]) => {
		if(table_ids.length){		
			const final_table_ids = table_ids.join(",");
			setCookie('add_to_plant_table_ids',final_table_ids);			
			window.location="/app/growhouse/add-plant-to-table";
		}else{
			swal("Warning!", "Please select at least one table", "warning");
		}
	};
	
	toggleModalTableCure = (e,table_ids=[]) => {
		this.setState({
		   table_ids:table_ids.join(","),
		  modalOpenTableCure: !this.state.modalOpenTableCure
		});
	};
    
    toggleModalHarvestTable = (e,table_ids=[]) => {
		this.setState({
		   table_ids:table_ids.join(","),
		  modalOpenHarvestTable: !this.state.modalOpenHarvestTable
		});
	};
	 
	toggleModalCutTable = (e,table_ids=[]) => {
		this.setState({
		   table_ids:table_ids.join(","),
		  modalOpenCutTable: !this.state.modalOpenCutTable
		});
	};
	
	
    toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.tables.forEach(x => {
               newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}

	
    dataListRender() {
		
		var tables=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('room_id',this.props.match.params.room_id );
		
		axios.post(REST_API_END_POINT+'growhouse/get-all-tables-by-room',formData).then((res)=>{
			var data = res.data;
					
			if(data.tables){
				tables=data.tables;
				let occupy=data.occupy;
				
				tables = tables.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				var room = data.room;
				this.setState({
			        tables,
					occupy,
					selectAll:0,
					selectedIds:[],
					room
				});
			}else{
				this.setState({
					tables: tables,
					selectAll:0,
					selectedIds:[],
					room:null
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning","Please select at least one table","warning");
			return false;
		}
		
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="table";
		
		if(selectedIds.length>1){
			msg_word="tables";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+"?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+"?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+"?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-room").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('table_ids',JSON.stringify(selectedIds));
					formData.append('room_id',this.props.match.params.room_id );
					formData.append('status',status);
					formData.append('current_user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'growhouse/delete-grow-tables',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-room").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-room").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	harvestTable = (e,selectedIds)  => {		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one table","warning");
			return false;
		}

		this.toggleModalHarvestTable(e,selectedIds);		
	}
	
	cureTable = (e,selectedIds)  => {		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one table","warning");
			return false;
		}

		this.toggleModalTableCure(e,selectedIds);		
	}
	
	cutTable = (e,selectedIds)  => {		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one table","warning");
			return false;
		}

		this.toggleModalCutTable(e,selectedIds);		
	}
	
    activeItem=(e,table_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(table_id);
			
		}else{
		  var x=selectedIds.indexOf(table_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.tables.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	onTableDrop=(data,e)=>{
		if(this.state.user_privileges.indexOf(152)!==-1){
			var position = e.target.getAttribute('data-position');
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			if(position!=null){
				var formData = new FormData();
				formData.append('table_id',data.tables);
				formData.append('position',position);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'growhouse/update-table-position',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					if(data.status==1){
						this.dataListRender();
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		}else{
			swal("Warning!","You don't have permission to perform this action");
		}
	}
  
	render() {
	  let data = this.state.tables
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.position.toLowerCase().includes(this.state.search) || row.plant_count.includes(this.state.search) || row.created_at.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"tables_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Table No.",
				accessor: "position",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Plant Count",
				accessor: "plant_count",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Created At",
				accessor: "created_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
			},	  	  
			{
				Header: "Options",
				accessor: "id",
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
								<DropdownItem className="cursor-pointer p-0">
									<NavLink to={"/app/growhouse/table/"+props.value} className="d-block py-2 px-4">	
										<span className="d-inline-block">View</span>
									</NavLink>					
								</DropdownItem>
								{this.state.user_privileges.indexOf(152)!==-1 ? <DropdownItem className="cursor-pointer" onClick={(e)=> this.toggleModalEdit(e,props.value) }>					
									<span className="d-inline-block">Edit</span>									
								</DropdownItem>:""}								
								{this.state.user_privileges.indexOf(153)!==-1 ? <Fragment>
								{props.original.status==1 ? <DropdownItem className="cursor-pointer" onClick={event =>this.deleteItem(event,[props.value],0)} >
									<span className="d-inline-block" >Deactivate</span>
								</DropdownItem>:<DropdownItem className="cursor-pointer" onClick={event =>this.deleteItem(event,[props.value],1)} >
									<span className="d-inline-block" >Activate</span>
								</DropdownItem>}
								<DropdownItem className="cursor-pointer" onClick={event =>this.deleteItem(event,[props.value],2)} >
									<span className="d-inline-block" >Delete</span>
								</DropdownItem>
								</Fragment>:""}
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];
		
		var GridContent=[];
		let i;
		
		if(this.state.room!=null){
			var rows=this.state.room['room_capacity_detail'].split(',');
			var j=0;
			if(rows.length){
				let k=1;
				for(let table_row of rows){
					GridContent.push(<div key={uniqueId()} className="row special-full-row icon-cards-row mb-2" ></div>);
					table_row = parseInt(table_row);
					let i=0;
					for(i = 0; i < table_row; i++){
						if(j<data.length && k==data[j]['position']){
							let table_id = data[j]['id'];
							let table_name = data[j]['name'];
							let table_status = data[j]['status'];
							GridContent.push(<Card className="mb-3 mr-3 growtable-width" key={uniqueId()}>
								<TooltipItemContent key={'tip'+table_id} item={{
												  placement: "top",
												  id: 'tip'+table_id,
												  content: <CardBody className="text-center pt-1 pb-1">
																<Draggable type="tables" data={table_id}>
																	<FormGroup className="mb-2">
																		<CustomInput type="checkbox" id={"table_"+table_id} onChange={event =>this.activeItem(event,table_id) } checked={this.state.selectedIds.includes(table_id)} />
																	</FormGroup>
																	{table_status==1 ?<span className="plant-count">{data[j]['plant_count']}</span>:""}
																	<NavLink to={"/app/growhouse/table/"+table_id} className="d-block">
																		<img  alt="Table" src={table_status==1 ? "/assets/img/open-table.png":"/assets/img/closed-table.png"} className="img-responsive p-0" />
																	</NavLink>
																</Draggable>
															</CardBody>,
												  body: <Fragment><strong>{table_name}</strong><br/>Number of Plants : {data[j]['plant_count']}<br/><small>{data[j]['table_type']}</small></Fragment>,
												  TipClassName:'bg-theme-color'
												}} id={'tip'+table_id} />
														  
							</Card>);
							j++;
						}else{
							GridContent.push(<Card className="mb-3 mr-3 growtable-width center-content-grow" key={uniqueId()} >
								<CardBody className="text-center pt-1 pb-1 center-position-number">
									<Droppable types={['tables']} data-position={k} onDrop={this.onTableDrop.bind(this)}>
										<h1>{k}</h1>
									</Droppable>
								</CardBody>						  
							</Card>);
						}
						k++;
					}
				}
			}
		}
		
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading_text={this.state.room!=null ? this.state.room.name:""} match={this.props.match} />						
					<Separator className="mb-5" />
				</Colxx>
			</Row>
			
			<Card className="p-4">
				<Row>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
					<Colxx xxs="12">
					{this.state.occupy!=null ? (this.state.occupy.room_capacity==this.state.occupy.table_count? <Button																		 
								  color="danger"
								  size="sm"
								  className="mb-3"
								> Room is fully occupied 							  
							</Button>:""):""}
					</Colxx>
					<Colxx xxs="12">
						<div className="float-sm-right mb-2">
							{" "}{this.state.occupy!=null && this.state.user_privileges.indexOf(151)!==-1 ? (this.state.occupy.room_capacity==this.state.occupy.table_count? "":<Button																		 
								  color="primary"
								  size="sm"
								  className="mb-2"
								  onClick={this.toggleModalTable}
								>
								  Add Table							  
							</Button>):""}
							{" "}{this.state.user_privileges.indexOf(10)!==-1 ? <Button																		 
								  color="primary"
								  size="sm"
								  className="mb-2"
								  onClick={(e)=> this.AddPlantToTable(e,this.state.selectedIds) }
								>
								  Add Plants to Tables
							</Button>:""}{" "}						
							   {" "}{this.state.user_privileges.indexOf(15)!==-1 ? <Button
								color="primary"
								size="sm"
								className="mb-2"
								onClick={(e)=> this.harvestTable(e,this.state.selectedIds) }
							  >
								Harvest Plants
							</Button>:""}{" "}{this.state.user_privileges.indexOf(16)!==-1 ? <Button
								id="delete-room"
								color="primary"
								size="sm"
								className="mb-2"
								onClick={(e)=>this.cureTable(e,this.state.selectedIds)}
							>
								Cure Plants
							</Button>:""}{" "}{this.state.user_privileges.indexOf(16)!==-1 ? <Button
								id="delete-room"
								color="primary"
								size="sm"
								className="mb-2"
								onClick={(e)=>this.cutTable(e,this.state.selectedIds)} 								
							>
								Cut Plants
							</Button>:""}{" "}{this.state.user_privileges.indexOf(153)!==-1 ? <Fragment><Button
								id="inactive-room"
								color="primary"
								size="sm"
								className="mb-2"
								onClick={event =>this.deleteItem(event,this.state.selectedIds,0)} 								
							>
								Deactivate
							</Button>{" "}<Button
								id="delete-room"
								color="primary"
								size="sm"
								className="mb-2"
								onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
							>
								Delete
							</Button></Fragment>:""}
						</div>
						
						<div className="mb-4 dropdown-edit" style={{minHeight:'400px',display: (!this.state.grid_view ? "none":"block")}}>
							<FormGroup row>
								<Colxx lg="4" xxl="2" className="pr-0 pl-0">
									<div>
										<Button
											color="primary"
											size="sm"
											className={this.state.grid_view ? "":"opacity-list-type-reduce" }
											onClick={event =>this.toggleView(true)}					
										  >
											<i className="fas fa-th-large"></i>
										</Button>{" "}
										<Button
											color="primary"
											size="sm"
											className={!this.state.grid_view ? "":"opacity-list-type-reduce" }
											onClick={event =>this.toggleView(false)}
										  >
											<i className="fas fa-list"></i>
										</Button>
									</div>
								</Colxx>								
							</FormGroup>
							<Row className="icon-cards-row mt-4 mb-2 w-100">
								{GridContent}							
							</Row> 
						</div>
						<div className="mb-4 dropdown-edit" style={this.state.grid_view ? {display:"none"}:{}}>	
							<FormGroup row>
								<Colxx lg="4" xxl="2" className="pr-0 pl-0">
									<div>
										<Button
											color="primary"
											size="sm"
											className={this.state.grid_view ? "":"opacity-list-type-reduce" }
											onClick={event =>this.toggleView(true)}					
										  >
											<i className="fas fa-th-large"></i>
										</Button>{" "}
										<Button
											color="primary"
											size="sm"
											className={!this.state.grid_view ? "":"opacity-list-type-reduce" }
											onClick={event =>this.toggleView(false)}
										  >
											<i className="fas fa-list"></i>
										</Button>
									</div>
								</Colxx>
								<Colxx lg="6">
									<Input
										type="text"
										name="table_search"
										id="table_search"
										placeholder="Search"
										value={this.state.search}
										onChange={e => this.setState({search: e.target.value.toLowerCase()})}
									/>
								</Colxx>
							</FormGroup>
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: true}]}
							/>
						</div>					
					</Colxx>

					{this.state.modalOpenTable ? <AddTableModal
						modalOpen={this.state.modalOpenTable}
						toggleModal={this.toggleModalTable}
						dataListRender = {this.dataListRender}
						room_id={this.props.match.params.room_id}
					/>:""}

					<EditTableViewModal
						modalOpen={this.state.modalOpenEdit}
						toggleModal={this.toggleModalEdit}
						dataListRender = {this.dataListRender}
						table_id ={this.state.table_id}
					/> 

					<HarvestTableModal
						modalOpen={this.state.modalOpenHarvestTable}
						toggleModal={this.toggleModalHarvestTable}
						dataListRender = {this.dataListRender}
						table_ids ={this.state.table_ids}
						room_id={this.props.match.params.room_id}
					/>

					<CutTableModal
						modalOpen={this.state.modalOpenCutTable}
						toggleModal={this.toggleModalCutTable}
						dataListRender = {this.dataListRender}
						table_ids ={this.state.table_ids}
						room_id={this.props.match.params.room_id}
					/>

					<TableCureModal
						modalOpen={this.state.modalOpenTableCure}
						toggleModal={this.toggleModalTableCure}
						dataListRender = {this.dataListRender}
						table_ids ={this.state.table_ids}
						room_id={this.props.match.params.room_id}
					/>
				</Row>
			</Card>
		</Fragment>
    );
  }
}
