const signagreements = [
{
    id: 1,
    name: "Ajay", 
	vendor: "aj",   
	team: "boys", 
stage:1,	
	status: {
      statusid: 2,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Joel ", 
	vendor: "aj",     
	team: "boys", 
	stage:2,
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 3,
    name: "Fasal ",
	vendor: "aj",  
	team: "boys", 
	stage:3,
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default signagreements
