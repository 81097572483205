const partner = [
{
    id: 1,
    name: "Compliance", 
    color: "", 
	addedBy: "Emerald City",   
	addedOn: "05/13/2019",   
	createdOn: "2019-04-19 00:28:54",     
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Compliance",
    color: "",
	addedBy: "Emerald City",   
	addedOn: "05/13/2019",   
	createdOn: "2019-04-19 00:28:54",   
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default partner
