import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
const selectData = [
    { label: "$", value: "0", key: 0 },
    { label: "%", value: "1", key: 1 }
  ];
  const selectData1 = [
    { label: "Per Day", value: "1", key: 0 },
    { label: "Per Week", value: "2", key: 1 },
    { label: "Per Month", value: "3", key: 2 },
    { label: "Per Year", value: "4", key: 3 }
  ];
  const selectCategories = [
    { label: "Tissue Culture Clones", value: "1", key: 0 },
    { label: "Trim", value: "2", key: 1 },
    { label: "Fresh Frozen Flower", value: "3", key: 2 },
    { label: "Frozen Trim", value: "4", key: 3 }
  ];
  const selectType = [
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }
  ];
  const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline", "strike", "blockquote"],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
	[{ 'align': [] }],
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image","video"],	
    ["code-block"],
    ["clean"]
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "color",
  "background",
  "align",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "code-block"
];

class AddNewRequestsModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {					
		};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
 
  
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add New request
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
				     <Colxx lg="4">
					     <div className="text-center">
			               <i className="iconsminds-file-zip popup-icon para_size"></i>
						 </div> 
                         <div className="text-center grn-clr">
						   <h3>Assignment</h3>
                         </div>	
                         <div className="text-center fnt-10 ">
						 <h6  style={{fontSize:"12px"}}> Request an individual or group to accept and then begin work on a request</h6>
                         </div>						 
				     </Colxx>
					 <Colxx lg="4">
					    <div className="text-center">
			               <i className="iconsminds-files popup-icon para_size"></i>
						 </div> 
                         <div className="text-center grn-clr">
						   <h3>RFP</h3>
                         </div>	
                         <div className="text-center fnt-10 ">
						 <h6  style={{fontSize:"12px"}}> Request a proposal including cost for completing work for evaluation and then awarding</h6>
                         </div>		
				     </Colxx>
					 <Colxx lg="4">
					     <div className="text-center">
			               <i className="iconsminds-file-clipboard-file---text popup-icon para_size"></i>
						 </div> 
                         <div className="text-center grn-clr">
						   <h3>RFI</h3>
                         </div>	
                         <div className="text-center fnt-10 ">
						 <h6  style={{fontSize:"12px"}}> Request information such as capabilities, documents or availability for evaluation, not awarding</h6>
                         </div>		
				     </Colxx>
					 
				</Row>
				
				
				
											
		  </ModalBody>
		  
		   </AvForm>
	  </Modal>
	  );
  }
};

export default  AddNewRequestsModal ;
