import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input, CustomInput, Label, Button } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class CashExchange extends Component {
	
	constructor(props) {
		super(props);
		this.state={
			currencies:[],
			currency_code:'USD',
			received:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			},
			provided:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			},
			drawer_id:0,
			data_loaded:false,
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
		this.dataListRender();
	}
	
	dataListRender(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var user_id = localStorage.getItem('user_id');
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-cash-exchange',formData).then((res)=>{
			var data = res.data;
			
			if(data.currencies){
				var currencies=data.currencies;	
				this.setState({
					currencies,
					drawer_id:data.drawer_id,
					data_loaded:true
				});
			}else{
				this.setState({
					currencies:[],
					data_loaded:true
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	calculateTotal(received){
		var total=0;
		if(received!=null){
			total = parseInt(received['1_s'])+parseInt(received['2_s'])*2+parseInt(received['5_s'])*5+parseInt(received['10_s'])*10+parseInt(received['20_s'])*20+parseInt(received['50_s'])*50+parseInt(received['100_s'])*100+parseInt(received['cents'])*0.01+parseInt(received['nickles'])*0.05+parseInt(received['dimes'])*0.1+parseInt(received['quarters'])*0.25+received['50_cents']*0.5+parseInt(received['1_d'])
		}
		return parseFloat(total.toFixed(2));
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			var received_total=this.calculateTotal(this.state.received);
			var provided_total=this.calculateTotal(this.state.provided);
			
			if(received_total==0){
				swal("Warning!","Please enter the denomination received","warning");
				return;
			}else if(received_total!==provided_total){
				swal("Warning!","The denomination received and denomination provided should be the same","warning");
				return;
			}
			
			document.getElementById("cash-exchange-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("cashExchangeForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
            formData.append('drawer_id',this.state.drawer_id);
			formData.append('currency_code',this.state.currency_code);
			
			axios.post(REST_API_END_POINT+"drawer/check-drawer-stock",formData).then((res)=>{
				var data = res.data;
				
				if(data.proceed){
					var formData = new FormData(document.getElementById("cashExchangeForm"));
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);
					formData.append('drawer_id',this.state.drawer_id);
					axios.post(REST_API_END_POINT+'point-of-sale/cash-exchange',formData).then((res)=>{
						var data = res.data;
						
						document.getElementById("cash-exchange-btn").disabled=false;
						if(data.status==1){
							swal("Success",data.msg,"success");
							this.setState({
								received:{
									'1_s':0,
									'2_s':0,
									'5_s':0,
									'10_s':0,
									'20_s':0,
									'50_s':0,
									'100_s':0,
									'cents':0,
									'nickles':0,
									'dimes':0,
									'quarters':0,
									'50_cents':0,
									'1_d':0
								},
								provided:{
									'1_s':0,
									'2_s':0,
									'5_s':0,
									'10_s':0,
									'20_s':0,
									'50_s':0,
									'100_s':0,
									'cents':0,
									'nickles':0,
									'dimes':0,
									'quarters':0,
									'50_cents':0,
									'1_d':0
								},
							});
						}else{
							swal("Failed",data.msg,"warning");
						}

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("cash-exchange-btn").disabled=false;
					});
				}else{
					swal("There is no enough money in the drawer");
					document.getElementById("cash-exchange-btn").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("cash-exchange-btn").disabled=false;
			});

		}
	}
	
	render(){
		var received = this.state.received;	
		var provided = this.state.provided;
		var total=this.calculateTotal(received);
		var provided_total=this.calculateTotal(provided);
		
		const currencies = this.state.currencies;
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.state.currency_code });
		var received_field_view = [];
		if(typeof selected_currency!='undefined'){
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){				
				received_field_view.push(<Fragment key={key}>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<AvGroup >
											<AvField
												id={"received_"+key}
												name={"received_"+key}
												type="number"
												placeholder="0"
												min="0"
												step="1"
												value={this.state.received[key]!=0?this.state.received[key]:''}
												onChange={(e)=>{
													var received = this.state.received;
													if(e.target.value!=''){
														received[key]=e.target.value;
													}else{
														received[key]=0;
													}
													this.setState({
														received
													});
												}}											
											/>
										</AvGroup>
									</Colxx>
								</Fragment>)
				
			}
		}
		
		var provided_field_view = [];
		if(typeof selected_currency!='undefined'){
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){				
				provided_field_view.push(<Fragment key={key}>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<AvGroup >
										  <AvField
											id={key}
											name={key}
											type="number"
											placeholder="0"
											min="0"
											step="1"
											value={this.state.provided[key]!=0?this.state.provided[key]:''}
											onChange={(e)=>{
												var provided = this.state.provided;
												if(e.target.value!=''){
													provided[key]=e.target.value;
												}else{
													provided[key]=0;
												}
												this.setState({
													provided
												});
											}}
										
										  />
										</AvGroup>
									</Colxx>
								</Fragment>)
				
			}
		}
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.cash-exchange" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12" className="page-tabs">
						<Card className="mb-4">								
							<CardBody>
								<AvForm
									id="cashExchangeForm"
									className="av-tooltip tooltip-right-bottom"
									onSubmit={this.handleSubmit}
								  >
									<Row className="mt-2 d-none">
										<Colxx xxs="3">
											<Label>Currency</Label>
											<AvField
												type="select"
												name="currency_code"
												id="currency_code"
												value={this.state.currency_code}
												onChange={(e)=>{ this.setState({ currency_code:e.target.value }) }}
												validate={{
													required: {
														value: true,
														errorMessage: "This field is required."
													}
												}}
											>
												{currencies.map(opt=>{
													return <option key={opt.id} value={opt.currency_code} >{opt.country_name+" - "+opt.currency_code}</option>
												})}
											</AvField>
										</Colxx>
									</Row>
									
									{this.state.data_loaded ? (this.state.drawer_id==0 ? <Row className="text-center align-items-center" style={{minHeight:'200px'}}>
										<Colxx md="12">
											<h2 className="text-danger">No drawer is assigned to you. Please contact administrator to get a drawer assigned to you.</h2>
										</Colxx>
										</Row>:<Fragment>
										<Row className="align-items-center">
											<Colxx md="6">
												<Row className="align-items-center">
													<Colxx xxs="12">
														<h2>Denomination Received</h2>
													</Colxx>
													{received_field_view}
													
													<Colxx xxs="12">
														<Row className="mt-4">
															<Colxx xxs="3">
																<h3>Total</h3>
															</Colxx>
															<Colxx xxs="3">
																<AvGroup >
																  <AvField
																	id="received_total"
																	name="received_total"
																	type="text"
																	readOnly={true}
																	placeholder="0"
																	value={total.toFixed(2)}
																  />
																</AvGroup>
															</Colxx>
															<Colxx xxs="2"></Colxx>
															<Colxx xxs="3"></Colxx>
														</Row>
													</Colxx>
												</Row>
											</Colxx>
											<Colxx md="6" style={{borderLeft:'1px solid #ccc'}}>
												<Row className="align-items-center">
													<Colxx xxs="12">
														<h2>Denomination Provided</h2>
													</Colxx>
													{provided_field_view}
													
													<Colxx xxs="12">
														<Row className="mt-4">
															<Colxx xxs="3">
																<h3>Total</h3>
															</Colxx>
															<Colxx xxs="3">
																<AvGroup >
																  <AvField
																	id="total"
																	name="total"
																	type="text"
																	readOnly={true}
																	placeholder="0"
																	value={provided_total.toFixed(2)}
																  />
																</AvGroup>
															</Colxx>
															<Colxx xxs="2"></Colxx>
															<Colxx xxs="3"></Colxx>
														</Row>
													</Colxx>
												</Row>
											</Colxx>
										</Row>
										<Row className="align-items-center">
											<Colxx xxs="12">
												<Button color="primary" id="cash-exchange-btn" >
													<IntlMessages id="customer.submit" />
												</Button>
											</Colxx>
										</Row>
									</Fragment>):""}
								</AvForm>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
			</Fragment>
		);
	}
}
