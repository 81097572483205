import React, { Component, Fragment  } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card,Input,
  InputGroupAddon,CardBody
} from "reactstrap";
import {CardHeader, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";

import Harvest from "../../../components/batchoption/Harvest";
import Cure from "../../../components/batchoption/Cure";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from 'moment';
var dataCrypto = new SimpleCrypto(dataEncryptKey);

   
class BatchOption extends Component{
	constructor(props) {
		super(props);
		
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
			activeFirstTab: "1"
		};
	}
      
    toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
   
	render() {	  
		  
		return (
				<Fragment>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.batchoption" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					
					<Card>
						<Row>
							<Colxx lg="12" className="page-tabs">
								<div className="p-4 mt-4">								
									<CardHeader>
										<Nav tabs className="card-header-tabs ">									
											<NavItem>
												<NavLink
													className={classnames({
													  active: this.state.activeFirstTab === "1",
													  "nav-link": true
													})}
													onClick={() => {
													  this.toggleFirstTab("1");
													}}
													to="#"
												>
													Harvest
												</NavLink>
											</NavItem>
											<NavItem>
												<NavLink
													className={classnames({
													  active: this.state.activeFirstTab === "2",
													  "nav-link": true
													})}
													onClick={() => {
													  this.toggleFirstTab("2");
													}}
													to="#"
												>
													Cure
												</NavLink>
											</NavItem>
										</Nav>
									</CardHeader>
								</div>	
							</Colxx>
						</Row>
						
						<TabContent activeTab={this.state.activeFirstTab}>
							<TabPane tabId="1">
								<Row className="p-0">
								   <Colxx lg="12">                    
									<Harvest dataListRender = {this.dataListRender}  />    
								  </Colxx>
								</Row>
							</TabPane>
						</TabContent>
						
						<TabContent activeTab={this.state.activeFirstTab}>
						  <TabPane tabId="2">
							<Row className="p-0">
							   <Colxx lg="12">                    
								<Cure dataListRender = {this.dataListRender}  />                      
							  </Colxx>
							</Row>
						  </TabPane>
						</TabContent>					
					</Card>
				</Fragment>
	  );
  }
};

export default BatchOption;
