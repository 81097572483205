import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input, DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddPayModal from "../../../containers/bankmanagement/AddPayModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Payouts extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.state = {
			modalOpenAdd: false,
			assigned_drawer_id: 0,
			assigned_drawer: false,
			search: '',
			payouts:[],
			currencies:[],
			currency_symbols:{},
			user_privileges:[],
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
    toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.payouts.forEach(x => {
                newSelected.push(x.pay_id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}

	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="payout";
		
        if(status===2){
            msg="Reject "+msg_word+" ?";
            success_msg="The "+msg_word+" has been rejected.";
            failure_msg="Failed to reject "+msg_word;
        }else if(status===1){
            msg="Approve "+msg_word+" ?";
            success_msg="The "+msg_word+" has been approved.";
            failure_msg="Failed to approve "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('pay_id',selectedIds);
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'payout/approve-payout',formData).then((res)=>{
						var data = res.data;
					
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	   
	activeItem=(e,pay_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(pay_id);
			
		}else{
		  var x=selectedIds.indexOf(pay_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.payout.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		var user_id = localStorage.getItem('user_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'payout/get-all-payouts',formData).then((res)=>{
			var data = res.data;
			var assigned_drawer_id = data.drawer_id;
			var assigned_drawer = data.drawer_id!=0 ? true:false;
			var currencies=data.currencies;
			
			var currency_symbols = {};
			for(let c  of currencies){
				currency_symbols[c.currency_code] = c.currency_symbol;
			}
			
			if(data.payouts){
				this.setState({
					payouts: data.payouts,					
					selectAll:0,
					selectedIds:0,
					assigned_drawer,
					assigned_drawer_id,
					currencies,
					currency_symbols
				});
			}else{
				this.setState({
					payouts: [],
					selectAll:0,
					selectedIds:0,
					assigned_drawer,
					assigned_drawer_id,
					currencies,
					currency_symbols
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	render() {
		const currency_symbols = this.state.currency_symbols;
		
		let data = this.state.payouts
		if (this.state.search) {
			data = data.filter(row => {
				return row.amount.toLowerCase().includes(this.state.search) || row.reason.toLowerCase().includes(this.state.search) || row.authorized_by.toLowerCase().includes(this.state.search) || row.created_at_date.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Amount",
				accessor: "amount",
				Cell: props => <p className="list-item-heading">{currency_symbols[props.original.currency_code]+" "+props.value}</p>
			},
			{
				Header: "Reason",
				accessor: "reason",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Created By",
				accessor: "authorized_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Created At",
				accessor: "created_at_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => <Badge color={parseInt(props.value)==1?"success":(parseInt(props.value)==2?"danger":"secondary")} pill>{parseInt(props.value)===1?"Approved":(parseInt(props.value)===2?"Rejected":"Pending")}</Badge>
			},
			{
				Header: "",
				width:80,
				accessor: "pay_id",
				sortable: false,
				Cell: props => { return props.original.status==0 && this.state.user_privileges.indexOf(162)!==-1 ? (<div className="action-btns w-9 w-sm-100"><Button
									  color="primary"
									  size="sm"
									  className=""
									  onClick={event =>this.deleteItem(event,props.value,1)} 
									>
									Approve 
								</Button></div>): ""}
			},
			{
				Header: "",
				width:80,
				accessor: "pay_id",
				sortable: false,
				Cell: props => { return props.original.status==0 && this.state.user_privileges.indexOf(162)!==-1 ? (<div className="action-btns w-9 w-sm-100"><Button
									  color="danger"
									  size="sm"
									  className=""
									  onClick={event =>this.deleteItem(event,props.value,2)} 
									>
									  Reject 
							</Button></div>): ""}
			}
		];
		return (<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.payouts" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
				<Row>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
				</Row>
				
				<Card className="p-4">
					<Row>
						<Colxx xxs="12">
							{this.state.assigned_drawer ? <div className="float-sm-right">
								{" "}<Button																		 
									  color="primary"
									  size="sm"
									  className=""
									  onClick={this.toggleModalAdd}
									>
									Add Payout		  
								</Button>
							</div>:""}
							
							<div className="mb-4 dropdown-edit">						
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value})}
										/>
									</Colxx>
								</FormGroup>			
								
								<ReactTable
								  data={data}
								  columns={dataTableColumns}
								  defaultPageSize={10}
								  filterable={false}
								  showPageJump={false}
								  responsive
								  PaginationComponent={DataTablePagination}
								  showPageSizeOptions={true}
								  defaultSorted={[{id: "send_on",desc: true}]}
								/>
							</div>					
						</Colxx>
						<AddPayModal
							modalOpen={this.state.modalOpenAdd}
							toggleModal={this.toggleModalAdd}
							dataListRender={this.dataListRender}
							drawer_id={this.state.assigned_drawer_id}
							currencies={this.state.currencies}
						/>
					</Row>
				</Card>
			</Fragment>
		);
	}
}
