import React, { Component } from "react";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey, JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ViewTestResult extends Component{
	constructor(props) {
		super(props);	
		
		this.state={
			test_results:[]
		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			if (prevProps.bucket_data!== this.props.bucket_data && this.props.bucket_data!=null) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('bucket_id',this.props.bucket_data.bucket_id);	
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'distillate-bucket/view-test-result',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						var test_results=data.test_results;
						
						this.setState({ 
							test_results
						});
					}else{
						this.setState({ 
							test_results:[]
						});
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
            }
        }
    }
	
	deleteItem = (e,test_id)  => {
		var msg="Delete this test result?";
        
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('test_id',test_id);
				formData.append('bucket_id',this.props.bucket_data.bucket_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'distillate-bucket/delete-test-result',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						var test_results = this.state.test_results;
						test_results = test_results.filter(r=>{ return r.test_id!=test_id });
						this.setState({ test_results })
						swal("Success",data.msg,"success");
					}else{
						swal("Failed",data.msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}

	render() {	
		const { test_results } = this.state;
		const { bucket_data } = this.props;
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					View Test Result
				</ModalHeader>
		 
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<h4>Barcode</h4>						
							<h3>{bucket_data!=null ? bucket_data.batch_uid:""}</h3>
						</Colxx>										
					</Row>		
					<hr/>
					<Table borderless>
						<thead>
							<tr>
								<th className="text-muted mb-2">ATTACHED FILES</th>
								<th className="text-muted mb-2"></th>
								<th className="text-muted mb-2"></th>		
							</tr>
						</thead>					
						<tbody>
							{test_results.map((row,index)=>{
								return(<tr>
									<td><a href={REST_API_BASE_URL+'uploads/test-result/'+row.upload_file_name} target="_blank" >{row.upload_file_name}</a></td>
									<td>{row.file_size}kb</td>
									<td>
										<Button
											color="danger"
											size="sm"
											className=""
											type="button"
											onClick={(e)=>{ this.deleteItem(e,row.test_id) }}
										>
											Delete
										</Button>
									</td>
								</tr>)
							})}                                                     
						</tbody>
					</Table>		
				</ModalBody>
				<ModalFooter>
				
				</ModalFooter>
			</Modal>
		);
	}
};
