import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import {	
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

class Reminder extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require("mousetrap");
	this.handleSubmit = this.handleSubmit.bind(this);
    this.dataListRender = this.dataListRender.bind(this);

    this.state = {
     startDate: null,
    };
  }
	
    handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
		
			if(this.state.startDate==null){
				swal("Warning!","Please select date","warning");
				return;
			}
			
			document.getElementById("add-remainder").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantReminderForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_id',this.props.plant_id);
			

			axios.post(REST_API_END_POINT+'growhouse/add-plant-remainder',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_reminder");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-remainder").disabled=false;
				this.dataListRender();
				this.setState({ startDate:null })
				this.form && this.form.reset();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-remainder").disabled=false;
			});

		}
	}
  
    componentDidMount(){
	   this.dataListRender();
	}

	
    dataListRender() {
		
		var plant_remainders=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('plant_id',this.props.plant_id);
		axios.post(REST_API_END_POINT+'growhouse/get-plant-remainders',formData).then((res)=>{
			var data = res.data;
			
				plant_remainders=data.plant_remainders;
				
			
		
			if(data.plant_remainders){
				this.setState({
			        plant_remainders: data.plant_remainders,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					plant_remainders: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}

  render() {
	  
	 let data = this.state.plant_remainders  
	  
	  
    const dataTableColumns = [			  
	  {
		Header: "Remainder",
		accessor: "message",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Date",
		accessor: "date",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  }
	];	
    return( 
      <Fragment>       
        <div className="p-4">	
         <div id="api_message_reminder"></div>		
		  <div className="mb-4 dropdown-edit">
			{this.props.user_privileges.indexOf(145)!==-1 ? <AvForm
		        id="plantReminderForm"
				ref={c => (this.form = c)}
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<Row className="mt-2">
					<Colxx lg="6" className="mt-2">
					<Label>Date<span className="text-danger">*</span></Label>
					 <DatePicker
					    selected={this.state.startDate}
						onChange={this.handleChangeDate}
						className="p-0"
						placeholderText="Date"
						dateFormat="MM/DD/YYYY"
						id="remainder"
						name="remainder_date"
						autoComplete = "off"
					  />
					</Colxx>
			
					<Colxx xxs="6"className="mt-2">	
                        <Label>Remainder<span className="text-danger">*</span></Label>					
						 <AvGroup className="error-t-negative">
						  <AvField
							id="note"
							name="remainder_msg"
							type="text"	
                            validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}									
						  />
						 </AvGroup>
					</Colxx>
				</Row>
				<Row className="mt-2">
					<Colxx xxs="6">
							<Button
								id="add-remainder"
								color="primary"
								size="sm"
								className=""
								>
								Add Reminder
							</Button>					
					</Colxx>					
				</Row>
			</AvForm>:""}
			<hr/>
				<ReactTable
				  data={data}
				  TbodyComponent={CustomTbodyComponent}
				  columns={dataTableColumns}
				  defaultPageSize={5}
				  showPageJump={false}
				  showPageSizeOptions={true}
				  showPagination={true}
				  PaginationComponent={DataTablePagination}
				/>
			</div>
		</div>	         
      </Fragment>
    );
  }
  
};
export default Reminder;
