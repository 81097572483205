const products = [
  {
    id: 1,
    title: 'Portfolio',
    img: 'http://skyniche.website/new-motagistics/uploads/partners/download_(15)3.jpeg',
    category: 'Portfolio',
    createDate: '02.04.2018',
    status: 'ON HOLD',
    statusColor: 'primary',
    description: 'Wedding cake with flowers Macarons and blueberries',
    sales: 1647,
    stock: 62
  }
]

export default products
