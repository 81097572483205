import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvInput, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, 
  InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { REST_API_BASE_URL } from "../../constants/defaultValues";


export default class CashPaymentModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {	
			cash_total:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			},
			total_due:0,
		};	
	}
	
	componentDidMount(){
		const payment_data = this.props.payment_data;
		var cash_total = this.state.cash_total;
		if(payment_data!=null){
			cash_total={
				'1_s':payment_data['1_s'],
				'2_s':payment_data['2_s'],
				'5_s':payment_data['5_s'],
				'10_s':payment_data['10_s'],
				'20_s':payment_data['20_s'],
				'50_s':payment_data['50_s'],
				'100_s':payment_data['100_s'],
				'cents':payment_data['cents'],
				'nickles':payment_data['nickles'],
				'dimes':payment_data['dimes'],
				'quarters':payment_data['quarters'],
				'50_cents':payment_data['50_cents'],
				'1_d':payment_data['1_d']
			}
		}
		this.setState({	cash_total, total_due:this.props.total_due+payment_data['cash'] });
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0){
			var payment_data = this.props.payment_data;
			var cash_total = this.state.cash_total;
			
			payment_data['1_s'] = cash_total['1_s'];
			payment_data['2_s'] = cash_total['2_s'];
			payment_data['5_s'] = cash_total['5_s'];
			payment_data['10_s'] = cash_total['10_s'];
			payment_data['20_s'] = cash_total['20_s'];
			payment_data['50_s'] = cash_total['50_s'];
			payment_data['100_s'] = cash_total['100_s'];
			payment_data['cents'] = cash_total['cents'];
			payment_data['nickles'] = cash_total['nickles'];
			payment_data['dimes'] = cash_total['dimes'];
			payment_data['quarters'] = cash_total['quarters'];
			payment_data['50_cents'] = cash_total['50_cents'];
			payment_data['1_d'] = cash_total['1_d'];
			
			if(this.state.cash_total!=null){
				payment_data['cash'] = parseInt(cash_total['1_s'])+parseInt(cash_total['2_s'])*2+parseInt(cash_total['5_s'])*5+parseInt(cash_total['10_s'])*10+parseInt(cash_total['20_s'])*20+parseInt(cash_total['50_s'])*50+parseInt(cash_total['100_s'])*100+parseInt(cash_total['cents'])*0.01+parseInt(cash_total['nickles'])*0.05+parseInt(cash_total['dimes'])*0.1+parseInt(cash_total['quarters'])*0.25+cash_total['50_cents']*0.5+parseInt(cash_total['1_d'])
			}else{
				payment_data['cash'] = 0;
			}
			
			this.props.updatePaymentData(payment_data);
			this.props.toggleModal();
		}
	}
  
	render() {

        var cash_total = this.state.cash_total;	
		var total=0;
		if(this.state.cash_total!=null){
			total = parseInt(cash_total['1_s'])+parseInt(cash_total['2_s'])*2+parseInt(cash_total['5_s'])*5+parseInt(cash_total['10_s'])*10+parseInt(cash_total['20_s'])*20+parseInt(cash_total['50_s'])*50+parseInt(cash_total['100_s'])*100+parseInt(cash_total['cents'])*0.01+parseInt(cash_total['nickles'])*0.05+parseInt(cash_total['dimes'])*0.1+parseInt(cash_total['quarters'])*0.25+cash_total['50_cents']*0.5+parseInt(cash_total['1_d'])
		}
		
		var total_due=this.state.total_due-total;
		if(parseFloat(total_due.toFixed(2))==0){
			total_due=0;
		}
		
		const currencies = this.props.currencies;
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.props.currency_code });
		var field_view = [];
		if(typeof selected_currency!='undefined'){
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){
				
				field_view.push(<Fragment key={key}>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<AvGroup className="denomination-qty">
											<InputGroup>
												<InputGroupAddon addonType="prepend" onClick={e=>{
													var cash_total = this.state.cash_total;
													cash_total[key]+=1;
													this.setState({
														cash_total
													});
												}} >+</InputGroupAddon>
												<AvField
													id={key}
													name={key}
													type="number"
													placeholder={currency_struct[key].alt}
													min="0"
													step="1"
													value={this.state.cash_total[key]!=0?this.state.cash_total[key]:''}
													onChange={(e)=>{
														var cash_total = this.state.cash_total;
														if(e.target.value!=''){
															cash_total[key]=e.target.value;
														}else{
															cash_total[key]=0;
														}
														this.setState({
															cash_total
														});
													}}
												/>
												<InputGroupAddon addonType="append" onClick={e=>{
													var cash_total = this.state.cash_total;
													if(cash_total[key]!=0){
														cash_total[key]-=1;
														this.setState({
															cash_total
														});
													}
												}} >-</InputGroupAddon>
											</InputGroup>
										</AvGroup>
									</Colxx>
								</Fragment>)
				
			}
		}
  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="lg"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Cash Payment
			</ModalHeader>
			<AvForm
		        id="cashMoneyForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row className="align-items-center">
						{field_view}
					</Row>
					
					<Row className="mt-4">
						<Colxx xxs="3">
							<h3>Cash</h3>
						</Colxx>
						<Colxx xxs="6">
							<AvGroup className="error-t-negative">
							  <AvField
								id="cash"
								name="cash"
								type="text"
								readOnly={true}
								placeholder="0"
								value={total.toFixed(2)}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row className="mt-4">
						<Colxx xxs="3">
							<h3>Total Due</h3>
						</Colxx>
						<Colxx xxs="6">
							<AvGroup className="error-t-negative">
								<AvField
									id="total_due"
									name="total_due"
									type="text"
									readOnly={true}
									placeholder="0"
									value={total_due.toFixed(2)}
								/>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter className="justify-content-start">
					<Button color="primary" id="add-money" >
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};
