const updates = [
{
  operation:"completed an order #74",
  doneby:"Motagistics",
  filedateandtime:"08-26-2019 07:24:12",
 
},


]

export default updates

