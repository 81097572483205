import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, CustomInput, FormGroup, Input } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, SITE_URL} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Taskactivities extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		 
			activities:[],
			search: '',
		};
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
    
	dataListRender() {		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('task_id',this.props.match.params.task_id);
		
		axios.post(REST_API_END_POINT+'task/view-activity',formData).then((res)=>{
			var data = res.data;
		
			if(data.activities){
				this.setState({
					activities: data.activities,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					activities: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	render() {
		let data = this.state.activities
		if (this.state.search) {
			data = data.filter(row => {
				return row.username.toLowerCase().includes(this.state.search) || row.message.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: "User",
				accessor: "username",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Action",
				accessor: "message",
				Cell: props => <p className="text-muted"><div className="d-inline" dangerouslySetInnerHTML={{ __html: props.value }} ></div></p>
			},
			{
				Header: "URL",
				accessor: "url",
				Cell: props => <p className="text-muted"><a href={SITE_URL+props.value} className="btn-link" >URL</a></p>
			},
			{
				Header: "Date",
				accessor: "timestamp",
				Cell: props => <p className="text-muted">{moment(props.value*1000).format('MM/DD/YYYY HH:mm')}</p>
			}
		];
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.task-activities" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				
				<Card className="p-4">
					<Row>				
						<Colxx xxs="12">
							<div id="api_message"></div>
						</Colxx>
						
						<Colxx xxs="12">
							<NavLink to={"/app/jobs/view-job/"+this.props.match.params.task_id} className="mb-2 btn btn-primary " >
								Back
							</NavLink>{" "}
						</Colxx>		

						<Colxx xxs="12">
							<div className="mb-4 dropdown-edit">						
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value})}
										/>
									</Colxx>
								</FormGroup>			
								<ReactTable
									data={data}
									columns={dataTableColumns}
									defaultPageSize={10}
									filterable={false}
									showPageJump={false}
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{id: "send_on",desc: true}]}
								/>
							</div>
						</Colxx>
					</Row>
				</Card>
			</Fragment>
		);
	}
}
