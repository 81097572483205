import React, { Component, Fragment }  from "react";
import moment from "moment";
import Barcode from 'react-barcode';

export default class PrintProductLabel extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const { print_pos_label, facility  } = this.props;

		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 2in 1in !important;
						  margin:0;
						}
						p{
							font-size:10px;
						}
					}
				`}} />
				{print_pos_label['order_products'].map((product,index)=>{
					let weight = ' G';
					if(product['qty_type']==0||product['qty_type']==4||product['qty_type']==5||product['qty_type']==6||product['qty_type']==7){
						weight = ' MG';
					}else if(product['qty_type']==2){		
						weight = ' OZ';
					}else if(product['qty_type']==3){		
						weight = ' Unit';
					}
					return (<Fragment key={index}>
						<div className="print_pos_label" style={{padding:'2px 10px 2px 10px'}}  >                  
							<div style={{width:'2in', height:'1in', fontSize:'10px', fontFamily:"Arial, 'Times New Roman'"}} className="panel-body">
								{/*<div className="row_data">
									Lic#: {facility['license_number']}<br/>								
									Date: {moment(product.timestamp*1000).format("MM/DD/YYYY")}<br/>								
									Total Weight: {product['product_qty']+' x '+product['variation_name']}<br/>								
									Usable Weight: {product['quantity']+''+weight}
								</div>*/}
								{/*product['mip_weight']!=null && product['product_type']==1 ? <div className="row_data">
									MIP Weight: {product['mip_weight']+' GR'}
								</div>:""*/}
								<div className="row_data">
									{/*Batch # {product['barcode']}<br/>*/}
									<strong>{product['product_name']}</strong><br/>
									Price: ${parseFloat(product['product_price']).toFixed(2)}
								</div>
								{/*(typeof product['additives']!='undefined' && product['additives']!=null) ? <Fragment><div className="row_data">Additives: {product['additives'].join(", ")}</div></Fragment>:""*/}
								<div className="row_data text-center">
									<Barcode value={product.barcode} height={20} width={0.95} margin={0} marginTop={5} displayValue={false} />
									<p className="text-center">{(product.barcode.match(/.{1,4}/g)).join(' ')}</p>
								</div>
							</div>
						</div>
						<div style={{pageBreakBefore:"always"}}></div>
						</Fragment>
					)
				})}
			</div>
		);
	}
};