import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardBody, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import { zeroPad } from "../../helpers/Utils";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

import InvoiceModalContent from "./InvoiceModalContent";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class InvoiceModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			order_data: null,
			order_products: null,
			facility_data: null,
			currencies: [],
			additionalRequirementsData:[],
		};
	
    }
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'point-of-sale/invoice',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data,
							order_products:data.order_products,
							facility_data:data.facility_data,
							currencies:data.currencies,
							additionalRequirementsData:data.additional_requirements,
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
		
    render() {
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Invoice
				</ModalHeader>
			 
				<ModalBody >
					<InvoiceModalContent
						ref={el => (this.componentRef = el)}
						{...this.state}
					/>
				</ModalBody>
				<ModalFooter>
					<ReactToPrint
						trigger={() => <Button color="primary" ><IntlMessages id="customer.print" /></Button>}
						content={() => this.componentRef}
						copyStyles={true}
						pageStyle={""}
					/>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
  }
};
