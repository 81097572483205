import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup,AvRadio,AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,Input,FormGroup,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Table
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DataTablePagination from "../../components/DatatablePagination";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';
import ReactTable from "react-table";
import { NavLink } from "react-router-dom";

const buy_types={
				'1':'All Strains',
				'2':'Specific Strains',
				'3':'All Categories',
				'4':'Specific Categories',
				'5':'All Products',
				'6':'Specific Products',
			}

class ViewAllBogoOfferModal extends Component{
	constructor(props) {
		super(props);
				
		this.state = {
			search:""
		};	
	}

	render() {
	  
	    let data = this.props.bogos;
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.code.toLowerCase().includes(this.state.search.toLowerCase()) || row.item_name.toLowerCase().includes(this.state.search.toLowerCase()) || buy_types[row.buy_type].toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Code",
				accessor: "code",
				Cell: props => <p className="text-muted">{props.original.auto_apply=="0" ? props.value:""}</p>
			},
			{
				Header: "Buy Product in",
				accessor: "buy_type",
				Cell: props => <p className="text-muted">{buy_types[props.value]}</p>
			},
			{
				Header: "Specific Items",
				accessor: "item_name",
				Cell: props => <p className="text-muted">{props.original.buy_type==2||props.original.buy_type==4||props.original.buy_type==6 ? props.value:""}</p>
			},
			{
				Header: "Start Date",
				accessor: "start_date",
				Cell: props => <p className="text-muted mb-0">{moment(props.value).format("DD-MM-YYYY")}</p>
			},
			{
				Header: "End Date",
				accessor: "end_date",
				Cell: props => <p className="text-muted mb-0">{moment(props.value).format("DD-MM-YYYY")}</p>
			}		  
		];
	  
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					All BOGO Offers
				</ModalHeader>
				
				<ModalBody>
					<Row>          
						 <Colxx xxs="12">
							<div className="mb-4 dropdown-edit">					
								<FormGroup row>
									<Colxx lg="12">
										<Input
										  type="text"
										  name="table_search"
										  id="bogo_table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value})}
										/>
								  </Colxx>
								</FormGroup>
								<Row>          
									<Colxx lg="12">					
										<ReactTable
										  data={data}
										  columns={dataTableColumns}
										  defaultPageSize={5}
										  filterable={false}
										  showPageJump={false}
										  PaginationComponent={DataTablePagination}
										  showPageSizeOptions={false}
										  defaultSorted={[{id: "send_on",desc: true}]}
										/>
									</Colxx>
								</Row>
							</div>				
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
	}
};

export default ViewAllBogoOfferModal;
