import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,Input,
  CustomInput,
  Button,
  Modal,
  Badge,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon ,DropdownToggle,
    DropdownItem,
    DropdownMenu,
    ButtonDropdown,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";

import { NavLink } from "react-router-dom";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import AddStrainModal from "../../containers/inventory/AddStrainModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import RemoveSectionInspection from "../../components/template/RemoveSectionInspection";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
const selectData = [
    { label: "$", value: "0", key: 0 },
    { label: "%", value: "1", key: 1 }
  ];
  const selectRoom = [  
    { label: "Room 2", value: "4", key: 0 }
  ];
  const selectQuantity = [
    { label: "Gram", value: "1", key: 0 },
    { label: "Lbs", value: "2", key: 1 },
    { label: "Unit", value: "3", key: 2 }
  ];
  const selectVendor = [
    { label: "Phinest ", value: "1", key: 0 }
  ];
  const selectAdditives = [
    { label: "Additives ", value: "1", key: 0 }
  ];
  const selectTaxCategory = [
    { label: "Sales Tax", value: "8", key: 0 },
    { label: "Service Tax", value: "1", key: 1 }
  ];
  const selectWorkflow = [
    { label: "Test Workflow 3", value: "18", key: 0 },
    { label: "Requirement Workflow", value: "17", key: 1 },
    { label: "Basic Workflow", value: "16", key: 2 },
    { label: "Test Workflow 2", value: "15", key: 3 }
  ];
  const selectStrain = [
    { label: "Tri Fi Cookies", value: "12", key: 1 },
    { label: "Orange Creamsicle", value: "13", key: 2 },
    { label: "Cuvee Cookies", value: "14", key: 3 }
  ];
  const selectJoin = [
    { label: "B2C", value: "15", key: 1 },
    { label: "Free Buyer", value: "14", key: 2 },
    { label: "Free Seller", value: "13", key: 3 },
    { label: "VIP", value: "12", key: 4 }
  ];

  const facilitiesType =[
	  { label: "Distribution", value: "1",key: 0 },
	  { label: "Genetics", value: "2",key: 1 },
	  { label: "501", value: "3",key: 2 },
	  { label: "Station", value: "4",key: 3 },
	  { label: "Waxy", value: "5",key: 4 }  
  ];
  
  const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline", 
  "list",
  "bullet",
  "indent", 
];

class AddNewPaymentModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
    modalOpenAdd: false,
	selectStrainType:null,
	selectTaxType:null,
	selectVendorType:null,
	selectAdditivesType:null,
	isOpenSizingSm: false,
	isOpenSizingSm1: false,
	selectJoinType: { label: "B2C", value: "15", key: 1 },
	selectRoomType:{ label: "Room 2", value: "4", key: 0 },
	selectQuantityType: { label: "Gram", value: "1", key: 0 },
	textarea_content:"",
	search: '',


	files:"",
		};
	this.changeTextarea = this.changeTextarea.bind(this);
  }
   onChangeHandler = e => {
    this.setState({ files: e.target.files });
  };
	handleCheckClick = () => {
	  this.setState({ checked: !this.state.checked });
	}
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleSizingSm = () => {
    this.setState(prevState => ({
      isOpenSizingSm: !prevState.isOpenSizingSm
    }));
  };
	toggleSizingSm1 = () => {
    this.setState(prevState => ({
      isOpenSizingSm1: !prevState.isOpenSizingSm1
    }));
  };

  onChange = e => {
    switch (e.target.name) {
      case 'thumbnail':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };
	onMultiFileChange = e => {
		switch (e.target.name){
			case 'files[]':
			if(e.target.files[0]){
				var filenames_obj=e.target.files;
				var filenames=[];
				for (var i = 0; i < filenames_obj.length; i++) {
					filenames.push(filenames_obj[i].name);
				}
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=filenames.join(',');
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		}
	};
	showAnother = e =>{
	if(e.currentTarget.textContent==='Add another'){
		document.getElementById('show_another').style.display='block';
	}
  }
  changeTextarea(new_textarea_content){		
		this.setState({
			  textarea_content: new_textarea_content
		});
	}
	  
  addTextContent = () =>{	
		if(this.props.item.textarea){
			var current_pos=this.getCursorPos(this.props.item.textarea[0]);
			let position = current_pos ? current_pos.start : 0;
			let endPos = current_pos ? current_pos.end : 0;
			let value = this.props.item.textarea[0].value.substring(0, position)+'{{'+(this.props.item.text.toUpperCase().split(' ').join('_'))+'}}' + this.props.item.textarea[0].value.substring(endPos, this.props.item.textarea[0].value.length);
			this.props.stateupdatingcallback(value);
		}
  }  
  
  switchChange = (status,e) =>{
	  if(status){	
		document.getElementById('show_custom').style.display='block';
	}else{
		document.getElementById('show_custom').style.display='none';
	  }
  }
	switchChange1 = (status,e) =>{
	  if(status){	
		document.getElementById('show_custom_toggle').style.display='block';
	}else{
		document.getElementById('show_custom_toggle').style.display='none';
	  }
  }

  render() {
let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
		    <div className="">
              Generate Invoice
			</div>
			<div>
			  <Badge className="" color="secondary">New</Badge>
			</div>
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Title<span className="text-danger">*</span></Label>
						  <AvField							
							name="company_name[]"
							type="text"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>	
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Request<span className="text-danger">*</span></Label>
						 <div className="mb-4 dropdown-edit">
						     <FormGroup row>
							    <Colxx lg="12">
								<Select
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						        </Colxx>
						     </FormGroup>	
					     </div>
						 </AvGroup>
					</Colxx>	
				</Row>
				<Row>
				         <Colxx lg="12">
			                  <Label><IntlMessages id="Due" /><span className="text-danger">*</span></Label>
						         <DatePicker
									  id="dob"
									  name="dob"
									  placeholder="SetDate"
									  validate={{
									  required: {
									  value: true,
									  errorMessage: "This field is required."
									  }
									}}
									className="mb-5"
									selected={this.state.startDate}
									onChange={this.handleChangeDate}
									placeholderText=""
									dateFormat="DD/MM/YYYY"
								  />
						  </Colxx>
				</Row>
			   <Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Total Amount<span className="text-danger">*</span></Label>
						  <AvField							
							name="company_name[]"
							type="text"
							readOnly="readonly"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>	
				</Row>
				<Row>
				<Colxx lg="12">
				<AvForm
					className="av-tooltip tooltip-right-bottom mt-2"
					onSubmit={this.handleSubmit}
					>
					<Label><IntlMessages id="customer.notes" /></Label>	&nbsp;&nbsp;
					<AvGroup className="error-t-negative">
					  <AvField
						id="notes"
						name="notes"
						type="textarea"
						value={this.state.textarea_content}
						placeholder="Please mention your comments here"
						rows="3"
						validate={{
						  minLength: {
							value: 10,
							errorMessage:
							  "Your message must have minimum of 10 characters"
						  },
						}}
					  />
					</AvGroup>					
				</AvForm>	
                </Colxx>				
                </Row>					
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			 Generate  
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddNewPaymentModal;
