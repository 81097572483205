import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input,  DropdownItem, CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddSackModal from "../../../containers/equipments/sacks/AddSackModal";
import EditSackModal from "../../../containers/equipments/sacks/EditSackModal";
import ViewSackModal from "../../../containers/equipments/sacks/ViewSackModal";
import FillSackModal from "../../../containers/equipments/sacks/FillSackModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,BASE_URL} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Sacks extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		  	
			sack_id:0,		  
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenView: false,
			modalOpenFill: false,
			sacks: [],
			search: '',
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,sack_id=0) => {
		this.setState({
			sack_id: parseInt(sack_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	toggleModalView = (e,sack_id=0) => {
		this.setState({
			sack_id: parseInt(sack_id),
			modalOpenView: !this.state.modalOpenView,
		});
	};
	
	toggleModalFill = (e,sack_id=0) => {
		this.setState({
			sack_id: parseInt(sack_id),
			modalOpenFill: !this.state.modalOpenFill,
		});
	};
    
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.sacks.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
    
     componentDidMount(){
	   this.dataListRender();
	  }	
	  
    	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="sock";
		
		if(selectedIds.length>1){
			msg_word="socks";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-sacks").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('sack_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'processor/delete-sacks',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-sacks").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-sacks").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	} 
	
	activeItem=(e,sack_ids)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(sack_ids);
			
		}else{
		  var x=selectedIds.indexOf(sack_ids);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.sacks.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/get-all-sacks',formData).then((res)=>{
			var data = res.data;
		
			if(data.sacks){
				this.setState({
					sacks: data.sacks,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					sacks: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}	
	
	
	
  render() {
	  let data = this.state.sacks
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.capacity_converted.toLowerCase().includes(this.state.search) || row.created_on.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "container_id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"sack_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading"><img src={BASE_URL+"processor/"+props.original.thumbnail} className="inventory-list-thumb" /> <span>{props.value}</span></p>
			},
			{
				Header: "Capacity",
				accessor: "capacity_converted",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Available Qty",
				accessor: "available_qty",
				
				Cell: props => <p className="list-item-heading" dangerouslySetInnerHTML={{__html:props.value}} ></p>
			},
			{
				Header: "Created On",
				accessor: "created_on",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				width:100,
				Cell: props => props.value==1 ? <span className="btn-xs btn btn-success mb-2">Active</span>:<span className="btn-xs btn btn-danger mb-2">Inactive</span>
			},	  
			{
				Header: "Options",
				accessor: "id",
				width:60,
				sortable: false,
				Cell: props => <Fragment>
							<div className="action-btns w-9 w-sm-50 p-0">
								<ItemDropdown item={<DropdownMenu >
										<DropdownItem onClick={(e)=> this.toggleModalEdit(e,props.value) } >
											<span className="d-inline-block">Edit</span>						
										</DropdownItem>
										<DropdownItem onClick={(e)=> this.toggleModalView(e,props.value) } >
											<span className="d-inline-block">View</span>						
										</DropdownItem>
										<DropdownItem onClick={(e)=> this.toggleModalFill(e,props.value) } >
											<span className="d-inline-block">Fill Sock</span>						
										</DropdownItem>			
										<DropdownItem onClick={event =>this.deleteItem(event,[props.value],2)} >
											<span className="d-inline-block" >Delete</span>
										</DropdownItem>
									</DropdownMenu>}
								/>
							</div>
						</Fragment>
			},
	 
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.sacks" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
			<Colxx xxs="12">
				<div id="api_message"></div>
			</Colxx>
			<Colxx xxs="12">
				<div className="float-sm-right">
					{" "}<Button																		 
						  color="primary"
						  size="sm"
						  className=""
						  onClick={this.toggleModalAdd}
						>
						  Add New 							  
					</Button>
					{" "}<Button
						id="delete-sacks"
						color="primary"
						size="sm"
						className=""
						onClick={event =>this.deleteItem(event,this.state.selectedIds,2)}							
					  >
						<IntlMessages id="pages.delete" />
				  </Button>
				</div>
			</Colxx>
			<Row>			  
				<Colxx xxs="12">		
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
					
			  </Colxx>
			</Row>
			
			{this.state.modalOpenAdd ? <AddSackModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
			/>:""}
			<EditSackModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				dataListRender={this.dataListRender}
				sack_id={this.state.sack_id}
			/>
			<ViewSackModal
				modalOpen={this.state.modalOpenView}
				toggleModal={this.toggleModalView}
				sack_id={this.state.sack_id}
			/>
			<FillSackModal
				modalOpen={this.state.modalOpenFill}
				toggleModal={this.toggleModalFill}
				dataListRender={this.dataListRender}
				sack_id={this.state.sack_id}
			/>
		</Card>
      </Fragment>
    );
  }
}
