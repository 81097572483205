import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES, OUNCE_CONSTANT } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddFinishedProductModal extends Component{
	constructor(props) {
		super(props);
		
		this.state={
			fill_variation:null,
			fill_variation_id:null,
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount() {		
		var fill_variation = null;
		var fill_variation_id = null;
		
		if(this.props.product_variations.length){
			fill_variation=this.props.product_variations[0];
			fill_variation_id = fill_variation.variation_id;
		}
		
		this.setState({ fill_variation, fill_variation_id }); 
		
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("add-finished-product").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("addFinishedProductForm"));
			formData.append('facility_id',facility_id);
			formData.append('product_id',this.props.product.id);
			formData.append('package_type',2);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'inventory/add-finished-package',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById("api_message_finished_products");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("add-finished-product").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-finished-product").disabled=false;
			});
		}
	}
	
	render() {
		const { no_finished } = this.state;
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add Finished Products
				</ModalHeader>
				<AvForm
					id="addFinishedProductForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Variation<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="finished_variation_id"
									name="finished_variation_id"
									type="select"
									value={this.state.fill_variation_id}
									onChange={e=>{
										var fill_variation = this.props.product_variations.find(r=>{ return r.variation_id==e.target.value });
										if(fill_variation!=null){
											this.setState({ fill_variation_id:fill_variation.variation_id,fill_variation });
										}else{
											this.setState({ fill_variation_id:null,fill_variation:null });
										}
									}}
								>
								{this.props.product_variations.map((row,index)=>{
									return <option value={row.variation_id} key={index} >{row.variation_name}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>No. Finished Product<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="no_packages"
									name="no_packages"
									type="number"
									autoComplete="off"
									value={1}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: "1",
											errorMessage: "Please enter a value greater than or equal to 1"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="add-finished-product" >
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};