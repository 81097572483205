import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup,AvInput } from "availity-reactstrap-validation";
import {
  CustomInput,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';
import WasteViewContent from "./WasteViewContent";

export default class WasteViewModal extends Component{
	constructor(props) {
		super(props);
		
	}
  
	render() {
		const { waste_data } = this.props;
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					View Waste
				</ModalHeader>
			  
				<ModalBody>
					<WasteViewContent 
						ref={el => (this.componentRef = el)} 
						waste_data={waste_data}
					/>
				</ModalBody>
				
				<ModalFooter className="justify-content-center">
					<ReactToPrint
						trigger={() => <Button color="primary">Print</Button>}
						content={() => this.componentRef}
						copyStyles={true}
						pageStyle={""}
					/>
					<Button outline onClick={this.props.toggleModal}>
						Cancel
					</Button>
				</ModalFooter>
		  </Modal>
		);
	}
};
