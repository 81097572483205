import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Label,
	Card
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";

import { REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

class HarvestPlantModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			modalOpenAdd: false,
			search: '',
			strain_name: "",
			selectRoom: [],
			selectTable: [],
			room_id: null,
			table_id: null,
			harvesttable: []
		};
	}


	handleSubmit(event, errors, values) {

		if (errors.length === 0) {

			document.getElementById("harvest-plant").disabled = true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("harvestForm"));
			formData.append('facility_id', facility_id);
			formData.append('current_user_id', user_id);
			formData.append('plant_id', this.props.plant_id);


			axios.post(REST_API_END_POINT + 'growhouse/harvest-plant', formData).then((res) => {
				var data = res.data;

				var api_message = document.getElementById("api_message");
				api_message.innerHTML = "<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>" + data.msg + "</span></div>";

				window.setTimeout(function () {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);

				JQUERY_MODULE.$('.alert .close').on("click", function (e) {
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				});

				document.getElementById("harvest-plant").disabled = false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("harvest-plant").disabled = false;
			});

		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		var rooms = [];
		var tables = [];
		if (this.props.modalOpen) {

			if (prevProps.plant_id !== this.props.plant_id && this.props.plant_id != 0) {
				
				var products = [];
				var rooms = [];
				var plant = [];				
				var strain_name = "";
				var tables = [];

				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');

				facility_id = dataCrypto.decrypt(facility_id);
				var formData = new FormData();

				formData.append('current_user_id', user_id);
				formData.append('facility_id', facility_id);
				formData.append('plant_id', this.props.plant_id);
				formData.append('type', 5)

				axios.post(REST_API_END_POINT + 'growhouse/data-for-harvest-cure', formData).then((res) => {
					var data = res.data;

					plant = [data.plant];
					rooms = data.rooms;
					tables = data.tables;
					strain_name = data.strain_name;

					rooms = rooms.map((element, index) => {
						element.label = element.name;
						element.value = element.id;
						element.key = index;
						return element;
					});

					tables = tables.map((element, index) => {
						element.label = element.name;
						element.value = element.id;
						element.key = index;
						return element;
					});

					this.setState({
						harvesttable: plant,
						products: products,
						selectRoom: rooms,
						selectTable: tables,
						strain_name: strain_name,
						room_id: data.plant.room,
						table_id: data.plant.table_id,
					});
				})
					.catch(e => {
						console.log("Addition failed , Error ", e);
					});
			}
		}
	}



	getRoomData(room_id) {
		var tables = [];
		var facility_id = localStorage.getItem('facility_id');

		facility_id = dataCrypto.decrypt(facility_id);

		var formData = new FormData();
		formData.append('room_id', room_id);

		axios.post(REST_API_END_POINT + 'growhouse/get-active-tables-by-room', formData).then((res) => {
			var data = res.data;

			if (data.tables) {
				tables = data.tables;

				tables = tables.map((element, index) => {
					element.label = element.name;
					element.value = element.id;
					element.key = index;
					return element;
				});
				this.setState({
					selectTable: tables,
				});
			} else {
				this.setState({
					selectTable: tables,
				});
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);

		});
	}

	render() {

		const { selectRoom, selectTable } = this.state;

		let data = this.state.harvesttable;

		if (this.state.strain_name != null) {
			var strain_name = this.state.strain_name;
		} else {
			var strain_name;
		}
		let new_position = 1;
		if (data.length) {
			new_position = data[0].position;
		}

		const dataTableColumns = [
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Strain",
				accessor: "strain_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Phase",
				accessor: "phase_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Birth Date",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
			,
			{
				Header: "Time in Room",
				accessor: "time_in_room",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
		];
		return (

			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Harvest Plant
				</ModalHeader>
				<AvForm
					id="harvestForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row>
							<Colxx xxs="12">
								<Label>Strain: <strong>{strain_name ? ((strain_name != null) ? strain_name : '') : ''}</strong></Label>
							</Colxx>
						</Row>
						<Row className="mt-4">
							<Colxx lg="4">
								<AvGroup className="error-t-negative">
									<Label>New Rooms<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="new_room_id"
										id="new_room_id"
										placeholder="--Select Room--"
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
										value={this.state.room_id}
										onChange={(e) => {
											this.setState({
												room_id: e.target.value
											}, () => {
												this.getRoomData(this.state.room_id)
											});
										}}
									>
										<option value="" >--Select Room--</option>
										{selectRoom.map(opt => {
											return <option key={opt.key} value={opt.value} >{opt.label}</option>
										})}
									</AvField>
								</AvGroup>
							</Colxx>
							<Colxx lg="4" className="mb-2">
								<AvGroup className="error-t-negative">
									<Label>New Table<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="new_table_id"
										id="new_table_id"
										placeholder="--Select Table--"
										value={this.state.table_id}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
									>
										<option value="" >--Select Table Type--</option>
										{selectTable.map(opt => {
											return <option key={opt.key} value={opt.value} >{opt.label}</option>
										})}
									</AvField>
								</AvGroup>
							</Colxx>

							<Colxx lg="4" className="mb-2">
								<AvGroup className="error-t-negative">
									<Label>New Position<span className="text-danger">*</span></Label>
									<AvField
										id="new_position"
										name="new_position"
										type="number"
										value={new_position}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											min: {
												value: 1,
												errorMessage: "Position must be greater than or equal to 1"
											}
										}}

									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="mt-2">
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Wet Weight<span className="text-danger">*</span></Label>
									<AvField
										id="wet_weight"
										name="wet_weight"
										type="number"
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											min: {
												value: 0,
												errorMessage: "Position must be greater than or equal to 0"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="mt-4">
							<Colxx lg="12">
								<Button
									id="harvest-plant"
									color="primary"
									size="sm"
									className="mr-2"
								>
									Harvest
								</Button>
							</Colxx>
						</Row>
						<Card className="p-4 mt-4">
							<div className="mb-4 dropdown-edit">
								<Row className="ml-2 mr-2">
									<Colxx lg="12">
										<ReactTable
											data={data}
											columns={dataTableColumns}
											defaultPageSize={1}
											filterable={false}
											showPageJump={false}
											PaginationComponent={DataTablePagination}
											showPageSizeOptions={false}
											defaultSorted={[{ id: "send_on", desc: true }]}
										/>
									</Colxx>
								</Row>
							</div>
						</Card>
					</ModalBody>
				</AvForm>
			</Modal>
		);
	}
};

export default HarvestPlantModal;
