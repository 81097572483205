import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Label, FormGroup,
	Card,
	Input,
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";


import swal from 'sweetalert';
import { REST_API_END_POINT, dataEncryptKey } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectQuantity = [
	{ label: "Gram", value: "1", key: 0 },
	{ label: "Lbs", value: "2", key: 1 }
];

class CurePlantModal extends Component {

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			data: [],
			cure_options: [],
			plant_list_name: [],
			strain_id: [],
			strain_name: [],
			plant_ids: [],
			room_id: [],
			table_id: [],
			search: '',
			bud_batch_now_value: "1",
			bud_batch_later_value: "2",
			bud_discard_value: "3",
			bud_batch_later: true,
			bud_batch_now: false,
			bud_discard: false,
			selectProducts: [],
			plants: [],
			blocking: false,
		};

	}

	handleSubmit(event, errors, values) {

		if (errors.length === 0) {

			document.getElementById("cure-plant").disabled = true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var cure_qty = {};
			for (let option of this.state.cure_options) {
				let cure_detail = {};
				cure_detail.qty_type = option.qty_type;
				cure_detail.qty = option.qty;
				cure_detail.batch_option = option.action;
				cure_detail.product_id = option.product_id;
				cure_qty[option.name.toLowerCase().split(" ").join("_")] = cure_detail;
			}

			var formData = new FormData(document.getElementById("cureForm"));
			formData.append('facility_id', facility_id);
			formData.append('current_user_id', user_id);
			formData.append('plant_list_name', this.state.plant_list_name);
			formData.append('strain_id', this.state.strain_id);
			formData.append('strain_name', this.state.strain_name);
			formData.append('plant_ids', this.state.plant_ids);
			formData.append('room_id', this.state.room_id);
			formData.append('table_id', this.state.table_id);
			formData.append('cure_qty', JSON.stringify(cure_qty));

			this.setState({ blocking: true });

			axios.post(REST_API_END_POINT + 'growhouse/cure-plants', formData).then((res) => {
				var data = res.data;

				if (data.status == 0) {
					swal("Success", data.msg, "success").then(() => {
						this.props.toggleModal();
						this.props.dataListRender();
						document.getElementById("cure-plant").disabled = false;
					});
				} else {
					let strain_name = data.strain_name;
					let strain_id = data.strain_id;
					let plant_ids = data.plant_ids;
					let cure_options = data.cure_options;
					this.form && this.form.reset();

					cure_options = cure_options.map(row => {
						row.qty_type = 1;
						return row;
					});

					this.setState({
						plant_ids,
						cure_options,
						strain_id: strain_id,
						strain_name: strain_name,
						plants: data.plants,
						wet_weight: "",
						blocking: false
					});

					document.getElementById("cure-plant").disabled = false;
				}

			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("cure-plant").disabled = false;
			});

		}
	}


	componentDidUpdate(prevProps, prevState, snapshot) {
		var rooms = [];
		var tables = [];
		var strain_id = [];
		var plant_list_name = [];
		var cureoptions = [];
		var strain_name = [];
		var plant_ids = [];
		var room_id = [];
		var table_id = [];
		var plant = [];


		if (this.props.modalOpen) {

			if (prevProps.plant_ids !== this.props.plant_ids && this.props.plant_ids != 0) {
				var cure_options = [];
				var products = [];
				var rooms = [];
				var strain_id = [];
				var strain_name = [];
				var tables = [];
				var plants = [];


				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');

				facility_id = dataCrypto.decrypt(facility_id);
				var formData = new FormData();

				formData.append('current_user_id', user_id);
				formData.append('facility_id', facility_id);
				formData.append('plant_ids', this.props.plant_ids);
				formData.append('table_id', this.props.table_id);
				formData.append('type', 6)

				axios.post(REST_API_END_POINT + 'growhouse/create-plants-list', formData).then((res) => {
					var data = res.data;

					let status = data.status;

					var msg = data.msg;

					if (status == 0) {
						swal(msg);
						this.props.toggleModal();
						return false;
					}

					cure_options = data.cure_options;

					cure_options = cure_options.map(row => {
						row.qty_type = 1;
						return row;
					});

					plant_list_name = data.plant_list_name;
					strain_id = data.strain_id;
					plant_ids = data.plant_ids;
					room_id = data.room_id;
					table_id = data.table_id;
					strain_name = data.strain_name;
					plants = data.plants;

					products = data.products;

					products = products.map((element, index) => {
						element.label = element.name;
						element.value = element.id;
						element.key = index;
						return element;
					});

					this.setState({
						cure_options,
						plant_list_name: plant_list_name,
						strain_id: strain_id,
						plant_ids: plant_ids,
						room_id: room_id,
						table_id: table_id,
						strain_name: strain_name,
						plants: data.plants,
						selectProducts: products,
						blocking: false
					});
				}).catch(e => {
					console.log("Addition failed , Error ", e);
				});

			}
		}

	}


	render() {
		let data = this.state.cure_options;

		let datas = this.state.plants;

		if (this.state.strain_name != null) {
			var strain_name = this.state.strain_name;
		} else {
			var strain_name;
		}

		if (this.state.search) {
			datas = datas.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search.toLowerCase()) || row.strain.toLowerCase().includes(this.state.search.toLowerCase()) || row.phase.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}

		const { selectProducts } = this.state;

		const dataTableColumns = [
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Strain",
				accessor: "strain",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Phase",
				accessor: "phase",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Birth Date",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
			,
			{
				Header: "Time in Room",
				accessor: "time_in_room",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
		];
		return (

			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Cure Plants
				</ModalHeader>

				<ModalBody>
					<Row>
						<Colxx xxs="12">
							<Label>Strain: <strong>{strain_name ? ((strain_name != null) ? strain_name : '') : ''}</strong></Label>
						</Colxx>
					</Row>
					<AvForm
						id="cureForm"
						ref={c => (this.form = c)}
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					>
						{!this.state.blocking ? data.map((row, index) => {
							return (<Row className="mt-2" key={index}>
								<Colxx lg="6">
									<AvGroup className="error-t-negative">
										<Label>{row.name}<span className="text-danger">*</span></Label>
										<AvField
											id={row.name.toLowerCase().split(" ").join("_")}
											name={row.name.toLowerCase().split(" ").join("_")}
											type="number"
											value={row.qty}
											onChange={(e) => {
												var cure_options = this.state.cure_options;
												var id = row.id;
												var value = e.target.value;
												cure_options.map(option => {
													if (option.id == id) {
														option.qty = value;
													}
													return option;
												})
												this.setState({
													cure_options
												});
											}}
											validate={{
												required: {
													value: true,
													errorMessage: "This field is required."
												}
											}}
										/>
									</AvGroup>
								</Colxx>
								<Colxx lg="6">
									<AvGroup className="error-t-negative">
										<Label>&nbsp;</Label>
										<AvField
											type="select"
											name="qty_type"
											validate={{
												required: {
													value: true,
													errorMessage: "This field is required."
												}
											}}
											value={row.qty_type}
											onChange={(e) => {
												var cure_options = this.state.cure_options;
												var id = row.id;
												var value = e.target.value;
												cure_options.map(option => {
													if (option.id == id) {
														option.qty_type = value;
													}
													return option;
												})
												this.setState({

													cure_options
												});
											}}
										>
											{selectQuantity.map(opt => {
												return <option key={opt.key} value={opt.value}>{opt.label}</option>
											})}
										</AvField>
									</AvGroup>
								</Colxx>

								<Colxx lg="12" className="mt-2 mb-2">
									<AvGroup className="error-t-negative">
										<AvRadioGroup
											inline
											name={row.name.toLowerCase().split(" ").join("_") + "_batch_option"}
											onChange={(e) => {
												var cure_options = this.state.cure_options;
												var id = row.id;
												var value = 0;
												var values = document.querySelectorAll('input[name=' + row.name.toLowerCase().split(" ").join("_") + "_batch_option]");
												for (let i = 0; i < values.length; i++) {
													if (values[i].checked) {
														value = values[i].value;
													}
												}
												cure_options.map(option => {
													if (option.id == id) {
														option.action = value;
													}
													return option;
												})
												this.setState({
													cure_options
												});
											}}
											validate={{
												required: {
													value: true,
													errorMessage: "Please select any "
												}
											}}
											defaultValue={row.action}
										>
											<AvRadio customInput label="Batch Later" value="2" />
											<AvRadio customInput label="Batch Now" value="1" />
											<AvRadio customInput label="Mark As Waste" value="3" />
										</AvRadioGroup >
									</AvGroup>
								</Colxx>
								{row.action == 1 ? <Colxx lg="12" className="mt-2 mb-2">
									<Label >Choose Products</Label>
									<AvField
										type="select"
										name={row.name.toLowerCase().split(" ").join("_") + "_product_id"}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
										value={row.product_id}
										onChange={(e) => {
											var cure_options = this.state.cure_options;
											var id = row.id;
											var value = e.target.value;
											cure_options.map(option => {
												if (option.id == id) {
													option.product_id = value;
												}
												return option;
											})
											this.setState({
												cure_options
											});
										}}
									>
										<option key={0} value={""}>-- Select Product--</option>
										{selectProducts.map(opt => {
											return <option key={opt.key} value={opt.value}>{opt.label}</option>
										})}
									</AvField>
								</Colxx> : ""}
							</Row>)
						}) : ""}
						<hr />
						<Row className="mt-4">
							<Colxx lg="12">
								<Button
									id="cure-plant"
									color="primary"
									size="sm"
									className="mr-2"
								>
									Cure
								</Button>
							</Colxx>
						</Row>
					</AvForm>
					<Card className="p-4 mt-4">
						<div className="mb-4 dropdown-edit">
							<FormGroup row>
								<Colxx lg="6">
									<Input
										type="text"
										name="table_search"
										id="table_search"
										placeholder="Search"
										value={this.state.search}
										onChange={e => this.setState({ search: e.target.value })}
									/>
								</Colxx>
							</FormGroup>
							<ReactTable
								data={datas}
								columns={dataTableColumns}
								defaultPageSize={10}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{ id: "send_on", desc: true }]}
							/>
						</div>
					</Card>
				</ModalBody>
			</Modal>
		);
	}
};

export default CurePlantModal;
