import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Row, Card,Button, CardBody, CardTitle, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { NavLink } from "react-router-dom";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const StatusOptions = [
	{ label: "New", value: "1", key: 0 },
	{ label: "In Progress", value: "2", key: 1 },
	{ label: "Completed", value: "3", key: 2 },
	{ label: "On Hold", value: "4", key: 3 },
	{ label: "Cancelled", value: "5", key: 4 },
];
  
const quillModules = {
  toolbar: [
	[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
	[{ 'align': [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"]
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "align",
  "list",
  "bullet",
  "indent",
  "link"
];

export default class AddJobModal extends Component {
	
	constructor(props) {
		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			startDate: null,
			endDate: null,
			textQuillStandart: "",
			selectStatus: { label: "New", value: "1", key: 0 },
			facility_id: null,
			module_id: null,
			ModuleOptions:[],
			FacilityOptions:[]
		};
	}
	
    handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
    
    handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	
	handleChangeDaten = date => {
		this.setState({
			endDate: date
		});
	};
		  
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			if(this.state.startDate.unix()>this.state.endDate.unix()){
				swal("Warning!", "The start date should be less than or equal to end date!", "warning");				
				return;
			}
			
			document.getElementById("add-job").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var formData = new FormData(document.getElementById("jobForm"));			
			formData.append('user_id',user_id);
			formData.append('description',this.state.textQuillStandart);
			
			axios.post(REST_API_END_POINT+'task/add-task',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				this.props.dataListRender();
				
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
		
				var current_facility_id = null; 
				var current_module_id = null;
				
				if(typeof this.props.facility_id!='undefined'){
					current_facility_id = this.state.FacilityOptions.find(row=>{ return row.id==this.props.facility_id });
					
					if(typeof this.props.module_id!='undefined'){
						current_module_id = this.state.ModuleOptions.find(row=>{ return row.id==this.props.module_id }); 
					}else{
						current_module_id = this.state.ModuleOptions[0];
					}
				}
				
				this.setState({
					facility_id:current_facility_id,
					module_id:current_module_id,
					selectStatus: { label: "New", value: "1", key: 0 },
					startDate: null,
					endDate: null,
				});
				
				this.props.toggleModal();
				document.getElementById("add-job").disabled=false;
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-job").disabled=false;
			});

		}
	}
	  
    componentDidMount(){  
		var user_id = localStorage.getItem('user_id');
	    var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'task/get-data-for-add-task',formData).then((res)=>{
			var data = res.data;
			
		    var ModuleOptions=data.modules;
			var FacilityOptions=data.facilities;
			
			ModuleOptions = ModuleOptions.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			FacilityOptions = FacilityOptions.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			var current_facility_id = null; 
			var current_module_id = null;
			
			if(typeof this.props.facility_id!='undefined'){
				current_facility_id = FacilityOptions.find(row=>{ return row.id==this.props.facility_id });
				
				
				if(typeof this.props.module_id!='undefined'){
					current_module_id = ModuleOptions.find(row=>{ return row.id==this.props.module_id }); 
				}else{
					current_module_id = ModuleOptions[0];
				}
			}else{
				current_facility_id = FacilityOptions.find(row=>{ return row.id==facility_id });
				current_module_id = ModuleOptions[0];
			}
			
			if(data.status==1){
				this.setState({
					ModuleOptions,
					FacilityOptions,
					facility_id:current_facility_id,
					module_id:current_module_id
				});
			}else{
				this.setState({
					ModuleOptions,
					FacilityOptions
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
	render() {
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add Job
				</ModalHeader>
				<AvForm
					id="jobForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Job Name<span className="text-danger">*</span></Label>
									<AvField
										id="name"
										name="name"
										type="text"
										autoComplete="off"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  },
										  pattern: {
											value: "^[a-zA-Z0-9 ,.'-]+$",
											errorMessage: "Invalid name"
										  },
										  minLength: {
											value: 2,
											errorMessage:
											  "The name must be between 2 and 100 characters"
										  },
										  maxLength: {
											value: 100,
											errorMessage:
											  "The name must be between 2 and 100 characters"
										  }
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
					
						<Row className="mb-4">
							<Colxx xxs="12">
								<Label>Job Overview<span className="text-danger">*</span></Label>
								<ReactQuill
									theme="snow"
									value={this.state.textQuillStandart}
									onChange={this.handleChangeQuillStandart}
									modules={quillModules}
									formats={quillFormats}
								/>
							</Colxx>
						</Row>
					
						<Row>
							<Colxx lg="12">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									placeholder=""							
									className="mb-3"
									selected={this.state.startDate}
									onChange={this.handleChangeDate}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
									autoComplete="off"
								/>
							</Colxx>						  
						</Row>
								  
						<Row>
							<Colxx lg="12">
								<Label>Due date<span className="text-danger">*</span></Label>
								<DatePicker
									id="due_date"
									name="due_date"
									placeholder=""
									className="mb-3"
									selected={this.state.endDate}
									onChange={this.handleChangeDaten}
									placeholderText=""
									dateFormat="MM/DD/YYYY"
									autoComplete="off"
								/>
							</Colxx>						  
						</Row>
						
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Job Status</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="status"
										id="status"
										placeholder="--Select Status--"
										value={this.state.selectStatus}						
										options={StatusOptions}
										onChange={selectStatus => { this.setState({ selectStatus }) }}
									/>
								</AvGroup>
							</Colxx>
						</Row>  
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Facility</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="facility_id"
										id="facility_id"
										placeholder="--Select Facility--"
										value={this.state.facility_id}						
										options={this.state.FacilityOptions}
										onChange={facility_id => { this.setState({ facility_id }) }}
									/>
								</AvGroup>
							</Colxx>
						</Row>  
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Module</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="module_id"
										id="module_id"
										placeholder="--Select Modules--"
										value={this.state.module_id}						
										options={this.state.ModuleOptions}
										onChange={module_id => { this.setState({ module_id }) }}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12" className="mb-3">
								<Label>Assign Yourself To Job</Label>
								<FormGroup check>
									<Label check>
										<Input type="checkbox"  name="assign" value="1" />{" "}Selecting this option will add you to the Job Team.
									</Label>
								</FormGroup>
							</Colxx>
						</Row>						
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="add-job" >
							<IntlMessages id="customer.add" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
}
