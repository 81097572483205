import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Button, Label } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";

import { REST_API_END_POINT,themeColor } from "../../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import TooltipItem from "../../../components/common/TooltipItem";
import TooltipActionItem from "../../../components/common/TooltipActionItemAdmin";

import data_fields from "../../../data/admin/admin_email_fields";

export default class Edit extends Component {
	
	constructor(props) {
		super(props);
		
		this.generateField = this.generateField.bind(this);
		this.changeTextarea = this.changeTextarea.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
		  template_data: null,
		  blocking:true,
		  focusing_field:"subject",
		};
	}
	
	
	componentDidMount() {
		var data_upload = new FormData();
		data_upload.append('template_id',this.props.match.params.id);
		axios.post(REST_API_END_POINT+'admin/get-email-template-by-alias',data_upload).then((res)=>{ 
			if(res.data.status==1){
				this.setState({ template_data: res.data.data, blocking:false });				
			}else{
				swal("Failed to load the email template!", "Please refresh the page.", "error");
				this.setState({ blocking:false });					
			}			
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	generateField(field,index){
		if(field.type==="text"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
			  <AvField
				name={field.key}
				type={field.type}
				autoComplete="off"
				value={field.value}
				onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
				onChange={(e)=>{ 
					var template_data=this.state.template_data;
					template_data.content[index].value = e.target.value;
					this.setState({ template_data });
				}}
			  />
			</AvGroup>)
		}else if(field.type==="textarea"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
			  <AvField
				name={field.key}
				type={field.type}
				autoComplete="off"
				rows={field.key=="email_content_text" ? 10:5}
				value={field.value.split("<br/>").join("\n")}
				onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
				onChange={(e)=>{ 
					var template_data=this.state.template_data;
					template_data.content[index].value = e.target.value.split("\n").join("<br/>");
					this.setState({ template_data });
				}}
			  />
			</AvGroup>)
		}
	}
	
	changeTextarea(new_textarea_content){	
		var template_data=this.state.template_data;
		if(this.state.focusing_field!='subject'){
			var index = template_data.content.findIndex(r=>{ return r.key==this.state.focusing_field });
			template_data.content[index].value = new_textarea_content;
		}else{
			template_data.subject = new_textarea_content;
		}
		this.setState({ template_data });
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("edit-email-template").disabled = true;
			var template_data = JSON.parse(JSON.stringify(this.state.template_data));
			delete template_data.real_template;
			
			var data_upload = new FormData();
			data_upload.append('template_id',this.props.match.params.id);
			data_upload.append('template_data',JSON.stringify(template_data));
			
			this.setState({ blocking:true });
			axios.post(REST_API_END_POINT+'admin/update-email-template',data_upload).then((data)=>{ 
				if(data.data.status==1){
					swal("Success!", "Email template has been updated successfully!", "success");				
				}else{
					swal("Failed to update email template!", "Please try after sometime.", "error");			
				}
				this.setState({ blocking:false });
				document.getElementById("edit-email-template").disabled = false;
			}).catch(e => console.log("Addition failed , Error ", e));
			
		  
		}
	}
	 
		
	render() {
		var template_data = this.state.template_data;
		
		var real_template = '<div id="view-email-template-content"></div>';
		if(template_data!=null){
			real_template = '<div id="view-email-template-content">'+JSON.parse(JSON.stringify(template_data.real_template))+'</div>';
			real_template = real_template.replace("body{width: 100%; background-color: #f0f3f8; ", "#view-email-template-content{width: 100%; background-color: #f0f3f8; ");
			for(let keyword of template_data.content){
				if(keyword.value==""){
					real_template = real_template.replace("{{"+keyword.key+"}}",keyword.default);
				}else{
					real_template = real_template.replace("{{"+keyword.key+"}}",keyword.value);
				}				
			}
		}
		
		const email_data_fields = JSON.parse(JSON.stringify(data_fields));
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row className="with-email-view">
						<Colxx xxs="12">
							<Breadcrumb heading_text={template_data!=null ? template_data.name:""} match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<style dangerouslySetInnerHTML={{ __html: `body{background: #f8f8f8 !important;}` }} />
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row>
										<Colxx xl="6">
											<AvForm
												id="edit_email_template_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											  >
												<Row>
													<Colxx md="8">
														<AvGroup className="error-t-negative">
														  <Label>Subject</Label>
														  <AvField
															name="subject"
															type="text"
															autoComplete="off"
															value={template_data!=null ? template_data.subject:""}
															onFocus={()=>{ this.setState({ focusing_field:"subject" }); }}
															onChange={(e)=>{ 
																var template_data=this.state.template_data;
																template_data.subject = e.target.value;
																this.setState({ template_data });
															}}
															validate={{
															  required: {
																value: true,
																errorMessage: "Please enter the subject"
															  },
															  minLength: {
																value: 10,
																errorMessage:
																  "Subject must be between 10 and 150 characters"
															  },
															  maxLength: {
																value: 150,
																errorMessage:
																  "Subject must be between 10 and 150 characters"
															  }
															}}
														  />
														</AvGroup>
													
														{template_data!=null ?  template_data.content.map((row,index)=>{
															return this.generateField(row,index);
														}):""}
													</Colxx>
													<Colxx md="4">
														<div className="text-right">
															<TooltipItem key="tip-hint" item={{
															  size:'xs round-tip',
															  placement: "bottom",
															  text: "?",
															  body: "These buttons will insert placeholder code into your email. They will be replaced with the actual values when the email is sent. Hover over each for more details."
															}} id="tip-hint" />
														</div>
														<p className="mb-3">Insert Placeholders</p>
														{email_data_fields.map((tooltip, i) => {															
															tooltip.textarea=document.getElementsByName(this.state.focusing_field);							
															return <TooltipActionItem key={i} item={tooltip} id={i} stateupdatingcallback={this.changeTextarea} />;
														})}
													</Colxx>
												</Row>												
												<hr/>
												
												<Button color="primary" id="edit-email-template" >
													<IntlMessages id="pages.submit" />
												</Button>{" "}
												<NavLink to={"/administrator/email-management"} className="btn btn-outline-secondary not-active" >
													<IntlMessages id="pages.cancel" />
												</NavLink>{" "}
											</AvForm>
										</Colxx>
										<Colxx xl="6">
											<div className="edit-email-template-view" dangerouslySetInnerHTML={{ __html: real_template }} ></div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
