import React, { Component } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { loginUser } from "../../redux/actions";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { projectTitle,REST_API_END_POINT,dataEncryptKey,BASE_URL } from "../../constants/defaultValues";
import { seo } from "../../helpers/Utils";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class Login extends Component {
	constructor(props) {
		super(props);
		this.onUserLogin = this.onUserLogin.bind(this);
		this.state = {
			email: "",
			password: "",
			configurations: {
				facility_footer_logo:null,
				facility_logo:null
			},
		};
		seo({
			title: 'Login | '+projectTitle,
		});
	}
  
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id!=null){
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{
				this.setState({ configurations: res.data.configurations, blocking:false });
			}).catch(e =>{ 
				console.log("Addition failed, Error ", e) 
				this.setState({ blocking:false });
			});
		}
	}
  
	onUserLogin(event) {
		event.preventDefault();
		if (this.state.email !== "" && this.state.password !== "") {
			this.props.loginUser(this.state, this.props.history);
		}
	}

  render() {
	  const { configurations } = this.state;
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="6" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="form-side">
				<div className="d-block text-center">
				  <NavLink to={`/`} className="white">
					  {configurations.facility_logo==null ? <span className="logo-single" />:<img src={BASE_URL+'pages/'+configurations.facility_logo} className="img-responsive mb-3" style={{maxHeight:'60px'}}   />}
				  </NavLink>
				</div>
              <CardTitle className="mb-4">
                <IntlMessages id="user.login-title" />				
              </CardTitle>
              <Form onSubmit={this.onUserLogin}>
                <Label className="form-group has-float-label mb-4">
                  <Input type="text" value={this.state.email} onChange={ (e) => { this.setState({ email:e.target.value }); }} />
                  <span>Username or Email *</span>
                </Label>
                <Label className="form-group has-float-label mb-4">
                  <Input type="password" value={this.state.password} autoComplete="new-password" onChange={ (e) => { this.setState({ password:e.target.value }); }} />
                  <IntlMessages id="user.password" />
                </Label>
                <div className="d-flex justify-content-between align-items-center">
                  <NavLink to={`/user/forgot-password`}>
                    <IntlMessages id="user.forgot-password-question" />
                  </NavLink>
                  <Button
                    color="primary"
                    className="btn-shadow"
                    size="lg"
                    type="submit"
                  >
                    <IntlMessages id="user.login-button" />
                  </Button>
                </div>
              </Form>
			  {/*<p className="text-center mt-sm mb-0">
					New to {projectTitle}? {" "}
					<NavLink to={`/user/register`} className="">
					  Sign Up Now!
					</NavLink>
			  </p>*/}
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(Login);
