import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import {	
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);
class Notes extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require("mousetrap");
	this.handleSubmit = this.handleSubmit.bind(this);
    this.dataListRender = this.dataListRender.bind(this);
	
    this.state = {
      
    };
  }
 
	

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-notes").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantNoteForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('plant_id',this.props.plant_id);
			formData.append('phase_id',this.props.phase_id.value);

			axios.post(REST_API_END_POINT+'growhouse/add-notes',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_note");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				document.getElementById("add-notes").disabled=false;
				
				this.dataListRender();
				
				this.form && this.form.reset();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-notes").disabled=false;
			});

		}
	}
	
	componentDidMount(){
	   this.dataListRender();
	}

	
    dataListRender() {
		
		var notes=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('plant_id',this.props.plant_id);
		axios.post(REST_API_END_POINT+'growhouse/notes',formData).then((res)=>{
			var data = res.data;

			if(data.notes){
				
				notes=data.notes;
				this.setState({
			        notes: data.notes,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					notes: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	
	
	
	render() {	    
		let data = this.state.notes;
		  
		const dataTableColumns = [			  
		  {
			Header: "Notes",
			accessor: "note",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Date",
			accessor: "created_at",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  }
		];
    return( 
      <Fragment>       
        <div className="p-4">
			<div id="api_message_note"></div>
		  <div className="mb-4 dropdown-edit">	
			{this.props.user_privileges.indexOf(143)!==-1 ? <AvForm
		        id="plantNoteForm"
				ref={c => (this.form = c)}
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<Row className="mt-2 mb-2">
					<Colxx xxs="8">	
						<AvGroup className="error-t-negative">	
                         <Label>Notes<span className="text-danger">*</span></Label>						
							<AvField
								id="note"
								name="note"
								type="text"	
                                validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							/>
						 </AvGroup>
					</Colxx>
				</Row>
				<Row className="mb-4">
					<Colxx xxs="4">
						<Button
							id="add-notes"
							color="primary"
							size="sm"
							className=""
							
							>
							Add Note
						</Button>					
					</Colxx>					
				</Row>
			</AvForm>:""}
			<hr/>
				<ReactTable
				  data={data}
				  TbodyComponent={CustomTbodyComponent}
				  columns={dataTableColumns}
				  defaultPageSize={5}
				  showPageJump={false}
				  showPageSizeOptions={true}
				  showPagination={true}
				  PaginationComponent={DataTablePagination}
				/>
			</div>
		</div>	         
      </Fragment>
    );
  }
  
};
export default Notes;
