import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class MotherYields extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			strains:[],
			search: '',
			strain_id: null,
			blocking: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
	}

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.strain_id!=null){
			formData.append('strain_id',this.state.strain_id.value);
		}else{
			formData.append('strain_id',0);
		}
				
		axios.post(REST_API_END_POINT+'report/mother-yields',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				
				var strains = data.strains;
				strains = strains.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				strains.unshift({ label:"All", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					strains,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					strains:[],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		this.$el.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray(),
				aaSorting:[],
				buttons: [
					'csv', 'excel', {
						extend: 'pdfHtml5',
						orientation: 'landscape',
						pageSize: 'LEGAL'
					}, 'print'
				]
			});
	}
	
	makeArray(){
		const reports = this.state.reports;
		var formated_data=[];
		var i=1;
		for(let row of reports){
			formated_data.push([
				row.barcode,
				moment(row.birth_date).format("MM/DD/YYYY HH:mm"),
				row.strain,
				row.harvested_date!=null ? moment(row.harvested_date).format("MM/DD/YYYY HH:mm"):"",
				row.cured_date!=null ? moment(row.cured_date).format("MM/DD/YYYY HH:mm"):"",
				row.wet_weight,
				row.used_weight,
				parseInt(row.wet_weight)!==0 ? ((parseFloat(row['wet_weight'])-parseFloat(row['used_weight']))/parseFloat(row['wet_weight'])*100).toFixed(2):0
			])
		}
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		const { strains } = this.state;
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.mother-yields" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="10">
								<Label>Strain</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="strain_id"
									id="strain_id"
									placeholder="All"
									value={this.state.strain_id}						
									options={strains}
									onChange={strain_id => { this.setState({ strain_id }) }}
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm>
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>Barcode</th>
												<th>Birth Date</th>
												<th>Strain</th>
												<th>Harvested Date</th>
												<th>Cured Date</th>
												<th>Wet Weight</th>
												<th>Used Weight</th>
												<th>Total Loss%</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
