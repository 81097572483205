import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import allEnquiries from "./allEnquiries";
import customerEditEnquiry from "./customerEditEnquiry";
import customerAddEnquiry from "../../../containers/enquiries/AddEnquiryModal"

const Enquiries = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
          <Route path={`${match.url}/all-enquiries`} component={allEnquiries} />
          <Route path={`${match.url}/add`} component={customerAddEnquiry} />
          <Route path={`${match.url}/edit/:order_id`} component={customerEditEnquiry} />
          {/* http://localhost:7500/app/all-enquiries/edit/56 */}
		  <Route path={`${match.url}`} component={allEnquiries} />
          <Redirect to="/error" />

        </Switch>
    </div>
);
export default Enquiries;