import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import DealModal from "./DealModal";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { Colxx } from "../../common/CustomBootstrap";


const DealGridListSingle = ({
  product,
  currency,
  addToCart,
  cartItem,
  sliderClassName,
  spaceBottomClass
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();

  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  product.product_stock = parseInt(product.product_stock)
	
  return (
    <Fragment>
      <div
        className={`col-xl-4 col-sm-6 ${
          sliderClassName ? sliderClassName : ""
        }`}
      >
        <div
          className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}
        >
          <div className="product-img">            
            <img
                className="default-img"
                src={product.thumbnail_url}
                alt=""
            />
            <div className="product-action">
				{product.product_stock && product.product_stock > 0 ? "": (<div className="pro-same-action pro-cart">
                  <button disabled className="active">
                    Out of Stock
                  </button>
                </div>)}              
              
            </div>
          </div>
          <div className="product-content">
			<Row>
              <Colxx lg={12} className='product-name-container'>
                <h3 className='text-center' dangerouslySetInnerHTML={{ __html: product.name }} ></h3>
              </Colxx>
            </Row>
            <Row className='mt-3'>
              <Colxx lg={12}>
                <div className="display-flex align-items-center justify-content-center">
                  <div className="product-price">{currency.currencySymbol}{parseFloat(finalProductPrice).toFixed(2)} </div>
                </div>
              </Colxx>              
            </Row>
            <Row className='mt-3'>
              <Colxx lg={12}>
                <div className="pro-same-action pro-quickview text-center">
                  <Button className="btn-home px-5 py-2" onClick={() => setModalShow(true)} title="Quick View">
                    <i className="" /> Quick View
                  </Button>
                </div>
              </Colxx>
            </Row>
          </div>
        </div>
      </div>
      {/* product modal */}
      <DealModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        finalproductprice={finalProductPrice}
        cartitem={cartItem}
        addtocart={addToCart}
        addtoast={addToast}
      />
    </Fragment>
  );
};

DealGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  cartItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default DealGridListSingle;
