import React,{ Component, Fragment } from "react";
import {
  Row,
  Card,
  CardBody,
  Table
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import moment from "moment";

export default class PrintLeafManagement extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		var data = this.props.data;
			
		return (
			<Row className="invoice-react">
				<Colxx xxs="12" className="mb-4">
					<Card className="mb-5 invoice-contents width-auto">
						<h2 style={{fontSize:'22px'}}>Activity Log</h2>
						<CardBody className="d-flex flex-column justify-content-between">
							<Table>
								<thead>
									<tr>
										<th>Operation</th>
										<th>Done By</th>
										<th>Filed Date & Time</th>
									</tr>
								</thead>
								<tbody>
									{data.map((order)=>{
										return(<tr>
											<th>{order.operation}</th>
											<th>{order.user}</th>
											<th>{moment(order.created_at).format('MM/DD/YYYY HH:mm')}</th>
										</tr>)
									})}
								</tbody>
							</Table>
							
						</CardBody>
					</Card>
				</Colxx>
			</Row>
		)
	
	}
}