import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput, Label, InputGroup, InputGroupAddon } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import axios from 'axios';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, BASE_URL} from "../../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Settings extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			configurations: null,
			blocking:true
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.updateConfiguration = this.updateConfiguration.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{ 
			this.setState({ configurations: res.data.configurations, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	updateConfiguration = (event, errors, values)  => {
		if (errors.length === 0) {
			document.getElementById("update-configurations").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("updateSettingsForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'website/update-settings',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					swal("Success",data.msg,"success").then(()=>{
						window.location.reload();
					});
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				document.getElementById("update-configurations").disabled=false;
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-configurations").disabled=false;
			});
		}
	}
	
		
	render() {
		
		const configurations = this.state.configurations	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.settings" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row>	
										<Colxx xxs="12">
											<div id="api_message"></div>
										</Colxx> 
										<Colxx xxs="12">
											<AvForm												
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.updateConfiguration}
												id="updateSettingsForm"
											  >
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Logo (Recommended Size: 190x45)</Label>
															{configurations!=null ? (configurations.facility_logo!=null ? <Row className="mt-2 mb-3">
																<Colxx lg="12" >
																	<div className="d-flex justify-content-start">
																		<div className="text-center">
																			<img src={BASE_URL+'pages/'+configurations.facility_logo} alt="Logo" className="img-responsive" />
																		</div>
																	</div>
																</Colxx>
															</Row>:""):""}
															<InputGroup className="mb-3">
																<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
																<CustomInput
																	type="file"
																	name="facility_logo"
																	id="facility_logo"
																	accept="image/png,image/jpeg,image/jpg,image/gif"
																	onChange={(e)=>{							
																		if(e.target.files[0]){
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
																		}else{
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
																		}
																	}}
																/>
															</InputGroup>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Footer Logo (Recommended Size: 340x82)</Label>
															{configurations!=null ? (configurations.facility_footer_logo!=null ? <Row className="mt-2 mb-3">
																<Colxx lg="12" >
																	<div className="d-flex justify-content-start">
																		<div className="text-center">
																			<img src={BASE_URL+'pages/'+configurations.facility_footer_logo} alt="Logo" className="img-responsive" />
																		</div>
																	</div>
																</Colxx>
															</Row>:""):""}
															<InputGroup className="mb-3">
																<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
																<CustomInput
																	type="file"
																	name="facility_footer_logo"
																	id="facility_footer_logo"
																	accept="image/png,image/jpeg,image/jpg,image/gif"
																	onChange={(e)=>{							
																		if(e.target.files[0]){
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
																		}else{
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
																		}
																	}}
																/>
															</InputGroup>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Mobile Logo (Recommended Size: 36x36) - This logo is only used in dashboard</Label>
															{configurations!=null ? (configurations.facility_mobile_logo!=null ? <Row className="mt-2 mb-3">
																<Colxx lg="12" >
																	<div className="d-flex justify-content-start">
																		<div className="text-center">
																			<img src={BASE_URL+'pages/'+configurations.facility_mobile_logo} alt="Logo" className="img-responsive" />
																		</div>
																	</div>
																</Colxx>
															</Row>:""):""}
															<InputGroup className="mb-3">
																<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
																<CustomInput
																	type="file"
																	name="facility_mobile_logo"
																	id="facility_mobile_logo"
																	accept="image/png,image/jpeg,image/jpg,image/gif"
																	onChange={(e)=>{							
																		if(e.target.files[0]){
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
																		}else{
																			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
																		}
																	}}
																/>
															</InputGroup>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>MailChimpURL</Label>
															<AvField
																id="mailchimp_form_url"
																name="mailchimp_form_url"
																type="text"
																autoComplete="off"
																value={configurations!=null ? (configurations.mailchimp_form_url!=null ? configurations.mailchimp_form_url:""):""}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Row>
													<Colxx lg="12">
														<Button color="primary" id="update-configurations" >Update</Button>
													</Colxx>
												</Row>
										   </AvForm>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
