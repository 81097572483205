const documents = [
{
    id: 1,
    issue_date: "2019-06-07",
    expiry_date: "2019-06-07",
    uploaded: "2019-06-07",
    amount: 100.00,
    name: "Authorize 15654543",
    description:"",
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 2,
    issue_date: "2019-03-16",
    expiry_date: "2019-06-07",
    uploaded: "2019-06-07",
    amount: 298,
    name: "Authorize 15653554",
    description:"",
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default documents
