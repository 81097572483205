import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

class AddDepartmentModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleSubmit(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			this.props.toggleModal();
		}
	}


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
	     	Add Department
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
		  <Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Work Location<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="customer.add" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddDepartmentModal;
