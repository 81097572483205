const files = [
{
    id: 1,
    uploaded_on: "2019-06-07",
    uploaded_by: "Motagistics",
    name: "Authorize 15654543",
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 2,
    uploaded_on: "2019-06-07",
    uploaded_by: "Motagistics",
    name: "Authorize 15654543",
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default files
