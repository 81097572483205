import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import {	
  CustomInput,
  Button,
  Label,FormGroup,
  Card, Table, 
  Input
} from "reactstrap";

import {REST_API_BASE_URL,dataEncryptKey} from "../../constants/defaultValues";
import printJS from 'print-js'
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

export default class SingleDrawerCheckOff extends Component {
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		
		this.state = {
			banktotal:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			},
			search:""
		};
		this.printLabel = this.printLabel.bind(this);
	}  
	
	componentDidMount(){
		var banktotal = this.props.drawer_closing_total;
		for(let r in banktotal){
			banktotal[r] = parseFloat(banktotal[r]);
		}
		this.setState({ banktotal });
	}
	
	printLabel(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
		let banktotal = this.state.banktotal;
		
		banktotal.final_total=this.getTotal(banktotal);
		
		printJS(REST_API_BASE_URL+"print-drawer-close/"+this.props.drawer_id+"/"+facility_id+"/"+this.props.selected_currency.currency_code+'/'+JSON.stringify(banktotal));
    }
	
	getTotal(banktotal){
		var total=0;
		if(banktotal!=null){
			if(typeof banktotal['1_s']!='undefined'){
				total+= parseInt(banktotal['1_s']);
			}
			if(typeof banktotal['2_s']!='undefined'){
				total+= parseInt(banktotal['2_s'])*2;
			}
			if(typeof banktotal['5_s']!='undefined'){
				total+= parseInt(banktotal['5_s'])*5;
			}
			if(typeof banktotal['10_s']!='undefined'){
				total+= parseInt(banktotal['10_s'])*10;
			}
			if(typeof banktotal['20_s']!='undefined'){
				total+= parseInt(banktotal['20_s'])*20;
			}
			if(typeof banktotal['50_s']!='undefined'){
				total+= parseInt(banktotal['50_s'])*50;
			}
			if(typeof banktotal['100_s']!='undefined'){
				total+= parseInt(banktotal['100_s'])*100;
			}
			if(typeof banktotal['cents']!='undefined'){
				total+= parseInt(banktotal['cents'])*0.01;
			}
			if(typeof banktotal['nickles']!='undefined'){
				total+= parseInt(banktotal['nickles'])*0.05;
			}
			if(typeof banktotal['dimes']!='undefined'){
				total+= parseInt(banktotal['dimes'])*0.1;
			}
			if(typeof banktotal['quarters']!='undefined'){
				total+= parseInt(banktotal['quarters'])*0.25;
			}
			if(typeof banktotal['50_cents']!='undefined'){
				total+= parseInt(banktotal['50_cents'])*0.5;
			}
			if(typeof banktotal['1_d']!='undefined'){
				total+= parseInt(banktotal['1_d']);
			}
		}
		return total;
	}
  
	render() {
		var data = this.props.customer_order_details;
		if (this.state.search) {
			data = data.filter(row => {
				return row.order_id.toLowerCase().includes(this.state.search) || row.customer_name.toLowerCase().includes(this.state.search) || row.total.toLowerCase().includes(this.state.search) 
			})
		}
	  
		const dataTableColumns = [			  
			{
				Header: "Order ID",
				accessor: "order_id",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Customer Name",
				accessor: "customer_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			}
		];

		const selected_currency = this.props.selected_currency;
		const drawer_starting_cash = this.props.drawer_starting_cash;
		const drawer_closing_total = JSON.parse(JSON.stringify(this.props.drawer_closing_total));
		const drawer_sales_cash_total = JSON.parse(JSON.stringify(this.props.drawer_sales_cash_total));
		
		const credit_card_total = this.props.credit_card_total;
		var field_view = [];

		const currency_struct = JSON.parse(selected_currency.currency_struct);
		for(let key in currency_struct){			
			field_view.push(<Fragment key={key}>
								<Colxx xxs="3" className="mt-4">
									<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
								</Colxx>
								<Colxx xxs="3" className="mt-4">
									<AvGroup className="error-t-negative">
									  <AvField
										id={key+"_"+selected_currency.currency_code}
										name={key+"_"+selected_currency.currency_code}
										type="number"
										placeholder="0"
										min="0"
										step="1"
										readOnly={this.props.readOnly}
										value={this.state.banktotal[key]!=0?this.state.banktotal[key]:''}
										onChange={(e)=>{
											var banktotal = this.state.banktotal;
											if(e.target.value!=''){
												banktotal[key]=e.target.value;
											}else{
												banktotal[key]=0;
											}
											this.setState({
												banktotal
											});
										}}									
									  />
									</AvGroup>
								</Colxx>
							</Fragment>)
			
		}
		
		const banktotal = this.state.banktotal;
		var total=this.getTotal(banktotal);
		
		
    return( 
		<Fragment>
			{this.props.customer_order_details.length ? <Fragment><div className="dropdown-edit small-padd-table">	
				<h2 className="mb-4">Customer Orders</h2>
				<FormGroup row>
					<Colxx lg="6">
						<Input
							type="text"
							name={"table_search"}
							placeholder="Search"
							value={this.state.search}
							onChange={e => this.setState({search: e.target.value.toLowerCase()})}
						/>
					</Colxx>
				</FormGroup>
				<ReactTable
					data={data}
					TbodyComponent={CustomTbodyComponent}
					columns={dataTableColumns}
					defaultPageSize={5}
					minRows={2}
					filterable={false}
					showPageJump={false}
					PaginationComponent={DataTablePagination}
					showPageSizeOptions={true}
					defaultSorted={[{id: "order_id",desc: true}]}
				/>
			</div>
			<hr/></Fragment>:""}
			<Row className="mt-2">
				<Colxx lg="6">
					<h2>Starting Cash Amount</h2>
				</Colxx>
				<Colxx lg="6">
					<h2>{selected_currency.currency_symbol+" "+drawer_starting_cash}</h2>
				</Colxx>
			</Row>
			<hr/>
			<Row>
				<Colxx lg="6">
					<h2>Sale Cash Amount</h2>
				</Colxx>
				<Colxx lg="6">
					<h2>{selected_currency.currency_symbol+" "+drawer_sales_cash_total.total}</h2>
				</Colxx>
			</Row>
			<hr/>
			<Row>
				<Colxx lg="6">
					<h2>Other Cash Amount</h2>
				</Colxx>
				<Colxx lg="6">
					<h2>{selected_currency.currency_symbol+" "+parseFloat(drawer_closing_total.total-parseFloat(drawer_starting_cash)-drawer_sales_cash_total.total).toFixed(2)}</h2>
				</Colxx>
			</Row>
			<hr/>
			<Row>
				<Colxx lg="6">
					<h2>Ending Cash Amount</h2>
				</Colxx>
				<Colxx lg="6">
					<h2>{selected_currency.currency_symbol+" "+drawer_closing_total.total}</h2>
				</Colxx>
			</Row>
			<hr/>
			<Row>
				<Colxx lg="6">
					<h2>Ending Credit Card Amount</h2>
				</Colxx>
				<Colxx lg="6">
					<h2>{selected_currency.currency_symbol+" "+credit_card_total}</h2>
				</Colxx>
			</Row>
			<hr/>
			
			<Row className="align-items-center">
				{field_view}
			</Row>
			<Row className="mt-5">
				<Colxx xxs="5">
					<h1>Total</h1>	
				</Colxx>
				<Colxx xxs="7">
					<h1>${total.toFixed(2)}</h1>		
				</Colxx>
			</Row>
			
			<Row className="mt-3 text-center">				
				<Colxx xxs="12">
					<Button color="primary" type="button" onClick={this.printLabel}>Print</Button>		
				</Colxx>
			</Row>
			
		</Fragment>
    );
  }
  
};
