import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, Button, CardBody, FormGroup, Label } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import { zeroPad } from "../../../helpers/Utils";

import moment from 'moment';
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey, JQUERY_MODULE, EQUIPMENT_STATUS} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ProcessOverview extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			extractors:[],
			crc:[],
			oven:[],
			rotovaps:[],
			distillators:[],
			max_count:0
		};
	}
	
	componentDidMount(){
	   var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/process-overview',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				this.setState({
					extractors: data.extractors,
					crc: data.crc,
					oven: data.oven,
					rotovaps: data.rotovaps,
					distillators: data.distillators,
					max_count: data.max_count,
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	render() {
		const { extractors, rotovaps, crc, oven, distillators, max_count } = this.state;
		var equipments=[];
		var i=0;
		for(i=0;i<max_count;i++){ 
			equipments.push(<div className="form-group equipments_status" key={i}>
								<h4 className="text-center">Process Train {zeroPad(i+1,2)}</h4>
								{typeof extractors[i]!='undefined' ? 
									<div className="col-lg-12 equipment_details">
										<img  alt="Extractor" src={REST_API_BASE_URL+"uploads/processor/"+extractors[i]['thumbnail']} className="img-responsive p-0" />
										<h3 className="text-center">Extractor</h3>
										<Button color="primary" className="btn btn-block btn-lg" style={{background:EQUIPMENT_STATUS.find(row=>{ return row.value==extractors[i]['equipment_status'] }).color, borderColor:EQUIPMENT_STATUS.find(row=>{ return row.value==extractors[i]['equipment_status'] }).color  }}>{extractors[i]['equipment_name']}<br/>{EQUIPMENT_STATUS.find(row=>{ return row.value==extractors[i]['equipment_status'] }).label}</Button>
										<div className="equipment_details_txt">
											<label  className="control-label col-lg-12">Batch ID : <b>{extractors[i]['batch_uid']!=null && extractors[i]['equipment_status']!="0" ? extractors[i]['batch_uid']:'N/A'}</b></label>                                                    
											{/*<label  className="control-label col-lg-12">Strain : <b>{extractors[i]['strain_name']!=null && extractors[i]['equipment_status']!="0" ? extractors[i]['strain_name']:'N/A'}</b></label>*/}                                                 
											<label  className="control-label col-lg-12">Operator : <b>{extractors[i]['operator_name']!=null && extractors[i]['equipment_status']!="0" ? extractors[i]['operator_name']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Start : <b>{extractors[i]['start_time']!=null && extractors[i]['equipment_status']!="0" ? moment(extractors[i]['start_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">End : <b>{extractors[i]['end_time']!=null && extractors[i]['equipment_status']!="0" ? moment(extractors[i]['end_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
										</div>
									</div>:<div className="col-lg-12 equipment_details">&nbsp;</div>}
								
								{typeof crc[i]!='undefined' ? 
									<div className="col-lg-12 equipment_details">
										<img  alt="Extractor" src={REST_API_BASE_URL+"uploads/processor/"+crc[i]['thumbnail']} className="img-responsive p-0" />
										<h3 className="text-center">CRC</h3>
										<Button color="primary" className="btn btn-block btn-lg" style={{background:EQUIPMENT_STATUS.find(row=>{ return row.value==crc[i]['equipment_status'] }).color, borderColor:EQUIPMENT_STATUS.find(row=>{ return row.value==crc[i]['equipment_status'] }).color  }}>{crc[i]['equipment_name']}<br/>{EQUIPMENT_STATUS.find(row=>{ return row.value==crc[i]['equipment_status'] }).label}</Button>
										<div className="equipment_details_txt">
											<label  className="control-label col-lg-12">Batch ID : <b>{crc[i]['batch_uid']!=null && crc[i]['equipment_status']!="0" ? crc[i]['batch_uid']:'N/A'}</b></label>                                                    
											{/*<label  className="control-label col-lg-12">Strain : <b>{crc[i]['strain_name']!=null && crc[i]['equipment_status']!="0" ? crc[i]['strain_name']:'N/A'}</b></label>*/}
											<label  className="control-label col-lg-12">Operator : <b>{crc[i]['operator_name']!=null && crc[i]['equipment_status']!="0" ? crc[i]['operator_name']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Start : <b>{crc[i]['start_time']!=null && crc[i]['equipment_status']!="0" ? moment(crc[i]['start_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">End : <b>{crc[i]['end_time']!=null && crc[i]['equipment_status']!="0" ? moment(crc[i]['end_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
										</div>
									</div>:<div className="col-lg-12 equipment_details">&nbsp;</div>}
									
								{typeof oven[i]!='undefined' ? 
									<div className="col-lg-12 equipment_details">
										<img  alt="Extractor" src={REST_API_BASE_URL+"uploads/processor/"+oven[i]['thumbnail']} className="img-responsive p-0" />
										<h3 className="text-center">Oven</h3>
										<Button color="primary" className="btn btn-block btn-lg" style={{background:EQUIPMENT_STATUS.find(row=>{ return row.value==oven[i]['equipment_status'] }).color, borderColor:EQUIPMENT_STATUS.find(row=>{ return row.value==oven[i]['equipment_status'] }).color  }}>{oven[i]['equipment_name']}<br/>{EQUIPMENT_STATUS.find(row=>{ return row.value==oven[i]['equipment_status'] }).label}</Button>
										<div className="equipment_details_txt">
											<label  className="control-label col-lg-12">Batch ID : <b>{oven[i]['batch_uid']!=null && oven[i]['equipment_status']!="0" ? oven[i]['batch_uid']:'N/A'}</b></label>                                                    
											{/*<label  className="control-label col-lg-12">Strain : <b>{oven[i]['strain_name']!=null && oven[i]['equipment_status']!="0" ? oven[i]['strain_name']:'N/A'}</b></label>*/}
											<label  className="control-label col-lg-12">Operator : <b>{oven[i]['operator_name']!=null && oven[i]['equipment_status']!="0" ? oven[i]['operator_name']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Start : <b>{oven[i]['start_time']!=null && oven[i]['equipment_status']!="0" ? moment(oven[i]['start_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">End : <b>{oven[i]['end_time']!=null && oven[i]['equipment_status']!="0" ? moment(oven[i]['end_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
										</div>
									</div>:<div className="col-lg-12 equipment_details">&nbsp;</div>}
								
								{/*typeof distillators[i]!='undefined' ? 
									<div className="col-lg-12 equipment_details">
										<Button className="btn btn-block btn-lg" style={{background:EQUIPMENT_STATUS.find(row=>{ return row.value==distillators[i]['equipment_status'] }).color, borderColor:EQUIPMENT_STATUS.find(row=>{ return row.value==distillators[i]['equipment_status'] }).color  }}>{distillators[i]['equipment_name']}<br/>{EQUIPMENT_STATUS.find(row=>{ return row.value==distillators[i]['equipment_status'] }).label}</Button>
										<div className="equipment_details_txt">
											<label  className="control-label col-lg-12">Batch ID : <b>{distillators[i]['batch_uid']!=null && distillators[i]['equipment_status']!="0" ? distillators[i]['batch_uid']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Strain : <b>{distillators[i]['strain_name']!=null && distillators[i]['equipment_status']!="0" ? distillators[i]['strain_name']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Operator : <b>{distillators[i]['operator_name']!=null && distillators[i]['equipment_status']!="0" ? distillators[i]['operator_name']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Start : <b>{distillators[i]['start_time']!=null && distillators[i]['equipment_status']!="0" ? moment(distillators[i]['start_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">End : <b>{distillators[i]['end_time']!=null && distillators[i]['equipment_status']!="0" ? moment(distillators[i]['end_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
										</div>
									</div>:<div className="col-lg-12 equipment_details">&nbsp;</div>*/}
									
								{/*typeof rotovaps[i]!='undefined' ? 
									<div className="col-lg-12 equipment_details">
										<Button className="btn btn-block btn-lg" style={{background:EQUIPMENT_STATUS.find(row=>{ return row.value==rotovaps[i]['equipment_status'] }).color, borderColor:EQUIPMENT_STATUS.find(row=>{ return row.value==rotovaps[i]['equipment_status'] }).color  }}>{rotovaps[i]['equipment_name']}<br/>{EQUIPMENT_STATUS.find(row=>{ return row.value==rotovaps[i]['equipment_status'] }).label}</Button>
										<div className="equipment_details_txt">
											<label  className="control-label col-lg-12">Batch ID : <b>{rotovaps[i]['batch_uid']!=null && rotovaps[i]['equipment_status']!="0" ? rotovaps[i]['batch_uid']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Strain : <b>{rotovaps[i]['strain_name']!=null && rotovaps[i]['equipment_status']!="0" ? rotovaps[i]['strain_name']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Operator : <b>{rotovaps[i]['operator_name']!=null && rotovaps[i]['equipment_status']!="0" ? rotovaps[i]['operator_name']:'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">Start : <b>{rotovaps[i]['start_time']!=null && rotovaps[i]['equipment_status']!="0" ? moment(rotovaps[i]['start_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
											<label  className="control-label col-lg-12">End : <b>{rotovaps[i]['end_time']!=null && rotovaps[i]['equipment_status']!="0" ? moment(rotovaps[i]['end_time']*1000).format("MM/DD/YYYY HH:mm"):'N/A'}</b></label>                                                    
										</div>
									</div>:<div className="col-lg-12 equipment_details">&nbsp;</div>*/}
								</div>);
		}
		
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.process-overview" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
		
				<Card className="p-4">
					<Row>
						{/*<Colxx lg="3" className="equipment-img">
							<div className="equipment_details text-center">
								<img  alt="Extractor" src={REST_API_BASE_URL+"img/processor/machine1.jpg"} className="img-responsive p-0" />
								<h3 className="text-center">Extractor</h3>
							</div>
							
							<div className="equipment_details text-center">
								<img  alt="CRC" src={REST_API_BASE_URL+"img/processor/machine4.jpg"} className="img-responsive p-0" />
								<h3 className="text-center">CRC</h3>
							</div>
							
							<div className="equipment_details text-center">
								<img  alt="CRC" src={REST_API_BASE_URL+"img/processor/machine2.jpg"} className="img-responsive p-0" />
								<h3 className="text-center">Oven</h3>
							</div>
							
							{/*<div className="equipment_details text-center">
								<img  alt="Distilator" src={REST_API_BASE_URL+"img/processor/machine2.jpg"} className="img-responsive p-0" />
								<h3 className="text-center">Distilator</h3>
							</div>
							
							<div className="equipment_details text-center">
								<img  alt="Rotovap Distillation Columns" src={REST_API_BASE_URL+"img/processor/machine3.jpg"} className="img-responsive p-0" />
								<h3 className="text-center">Rotovap Distillation Columns</h3>
							</div>*}
						</Colxx>*/}
						<Colxx lg="12" className="equipments">
							{equipments}							
						</Colxx>
					</Row>
				</Card>
			</Fragment>
		);
	}
}
