import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,FormGroup,
  Card, 
  Input,
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";

import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);
 
class HarvestModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			data:[],
			plant_list_name:[],
			strain_id:[],
			strain_name:[],
			plant_ids:[],
			room_id:null,
			table_id:null,
			search: '',
			selectRoom:[],
			selectTable:[],
			plants:[],
			wet_weight:""
		};	
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-harvest").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("harvestForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_ids',this.state.plant_ids);
			formData.append('room_id',this.props.room_id);
			formData.append('table_id',this.props.table_id);
			formData.append('strain_id',this.state.strain_id);
			formData.append('strain_name',this.state.strain_name);
			formData.append('plant_list_name',this.state.plant_list_name);
            
			
			axios.post(REST_API_END_POINT+'growhouse/harvest-plants',formData).then((res)=>{
				var data = res.data;
				
				let status=data.status;
				
				if(data.status==0){
					swal("Success",data.msg,"success").then(()=>{
						this.props.toggleModal();
						this.props.dataListRender();
						document.getElementById("update-harvest").disabled=false;
					});
				}else{
					let strain_name=data.strain_name;
					let strain_id=data.strain_id;
					let plant_ids=data.plant_ids;

					this.setState({
						plant_ids,
						strain_id:strain_id,
						strain_name:strain_name,
						plants : data.plants,
						wet_weight:""
					});
					
					document.getElementById("update-harvest").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-harvest").disabled=false;
			});

		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		var rooms=[];
		var tables=[];
		var strain_id=[];
		var plant_list_name="";
		var cureoptions=[];
		var strain_name=[];
		var plant_ids=[];
		var room_id=[];
		var table_id=[];
		var plant=[];
		
        if(this.props.modalOpen){
            if(prevProps.plant_ids !== this.props.plant_ids && this.props.plant_ids!=0) {
				var cure_options=[];
				var products=[];
				var rooms=[];
				var strain_id=[];
				var strain_name=[];
				var tables=[];
				var plants=[];
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();					
					
				formData.append('current_user_id',user_id);
				formData.append('facility_id',facility_id);	
				formData.append('plant_ids',this.props.plant_ids);
				formData.append('table_id',this.props.table_id);
				formData.append('type',5);
					
				axios.post(REST_API_END_POINT+'growhouse/create-plants-list',formData).then((res)=>{
					var data = res.data;
					
					let status=data.status;
						
					var msg =data.msg;
							
					if(status==0){
						swal(msg);
						this.props.toggleModal();
						return false;					
					}				
					
					rooms=data.rooms;			
			        tables=data.tables;
					
					rooms = rooms.map((element,index)=>{
								element.label=element.name;
								element.value=element.id;
								element.key=index;
								return element;
							});
							
					tables = tables.map((element,index)=>{
								element.label=element.name;
								element.value=element.id;
								element.key=index;
								return element;
							});
				
					strain_id=data.strain_id;
					strain_name=data.strain_name;
					plant_list_name=data.plant_list_name;
					plant_ids=data.plant_ids;

					this.setState({
						plant_list_name:plant_list_name,
						strain_id:strain_id,
						plant_ids:plant_ids,
						room_id:this.props.room_id,
						table_id:this.props.table_id,
						strain_name:strain_name,
						plants : data.plants,
						selectRoom:rooms,
						selectTable:tables,						
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});		
					
			}
		}		
	}


	getRoomData(room_id){
	  
		var tables=[];
		var rooms=[];
		var facility_id = localStorage.getItem('facility_id');
		
		facility_id =  dataCrypto.decrypt(facility_id);
			
		var formData = new FormData();		
		formData.append('room_id',room_id);
		
		axios.post(REST_API_END_POINT+'growhouse/get-active-tables-by-room',formData).then((res)=>{
			var data = res.data;
			
			if(data.tables){
				tables=data.tables;	

				tables = tables.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				var table_id=null;
				if(tables.length){
					table_id=tables[0].value;
				}
			
				this.setState({
					table_id,
					selectTable:tables,
				});
			}else{
				this.setState({
					selectTable:tables,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			
		});	
	}
	
	render() {
	  
		let data = this.state.plants;
		if(this.state.strain_name!=null){
			var strain_name=this.state.strain_name;
		}else{
			var strain_name;
		}

		var {selectTable} = this.state; 
	
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search.toLowerCase()) || row.strain.toLowerCase().includes(this.state.search.toLowerCase())|| row.phase.toLowerCase().includes(this.state.search.toLowerCase()) 
			})
		}
		
		const dataTableColumns = [			  
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Strain",
				accessor: "strain",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Phase",
				accessor: "phase",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Birth Date",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Time in Room",
				accessor: "time_in_room",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
		];
		
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Harvest Plants
			</ModalHeader>
			
			<ModalBody>
				<Row>
					<Colxx xxs="12">
						<Label>Strain: <strong>{strain_name ? ((strain_name!=null)?strain_name:''):'' }</strong></Label>
					</Colxx>
				</Row>
				<AvForm
					id="harvestForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<Row className="mt-4">
						<Colxx lg="6">
								<AvGroup className="error-t-negative">
									<Label>New Rooms<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="new_room_id"
										id="new_room_id"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
										value={this.state.room_id}
										onChange={(e)=>{
											this.setState({
												room_id:e.target.value
											},()=>{
												this.getRoomData(this.state.room_id)
											});
										}}
									>
									<option value="" >--Select Room--</option>
									{this.state.selectRoom.map(opt=>{
										return <option key={opt.key} value={opt.value} >{opt.label}</option>
									})}
									</AvField>						  
								</AvGroup>
							</Colxx>
							<Colxx lg="6" className="mb-2">						
							<AvGroup className="error-t-negative">
								  <Label>New Table<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="new_table_id"
										id="new_table_id"
										placeholder="--Select Table--"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
										value={this.state.table_id==null ? this.props.table_id:this.state.table_id}
										onChange={(e)=>{
											this.setState({
												table_id:e.target.value
											});
										}}
									>
										<option value="" >--Select Table Type--</option>
										{selectTable.map(opt=>{
											return <option key={opt.key} value={opt.value} >{opt.label}</option>
										})}		
									</AvField>
								</AvGroup>								
							</Colxx>
					</Row>	
					<Row className="mt-2">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Wet Weight<span className="text-danger">*</span></Label>
							  <AvField
								id="wet_weight"
								name="wet_weight"
								type="number"
								min="0"
								value={this.state.wet_weight}
								onChange={(e)=>{ this.setState({ wet_weight:e.target.value }) }}
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}							
							  />
							</AvGroup>
						</Colxx>											
					</Row>
					<Row className="mt-4">					
						<Colxx lg="12">
							<Button
								  id="update-harvest"
								  color="primary"
								  size="sm"
								  className="mr-2"							  
								>
								Harvest							  
							</Button>										
						</Colxx>
					</Row>
				</AvForm>
				<Card className="p-4 mt-4">									
					<div className="mb-4 dropdown-edit">							
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
							</Colxx>								
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={false}
							defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
				</Card>
			</ModalBody>
		
	  </Modal>
	  );
  }
};

export default HarvestModal;
