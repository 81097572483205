import React from "react";
import { Tooltip } from "reactstrap";

class TooltipItemContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  };

  render() {
    return (
      <span className={this.props.className?this.props.className:""}>
        <div id={"Tooltip-" + this.props.id} className={this.props.item.className?this.props.item.className:""} >
          {this.props.item.content}
        </div>
        <Tooltip
          placement={this.props.item.placement}
          isOpen={this.state.tooltipOpen}
          target={"Tooltip-" + this.props.id}
          toggle={this.toggle}
			className={this.props.TipClassName?this.props.TipClassName:""}
        >
          {this.props.item.body}
        </Tooltip>
      </span>
    );
  }
}
export default TooltipItemContent;
