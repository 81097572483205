import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Badge,DropdownMenu, DropdownItem, CustomInput } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import swal from 'sweetalert';

import CreateOrderModal from "../../../containers/wholesale/receive-inventory/CreateOrderModal";
import InvoiceModal from "../../../containers/wholesale/receive-inventory/InvoiceModal";
import ViewOrderModal from "../../../containers/wholesale/receive-inventory/ViewOrderModal";
import EditOrderModal from "../../../containers/wholesale/receive-inventory/EditOrderModal";
import ApproveOrderModal from "../../../containers/wholesale/receive-inventory/ApproveOrderModal";
import UploadTestResultModal from "../../../containers/wholesale/receive-inventory/UploadTestResultModal";
import ReceiveOrderModal from "../../../containers/wholesale/receive-inventory/ReceiveOrderModal";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, CUSTOMER_ORDER_STATUS} from "../../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AllOrders extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			orders:[],
			strains:[],
			search: '',
			order_id:0,
			modalOpenCreateOrder:false,
			modalOpenViewOrder:false,
			modalOpenInvoice:false,
			modalOpenApproveOrder:false,
			modalOpenUploadTestResult:false,
			modalOpenEditOrder:false,
			modalOpenCompleteOrder:false,
			selectAll:0,
			selectedIds:[]
		};
		
		this.toggleModalCreateOrder = this.toggleModalCreateOrder.bind(this);
		this.toggleModalViewOrder = this.toggleModalViewOrder.bind(this);
		this.toggleModalInvoice = this.toggleModalInvoice.bind(this);		
		this.toggleModalApproveOrder = this.toggleModalApproveOrder.bind(this);
		this.toggleModalUploadTestResult = this.toggleModalUploadTestResult.bind(this);
		this.toggleModalEditOrder = this.toggleModalEditOrder.bind(this);
		
		this.toggleModalReceiveOrder = this.toggleModalReceiveOrder.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.cancelOrders = this.cancelOrders.bind(this);
		this.activeItem = this.activeItem.bind(this);
	}
	
	componentDidMount(){
		this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'receive-inventory/all-orders',formData).then((res)=>{
			var data = res.data;
			var strains=data.strains;
			strains = strains.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
			if(data.orders){
				var orders=data.orders;
				orders = orders.map(row=>{
					
					if(row.status==0 && row.process_count==0){ 
						row.status_text = "Pending";
						row.status_badge = <Badge color="warning" >Pending</Badge>;
					}else if(row.status==1){
						row.status_text = "Approved";
						row.status_badge = <Badge color="info" >Approved</Badge>;
					} else if(row.status==2||(row.process_count>0&&row.status!=3&&row.status!=4&&row.status!=6&&row.status!=7&&row.status!=8)){ 
						row.status_text = "In Process";
						row.status_badge = <Badge color="info" >In Process</Badge>;
					} else if(row.status==3){
						row.status_text = "Completed";
						row.status_badge = <Badge color="success" >Completed</Badge>;
					}  else if(row.status==5){
						row.status_text = "Received";
						row.status_badge = <Badge color="info" >Received</Badge>;
					}  else if(row.status==6){
						row.status_text = "Ready to send to lab";
						row.status_badge = <Badge color="info" >Ready to send to lab</Badge>;
					} else if(row.status==7){
						row.status_text = "Sent to lab";
						row.status_badge = <Badge color="info" >Sent to lab</Badge>;
					} else if(row.status==8){
						row.status_text = "Test Result Submitted";
						row.status_badge = <Badge color="info" >Test Result Submitted</Badge>;
					} else {
						row.status_text = "Canceled";
						row.status_badge = <Badge color="danger" >Canceled</Badge>;
					} 

					return row;
				})
				
				this.setState({
			        orders: orders,
					strains
				});
			}else{
				this.setState({
					orders: [],
					strains
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleModalCreateOrder = () => {
		this.setState({
			modalOpenCreateOrder: !this.state.modalOpenCreateOrder
		});
	};
	
	toggleModalViewOrder = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenViewOrder: !this.state.modalOpenViewOrder
		});
	};
	
	toggleModalInvoice = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenInvoice: !this.state.modalOpenInvoice
		});
	};
	
	toggleModalUploadTestResult = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenUploadTestResult: !this.state.modalOpenUploadTestResult
		});
	};
	
	toggleModalApproveOrder = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenApproveOrder: !this.state.modalOpenApproveOrder
		});
	};
	
	toggleModalEditOrder = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenEditOrder: !this.state.modalOpenEditOrder
		});
	};
	
	toggleModalReceiveOrder = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenReceiveOrder: !this.state.modalOpenReceiveOrder
		});
	};
	
	
	activeItem=(e,order_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(order_id);
			
		}else{
		  var x=selectedIds.indexOf(order_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.orders.filter(x=>{ return x.status!=3 && x.status!=4 && x.status!=5 }).length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.orders.forEach(x => {
				if(x.status!=3 && x.status!=4 && x.status!=5){
					newSelected.push(x.order_id);
				}
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	sendToLab = (e,order_id)  => {
		
		var msg="Send this order to lab?";
        var success_msg="The orders has been sent to the lab.";
        var failure_msg="Failed to sent the order to the lab";
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('order_id',order_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'receive-inventory/send-to-lab',formData).then((res)=>{
					var data = res.data;
				
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	cancelOrders = (e,selectedIds)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one order","warning");
			return;
		}
		
		var msg="Cancel these orders?";
        var success_msg="The orders has been canceled.";
        var failure_msg="Failed to cancel orders";
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("cancel-orders").disabled=true;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('order_ids',JSON.stringify(selectedIds));
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'receive-inventory/cancel-orders',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("cancel-orders").disabled=false;					
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("cancel-orders").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
  
	render(){
		let data = this.state.orders;
		if (this.state.search) {
			data = data.filter(row => {
				return row.order_number.includes(this.state.search) || row.vendor_name.toLowerCase().includes(this.state.search) || moment(row.ordered_date*1000).format('MM/DD/YYYY').includes(this.state.search) || moment(row.due_date*1000).format('MM/DD/YYYY').includes(this.state.search) || row.status_text.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					if(rowInfo.original.status!=3 && rowInfo.original.status!=4 && rowInfo.original.status!=5){
						return ( <FormGroup>
							<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.order_id} onChange={event =>this.activeItem(event,rowInfo.original.order_id) } checked={this.state.selectedIds.includes(rowInfo.original.order_id)} />
							</FormGroup> );
					}else{
						return "";
					}
				}
			},
			{
				Header: "Order ID",
				accessor: "order_number",
				Cell: props => <p className="list-item-heading mb-0">{props.value}</p>
			},
			{
				Header: "Vendor Name",
				accessor: "vendor_name",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Date Expected",
				accessor: "due_date",
				Cell: props => <p className="text-muted mb-0">{moment(props.value*1000).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Date Received",
				accessor: "ordered_date",
				Cell: props => <p className="text-muted mb-0">{moment(props.value*1000).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Status",
				accessor: "status_badge",
				Cell: props => props.value
			},
			{
				Header: "Options",
				accessor: "order_id",
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalViewOrder(e,props.value) }}>
									<span className="d-inline-block">View</span>
								</DropdownItem>
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalInvoice(e,props.value) }} >
									<span className="d-inline-block">Invoice</span>
								</DropdownItem>
								{props.original.status<3 || props.original.status==5  ? <Fragment>
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalEditOrder(e,props.value) }} >
									<span className="d-inline-block" >Edit Order</span>
								</DropdownItem>
								{props.original.cannabis_count>0 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalApproveOrder(e,props.value) }} >
									<span className="d-inline-block" >Ready to send to lab</span>
								</DropdownItem>:""}
								</Fragment>:""}
								{props.original.status==6 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.sendToLab(e,props.value) }} >
									<span className="d-inline-block" >Send to Lab</span>
								</DropdownItem>:""}
								{props.original.status==7 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalUploadTestResult(e,props.value) }} >
									<span className="d-inline-block" >Upload Test Result</span>
								</DropdownItem>:""}
								{props.original.status==8 || (props.original.cannabis_count==0 && (props.original.status==1 || props.original.status==2 || (props.original.process_count>0 && props.original.status!=3 && props.original.status!=4 && props.original.status!=6 && props.original.status!=7 && props.original.status!=8))) ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalReceiveOrder(e,props.value) }} >
									<span className="d-inline-block" >Receive Order</span>
								</DropdownItem>:""}
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.all-orders" match={this.props.match} />
					<Separator className="mb-5" />
				</Colxx>
			</Row>
			
			<Row>          
				<Colxx md="12">
					<Card>
						<CardBody>	
							<Colxx xxs="12">
								<div id="api_message"></div>
							</Colxx>
							<div className="float-sm-right">
								{" "}<Button
									  color="primary"
									  size="sm"
									  id="cancel-orders"
									  onClick={(e)=>{ this.toggleModalCreateOrder() }}
									>
									  Create Order
								</Button>
								{" "}<Button
									  color="primary"
									  size="sm"
									  id="cancel-orders"
									  onClick={(e)=>{ this.cancelOrders(e,this.state.selectedIds) }}
									>
									  Cancel Orders
								</Button>
							</div>
							
							<div className="mb-4 dropdown-edit">					
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
								  </Colxx>
								</FormGroup>
								<Row>          
									<Colxx lg="12">
										<ReactTable
										  data={data}
										  columns={dataTableColumns}
										  defaultPageSize={10}
										  filterable={false}
										  showPageJump={false}
										  PaginationComponent={DataTablePagination}
										  showPageSizeOptions={true}
										  defaultSorted={[{id: "send_on",desc: false}]}
										/>
									</Colxx>
								</Row>
							</div>
						</CardBody>
					</Card>
				</Colxx>
			</Row>			

			<CreateOrderModal
				modalOpen={this.state.modalOpenCreateOrder}
				toggleModal={this.toggleModalCreateOrder}
				dataListRender={this.dataListRender}
				strains={this.state.strains}
			/>
			
			<ViewOrderModal
				modalOpen={this.state.modalOpenViewOrder}
				toggleModal={this.toggleModalViewOrder}
				order_id={this.state.order_id}
				dataListRender={this.dataListRender}
			/>

			<InvoiceModal
				modalOpen={this.state.modalOpenInvoice}
				toggleModal={this.toggleModalInvoice}
				order_id={this.state.order_id}
			/>
			
			<EditOrderModal
				modalOpen={this.state.modalOpenEditOrder}
				toggleModal={this.toggleModalEditOrder}
				dataListRender={this.dataListRender}
				order_id={this.state.order_id}				
				strains={this.state.strains}
			/>
			
			<ApproveOrderModal
				modalOpen={this.state.modalOpenApproveOrder}
				toggleModal={this.toggleModalApproveOrder}
				order_id={this.state.order_id}
				dataListRender={this.dataListRender}
			/>
			
			<UploadTestResultModal
				modalOpen={this.state.modalOpenUploadTestResult}
				toggleModal={this.toggleModalUploadTestResult}
				order_id={this.state.order_id}
				dataListRender={this.dataListRender}
			/>
			
			<ReceiveOrderModal
				modalOpen={this.state.modalOpenReceiveOrder}
				toggleModal={this.toggleModalReceiveOrder}
				order_id={this.state.order_id}
				dataListRender={this.dataListRender}
			/>
			
		</Fragment>
    );
  }
}
