import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
	Row,Card,
  CustomInput,
  Button,
  Modal,Input,
  ModalHeader,
  ModalBody,
  ModalFooter,FormGroup,
  Label,  
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx} from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class AddUserroleModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.SelectAll = this.SelectAll.bind(this);
    this.DeselectAll = this.DeselectAll.bind(this);
	
	this.state = {	
		Permissions:[],	
		AllPermissions:[],	
		selected_permissions:[],	
	};
	
  }

	componentDidMount() {	
		var Permissions=[];

		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'staff/get-data-for-add-user-role',formData).then((res)=>{
			var data = res.data;
		
			this.setState({
				Permissions:data.staff_permissions,					
				AllPermissions:data.staff_permissions,					
			});			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
	}  
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-userrole").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("userForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'staff/add-user-role',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-userrole").disabled=false;
				this.props.toggleModal();
				this.DeselectAll();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-userrole").disabled=false;
			});

		}
	}
	
	SelectAll(){
		var AllPermissions = this.state.AllPermissions;
		this.setState({
			Permissions:[]
		},()=>{
			this.setState({
				Permissions:AllPermissions,					
				selected_permissions:AllPermissions.map(row=>row.upriv_id),					
			});
		});
		
	}
	
	DeselectAll(){
		var AllPermissions = this.state.AllPermissions;
		this.setState({
			Permissions:[]
		},()=>{
			this.setState({
				Permissions:AllPermissions,					
				selected_permissions:[],					
			});
		});
		
	}
  
	render() {	
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={(e)=>{ this.DeselectAll(); this.props.toggleModal(e); }}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add User Role
			</ModalHeader>
			<AvForm
				id="userForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>				
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Role Name<span className="text-danger">*</span></Label>
								<AvField
									id="name"
									name="name"
									type="text"
									autoComplete="off"
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									  pattern: {
										value: "^[a-zA-Z0-9 ,.'-]+$",
										errorMessage: "Invalid name"
									  },
									  minLength: {
										value: 2,
										errorMessage:
										  "The name must be between 2 and 100 characters"
									  },
									  maxLength: {
										value: 100,
										errorMessage:
										  "The name must be between 2 and 100 characters"
									  }
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Pay Rate</Label>
								<AvField
									id="pay_rate"
									name="pay_rate"
									type="text"
									autoComplete="off"
									validate={{
										number: {
											value: true,
											errorMessage: "Please enter a valid number"
										}
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Permissions</Label>
							  <Button size="sm" color="primary" type="button" className="ml-2" onClick={this.SelectAll}>Select All</Button>{" "}
							  <Button size="sm" color="primary" type="button" className="ml-2" onClick={this.DeselectAll}>Deselect All</Button>{" "}
							</AvGroup>
						</Colxx>
					</Row>
				
					{this.state.Permissions.length ? <Row>				
						<Colxx lg="12"  >
							<AvCheckboxGroup
								inline
								name="assigned_permissions[]"
								className="user_permissions"
								onChange={(e) => {}}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select any of the permissions"
								  }
								}}
								defaultValue={this.state.selected_permissions}
							>
								<Row>
								{this.state.Permissions.map((row,index)=>{
									return <Colxx lg="4" key={index} ><AvCheckbox customInput label={row.upriv_name} value={row.upriv_id} title={row.upriv_desc} /></Colxx>
								})}
								</Row>
							</AvCheckboxGroup >
						</Colxx>
					</Row>:""}
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-userrole">
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={(e)=>{ this.DeselectAll(); this.props.toggleModal(e); }}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default AddUserroleModal;
