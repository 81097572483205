import React, { Fragment, Component } from "react";
import {
    Row, Container
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";


class TestimSolo extends Component {
    constructor(props) {
        super(props);
    }

    render() {


        return (
            <Row className={`${this.props.class && this.props.class} py-5 testim-solo`}>
                <Colxx className='text-center' xxs={12}>
                    <Container>
                        <h1>
                            {this.props.heading}
                        </h1>
                    </Container>
                </Colxx>
                <Colxx xxs={12}>
                    <div className='text-center mx-auto quote-test' style={{ maxWidth: 900 }}>
                        <h2>
                            {this.props.children}
                        </h2>
                        <h3 className='text-right mr-5'>
                            - {this.props.person}
                        </h3>
                    </div>
                </Colxx>
            </Row>
        );
    }
};

export default TestimSolo;
