import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Button,Input } from "reactstrap";

import {Card, CardBody} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import {
	
  CustomInput,
  
  Label,
  Collapse,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import Calendarsettings from "../../../containers/calendar/CalendarSettings";
import AddEventModal from "../../../containers/calendar/AddEventModal";
import AddInspection from "../../../containers/calendar/AddInspection";

import { NavLink } from "react-router-dom";

class CalendarDashboard extends Component {
	
	constructor(props) {
    super(props);

	
    
    this.state = {
		modalOptions: false,
		}
	};
	
	toggleModalOptions = () => {
		this.setState({
		  modalOptions: !this.state.modalOptions
		});
	};
	
  render() {
    //const {messages} = this.props.intl;
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.express-calendar" match={this.props.match}/>
            <Separator className="mb-5" />
          </Colxx>
        </Row>
        <Row>			
            <Colxx lg="12"  className="mb-4">
			<Row>																							
				<Colxx lg="12" className="text-right mb-2" >
					
				</Colxx>					
			</Row>	
			  <Row>				  
				  <Colxx xl="12" lg="12" className="mb-4">
					<Calendarsettings modalEvent={this.toggleModalOptions}/>
				  </Colxx>			  
			  </Row>
			</Colxx>			 
        </Row>
        
      </Fragment>
    );
  }
}

export default injectIntl(CalendarDashboard);