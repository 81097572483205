import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, FormGroup,Label,Input,
    DropdownItem,
    DropdownMenu, } from "reactstrap";
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import EditInformation from "../../containers/wholesale/EditInformation";

class MoreInfo extends Component {
	constructor(props) {
		super(props);
		 this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			 modalOpen: false,
		};
	}
	toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };
	handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
     
    }
  }
  
  render() {
	 
    return (
      <Fragment>
	  <AvForm
		className="av-tooltip tooltip-right-bottom"
		onSubmit={this.handleSubmit}
	  >
        <Row>         
          <Colxx xxs="12">
             	<div className="text-right mobile-template-options">
					<NavLink to="#" onClick={this.toggleModal} className="btn btn-primary btn-sm btn-shadow">
							Edit Information		
					</NavLink>
				</div>
				<EditInformation
					modalOpen={this.state.modalOpen}
					toggleModal={this.toggleModal}
				  />
          </Colxx>
        </Row>
		
		<h3 className="font-weight-bold">Bank Information</h3>
		<Row className="mt-4">
			<Colxx xxs="4">
				
				  <span className="font-weight-bold">Bank Name</span>						  
				
			</Colxx>
			<Colxx xxs="4">
				
				  <span className="text-muted">Axis Bank</span>						  
				
			</Colxx>					
		</Row>
		<Row>
			<Colxx xxs="4">
				<AvGroup className="error-t-negative">
				  <span className="font-weight-bold">Name on Account</span>						  
				</AvGroup>
			</Colxx>
			<Colxx xxs="4">
				<AvGroup className="error-t-negative">
				  <span className="text-muted">Ava</span>						  
				</AvGroup>
			</Colxx>					
		</Row>
		<Row>
			<Colxx xxs="4">
				<AvGroup className="error-t-negative">
				  <span className="font-weight-bold">Routing Number</span>						  
				</AvGroup>
			</Colxx>
			<Colxx xxs="4">
				<AvGroup className="error-t-negative">
				  <span className="text-muted">123456784</span>						  
				</AvGroup>
			</Colxx>					
		</Row>
		
		</AvForm>			
      </Fragment>
    );
  }
}

export default MoreInfo;
