import React , { Component, Fragment } from 'react'
import { Row, Card, CustomInput, CardTitle,FormGroup,Badge,DropdownMenu,DropdownItem } from "reactstrap";
import { NavLink } from "react-router-dom";

import ItemDropdown from "../../components/customer/ItemDropdown";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";

const ImageCardList = () => {

	
  return (
    <Row>
    <Colxx xxs="12">
      <CardTitle className="mb-4"><IntlMessages id="cards.Stages" /></CardTitle>
      <Row>
        <Colxx xxs="12">
          <Card className="d-flex flex-row mb-3">
            <NavLink to="/app/ui/cards" className="d-flex">
              <img alt="Thumbnail" src="/assets/img/avatar.jpg" className="list-thumbnail responsive border-0" />
            </NavLink>
            <div className="pl-2 d-flex flex-grow-1 min-width-zero">
              <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                <NavLink to="/app/ui/cards" className="w-40 w-sm-100">
                  <p className="list-item-heading mb-1 truncate">Stage</p>
                </NavLink>
                <p className="mb-1 text-muted text-small w-15 w-sm-100">Data Collection</p>
            <i class="fas fa-circle"></i> <p className="mb-1 text-muted text-small w-15 w-sm-100">Automatic</p>
                <div className="w-15 w-sm-100">
				{/*<Badge color="primary" pill >iconsminds-upward</Badge>*/}
				
				
			       <i className="fas fa-arrows-alt-v"></i>
				  
                </div>
              </div>
              <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
              <FormGroup>
			 
                  <i className="simple-icon-options-vertical"></i>
                </FormGroup>
				 
		
			  
	  
              </div>
            </div>
          </Card>
        </Colxx>
      
      </Row>
    </Colxx>
  </Row>
  )
}

export default ImageCardList
