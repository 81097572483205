const createworkflow = [
{
    id: 1,
    name: "Canna Culture", 
	createdOn: "13/02/2019",   
	createdBy: "aj",    
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Canna ", 
	createdOn: "13/02/2019",   
	createdBy: "aj",    
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 3,
    name: "Canna ",
	createdOn: "13/02/2019",
	createdBy: "aj",
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default createworkflow
