import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DatePicker from "../../components/common/CustomDateTimePicker";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import swal from "sweetalert";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class CovidFormModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			facility_data:null,
			visitor_data:null,
			show_form:true
		};
	}
	
	componentDidMount(){  
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'visitor/get-visitor-settings',formData).then((res)=>{
			var data = res.data;
		
			if(data.facility){
				this.setState({
					facility_data: data.facility,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					facility_data: null,
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			const facility_data = this.state.facility_data;
			
			document.getElementById("checkin-visitor").disabled=true;
			
			var post_url = 'visitor/submit-covid-form';
			
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("checkinForm"));
			formData.append('facility_id',facility_id);
			formData.append('visitor_id',this.props.visitor_data.vid);
			formData.append('user_id',user_id);
			if(typeof values.name!='undefined' && facility_data.covid_form!=null){
				formData.append('covid_form',JSON.stringify(facility_data.covid_form));
			}
			
			let check_boxes={};
			
			for(let pair of formData.entries()){
				if(pair[0].includes('checkboxes')){
					if(typeof check_boxes[pair[0]]=='undefined'){
						check_boxes[pair[0]]=[];
					}
					check_boxes[pair[0]].push(pair[1]);
				}
			}
			
			for(let pair of formData.entries()){
				if(pair[0].includes('checkboxes')){
					formData.delete(pair[0]);
				}
			}
						
			for(let key in check_boxes){
				formData.append(key,JSON.stringify(check_boxes[key]));
			}
			
			
			axios.post(REST_API_END_POINT+post_url,formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("checkin-visitor").disabled=false;
				
				if(data.status==1){
					swal('Success!',data.msg,"success").then(()=>{
						if(facility_data.print_visitor_badge=="1"){
							this.props.showPrintBadge(null,data.visitor_data);
						}else{
							this.props.toggleModal();
						}
					});
				}else{
					swal('Warning!',data.msg,"warning");
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("checkin-visitor").disabled=false;
			});

		}
	}
	
	render() {
		const facility_data = this.state.facility_data;
		return(	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Covid Form
				</ModalHeader>
				
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
					id="checkinForm"
				>
					<ModalBody>
						{facility_data!=null ? (facility_data.covid_form!=null ? facility_data.covid_form.map((row,index)=>{
							if(row.element=="TextInput" || row.element=="EmailInput" || row.element=="NumberInput" || row.element=="TextArea"){
								return(<Row key={index}>
									<Colxx lg="12">
										<AvGroup className="error-t-negative">
											<Label>{row.label} {row.required ? <span className="text-danger">*</span>:""}</Label>
											<AvField
												name={row.field_name}
												type={row.element=="TextInput" ? "text":(row.element=="EmailInput" ? "email":(row.element=="NumberInput" ? "number":"textarea"))}
												autoComplete="off"
												validate={row.required ? {
													required: {
														value: true,
														errorMessage: "This field is required."
													}
												}:{}}
											/>
										</AvGroup>
									</Colxx>
								</Row>)
							}else if(row.element=="Dropdown"){
								return(<Row key={index}>
									<Colxx lg="12">
										<AvGroup className="error-t-negative">
											<Label>{row.label} {row.required ? <span className="text-danger">*</span>:""}</Label>
											<AvField
												name={row.field_name}
												type="select"
												autoComplete="off"
												validate={row.required ? {
													required: {
														value: true,
														errorMessage: "This field is required."
													}
												}:{}}
											>
											<option value="">Select</option>
											{row.options.map(r=>{
												return(<option value={r.text} key={r.key}>{r.text}</option>)
											})}
											</AvField>
										</AvGroup>
									</Colxx>
								</Row>)
							}else if(row.element=="RadioButtons"){
								return(<Row key={index}>
									<Colxx lg="12">
										<AvGroup className="error-t-negative">
											<Label>{row.label} {row.required ? <span className="text-danger">*</span>:""}</Label>
											<AvRadioGroup
												inline={row.canHaveDisplayHorizontal}
												name={row.field_name}
												validate={row.required ? {
													required: {
														value: true,
														errorMessage: "Please select any of the options"
													}
												}:{}}
											>								
												{row.options.map(r=>{
													return(<AvRadio customInput label={r.text} key={r.key} value={r.text} />)
												})}																				
											</AvRadioGroup>
										</AvGroup>
									</Colxx>
								</Row>)
							}else if(row.element=="Checkboxes"){
								return(<Row key={index}>
									<Colxx lg="12">
										<AvGroup className="error-t-negative">
											<Label>{row.label} {row.required ? <span className="text-danger">*</span>:""}</Label>
											<AvCheckboxGroup
												inline={row.canHaveDisplayHorizontal}
												name={row.field_name}
												validate={row.required ? {
													required: {
														value: true,
														errorMessage: "Please select at least one option"
													}
												}:{}}
											>								
												{row.options.map(r=>{
													return(<AvCheckbox customInput label={r.text} key={r.key} value={r.text} />)
												})}																				
											</AvCheckboxGroup>
										</AvGroup>
									</Colxx>
								</Row>)
							}else if(row.element=="DatePicker"){
								return(<Row key={index}>
									<Colxx lg="12">
										<AvGroup className="error-t-negative">
											<Label>{row.label} {row.required ? <span className="text-danger">*</span>:""}</Label>
											<DatePicker
												name={row.field_name}
												className=""
												selected={row.value}
												placeholderText=""
												dateFormat="MM/DD/YYYY"
												onChange={(date)=>{
													var facility_data = this.state.facility_data;
													facility_data.visitor_form = facility_data.visitor_form.map(r=>{
														if(r.id==row.id){
															r.value = date;
														}
														return r;
													})
													this.setState({ facility_data })
												}}
											  />
										</AvGroup>
									</Colxx>
								</Row>)
							}else{
								return "";
							}
						}):""):""}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="checkin-visitor" >
						  <IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
						  <IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
