import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { AvField, AvGroup } from "availity-reactstrap-validation";
import {	
  CustomInput,
  Button,
  Label,FormGroup,
  Card, 
} from "reactstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import AddProductLabelModal from "../../containers/inventory/AddProductLabelModal";
export default class ProductRow extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			product_row: this.props.product_row,
			tax_percentage:this.props.tax_percentage,
			modalProductAdd:false,
		};
	}
	toggleModalAdd = () => {
	
		const order_id=this.props.orderId
		this.setState({
			modalProductAdd: !this.state.modalProductAdd,
		  orderId:this.props.orderId,
		});
		
	};
	componentDidMount() {
		var product_row = this.state.product_row;	
		var tax_percentage=this.state.tax_percentage;
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		if (JSON.stringify(prevProps.product_row) !== JSON.stringify(this.props.product_row) && JSON.stringify(this.state.product_row) !== JSON.stringify(this.props.product_row)){		
		console.log('GH');
		
			this.setState({ product_row:this.props.product_row });
			this.setState({tax_percentage:this.props.tax_percentage});
		}
		
	}
	
	
	handleProductChange = product_id => {
		var product_row = this.state.product_row;
		product_row.product_id = product_id;
		
		this.setState({
			product_row
		});
			
		this.props.updateProductRow(product_row);	
	};
  
	render() {
		var product_row = this.state.product_row;
		const { products, index } = this.props;
		
		return( 
			<Card className="p-3 border-radius-7 mb-2">
				<Row>
					<Colxx md="7" className="pr-0">
						<AvGroup >
							<Label>Product<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name={"product_id["+index+"]"}
								id={"product_id_"+index}
								 value={this.state.product_row.product_id}						
								options={products}
								onChange={selectProduct => { this.handleProductChange(selectProduct) }}
								required
							/>
						</AvGroup>
					</Colxx>
					
					<Colxx md="1" className="pr-0">
						<AvGroup>
							<Label>Quantity<span className="text-danger">*</span></Label>
							<AvField
								id={"product_quantity_"+index}
								name={"product_quantity["+index+"]"}
								autoComplete="off"
								type="number"
								value={this.state.product_row.product_quantity}
								onChange={e => { 
									var product_row = this.state.product_row;
									product_row.product_quantity = e.target.value;
									
									this.setState({	product_row	});			
									this.props.updateProductRow(product_row);
								}}							
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required"
								  },
								  min: {
									value: "1",
									errorMessage: "Please enter a value greater than 0"
								  },
								  step: {
									value: "1",
									errorMessage: "Only digits allowed"
								  },
								}}
							/>
						</AvGroup>
					</Colxx>
					<Colxx md="2" className="pr-0 ">
						<AvGroup>
							<Label>Unit Price</Label>
							<AvField
								id={"unit_price_"+index}
								name={"unit_price["+index+"]"}
								autoComplete="off"
								type="number"
								value={this.state.product_row.product_id["unit_price"]}							
							/>
						</AvGroup>
					</Colxx>
					<Colxx lg="2 mt-4">
						<Button color="primary" onClick={this.toggleModalAdd} ><b><i className="simple-icon-plus" /></b></Button>
						</Colxx>
					<Colxx md="1" className="pr-0 ">
						<AvGroup>
							<Label>VAT</Label>
							<AvField
								id={"tax"+index}
								name={"tax["+index+"]"}
								autoComplete="off"
								type="number"
								value={this.state.tax_percentage}							
							/>
						</AvGroup>
					</Colxx>
					<Colxx md="2" className="text-right">
						<Label className="d-block w-100">&nbsp;</Label>
						{this.props.product_rows_length!=1 ? <Button type="button" size="sm" color="danger" className="mr-2" onClick={()=>{ this.props.removeProductRow(product_row.id); }} >Remove</Button>:""}
					</Colxx>
				</Row>	

				<AddProductLabelModal
				modalOpen={this.state.modalProductAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.props.dataListRender}
				orderId={this.state.orderId}
					/>			
			</Card>
			
		);
		
	}
	
};
