import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import DatePicker from "../components/common/CustomDateTimePicker";

import {REST_API_END_POINT,dataEncryptKey, JQUERY_MODULE, BASE_URL } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import swal from "sweetalert";
import LocationMap from "../components/contact/LocationMap";
import { seo } from "../helpers/Utils";

const dataCrypto = new SimpleCrypto(dataEncryptKey);


class ContactUs extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		  template_data:null,
		  facility_data:null,
		  contact_form:null,
		  real_contact_form:null
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		seo({
			title: 'Contact Us | '+projectTitle,
			metaDescription: 'Reach out to us'
		});
	}
  
	componentDidMount() {
		
		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id){
			this.dataListRender();
		}else{
			window.location='/';
		}
	}
	
	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var data_upload = new FormData();
		data_upload.append('facility_id',facility_id);
		data_upload.append('template_id','contact-us');
		axios.post(REST_API_END_POINT+'website/get-page-template-by-alias',data_upload).then((res)=>{ 
			if(res.data.status==1){
				this.setState({ template_data: res.data.data, facility_data: res.data.facility_data, contact_form: res.data.contact_form, real_contact_form: JSON.parse(JSON.stringify(res.data.contact_form)), blocking:false });				
			}else{
				this.setState({ blocking:false });					
			}			
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e)
			this.setState({ blocking:false });
		});
	}
	
	handleSubmit (event, errors, values){
		if (errors.length === 0) {		
			if(this.state.contact_form!=null){
				let proceed=true;
				for(let row of this.state.contact_form){
					if(row.element=="DatePicker" && row.required){
						if((typeof row.value!='undefined' && row.value==null) || typeof row.value=='undefined'){
							proceed=false;
							swal("Warning","Please enter "+row.label.toLowerCase(),"warning");
							break;
						}
					}
				}	
				if(!proceed){
					return;
				}				
			}
			document.getElementById("contact-submit").disabled=true;
			
			const user_id = localStorage.getItem('user_id');
			const customer_id = this.state.customer_id;
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
					
			var formData = new FormData(document.getElementById("contactForm")); 
			formData.append('facility_id',facility_id);
			
			if(this.state.contact_form!=null){
				formData.append('contact_form',JSON.stringify(this.state.contact_form));
			}
			
			let check_boxes={};
			
			for(let pair of formData.entries()){
				if(pair[0].includes('checkboxes')){
					if(typeof check_boxes[pair[0]]=='undefined'){
						check_boxes[pair[0]]=[];
					}
					check_boxes[pair[0]].push(pair[1]);
				}
			}
			
			for(let pair of formData.entries()){
				if(pair[0].includes('checkboxes')){
					formData.delete(pair[0]);
				}
			}
						
			for(let key in check_boxes){
				formData.append(key,JSON.stringify(check_boxes[key]));
			}
			
			axios.post(REST_API_END_POINT+'front-end/contact-form',formData).then((res)=>{
				var data = res.data;
			
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					this.form && this.form.reset();
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
				}
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove(); 
					});
				}, 5000);
				
				document.getElementById("contact-submit").disabled=false;
				this.setState({ contact_form:null },()=>{
					this.setState({ contact_form : JSON.parse(JSON.stringify(this.state.real_contact_form)) })
				});
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("contact-submit").disabled=false;
			});
		}
	}
	
	render() {
		 const params = {
            spaceBetween: 10,
            centeredSlides: true,
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false
            },
        }
		const { template_data, facility_data, contact_form } = this.state;
		var content_text='';
		var banner_image='';
		var contact_phone='';
		var contact_email='';
		var contact_address='';
		var contact_map='';
		var page_name='';
		var page_sub_title='';
		if(template_data!=null){
			let content_text_row = (template_data.content.find(r=>{ return r.key=='content_text' }));
			if(content_text_row!=null){
				content_text = content_text_row.value;
			}
			
			let contact_phone_row = (template_data.content.find(r=>{ return r.key=='contact_phone' }));
			if(contact_phone_row!=null){
				contact_phone = contact_phone_row.value;
			}
			let contact_email_row = (template_data.content.find(r=>{ return r.key=='contact_email' }));
			if(contact_email_row!=null){
				contact_email = contact_email_row.value;
			}
			let contact_address_row = (template_data.content.find(r=>{ return r.key=='contact_address' }));
			if(contact_address_row!=null){
				contact_address = contact_address_row.value;
			}
			
			let banner_image_row = (template_data.content.find(r=>{ return r.key=='banner_image' }));
			if(banner_image_row!=null){
				banner_image = banner_image_row.value;
			}
			contact_map=template_data.contact_map;
			page_name=template_data.name;
			page_sub_title=template_data.sub_title;
		}
		return (
			<Fragment>
				<ToastProvider placement="bottom-left">
					<div className="main-pages-layout">
						<main>
							<HomeHeader />
							    {banner_image!='' ? <div className="front-page-banner" style={{backgroundImage:`url(${BASE_URL}pages/${banner_image})`}}>
									<div className="front-page-header-wrapper d-flex flex-wrap-wrap align-items-center">
										<div className="front-page-header-text">
											<h1>{page_name}</h1>
											{page_sub_title!='' ? <h3 className="h3">{page_sub_title}</h3>:""}
										</div>
									</div>
								</div>:""}
								
								<div className="row ">
									<div className="col-lg-6">
										<div className="pl-3 pl-sm-5 pb-2 mb-5">
											<div className="mt-5">
												<div className="dynamic-page-content" dangerouslySetInnerHTML={{ __html: content_text }} ></div>	
											</div>
											{contact_phone!='' ? <div className="mt-4">
												 <i className=" ic-r simple-icon-phone" style={{color:"#356ab6", fontSize:"20px"}}></i>
												 <span className="pl-3 pr-3 f-16">{contact_phone}</span>
											</div>:""}
											{contact_email!='' ? <div className="mt-4">
												 <i className=" ic-r simple-icon-globe " style={{color:"#356ab6", fontSize:"20px"}}></i>
												 <span className="pl-3 pr-3 f-16">{contact_email}</span>												 
											</div>:""}
											{contact_address!='' ? <div className="mt-4">
												 <i className="ic-r simple-icon-location-pin" style={{color:"#356ab6", fontSize:"20px"}}></i>
												 <span className="pl-3 pr-3 f-16 d-inline-block" style={{verticalAlign:'middle'}} dangerouslySetInnerHTML={{ __html: contact_address }}></span>
											</div>:""}
										</div>
									</div>
									<div className="col-lg-6 grey h-50 contact-form-wrapper">
										<div className="px-3">
											<h3 className="black f-w-7 ">GET IN TOUCH</h3>
										</div>
										
										<AvForm
											id="contactForm"
											ref={c => (this.form = c)}
											className="error-t-negative px-3 mt-3"
											onSubmit={this.handleSubmit}
										>
											{contact_form!=null ? contact_form.map((row,index)=>{
												if(row.element=="TextInput" || row.element=="EmailInput" || row.element=="NumberInput" || row.element=="TextArea"){
													return(<div className="row  " key={index}>
														<div className="col-lg-12 grey ">
															<AvGroup className="error-t-negative">
																<Label dangerouslySetInnerHTML={{ __html: row.label+(row.required ? '<span class="text-danger">*</span>':"") }}></Label>
																<AvField
																	name={row.field_name}
																	type={row.element=="TextInput" ? "text":(row.element=="EmailInput" ? "email":(row.element=="NumberInput" ? "number":"textarea"))}
																	autoComplete="off"
																	validate={row.required ? {
																		required: {
																			value: true,
																			errorMessage: "This field is required."
																		}
																	}:{}}
																/>
															</AvGroup>
														</div>
													</div>)
												}else if(row.element=="Dropdown" || row.element=="StaffDropdown"){
													return(<div className="row " key={index}>
														<div className="col-lg-12 grey ">
															<AvGroup className="error-t-negative">
																<Label dangerouslySetInnerHTML={{ __html: row.label+(row.required ? '<span class="text-danger">*</span>':"") }}></Label>
																<AvField
																	name={row.field_name}
																	type="select"
																	autoComplete="off"
																	validate={row.required ? {
																		required: {
																			value: true,
																			errorMessage: "This field is required."
																		}
																	}:{}}
																>
																<option value="">Select</option>
																{row.options.filter(r=>{ return r.text!="" }).map(r=>{
																	return(<option value={r.text} key={r.key}>{r.text}</option>)
																})}
																</AvField>
															</AvGroup>
														</div>
													</div>)
												}else if(row.element=="RadioButtons"){
													return(<div className="row " key={index}>
														<div className="col-lg-12 grey ">
															<AvGroup className="error-t-negative">
																<Label dangerouslySetInnerHTML={{ __html: row.label+(row.required ? '<span class="text-danger">*</span>':"") }}></Label>
																<AvRadioGroup
																	inline={row.inline}
																	name={row.field_name}
																	validate={row.required ? {
																		required: {
																			value: true,
																			errorMessage: "Please select any of the options"
																		}
																	}:{}}
																>								
																	{row.options.filter(r=>{ return r.text!="" }).map(r=>{
																		return(<AvRadio customInput label={r.text} key={r.key} value={r.text} />)
																	})}																				
																</AvRadioGroup>
															</AvGroup>
														</div>
													</div>)
												}else if(row.element=="Checkboxes"){
													return(<div className="row " key={index}>
														<div className="col-lg-12 grey ">
															<AvGroup className="error-t-negative">
																<Label dangerouslySetInnerHTML={{ __html: row.label+(row.required ? '<span class="text-danger">*</span>':"") }}></Label>
																<AvCheckboxGroup
																	inline={row.inline}
																	name={row.field_name}
																	validate={row.required ? {
																		required: {
																			value: true,
																			errorMessage: "Please select at least one option"
																		}
																	}:{}}
																>								
																	{row.options.filter(r=>{ return r.text!="" }).map(r=>{
																		return(<AvCheckbox customInput label={r.text} key={r.key} value={r.text} />)
																	})}																				
																</AvCheckboxGroup>
															</AvGroup>
														</div>
													</div>)
												}else if(row.element=="DatePicker"){
													return(<div className="row " key={index}>
														<div className="col-lg-12 grey ">
															<AvGroup className="error-t-negative">
																<Label dangerouslySetInnerHTML={{ __html: row.label+(row.required ? '<span class="text-danger">*</span>':"") }}></Label>
																<DatePicker
																	name={row.field_name}
																	className=""
																	selected={row.value}
																	placeholderText=""
																	dateFormat="MM/DD/YYYY"
																	onChange={(date)=>{
																		var contact_form = this.state.contact_form;
																		contact_form = contact_form.map(r=>{
																			if(r.id==row.id){
																				r.value = date;
																			}
																			return r;
																		})
																		this.setState({ contact_form })
																	}}
																  />
															</AvGroup>
														</div>
													</div>)
												}else{
													return "";
												}
											}):""}
											
											<div className="mt-2">
												<button id="contact-submit" className="white btn-work-about">SUBMIT</button> 
											</div>
										</AvForm>
										<div id="api_message" className="mt-2"></div>
									</div>
								</div>
								 
								{contact_map!="" ? <div className="contact-map-wrapper" >
									<iframe src={contact_map} width="600" height="450" style={{border:0}} allowFullScreen="" ></iframe>
								</div>:""}
							<HomeFooter />
						</main>	
					</div>
				</ToastProvider>
			</Fragment>	
		);
	}
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(ContactUs);
