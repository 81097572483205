import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, CardTitle, Button, Label, InputGroup, InputGroupAddon, CustomInput,  DropdownItem, DropdownMenu, } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";

import { REST_API_END_POINT,themeColor, dataEncryptKey, BASE_URL } from "../../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import {ChromePicker} from 'react-color';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import TooltipItem from "../../../components/common/TooltipItem";
import TooltipActionItem from "../../../components/common/TooltipActionItemAdmin";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import SimpleCrypto from "simple-crypto-js";
import ViewFileModal from "../../../components/common/ViewFileModal";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import pageStructure from "../../../constants/pageStructure";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
	[{ 'align': [] }],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    ["link"],
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "align",  
  "list",
  "bullet",
  "indent", 
  "link"
];


export default class Edit extends Component {
	
	constructor(props) {
		super(props);
		
		this.generateField = this.generateField.bind(this);
		this.changeTextarea = this.changeTextarea.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleModalViewDoc = this.toggleModalViewDoc.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.state = {
		  template_data: null,
		  blocking:true,
		  focusing_field:"subject",
		  modalOpenViewDoc: false,
		  refresh_file: true,
		};
	}
	
	
	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var data_upload = new FormData();
		data_upload.append('facility_id',facility_id);
		data_upload.append('template_id',this.props.match.params.id);
		axios.post(REST_API_END_POINT+'website/get-page-template-by-alias',data_upload).then((res)=>{ 
			if(res.data.status==1){
				this.setState({ template_data: res.data.data, blocking:false });				
			}else{
				swal("Failed to load the page template!", "Please refresh the page.", "error");
				this.setState({ blocking:false });					
			}			
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	toggleModalViewDoc = (e,doc_url=null) => {
		this.setState({
			modalOpenViewDoc: !this.state.modalOpenViewDoc,
			doc_url
		});
	};
	
	generateField(field,index,f_index){
		const refresh_file = this.state.refresh_file;
		if(field.type==="text" || field.type==="email"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
			  <AvField
				name={field.key}
				type={field.type}
				autoComplete="off"
				value={field.value}
				onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
				onChange={(e)=>{ 
					var template_data=this.state.template_data;
					if(typeof template_data.content[index].fields!=='undefined'){
						template_data.content[index].fields[f_index].value = e.target.value;
					}else{
						template_data.content[index].value = e.target.value;
					}
					this.setState({ template_data });
				}}
			  />
			</AvGroup>)
		}else if(field.type==="select"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
			  <AvField
				name={field.key}
				type={field.type}
				autoComplete="off"
				value={field.value}
				onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
				onChange={(e)=>{ 
					var template_data=this.state.template_data;
					if(typeof template_data.content[index].fields!=='undefined'){
						template_data.content[index].fields[f_index].value = e.target.value;
					}else{
						template_data.content[index].value = e.target.value;
					}
					this.setState({ template_data });
				}}
			  >
			  {field.options.map((r,i)=>{
				  return <option key={r.key} value={r.value}>{r.label}</option>
			  })}
			  </AvField>
			</AvGroup>)
		}else if(field.type==="file"){
			return(<AvGroup className="error-t-negative" key={field.key}>
				<Label>{field.label}</Label>
				{refresh_file ? <InputGroup className="mb-3">
					<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
					<CustomInput
						type={field.type}
						id={field.key+index}
						name={field.key+index}
						accept="image/png,image/jpeg,image/jpg,image/gif"
						onChange={(e)=>{							
							if(e.target.files[0]){
								e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
							}else{
								e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
							}
						}}
					/>
				</InputGroup>:""}
				{field.value!='' ? <Row>
						<Colxx lg="8" className="mb-2">	
							<table role="presentation" className="table table-striped">
								<tbody className="files">									
									<tr className="template-download" >
										<td>												
											<Button color="link" className="open-document p-0 cursor-pointer" onClick={(e)=>{ this.toggleModalViewDoc(e,BASE_URL+'pages/'+field.value) }}>{field.value}</Button>
										</td>
										<td>
											<Button type="button" color="danger" size="sm" onClick={()=>{ 
												var template_data=this.state.template_data;
												if(typeof template_data.content[index].fields!=='undefined'){
													template_data.content[index].fields[f_index].value = '';
												}else{
													template_data.content[index].value = '';
												}
												this.setState({ template_data });
											}} >
												<i className="fa fa-trash"></i>{" "}<span className="ml-1">Delete</span>
											</Button>
										</td>
									</tr>
								</tbody>
							</table>
						</Colxx>
					</Row>:""}
			</AvGroup>)
		}else if(field.type==="textarea"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
			  <AvField
				name={field.key}
				type={field.type}
				autoComplete="off"
				rows={field.key=="email_content_text" ? 10:2}
				value={field.value.split("<br/>").join("\n")}
				onFocus={()=>{ this.setState({ focusing_field:field.key }); }}
				onChange={(e)=>{ 
					var template_data=this.state.template_data;
					if(typeof template_data.content[index].fields!=='undefined'){
						template_data.content[index].fields[f_index].value = e.target.value.split("\n").join("<br/>");
					}else{
						template_data.content[index].value = e.target.value.split("\n").join("<br/>");
					}
					this.setState({ template_data });
				}}
			  />
			</AvGroup>)
		}else if(field.type==="quill"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
				<ReactQuill
					name={field.key}
					theme="snow"
					value={field.value}
					onChange={(text_content)=>{ 
						var template_data=this.state.template_data;
						if(typeof template_data.content[index].fields!=='undefined'){
							template_data.content[index].fields[f_index].value = text_content;
						}else{
							template_data.content[index].value = text_content;
						}
						this.setState({ template_data });
					}}					
					placeholder=""
					modules={quillModules}
					formats={quillFormats}
				/>
			</AvGroup>)
		}else if(field.type==="color"){
			return(<AvGroup className="error-t-negative" key={field.key}>
			  <Label>{field.label}</Label>
				<ChromePicker
					color={field.value}
					onChangeComplete={(color)=>{ 
						var template_data=this.state.template_data;
						if(typeof template_data.content[index].fields!=='undefined'){
							template_data.content[index].fields[f_index].value = color.hex;
						}else{
							template_data.content[index].value = color.hex;
						}
						this.setState({ template_data });
					}}
					className="no-box-shadow"
				/>
			</AvGroup>)
		}
	}
	
	changeTextarea(new_textarea_content){	
		var template_data=this.state.template_data;
		if(this.state.focusing_field!='subject'){
			var index = template_data.content.findIndex(r=>{ return r.key==this.state.focusing_field });
			template_data.content[index].value = new_textarea_content;
		}else{
			template_data.subject = new_textarea_content;
		}
		this.setState({ template_data });
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("edit-page-template").disabled = true;
			var template_data = JSON.parse(JSON.stringify(this.state.template_data));
			delete template_data.real_template;
			delete template_data.contact_map;
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var data_upload = new FormData(document.getElementById('edit_page_template_form'));
			data_upload.append('facility_id',facility_id);
			data_upload.append('template_id',this.props.match.params.id);
			data_upload.append('content',JSON.stringify(template_data.content));
			delete template_data.content;
			data_upload.append('template_data',JSON.stringify(template_data));
			
			
			if(typeof values.contact_map!='undefined'){				
				if(values.contact_map.includes('iframe')){
					var test = values.contact_map;
					var src = test.split('src=')[1].split(/[ >]/)[0]
					
					data_upload.set('contact_map',src.slice(1, -1));
				}else{
					data_upload.set('contact_map',values.contact_map);
				}
			}
			
			this.setState({ blocking:true, refresh_file:false });
			axios.post(REST_API_END_POINT+'website/update-page-template',data_upload).then((data)=>{ 
				
				swal("Success!", "Page has been updated successfully!", "success").then(()=>{
					window.location="/app/website/pages";
				});				
				
				this.setState({ blocking:false, refresh_file:true });
				document.getElementById("edit-page-template").disabled = false;
			}).catch(e => console.log("Addition failed , Error ", e));
			
		  
		}
	}	
	
	addBlock(row){
		let template_data = this.state.template_data;
		template_data.content.push(JSON.parse(JSON.stringify(row)));
		this.setState({ template_data });
	}
	
	handleRemove(e,index){
		var template_data=this.state.template_data;
		template_data.content = template_data.content.filter((r,i)=>{ return i!=index });
		this.setState({ template_data });
	}
		
	render() {
		var template_data = this.state.template_data;
				
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row className="with-email-view">
						<Colxx xxs="12">
							<Breadcrumb heading_text={template_data!=null ? template_data.name:""} match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<style dangerouslySetInnerHTML={{ __html: `body{background: #f8f8f8 !important;}` }} />
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row>
										<Colxx xl="12">
											<AvForm
												id="edit_page_template_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											  >
												<Row>
													<Colxx md="12">
														<AvGroup className="error-t-negative">
														  <Label>Title</Label>
														  <AvField
															name="name"
															type="text"
															autoComplete="off"
															value={template_data!=null ? template_data.name:""}
															onFocus={()=>{ this.setState({ focusing_field:"name" }); }}
															onChange={(e)=>{ 
																var template_data=this.state.template_data;
																template_data.name = e.target.value;
																this.setState({ template_data });
															}}
															validate={{
															  required: {
																value: true,
																errorMessage: "Please enter the name"
															  },
															  minLength: {
																value: 2,
																errorMessage:
																  "Title must be between 2 and 150 characters"
															  },
															  maxLength: {
																value: 150,
																errorMessage:
																  "Title must be between 2 and 150 characters"
															  }
															}}
														  />
														</AvGroup>
														
														<AvGroup className="error-t-negative d-none">
														  <Label>Sub Title</Label>
														  <AvField
															name="sub_title"
															type="text"
															autoComplete="off"
															value={template_data!=null ? template_data.sub_title:""}
															onFocus={()=>{ this.setState({ focusing_field:"sub_title" }); }}
															onChange={(e)=>{ 
																var template_data=this.state.template_data;
																template_data.sub_title = e.target.value;
																this.setState({ template_data });
															}}
															validate={{
															  minLength: {
																value: 2,
																errorMessage:
																  "Sub title must be between 2 and 250 characters"
															  },
															  maxLength: {
																value: 250,
																errorMessage:
																  "Sub title must be between 2 and 250 characters"
															  }
															}}
														  />
														</AvGroup>
														
														{this.props.match.params.id !== 'contact-us' && this.props.match.params.id !== 'about' ? <Row>
															<Colxx lg="12">
																<AvGroup className="error-t-negative" >
																	<Label>Header Image</Label>
																	<InputGroup className="mb-3">
																		<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
																		<CustomInput
																			type="file"
																			id="thumbnail_image"
																			name="thumbnail_image"
																			accept="image/png,image/jpeg,image/jpg,image/gif"
																			onChange={(e)=>{							
																				if(e.target.files[0]){
																					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
																				}else{
																					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
																				}
																			}}
																		/>
																	</InputGroup>
																</AvGroup>
															</Colxx>
														</Row>:""}
														
														{this.props.match.params.id == 'contact-us' ? <AvGroup className="error-t-negative">
														  <Label>Embed Map</Label>
														  <AvField
															name="contact_map"
															type="text"
															autoComplete="off"
															value={template_data!=null ? template_data.contact_map:""}
															onFocus={()=>{ this.setState({ focusing_field:"contact_map" }); }}
															onChange={(e)=>{ 
																var template_data=this.state.template_data;
																template_data.contact_map = e.target.value;
																this.setState({ template_data });
															}}
														  />
														</AvGroup>:""}
													
														
													</Colxx>													
												</Row>

												{template_data!=null ? template_data.content.map((row,index)=>{
													if(typeof row.fields!='undefined'){
														let currentField = pageStructure.find(p=>{ return p.name==row.name });
														if(currentField){
															for(let f of currentField.fields){
																if(row.fields.find(p=>{ return p.key==f.key })==null){
																	row.fields.push(f);
																}
															}
														}
														return <Row>
															<Colxx lg="12">
																<Card className="mb-3">
																	<CardBody>
																		<CardTitle className="font-weight-bold mb-2">{row.name}</CardTitle>
																		<div className="remove-section float-right" onClick={e=>this.handleRemove(e,index)} ><i className="simple-icon-close"></i></div>
																		<Row>
																			{row.fields.map((field,f_index)=>{
																				return <Colxx md={field.type==="color" ? "3":"6"} >
																					{this.generateField(field,index,f_index)}
																				</Colxx>
																			})}
																		</Row>
																	</CardBody>								
																</Card>
															</Colxx>
														</Row>
													}else{
														return <Row>
															<Colxx lg="12">
																{this.generateField(row,index,1)}
															</Colxx>
														</Row>
													}
												}):""}
												
												{this.props.match.params.id !== 'contact-us' && this.props.match.params.id !== 'about' ? <Row className="mt-2">
													<Colxx lg="12">
														<ItemDropdown
															name="Add Block"
															item={<DropdownMenu >								
																{pageStructure.map((row,i)=>{
																	return <DropdownItem key={i} className="cursor-pointer" onClick={(e)=>{ this.addBlock(row) }}  >
																		<span className="d-inline-block">{row.name}</span>
																	</DropdownItem>
																})}
															</DropdownMenu>} 
														/>
													</Colxx>
												</Row>:""}
					
												<hr/>
												
												<Button color="primary" id="edit-page-template" >
													<IntlMessages id="pages.submit" />
												</Button>{" "}
												<NavLink to={"/app/website/pages"} className="btn btn-outline-secondary not-active" >
													<IntlMessages id="pages.cancel" />
												</NavLink>{" "}
											</AvForm>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
					
					
				
					{this.state.doc_url!=null ? <ViewFileModal 
						modalOpen={this.state.modalOpenViewDoc}
						toggleModal={this.toggleModalViewDoc}
						doc_url={this.state.doc_url}
					/>:""}
				</BlockUi>
			</Fragment>
		);
	}
}
