import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, CustomInput, Badge } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import swal from 'sweetalert';
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddPricingModal from "../../../containers/inventory/pricing-group/AddPricingModal";
import EditPricingModal from "../../../containers/inventory/pricing-group/EditPricingModal";
import ReactTable from "react-table";
import { Loader } from 'react-loaders';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PricingGroup extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.state = {		  
			modalOpenAdd: false,
			modalOpenEdit: false,
			selectedIds:[],
			pricingGroup:[],
			selectAll: 0,
			pricing_id:0,
			search: '',
			user_privileges:[],
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.pricingGroup.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        if(status===1){
            msg="Activate the pricing groups ?";
            success_msg="The pricing groups has been activated.";
            failure_msg="Failed to activate pricing groups";
        }else if(status===2){
            msg="Delete the pricing groups ?";
            success_msg="The pricing groups has been deleted.";
            failure_msg="Failed to delete pricing groups";
        }else{
            msg="Deactivate the pricing groups ?";
            success_msg="The pricing groups has been deactivated.";
            failure_msg="Failed to deactivate pricing groups";
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("active-pricing-group").disabled=true;
				document.getElementById("inactive-pricing-group").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('pricing_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/delete-pricing-group',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("active-pricing-group").disabled=false;
						document.getElementById("inactive-pricing-group").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("active-pricing-group").disabled=false;
						document.getElementById("inactive-pricing-group").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,pricing_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(pricing_id);
			
		}else{
		  var x=selectedIds.indexOf(pricing_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.pricingGroup.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,pricing_id=0) => {
		if(pricing_id!=0){
			this.setState({
				pricing_id: parseInt(pricing_id),
			});
		}
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};  
	   
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory/get-all-pricing-groups',formData).then((res)=>{
			var data = res.data;
		
			if(data.pricing){
				this.setState({
					pricingGroup: data.pricing,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					pricingGroup: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    componentDidMount(){  
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
	  
	render() {
		let data = this.state.pricingGroup
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.type.toLowerCase().includes(this.state.search) || row.rate.includes(this.state.search) || row.created_at.includes(this.state.search) || row.status.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Type",
				accessor: "type",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Rate(%)",
				accessor: "rate",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Created On",
				accessor: "created_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props =>  <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
			},		
			{
				Header: "",
				accessor: "id",
				sortable: false,
				Cell: props => this.state.user_privileges.indexOf(46)!==-1 ? <Button color="primary" size="sm" onClick={(e)=> this.toggleModalEdit(e,props.value) } >Edit</Button>:"" 
			}
		];
    return (
      <Fragment>
	  
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.pricing-groups" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				
				<Colxx xxs="12">
					<div className="float-sm-right">
						{" "}{this.state.user_privileges.indexOf(45)!==-1 ? <Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New							  
						</Button>:""}
						{this.state.user_privileges.indexOf(47)!==-1 ? <Fragment>
							   {" "}<Button
								color="primary"
								size="sm"
								id="active-pricing-group"
								className=""
								onClick={event =>this.deleteItem(event,this.state.selectedIds,1)} >														
								<IntlMessages id="pages.active" />
							</Button>{" "}<Button
								color="primary"
								size="sm"
								id="inactive-pricing-group"
								className=""
								onClick={event =>this.deleteItem(event,this.state.selectedIds,0)} >							
								<IntlMessages id="pages.inactive" />
							</Button>
						</Fragment>:""}
					</div>
					
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
			  </Colxx>
			</Row>
		</Card>
		
		{this.state.user_privileges.indexOf(45)!==-1 ?<AddPricingModal
			modalOpen={this.state.modalOpenAdd}
			toggleModal={this.toggleModalAdd}
			dataListRender={this.dataListRender}
		/>:""}
		
		{this.state.user_privileges.indexOf(46)!==-1 ?<EditPricingModal
			modalOpen={this.state.modalOpenEdit}
			toggleModal={this.toggleModalEdit}
			pricing_id={this.state.pricing_id}
			dataListRender={this.dataListRender}
		/>:""}
      </Fragment>
    );
  }
}
