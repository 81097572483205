import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Button, Row, Col } from "reactstrap";
import { Colxx } from "../common/CustomBootstrap";
import ShopTopAction from "./product/ShopTopAction";
import ShopSearch from "./product/ShopSearch";

const ShopTopbar = ({
  getFilterSortParams,
  productCount,
  sortedProductCount,
  shopSearchSubmit
}) => {
  return (
    <Fragment>
      <Row className='shop-topbar align-items-center'>
        <Col xs={3}>
          <ShopSearch shopSearchSubmit={shopSearchSubmit} />
        </Col>

        <Col xs={9}>
          <ShopTopAction
            getFilterSortParams={getFilterSortParams}
            productCount={productCount}
            sortedProductCount={sortedProductCount}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

ShopTopbar.propTypes = {
  getFilterSortParams: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number
};

export default ShopTopbar;
