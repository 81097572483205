import React from "react";
import { Button, Tooltip } from "reactstrap";

class TooltipActionItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  };
  
  getCursorPos= (input) => {
	input.focus();
    if ("selectionStart" in input && document.activeElement === input) {
        return {
            start: input.selectionStart,
            end: input.selectionEnd
        };
    }
    else if (input.createTextRange) {
        var sel = document.selection.createRange();
        if (sel.parentElement() === input) {
            var rng = input.createTextRange();
            rng.moveToBookmark(sel.getBookmark());
            for (var len = 0;
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                len++;
            }
            rng.setEndPoint("StartToStart", input.createTextRange());
            for (var pos = { start: 0, end: len };
                     rng.compareEndPoints("EndToStart", rng) > 0;
                     rng.moveEnd("character", -1)) {
                pos.start++;
                pos.end++;
            }
            return pos;
        }
    }
    return undefined;
}
  
  addTemplateContent = () =>{
		if(this.props.item.quillRef){
			var range = this.props.item.quillRef.getSelection();
			let position = range ? range.index : 0;		
			this.props.item.quillRef.insertText(position, '['+(this.props.item.text.toUpperCase().split(' ').join('_'))+']');
		}
		if(this.props.item.textarea){
			var current_pos=this.getCursorPos(this.props.item.textarea[0]);
			let position = current_pos ? current_pos.start : 0;
			let endPos = current_pos ? current_pos.end : 0;
			let value = this.props.item.textarea[0].value.substring(0, position)+'['+(this.props.item.text.toUpperCase().split(' ').join('_'))+']' + this.props.item.textarea[0].value.substring(endPos, this.props.item.textarea[0].value.length);
			this.props.stateupdatingcallback(value);
		}
  }  
  

  render() {
    return (
      <span >
        <Button
		  type="button"
          className="mr-1 mb-2 p-1 text-uppercase col-lg-12 rounded-0 small-text-tooltip"
          color="primary"
		  size="sm"
          id={"Tooltip-place-" + this.props.id}
		  onClick={this.addTemplateContent}
        >        
			<i className="simple-icon-plus" />{" "}{this.props.item.text}
        </Button>
        <Tooltip
          placement={this.props.item.placement}
          isOpen={this.state.tooltipOpen}
          target={"Tooltip-place-" + this.props.id}
          toggle={this.toggle}
		  className="white-tooltip"
        >
          {this.props.item.body}
        </Tooltip>
      </span>
    );
  }
}
export default TooltipActionItem;
