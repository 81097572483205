import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Customers from "../../../components/customer/Customers";
import AddGetStarted from "../../../containers/customer/AddGetStarted";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import TemplateApplicationMenu from "../../../containers/template/TemplateApplicationMenu";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddDepartmentModal from "../../../containers/department/AddDepartmentModal";
import AddNewRequestModal from "../../../containers/officialrequests/AddNewRequestModal";
import EditDistributionModal from "../../../containers/accounts/EditDistributionModal";
import ReactTable from "react-table";

import data from "../../../data/officialrequests";

export default class Officialrequests extends Component {
	
	constructor(props) {
		super(props);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		 modalOpenAdd: false,
		 modalOpenEdit: false,
		  modalOpenStaff: false,
		  data:data,
		  search: '',
		};
	}
	
	 toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
     
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
	
		
	  
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.official-requests" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button
							color="primary"
							size="sm"
							className=""						
						  >
							<IntlMessages id="All Requests" />
					  </Button>
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New Requests 							  
						</Button>																		
						   <AddNewRequestModal
							modalOpen={this.state.modalOpenAdd}
							toggleModal={this.toggleModalAdd}
						  />
					</div>
					 <EditDistributionModal
							modalOpen={this.state.modalOpenEdit}
							toggleModal={this.toggleModalEdit}
						  />
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						  </Colxx>
						  </FormGroup>	
   		  
					
<Row>
		<Colxx lg="3 mt-4">
       <Card className="p-2">
	            <div className="text-center"><Badge color="success" className="img-badge">New</Badge>
                     <img alt="Responsive image" src="/assets/img/c2.jpg" className="img-responsive" width="250" height="127"/>
			    </div>
				<div>
		                 <h6 className="mt-2 text-center">Assignment</h6>
		                 <h2 className="text-center"style={{color:"green"}}><NavLink to="/app/official-requests/assignment" className="mt-4">Assignment</NavLink></h2>
				</div>
				<div className="text-center">
		             <img className="image-center"  alt="Responsive image" src="/assets/img/avatar.jpg" className="img-responsive" width="56" height="56"/>
				</div>
				<div>
		                 <h6 className="text-center" style={{fontSize:"11px"}}>Updated last 9 Months ago</h6>
				</div>
			    <div className="text-center">
			               <i className="simple-icon-cloud-download md-18 para_size"></i>
				           <strong className="para-class pl-1">1</strong>
						   <i className="simple-icon-bubble pl-1 md-18 para_size"></i>
				           <strong className="para-class pl-1">0</strong>
				</div>			
	   </Card>
		</Colxx>
		
		<Colxx lg="3 mt-4">
		<Card className="p-2">
	            <div className="text-center"><Badge color="success" className="img-badge">New</Badge>
                     <img alt="Responsive image" src="/assets/img/c2.jpg" className="img-responsive" width="250" height="127"/>
			    </div>
				<div>
		                 <h6 className="mt-2 text-center">Assignment</h6>
		                 <h2 className="text-center"style={{color:"green"}}>Assignment</h2>
				</div>
				<div className="text-center">
		             <img className="image-center img-crcle "  alt="Responsive image" src="/assets/img/avatar.jpg"  width="56" height="56"/>
		             <img className="image-center img-crcle img-lft"  alt="Responsive image" src="/assets/img/avatar.jpg" width="56" height="56"/>
				</div>
				<div>
		                 <h6 className="text-center" style={{fontSize:"11px"}}>Updated last 9 Months ago</h6>
				</div>
			    <div className="text-center">
			               <i className="simple-icon-cloud-download md-18 para_size"></i>
				           <strong className="para-class pl-1">1</strong>
						   <i className="simple-icon-bubble pl-1 md-18 para_size"></i>
				           <strong className="para-class pl-1">0</strong>
				</div>			
	   </Card>
		</Colxx>
		
		<Colxx lg="3 mt-4">
		<Card className="p-2">
	            <div className="text-center"><Badge color="danger" className="img-badge">Closed</Badge>
                     <img alt="Responsive image" src="/assets/img/c2.jpg" className="img-responsive" width="250" height="127"/>
			    </div>
				<div>
		                 <h6 className="mt-2 text-center">RFP</h6>
		                 <h2 className="text-center"style={{color:"green"}}><NavLink to="/app/official-requests/rfprequest" className="mt-4">RFP Request</NavLink></h2>
				</div>
				<div className="text-center">
		             <img className="image-center"  alt="Responsive image" src="/assets/img/avatar.jpg" className="img-responsive" width="56" height="56"/>
				</div>
				<div>
		                 <h6 className="text-center" style={{fontSize:"11px"}}>Updated last 9 Months ago</h6>
				</div>
			    <div className="text-center">
			               <i className="simple-icon-cloud-download md-18 para_size"></i>
				           <strong className="para-class pl-1">1</strong>
						   <i className="simple-icon-bubble pl-1 md-18 para_size"></i>
				           <strong className="para-class pl-1">0</strong>
				</div>			
	   </Card>
		</Colxx>
		
		<Colxx lg="3 mt-4">
		<Card className="p-2">
	            <div className="text-center"><Badge color="secondary" className="img-badge">Awarded</Badge>
                     <img alt="Responsive image" src="/assets/img/c2.jpg" className="img-responsive" width="250" height="127"/>
			    </div>
				<div>
		                 <h6 className="mt-2 text-center">RFI</h6>
		                 <h2 className="text-center"style={{color:"green"}}>RFI Request</h2>
				</div>
				<div className="text-center">
		             <img className="image-center"  alt="Responsive image" src="/assets/img/avatar.jpg" className="img-responsive" width="56" height="56"/>
				</div>
				<div>
		                 <h6 className="text-center" style={{fontSize:"11px"}}>Updated last 9 Months ago</h6>
				</div>
			    <div className="text-center">
			               <i className="simple-icon-cloud-download md-18 para_size"></i>
				           <strong className="para-class pl-1">1</strong>
						   <i className="simple-icon-bubble pl-1 md-18 para_size"></i>
				           <strong className="para-class pl-1">0</strong>
				</div>			
	   </Card>
		</Colxx>
		
		</Row>
		
		

					
					</div>
					
			  </Colxx>
			</Row>
		</Card>
		
		
      </Fragment>
    );
  }
}
