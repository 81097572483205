import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Table, Button, Label } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { Separator, Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import moment from 'moment';
import SimpleCrypto from "simple-crypto-js";
import { NavLink } from "react-router-dom";

import EditCommentModal from "../../../containers/complaintandcomments/EditCommentModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class Complaintview extends Component {
	
	constructor(props) {
		super(props);
		  this.handleSubmit = this.handleSubmit.bind(this);
		  this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			readOnly: true,
			modalOpenEdit: false,
			complaint:null,
			comments:null,
			comment_id:0,
			message_body:'',
			load_comment_box:true
		};	
	}
	
	toggleModalEdit = (e,comment_id=0) => {
		this.setState({
			comment_id: parseInt(comment_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
    componentDidMount() {		
		var formData = new FormData();
		let complaint;
		let comments;
		formData.append('complaint_id',this.props.match.params.complaint_id);

		axios.post(REST_API_END_POINT+'complaint/get-complaint-by-id',formData).then((res)=>{
			var data = res.data;
			complaint=data.complaint;
			comments=data.comments;
				
			this.setState({ 
				complaint,
				comments			
			});			
		})
	}
 

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			let errorMessage='';
		
			if(errorMessage.length!=0){
				swal("Warning",errorMessage,"warning");
				return false;
			}
			//document.getElementById("add-complaint").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("complaintForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('complaint_id',this.props.match.params.complaint_id);
			
			this.setState({ load_comment_box:false });
			axios.post(REST_API_END_POINT+'complaint/add-comment',formData).then((res)=>{
				var data = res.data;
				var comments = data.comments;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				//document.getElementById("add-complaint").disabled=false;
				this.setState({
					comments,
					message_body:'',
					load_comment_box:true
				});	

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				//document.getElementById("add-complaint").disabled=false;
			});

		}
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('complaint_id',this.props.match.params.complaint_id);
		
		axios.post(REST_API_END_POINT+'complaint/get-all-comments',formData).then((res)=>{
			var data = res.data;		
			if(data.comments){
				this.setState({
					comments: data.comments
				});
			}else{
				this.setState({
					comments: []
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	deleteItem = (e,selectedId)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="comment";
		
		msg="Delete the "+msg_word+" ?";
		success_msg="The "+msg_word+" has been deleted.";
		failure_msg="Failed to delete "+msg_word;
        
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				
				if(selectedId!=0){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('comment_id',selectedId);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'complaint/delete-comment',formData).then((res)=>{
						var data = res.data;
					
						swal(data.msg);
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	

  
	render() {		
		
		if(this.state.complaint!=null){
			var complaint=this.state.complaint;
		}else{
			var complaint;
		}
		
		if(this.state.comments!=null){
			var comments=this.state.comments;
		}else{
			var comments;
		}
		
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.complaint-view" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
             
        <Row className="invoice-react">
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-5 invoice-contents">
              <CardBody className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column">
					
                  <div className="d-flex flex-row justify-content-between mb-5">
                    <div className="d-flex flex-column w-70 mr-2 p-4 ">
						<p className="mb-0"><strong>Complaint #:</strong> {complaint ? ((complaint.complaint_number!=null)?complaint.complaint_number:''):'' }</p>
						<br/>
						<p className="mb-0"> <strong>Customer:</strong> {complaint ?((complaint.customer_name!=null)?complaint.customer_name:''):'' }</p>
						<br/>
						{complaint ?((complaint.product_name!=null)? <p ><strong>Product:</strong> {complaint.product_name} </p>:''):'' } 
						{complaint ?((complaint.batch_number!=null)? <p ><strong>Batch number:</strong>  {complaint.batch_number} </p>:''):'' } 
					 
						<p className="mb-0"><strong>Complaint:</strong> <br/> { complaint?((complaint.suggestion!=null)?complaint.suggestion:''):'' } </p>

						{complaint ? (complaint.status==2 ? <div>
							<br/>
							<p className="mb-0"><strong>Closed By:</strong> {complaint.closed_by}</p>
							<br/>
							<p className="mb-0"><strong>Closed On:</strong> {moment(complaint.close_timestamp*1000).format("MM/DD/YYYY HH:mm")}</p>
							</div>:""):""}	
                    </div>
                  </div>				 
                </div>
				
		    	 <div className="d-flex flex-column">
				
                  <div className="border-bottom pt-3 mb-5" />
				    {complaint ? (complaint.status!=2 ? <div>
						<div className="d-flex align-self-left font-weight-bold h6">
							Add Comment
						</div>			
						<div className="border-bottom" />
						<Row>
							<Colxx xxs="12">
								<div id="api_message"></div>
							</Colxx>
						</Row>
						{this.state.load_comment_box ? <AvForm
							id="complaintForm"
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
							<Row className="mt-4">
								<Colxx lg="12" className="mb-2">	
									<AvGroup>
										<Label>Comment Body</Label>
										<AvField 
											name="body" 
											type="textarea" 
											autoComplete="off"
											rows="6"
											value={this.state.message_body}
											onChange={(e)=>{ this.setState({ message_body:e.target.value }); }}
											validate={{
												  required: {
													value: true,
													errorMessage: "Please enter the  message"
												  },
												  minLength: {
													value: 2,
													errorMessage:
													  "The message must have at least 2 characters"
												  }
												}}
										/>
									</AvGroup>
								</Colxx>
							</Row>							
							<Button color="primary" id="add-complaint" > <IntlMessages id="customer.save" /> </Button>				
						</AvForm>:""}
					</div>:""):""}	
                 
                </div>		
              </CardBody>
            </Card>
			{comments ? (comments.length ? <Card className=" mb-3">
				<CardBody>
					<h5 className="card-title">Comments</h5>
					<Separator classNameName="mb-5" />
					<div className="mt-3">
						{comments.map((row,index)=>{
							return (<div key={index}>
							<div className="mt-2">
								<img src={row.profile_pic_url}  width="60" className="mb-3" alt={row.username} />
								<span>{row.username}</span>
								<div className="float-right">
									{complaint.status!=2 ? <Button color="primary" className="btn-round" onClick={(e)=> this.toggleModalEdit(e,row.ID) } > <i className="simple-icon-note" /> </Button>:""}{" "}
									{complaint.status!=2 ? <Button color="danger" className="btn-round" onClick={(e)=> this.deleteItem(e,row.ID) } > <i className="simple-icon-trash" /> </Button>:""}
								</div>
							</div>
							<p>{row.body}</p>
							<p className="card-text"><small className="text-muted">{row.created_at}</small></p>
							<Separator classNameName="mb-5" />
						</div>)
						})}
					</div>
				</CardBody>
				
			</Card>:""):""}
	
          </Colxx>
        </Row>
		
		<EditCommentModal
			modalOpen={this.state.modalOpenEdit}
			toggleModal={this.toggleModalEdit}
			dataListRender={this.dataListRender}
			comment_id={this.state.comment_id}
		/>
      </Fragment>
    );
  }
}
export default Complaintview;
