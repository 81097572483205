import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, Nav,CustomInput, FormGroup, Input, Badge } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../components/DatatablePagination";
import AddComplaintandcommentsModal from "../../containers/complaintandcomments/AddComplaintandcommentsModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Complaintandcomments extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		
		this.state = {		 
			modalOpenAdd: false,
			complaints:[],
			search: '',
		};
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.complaints.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    } 
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	  
	componentDidMount(){
	   this.dataListRender();
	}
  
  
    deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        		var strain_word="complaint";
		
		if(selectedIds.length>1){
			strain_word="complaints";
		}
		
        if(status===1){
            msg="Activate the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been activated.";
            failure_msg="Failed to activate "+strain_word;
        }else if(status===2){
            msg="Delete the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been deleted.";
            failure_msg="Failed to delete "+strain_word;
        }else{
            msg="Deactivate the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+strain_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-complaint").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('complaint_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'complaint/delete-complaint-categories',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-complaint").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-complaint").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	} 
      activeItem=(e,complaint_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(complaint_id);
			
		}else{
		  var x=selectedIds.indexOf(complaint_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.complaints.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'complaint/get-all-complaints',formData).then((res)=>{
			var data = res.data;
		
			if(data.complaints){
				this.setState({
				complaints: data.complaints,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					complaints: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	
	
  render() {
	  let data = this.state.complaints
		if (this.state.search) {
			data = data.filter(row => {
				return row.complaint_number.toLowerCase().includes(this.state.search) || row.customer_name.toLowerCase().includes(this.state.search) || row.suggested_by.toLowerCase().includes(this.state.search) || row.created_at.toLowerCase().includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
	  {
		Header: "#",
		accessor: "complaint_number",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Customer Name",
		accessor: "customer_name",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },

	  {
		Header: "Added by",
		accessor: "suggested_by",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Added On",
		accessor: "created_at",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	
	  {
		Header: "Status",
		accessor: "status",
		Cell: props =>  <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Open":"Close"}</Badge>

	  },	  	  
	 {
		Header: "",
		accessor: "id",
		sortable: false,
		Cell: props => <Fragment> 
					<NavLink to={"/app/complaints/complaint-view/"+props.value} className="mb-2 btn btn-xs btn-info ">View</NavLink>
					{props.original.status==1 ? <NavLink to={"/app/complaints/close-complaint/"+props.value} className="mb-2 btn btn-xs btn-primary ml-2">Close</NavLink>:""}
				</Fragment>
	  }
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.complaints-and-comments" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
			<Card className="p-4">
			<Row>				
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>		
							  
				<Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New 						  
						</Button>					
					</div>
	
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}							
						/>
					</div>
					
				</Colxx>
			</Row>
			<AddComplaintandcommentsModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
			/>
		</Card>
      </Fragment>
    );
  }
}
