import React, { Component,Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import { getCurrencySymbol, getCustomerWord } from "../../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

class AddNewCustomerPointsModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		let currencySymbol = getCurrencySymbol();
		this.state = {		
			currencySymbol,
		};
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-customer-point").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("customerpointForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'point-of-sale/add-price-point',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-customer-point").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-customer-point").disabled=false;
			});

		}
	}

  render() {	
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add {customerWord} Point
		  </ModalHeader>
		  <AvForm
		        id="customerpointForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>{customerWord} Points<span className="text-danger">*</span></Label>
						  <AvField
							id="price_points"
							name="price_points"
							type="number"
							autoComplete="off"
                            min='0'							
							validate={{
									required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
								
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Discount ({this.state.currencySymbol})<span className="text-danger">*</span></Label>
						  <AvField
							id="amount"
							name="amount"
							type="number"
							autoComplete="off"
							min='0'
							validate={{
									required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
								
						  />
						</AvGroup>
					</Colxx>
				</Row>								

		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-customer-point" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddNewCustomerPointsModal;
