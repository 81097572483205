import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddToInventoryModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state={
			product_id:null
		}
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add_to_inventory-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("pauseRestartForm"));
			formData.append('user_id',user_id);
			formData.append('facility_id',facility_id);
			formData.append('equipment_id',this.props.equipment_id.value);
			formData.append('batch_uid',this.props.record_batch_uid);
			if(this.props.source!=5){
				formData.append('product_id',values.add_to_inventory_product);
			}
			formData.append('quantity',this.props.crude_oil);
			formData.append('source',this.props.source);
			
			var req_url='processor/add-to-inventory';
			if(this.props.source==5){
				var req_url='processor/add-to-inventory-distilate';
			}
			
			axios.post(REST_API_END_POINT+req_url,formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					document.getElementById("add_to_inventory-btn").disabled=false;
					this.props.toggleModal();
					var package_details = data.package;
					this.props.AddToInventory(package_details);
				}else{
					swal("Failed to add the package to inventory");
					document.getElementById("add_to_inventory-btn").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add_to_inventory-btn").disabled=false;
			});
		}
	}  
 

  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
	     	Add to inventory
		  </ModalHeader>
		  <AvForm
				id="pauseRestartForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Batch UID</Label>
							<AvField
								id="add_to_inventory_batch_uid"
								name="add_to_inventory_batch_uid"
								autoComplete="off"
								readOnly={true}
								value={this.props.record_batch_uid}
							/>
						</AvGroup>
					</Colxx>
					
					{this.props.source!=5 ? <Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Product</Label>
							<AvField
								id="add_to_inventory_product"
								name="add_to_inventory_product"
								type="select"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
							>
							<option value="">-- Select Product--</option>
							{this.props.products.map((row,index)=>{
								return(<option value={row.id} key={index}>{row.name}</option>)
							})}
							</AvField>
						</AvGroup>
					</Colxx>:""}
					
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Quantity</Label>
							<AvField
								id="add_to_inventory_qty"
								name="add_to_inventory_qty"
								autoComplete="off"
								readOnly={true}
								value={this.props.crude_oil+" (Grams)"}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
			</ModalBody>
			<ModalFooter className="justify-content-start">
				<Button color="primary" id="add_to_inventory-btn" >
					<IntlMessages id="customer.submit" />
				</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
					<IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
