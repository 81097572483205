import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  Input,
  Button,
  Modal,
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter, Card, CardBody,
  Label,FormGroup,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { zeroPad } from "../../../helpers/Utils";
import number_format from "number_format-php";
import { uuid } from 'uuidv4';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class ApproveOrderModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {
			order_data: null,
			order_products: null,
			rooms: [],
			groups: [],
		};	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'growhouse/data-for-approve-plant-order',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data,
							order_products:data.order_data.order_items.map(row=>{ row.room_table=[{id:uuid(),room_id:null,table_id:[],tables:[]}]; row.fill_plants_total="0"; return row; }),
							rooms:data.rooms,
							groups:data.groups,
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			var proceed=true;			
			var order_products = this.state.order_products;
			for(let order_product of order_products){
				if(parseInt(order_product.fill_plants_total)<parseInt(order_product.product_qty)){
					proceed=false;
					break;
				}
			}
			if(!proceed){
				swal('Warning!',"Please choose enough space for the plants","warning");
				return;
			}
			
			document.getElementById("approve-order-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("approveOrderForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);
			
			axios.post(REST_API_END_POINT+'growhouse/approve-plant-order',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("approve-order-btn").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("approve-order-btn").disabled=false;
			});
		}
		
	}
	
	render() {	
		const { order_data, order_products, rooms, groups } = this.state;
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Approve Order
			</ModalHeader>
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
				id="approveOrderForm"
			>
			
				<ModalBody>
					<Row>
						<Colxx lg="12" className="mb-4">
							{order_data!=null ? <h2 className="font-weight-bold">#{zeroPad(order_data.order_number,6)}</h2>:""}
							Hold down the Ctrl (windows) / Command (Mac) button to select multiple options.
						</Colxx>															
					</Row>
					
					<Table responsive>
						<thead>
							<tr>
								<th>Strain</th>
								<th>Birth Date</th>
								<th>Phase</th>
								<th>Quantity</th>
								<th>Room & Table</th>												
								<th>Group</th>												
							</tr>
						</thead>					
						
						<tbody>							
							{order_data!=null ? order_products.map((row,index)=>{
								return(<tr key={row.id}>
									<td>{row.strain_name}</td>
									<td>{moment(row.birth_date*1000).format("MM/DD/YYYY")}</td>
									<td>{row.phase_name}</td>
									<td>{number_format(parseFloat(row.product_qty),2,'.','')}</td>
									<td>
										{row.room_table.map((r,i)=>{
											return(<Card className="mb-3" key={i}><CardBody className="p-3">
												<AvGroup>
													<AvField
														id={"room"+index+'_'+i}
														name={"room["+index+"]["+i+"]"}
														type="select"
														value={r.room_id}
														validate={{
															required: {
																value: true,
																errorMessage: "This field is required."
															}													
														}}
														onChange={(e)=>{
															const value = e.target.value;
															if(value!=''){
																var tables=[];
																var facility_id = localStorage.getItem('facility_id');		
																facility_id =  dataCrypto.decrypt(facility_id);			
																var formData = new FormData();
																formData.append('room_id',value);
																
																axios.post(REST_API_END_POINT+'growhouse/get-active-tables-by-room',formData).then((res)=>{
																	var data = res.data;

																	if(data.tables){
																		tables=data.tables.filter(t=>{ return parseInt(t.table_capacity)>parseInt(t.plant_count) });																		
																	}
																	var order_products = this.state.order_products;											
																	order_products = order_products.map(ro=>{
																		if(ro.id==row.id){
																			ro.room_table = ro.room_table.map((rt,j)=>{
																				if(r.id==rt.id){
																					rt.tables=tables;
																					rt.table_id=[];
																				}
																				return rt;
																			});
																		}
																		return ro;
																	});
																	this.setState({ order_products });
																}).catch(e =>{
																	console.log("Addition failed , Error ", e);			
																});	
															}else{
																var order_products = this.state.order_products;											
																order_products = order_products.map(ro=>{
																	if(ro.id==row.id){
																		ro.room_table = ro.room_table.map((rt,j)=>{
																			if(r.id==rt.id){
																				rt.tables=[];
																				rt.table_id=[];
																			}
																			return rt;
																		});
																	}
																	return ro;
																});
																this.setState({ order_products });
															}
														}}
													>
														<option value="" >--Select Room--</option>
														{rooms.map(r=>{
															return(<option value={r.id} key={r.id}>{r.name}</option>)
														})}
													</AvField>
												</AvGroup>
												<AvGroup>
													<AvField
														id={"table_id"+index+'_'+i}
														name={"table_id["+index+"]["+i+"][]"}
														type="select"
														value={r.table_id}
														multiple
														validate={{
															required: {
																value: true,
																errorMessage: "This field is required."
															}													
														}}
														onChange={(e)=>{ 
															var order_products = this.state.order_products;
															var other_selected_tables=[];
															order_products = order_products.map(ro=>{
																ro.room_table.map((rt,j)=>{
																	rt.table_id.map((t)=>{
																		other_selected_tables.push(t);
																	});
																	return rt;
																});
																if(ro.id==row.id){
																	ro.room_table = ro.room_table.map((rt,j)=>{
																		if(r.id==rt.id){
																			let selected_values=[];
																			for (let k=0, n=e.target.options.length;k<n;k++) { // looping over the options
																				if(e.target.options[k].selected){
																					selected_values.push(e.target.options[k].value);
																				}
																			}
																			
																			let newly_selected = selected_values.find(t=>{ return rt.table_id.indexOf(t)==-1 });
																			
																			if(other_selected_tables.indexOf(newly_selected)!==-1){
																				for (let k=0, n=e.target.options.length;k<n;k++) { // looping over the options
																					if(e.target.options[k].selected){
																						if(rt.table_id.indexOf(e.target.options[k].value)==-1){
																							e.target.options[k].selected=false;
																						}
																					}
																				}
																				swal('Warning!',"This table is already chosen","warning");
																			}else{																			
																				if(parseInt(ro.fill_plants_total)<parseInt(ro.product_qty) || selected_values.length==1){
																					rt.table_id=selected_values;
																				}else{
																					for (let k=0, n=e.target.options.length;k<n;k++) { // looping over the options
																						if(e.target.options[k].selected){
																							if(rt.table_id.indexOf(e.target.options[k].value)==-1){
																								e.target.options[k].selected=false;
																							}
																						}
																					}
																					swal('Warning!',"You have already choosen enough plants positions","warning");
																				}
																				
																				let selected_tables = rt.tables.filter(t=>{ return rt.table_id.indexOf(t.id)!==-1 });
																				ro.fill_plants_total = selected_tables.map(t=>{ return parseInt(t.table_capacity)-parseInt(t.plant_count); }).reduce((total,remaining)=>{ return total+remaining; });
																			}
																		}
																		return rt;
																	});
																}
																return ro;
															});
															this.setState({ order_products });
														}}
													>
														<option value="" >--Select Table--</option>
														{r.tables.map(t=>{
															return(<option value={t.id} key={t.id}>{t.name+' ('+(parseInt(t.table_capacity)-parseInt(t.plant_count))+' Plants)'}</option>)
														})}
													</AvField>
												</AvGroup>
												{i!=0 ? <Button type="button" color="primary" size="xs" onClick={(e)=>{
													var order_products = this.state.order_products;											
													order_products = order_products.map(ro=>{
														if(ro.id==row.id){
															ro.room_table = ro.room_table.filter(rt=>{ return rt.id!==r.id });
														}
														return ro;
													});
													this.setState({ order_products });
												}}>Remove</Button>:""}
											</CardBody></Card>)
										})}
										<Label>Total Plant Positions Selected</Label>
										<AvField
											id={"fill_plants_total"+index}
											name={"fill_plants_total["+index+"]"}
											type="number"
											readOnly={true}
											value={row.fill_plants_total}
										/>
										<Button type="button" color="primary" size="xs" onClick={(e)=>{
											var order_products = this.state.order_products;											
											order_products = order_products.map(ro=>{
												if(ro.id==row.id){
													ro.room_table.push({id:uuid(),room_id:null,table_id:[],tables:[]});
												}
												return ro;
											});
											this.setState({ order_products });
										}}>Add More</Button>
									</td>
									<td>
										<AvGroup>
											<AvField
												id={"group_type"+index}
												name={"group_type["+index+"]"}
												type="select"
											>
												<option value="" >--Select Group--</option>
												{groups.map(r=>{
													return(<option value={r.id} key={r.id}>{r.name}</option>)
												})}
											</AvField>
										</AvGroup>
									</td>
								</tr>)
							}):""}
						</tbody>
					</Table>
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="approve-order-btn">
						Approve
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
		);
	}
};

export default ApproveOrderModal;
