const agents = [
{
    id: 1,
    title: "Get Started with people",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse feugiat fringilla erat vitae tincidunt. Nam vitae tortor eleifend, tempus ex in, tempus enim. Donec sodales nulla at venenatis aliquam. Integer urna libero, accumsan volutpat dictum eget, pellentesque ac ante. Nam urna libero, faucibus ut nisi vel, malesuada tincidunt purus.",
	date: "07/24/2019 10:09",
	status: 1
  },
  {
    id: 2,
    title: "Get Started with metrics",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse feugiat fringilla erat vitae tincidunt. Nam vitae tortor eleifend, tempus ex in, tempus enim. Donec sodales nulla at venenatis aliquam. Integer urna libero, accumsan volutpat dictum eget, pellentesque ac ante. Nam urna libero, faucibus ut nisi vel, malesuada tincidunt purus.",
	date: "07/24/2019 10:09",
	status: 1
  },{
    id: 3,
    title: "Get Started with templates",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse feugiat fringilla erat vitae tincidunt. Nam vitae tortor eleifend, tempus ex in, tempus enim. Donec sodales nulla at venenatis aliquam. Integer urna libero, accumsan volutpat dictum eget, pellentesque ac ante. Nam urna libero, faucibus ut nisi vel, malesuada tincidunt purus.",
	date: "07/24/2019 10:09",
	status: 1
  },{
    id: 4,
    title: "Get Started with communication",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse feugiat fringilla erat vitae tincidunt. Nam vitae tortor eleifend, tempus ex in, tempus enim. Donec sodales nulla at venenatis aliquam. Integer urna libero, accumsan volutpat dictum eget, pellentesque ac ante. Nam urna libero, faucibus ut nisi vel, malesuada tincidunt purus.",
	date: "07/24/2019 10:09",
	status: 1
  },{
    id: 5,
    title: "Get Started with settings",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse feugiat fringilla erat vitae tincidunt. Nam vitae tortor eleifend, tempus ex in, tempus enim. Donec sodales nulla at venenatis aliquam. Integer urna libero, accumsan volutpat dictum eget, pellentesque ac ante. Nam urna libero, faucibus ut nisi vel, malesuada tincidunt purus.",
	date: "07/24/2019 10:09",
	status: 1
  }
]

export default agents
