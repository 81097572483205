import React, { Component,Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  Badge,
  FormGroup,
  Input
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import DataTablePagination from "../../../components/DatatablePagination";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactTable from "react-table";
import moment from "moment";
import number_format from "number_format-php";
import { uuid } from 'uuidv4';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const sources = [
    { label: "Seed", value: "0", key: 0 },
    { label: "Clone", value: "1", key: 1},
    { label: "Tissue Culture", value: "2", key: 2 }
];
  
class CreateOrderModal extends Component{

	constructor(props) {
		super(props);
		this.addToCart = this.addToCart.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {	
			strains:[],
			phases:[],
			sources:[],
			selectSource:{ label: "Clone", value: "1", key: 1},
			selectStrain:null,
			selectPhase:null,
			selectVendor:null,
			cart:[],
			cart_totals:{
				total:0,
			},
			birth_date:null,
			product_price:'',
			product_qty:'',
			blocking:true
		};
	}
	
	componentDidMount(){  
		var phases=[];	
		var strains=[];	
		var harvested=[];
		var vendors=[];
		
	    var user_id = localStorage.getItem('user_id');
	    var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('room_checking',1);
		
		axios.post(REST_API_END_POINT+'growhouse/get-data-for-add-plant',formData).then((res)=>{
			var data = res.data;
			if(data.status==1){
				strains=data.strains;
				phases=data.phases;
				vendors=data.vendors;
				
				phases = phases.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				strains = strains.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
					
				vendors = vendors.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});	
				
				var selectPhase=null;
				if(phases.length){
					selectPhase=phases[0];
				}
					
				this.setState({
					phases:phases,
					strains:strains,
					vendors:vendors,
					selectStrain:null,
					selectPhase,
					selectVendor:null,
					blocking:false
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
	}
	
	addToCart(event, errors, values) {
		if (errors.length === 0) {
			const {  selectSource, selectStrain, selectPhase, product_price, product_qty, birth_date } = this.state;
			if(selectStrain==null){
				swal('Warning!',"Please select the strain",'warning');
				return;
			}else if(selectPhase==null){
				swal('Warning!',"Please select the phase",'warning');
				return;
			}else if(birth_date==null){
				swal('Warning!',"Please select the birth date",'warning');
				return;
			}
			
			
			if(selectSource!=null && selectStrain!=null && selectPhase!=null && birth_date!=null && product_price!=''&& product_qty!=''){
				var cart = this.state.cart;
				var line_item={};
				
				line_item.insert_id=uuid();
				line_item.strain_id=selectStrain;
				line_item.phase_id=selectPhase;
				line_item.source_id=selectSource;
				line_item.unit_price=product_price;
				line_item.qty=product_qty;
				line_item.birth_date=birth_date;
				line_item.total=parseFloat(product_price)*parseFloat(product_qty);
				
				cart.push(line_item);
				
				let selectPhaseTemp=null;
				if(this.state.phases.length){
					selectPhaseTemp=this.state.phases[0];
				}
				
				this.setState({
					cart,
					selectSource:{ label: "Clone", value: "1", key: 1},
					selectStrain:null,
					selectPhase:selectPhaseTemp,
					product_price:'',
					product_qty:'', 
					birth_date:null
				},()=>{ this.recalculateTotals(); });
			}
		}
	}
	
	removeCart(insert_id){
		var cart = this.state.cart;
		cart = cart.filter(row=>{ return row.insert_id!=insert_id });		
		this.recalculateTotals(cart);
	}
	
	async recalculateTotals(cart=null){
		const transfer_facility_id = this.state.transfer_facility_id;
		if(cart==null){
			cart=this.state.cart;
		}
		
		var cart_totals = {
			total:0,
		}
		
		for(let key in cart){
			let row = cart[key];
			cart_totals.total += parseFloat((row['unit_price']*row['qty']).toFixed(2));
			cart[key] = row;
		}
				
		this.setState({ cart, cart_totals });
	}	
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			if(this.state.selectVendor==null){
				swal('Warning!',"Please select the vendor",'warning');
				return;
			}
			
			if(this.state.cart.length==0){
				swal('Warning!',"Please add at least one plant to cart",'warning');
				return;
			}
			
			document.getElementById("place-grow-order-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("growInventoryForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
            
			var cart = this.state.cart;
			cart = cart.map(row=>{
				row.strain_id = row.strain_id.value;
				row.phase_id = row.phase_id.value;
				row.source_id = row.source_id.value;
				return row;
			});
			
			formData.append('cart',JSON.stringify(cart));
			
			axios.post(REST_API_END_POINT+'growhouse/create-plant-order',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("place-grow-order-btn").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
				
				let selectPhaseTemp=null;
				if(this.state.phases.length){
					selectPhaseTemp=this.state.phases[0];
				}
				
				this.setState({
					cart:[],
					cart_totals:{
						total:0,
					},
					selectSource:{ label: "Clone", value: "1", key: 1},
					selectStrain:null,
					selectPhase:selectPhaseTemp,
					product_price:'',
					product_qty:'', 
					birth_date:null,
					selectVendor:null,
				});
				

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("place-grow-order-btn").disabled=false;
			});

		}
	}

	render(){
	
        const {  strains,phases,vendors, cart_totals } = this.state;
 		let data = this.state.cart
	 
    	const dataTableColumns = [
			{
				Header: "Strain",
				accessor: "strain_id",
				Cell: props => <p className="list-item-heading">{props.value.label}</p>	
			},
			{
				Header: "BirthDate",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{props.value.format('MM/DD/YYYY HH:mm')}</p>
			},
			{
				Header: "Phase",
				accessor: "phase_id",
				Cell: props => <p className="text-muted">{props.value.label}</p>
			},
			{
				Header: "Price",
				accessor: "unit_price",
				Cell: props => <p className="text-muted">${number_format(parseFloat(props.value).toFixed(2),2,'.','')}</p>
			},
			{
				Header: "Quantity",
				accessor: "qty",
				Cell: props => <p className="text-muted">{number_format(parseFloat(props.value).toFixed(2),2,'.','')}</p>
			},
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="text-muted">${number_format(parseFloat(props.value).toFixed(2),2,'.','')}</p>
			},
			{
				Header: "",
				accessor: "insert_id",
				width:60,
				Cell: props =><Fragment>
								<span className="pl-1 pr-1 text-danger cursor-pointer cart-op-icons" onClick={(e)=>{ this.removeCart(props.value) }} ><i className="fa fa-times" /></span>
							</Fragment>
			}
		]; 

		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="md"
			  wrapClassName="modal-right"
			>
			<ModalHeader toggle={this.props.toggleModal}>
				Create Order
			</ModalHeader>
			
			<ModalBody>
			<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
		
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.addToCart}
				>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>Strains<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="strain_id"
									id="strain_id"
									placeholder="--Select Strains--"
									value={this.state.selectStrain}						
									options={strains}
									onChange={selectStrain => { this.setState({ selectStrain }) }}
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="6" className="mb-4">
							<Label >Source<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								name="source"
								id="source"
								placeholder="--Select Source--"
								value={this.state.selectSource}	
								options={sources}
								onChange={selectSource => { this.setState({ selectSource }) }}
							/>
						</Colxx>						
					</Row>
					
					<Row>				
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Birth Date<span className="text-danger">*</span></Label>
								<DatePicker
									id="birth_date"
									name="birth_date"
									selected={this.state.birth_date}
									maxDate={moment()}
									showTimeSelect
									onChange={(e) => {this.setState({ birth_date: e });}}
									placeholderText=""
									autoComplete="off"
									dateFormat="MM/DD/YYYY HH:mm"
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>Phases<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="phase"
									id="phase"
									placeholder="--Select Phases--"
									value={this.state.selectPhase}						
									options={phases}
									onChange={selectPhase => { this.setState({ selectPhase }) }}
								/>
							</AvGroup>
						</Colxx>					
					</Row>

					<Row>
						<Colxx lg="6" className="mb-2">
							<AvGroup className="error-t-negative">
							  <Label>Price<span className="text-danger">*</span></Label>
							  <AvField
								id="product_price"
								name="product_price"
								type="number"
								min="0"
								value={this.state.product_price}
								onChange={e => this.setState({product_price: e.target.value})}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please enter the price"
								  }
								}}
							  />
							</AvGroup>
							
						</Colxx>	
						<Colxx lg="6" className="mb-2">
							<AvGroup className="error-t-negative">
								<Label>Quantity<span className="text-danger">*</span></Label>
								<AvField
									id="product_qty"
									name="product_qty"
									type="number"
									min="0"
									value={this.state.product_qty}
									onChange={e => this.setState({product_qty: e.target.value})}
									validate={{
									  required: {
										value: true,
										errorMessage: "Please enter the quantity"
									  }
									}}
								/>
							</AvGroup>	
						</Colxx>					
					</Row>
				
					<Row>
						<Colxx lg="12" className="mb-4">
							<Button
								block
								color="primary"
								size="sm"
								className=""
							>
								Add to Cart
							</Button>
						</Colxx>
					</Row>
				</AvForm>
				<AvForm
					id="growInventoryForm"
					className="av-tooltip tooltip-right-bottom compact-cart-form"
					onSubmit={this.handleSubmit}
				>
					<Row>
						<Colxx lg="12" className="mb-4">
							<AvGroup className="error-t-negative">
								<Label>Vendors</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="vendor_id"
									id="vendor_id"
									placeholder="--Select Vendors--"
									value={this.state.selectVendor}						
									options={vendors}
									onChange={selectVendor => { this.setState({ selectVendor }) }}
								/>
							</AvGroup>
						</Colxx>									
					</Row>

					<Row>
						<Colxx lg="12">	  
							<ReactTable
							  data={data}
							  columns={dataTableColumns}
							  defaultPageSize={10}
							  minRows={2}
							  filterable={false}
							  showPageJump={false}
							  PaginationComponent={DataTablePagination}
							  showPageSizeOptions={true}
							  defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</Colxx>
					</Row>
					
					<Row className="text-right">
						<Colxx lg="12">
							<div className="bg-black ">
								<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">Grand Total </span>: $ {number_format(parseFloat(cart_totals.total).toFixed(2),2,'.','')}</p>
							</div>
						</Colxx>
					</Row>
					<ModalFooter>
						<Button color="primary" id="place-grow-order-btn" >
							<IntlMessages id="customer.submit" />
						</Button>
						<Button type="button" color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</BlockUi>
		  </ModalBody>
		 
	  
	  </Modal>
	  );
  }
};

export default CreateOrderModal;
