import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input,
    DropdownItem,
	CustomInput, Badge,ButtonDropdown,DropdownToggle,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddTableTypeModal from "../../../containers/growhouse/AddTableTypeModal";
import EditTableTypeModal from "../../../containers/growhouse/EditTableTypeModal";
import ReactTable from "react-table";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AllTablesType extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
	this.state = {		  
		  modalOpenTable: false,
		  modalOpenEditTable: false,
		  modalOpenPlantTable: false,
		  dropdownSplitOpen: false,
		  tables:[],
		  search: '',
	};
	}
	toggleModalTable = () => {
		this.setState({
		  modalOpenTable: !this.state.modalOpenTable
		});
	};
	toggleModalEditTable = (e,table_type_id =0) => {
		this.setState({
		  table_type_id: parseInt(table_type_id ),
		  modalOpenEditTable: !this.state.modalOpenEditTable
		});
	};
	toggleModalPlantTable = () => {
		this.setState({
		  modalOpenPlantTable: !this.state.modalOpenPlantTable
		});
	};
	
	
	toggleSplit = () => {
    this.setState(prevState => ({
      dropdownSplitOpen: !prevState.dropdownSplitOpen
    }));
	};
  	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.tables.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    } 
	
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	
	dataListRender() {		
		
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'growhouse/get_table_types',formData).then((res)=>{
			var data = res.data;
			plant_sizes=data.plant_sizes;
				
		    plant_sizes = plant_sizes.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
		
			if(data.table_types){
				this.setState({
			        tables: data.table_types,
					plant_sizes:plant_sizes,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					tables: [],
					plant_sizes:plant_sizes,
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="table type";
		
		if(selectedIds.length==0){
			swal("Warning","Please select at least one table type","warning");
			return;
		}
		
		if(selectedIds.length>1){
			msg_word="table types";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-table").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('type_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('current_user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'growhouse/delete-table-types',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-table").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-table").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	

    activeItem=(e,type_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(type_id);
			
		}else{
		  var x=selectedIds.indexOf(type_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.tables.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}

	
	render() {
		let data = this.state.tables
		if(this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.table_capacities.includes(this.state.search) 
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"tables_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>	
			},
			{
				Header: "Capacities Available",
				accessor: "table_capacities",
				Cell: props => <p className="text-muted">{props.value}</p>
			},	  	  	  
			{
				Header: "Options",
				accessor: "id",
				Cell: props => <Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
						<DropdownItem onClick={(e)=> this.toggleModalEditTable(e,props.value) } >							
							<span className="d-inline-block">Edit</span>												
						</DropdownItem>
						<DropdownItem  id="delete-table"  onClick={event =>this.deleteItem(event,[props.value],2)} >						 
							<span className="d-inline-block">Delete</span>						
						</DropdownItem>
					  </DropdownMenu>} /></div></Fragment>
			}
		];
		
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.all-table-types" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			   <Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				<Colxx xxs="12">
					<div className="float-sm-right mb-2">
						<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalTable}
							>
							  Add New						  
							</Button>{" "}
							<Button
							id="delete-staff"
							color="primary"
							size="sm"
							className=""	
							onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
							>
							<IntlMessages id="pages.delete" />
							</Button>
						</div>						
					</div>					 
	  
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
				</Colxx>
			             <AddTableTypeModal
							modalOpen={this.state.modalOpenTable}
							toggleModal={this.toggleModalTable}
							plant_sizes={this.state.plant_sizes}
							dataListRender={this.dataListRender}
						
						  />
						  <EditTableTypeModal
							modalOpen={this.state.modalOpenEditTable}
							toggleModal={this.toggleModalEditTable}
							plant_sizes={this.state.plant_sizes}
							dataListRender={this.dataListRender}
							table_type_id ={this.state.table_type_id }
						  />
						  
			</Row>
		</Card>
      </Fragment>
    );
  }
}
