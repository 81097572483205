import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

class WastePhasePlantModal extends Component{

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			
		};
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("waste-plant-phase").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("wasteForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_id',this.props.plant_id);
			formData.append('strain_id',this.props.strain_id.value);
			formData.append('phase_id',this.props.phase_id.value);
			

			axios.post(REST_API_END_POINT+'growhouse/waste-plant-phase',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\"  role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("waste-plant-phase").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("waste-plant-phase").disabled=false;
			});

		}
	}

      render() {
      
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Reason For Waste 
			</ModalHeader>
			<AvForm
                id="wasteForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Quantity in gm<span className="text-danger">*</span></Label>
							  <AvField
								id="delete_quantity"
								name="delete_quantity"
								type="text"
								autoComplete="off"
							
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>

					</Row>
					<AvGroup className="error-t-negative">
						<Label>Reason</Label>	&nbsp;&nbsp;
						<AvField
							id="delete_reason"
							name="delete_reason"
							type="textarea"
							autoComplete="off"
							value={this.state.textarea_content}
							placeholder=""
							rows="3"
							validate={{
								minLength: {
									value: 5,
									errorMessage:"Your message must have minimum of 5 characters"
								},
							}}
						/>
					</AvGroup>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="waste-plant-phase" >
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default WastePhasePlantModal;
