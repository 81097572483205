import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";
import { AvForm, AvInput, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  Button,
  Label, InputGroup, InputGroupAddon, CustomInput
} from "reactstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AppSettings extends Component {
	constructor(props) {
		super(props);
		this.updateAppSettings = this.updateAppSettings.bind(this);
	}
	
	updateAppSettings(values) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);		
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		for(let key in values){
			formData.append(key,values[key]);
		}

		axios.post(REST_API_END_POINT+'visitor/update-app-settings',formData).then((res)=>{
			var data = res.data;
			
			var api_message=document.getElementById("api_app_settings");
			api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
			
			window.setTimeout(function() {
				JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
					JQUERY_MODULE.$(this).remove();
				});
			}, 5000);
			
			JQUERY_MODULE.$('.alert .close').on("click", function(e){
				JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
					JQUERY_MODULE.$(this).remove();
				});
			});
			if(data.status==1){
				this.props.updateData(values);
			}
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	render() {
		const facility_data = this.props.facility_data  
		
		return( 
			<Fragment>
				<h2>Update App Settings</h2>
				<hr/>
				<div id="api_app_settings"></div>				
				
				<div className="mb-4 dropdown-edit">					
					<Row>
						<Colxx lg="12" className="mb-2">
							<Label>													  
								<Switch
									className="custom-switch custom-switch-primary im-inline-block"
									checked={facility_data!=null ? (facility_data.capture_visitor_photo==1):false}
									onChange={status => { 
										let values={};
										values.capture_visitor_photo = status ? 1:2;
										this.updateAppSettings(values);
									}}
								/>
								<span className="h6 ml-4">Capture visitor photo on camera?</span>
							</Label>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="12" className="mb-2">
							<Label>													  
								<Switch
									className="custom-switch custom-switch-primary im-inline-block"
									checked={facility_data!=null ? (facility_data.print_visitor_badge==1):false}
									onChange={status => { 
										let values={};
										values.print_visitor_badge = status ? 1:2;
										this.updateAppSettings(values);
									}}
								/>
								<span className="h6 ml-4">Print a visitor badge?</span>
							</Label>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="12" className="mb-2">
							<Label>													  
								<Switch
									className="custom-switch custom-switch-primary im-inline-block"
									checked={facility_data!=null ? (facility_data.staff_notification==1):false}
									onChange={status => { 
										let values={};
										values.staff_notification = status ? 1:2;
										this.updateAppSettings(values);
									}}
								/>
								<span className="h6 ml-4">Is staff notification required?</span>
							</Label>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="12" className="mb-2">
							<Label>													  
								<Switch
									className="custom-switch custom-switch-primary im-inline-block"
									checked={facility_data!=null ? (facility_data.enable_covid_form==1):false}
									onChange={status => { 
										let values={};
										values.enable_covid_form = status ? 1:2;
										this.updateAppSettings(values);
									}}
								/>
								<span className="h6 ml-4">Enable Covid health questionnaire form?</span>
							</Label>
						</Colxx>
					</Row>						
				</div>       
			</Fragment>
		);
	}
  
};
