import React, { Component, Fragment } from "react";
import { Row , DropdownItem,DropdownMenu} from "reactstrap";
import data from "../../data/harvest";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import ItemDropdown from "../../components/customer/ItemDropdown";
import {	
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const selectData = [
    { label: "YES", value: "1", key: 0 },
    { label: "NO", value: "0", key: 1 }
  ];
const selectAction = [
    { label: "Batch At Harvest", value: "1", key: 0 },
    { label: "Batch Later", value: "2", key: 1 },
    { label: "Discard As Waste", value: "3", key: 2 },
  ];  
const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

class Cure extends Component {
	
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.state = {
		  modalOpen: false,
		  search: "",
		  selectedItems: [],
		  lastChecked: null,
		  isLoading: false,
		  selectSourceType : []
		};
	}
 
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("save-cure").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var cure_options=JSON.parse(JSON.stringify(this.state.cure_options));
			cure_options = cure_options.map(row=>{
				let data={};
				data.id=row.id;
				data.action=row.action;
				data.status=row.status;
				return data;
			})
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('cure_options',JSON.stringify(cure_options));
			

			axios.post(REST_API_END_POINT+'growhouse/cure-batch-options',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_additive");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("save-cure").disabled=false;
				
				document.body.scrollTop = 0; // For Safari
				document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
				this.dataListRender();
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("save-cure").disabled=false;
			});

		}
	}
	
	
	componentDidMount(){
		this.dataListRender();
	}
  
    dataListRender() {		
		var cure_options=[];	
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'growhouse/get-batch-options',formData).then((res)=>{
			var data = res.data;
			
				cure_options=data.cure_options;
		
				this.setState({
					cure_options: data.cure_options,
					selectAll:0,
					selectedIds:[],
				});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}  
  
	render() {
		let data = this.state.cure_options
	  
		const dataTableColumns = [			  
			{
				Header: "Active",
				accessor: "status",
				Cell: props => <Fragment>	
								<AvGroup style={{width:'100px'}}>			
									<AvField
										type="select"
										name="status"								
										value={props.value}
										onChange={(e)=>{
											var cure_options=this.state.cure_options;
											var id=props.original.id;
											cure_options.map(row=>{
												if(row.id==id){
													row.status=e.target.value;
												}
												return row;
											})
											this.setState({
												cure_options
											},);
										}}
									>
									{selectData.map(opt=>{
										return <option key={opt.key} value={opt.value}>{opt.label}</option>
									})}
								</AvField>						  
							</AvGroup>
						</Fragment>
			},
			{
				Header: "Order",
				accessor: "order",
				width:100,
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Harvest Type",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Default Action",
				accessor: "action",
				Cell: props =><Fragment>
							<AvGroup className="error-t-negative">						
								<AvField
									type="select"
									name="harvest_action[]"
									value={props.value}
									onChange={(e)=>{
										var cure_options=this.state.cure_options;
										var id=props.original.id;
										cure_options.map(row=>{
											if(row.id==id){
												row.action=e.target.value;
											}
											return row;
										})
										this.setState({
											cure_options
										},);
									}}
								>
								<option value="" >--Select Action--</option>
								{selectAction.map(opt=>{
									return <option key={opt.key} value={opt.value}>{opt.label}</option>
								})}
								</AvField>						  
							</AvGroup>
				</Fragment>
			}
		];	
    return( 
      <Fragment>       
        <div className="p-4">	
			<div id="api_message_additive"></div>				
			<div className="mb-4 dropdown-edit">
				<AvForm
					id="plantAdditiveForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ReactTable
						data={data}
						TbodyComponent={CustomTbodyComponent}
						columns={dataTableColumns}
						defaultPageSize={8}
						showPageJump={false}
						showPageSizeOptions={false}
						showPagination={false}
					/>
					
					<div className="mt-2 mb-4">
						<Button color="primary" id="save-cure" >Save</Button>
					</div>
				</AvForm>
			</div>
		</div>	         
      </Fragment>
    );
  }
  
};
export default Cure;
