import React, { Component } from "react";
import { AvForm, AvField, AvGroup, } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,InputGroup, InputGroupAddon, Card, CardBody
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { uuid } from 'uuidv4';
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import axios from 'axios';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const WeekdayOptions = [
	{ label: "All Day", value: "0", key: -1 },
	{ label: "Monday", value: "1", key: 0 },
	{ label: "Tuesday", value: "2", key: 1 },
	{ label: "Wednesday", value: "3", key: 2 },
	{ label: "Thursday", value: "4", key: 3 },
	{ label: "Friday", value: "5", key: 4 },
	{ label: "Saturday", value: "6", key: 5 },
	{ label: "Sunday", value: "7", key: 6 }
];

const MonthsOptions = [
	{ label: "All Months", value: "0", key: -1 },
	{ label: "January", value: "1", key: 0 },
	{ label: "February", value: "2", key: 1 },
	{ label: "March", value: "3", key: 2 },
	{ label: "April", value: "4", key: 3 },
	{ label: "May", value: "5", key: 4 },
	{ label: "June", value: "6", key: 5 },
	{ label: "July", value: "7", key: 6 },
	{ label: "August", value: "8", key: 7 },
	{ label: "September", value: "9", key: 8 },
	{ label: "October", value: "10", key: 9 },
	{ label: "November", value: "11", key: 10 },
	{ label: "December", value: "12", key: 11 },
];
 
class EditDealModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onVariationChange = this.onVariationChange.bind(this);
		this.onCategoryChange = this.onCategoryChange.bind(this);

		this.state = {
			products:[],
			categories:[],
			tax_categories:[],
			product_variations:[],
			product_ids:[],
			variation_id:null,
			category_id:null,
			tax_category:null,			
			inclusive_taxes:false,
			week_day: { label: "All Day", value: "0", key: -1 },
			deal_month: { label: "All Month", value: "0", key: -1 },
			restrict_hours:false,
			start_time:null,
			end_time:null,
		};
	}
	
	componentDidMount() {		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'point-of-sale/get-data-for-match-deal',formData).then((res)=>{
			var data = res.data;
			var product_variations=data.product_variations;
			var tax_categories=data.tax_categories;
			var categories=data.categories;
			
			product_variations.map((element,index)=>{
				element.label=element.name;
				element.value=element.quantity+"_"+element.qty_type;
				element.key=index;
				return element;
			});
			
			tax_categories.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			categories.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				product_variations,
				tax_categories,
				categories
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		
        if(this.props.modalOpen){
			if (prevProps.deal_id !== this.props.deal_id && this.props.deal_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('deal_id',this.props.deal_id);
	
				axios.post(REST_API_END_POINT+'point-of-sale/get-mix-and-match-deal-by-id',formData).then((res)=>{
					var data = res.data;					
					let deal = data.deal;			 
					
					let products = data.products;
					
					products.map((element,index)=>{
						element.label=element.name;
						element.value=element.id;
						element.key=index;
						return element;
					});
					
					let product_variations = data.product_variations;
					product_variations.map((element,index)=>{
						element.label=element.name;
						element.value=element.quantity+"_"+element.qty_type;
						element.key=index;
						return element;
					});
					
					let week_day = WeekdayOptions.find(p=>{ return p.value==deal.week_day });
					let deal_month = MonthsOptions.find(p=>{ return p.value==deal.deal_month });
					let variation_id = product_variations.find(p=>{ return p.value==deal.variation_quantity+'_'+deal.variation_qty_type });
					let category_id = this.state.categories.find(p=>{ return p.value==deal.category_id });
					let tax_category = this.state.tax_categories.find(p=>{ return p.value==deal.tax_category });
					let inclusive_taxes = deal.including_tax==1;
					let restrict_hours = deal.restrict_hours==1;
					let product_ids = products.filter(p=>{ return deal.product_ids.indexOf(p.value)!=-1 });
					
					let start_time=null,end_time=null;					
					if(restrict_hours){
						if(deal.deal_starttime!=null){
							start_time=moment(moment().format("YYYY-MM-DDT")+deal.deal_starttime);
						}
						if(deal.deal_endtime!=null){
							end_time=moment(moment().format("YYYY-MM-DDT")+deal.deal_endtime);
						}
					}
					
					this.setState({ 
						deal,
						week_day,
						deal_month,
						tax_category,
						category_id,
						variation_id,
						inclusive_taxes,
						restrict_hours,
						products,
						product_variations,
						product_ids,
						start_time,
						end_time
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}
		}
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			let errorMessage='';
			if(this.state.category_id==null){
				swal("Warning","Please select the category","warning");
				return false;
			}
			
			if(this.state.variation_id==null){
				swal("Warning","Please select the variation","warning");
				return false;
			}
			
			if(this.state.product_ids.length==0){
				swal("Warning","Please select the products","warning");
				return false;
			}
			
			if(this.state.tax_category==null){
				swal("Warning","Please select the tax category","warning");
				return false;
			}
						
			if(this.state.restrict_hours){
				if(this.state.start_time===null){
					swal("Warning", "Please select start time", "error");
					return false;
				}else if(this.state.end_time===null){
					swal("Warning", "Please select end time", "error");
					return false;
				}
			}
			
			document.getElementById("add-coupon").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("customercouponForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('inclusive_taxes',this.state.inclusive_taxes ? 1:0);
			formData.append('restrict_hours',this.state.restrict_hours ? 1:0);
			if(this.state.restrict_hours){
				formData.append('deal_starttime',moment(this.state.start_time).format("HH:mm:00"));
				formData.append('deal_endtime',moment(this.state.end_time).format("HH:mm:00"));
			}
			let variation_id = this.state.variation_id.value.split("_");
			
			formData.append('variation_quantity',variation_id[0]);
			formData.append('variation_qty_type',variation_id[1]);
			formData.append('product_ids',this.state.product_ids.map(r=>{ return r.id }).join(","));			
			formData.append('deal_id',this.state.deal.id);

			axios.post(REST_API_END_POINT+'point-of-sale/edit-mix-and-match-deal',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
				}
				
				document.getElementById("add-coupon").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-coupon").disabled=false;
			});

		}
	}
	
	onCategoryChange(category_id){
		if(category_id!=null){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('category_id',category_id.value);
			
			axios.post(REST_API_END_POINT+'point-of-sale/get-deal-variations',formData).then((res)=>{
				var data = res.data;
				var product_variations=data.product_variations;
				product_variations.map((element,index)=>{
					element.label=element.name;
					element.value=element.quantity+"_"+element.qty_type;
					element.key=index;
					return element;
				});
				
				this.setState({ product_variations,category_id },()=>{
					if(this.state.variation_id!=null){
						this.onVariationChange(this.state.variation_id);
					}
				});
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
	
	onVariationChange(variation_id){
		if(variation_id!=null){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			let variation_ids = variation_id.value.split("_");
			
			if(this.state.category_id!=null){
				formData.append('category_id',this.state.category_id.value);
			}
			formData.append('variation_quantity',variation_ids[0]);
			formData.append('variation_qty_type',variation_ids[1]);
			
			axios.post(REST_API_END_POINT+'point-of-sale/get-deal-products',formData).then((res)=>{
				var data = res.data;
				var products=data.products;
				
				products.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				this.setState({ products,variation_id, product_ids:[] });
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
	
	onChange(e){
		switch (e.target.name) {
		  case 'thumbnail':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	handleChangeStartTime = time => {
		if(parseInt(moment(this.state.end_time,"HH:mm:ss").unix()/100)>parseInt(time.unix()/100) || this.state.end_time==null){
			this.setState({ start_time: time });
		}else{
			swal("", "Start time must be less than end time", "error");		
		}
		
	};
	
	handleChangeEndTime = time => {
		if(parseInt(moment(this.state.start_time,"HH:mm:ss").unix()/100)<parseInt(time.unix()/100) || this.state.start_time==null){
			this.setState({ end_time: time });
		}else{
			swal("", "End time must be greater than start time", "error");		
		}
	};


	render(){
		if(this.state.deal!=null){
			var deal=this.state.deal;
		}else{
			var deal;
		}
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Mix and Match Deal
				</ModalHeader>
				<AvForm
					id="customercouponForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Name<span className="text-danger">*</span></Label>
								  <AvField
									id="name"
									name="name"
									type="text"
									value={ deal?((deal.name!=null)?deal.name:''):'' }
									autoComplete="off"
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									  minLength: {
										value: 2,
										errorMessage:"The name must be between 2 and 255 characters"
									  },
									  maxLength: {
										value: 255,
										errorMessage:"The name must be between 2 and 255 characters"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Description</Label>
									<AvField
										id="description"
										name="description"
										type="textarea"
										autoComplete="off"
										value={ deal?((deal.description!=null)?deal.description:''):'' }
										rows="4"									
									/>
								</AvGroup>
							</Colxx>
						</Row>
						{deal ? (deal.thumbnail!=null ? <Row className="mt-4">
							<Colxx lg="12" >
								<div className="d-flex justify-content-start">
									<div className="text-center img-view-wrapper">
										<img src={BASE_URL+'products/'+deal.thumbnail} alt="Product Image" className="img-responsive" />
									</div>
								</div>
							</Colxx>
						</Row>:""):""}
						<Row>
							<Colxx lg="12" className="mb-2">						
								<AvGroup className="error-t-negative">
									<Label>Thumbnail (Recommended size:500x500)</Label>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
										<CustomInput
											type="file"
											id="thumbnail"
											name="thumbnail"
											accept="image/*"
											onChange={this.onChange}
										/>
									</InputGroup>
								</AvGroup>					
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Category<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="category_id"
										id="category_id"
										value={this.state.category_id}
										onChange={category_id => { this.onCategoryChange(category_id) }}
										options={this.state.categories}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Variation<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="variation_id"
										id="variation_id"
										value={this.state.variation_id}
										onChange={variation_id => { this.onVariationChange(variation_id) }}
										options={this.state.product_variations}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="8">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Products<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="product_ids"
										id="product_ids"
										isMulti
										value={this.state.product_ids}
										onChange={product_ids => { this.setState({ product_ids }) }}
										options={this.state.products}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="4">
								<Button color="primary" className="mt-4" onClick={()=>{ this.setState({ product_ids:this.state.products }) }}>Select All Products</Button>
							</Colxx>
						</Row>
						<Row>
							<Colxx md="6">
								<AvGroup >
									<Label>Total Product Quantity<span className="text-danger">*</span></Label>
									<AvField
										id="no_of_products"
										name="no_of_products"
										type="number"
										min='1'
										autoComplete="off"
										value={ deal?((deal.no_of_products!=null)?deal.no_of_products:''):'' }
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter numeric value"
											},
											min: {
												value: "1",
												errorMessage: "Please enter a value greater than or equal to 1"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							<Colxx md="6">
								<AvGroup >
									<Label>Price<span className="text-danger">*</span></Label>
									<AvField
										id="amount"
										name="amount"
										type="number"
										min='0'
										autoComplete="off"
										value={ deal?((deal.amount!=null)?deal.amount:''):'' }
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter numeric value"
											},
											min: {
												value: "0",
												errorMessage: "Please enter a value greater than or equal to 0"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="6">
								<AvGroup >
									<Label>Month</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="deal_month"
										id="deal_month"
										value={this.state.deal_month}
										onChange={deal_month => { this.setState({ deal_month }) }}
										options={MonthsOptions}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="6">
								<AvGroup >
									<Label>Weekday</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="week_day"
										id="week_day"
										value={this.state.week_day}
										onChange={week_day => { this.setState({ week_day }) }}
										options={WeekdayOptions}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.restrict_hours}
										onChange={status => { this.setState({ restrict_hours:!this.state.restrict_hours }) }}
									/>
									<span className="h6 ml-4">Restrict Hours</span>
								</Label>
							</Colxx>
						</Row>
						
						{this.state.restrict_hours ? <Row>
							<Colxx lg="6" >		
								<AvGroup className="error-t-negative disabled_time_hide">
									<Label>Start Time</Label><br/>					
									<DatePicker
										className="mb-2"
										placeholderText=""
										selected={this.state.start_time}
										onChange={this.handleChangeStartTime}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										dateFormat="hh:mm a"
										timeCaption="Time"
										popperPlacement="bottom"
										popperModifiers={{
											flip: {
												behavior: ["bottom"] // don't allow it to flip to be above
											},
											preventOverflow: {
												enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
											},
											hide: {
												enabled: true // turn off since needs preventOverflow to be enabled
											}
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx lg="6" >		
								<AvGroup className="error-t-negative disabled_time_hide">
									<Label>End Time</Label><br/>					
									<DatePicker
										className="mb-2"
										placeholderText=""
										selected={this.state.end_time}
										onChange={this.handleChangeEndTime}
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										dateFormat="hh:mm a"
										timeCaption="Time"
										popperPlacement="bottom"
										popperModifiers={{
											flip: {
												behavior: ["bottom"] // don't allow it to flip to be above
											},
											preventOverflow: {
												enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
											},
											hide: {
												enabled: true // turn off since needs preventOverflow to be enabled
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>:""}
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Tax Category<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="tax_category"
										id="tax_category"
										value={this.state.tax_category}
										onChange={tax_category => { this.setState({ tax_category }) }}
										options={this.state.tax_categories}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.inclusive_taxes}
										onChange={status => { this.setState({ inclusive_taxes:!this.state.inclusive_taxes }) }}
									/>
									<span className="h6 ml-4">Inclusive Taxes</span>
								</Label>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="add-coupon" >
						  <IntlMessages id="customer.update" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
						  <IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditDealModal;
