import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';

import {REST_API_END_POINT, dataEncryptKey,JQUERY_MODULE } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class VoidOrderModal extends Component{
	constructor(props) {
		super(props);
				
		this.handleSubmit = this.handleSubmit.bind(this);
	
    }
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("void-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("voidOrderForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);

			axios.post(REST_API_END_POINT+'purchase-order/void-order',formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("void-order").disabled=false;
				this.props.dataListRender();
				this.props.toggleModal();
				
				if(data.status==1){
					swal("Success",data.msg,"success");
				}else{
					swal("Failed",data.msg,"warning");
				}
					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("void-order").disabled=false;
			});

		}
	}
	
    render() {
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Void Order
				</ModalHeader>
				
				<AvForm
					id="voidOrderForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >						
						<Row>
							<Colxx md="12">
								<AvGroup>
									<Label>Reason<span className="text-danger">*</span></Label>
									<AvField
										id="void_reason"
										name="void_reason"
										type="textarea"
										autoComplete="off"
										rows="4"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the reason for void"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter className="justify-content-start">
						<Button color="primary" id="void-order">
							<IntlMessages id="customer.submit" />
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
