import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Card, CardBody,Button, CustomInput,FormGroup, Input,DropdownItem, Badge, DropdownMenu} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import AddShelfModal from "../../../containers/warehouse/AddShelfModal";
import EditShelfModal from "../../../containers/warehouse/EditShelfModal";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import swal from 'sweetalert';
import {REST_API_END_POINT, dataEncryptKey,JQUERY_MODULE,themeColor} from "../../../constants/defaultValues";
import axios from 'axios';
import uniqueId from 'lodash/uniqueId';
import SimpleCrypto from "simple-crypto-js";
import { NavLink } from "react-router-dom";
import ReactTable from "react-table";
import { Draggable, Droppable } from 'react-drag-and-drop'
import DataTablePagination from "../../../components/DatatablePagination";
import TooltipItemContent from "../../../components/common/TooltipItemContent";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class Warehouseview extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleView = this.toggleView.bind(this);
		this.viewShelf = this.viewShelf.bind(this);
		
		this.state = {
			grid_view:true,
			warehouse:null,
			occupy:null,
			shelves:[],
			shelf_nos_data:[],
			shelf_structures:[],
			selectAll:0,
			selectedIds:[],
			user_privileges:[],
	
		};	
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleView =(grid_view) => {
		this.setState({
			grid_view
		});
	};

	toggleModalEdit = (e,shelf_id=0) => {
		this.setState({
			shelf_id: parseInt(shelf_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	viewShelf = (e,shelf_id=0) => {
		if(shelf_id!=0){
			window.location="/app/warehouse/shelf-view/"+shelf_id;
		}
	};
	
	toggleSelectAll(){
		let newSelected = [];
		if (this.state.selectAll === 0) {
			this.state.shelves.forEach(x => {
				newSelected.push(x.id);
			});
		}
		this.setState({
			selectedIds: newSelected,
			selectAll: this.state.selectAll === 0 ? 1 : 0
		});
	}

	componentDidMount(){
		this.dataListRender();
		
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}


	dataListRender() {		   
		var formData = new FormData();
		let shelf_structures;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('warehouse_id',this.props.match.params.warehouse_id );		
		
		axios.post(REST_API_END_POINT+'warehouse/view-warehouse',formData).then((res)=>{
			var data = res.data;
			shelf_structures=data.available_shelf_nos;
		   
			if(data.shelves){
				this.setState({
					occupy: data.occupy,
					warehouse: data.warehouse,
					shelves: data.shelves,
					shelf_nos_data: data.shelf_nos_data,
					shelf_structures,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					shelves: [],
					shelf_nos_data: [],
					shelf_structures,
					selectAll:0,
					selectedIds:[]
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
		
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="shelf";
		
		if(selectedIds.length>1){
			msg_word="shelves";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-shelves").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('shelf_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'warehouse/delete-shelves',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-shelves").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-shelves").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,shelf_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(shelf_id);
			
		}else{
		  var x=selectedIds.indexOf(shelf_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.shelves.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	onRoomDrop=(data,e)=>{
		//if(this.state.user_privileges.indexOf(8)!==-1){
			
			var position = e.target.parentNode.getAttribute('data-position')==null ? e.target.getAttribute('data-position'):e.target.parentNode.getAttribute('data-position');
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			if(position!=null){
				var formData = new FormData();
				formData.append('shelf_id',data.rooms);
				formData.append('position',position);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'warehouse/update-shelf-position',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					if(data.status==1){
						this.dataListRender();
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		/*}else{
			swal("Warning!","You don't have permission to perform this action");
		}*/
	}
	
    render() {
		let data = this.state.shelves;
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.capacity.toLowerCase().includes(this.state.search) || row.created_at.toLowerCase().includes(this.state.search)
			})
		}
		
       const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"warehouse_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Shelf No",
				accessor: "position",
				Cell: props => <p className="text-muted">{props.value}</p>
			},	 
			{
				Header: "Created at",
				accessor: "created_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				width:80,
				Cell: props => <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{props.value==1?"Active":"Inactive"}</Badge>
			},
			{
				Header: "Options",
				accessor: "id",
				sortable: false,
				Cell: props => <Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
						<DropdownItem onClick={(e)=>this.toggleModalEdit(e,props.value)} >
							<span className="d-inline-block">Edit</span>
						</DropdownItem>
						<DropdownItem onClick={(e)=>this.viewShelf(e,props.value)} >
							<span className="d-inline-block">View</span>
						</DropdownItem>
						{props.original.status==1 ? <DropdownItem onClick={(e)=>this.deleteItem(e,[props.value],0)} >
							<span className="d-inline-block">Inactive</span>
						</DropdownItem>	: <DropdownItem onClick={(e)=>this.deleteItem(e,[props.value],1)} >
							<span className="d-inline-block">Active</span>
						</DropdownItem>	}
						<DropdownItem onClick={(e)=>this.deleteItem(e,[props.value],2)} >
							<span className="d-inline-block">Delete</span>
						</DropdownItem>						
					</DropdownMenu>} /></div></Fragment>			  
			}
	];
	
	var GridContent=[];
	let i;
	if(this.state.warehouse!=null){
		var rows=this.state.warehouse['capacity_detail'].split(',');
		var shelf_no = this.state.shelf_nos_data;
		var j=0;
		if(rows.length){
			let k=1;
			for(let table_row of rows){
				GridContent.push(<div key={uniqueId()} className="row special-full-row icon-cards-row mb-2" ></div>);
				table_row = parseInt(table_row);
				let i=0;
				for(i = 0; i < table_row; i++){
					if(j<shelf_no.length && k==shelf_no[j]['position']){
						let shelf_id = shelf_no[j]['id'];
						let shelf_name = shelf_no[j]['name'];
						let shelf_status = shelf_no[j]['status'];
						GridContent.push(<Card className="mb-3 mr-3 shelf-width" key={uniqueId()}>
							<TooltipItemContent key={'tip'+shelf_id} item={{
											  placement: "top",
											  id: 'tip'+shelf_id,
											  content: <CardBody className="text-center pt-1 pb-1">
															<Draggable type="rooms" data={shelf_id}>
																<FormGroup className="mb-1">
																	<CustomInput type="checkbox" id={"warehouse_"+shelf_id} onChange={event =>this.activeItem(event,shelf_id) } checked={this.state.selectedIds.includes(shelf_id)} />
																</FormGroup>
																<img  alt="Shelf" src={shelf_status==1 ? "/assets/img/shelf.png":"/assets/img/closed-shelf.png"} className="img-responsive p-0" onClick={(e)=>this.viewShelf(e,shelf_id)} />
															</Draggable>
														</CardBody>,
											  body: shelf_name,
											  TipClassName:'bg-theme-color'
											}} id={'tip'+shelf_id} />
													  
						</Card>);
						j++;
					}else{
						GridContent.push(<Card className="mb-3 mr-3 shelf-width center-content-shelf" key={uniqueId()} >
							<CardBody className="text-center pt-1 pb-1 center-position-number">
								<Droppable types={['rooms']} data-position={k} onDrop={this.onRoomDrop.bind(this)}>
									<h1>{k}</h1>
								</Droppable>
							</CardBody>						  
						</Card>);
					}
					k++;
				}
				//GridContent+='</div>';
			}
		}
					
	}
	
	
	
    return (
      <Fragment>
        <Row>		
          <Colxx xxs="12">            
			{this.state.warehouse!=null ? <Breadcrumb heading_text={this.state.warehouse.name} match={this.props.match} />:<Breadcrumb heading="" match={this.props.match} />}
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				
				<Colxx xxs="12">
				{this.state.occupy!=null ? (this.state.occupy.capacity==this.state.occupy.shelf_count? <Button																		 
						  	  color="danger"
						  	  size="sm"
						  	  className="mb-3"
						  	> Fully Occupied 							  
					  	</Button>:""):""}
				</Colxx>
				
				<Colxx xxs="12">
					<div className="float-sm-right">
						{" "}{this.state.occupy!=null ? (this.state.occupy.capacity==this.state.occupy.shelf_count? "":<Button																		 
						  	  color="primary"
						  	  size="sm"
						  	  className=""
						  	  onClick={this.toggleModalAdd}
						  	> Add New 							  
					  	</Button>):""}																
						{" "}<Button
						    id="delete-shelves"
							color="primary"
							size="sm"
							className=""
                            onClick={event =>this.deleteItem(event,this.state.selectedIds,2)}								
						  >
							<IntlMessages id="pages.delete" />
						</Button>
					</div>
					
					<div className="mb-4">						
						<Button
							color="primary"
							size="sm"
							className={this.state.grid_view ? "":"opacity-list-type-reduce" }
							onClick={event =>this.toggleView(true)}					
						  >
							<i className="fas fa-th-large"></i>
						</Button>{" "}
						<Button
							color="primary"
							size="sm"
							className={!this.state.grid_view ? "":"opacity-list-type-reduce" }
							onClick={event =>this.toggleView(false)}
						  >
							<i className="fas fa-list"></i>
						</Button>
					</div>
					
					
					<div className="mb-4 dropdown-edit" style={{minHeight:'400px',display: (!this.state.grid_view ? "none":"block")}}>
						<Row className="icon-cards-row mb-2">
							{GridContent}							
						</Row> 
					</div>
					<div className="mb-4 dropdown-edit" style={this.state.grid_view ? {display:"none"}:{}}>						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>					
				</Colxx>
				<AddShelfModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					shelf_structures = {this.state.shelf_structures}
					warehouse_id={this.props.match.params.warehouse_id}
					dataListRender={this.dataListRender}
			    />
						  
				<EditShelfModal
					modalOpen={this.state.modalOpenEdit}
					toggleModal={this.toggleModalEdit}
					dataListRender={this.dataListRender}
					shelf_structures = {this.state.shelf_structures}
					shelf_id={this.state.shelf_id}
					warehouse_id={this.props.match.params.warehouse_id}
				/>
						  
			</Row>
		</Card>
      </Fragment>
    );
  }
}

export default injectIntl(Warehouseview);