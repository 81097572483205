import React, { Component, Fragment } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";
import { AvForm, AvInput, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  Button,
  Label, InputGroup, InputGroupAddon, CustomInput
} from "reactstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ChangeCompanyLogo extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("submit-logo").disabled=true;
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);		
			var formData = new FormData(document.getElementById("companyLogoForm"));
			formData.append('facility_id',facility_id);

			axios.post(REST_API_END_POINT+'visitor/update-company-logo',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_company_logo");
				api_message.innerHTML="<div class=\"rounded alert alert-"+(data.status==1 ? "success":"danger")+" alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				if(data.status==1){
					this.props.updateData(data);
				}
				
				this.form && this.form.reset();
				
				document.getElementById("submit-logo").disabled=false;
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("submit-logo").disabled=false;
			});

		}else{
			swal("Warning!","Please select company logo","warning");
		}
	} 
	
	onChange = e => {
		switch (e.target.name) {
		  case 'company_logo':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
  
	render() {
		const facility_data = this.props.facility_data  
		var  company_logo= BASE_URL+'company/avatar.png';
		if(facility_data!=null){
			if(facility_data.company_logo!=null){
				company_logo = BASE_URL+'company/'+facility_data.company_logo;
			}
		}
		return( 
			<Fragment>
				<h2>Update Company Logo</h2>
				<hr/>
				<div id="api_company_logo"></div>				
				
				<div className="mb-4 dropdown-edit">
					<AvForm
						id="companyLogoForm"
						ref={c => (this.form = c)}
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					  >
						{facility_data!=null ? <div className="mt-2">
							<div className="text-center company-img-wrapper">
								<div className="text-center position-relative">
									<div className="text-center company-img" >
										<img src={company_logo} />
									</div>
								</div>		
							</div>							
						</div>:""}
						<Row className="mt-4">
							<Colxx lg="12" className="mb-2">						
								<AvGroup className="error-l-125 error-t-negative">
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
										<AvInput 
											tag={CustomInput}
											type="file"
											id="company_logo"
											name="company_logo"
											accept="image/*"
											onChange={this.onChange}
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  }
											}}
										/>
									</InputGroup>
								</AvGroup>					
							</Colxx>
						</Row>
						<Row className="mt-2 mb-4">	
							<Colxx xxs="6">
								<Button	id="submit-logo" color="primary" >Submit</Button>					
							</Colxx>					
						</Row>
					</AvForm>
				</div>       
			</Fragment>
		);
	}
  
};
