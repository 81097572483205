import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";

import SendtoLab from "../../../containers/inventory/distillate-bucket/SendtoLab";
import AddResult from "../../../containers/inventory/distillate-bucket/AddResult";
import ViewTestResult from "../../../containers/inventory/distillate-bucket/ViewTestResult";
import MoveToInventorySingle from "../../../containers/inventory/distillate-bucket/MoveToInventorySingle";
import MoveToInventoryMulti from "../../../containers/inventory/distillate-bucket/MoveToInventoryMulti";


import swal from 'sweetalert';
import moment from 'moment';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class DistillateBuckets extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			modalOpenAdd: false,
			modalOpenSingle: false,
			modalOpenMulti: false,
			modalOpenAddResult: false,
			modalOpenView: false,
			search: '',
			data:[],
			products:[],
			selectAll:0,
			selectedIds:[],
			bucket_data:null
		};
		
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.data.forEach(x => {
                newSelected.push(x.bucket_id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	activeItem=(e,id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(id);
			
		}else{
		  var x=selectedIds.indexOf(id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.data.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'distillate-bucket/all-buckets',formData).then((res)=>{
			var data = res.data;
		
			if(data.buckets){
				let products = data.products;
				products = products.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				this.setState({
					data: data.buckets,
					products,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					data: [],
					products: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	toggleModalSendToLab = (e,bucket_data=null) => {
		this.setState({
			bucket_data,
			modalOpenSendToLab: !this.state.modalOpenSendToLab
		});
	};
		
	toggleModalAddResult = (e,bucket_data=null) => {
		this.setState({
			bucket_data,
			modalOpenAddResult: !this.state.modalOpenAddResult
		});
	};
	
	toggleModalView = (e,bucket_data=null) => {
		this.setState({
			bucket_data,
			modalOpenView: !this.state.modalOpenView
		});
	};
	
	toggleModalSingle = (e,bucket_data=null) => {
		this.setState({
			bucket_data,
			modalOpenSingle: !this.state.modalOpenSingle			
		});
	};
	
	toggleModalMulti = () => {
		this.setState({
			modalOpenMulti: !this.state.modalOpenMulti
		});
	};
		
	
	deleteItem = (e,bucket_id)  => {
		var msg="Do you want to delete this distillate?";
        
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('bucket_id',bucket_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'distillate-bucket/delete-distillate-inventory',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						this.dataListRender();
						swal("Success",data.msg,"success");
					}else{
						swal("Failed",data.msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	MoveToRemediation = (e,bucket_id)  => {
		var msg="Do you want to move this to remediation?";
        
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('bucket_id',bucket_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'distillate-bucket/remediation-distillate-inventory',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						this.dataListRender();
						swal("Success",data.msg,"success");
					}else{
						swal("Failed",data.msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	MoveToWaste = (e,bucket_id)  => {
		var msg="Do you want to move this to waste?";
        
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('bucket_id',bucket_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'distillate-bucket/waste-distillate-inventory',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						this.dataListRender();
						swal("Success",data.msg,"success");
					}else{
						swal("Failed",data.msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
     
  
	render() {
		let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.batch_uid.toLowerCase().includes(this.state.search) || row.quantity.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [			
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					if(rowInfo.original.test_passed==2){
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"bucket_"+rowInfo.original.bucket_id} onChange={event =>this.activeItem(event,rowInfo.original.bucket_id) } checked={this.state.selectedIds.includes(rowInfo.original.bucket_id)} />
						</FormGroup> );
					}else{
						return "";
					}
				}
			},
			{
				Header: "Batch UID",
				accessor: "batch_uid",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Quantity",
				accessor: "quantity",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Test Result",
				accessor: "test_passed",
				Cell: props => {
						if(props.value=="1"){
							return <p className="text-muted">Sent to Lab</p>
						}else if(props.value=="2"){
							return <p className="text-muted">Passed</p>
						}else if(props.value=="3"){ 
							return <p className="text-muted">Failed</p>
						}else{ 
							return <p className="text-muted">Pending</p>
						}
					}
			},
			{
				Header: "THC Percentage",
				accessor: "thc_percentage",
				Cell: props => {
					let row = props.original;
					if((row['test_passed']=="2" || row['test_passed']=="3")&& props.value!="0"){
						return <p className="text-muted">{props.value}%</p>
					}else{
						return "";
					}
				}
			},
			{
				Header: "Created On",
				accessor: "timestamp",
				Cell: props => <p className="text-muted">{moment(props.value*1000).format("MM/DD/YYYY HH:mm")}</p>
			},	  	  
			{
				Header: "Options",
				accessor: "",
				Cell: props => <Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
								{props.original.test_passed=="2" ? <DropdownItem onClick={(e)=>{ this.toggleModalSingle(e,props.original) }}>
										<span className="d-inline-block cursor-pointer">Move to Inventory</span>
								</DropdownItem>:""}
								{props.original.test_passed!="1" ? <DropdownItem onClick={(e)=>{ this.toggleModalSendToLab(e,props.original) }}>
									<span className="d-inline-block cursor-pointer">Send to Lab</span>
								</DropdownItem>:""}
								<DropdownItem onClick={(e)=>{ this.toggleModalAddResult(e,props.original) }}>
									<span className="d-inline-block cursor-pointer">Add test result</span>
								</DropdownItem>
								
								{props.original.test_passed=="2" || props.original.test_passed=="3" ? <DropdownItem onClick={(e)=>{ this.toggleModalView(e,props.original) }}>
									<span className="d-inline-block cursor-pointer">View Test Result</span>
								</DropdownItem>:""}
								
								{props.original.test_passed=="3" ? <DropdownItem onClick={(e)=>{ this.MoveToRemediation(e,props.original.bucket_id) }} >
									<span className="d-inline-block cursor-pointer">Move to Remediation</span>
								</DropdownItem>:""}
								
								{props.original.test_passed=="3" ? <DropdownItem onClick={(e)=>{ this.MoveToWaste(e,props.original.bucket_id) }} > 
									<span className="d-inline-block cursor-pointer">Move to Waste</span>
								</DropdownItem>:""}
								
								<DropdownItem onClick={(e)=>{ this.deleteItem(e,props.original.bucket_id) }} >
									<span className="d-inline-block cursor-pointer">Delete</span>
								</DropdownItem>
							  </DropdownMenu>} />
						  </div>
					  </Fragment>
			}
		];
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.distillate-bucket" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
				<Card className="p-4">
					<Row>
					  
						<Colxx xxs="12">
							<div id="api_message"></div>
						</Colxx>
						<Colxx xxs="12">
							<div className="float-sm-right">
								<Button																		 
									color="primary"
									size="sm"
									className=""
									disabled={this.state.selectedIds.length==0}
									onClick={this.toggleModalMulti}
									>
									  Move to Inventory							  
								</Button>
							</div>
														  
							<div className="mb-4 dropdown-edit">
								
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
								  </Colxx>
								  </FormGroup>			
								<ReactTable
								  data={data}
								  columns={dataTableColumns}
								  defaultPageSize={10}
								  minRows="5"
								  filterable={false}
								  showPageJump={false}
								  PaginationComponent={DataTablePagination}
								  showPageSizeOptions={true}
								  defaultSorted={[{id: "send_on",desc: true}]}
								/>
							</div>
							
					  </Colxx>
					</Row>
					
					<SendtoLab
						modalOpen={this.state.modalOpenSendToLab}
						toggleModal={this.toggleModalSendToLab}
						bucket_data={this.state.bucket_data}
						dataListRender={this.dataListRender}
					/>
					
					<AddResult
						modalOpen={this.state.modalOpenAddResult}
						toggleModal={this.toggleModalAddResult}
						bucket_data={this.state.bucket_data}
						dataListRender={this.dataListRender}
					/>
					
					<ViewTestResult
						modalOpen={this.state.modalOpenView}
						toggleModal={this.toggleModalView}
						bucket_data={this.state.bucket_data}
						dataListRender={this.dataListRender}
					/>
					
					<MoveToInventorySingle
						modalOpen={this.state.modalOpenSingle}
						toggleModal={this.toggleModalSingle}
						bucket_data={this.state.bucket_data}
						products={this.state.products}
						dataListRender={this.dataListRender}
					/> 
					
					<MoveToInventoryMulti
						modalOpen={this.state.modalOpenMulti}
						toggleModal={this.toggleModalMulti}						
						products={this.state.products}
						bucket_ids={this.state.selectedIds}
						dataListRender={this.dataListRender}
					/>
					
					
				</Card>
			</Fragment>
		);
	}
}
