import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row,Card,CardBody,
  CardTitle,DropdownToggle,
  CardSubtitle,ButtonDropdown,
  CardImg,
  CardText, FormGroup,Label,Input,
    DropdownItem,Badge,
    DropdownMenu,Button } from "reactstrap";
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import Rating from "../../components/common/Rating"
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";
import ThumbnailImage from "../../components/cards/ThumbnailImage"
import ThumbnailLetters from "../../components/cards/ThumbnailLetters"
import EditInformation from "../../containers/wholesale/EditInformation";
import SetRelationshipManager from "../../containers/wholesale/SetRelationshipManager";
import AddInternalNote from "../../containers/wholesale/AddInternalNote";
import EditVendorModal from "../../containers/wholesale/EditVendorModal";
import AddToGroup from "../../containers/wholesale/AddToGroup";
import ManageWorkflow from "../../containers/wholesale/ManageWorkflow";
import RemoveSectionInspection from "../../components/template/RemoveSectionInspection";
const selectSkills = [
    { label: "Hemp", value: "1", key: 0 },
    { label: "Security", value: "2", key: 1 }
  ];
class Details extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			 modalOpen: false,
			 modalOpenEdit: false,
			 modalOpenSet: false,
			 modalOpenInternal: false,
			 modalOpenGroup: false,
			 modalOpenProfile: false,
			 modalOpenWorkflow: false,
		  isOpenSizingSm: false,
		};
	}
	toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  };
  toggleModalEdit = () => {
    this.setState({
      modalOpenEdit: !this.state.modalOpenEdit
    });
	};
	toggleModalSet = () => {
    this.setState({
      modalOpenSet: !this.state.modalOpenSet
    });
	};
	toggleModalInternal = () => {
    this.setState({
      modalOpenInternal: !this.state.modalOpenInternal
    });
	};
	toggleModalGroup = () => {
    this.setState({
      modalOpenGroup: !this.state.modalOpenGroup
    });
	};
	toggleModalProfile = () => {
    this.setState({
      modalOpenProfile: !this.state.modalOpenProfile
    });
	};
	toggleModalWorkflow = () => {
    this.setState({
      modalOpenWorkflow: !this.state.modalOpenWorkflow
    });
	};
	
	handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      
    }
  }
  toggleSizingSm = () => {
    this.setState(prevState => ({
      isOpenSizingSm: !prevState.isOpenSizingSm
    }));
  };
	showReview = e =>{
		if(e.currentTarget.textContent==='Show Reviews'){
		 e.currentTarget.innerHTML='Hide Reviews';
		document.getElementById('show_review').style.display='block';
	}else{
		 e.currentTarget.innerHTML='Show Reviews';
		document.getElementById('show_review').style.display='none';		
	}		
	  }
  render() {
	 
    return (
      <Fragment>
			<Row>
				<Colxx lg="4">
					<Card className="d-flex flex-row mb-4">					
					<Row>
						<Colxx lg= "12">							
								<div className=" d-flex flex-grow-1 min-width-zero">
								<div className="position-relative">
								  <NavLink to="#" className="d-flex">
								  <ThumbnailImage top rounded src="/assets/img/profile-pic-l.jpg" alt="Card image cap" className="m-4" />
								</NavLink>
								  <Badge color="primary" pill className="position-absolute badge-top-left">VIP</Badge>
								  <Badge color="secondary" pill className="position-absolute badge-top-left-2"><i className="simple-icon-badge"/></Badge>            
								</div>
								<CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
								<div className="min-width-zero">                 
								<CardSubtitle className="truncate mb-1">Sarah Kortney</CardSubtitle>               
								<CardText className="text-muted text-small mb-2"><i className="simple-icon-location-pin p-0"/>{" "}california, CA</CardText>
								<Rating total={5} rating={0} onRate={rating => {}} /> 0 reviews<br/>
								<ButtonDropdown
								  className="p-2"
								  isOpen={this.state.isOpenSizingSm}
								  toggle={this.toggleSizingSm}
								>
								  <DropdownToggle caret size="xs" color="primary">Actions</DropdownToggle>
								  <DropdownMenu right>					
									<DropdownItem>
									  <NavLink to="#"  >
										Add review
									</NavLink>					
									</DropdownItem>					              					
									<DropdownItem>
									  <NavLink to="#" onClick={this.toggleModalGroup} >
										Add to group
									</NavLink>
									</DropdownItem>	
									<DropdownItem>
									  <NavLink to="#" >
										Remove partner
									</NavLink>
									</DropdownItem>	
									<DropdownItem>
									  <NavLink to="#" onClick={this.toggleModalProfile}>
										Edit profile information
									</NavLink>
									</DropdownItem>	
									<DropdownItem>
									  <NavLink to="#" onClick={this.toggleModalWorkflow}>
										Manage onboarding workflows
									</NavLink>
									</DropdownItem>								
								  </DropdownMenu>
								</ButtonDropdown>
								</div>
								<AddToGroup
										modalOpen={this.state.modalOpenGroup}
										toggleModal={this.toggleModalGroup}
									  />
								<EditVendorModal
										modalOpen={this.state.modalOpenProfile}
										toggleModal={this.toggleModalProfile}
									  />
								<ManageWorkflow
										modalOpen={this.state.modalOpenWorkflow}
										toggleModal={this.toggleModalWorkflow}
									  />					  
								</CardBody>
								</div>							
						</Colxx>
						
						<Colxx lg="12">						          
							<div className=" d-flex flex-grow-1 min-width-zero">			
							  <CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
								<div className="min-width-zero p-2">                 
								  <CardSubtitle className="truncate font-weight-bold mb-1">Relationship managers</CardSubtitle>
								  <CardText className="text-muted text-small mb-2">Alex <RemoveSectionInspection/>	</CardText>
								  <Button outline size="xs" className="mt-2" color="primary"onClick={this.toggleModalSet} >Set Relationship managers</Button>				  				  				  
								</div>					
							  </CardBody>
							</div>
							<SetRelationshipManager
									modalOpen={this.state.modalOpenSet}
									toggleModal={this.toggleModalSet}
								  />										  
						</Colxx>
					</Row>
					</Card>
				</Colxx>
				<hr/>
				<Colxx lg="8">
					<Card className="d-flex flex-row mb-4 p-4">   
					<Row>
						<Colxx lg="12">
							  
							<div className=" d-flex flex-grow-1 min-width-zero">			
							  <CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
								<div className="min-width-zero p-2">                                  
								  <Button outline size="xs" className="mt-2" color="primary" onClick={this.toggleModalInternal}>Internal Note</Button>				  				  				  
								</div>					
							  </CardBody>
							</div>
								<AddInternalNote
										modalOpen={this.state.modalOpenInternal}
										toggleModal={this.toggleModalInternal}
									  />										 
						</Colxx>						
						<Colxx lg="12">
							       
								<div className=" d-flex flex-grow-1 min-width-zero">			
								  <CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
									<div className="min-width-zero p-2">                                  
									  <CardSubtitle className="truncate font-weight-bold mb-1">Contact info</CardSubtitle>	
									  <CardText className="text-muted text-small mb-2"><i className="simple-icon-phone"/>{" "}8921317304</CardText>
									  <CardText className="text-muted text-small mb-2"><i className="simple-icon-location-pin"/>{" "}california, CA</CardText><br/>
									  
									</div>					
								  </CardBody>
								</div>			
							  	
						</Colxx>
						<Colxx lg="12">
							   
								<div className=" d-flex flex-grow-1 min-width-zero">			
								  <CardBody className=" pl-0 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
									<div className="min-width-zero p-2">                                  
									  <CardSubtitle className="truncate font-weight-bold mb-1">Groups</CardSubtitle>
										<CardText className="text-muted text-small mb-2">Web Desiging</CardText>
									</div>					
								  </CardBody>
								</div>			
							  	
						</Colxx>
						<Colxx lg="12" >											
								<div className="text-right mobile-template-options">
									<NavLink to="#" onClick={this.toggleModalEdit} className="btn btn-primary btn-sm btn-shadow">
											Edit		
									</NavLink>
								</div>
								<EditInformation
									modalOpen={this.state.modalOpenEdit}
									toggleModal={this.toggleModalEdit}
								  />
								<h3 className="font-weight-bold">Bank Information</h3>
								<Row className="mt-4">
									<Colxx xxs="4">
										
										  <span className="font-weight-bold">Bank Name</span>						  
										
									</Colxx>
									<Colxx xxs="4">
										
										  <span className="text-muted">Axis Bank</span>						  
										
									</Colxx>					
								</Row>				  
						
						</Colxx>
						<Colxx lg="12" >											
								<div className="text-left mobile-template-options">
									<Button outline size="xs" className="mt-2 ml-4" color="primary" onClick={(e) => this.showReview(e)}>Show Reviews</Button>		
								</div>
								
								<AvForm
									className="av-tooltip tooltip-right-bottom"
									onSubmit={this.handleSubmit}
								  >
								<Row className="mt-4" id="show_review" style={{display:'none'}}>
									<Row className="p-4">
										<Colxx lg="6">
										
											<AvGroup className="error-t-negative">
											  <Label>Write Reviews</Label>
											  <AvField
												id="review_comment"
												name="review_comment"
												type="textarea"
												rows="3"
											  />
											</AvGroup>
										</Colxx>
									</Row>
									<Row className="mt-4">
										<Colxx lg="6">
											<span className="font-weight-bold">Related request</span><br/>
											<span className="text-muted">No request for the vendor</span>							 
										</Colxx>
										<Colxx lg="6">
											<Label >Skills or services required</Label>
										 <Select
											components={{ Input: CustomSelectInput }}
											className="react-select"
											classNamePrefix="react-select"
											isMulti
											id="select2-state_id-container"
											value={this.state.selectedOptions}
											onChange={this.handleChangeMulti}
											options={selectSkills}
										/>
										</Colxx>								
									</Row>
									<Row className="mt-4 ">
										<Colxx lg="3">
											<span className="font-weight-bold">Quality</span><br/>
											<Rating total={5} rating={0} onRate={rating => {}} />							 
										</Colxx>
										<Colxx lg="3">
											<span className="font-weight-bold">Delivery</span><br/>
											<Rating total={5} rating={0} onRate={rating => {}} />							 
										</Colxx>
										<Colxx lg="3">
											<span className="font-weight-bold">Value for money</span><br/>
											<Rating total={5} rating={0} onRate={rating => {}} />							 
										</Colxx>
										<Colxx lg="3" className="mb-2">
											<span className="font-weight-bold">Recommended</span><br/>
											<i className="iconsminds-like"/>&nbsp;<i className="iconsminds-unlike"/>
										</Colxx>	
									</Row>
									<Row>
										<Colxx lg="6" className="mb-2 mt-2">								
											<NavLink to="#" className="btn btn-primary btn-xs btn-shadow">
											POST PRIVATE FEEDBACK		
											</NavLink>
										</Colxx>
										<Colxx lg="6" className="mb-2 mt-2">								
											<span className="font-weight-bold">Your feedback will only be visible to co-workers</span><br/>
										</Colxx>
									</Row>
								</Row>
							</AvForm>											  
						</Colxx>					
					</Row>
					</Card>
				</Colxx>
			</Row>
      </Fragment>
    );
  }
}

export default Details;
