import React, { Fragment, Component } from "react";
import {
    Row, Container
} from "reactstrap";
import { Colxx } from "../CustomBootstrap";
import { NavLink } from "react-router-dom";

function GetImage(img) {
    return (
        <img src={img} className='sectionImg' />
    )
}

class HomePageSection extends Component {
    constructor(props) {
        super(props);
    }

    render() {


        return (
            <div style={{ background: this.props.colorcbd ? '#f2dfc9' : 'transparent' }}>
                <Container classname='container-xl container-page base-section ' >
                    <Row className={`${this.props.class} base-section`} style={{ flexDirection: this.props.textLeft ? 'row' : 'row-reverse' }}>
                        <Colxx xxs="12" md='7' className='d-flex align-items-start'>
                            {this.props.children}
                        </Colxx>
                        <Colxx xxs="12" md='5'>
                            {
                                this.props.customImgclass ?
                                    <div className={`${this.props.customImgclass} aboutImg-container`} style={{ background: `url(${this.props.img})` }} />
                                    :
                                    <div className='aboutImg-container' style={{ background: `url(${this.props.img})` }} />
                            }

                        </Colxx>
                    </Row>
                </Container>
            </div>
        );
    }
};

export default HomePageSection;
