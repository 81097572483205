import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, CardImg, Badge, DropdownItem, DropdownMenu, } from "reactstrap";
import queryString from 'query-string';
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";

import DataTablePagination from "../../components/DatatablePagination";
import ReactTable from "react-table";

import { REST_API_END_POINT,themeColor } from "../../constants/defaultValues";
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import { NotificationManager } from "../../components/common/react-notifications";
import ItemDropdown from "../../components/customer/ItemDropdown";

import AddModal from "../../containers/admin/discounts/AddModal";
import EditModal from "../../containers/admin/discounts/EditModal";

export default class Discounts extends Component {
	
	constructor(props) {
		super(props);
		
		this.toggleModal=this.toggleModal.bind(this);
		this.toggleModalEdit=this.toggleModalEdit.bind(this);
		this.dataListRender=this.dataListRender.bind(this);
		this.activateRow=this.activateRow.bind(this);
		this.state = {
			blocking: false,
			modalOpen: false,
			modalOpenEdit: false,
			discounts: [],
			discount_id:0
		};
	}
	
	componentDidMount() {
		this.dataListRender();	
	}
	
	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen
		});
	};
	
	toggleModalEdit = (e,discount_id=0) => {
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
			discount_id
		});
	};
	
	activateRow = (row_id,status) => {
		var formData = new FormData();
		formData.append('id',row_id);
		var msg="";
		if(status==1){
			formData.append('status',0);
			msg="You want to deactivate this discount!"
		}else if(status==2){
			formData.append('status',2);
			msg="You want to delete this discount!"
		}else{
			formData.append('status',1);
			msg="You want to activate this discount!"			
		}
		swal({
		  title: "Are you sure?",
		  text: msg,
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then((willDelete) => {
		  if (willDelete) {
			axios.post(REST_API_END_POINT+'admin/delete-discount',formData).then((data)=>{ 
				if(data.data.status==1){
					this.dataListRender();
					if(status==1){
						swal("Success!", "The discount has been deactivated successfully!", "success");
					}else if(status==2){
						swal("Success!", "The discount has been deleted successfully!", "success");
					}else{
						swal("Success!", "The discount has been activated successfully!", "success");
					}								
				}else{
					if(status==1){
						swal("Failed to deactivate discount!", "Please try after sometime.", "error");
					}else if(status==2){
						swal("Failed to delete discount!", "Please try after sometime.", "error");
					}else{
						swal("Failed to activate discount!", "Please try after sometime.", "error");
					}		
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		  } else {
			swal("You have canceled the operation!");
		  }
		});

		
	};
	
	dataListRender = () => {
		this.setState({
			blocking:true
		});
		axios.get(REST_API_END_POINT+'admin/discounts').then((res)=>{ 
			if(res.data.status==1){
				var discounts = res.data.data;
				this.setState({
					discounts,
					blocking:false
				});
			}else{
				this.setState({
					blocking:false
				});
			}			
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
			NotificationManager.error("Failed to load the discounts! Please check your internet connection.","",2000,null,null,"filled");
			this.setState({
				blocking:false
			});
		});
		
	};
	  
	 
		
	render() {
		let data = this.state.discounts;
				
		const dataTableColumns = [
			{
				Header: "#",
				Cell: props => {
					return data.findIndex(r=>{ return r.id==props.original.id })+1
				}
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Discount Code",
				accessor: "discount_code",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Start Date",
				accessor: "start_date",
				Cell: props => typeof props.value!='undefined' && props.value!=null ? moment(props.value*1000).format("MM/DD/YYYY"):""
			},
			{
				Header: "End Date",
				accessor: "end_date",
				Cell: props => typeof props.value!='undefined' && props.value!=null ? moment(props.value*1000).format("MM/DD/YYYY"):""
			},
			{
				Header: "Discount Amount",
				accessor: "amount",
				Cell: props => "$"+props.value
			},
			{
				Header: "Discount Type",
				accessor: "apply_type",
				Cell: props =>{ if(parseInt(props.value)==1){ return "On First payment" }else{ return "Lifetime" } }
			},
			/*{
				Header: "Auto Apply",
				accessor: "auto_apply",
				Cell: props =>{ if(props.value){ return "Yes" }else{ return "No" } }
			},*/
			{
				Header: "Status",
				accessor: "status",
				Cell: props =><Badge
								  color={parseInt(props.value)==1?"success":"danger"}
								  pill
								  className="badge-top-left"
								  style={{fontSize:'90%'}}
								>
								  {parseInt(props.value)===1?"Active":"Inactive"}
								</Badge>
			},
			{
				Header: "Actions",
				accessor: "id",
				Cell: props => <div className="action-btns">
				  <ItemDropdown item={<DropdownMenu right>
					<DropdownItem onClick={(e) => this.toggleModalEdit(e,props.value)}>
						<i className="simple-icon-pencil" />{" "}<span className="d-inline-block">Edit</span>
					</DropdownItem>
					<DropdownItem onClick={() => this.activateRow(props.value,props.original.status)}>
						<i className={props.original.status==0?"simple-icon-check":"simple-icon-ban"} />{" "}<span className="d-inline-block">{props.original.status==0?"Activate":"Deactivate"}</span>
					</DropdownItem>
					<DropdownItem onClick={() => this.activateRow(props.value,2)}>
					 	<i className="simple-icon-trash" />{" "}<span className="d-inline-block">Delete</span>
					</DropdownItem>
				  </DropdownMenu>} />
              </div>
			}
		];	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.discounts" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row className="mb-4">
										<Colxx lg="12">
											<Button
												color="primary"
												className="float-right"
												onClick={this.toggleModal}
											  >
												Add Discount
											</Button>
										</Colxx>
									</Row>
									<Row>			  
										<Colxx xxs="12">
											<div className="mb-4 dropdown-edit">					
												<ReactTable
													data={data}
													columns={dataTableColumns}
													defaultPageSize={10}
													filterable={false}
													showPageJump={false}
													PaginationComponent={DataTablePagination}
													showPageSizeOptions={true}
													defaultSorted={[{id: "x",asc: true}]}
												/>
											</div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
					<AddModal
						modalOpen={this.state.modalOpen}
						toggleModal={this.toggleModal}
						dataListRender={this.dataListRender}
					/>
					<EditModal
						modalOpen={this.state.modalOpenEdit}
						toggleModal={this.toggleModalEdit}
						dataListRender={this.dataListRender}
						discount_id={this.state.discount_id}
					/>
				</BlockUi>
			</Fragment>
		);
	}
}
