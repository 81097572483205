import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Card, CardBody, CardTitle,Button, CardSubtitle,Label,Table, ButtonDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, ButtonGroup, Input, Media } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import AddTaskModal from "../../../containers/myjobs/AddTaskModal";
import EditTaskModal from "../../../containers/myjobs/EditTaskModal";
import AddFileModal from "../../../containers/myjobs/AddFileModal";
import AddMemberModal from "../../../containers/myjobs/AddMemberModal";
import EditJobModal from "../../../containers/myjobs/EditJobModal";

import IntlMessages from "../../../helpers/IntlMessages";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import swal from 'sweetalert';
import {REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, REST_API_BASE_URL} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";

import {SliderTooltip} from "../../../components/common/SliderTooltips";

const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"],
    ["clean"]
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image"
];

const selectProduct = [
    { label: "New", value: "1", key: 0 },
    { label: "In Progress", value: "2", key: 1 },
    { label: "Completed", value: "3", key: 2 },
    { label: "On Hold", value: "4", key: 3 },
    { label: "Canceled", value: "5", key: 3 },
];

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class ViewJob extends Component {
	
	constructor(props) {
		super(props);	   
		this.state = {
			textQuillBubble: "",
			textQuillStandart: "",
			activeFirstTab: "1",		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenStaff: false,
			dropdownBasicOpen: false,
			search: '',
			members:[],
			task_members:[],
			job_tasks:[],
			messages:[],
			activities:[],
			user_privileges:[],
		};
		
		this.dataListRender = this.dataListRender.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.deleteItem = this.deleteItem.bind(this);
		this.deleteMember = this.deleteMember.bind(this);
		this.deleteMessage = this.deleteMessage.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.setRemainder = this.setRemainder.bind(this);
		this.updateJob = this.updateJob.bind(this);
	}
	
	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
	
	dataListRender() {		   
		var formData = new FormData();
		let task;
		var members=[];	
		var job_tasks=[];	
		var messages=[];	
		var files=[];
		var activities=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('task_id',this.props.match.params.task_id);
		
		axios.post(REST_API_END_POINT+'task/view-task',formData).then((res)=>{
			var data = res.data;
			members=data.members;
			
			members = members.map((element,index)=>{
				element.label=element.username;
				element.value=element.userid;
				element.key=index;
				return element;
			});
			
			var task = data.task;
			
			var startDate = moment(task.start_date*1000);
			var endDate = moment(task.due_date*1000);
		   
			if(data.task){
				this.setState({
					task: data.task,
					startDate,
					endDate,
					members:members,
					task_members:data.task_members,
					job_tasks:data.job_tasks,
					messages:data.messages,
					files:data.files,
					activities:data.activities,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					task: [],
					members:members,
					job_tasks:job_tasks,
					messages:messages,
					files:files,
					activities:activities,
					selectAll:0,
					selectedIds:[]
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
	
	handleChangeDate = date => {
		if(this.state.endDate!=null){
			if(date.unix()>this.state.endDate.unix()){
				swal("Warning!", "The start date should be less than or equal to end date!", "warning");				
				return;
			}
			this.setState({
				startDate: date
			});
		}else{
			this.setState({
				startDate: date
			});
		}
	}

	handleChangeEndDate = date => {
		if(this.state.startDate!=null){
			if(date.unix()<this.state.endDate.unix()){
				swal("Warning!", "The end date should be greater than or equal to start date!", "warning");				
				return;
			}
			this.setState({
				endDate: date
			});
		}else{
			this.setState({
				endDate: date
			});
		}
	}

	toggleBasic = () => {
        this.setState(prevState => ({
            dropdownBasicOpen: !prevState.dropdownBasicOpen
        }));
    }  

	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	}

	toggleModalAddMember = (e,task_id=0) => {
		this.setState({
			task_id: parseInt(task_id),
			modalOpenAddMember: !this.state.modalOpenAddMember
		});
	}

	toggleModalAddTask = (e,task_id=0) => {
		this.setState({
			task_id: parseInt(task_id),
			modalOpenAddTask: !this.state.modalOpenAddTask
		});
	}

	toggleModalAddFile = (e,task_id=0) => {
		this.setState({
			task_id: parseInt(task_id),
			modalOpenAddFile: !this.state.modalOpenAddFile
		});
	}

	toggleModalEditTask = (e,ID=0) => {
		this.setState({
			ID: parseInt(ID),
			modalOpenEditTask: !this.state.modalOpenEditTask,
		});
	};
	
	toggleModalEdit = (e,task_id=0) => {
		this.setState({
			task_id: parseInt(task_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {	
			if(this.state.textQuillStandart==""){
				swal("Warning!","Please enter a message","warning");
				return;
			}
			document.getElementById("add-message").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("messageForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('task_id',this.props.match.params.task_id);
			formData.append('message',this.state.textQuillStandart);

			axios.post(REST_API_END_POINT+'task/add-message',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-message").disabled=false;
				this.setState({ textQuillStandart:"" })
				this.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-message").disabled=false;
			});

		}
	}	
	
	updateJob() {
		var user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var formData = new FormData(document.getElementById("messageForm"));
		formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		formData.append('task_id',this.props.match.params.task_id);
		
		var start_date = this.state.startDate;
		var due_date = this.state.endDate;
		var complete = this.state.task.complete
		var sync = this.state.task.complete_sync;
		
		formData.append('start_date',moment(start_date).format("YYYY-MM-DD HH:mm"));
		formData.append('due_date',moment(due_date).format("YYYY-MM-DD HH:mm"));
		formData.append('complete',complete);
		formData.append('sync',sync);
		
		axios.post(REST_API_END_POINT+'task/update-details',formData).then((res)=>{
			var data = res.data;
			
			var api_message=document.getElementById("api_message");
			api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
			
			window.setTimeout(function() {
				JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
					JQUERY_MODULE.$(this).remove();
				});
			}, 5000);
			
			JQUERY_MODULE.$('.alert .close').on("click", function(e){
				JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
					JQUERY_MODULE.$(this).remove();
				});
			});
						
			this.dataListRender();

		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}	

	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="job";
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-job").disabled=true;
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('status',status);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('task_id',this.props.match.params.task_id );
				axios.post(REST_API_END_POINT+'task/delete-task',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("delete-job").disabled=false;
					
					if(data.status==1){
						swal("Success!",success_msg,"success").then(()=>{
							document.getElementById("job-back-btn").click();
						})
					}else{
						swal("Warning!",failure_msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("delete-job").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	

	deleteMember = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="member";
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
					
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('status',status);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('task_id',this.props.match.params.task_id );
				formData.append('target_user_id',selectedIds );
				
				axios.post(REST_API_END_POINT+'task/remove-member',formData).then((res)=>{
					var data = res.data;

					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("delete-member").disabled=false;
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	

	deleteMessage = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="message";
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {

				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('status',status);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('id',selectedIds);
				formData.append('task_id',this.props.match.params.task_id);
				
				axios.post(REST_API_END_POINT+'task/delete-message',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}

	deleteTask = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="job";
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {

				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('status',status);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('id',selectedIds);
				
				axios.post(REST_API_END_POINT+'task/delete-job-task',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});

			}else{
				swal("Your operation is canceled!");
			}
		});
	}

	deleteFile = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="file";
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('status',status);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('id',selectedIds);
				formData.append('task_id',this.props.match.params.task_id);
				
				axios.post(REST_API_END_POINT+'task/remove-file',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}

	setRemainder = (e,selectedIds,status)  => {
		var msg_word="remainder";
		
		var msg="Send the "+msg_word+" ?";
		var success_msg="The "+msg_word+" has been sent.";
		var failure_msg="Failed to send "+msg_word;
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('status',status);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('id',selectedIds);
				
				axios.post(REST_API_END_POINT+'task/remind-user',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
		
	markComplete = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="status";
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Marked as "+msg_word+" ?";
            success_msg="The "+msg_word+" has been marked.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('status',status);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('id',selectedIds);
				
				axios.post(REST_API_END_POINT+'task/complete-job-task',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	changeStatus= (status)  => {		
		var user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		
		formData.append('status',status);
		formData.append('user_id',user_id);
		formData.append('facility_id',facility_id);
		formData.append('task_id',this.props.match.params.task_id);					

		axios.post(REST_API_END_POINT+'task/change-task-status',formData).then((res)=>{
			var data = res.data;
			
			var api_message=document.getElementById("api_message");
			if(data.status==1){
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
			}/*else{
				api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
			}*/
			
			window.setTimeout(function() {
				JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
					JQUERY_MODULE.$(this).remove(); 
				});
			}, 5000);
			this.dataListRender();
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
	

	render() {
		var task_members=this.state.task_members;
		var job_tasks=this.state.job_tasks;
		var messages=this.state.messages;
		var files=this.state.files;
		var activities=this.state.activities;
		
		var task = this.state.task;
		let data = this.state.data;
		
		const dataTableColumns = [
					{  
						Header: 'Name',  
						accessor: 'name'  
					},
					{  
						Header: 'Age',  
						accessor: 'age'  
					}
				];

    return (<Fragment>
        <Row>
			<Colxx xxs="12" className="mb-4">
				<Breadcrumb heading="menu.view-job" match={this.props.match}/>
				<hr/>
				
				<div className="float-sm-left">
					{" "}<Button
						  color="primary"
						  size="sm"
						  id="job-back-btn"
						  onClick={this.props.history.goBack}
						> 
						Back
					</Button>
				</div>		
			</Colxx>
        </Row>
		
		<Row className=" ">
			<Colxx xxs="12">
				<div id="api_message"></div>
			</Colxx>
		</Row>
		<Row className=" ">
			<Colxx xxs="8" className="mb-4">		  
				<Card>
					<CardBody className="text-center px-3 py-3">
						{this.state.user_privileges.indexOf(165)!==-1 ? <div className="float-sm-right ">
							<ButtonGroup className="sm-responsive-btn">
								<ButtonDropdown
									isOpen={this.state.dropdownBasicOpen}
									toggle={this.toggleBasic}
								> 
									<DropdownToggle caret size="sm" color={task ? ((task.status_class!=null)?task.status_class:'primary'):'primary'}>{task ? ((task.status_text!=null)?task.status_text:''):''}</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={()=>{this.changeStatus(1)}}>New</DropdownItem>
										<DropdownItem onClick={()=>{this.changeStatus(2)}}>In Progress</DropdownItem>
										<DropdownItem onClick={()=>{this.changeStatus(3)}}>Completed</DropdownItem >
										<DropdownItem onClick={()=>{this.changeStatus(4)}}>On Hold</DropdownItem>
										<DropdownItem onClick={()=>{this.changeStatus(5)}}>Cancelled</DropdownItem>
									</DropdownMenu>
								</ButtonDropdown>
								
								{" "}<Button
								  color="primary"
								  size="sm"
								  className=""
								  onClick={(e)=> this.toggleModalEdit(e,this.props.match.params.task_id) }
								>
									Edit Job
								</Button>
								<Button
									id="delete-job"
									color="danger"
									size="sm"
									className=""
									onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 
									>Delete Job 							  
								</Button>
								
							</ButtonGroup>			
						</div>:""}
				
						<h2 className="text-left mb-4 font-weight-semibold">{task ? ((task.name!=null)?task.name:''):''}</h2>
						<hr/>		
						<div className="text-left font-weight-semibold mb-0" dangerouslySetInnerHTML={{ __html: task ? ((task.description!=null)?task.description:''):'' }} ></div>
			  
					</CardBody>
				</Card>
				
				<Card className="mt-4" >
					<CardBody className="text-center px-3 py-3">
						{this.state.user_privileges.indexOf(165)!==-1 ? <div className="float-sm-right">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={(e)=> this.toggleModalAddTask(e,this.props.match.params.task_id) }
								  > Add Task 							  
							</Button>
						</div>:""}
						
						<h2 className="text-left mb-4 font-weight-semibold">Job Tasks</h2>
						<hr/>		
						{job_tasks ? job_tasks.map((row,index)=>{ return( <div className="text-left font-weight-semibold mb-0" key={index}>								
							<div className="float-sm-right">
								{" "}{row.complete!=1 ? <Button
									  color="primary"
									  size="xs"
									  className=""
									  onClick={event =>this.markComplete(event,row.ID,2)} 
									> Mark Complete						  
								</Button>:""}{" "}{this.state.user_privileges.indexOf(165)!==-1 ? <Fragment><Button
									  color="warning"
									  size="xs"
									  className=""
									  onClick={(e)=> this.toggleModalEditTask(e,row.ID) }
									> Edit							  
								</Button>{" "}<Button
									  color="danger"
									  size="xs"
									  className=""
									  onClick={event =>this.deleteTask(event,row.ID,2)} 
									>Delete							  
								</Button></Fragment>:""}
							</div>
							<h4>{row.title}{row.complete==1 ? <span className="completeText" style={{color: "#46b912"}} > <span className="simple-icon-check"></span> COMPLETED</span>:""}</h4>								
							<div dangerouslySetInnerHTML={{ __html: row.description }} ></div>
							
							{row.objective_members.length ? <div className="task-members-wrapper">
								<strong className="d-block">Assigned Users:</strong>
								{row.objective_members.map((r,index)=>{
									return(<Media key={index}>
										<Media left className="mr-2">
											<Media object width="50" title={r.username} src={REST_API_BASE_URL+"uploads/users/"+r.profile_pic} alt={r.username} />										
											{r.active=="N" ? <div className="offline-dot-user" title="Offline"></div>:<div className="online-dot-user" title="Online"></div>}
										</Media>
									</Media>)
								})}
							</div>:""}
							<hr/>
						</div>)}):""}
					</CardBody>
				</Card>
				
				<Card className="mt-4" >
					<CardBody className="px-3 py-3 task-members-wrapper">	
						<h2 className="text-left mb-4 font-weight-semibold">Job Messages</h2>
						<hr/>	
						{messages ? messages.map((row,index)=>{
							return(<div key={index}><Media >
								<Media left className="mr-2">
									<Media object width="50" title={row.username} src={REST_API_BASE_URL+"uploads/users/"+row.profile_pic} alt={row.username} />										
									{row.active=="N" ? <div className="offline-dot-user" title="Offline"></div>:<div className="online-dot-user" title="Online"></div>}
								</Media>
								<Media body className="mr-2">
									<div className="float-sm-right ">
										{" "}<Button																		 
											  color="danger"
											  size="sm"
											  className=""
											  onClick={event =>this.deleteMessage(event,row.ID,2)} 
											> Delete					  
										</Button>  
									</div>
									
									<div dangerouslySetInnerHTML={{ __html: row.message }} ></div>
									Posted on {moment(row.timestamp*1000).format('MM/DD/YYYY HH:mm')}
								</Media>
							</Media><hr/></div>)}):""}
						<AvForm
							id="messageForm"
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						> 	
							<h4>Add Message To Job</h4>
							<ReactQuill
								theme="snow"
								value={this.state.textQuillStandart}
								onChange={this.handleChangeQuillStandart}
								modules={quillModules}
								formats={quillFormats}
							/>
							<div className="mt-3">
								<Button color="primary" id="add-message" >
									<IntlMessages id="customer.add" />
								</Button>
							</div>
						</AvForm>
					</CardBody>
				</Card>
				
				<Card className="mt-4 d-none" >
					<CardBody className="px-3 py-3">
						{this.state.user_privileges.indexOf(165)!==-1 ? <div className="float-sm-right mb-6 ">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={this.toggleModalAdd}
								>Add Sheet 							  
							</Button>
						</div>:""}
						
						<h2 className="text-left mb-4 font-weight-semibold">Job Sheet</h2>
						<hr/>			  
					</CardBody>
				</Card>
				
			</Colxx>
			
			<Colxx  xxs="4  mb-4">		  
				<Card >
					<CardBody className="px-3 py-3">
						{this.state.user_privileges.indexOf(165)!==-1 ? <div className="float-sm-right mb-0 ">
							{" "}<Button																		 
									color="primary"
									size="sm"
									className=""
									onClick={this.updateJob}
								>Update						  
							</Button>
						</div>:""}
						
						<h2 className="text-left mb-4 font-weight-semibold">Job Details</h2>
						<hr/>
						
						<div>
							<Label>Start Date<span className="text-danger">*</span></Label>
							<DatePicker
								id="start_date"
								name="start_date"
								placeholder=""
								className="mb-3"
								autoComplete="off"
								selected={this.state.startDate}
								onChange={this.handleChangeDate}
								placeholderText=""
								dateFormat="MM/DD/YYYY"
							/>
						</div>
						<div>
							<Label>End Date<span className="text-danger">*</span></Label>
							<DatePicker
								id="end_date"
								name="end_date"
								autoComplete="off"
								placeholder=""
								className="mb-3"
								selected={this.state.endDate}
								onChange={this.handleChangeEndDate}
								placeholderText=""
								dateFormat="MM/DD/YYYY"
							/>
						</div>				  
						<div>
							Completion {task ? task.complete:0}%
							<Row>       
								<Colxx  lg="10">
									<SliderTooltip
										min={0}
										max={100}
										value={task ? task.complete:0}
										className="mb-2"
										onChange={(complete)=>{
											var task = this.state.task;
											if(task.complete_sync!=1){
												task.complete=complete;
											}
											this.setState({ task });
										}}
									/>
									<div className="ml-5">
									{task ? <Input 
										type="checkbox" 
										name="sync" 
										checked={task.complete_sync==1} 
										value="1" 
										onChange={(complete)=>{
											var task = this.state.task;
											if(task.complete_sync!=1){
												task.complete_sync=1;
											}else{
												task.complete_sync=0;
											}
											this.setState({ task });
										}}
									/>:''} Sync With Objectives
									</div>
								</Colxx>
							</Row>
						</div>
					</CardBody>
				</Card>
				
				<Card className="mt-4" >
					<CardBody className="px-3 py-3">		
						{this.state.user_privileges.indexOf(165)!==-1 ? <div className="float-sm-right mb-6 ">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={(e)=> this.toggleModalAddMember(e,this.props.match.params.task_id) }
								>Add Member							  
							</Button>
						</div>:""}
						
						<h2 className="text-left mb-4 font-weight-semibold">Job Members</h2>
						<hr/>
						
						<Table responsive>
							<thead>
								<tr>
									<th>User</th>
									<th>Options</th>							 
								</tr>
							</thead>
							
							<tbody>
								{task_members ? task_members.map((row,index)=>{ 
									return(<tr key={index}>
									  <td>{row.username}</td>
									  <td><Button
											  color="warning"
											  size="xs"
											  title="Remind user of this task (sends notification!)"
											  onClick={event =>this.setRemainder(event,row.ID,2)} 
											><span className="simple-icon-paper-plane"></span> 							  
										</Button>{" "}<Button						
											  color="danger"
											  size="xs"
											  className=""
											  onClick={event =>this.deleteMember(event,row.userid,2)} 
											>Remove 							  
										</Button></td>
								</tr>)}):""}						
							</tbody>
							
						</Table>
					</CardBody>
				</Card>
				
				<Card className="mt-4" >
					<CardBody className="px-3 py-3">
						{this.state.user_privileges.indexOf(165)!==-1 ? <div className="float-sm-right mb-0 ">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={(e)=> this.toggleModalAddFile(e,this.props.match.params.task_id) }
								>Attach File							  
							</Button>
						</div>:""}
						
						<h2 className="text-left mb-4 font-weight-semibold">Files</h2>
						<hr/>
				
						<Table responsive>
							<thead>
								<tr>
									<th>File</th>
									<th>Type</th>
									<th>Options</th>
								</tr>
							</thead>
							<tbody>
							{files ? files.map((row,index)=>{ 
								return(<tr key={index}>
									<td><a href={REST_API_BASE_URL+"uploads/tasks/"+row.upload_file_name} target="_blank" className="btn-link" >{row.upload_file_name}</a></td>
									<td>{row.file_type}</td>
									<td>
										<Button
											color="primary"
											size="xs"
											className=""
											onClick={event =>this.deleteFile(event,row.ID,2)} 
											>Remove							  
										</Button>
									</td>
							   </tr>)}):""}
							</tbody>
						</Table>
					</CardBody>
				</Card>
				
				<Card className="mt-4" >
					<CardBody className="px-3 py-3 task-members-wrapper">
						<div className="float-sm-right mb-0 ">					  	
							<NavLink to={"/app/jobs/task-activities/"+this.props.match.params.task_id} className="mb-2 btn btn-primary btn-xs" >
								All Activities
							</NavLink>{" "}
						</div>
						
						<h2 className="text-left mb-4 font-weight-semibold">Activity log</h2>
						<hr/>
						
						<Table responsive className="mb-0">
							<thead>
								<tr>
									<th>User</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{activities ? activities.map((row,index)=>{ 
									return(<tr key={index}>
										<td>
											<Media >
												<Media left className="mr-2">
													<Media object width="50" title={row.username} src={REST_API_BASE_URL+"uploads/users/"+row.profile_pic} alt={row.username} />										
													{row.active=="N" ? <div className="offline-dot-user" title="Offline"></div>:<div className="online-dot-user" title="Online"></div>}
												</Media>
											</Media>
										</td>
										<td><strong><i>{row.username}</i></strong> <div className="d-inline" dangerouslySetInnerHTML={{ __html: row.message }} ></div><p className="small-text"><i>{moment(row.timestamp*1000).format("MM/DD/YYYY HH:mm")}</i></p></td>
							   </tr>)}):""}
							</tbody>
						</Table>
					</CardBody>
				</Card>
			
			</Colxx>		
		</Row>
			   
		  
			<AddTaskModal
				modalOpen={this.state.modalOpenAddTask}
				toggleModal={this.toggleModalAddTask}
				dataListRender={this.dataListRender}
				task_id={this.state.task_id}
				task_members={this.state.task_members}
			/>
			  
			{this.state.modalOpenEditTask ? <EditTaskModal
				modalOpen={this.state.modalOpenEditTask}
				toggleModal={this.toggleModalEditTask}
				dataListRender={this.dataListRender}
				ID={this.state.ID}
				task_members={this.state.task_members}
			/>:""}
			
		   <EditJobModal
				modalOpen={this.state.modalOpenEdit}
				toggleModal={this.toggleModalEdit}
				dataListRender={this.dataListRender}
				task_id={this.state.task_id}
			/>
			
			<AddMemberModal
				modalOpen={this.state.modalOpenAddMember}
				toggleModal={this.toggleModalAddMember}
				dataListRender={this.dataListRender}
				members={this.state.members}
				task_id={this.state.task_id}
			/>
			
            <AddFileModal
				modalOpen={this.state.modalOpenAddFile}
				toggleModal={this.toggleModalAddFile}
				dataListRender={this.dataListRender}
				task_id={this.state.task_id}
			/>
		
      </Fragment>
    );
  }
}

export default injectIntl(ViewJob);