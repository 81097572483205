import React, { Component } from "react";
import { Row, Card, CardTitle, Label, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Formik, Form, Field } from "formik";
import swal from 'sweetalert';

import { projectTitle,REST_API_END_POINT,dataEncryptKey,BASE_URL } from "../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { seo } from "../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			configurations: {
				facility_footer_logo:null,
				facility_logo:null
			},
		};
		this.validate = this.validate.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		seo({
			title: 'Reset Password | '+projectTitle,
		});
	}
  
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id!=null){
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{
				this.setState({ configurations: res.data.configurations, blocking:false });
			}).catch(e =>{ 
				console.log("Addition failed, Error ", e) 
				this.setState({ blocking:false });
			});
		}
	}
	
	handleSubmit(values) {
		//console.log(values);
		var form_data = new FormData();
		form_data.append("reset_code",this.props.match.params.reset_code);
		form_data.append("new_password",values.new_password);
		form_data.append("confirm_password",values.confirm_password);
		
		document.getElementById("reset-password-button").disabled = true;
		axios.post(REST_API_END_POINT+'register/reset-password',form_data).then((data)=>{ 
			console.log(data.data);
			if(data.data.no_user){
				swal("Link Expired!", "Please generate a new link to reset password.", "error");
			}else if(data.data.email_send){
				document.getElementById("reset-new-password").reset();			
				swal("Password has been reset!", "your password has been successfully updated.", "success");
			}else{
				swal("Failed to send email!", "Please try after sometime.", "error");
			}
			document.getElementsByClassName("swal-button--confirm")[0].removeEventListener("click", function(){
				window.location="/user/login";
			});
			document.getElementsByClassName("swal-button--confirm")[0].addEventListener("click", function(){
				window.location="/user/login";
			});
			document.getElementById("reset-password-button").disabled = false;
		}).catch(e => console.log("Addition failed , Error ", e));
	}
  
	validate(values) {
        let errors = {};

        if (!values.new_password) {
            errors.new_password = 'Please enter a strong password';
        }else if (!/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/i.test(values.new_password)) {
            errors.new_password = 'Password must contain atleast one uppercase letter, one lowercase letter, one digit and any of the following special characters !, @, #, $, & and atleast 8 characters long';
        }
		
        if (!values.confirm_password) {
            errors.confirm_password = 'Please enter confirm password';
        } else if (values.new_password!==values.confirm_password) {
            errors.confirm_password = 'The new password and confirm password must be the same';
        }
        return errors;
    }
	
	render() {
		const { configurations } = this.state;
		return (
		  <Row className="h-100">
			<Colxx xxs="12" md="9" lg="7" className="mx-auto my-auto">
			  <Card className="auth-card">
				<div className="form-side">
					<div className="d-block text-center">
					  <NavLink to={`/`} className="white">
						  {configurations.facility_logo==null ? <span className="logo-single" />:<img src={BASE_URL+'pages/'+configurations.facility_logo} className="img-responsive mb-3" style={{maxHeight:'60px'}}  />}
					  </NavLink>
					</div>
				  <CardTitle className="mb-4">
					<IntlMessages id="user.reset-password" />
				  </CardTitle>
				  <p className="mb-4">
					Please enter your new password.                
				  </p>
				  <Formik
					validate={this.validate}
					initialValues={{
					  password: "",
					  confirm_password: ""
					}}
					onSubmit={this.handleSubmit}
				  >
					{({ errors, touched,isValidating }) => (
					<Form className="av-tooltip tooltip-right-bottom" id="reset-new-password">
						<Label className="form-group has-float-label mb-4">
						  <Field name="new_password"  className="form-control" type="password" />
						  {errors.new_password && touched.new_password && <div className="invalid-feedback d-block">{errors.new_password}</div>}
						  <IntlMessages id="user.new_password" />
						</Label>
						
						<Label className="form-group has-float-label mb-4">
						  <Field name="confirm_password"  className="form-control" type="password" />
						  {errors.confirm_password && touched.confirm_password && <div className="invalid-feedback d-block">{errors.confirm_password}</div>}
						  <IntlMessages id="user.confirm_password" />
						</Label>

						<div className="d-flex justify-content-center align-items-center">
						  <Button						
							color="primary"
							className="btn-shadow"
							size="lg"
							type="submit"
							id="reset-password-button"
						  >
							<IntlMessages id="user.reset-password-button" />
						  </Button>
						</div>
				  </Form>
					)}
				  </Formik>
				  
				  <p className="text-center mt-sm mb-0">
						Already a Member {" "}
						<NavLink to={`/user/login`} className="">
						  Login Now!
						</NavLink>
					</p>
				</div>
			  </Card>
			</Colxx>
		  </Row>
		);
	}
}
