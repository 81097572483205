const payments = [
{
    id: 1,
    date: "2019-06-07",
    amount: 100.00,
    fee: 2.00,
	net: 12.00,
    title: "Authorize 15654543",
    description:"",
    notes:"",    
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 2,
    date: "2019-03-16",
    amount: 298,
    fee: 30,
	net: 38,
    title: "Authorize 15653554",
    description:"",
    notes:"",    
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default payments
