import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon, Media
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, REST_API_BASE_URL} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const quillModules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link", "image"],
    ["clean"]
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image"
];


class EditTaskModal extends Component{

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {			
			textQuillStandart:'',
			objective:null,
			task_members:[],
			objective_members_ids:[]
		};
	}
	
    handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
	
	componentDidMount(){
		let objective; 		  
		if(this.props.ID !=0) {
			var formData = new FormData();
			formData.append('id',this.props.ID);

			axios.post(REST_API_END_POINT+'task/get-job-task-by-id',formData).then((res)=>{
				var data = res.data;
				var status=null;
				objective=data.objective;
				var objective_members_ids=data.objective_members_ids;
				var task_members=data.task_members;

				this.setState({ 
					objective,
					objective_members_ids,
					task_members
				});
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});	
		}
		
	}
  
    handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-task").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("taskForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('id',this.props.ID);
            formData.append('description',this.state.textQuillStandart);
			
			axios.post(REST_API_END_POINT+'task/edit-job-task',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-task").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-task").disabled=false;
			});

		}
	}
    render() {
      	if(this.state.objective!=null){
			var objective=this.state.objective;
		}else{
			var objective;
		}
		const objective_members_ids=this.state.objective_members_ids;
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Task
		  </ModalHeader>
		  <AvForm
                id="taskForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Task Title<span className="text-danger">*</span></Label>
							  <AvField
								id="title"
								name="title"
								type="text"
								autoComplete="off"
								value={ objective?((objective.title!=null)?objective.title:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "Please enter the title"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "Your title must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "Your title must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					
					<Row>
						<Colxx lg="12">
							<Label>Task Outline<span className="text-danger">*</span></Label>
							<ReactQuill
								theme="snow"
								value={ objective?((objective.description!=null)?objective.description:''):'' }
								onChange={this.handleChangeQuillStandart}
								modules={quillModules}
								formats={quillFormats}
							/>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="12">
							<Label className="d-block mt-3"><strong>Assign Users</strong></Label>
							{this.state.task_members.map((r,index)=>{
								return(<Media key={index}>
									<Media left className="mr-2">
										<Media object width="50" src={REST_API_BASE_URL+"uploads/users/"+r.profile_pic} alt={r.username} />
									</Media>
									<Media body>
										<p className="mb-1">{r.username}</p>
										<Input type="checkbox" name={"user_"+r.ID} value="1" defaultChecked={objective_members_ids.indexOf(r.userid)!==-1} className="ml-2" />									
									</Media>
								</Media>)
							})}							
						</Colxx>
					</Row>
					<hr />
					
					{objective ? <Row>
						<Colxx lg="12 mb-3">				
							<FormGroup check>
								<Label check>
									<Input type="checkbox" name="complete" defaultChecked={objective.complete==1} value="1" />{' '}
									Objective Complete
								</Label><br/>
							</FormGroup>
						</Colxx>
					</Row>:""}
					  
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-task" >
					  <IntlMessages id="customer.update" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditTaskModal;
