import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddNewRoomModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {
			room_no: '',	
			room_structures: [],
		};
	
	}
	
	componentDidMount() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
			
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory/get-available-rooms',formData).then((res)=>{
			var data = res.data;
			var room_nos = data.room_nos;
			if(data.room_nos){
				this.setState({
					room_structures:room_nos
				});
			}else{
				this.setState({
					room_structures:[]
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			document.getElementById("add-room").disabled=false;
		});
		
		
	} 
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-room").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("roomForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'inventory/add-room',formData).then((res)=>{
				var data = res.data;
				var room_nos = data.room_nos;
			
				this.setState({
					room_structures:room_nos,
					room_no:''
				});
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-room").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-room").disabled=false;
			});

		}
	}
	  
	handleClick = (room_no) => {
        this.setState({
			room_no
        });
    };	

  render() {
		if(JSON.stringify(this.state.room_structures)!=JSON.stringify(this.props.room_structures)){
			var room_structures= this.props.room_structures;
		}else{
			var room_structures= this.state.room_structures;
		}
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Room
		  </ModalHeader>
		  <AvForm
		        id="roomForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="cname"
							name="name"
							type="text"
							autoComplete="off"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z0-9 ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Room No.<span className="text-danger">*</span></Label>
						  <AvField
							id="room-no"
							name="position"
							type="number"
							value={this.state.room_no}
                            readOnly={true}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please enter numeric value"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<hr/>
				<h5>Choose any one of Available Room</h5>
				{room_structures.map((row,index)=>{
					return (<span key={index} className={"w-40px p-2 m-1 border d-inline-block text-center cursor-pointer "+(this.state.room_no==row.value ? "room-active":"")} onClick={()=>{this.handleClick(row.value)}} >
							{row.value}
							</span>)
				})}				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-room">
			  <IntlMessages id="customer.add" />
			</Button>
			<Button color="secondary" outline outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddNewRoomModal;
