import React, { Component, Fragment } from "react";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import ReactToPrint from 'react-to-print';

import Receipt from "../../../components/pos/Receipt";
import PrintPatientLabel from "../../../components/pos/PrintPackage";
import PrintProductLabel from "../../../components/pos/PrintProductLabel";

import {REST_API_END_POINT, dataEncryptKey,JQUERY_MODULE } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PrintLabelModal extends Component{
	constructor(props) {
		super(props);
	
		this.state = {
			order_data: null,
			order_products: null,
			whole_order_data: null,
			facility_data: null,
			currencies: []
		};
    }
	
	componentDidMount(){
		setTimeout(()=>{
			document.getElementById("print-pos-label").click();
		},500);
		
	}
	
	
    render() {		
		var print_order_data = localStorage.getItem('print_order_data');
		const print_label = this.props.match.params.print_label;
		const ref = React.createRef();
		
		if(print_order_data!=null){
		
			const { order_data, order_products, whole_order_data, facility_data, currencies } = JSON.parse(print_order_data);
			
			return (<Fragment>
						<Row>
							<Colxx md="12" className="mb-2">
							{print_label=='customer-label' ? <ReactToPrint
								trigger={() => <Button color="primary" id="print-pos-label" >Print</Button>}
								content={() => this.patientLabelRef}
								copyStyles={true}
								pageStyle={""}
							/>:""}
							{print_label=='product-label' ? <ReactToPrint
								trigger={() => <Button color="primary" id="print-pos-label"  >Print</Button>}
								content={() => this.productLabelRef}
								copyStyles={true}
								pageStyle={""}
							/>:""}						
							{print_label=='receipt' ? <ReactToPrint
								trigger={() => <Button color="primary" id="print-pos-label">Print</Button>}
								content={() => this.receiptRef}
								copyStyles={true}
								pageStyle={""}
							/>:""}
							</Colxx>
						</Row>
						
						{print_label=='receipt' ? <Receipt 
							ref={el => (this.receiptRef = el)}
							order_data={order_data}
							order_products={order_products}
							facility_data={facility_data}
							currencies={currencies}
						/>:""}
						
						{print_label=='customer-label' && whole_order_data!=null ? <PrintPatientLabel 
							ref={el => (this.patientLabelRef = el)} 
							print_pos_label={whole_order_data} 
							facility={facility_data}
						/>:""}
						
						{print_label=='product-label' && whole_order_data!=null ? <PrintProductLabel 
							ref={el => (this.productLabelRef = el)} 
							print_pos_label={whole_order_data} 
							facility={facility_data}
						/>:""}
								
					</Fragment>
			);
		}
	}
};
