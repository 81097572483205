import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { uuid } from "uuidv4";

const handleSubmit = (event, errors, values) => {		
	if (errors.length === 0) {
		
	}
}
	
// get product cart quantity
const getProductCartQuantity = (cartItems, product) => {
  let productInCart = cartItems.filter(
    single =>
      single.id === product.id 
  )[0];
  if (cartItems.length >= 1 && productInCart) {
    if (product.variation) {
      return cartItems.filter(
        single =>
          single.id === product.id
      )[0].quantity;
    } else {
      return cartItems.filter(single => product.id === single.id)[0].quantity;
    }
  } else {
    return 0;
  }
};

const selectProduct = (products,row) => {
	products = products.map(r=>{
		if(r.variation_id==row.variation_id){
			r.selected=!r.selected;
		}			
		return r;
	});
	return products;
}
	
const updateProductQuantity = (products,row,quantity) =>{		
	products = products.map(r=>{
		if(r.variation_id==row.variation_id){
			r.quantity=quantity;
		}
		return r;
	});
	return products;
}

function ProductModal(props) {
  var product  = JSON.parse(JSON.stringify(props.product));
  const { currency } = props;
  const { finalproductprice } = props;
  
  let deal_products = product.is_mix_match==1 ? product.deal_products.map(row=>{
					row.selected=false;
					row.quantity=1;
					return row;
				}):product.deal_products;
  let show_add_to_cart = product.is_mix_match==0;
  
  const [showAddToCart, setShowAddToCart] = useState( show_add_to_cart );
  const [productStock, setProductStock] = useState( parseInt(product.product_stock) );
  const [dealProducts, setDealProducts] = useState( deal_products );
  const [quantityCount, setQuantityCount] = useState(1);
  const [finalProductPrice, setFinalProductPrice] = useState(finalproductprice);
  const [variationId, setVariation] = useState(product.variation_id);

  const addToCart = props.addtocart;
  const addToast = props.addtoast;
  const cartItems = props.cartitems;

  const productCartQty = getProductCartQuantity(
    cartItems,
    product,
  );

  return (
    <Fragment>
      <Modal
		isOpen={props.show}
		toggle={props.onHide}
        className="product-quickview-modal-wrapper"
		size="lg"
      >
        <ModalHeader toggle={props.onHide}>
			
		</ModalHeader>
		<ModalBody>			
			  <div className="row">
				<div className="col-md-5 col-sm-12 col-xs-12">
				  <div className="product-large-image-wrapper">
				   	<div >
					  <div className="single-image">
						<img
						  src={product.thumbnail_url}
						  className="img-fluid"
						  alt=""
						/>
					  </div>
					</div>
				  </div>
				  
				</div>
				<div className="col-md-7 col-sm-12 col-xs-12">
				  <div className="product-details-content quickview-content">
					<h2 dangerouslySetInnerHTML={{ __html: product.name }}></h2>
					<div className="product-details-price">                 
						<span>{currency.currencySymbol}{finalProductPrice.toFixed(2)} </span>
					</div>
					<div className="pro-details-list">
					  {product.description!="" ? (
						  <div dangerouslySetInnerHTML={{ __html: product.description }} ></div>
						) : (
						  ""
						)}
					</div>
					
					{product.variation_name!="" ? <div className="pro-variation-list">
						{productStock && productStock < 20 ? <div className="text-danger">Only {productStock} is available</div>:(productStock ? <div className="text-success font-weight-bold mb-2">In Stock</div>:"")}
						{product.variation_name}
					</div>:""}
												
						{product.is_mix_match==1 ? <Fragment>
							<h3 className="mt-3">Choose {product.no_of_products} Quantity of Products</h3>
						<PerfectScrollbar
						  option={{ suppressScrollX: true, wheelPropagation: false }}
						  className="mix-and-match-scrollbar pos-product-wrapper py-2 px-4"
						>
							<div className="row">
							{dealProducts.map((row,index)=>{
								return(<Colxx md="4" className="text-center product-item" key={index} >
									<img src={row.thumbnail_url} className="product-thumnail img-responsive" />	
									<p className="mt-1 mb-1 product-item-name">{row.name.toUpperCase()}</p>
									<div className="d-flex justify-content-center">
										<CustomInput
											name={"selected_p_"+index}									
											type="checkbox"
											id={"selected_p_"+index}
											checked={row.selected}
											value={row.selected}
											onClick={(e)=>{
												let deal_products = selectProduct(dealProducts,row);
												let selected_products = deal_products.filter(r=>{ return r.selected }).map(r=>r.quantity!='' ? parseInt(r.quantity):0);
												let total_selected=0;
												if(selected_products.length>0){
													total_selected = selected_products.reduce((total,r)=>{ return total+r });
												}
												setDealProducts(deal_products);
												setShowAddToCart(total_selected===parseInt(product.no_of_products))
											}}
										/>
									</div>
									{row.selected ? <div className="d-flex justify-content-center">
									<AvForm							 
										className="av-tooltip tooltip-right-bottom w-100"
										onSubmit={handleSubmit}
									>	
										<AvField
											name={"selected_q_"+index}									
											type="number"
											id={"selected_q_"+index}
											className="text-center"
											value={row.quantity}
											validate={{
												required: {
													value: true,
													errorMessage: "This field is required"
												},
												min: {
													value: "1",
													errorMessage:"The quantity must be greater than or equal to 1"
												},
												max: {
													value: parseInt(row.stock).toString(),
													errorMessage:"The quantity must be less than or equal to "+parseInt(row.stock).toString()
												},
											}}
											onChange={(e)=>{
												let value = e.target.value;
												let deal_products = updateProductQuantity(dealProducts,row,e.target.value);
												let selected_products = deal_products.filter(r=>{ return r.selected }).map(r=>r.quantity!='' ? parseInt(r.quantity):0);
												let total_selected=0;
												if(selected_products.length>0){
													total_selected = selected_products.reduce((total,r)=>{ return total+r });
												}
												setDealProducts(deal_products);
												setShowAddToCart(total_selected===parseInt(product.no_of_products));
											}}
										/>
									</AvForm>
									</div>:""}
								</Colxx>)
							})}					
							</div>
						</PerfectScrollbar></Fragment>:""}
						
					  <div className="pro-details-quality">
						<div className="cart-plus-minus d-none">
						  <button
							onClick={() =>
							  setQuantityCount(
								quantityCount > 1 ? quantityCount - 1 : 1
							  )
							}
							className="dec qtybutton"
						  >
							-
						  </button>
						  <input
							className="cart-plus-minus-box"
							type="text"
							value={quantityCount}
							readOnly
						  />
						  <button
							onClick={() =>
							  setQuantityCount(
								quantityCount < productStock - productCartQty
								  ? quantityCount + 1
								  : quantityCount
							  )
							}
							className="inc qtybutton"
						  >
							+
						  </button>
						</div>
						
						<div className="pro-details-cart btn-hover">
						  {productStock && productStock > 0 ? (
							showAddToCart ? <button
							  onClick={() =>{
								let product_data  = JSON.parse(JSON.stringify(product));
								if(product_data.is_mix_match==1){
									let selected_products = JSON.parse(JSON.stringify(dealProducts)).filter(r=>{ return r.selected });
									product_data.product_stock=Math.min(...selected_products.map(r=>parseInt(r.stock/parseInt(r.quantity))));
									
									product_data.deal_products = selected_products.map(r=>{ 
										delete r.selected; 
										delete r.stock; 
										delete r.name; 
										delete r.thumbnail; 
										delete r.thumbnail_url;
										return r; 
									});
									product_data.cartItemId=uuid();
								}
								addToCart(
								  product_data,
								  addToast,
								  quantityCount,
								  variationId
								);
								if(product_data.is_mix_match==1){
									let deal_products = dealProducts.map(row=>{
										row.selected=false;
										row.quantity=1;
										return row;
									});
									setDealProducts(deal_products);
									setShowAddToCart(false);
								}
				
								props.onHide();
							  }}
							  disabled={productCartQty >= productStock}
							>
							  {" "}
							  Add To Cart{" "}
							</button>:""
						  ) : (
							<button disabled>Out of Stock</button>
						  )}
						</div>
					  </div>
					
				  </div>
				</div>
			  </div>
			</ModalBody>
		</Modal>
    </Fragment>
  );
}

ProductModal.propTypes = {
  addtoast: PropTypes.func,
  addtocart: PropTypes.func,
  cartitems: PropTypes.array,
  currency: PropTypes.object,
  finalproductprice: PropTypes.number,
  onHide: PropTypes.func,
  product: PropTypes.object,
  show: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    cartitems: state.cartData
  };
};

export default connect(mapStateToProps)(ProductModal);
