import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import emailManagement from './email-management';
import edit from './edit';


const Reports = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Route exact path={`${match.url}`} component={emailManagement} />
            <Route path={`${match.url}/edit/:id`} component={edit} />           
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Reports;