import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, Input,
	CustomInput, Badge,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import InventoryAuditAdjustModal from "../../../containers/inventory/InventoryAuditAdjustModal";

import ReactTable from "react-table";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class InventoryAudit extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleAdjustInventory = this.toggleAdjustInventory.bind(this);
		
		this.state = {		 
			search: '',
			products: [],
			selectAll:0,
			selectedIds:[],
			select_category_id:null,
			select_sub_category_id:null,
			select_product_id:null,
			product_id:0,
			variation_id:0,
			modalOpen:false
		};
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		if(this.state.select_product_id!=null){
			formData.append('product_id',this.state.select_product_id.value);
		}
		
		if(this.state.select_category_id!=null){
			formData.append('category_id',this.state.select_category_id.value);
		}
		
		if(this.state.select_sub_category_id!=null){
			formData.append('sub_category_id',this.state.select_sub_category_id.value);
		}
		
		axios.post(REST_API_END_POINT+'inventory/inventory-audit',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				this.setState({
					products: data.data.product_variations,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					products: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleAdjustInventory = (e,product_id=0,variation_id=0) => {
		this.setState({
			modalOpen: !this.state.modalOpen,
			product_id,
			variation_id
		});
	};
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	render() {
		let data = this.state.products
		if (this.state.search) {
			data = data.filter(row => {
				if(row.strain_name==null){
					row.strain_name="";
				}
				if(row.sub_category==null){
					row.sub_category="";
				}
				return row.name.toLowerCase().includes(this.state.search) || row.barcode.toLowerCase().includes(this.state.search) || row.strain_name.toLowerCase().includes(this.state.search) || row.category.toLowerCase().includes(this.state.search) || row.sub_category.toLowerCase().includes(this.state.search) || row.source_name.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [		
			
			{
				Header: "Name",
				accessor: "name",
				width:200,
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			/*{
				Header: "Strain",
				accessor: "strain_name",
				width:100,
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},*/
			{
				Header: "Category",
				accessor: "category",
				width:110,
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Subcategory",
				accessor: "sub_category",
				width:110,
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Available Qty",
				accessor: "available_qty",
				width:120,
				Cell: props => <p className="list-item-heading" dangerouslySetInnerHTML={{__html:props.value}} ></p>
			},			
			{
				Header: "Actions",
				accessor: "id",
				width:60,
				sortable: false,
				Cell: props => <Fragment>
						<Button color="primary" onClick={(e)=>{this.toggleAdjustInventory(e,props.original.id,props.original.variation_id)}}>Adjust Inventory</Button>
					</Fragment>
			}
		];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.inventory-audit" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		<Card className="p-4">
			<Row>			  
				<Colxx xxs="12">
					<div id="api_message"></div>
					<div className="mb-4 dropdown-edit small-padd-table">
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>					
				</Colxx>
			</Row>
		</Card>
		{this.state.modalOpen ? <InventoryAuditAdjustModal
			modalOpen={this.state.modalOpen}
			toggleModal={this.toggleAdjustInventory}
			dataListRender={this.dataListRender}
			product_id={this.state.product_id}
			variation_id={this.state.variation_id}
		/>:""}
		
      </Fragment>
    );
  }
}
