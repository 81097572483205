import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import customerDirectory from "./customerDirectory";
import allOrders from "./allOrders";
import accountManagement from "./accountManagement";
import customerPos from "./customerPos";
import placeOrder from "./placeOrder";
import printLabels from "./printLabels";
import settings from "./settings";
import processPayment from "./processPayment";
import customerEditOrder from "./customerEditOrder";
import dailyDeals from "./dailyDeals";
import mixMatchDeals from "./mixMatchDeals";
import reverseTaxCalculator from "./reverse-tax-calculator";
import calendar from "./calendar";
import dailyDealsCalendar from "./daily-deals-calendar";
import cashExchange from "./cash-exchange";
import printerSettings from "./printer-settings";

const Pointofsale = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			<Redirect exact from={`${match.url}`} to={`${match.url}/customer-lookup`} />			
			<Route path={`${match.url}/customer-lookup`} component={customerDirectory} />
			<Route path={`${match.url}/all-orders`} component={allOrders} />

			<Route path={`${match.url}/account-management`} component={accountManagement} />

			<Route path={`${match.url}/express-calendar`} component={calendar} />	
			<Route path={`${match.url}/daily-deals-calendar`} component={dailyDealsCalendar} />	
			<Route path={`${match.url}/pos/:customer_id`} component={customerPos} />
			<Route path={`${match.url}/place-order`} component={placeOrder} />
			<Route path={`${match.url}/print/:print_label`} component={printLabels} />
			<Route path={`${match.url}/settings`} component={settings} />
			<Route path={`${match.url}/process-payment/:order_id`} component={processPayment} />
			<Route path={`${match.url}/edit-order/:order_id`} component={customerEditOrder} />
			<Route path={`${match.url}/daily-deals`} component={dailyDeals} />
			<Route path={`${match.url}/mix-and-match-deals`} component={mixMatchDeals} />
			<Route path={`${match.url}/reverse-tax-calculator`} component={reverseTaxCalculator} />
			<Route path={`${match.url}/cash-exchange`} component={cashExchange} />
			<Route path={`${match.url}/printer-settings`} component={printerSettings} />
			<Redirect to="/error" />
        </Switch>
    </div>
);
export default Pointofsale;