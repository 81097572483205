import React from "react";
import ChartComponent, { Chart } from "react-chartjs-2";
import { chartTooltip } from './util'

export default class TopAgentBar extends React.Component {
  componentWillMount() {
    if (this.props.shadow) {
      Chart.defaults.barWithShadow = Chart.defaults.bar;
      Chart.controllers.barWithShadow = Chart.controllers.bar.extend({
        draw: function(ease) {
          Chart.controllers.bar.prototype.draw.call(this, ease);
          var ctx = this.chart.ctx;
          ctx.save();
          ctx.shadowColor = "rgba(0,0,0,0.2)";
          ctx.shadowBlur = 7;
          ctx.shadowOffsetX = 5;
          ctx.shadowOffsetY = 7;
          ctx.responsive = true;
          Chart.controllers.bar.prototype.draw.apply(this, arguments);
          ctx.restore();
        }
      });
    }
  }

  render() {
    const { data, shadow } = this.props;
	const barChartOptions = {
		  legend: {
			display:false
		  },
		  responsive: true,
		  maintainAspectRatio: false,
		  scales: {
			yAxes: [
			  {
				gridLines: {
				  display: false
				}
			  }			  
			],
			xAxes: [
			  {
				gridLines: {
				  display: true,
				  lineWidth: 1,
				  color: 'rgba(0,0,0,0.1)',
				  drawBorder: false
				},
				ticks: {
				  beginAtZero: true,
				  /*stepSize: 1,
				  min: 0,
				  max: 10,*/
				  padding: 20
				}
			  }
			]
		  },
		  tooltips: chartTooltip
		}
    return (
      <ChartComponent
        ref={ref => (this.chart_instance = ref && ref.chart_instance)}
        type={shadow ? "horizontalBarWithShadow" : "horizontalBar"}
        options={{
          ...barChartOptions
        }}
        data={data}
      />
    );
  }
}
