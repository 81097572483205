import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";

import InvoiceModal from "../../../containers/pointofsale/InvoiceModal";
import AddToAccountModal from "../../../containers/accounts/AddToAccountModal";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, CUSTOMER_ORDER_STATUS} from "../../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class CompletedOrders extends Component {
	
	constructor(props) {
		super(props);
		this.toggleModalInvoice = this.toggleModalInvoice.bind(this);
		this.toggleModalAddToAccount = this.toggleModalAddToAccount.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			modalOpenAddToAccount: false,
			modalOpenInvoice:false,
			orders:[],
			search: '',
		};
	}
		
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'account/completed-orders',formData).then((res)=>{
			var data = res.data;
		
			if(data.orders){
				var orders=data.orders;
				orders = orders.map(row=>{
					if(row.delivery_date=='0'){
						row.delivery_date='';
					}else{
						row.delivery_date = moment(row.delivery_date*1000).format('MM/DD/YYYY HH:mm');
					}
					
					const order_status = CUSTOMER_ORDER_STATUS.find(r=>{ return r.value==row.status });
					if(order_status!=null){
						row.status_text = order_status.label;
						row.status_badge = <Badge color={order_status.badge} >{order_status.label}</Badge>;
					}else{
						row.status_text ="";
						row.status_badge ="";
					}
					
					return row;
				});
				
				this.setState({
					orders,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					orders: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleModalInvoice = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenInvoice: !this.state.modalOpenInvoice
		});
	};
	
	toggleModalAddToAccount = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpenAddToAccount: !this.state.modalOpenAddToAccount
		});
	};    
  
	render() {
		let data = this.state.orders;
		if (this.state.search) {
			data = data.filter(row => {
				return row.order_number.includes(this.state.search) || row.vendor_name.toLowerCase().includes(this.state.search) || moment(row.timestamp*1000).format('MM/DD/YYYY').includes(this.state.search) || row.delivery_date.includes(this.state.search) || row.status_text.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [			  
			{
				Header: "Order ID",
				accessor: "order_number",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Patient Name",
				accessor: "vendor_name",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Order Date",
				accessor: "timestamp",
				Cell: props => <p className="text-muted mb-0">{moment(props.value*1000).format('MM/DD/YYYY')}</p>
			},
			{
				Header: "Delivery Date",
				accessor: "delivery_date",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status_badge",
				Cell: props => props.value
			},
			{
				Header: "Options",
				accessor: "order_id",
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalInvoice(e,props.value) }} >
									<span className="d-inline-block">Invoice</span>
								</DropdownItem>
								{props.original.account!=1 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalAddToAccount(e,props.value) }} >
									<span className="d-inline-block" >Add to Account</span>
								</DropdownItem>:""}
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];
    return (
		<Fragment>
			<Row>
			  <Colxx xxs="12">
				<Breadcrumb heading="menu.completed-orders" match={this.props.match} />
				<Separator className="mb-5" />
			  </Colxx>
			</Row>
		
			<Card className="p-4">
				<Row>			  
					<Colxx xxs="12">									
						<div className="mb-4 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
									/>
								</Colxx>
							</FormGroup>			
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								minRows = {2}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>						
					</Colxx>
				</Row>
			</Card>
			
			<InvoiceModal
				modalOpen={this.state.modalOpenInvoice}
				toggleModal={this.toggleModalInvoice}
				order_id={this.state.order_id}
			/>
			
			<AddToAccountModal
				modalOpen={this.state.modalOpenAddToAccount}
				toggleModal={this.toggleModalAddToAccount}
				dataListRender={this.dataListRender}
				order_id={this.state.order_id}
			/>
			
		</Fragment>
    );
  }
}
