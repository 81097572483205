import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import number_format from "number_format-php";
import { uuid } from 'uuidv4';
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class FillSackModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.addConsolidation = this.addConsolidation.bind(this);
		this.removeConsolidation = this.removeConsolidation.bind(this);
		this.state = {	
			sack:null,
			product_packages:[],
			all_packages:[],
			consolidations:[],
			container_uid:null,
			container_capacity:0,
			in_house_weight_lbs_val:"",
			in_house_weight_gram_val:"",
			package_uid:null,
		};	
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){   
        if(this.props.modalOpen){
			if (prevProps.sack_id!== this.props.sack_id && this.props.sack_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('sack_id',this.props.sack_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'processor/get-data-for-sack-filling',formData).then((res)=>{
				    var data = res.data;
					let sack=data.sack;
					let product_packages = data.packages;
					product_packages = product_packages.map(row=>{
						row.label=row.name+" ("+row.barcode+")";
						row.value=row.package_id;
						row.key=row.package_id;
						return row;
					});
					this.setState({ 
						sack,
						product_packages,
						all_packages:JSON.parse(JSON.stringify(product_packages))
					});					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
            }
        }
    }
	
	
	addConsolidation() {		
		const package_uid=this.state.package_uid;
		const consolidations=this.state.consolidations;
		
		if(package_uid!=null){
			var package_id=package_uid.value;
			var in_house_weight_lbs_val =0;
			var in_house_weight_gram_val =0;
			
			var package_current_qty = parseFloat(package_uid.quantity);
			
			if(this.state.in_house_weight_lbs_val!=''){
				in_house_weight_lbs_val = parseFloat(this.state.in_house_weight_lbs_val);
			}
			if(this.state.in_house_weight_gram_val!=''){
				in_house_weight_gram_val = parseFloat(this.state.in_house_weight_gram_val);
			}
			var in_house_weight = parseFloat(((in_house_weight_lbs_val*LBS_CONSTANT)+in_house_weight_gram_val).toFixed(4));
			if(package_current_qty<in_house_weight){
				let package_current_qty_lbs=parseInt(package_current_qty/LBS_CONSTANT);
				let	package_current_qty_grams=parseFloat(((package_current_qty/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(4);
				swal("Warning!",'The package has only '+package_current_qty_lbs+' Lbs '+package_current_qty_grams+' Grams ',"warning");
				return;
			}
			
			var total_grams=0;
			if(consolidations.length){
				total_grams = parseFloat(consolidations.map(r=>r.in_house_weight).reduce((total,in_house_weight)=>{ return parseFloat(total)+parseFloat(in_house_weight) }));
			}
			
			let consolidation = {};
			consolidation.biomass_id = uuid();
			consolidation.package_id = package_id;
			consolidation.barcode = package_uid.barcode;
			consolidation.in_house_weight = in_house_weight;
			consolidation.in_house_weight_lbs = in_house_weight_lbs_val;
			consolidation.in_house_weight_gram = in_house_weight_gram_val;
			
			consolidations.push(consolidation);
			var product_packages = this.state.product_packages.filter(row=>{ return row.package_id!=package_id });
			this.setState({
				consolidations,
				product_packages,
				package_uid:null, 
				in_house_weight_lbs_val:"",
				in_house_weight_gram_val:""
			});
		}else{
			swal("Warning!","Please select a package","warning");
		}
		
	}
	
	removeConsolidation(biomass_id) {
		var product_packages = JSON.parse(JSON.stringify(this.state.product_packages));
			
		var consolidation = this.state.consolidations.find(row=>{ return row.biomass_id==biomass_id });
		let product_package = this.state.all_packages.find(row=>{ return row.value==consolidation.package_id });
		
		if(product_package){
			product_packages.push(product_package);
		}
		
		var consolidations = this.state.consolidations.filter(row=>{ return row.biomass_id!=biomass_id });
		this.setState({
			product_packages,
			consolidations
		});
			
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			let consolidations = this.state.consolidations;
			if(consolidations.length==0){
				swal("Warning!","Please add at least one package.","warning");
				return;
			}
			var total_grams=0;
			consolidations.map((consolidation,index)=>{			
				total_grams+=parseFloat(consolidation.in_house_weight);
			});
			let no_of_sacks = parseInt(total_grams/parseFloat(this.state.sack.capacity));
			if(no_of_sacks==0){
				swal("Warning!","Please packages for filling at least one sock.","warning");
				return;
			}
			
			document.getElementById("update-sacks").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("sackFillForm"));
			formData.append('sack_id',this.props.sack_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('consolidations',JSON.stringify(this.state.consolidations));

			axios.post(REST_API_END_POINT+'processor/fill-sack',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-sacks").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-sacks").disabled=false;
			});

		}
	} 
  
	render(){
		const { sack, consolidations } = this.state;
		var total_grams=0;
		var total_container_weight_lbs=0;
		var total_container_weight_gram=0;
		var sack_quantity = 1;
		if(sack!=null){
			sack_quantity=parseFloat(sack.capacity);
		}
		
		var final_consolidations=consolidations.map((consolidation,index)=>{			
			total_grams+=parseFloat(consolidation.in_house_weight);
			return(<Row className="align-items-center mb-3" key={index}>
						<Colxx lg="4">
							<Input
								readOnly={true}
								value={consolidation.barcode}
							/>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="5">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_lbs+" (Lbs)"}
									/>
								</Colxx>
								<Colxx lg="5">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_gram+" (Grams)"}
									/>
								</Colxx>
								<Colxx lg="2">
									<span className="text-danger" onClick={()=>{ this.removeConsolidation(consolidation.biomass_id) }}><i className="fas fa-2x fa-times" /></span>
								</Colxx>
							</Row>
						</Colxx>	
					</Row>);
		})
		total_container_weight_lbs=parseInt(total_grams/LBS_CONSTANT);
		total_container_weight_gram=parseFloat(((total_grams/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(5);
		
		let no_of_sacks = parseInt(total_grams/sack_quantity);
		let remaining_quantity = total_grams - no_of_sacks*sack_quantity;
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Fill Sock
			</ModalHeader>
			<AvForm
				id="sackFillForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row className="align-items-center mb-3 h4">
						<Colxx lg="4">
							<Label>Capacity of Sock</Label>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="10">																	
									{sack!=null ? sack.lbs+" Lbs "+sack.grams+" Grams ":""}
								</Colxx>
							</Row>
						</Colxx>
					</Row>
					<Row>						
						<Colxx lg="4" className="mb-4">
							<AvGroup className="error-t-negative">
								<Label>Enter/Scan Package UID To Add</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="package_uid"
									id="package_uid"
									placeholder="-- Select Packages --"
									value={this.state.package_uid}						
									options={this.state.product_packages}
									onChange={package_uid => { 
										if(package_uid!=null){
											let in_house_weight_lbs_val=parseInt(package_uid.quantity/LBS_CONSTANT);
											let	in_house_weight_gram_val=parseFloat(((package_uid.quantity/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(4);
											this.setState({ package_uid, in_house_weight_lbs_val, in_house_weight_gram_val })
										}else{
											this.setState({ package_uid })
										}
									}}
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="4" className="mb-4 form-group-mb-0">
							<AvGroup>
								<Label>In House Weight</Label>
								<AvField
									id="in_house_weight_lbs_val"
									name="in_house_weight_lbs_val"
									type="number"												
									placeholder="(Lbs)"
									value={this.state.in_house_weight_lbs_val}
									onChange={e => { this.setState({ in_house_weight_lbs_val:e.target.value }) }}												
								/>
								<Label>Lbs</Label>
							</AvGroup>
						</Colxx>						
						<Colxx lg="4" className="mb-4 form-group-mb-0">
							<AvGroup>
								<Label>&nbsp;</Label>
								<AvField
									id="in_house_weight_gram_val"
									name="in_house_weight_gram_val"
									type="number"	
									placeholder="(Grams)"
									value={this.state.in_house_weight_gram_val}
									onChange={e => { this.setState({ in_house_weight_gram_val:e.target.value }) }}
								/>
								<Label>Grams</Label>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row className="text-center mb-4">
						<Colxx lg="12">
							<Button	color="success" block size="lg" className="mr-1" onClick={this.addConsolidation} >Add Package To Sock</Button> 
						</Colxx>
					</Row>
					
					<div>
					{final_consolidations}
					</div>
					
					<Row className="align-items-center">
						<Colxx lg="4">
							<Label>Total Weight</Label>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="5">																	
									<Input
										readOnly={true}
										value={total_container_weight_lbs+" (Lbs)"}
									/>
								</Colxx>
								<Colxx lg="5">
									<Input
										readOnly={true}
										value={total_container_weight_gram+" (Grams)"}
									/>
								</Colxx>
							</Row>
						</Colxx>
					</Row>
					
					<Row className="align-items-center mt-2 ">
						<Colxx lg="4">
							<Label>No. of Socks</Label>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="10">																	
									<Input
										readOnly={true}
										value={no_of_sacks}
									/>
								</Colxx>
							</Row>
						</Colxx>
					</Row>
					
					<Row className="align-items-center mt-2 ">
						<Colxx lg="4">
							<Label>Remaining Quantity</Label>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="10">																	
									<Input
										readOnly={true}
										value={remaining_quantity.toFixed(5)+" (Grams)"}
									/>
								</Colxx>
							</Row>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-sacks" >
					  <IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default FillSackModal;
