import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader, Table,Media,
  InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import moment from 'moment';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";

import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE,ENQUIRY_STATUS, BASE_URL } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";
import ViewFileModal from "../../components/common/ViewFileModal";
import EditReplyModal from "./EditReplyModal";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

const quillModules = {
	toolbar: [
	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	  ["bold", "italic", "underline"],
	   // dropdown with defaults from theme	
	  [
		{ list: "ordered" },
		{ list: "bullet" },
		{ indent: "-1" },
		{ indent: "+1" }
	  ],    	    
	  
	]
  };
  
  const quillFormats = [
	"header",
	"bold",
	"italic",
	"underline", 
	"list",
	"bullet",
	"indent", 
  ];
  
export default class CompleteModal extends Component{
	constructor(props) {
		super(props);
		const option = [
			{ value: "1", label: "Pending for confirmation",key: 1 },
			{ value: "3", label: "Approved",key: 3 },
			{ value: "9", label: "Lost",key: 9 }
		  ];
		  var selectOptions = option.map((element,index)=>{
			element.label=element.label;
			element.value=element.value;
			element.key=index;
			return element;
		});
		
		this.state = {
			enquiry_data: null,
			facility_data: null,
			status:null,
			replies: [],
			currencies: [],
			textQuillStandart:"",
			showFile:true,
			doc_url:null,
			modalOpenEditReply:false,
			reply_id:"",
			editBody:"",
			enquiry_id:"",
			options:selectOptions,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSubmitNote = this.handleSubmitNote.bind(this);
		this.deleteReply = this.deleteReply.bind(this);
		this.deleteReplyFile = this.deleteReplyFile.bind(this);
		this.toggleModalViewDoc = this.toggleModalViewDoc.bind(this);
	    this.editReply=this.editReply.bind(this);
		
    }
	toggleModalViewDoc = (e,doc_url=null) => {
		this.setState({
			modalOpenViewDoc: !this.state.modalOpenViewDoc,
			doc_url
		});
	};
	editReply= (reply_id,body) => {
		
		
		this.setState({
			modalOpenEditReply: !this.state.modalOpenEditReply,
			reply_id:reply_id,
			editBody:body,
			enquiry_id:this.state.enquiry_id
			
		});
	};

	
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }	
	
	onMultiFileChange = e => {
		switch (e.target.name){
			case 'files[]':
			if(e.target.files[0]){
				var filenames_obj=e.target.files;
				var filenames=[];
				for (var i = 0; i < filenames_obj.length; i++) {
					filenames.push(filenames_obj[i].name);
				}
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=filenames.join(',');
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		}
	};	
	componentDidUpdate(prevProps, prevState, snapshot){
		
        if(this.props.modalOpen){		
			
					
            if (prevProps.enquiry_id!== this.props.enquiry_id && this.props.enquiry_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('enquiry_id',this.state.enquiry_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'enquiries/get-enquiry-detail',formData).then((res)=>{
					
					var data = res.data;
					const status = this.state.options.find(r=>{ return r.value==data.enquiry_data.status });
					if(data.status==1){
						this.setState({ 
							enquiry_data:data.enquiry_data,
							status,
							facility_data:data.facility_data,
							replies:data.replies,
							textQuillStandart:"",
							showFile:true,
							enquiry_id:this.props.enquiry_id,
						});
						console.log('DataStatus',this.state.status);
						
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such enquiry","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
	componentDidMount(){
		
     
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('enquiry_id',this.props.enquiry_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'enquiries/get-enquiry-detail',formData).then((res)=>{
					
					var data = res.data;
					const status = this.state.options.find(r=>{ return r.value==data.enquiry_data.status });
					if(data.status==1){
						this.setState({ 
							enquiry_data:data.enquiry_data,
							status,
							facility_data:data.facility_data,
							replies:data.replies,
							textQuillStandart:"",
							showFile:true,
							enquiry_id:this.props.enquiry_id,
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such enquiry","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            
        
    }
	
	dataListRender=(enquiry_id)=>{
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('enquiry_id',enquiry_id);
		formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'enquiries/get-enquiry-detail',formData).then((res)=>{
			
			var data = res.data;
			const status = this.state.options.find(r=>{ return r.value==data.enquiry_data.status });
			
			if(data.status==1){
				this.setState({ 
					// enquiry_data:data.enquiry_data,
					status,
					// facility_data:data.facility_data,
					replies:data.replies,
					 textQuillStandart:"",
					// showFile:true
				});
				
			}else{
				// this.props.toggleModal();
				swal("Warning!","There is no such enquiry","warning");
			}					
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		}); 
		
	}	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			// document.getElementById("complete-order").disabled=true;

			
			// if(this.state.status==4){
			// 	if(values.reason.length==0){
			// 		swal("Failed","Please Enter Reason","warning");
			// 		return;
			// 	}
			// }
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			// var status=
			var formData = new FormData(document.getElementById("completeForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('enquiry_id',this.props.enquiry_id);
			console.log('Sfgggg',this.state.status.value);
			
            formData.append('status',this.state.status.value);
			axios.post(REST_API_END_POINT+'enquiries/complete-enquiry',formData).then((res)=>{
				var data = res.data;
				
				// document.getElementById("complete-order").disabled=false;
				this.dataListRender(this.props.enquiry_id);
				// this.props.toggleModal();
				
				if(data.status==1){
					swal("Success",data.msg,"success");
					
				}else{
					swal("Failed",data.msg,"warning");
				}
					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				// document.getElementById("complete-order").disabled=false;
			});
		  
		}
	}
	deleteReply = (reply_id)  => {
		var msg="Delete this note?";
        
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('reply_id',reply_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'enquiries/delete-reply',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						var replies = this.state.replies;
						replies = replies.filter(r=>{ return r.ID!=reply_id });
						this.setState({ replies })
						swal("Success",data.msg,"success");
					}else{
						swal("Failed",data.msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	
	deleteReplyFile = (file_id)  => {
		var msg="Delete this file?";
        
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('file_id',file_id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'enquiries/delete-reply-file',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						var replies = this.state.replies;
						replies = replies.map(r=>{
							r.files = r.files.filter(f=>{ return f.ID!=file_id });
							return r;
						})
						this.setState({ replies })
						swal("Success",data.msg,"success");
					}else{
						swal("Failed",data.msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	handleSubmitNote(event, errors, values) {
		
		if (errors.length === 0) {
		
			if(this.state.textQuillStandart.length==0){
				// swal("Warning!","Please enter the note","warning");
				return;
			}
			
			// document.getElementById("reply-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("replyForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('enquiry_id',this.props.enquiry_id);
			formData.append('body',this.state.textQuillStandart);

			axios.post(REST_API_END_POINT+'enquiries/order-reply',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_documents");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				// document.getElementById("reply-order").disabled=false;
				this.form && this.form.reset();
				// this.dataListRender(this.props.enquiry_id);

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				// document.getElementById("reply-order").disabled=false;
			});

		}
	}

    render() {
		{console.log('Status',typeof(this.state.status),this.state.status)						}
		const { enquiry_data, facility_data,replies,options} = this.state;
		var current_currency_symbol = getCurrencySymbol();
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
				  Track	Enquiry 
				</ModalHeader>
				
				<AvForm
					id="completeForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					 onSubmit={this.handleSubmit}
				>
					<ModalBody >
						{enquiry_data!=null ? <Fragment>
							<Row>
								<Colxx md="12">
									<div className="view-order-table">
										<div className="font-weight-bold text-uppercase h4">#{moment(enquiry_data.timestamp*1000).format('YYYY')}/{zeroPad(enquiry_data.order_number,6)}</div>
										<div className="border-bottom pt-2 mb-3" />
										<Table responsive bordered>
											<thead>
												<tr>
													<th className="mb-2">#</th>						
													<th className="mb-2">Product</th>
													<th className="mb-2 text-right">Quantity</th>	
													<th className="mb-2 text-right">Unit Price</th>	
													<th className="mb-2 text-right">VAT</th>	
												</tr>
											</thead>
											<tbody>
												{enquiry_data.enquiry_products.map((row,index)=>{
													return(<tr key={index}>
													<td>{index+1}</td>
													<td>{row.product_name}</td>
													<td className="text-right">{number_format(parseFloat(row.quantity),2,'.','')}</td>
													<td className="text-right">{number_format(parseFloat(row.unit_price),2,'.','')}</td>
													<td className="text-right">{number_format(parseFloat(row.product_tax),2,'.','')}</td>
												    </tr>)
												})}
											</tbody>
										</Table>
									</div>
								</Colxx>
							</Row>
							<Row>
					
					<Colxx lg="12">
					{console.log('StatusAv',this.state.status)}
						{/*}	<AvGroup>
								<Label>Enquiry Status</Label>
								{console.log('StatusAv',this.state.status)}
								<Select
									id="status"
									name="status"
									type="select"
									value={this.state.status!=null?9:""}
									// defaultValue={this.state.status}
									onChange={(e)=>{ this.setState({ status:e.target.value }) }}
									autoComplete="off"
								>
									<option value="1"> Pending for confirmation </option>
									{/* <option value="2" > Follow up</option> */}
									{/*<option value="3" >Approved</option>
									{/* <option value="4" >Spam</option> */}
									{/* <option value="5" >Void</option> */}
									{/*</Colxx><option value="9" >Lost</option>
									
									</Select>
							</AvGroup>	*/}		
							<AvGroup className="error-t-negative">
							<Label>Enquiry Status<span className="text-danger"></span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="status"
								id="status"
								value={this.state.status}						
								options={options}
								onChange={status => { this.setState({ status}) }}
								required={true}
							/>
						</AvGroup>		
						</Colxx>
					</Row>	
						</Fragment>:""}
						{/* <Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Reason<span className="text-danger"></span></Label>
							  <AvField
								id="reason"
								name="reason"
								type="text"
								autoComplete="off"
								
							  />
							</AvGroup>
						</Colxx>

					</Row> */}
				
				
					{/* <ModalFooter> */}
						{/* <Button color="primary" id="complete-order"  onClick={() => (this.state.status=3)} type="submit" >
						 
							Accept
						</Button>{" "}
						<Button color="primary" id="Reject-order" onClick={() => (this.state.status=4)} type="submit">
							Reject
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "} */}
					{/* </ModalFooter> */}
					
					{replies.length ? <hr/>:""}
						{replies.length ? <Row>
							
							
							<Colxx md="12">
								<div className="font-weight-bold h5">Notes</div>
								
								{replies.map(row=>{
									return(<Card className="p-3 mb-3">
									<Row>
										<Colxx md="4">
											<Media>
												<Media left className="mr-2">
													<Media object width="50" src={row.profile_pic!="" ? REST_API_BASE_URL+"uploads/users/"+row.profile_pic:REST_API_BASE_URL+"uploads/users/avatar.jpg"} alt={row.username} />
												</Media>
												<Media body>
													<p className="mb-1">{row.username}</p>
													<p className="mb-1">Created At {moment(row.timestamp*1000).format('MM/DD/YYYY hh:mm a')}</p>
												{row.updated_at!=0?	<p className="mb-1">Updated At {moment(row.updated_at*1000).format('MM/DD/YYYY hh:mm a')}</p>:""}
												</Media>
											</Media>
										</Colxx>
										<Colxx md="8">
											<div className="float-right">
												<Button size="sm" color="danger" className="py-1 px-2  m-2" onClick={()=>{ this.editReply(row.ID,row.body) }} ><i className="simple-icon-pencil" /></Button>
												<Button size="sm" color="danger" className="py-1 px-2" onClick={()=>{ this.deleteReply(row.ID) }} ><i className="far fa-trash-alt" /></Button>
											</div>
											<div dangerouslySetInnerHTML={{__html: row.body}} />
											
											{row.files.length ? <Table hover responsive>										
												<tbody>
													{row.files.map((r,index)=>{
														return(<tr key={index}>
														<td><p className="name mb-0"><span className="open-document btn-link cursor-pointer" onClick={(e)=>{ this.toggleModalViewDoc(e,BASE_URL+'customers/'+r.upload_file_name) }}>{r.upload_file_name}</span></p></td>
														<td>{r.file_size} KB</td>
														{/*<td><Button size="sm" color="danger" className="py-1 px-2" onClick={()=>{ this.deleteReplyFile(r.ID) }} ><i className="far fa-trash-alt" /></Button></td>*/}
													</tr>)
													})}
												</tbody>
											</Table>:""}
										</Colxx>
									</Row>
									</Card>);
								})}
							</Colxx>
						</Row>:""}
						
						<hr/>
						<Row>
						{/* {enquiry_data.status!=8 ? */}
						<Colxx md="12">
								<div className="font-weight-bold h5">Add Note</div>
								
								<AvForm
									id="replyForm"
									ref={c => (this.form = c)}
									className="av-tooltip tooltip-right-bottom"
									 onSubmit={this.handleSubmitNote}
								>
									<Row className="mt-3">
										<Colxx lg="12" className="mb-2">
											<AvGroup>
												<Label>Message Body<span className="text-danger">*</span></Label>
												<ReactQuill
													theme="snow"
													name="body"
													value={this.state.textQuillStandart}
													onChange={this.handleChangeQuillStandart}
													placeholder=""
													modules={quillModules}
													formats={quillFormats}
												/>
											</AvGroup>
										</Colxx>
									</Row>
									{this.state.showFile ? <Row>
										<Colxx lg="12" className="mb-2">
											<AvGroup className="error-l-125 error-t-negative">
												<Label>Attach Files</Label>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
													<CustomInput
														type="file"
														name="files[]"
														id="test_document"
														multiple 
														onChange={this.onMultiFileChange}
													/>
												</InputGroup>
											</AvGroup>
										</Colxx>
									</Row>:""}
									
									<Row className="mt-2 mb-4">
										<Colxx xxs="12">
											<Button	id="reply-order" color="primary" >Submit</Button>
										</Colxx>
									</Row>	
																	
								</AvForm>
								<div id="api_message_documents"></div>
							</Colxx>
							{/* :""} */}
						</Row>

						{this.state.doc_url!=null ? <ViewFileModal 
						modalOpen={this.state.modalOpenViewDoc}
						toggleModal={this.toggleModalViewDoc}
						doc_url={this.state.doc_url}
					/>:""}
					
				{this.state.modalOpenEditReply? <EditReplyModal 
						modalOpen={this.state.modalOpenEditReply}
						toggleModal={this.editReply}
						replyId={this.state.reply_id}
						editBody={this.state.editBody}
						enquiry_id={this.state.enquiry_id}
						dataListRender={this.dataListRender}
					
					/>:""}
					</ModalBody>
				</AvForm>
				<ModalFooter>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.close" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
  }
};
