import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Input, CustomInput, Label, Button, InputGroup, InputGroupAddon } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvInput, AvGroup } from "availity-reactstrap-validation";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class LabelSettings extends Component {
	
	constructor(props) {
		super(props);
		this.state={
			facility_data:{},
			facility_id:"6",
		}
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount(){
		this.dataListRender();
	}
	
	dataListRender(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var user_id = localStorage.getItem('user_id');
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'metrc-location/get-label-settings',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var facility_data=data.facility_data;
				this.setState({
					facility_data,
					data_loaded:true,
					facility_id
				});
			}else{
				this.setState({
					data_loaded:true,
					facility_id
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("settings-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("label_form"));
			formData.append('facility_id',facility_id);
			
			axios.post(REST_API_END_POINT+'metrc-location/update-label-settings',formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("settings-btn").disabled=false;
				if(data.status==1){
					swal("Success",data.msg,"success");					
				}else{
					swal("Failed",data.msg,"warning");
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("settings-btn").disabled=false;
			});

		}
	}
	
	render(){
		const { facility_data, facility_id } = this.state;
		return (
			<Fragment>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.metrc-label-settings" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row className="mb-3">	
										<Colxx md="12">
											<AvForm
												id="label_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											>
												<Row>
													<Colxx lg="12">
														<AvGroup >
															
															<Row>
																<Colxx lg="4">
																	<Label>Label Common Text<span className="text-danger">*</span></Label>
																	<AvField
																		name="metrc_label_common"
																		type="text"
																		autoComplete="off"
																		value={facility_data.metrc_label_common}
																		onChange={(e)=>{
																			let facility_data = this.state.facility_data;
																			facility_data.metrc_label_common=e.target.value;
																			this.setState({ facility_data });
																		}}
																		validate={{
																			required: {
																				value: true,
																				errorMessage: "This field is required."
																			}
																		}}
																	/>
																</Colxx>
																<Colxx lg="4">
																	<Label>Label Start Number<span className="text-danger">*</span></Label>
																	<AvField
																		name="metrc_label_start"
																		type="number"
																		autoComplete="off"
																		value={facility_data.metrc_label_start}
																		onChange={(e)=>{
																			let facility_data = this.state.facility_data;
																			facility_data.metrc_label_start=e.target.value;
																			this.setState({ facility_data });
																		}}
																		validate={{
																			required: {
																				value: true,
																				errorMessage: "This field is required."
																			},
																			min: {
																				value: "0",
																				errorMessage: "The value should be greater than 0"
																			}
																		}}
																	/>
																</Colxx>
																<Colxx lg="4">
																	<Label>Label End Number<span className="text-danger">*</span></Label>
																	<AvField
																		name="metrc_label_end"
																		type="number"
																		autoComplete="off"
																		value={facility_data.metrc_label_end}
																		onChange={(e)=>{
																			let facility_data = this.state.facility_data;
																			facility_data.metrc_label_end=e.target.value;
																			this.setState({ facility_data });
																		}}
																		validate={{
																			required: {
																				value: true,
																				errorMessage: "This field is required."
																			},
																			min: {
																				value: "0",
																				errorMessage: "The value should be greater than 0"
																			}
																		}}
																	/>
																</Colxx>
															</Row>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Button color="primary" id="settings-btn" >
													<IntlMessages id="customer.save-settings" />
												</Button>
											</AvForm>
										</Colxx>
									</Row>									
								</CardBody>
							</Card>
						</Colxx>
					</Row>
			</Fragment>
		);
	}
}
