import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ProductModal from "./ProductModal";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { Colxx } from "../../common/CustomBootstrap";


const ProductGridListSingle = ({
  product,
  currency,
  addToCart,
  cartItem,
  sliderClassName,
  spaceBottomClass
}) => {
  const [modalShow, setModalShow] = useState(false);
  const { addToast } = useToasts();

  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  let product_stock = parseInt(product.product_stock);
  if (typeof product.variations != 'undefined') {
    product_stock = Math.max(...product.variations.map(r => parseInt(r.real_stock)));
  }

  return (
    <Fragment>
      <div
        className={`col-xl-4 col-sm-6 ${sliderClassName ? sliderClassName : ""
          }`}
      >
        <div className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}>
          <div className="product-img">
            <img
              className="default-img"
              src={product.thumbnail_url}
              alt=""
            />
            {product.is_featured_product == "1" ? (
              <div className="product-img-badges">
                <span className="purple">Featured</span>
              </div>
            ) : (
              ""
            )}

            <div className="product-action">
              {product_stock && product_stock > 0 ? "" : (<div className="pro-same-action pro-cart">
                <button disabled className="active">
                  Out of Stock
                </button>
              </div>)}

            </div>
          </div>

          <div className="product-content">
            <Row>
              <Colxx lg={12} className='product-name-container'>
                <h3 className='text-center' dangerouslySetInnerHTML={{ __html: product.name }} ></h3>
              </Colxx>
            </Row>
            <Row className='mt-3'>
              <Colxx lg={12}>
                <div className="display-flex align-items-center justify-content-center">
                  <div className="product-price">{currency.currencySymbol}{parseFloat(finalProductPrice).toFixed(2)} </div>
                </div>
              </Colxx>
              {/* <Colxx lg={6}>
                <div className="pro-same-action pro-quickview text-right">
                  <Button className="btn-home pl-2 pr-2 pt-2 pb-2" onClick={() => setModalShow(true)} title="Quick View">
                    <i className="" /> Buy Now
                  </Button>
                </div>
              </Colxx> */}
            </Row>
            <Row className='mt-3'>
              {/* <Colxx lg={6}>
                <div className="display-flex align-items-center">
                  <span className="product-price">{currency.currencySymbol}{parseFloat(finalProductPrice).toFixed(2)} </span>
                </div>
              </Colxx> */}
              <Colxx lg={12}>
                <div className="pro-same-action pro-quickview text-center">
                  <Button className="btn-home px-5 py-2" onClick={() => setModalShow(true)} title="Quick View">
                    <i className="" /> Quick View
                  </Button>
                </div>
              </Colxx>
            </Row>
          </div>
        </div>

        <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6">
              <div className="product-list-image-wrap">
                <div className="product-img">
                  <img
                    className="default-img img-fluid"
                    src={product.thumbnail_url}
                    alt=""
                  />
                  {product.is_featured_product == "1" ? (
                    <div className="product-img-badges">
                      <span className="purple">Featured</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6">
              <div className="shop-list-content">
                <h3 dangerouslySetInnerHTML={{ __html: product.name }} ></h3>
                <div className="product-list-price">
                  <span>{currency.currencySymbol}{parseFloat(finalProductPrice).toFixed(2)} </span>
                </div>
                {product.description ? (
                  <div dangerouslySetInnerHTML={{ __html: product.description }} ></div>
                ) : (
                  ""
                )}

                <div className="shop-list-actions d-flex align-items-center">
                  <div className="shop-list-btn btn-hover">
                    {product_stock && product_stock > 0 ? "" : (
                      <button disabled className="active">
                        Out of Stock
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        finalproductprice={finalProductPrice}
        cartitem={cartItem}
        addtocart={addToCart}
        addtoast={addToast}
      />
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  addToCart: PropTypes.func,
  cartItem: PropTypes.object,
  currency: PropTypes.object,
  product: PropTypes.object,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default ProductGridListSingle;
