import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup,AvRadio,AvRadioGroup } from "availity-reactstrap-validation";

import { Row, Card, CardHeader, Button, Select, CardBody, FormGroup, Input, CustomInput, Badge, Label, Nav,NavItem, TabContent, TabPane, NavLink } from "reactstrap";
import swal from 'sweetalert';
import classnames from "classnames";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import { Loader } from 'react-loaders';
import ReactToPrint from 'react-to-print';

import ProductGrid from "../../../components/pos/ProductGrid";
import PrintPackage from "../../../components/pos/PrintPackage";

import PrintLabelModal from "../../../containers/pointofsale/PrintLabelModal";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, OUNCE_CONSTANT, CUSTOMER_ORDER_STATUS} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";
import moment from "moment";

import { getCookie, setCookie, getCurrencySymbol } from "../../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = 'Supplier';

export default class CustomerPos extends Component {
	constructor(props) {
		super(props);
		
		let currencySymbol = getCurrencySymbol();
		this.state = {			
		    activeFirstTab: "1",		 
			modalOpenGetPackages: false,
			modalOpenGetMixProducts: false,
			modalOpenApplyBogoOffer: false,
			modalOpenViewAllCoupons: false,
			modalOpenShowProducts: false,
			modalOpenEdit: false,
			modalOpenPrintLabel: false,
			data:[],
			deals:[],
			mixdeals:[],
			search: '',
			points: [],
			selectedIds:[],
			price_point_id:0,
			selectAll: 0,
			
			blocking:true,
			currency_code:'USD',
			currency_rate:1,
			currency_symbol:currencySymbol,
			
			customer_id:0,
			product_id:0,
			mixdeal_row:null,
			deal_id:0,
			customer:null,
			bogo_infos: [],
			coupon_infos: [],
			applied_coupon_infos: [],
			auto_apply_coupons: [],
			orders: [],
			products: [],
			categories: [],
			thc_categories: {
				medical_marijuana:[],
				edibles:[],
				non_edibles:[],
			},
			currencies: [],
			cart: [],
			cart_totals:{
				subtotal:0,
				discount:0,
				tax:0,
				sgst:0,
				cgst:0,
				total:0,				
				thc_medical:0,
				thc_edible:0,
				thc_non_edible:0,
				auto_apply_discount:0,
				total_due:0
			},
			bogo_pop_up: 0,
			parent_product: 0,
			bogo_products: [],
			coupon:'',
			facility:null,
			print_pos_label:null,
			payment_data:null,
			delivery_date:null,
			checkout_mode:"",
			hypur_transaction_id:"",
			change_data:null,
			is_utgst:false,
			is_igst:true,
			tax_percentage:0,
		};
		
		this.addToCart = this.addToCart.bind(this); 
		this.recalculateTotals = this.recalculateTotals.bind(this); 
		this.checkOut = this.checkOut.bind(this); 
		this.resetPaymentData = this.resetPaymentData.bind(this); 
		this.toggleFirstTab = this.toggleFirstTab.bind(this); 

	}
	
	componentDidMount(){  
		const user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);		
        formData.append('order_id',this.props.match.params.order_id);
        formData.append('user_time',moment().format('HH:mm:00'));
		
		
		axios.post(REST_API_END_POINT+'purchase-order/edit-order-data',formData).then((res)=>{
			var data = res.data;
			let is_utgst = false;
			let is_igst = false;
			
			if(data.customer){
				var cart = data.cart;
				var whole_order_data = data.whole_order_data;
				var customer_id = data.customer_id;
				
				setCookie('edit_cart',null);
				setCookie('edit_cart_process',null);
				setCookie('currency_code',null);
				setCookie('e_bogo_code',null);
				setCookie('e_payment_data',null);
				setCookie('p_payment_data',null);
				setCookie('e_change_data',null);
				setCookie('edit_pos_customer_id',customer_id);
				
				var currency_code = getCookie('currency_code','string');
				if(currency_code==null){
					currency_code='USD';
				}
				
				if(cart==null){
					cart=[];
				}
				
				var payment_data = null;
				if(payment_data==null){
					payment_data={
						'1_s' : 0,
						'2_s' : 0,
						'5_s' : 0,
						'10_s' : 0,
						'20_s' : 0,
						'50_s' : 0,
						'100_s' : 0,
						'cents' : 0,
						'nickles' : 0,
						'dimes' : 0,
						'quarters' : 0,
						'50_cents' : 0,
						'1_d' : 0,
						'cash' : 0,
						'card_name' : "",
						'card_number' : "",
						'debit' : 0,
						'discount' : 0,
						'discount_value' : 0,
						's_discount_type' : 1,
						'discount_reason' : "",
						'discount_product' : "",
						'coupon_code' : "",
						'coupon_discount' : 0,
						'points' : 0,
						'point_discount' : 0,
					};
				}
				
				if(whole_order_data.points_used!="" && parseInt(whole_order_data.points_used)!=NaN){
					payment_data.points=parseInt(whole_order_data.points_used);
				}
				
				if(whole_order_data.point_discount!="" && parseFloat(whole_order_data.point_discount)!=NaN){
					payment_data.point_discount=parseFloat(whole_order_data.point_discount);
				}
				
				if(whole_order_data.coupon_discount!="" && parseFloat(whole_order_data.coupon_discount)!=NaN && parseFloat(whole_order_data.coupon_discount)>0){
					payment_data.coupon_discount=parseFloat(whole_order_data.coupon_discount);
					let coupon_code = whole_order_data.coupon_code.split(",");
					let last_coupon_code = coupon_code.pop();
					while(last_coupon_code=="" && coupon_code.length>0){
						last_coupon_code = coupon_code.pop()
					}
					if(last_coupon_code!=''){
						payment_data.coupon_code = last_coupon_code;
					}					
				}
				
				if(whole_order_data.manual_discount!="" && parseFloat(whole_order_data.manual_discount)!=NaN && parseFloat(whole_order_data.manual_discount)>0){
					payment_data.discount=parseFloat(whole_order_data.manual_discount);
					payment_data.s_discount_type=parseInt(whole_order_data.manual_discount_type);
					payment_data.discount_value=parseFloat(whole_order_data.manual_discount_value);
					payment_data.discount_reason=whole_order_data.reason_for_discount;
				}
				
				setCookie('e_payment_data',payment_data);
				
				const currencies= data.currencies;
				const selected_currency = currencies.find(row=>{ return row.currency_code==currency_code });
				var currency_rate = 1;
				var currency_symbol = getCurrencySymbol();
				if(selected_currency!=null){
					currency_rate = selected_currency.currency_rate;
					//currency_symbol = selected_currency.currency_symbol;
				}
				
				let products = data.products.filter(r=>{ return parseInt(r.product_stock)>0 });
				let activeFirstTab="1";
				for(let key in data.categories){
					const filtered_products = products.filter(r=>{ return r.category_id==data.categories[key].category_id });
					if(filtered_products.length>0){
						activeFirstTab = (parseInt(key)+1).toString();
						break;
					}
				}
				
				is_igst = data.customer.vendor_state !== data.facility.facility_state_id;
				if(!is_igst){
					is_utgst = data.customer.is_utgst=="1";
				}
				
				this.setState({
					customer_id,
					customer: data.customer,
					bogo_infos: data.bogo_infos,
					coupon_infos: data.coupon_infos,
					auto_apply_coupons: data.auto_apply_coupons,
					tax_percentage: data.tax_percentage,
					products: products,
					deals: data.deals,
					mixdeals: data.mixdeals,
					currencies,
					categories: data.categories,
					thc_categories: data.thc_categories,
					facility: data.facility,
					currency_code,
					currency_rate,
					currency_symbol,
					blocking:false,
					payment_data,
					activeFirstTab,
					//is_utgst,
					//is_igst
				},()=>{
					if(cart!=null){
						this.recalculateTotals(cart);
					}
				});
			}else{
				swal("Warning!",data.msg,"warning").then(()=>{
					window.location="/app/wholesale/all-orders";
				});
				setTimeout(()=>{
					window.location="/app/wholesale/all-orders";
				},4000)
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleModalPrintLabel = () =>{
		window.location="/app/wholesale/all-orders";
	}
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
	
	addToCart(values) {
        const user_id = localStorage.getItem('user_id');
		const customer_id = this.state.customer_id;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var bogo_code = getCookie('e_bogo_code','string');
		
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);		
        formData.append('customer_id',customer_id);
        formData.append('bogo_code',bogo_code);
		
		if(this.state.bogo_pop_up==1){
			formData.append('free_product',1);
			formData.append('parent_product',this.state.parent_product);
		}else{
			formData.append('free_product',0);
			formData.append('parent_product',0);
		}
		
		for(let v in values){
			 formData.append(v,values[v]);
		}
		
		axios.post(REST_API_END_POINT+'purchase-order/add-to-cart',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				this.setState({
					product_id:0,
					modalOpenShowProducts:false,
					modalOpenGetPackages: false,
					modalOpenGetDeals: false,
				});
				
				var cart_item = data.cart_item;
				var insert_id = data.insert_id;
				const bogo_pop_up = data.bogo_pop_up;
				
				var cart = this.state.cart;
				
				cart.push(cart_item);
				setCookie('edit_cart',cart);
				
				var bogo_products=[];
				
				if(bogo_pop_up==1 && data.bogo_pop_up_products.length){
					bogo_products = data.bogo_pop_up_products;
					this.toggleModalShowProducts();
				}
				
				this.setState({ cart, bogo_pop_up, bogo_products, parent_product:insert_id },()=>{ this.recalculateTotals(); });
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
    }
	
	removeCart(insert_id){
		var cart = this.state.cart;
		cart = cart.filter(row=>{ return row.insert_id!=insert_id && row.parent_product!=insert_id });		
		this.recalculateTotals(cart);
	}
	
	async recalculateTotals(cart=null, show_warnig=false){
		if(cart==null){
			cart=this.state.cart;
		}
		
		var coupons = [];
		if(this.state.coupon!=''){
			coupons = this.state.coupon.split(',');		
		}
		
		const customer_data=this.state.customer;
		const payment_data=this.state.payment_data;
		const auto_apply_coupon_datas=this.state.auto_apply_coupons;
		const coupon_infos=this.state.coupon_infos;
		const applied_coupon_infos=this.state.applied_coupon_infos;
		const thc_categories=this.state.thc_categories;
			
		var coupon_discount=0;
		var auto_apply_discount=0;
		var coupon_datas=[];
		for(let coupon of coupons){
			if(coupon!=''){
				let coupon_data = applied_coupon_infos.find(r=>{ return r.code==coupon }); 
				if(coupon_data==null){
					const user_id = localStorage.getItem('user_id');
					const customer_id = this.state.customer_id;
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var bogo_code = getCookie('e_bogo_code','string');
					
					var formData = new FormData();
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);		
					formData.append('customer_id',customer_id);
					formData.append('coupon',coupon);
					await axios.post(REST_API_END_POINT+'purchase-order/get-applied-coupon-detail',formData).then((res)=>{
						var data = res.data;
					
						if(data.status==1){
							coupon_data = data.coupon_data;
							applied_coupon_infos.push(coupon_data);
						}
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				}
				if(coupon_data!=null){
					if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){					
						coupon_discount += parseFloat(coupon_data['amount']);
					}
					coupon_datas.push(coupon_data);
				}
			}			
		}
		
		for(let coupon_data of auto_apply_coupon_datas){
			if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){
				auto_apply_discount+=parseFloat(coupon_data['amount']);
			}		
		}
		
		var cart_totals = {
			subtotal:0,
			discount:0,
			tax:0,
			sgst:0,
			cgst:0,
			total:0,
			thc_medical:0,
			thc_edible:0,
			thc_non_edible:0,
			auto_apply_discount:0,
			total_due:0
		}
		
		for(let key in cart){
			let row = cart[key];		
			row.thc = row.actual_weight;
			
			if(row['is_finished']!=1){
				let variation_data = row.variations.find(r=>{ return row['options']['variation_id']==r['variation_id'] });
				if(variation_data!=null){
					if(variation_data['qty_type']==0){
						row['max_quantity'] = parseFloat(row['real_quantity'])*1000/parseFloat(variation_data['quantity']);
					}else if(variation_data['qty_type']==2){
						row['max_quantity'] = parseFloat(row['real_quantity'])/OUNCE_CONSTANT/parseFloat(variation_data['quantity']);
					}else{
						row['max_quantity'] = parseFloat(row['real_quantity'])/parseFloat(variation_data['quantity']);
					}
				}
			}
			
			if(auto_apply_coupon_datas.length && row.deal_id==0){
				for(let coupon_data of auto_apply_coupon_datas){
					let product_ids=coupon_data['product_id'].split(',');
					let	category_ids=coupon_data['category_id'].split(',');
					let product_discount = 0;
					if(((product_ids.indexOf(row.id.toString())!=-1 && coupon_data['is_product']=='3') || (category_ids.indexOf(row.category_id.toString())!=-1 && coupon_data['is_product']=='4') || (category_ids.indexOf(row.sub_category.toString())!=-1 && coupon_data['is_product']=='4') || coupon_data['is_product']=='2') && row['options']['is_free']==0){
						if(coupon_data['type']=='0'){
							product_discount=parseFloat(coupon_data['amount']);	
						}else{ 
							product_discount=((row.unit_price+(row.unit_sales_tax*row.unit_price))*row.qty)*parseFloat(coupon_data['amount'])/100;
						}
						if(product_discount>parseFloat(((row.unit_price+(row.unit_sales_tax*row.unit_price))*row.qty).toFixed(2))){							
							product_discount=0;
						}
					}
					row.discount = parseFloat(product_discount.toFixed(2));
				}
			}else{
				row.discount = 0;
			}
			
			if(row.coupon_code!=''){
				let coupon_data = applied_coupon_infos.find(r=>{ return r.code==row.coupon_code }); 
				if(coupon_data==null){
					const user_id = localStorage.getItem('user_id');
					const customer_id = this.state.customer_id;
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);		
					formData.append('customer_id',customer_id);
					formData.append('coupon',row.coupon_code);
					await axios.post(REST_API_END_POINT+'purchase-order/get-applied-coupon-detail',formData).then((res)=>{
						var data = res.data;
					
						if(data.status==1){
							coupon_data = data.coupon_data;
							applied_coupon_infos.push(coupon_data);
						}
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				}
				if(coupon_data!=null){
					let product_ids= coupon_data['is_product']!='0' ? coupon_data['product_id'].split(','):[];
					let	category_ids=coupon_data['is_product']!='0' ? coupon_data['category_id'].split(','):[];
					let product_discount = 0;
			
					if(((product_ids.indexOf(row.id.toString())!=-1 && coupon_data['is_product']=='3') || (category_ids.indexOf(row.category_id.toString())!=-1 && coupon_data['is_product']=='4') || (category_ids.indexOf(row.sub_category.toString())!=-1 && coupon_data['is_product']=='4') || coupon_data['is_product']=='2' || coupon_data['is_product']=='0') && row['options']['is_free']==0){
						if(coupon_data['type']=='0'){
							product_discount=parseFloat(coupon_data['amount']);	
						}else{ 
							product_discount=((row.unit_price+(row.unit_sales_tax*row.unit_price))*row.qty)*parseFloat(coupon_data['amount'])/100;
						}
						if(product_discount>parseFloat(((row.unit_price+(row.unit_sales_tax*row.unit_price))*row.qty).toFixed(2))){							
							product_discount=0;
						}
					}
					if(show_warnig && product_discount==0){
						swal("Warning!","Sorry, this coupon is not applicable now","warning");
						row.coupon_code="";
					}
					row.coupon_discount = parseFloat(product_discount.toFixed(2));
				}
			}else{
				row.coupon_discount =0;
			}
			
			let unit_exise_tax = JSON.parse(JSON.stringify(row.unit_exise_tax));
			let unit_sales_tax = JSON.parse(JSON.stringify(row.unit_sales_tax))*row.unit_price;
			let unit_sgst_tax = JSON.parse(JSON.stringify(row.unit_sgst_tax))*row.unit_price;
			let unit_cgst_tax = JSON.parse(JSON.stringify(row.unit_cgst_tax))*row.unit_price;
			
			if(row.unit_price!=0){					
				let tot_price=row.unit_price*row.qty-row.discount-row.coupon_discount;
				//unit_exise_tax=(unit_exise_tax/row.unit_price)*tot_price/row.qty;
				//unit_sales_tax=(unit_sales_tax/row.unit_price)*tot_price/row.qty;
				
				row.price = tot_price/row.qty+unit_exise_tax+unit_sales_tax;
			}
			
			row.exise_tax = parseFloat((row.qty*unit_exise_tax).toFixed(2));
			//row.sales_tax = parseFloat((row.qty*unit_sales_tax).toFixed(2));
			row.sgst_tax = parseFloat((row.qty*unit_sgst_tax).toFixed(2));
			row.cgst_tax = parseFloat((row.qty*unit_cgst_tax).toFixed(2));
			row.sales_tax = row.sgst_tax+row.cgst_tax;
			row.tax = row.exise_tax+row.sales_tax;
			cart_totals.tax+=row.tax;
			cart_totals.sgst+=row.sgst_tax;
			cart_totals.cgst+=row.cgst_tax;
			
			cart_totals.subtotal += parseFloat((row['unit_price']*row['qty']).toFixed(2));
			cart_totals.discount += parseFloat((row['discount']+row['coupon_discount']).toFixed(2));
			cart_totals.total += parseFloat((row['unit_price']*row['qty']-row['discount']-row['coupon_discount']+row.tax).toFixed(2));
			
			if(thc_categories.medical_marijuana.indexOf(row['category_id'])!=-1 || thc_categories.medical_marijuana.indexOf(row['sub_category'])!=-1){
				cart_totals.thc_medical+=parseFloat(row.thc);
			}else if(thc_categories.edibles.indexOf(row['category_id'])!=-1 || thc_categories.edibles.indexOf(row['sub_category'])!=-1){
				cart_totals.thc_edible+=parseFloat(row.thc);
			}else if(thc_categories.non_edibles.indexOf(row['category_id'])!=-1 || thc_categories.non_edibles.indexOf(row['sub_category'])!=-1){
				cart_totals.thc_non_edible+=parseFloat(row.thc);
			}
			
			cart[key] = row;
		}
		
		if(coupon_datas.length){
			for(let coupon_data of coupon_datas){
				if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
					coupon_discount+=cart_totals.total*parseFloat(coupon_data['amount'])/100;
				}
			}
		}
		
		if(cart_totals.total<coupon_discount){
			coupon_discount=0;
		}		
		
		for(let coupon_data of auto_apply_coupon_datas){
			if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
				auto_apply_discount+=cart_totals.total*parseFloat(coupon_data['amount'])/100;
			}		
		}
		
		if(cart_totals.total<auto_apply_discount){
			auto_apply_discount=0;
		}
		
		cart_totals.discount += parseFloat(coupon_discount+auto_apply_discount+((payment_data.discount_value+payment_data.point_discount)/this.state.currency_rate));
		cart_totals.total -= parseFloat(coupon_discount+auto_apply_discount+((payment_data.discount_value+payment_data.point_discount)/this.state.currency_rate));
		
		cart_totals.auto_apply_discount = auto_apply_discount;
		cart_totals.total_due = cart_totals.total*this.state.currency_rate - payment_data.cash - payment_data.debit;
		
		this.setState({ cart, cart_totals, applied_coupon_infos });
		setCookie('cart',cart);
	}
	
	placeOrderFinal = () =>{
		this.setState({ blocking:true });
		const currency_rate = this.state.currency_rate;
		const currency_code = this.state.currency_code;
		
		const delivery_date = this.state.delivery_date;
		const checkout_mode = "Express";
		const hypur_transaction_id = this.state.hypur_transaction_id;
		const payment_data = this.state.payment_data;
		const change_data = this.state.change_data;
		const cart_totals = this.state.cart_totals;
		var cart = JSON.parse(JSON.stringify(this.state.cart));
		
		cart = cart.map(row=>{
			row.unit_price = row.unit_price*currency_rate;
			row.price = row.price*currency_rate;
			row.discount = row.discount*currency_rate;
			row.exise_tax = row.exise_tax*currency_rate;
			row.sales_tax = row.sales_tax*currency_rate;
			row.sgst_tax = row.sgst_tax*currency_rate;
			row.cgst_tax = row.cgst_tax*currency_rate;
			row.tax = row.tax*currency_rate;
			row.free_discount = row.free_discount*currency_rate;
			row.coupon_discount = row.coupon_discount*currency_rate;
			return row;
		})
		
		const user_id = localStorage.getItem('user_id');
		const customer = this.state.customer;
		const customer_id = this.state.customer_id;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var bogo_code = getCookie('e_bogo_code','string');
		
        var formData = new FormData(); 
		let bill_address=[];
		/*if(customer.vendor_address!=null && customer.vendor_address!=""){
			bill_address.push(customer.vendor_address);
		}
		if(customer.vendor_city!=null && customer.vendor_city!=""){
			bill_address.push(customer.vendor_city);
		}
		if(customer.vendor_state_name!=null && customer.vendor_state_name!=""){
			bill_address.push(customer.vendor_state_name);
		}
		if(customer.zip_code!=null && customer.zip_code!=""){
			bill_address.push(' - '+customer.zip_code);
		}*/
		
		formData.append('customer_state_id',customer.vendor_state);
		formData.append('first_name',customer.vendor_name);
		formData.append('email_id',customer.email_id);
		formData.append('phone',customer.phone);
		formData.append('bill_address',bill_address.join(", "));
		formData.append('ship_address',bill_address.join(", "));
		
        formData.append('facility_id',facility_id);
        formData.append('user_id',user_id);		
        formData.append('customer_id',customer_id);
        formData.append('bogo_code',bogo_code);
        formData.append('currency_code',currency_code);
        formData.append('currency_rate',currency_rate);
        formData.append('checkout_mode',checkout_mode);
        formData.append('delivery_date',delivery_date);
        formData.append('payment_data',JSON.stringify(payment_data));
        formData.append('hypur_transaction_id',hypur_transaction_id);
		if(typeof change_data!='undefined' && change_data!=null){
			formData.append('change',JSON.stringify(change_data));
		}
		
		formData.append('cart_products',JSON.stringify(cart));
		
		formData.append('auto_apply_discount',cart_totals.auto_apply_discount*currency_rate);
		formData.append('total_tax',cart_totals.tax*currency_rate);
		formData.append('thc_medical',cart_totals.thc_medical);
		formData.append('thc_edible',cart_totals.thc_edible);
		formData.append('thc_non_edible',cart_totals.thc_non_edible);
		formData.append('total_thc',parseFloat((cart_totals.thc_medical+cart_totals.thc_edible+cart_totals.thc_non_edible).toFixed(2)));
		
		formData.append('order_id',this.props.match.params.order_id);
		
		axios.post(REST_API_END_POINT+'purchase-order/update-place-order',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				setCookie('edit_pos_customer_id',null);
				setCookie('edit_cart',null);
				setCookie('currency_code',null);
				setCookie('e_bogo_code',null);
				setCookie('e_payment_data',null);
				setCookie('e_change_data',null);
				this.setState({ modalOpenChangePayment:false, modalOpenPrintLabel:true, blocking:false, order_id:data.order_id, success_msg:data.msg });
				/*swal("Success!",data.msg,"success").then(()=>{
					window.location="/app/wholesale/supplier-lookup";
				});
				setTimeout(()=>{
					window.location="/app/wholesale/supplier-lookup";
				},4000);*/
			}else{
				swal("Warning!",data.msg,"warning");
				this.setState({ modalOpenChangePayment:false, blocking:false });
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	checkOut = () =>{
		
		const cart = this.state.cart;
		if(cart.length){
			let proceed=true;
			/*for(let row of cart){
				if(parseInt(row.max_quantity)<row.qty){
					proceed=false;
					break;
				}
			}*/
			if(proceed){
				this.placeOrderFinal();
			}else{
				swal("Warning!","Please check the quantity in cart","warning");
			}
		}else{
			swal({
			  title: "Are you sure?",
			  text: "There is no products in the cart",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
				if (willDelete) {
					const customer_id = this.state.customer_id;						
					var formData = new FormData();
					formData.append('customer_id',customer_id);
					
					axios.post(REST_API_END_POINT+'purchase-order/checkout-customer',formData).then((res)=>{
						setCookie('edit_pos_customer_id',null);
						setCookie('e_cart',null);
						setCookie('currency_code',null);
						setCookie('e_bogo_code',null);
						setCookie('e_payment_data',null);
						setCookie('e_change_data',null);
						window.location="/app/wholesale/all-orders";
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
					});
				}else{
					swal("Continue ordering!");
				}
			});
		}
	} 
	
	printPOSLabel = (variation_id,quantity,actual_weight)  => {
		const customer_id = this.state.customer_id;
		var user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		formData.append('customer_id',customer_id);
		formData.append('variation_id',variation_id);
		formData.append('quantity',quantity);
		formData.append('actual_weight',actual_weight);
			
		axios.post(REST_API_END_POINT+'purchase-order/print-pos-product-label',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var print_pos_label = data.order;

				this.setState({ print_pos_label });
				setTimeout(function(){ document.getElementById('print-pos-label-hidden').click() }, 500)
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	resetPaymentData = ()  => {	
		const payment_data={
						'1_s' : 0,
						'2_s' : 0,
						'5_s' : 0,
						'10_s' : 0,
						'20_s' : 0,
						'50_s' : 0,
						'100_s' : 0,
						'cents' : 0,
						'nickles' : 0,
						'dimes' : 0,
						'quarters' : 0,
						'50_cents' : 0,
						'1_d' : 0,
						'cash' : 0,
						'card_name' : "",
						'card_number' : "",
						'debit' : 0,
						'discount' : 0,
						'discount_value' : 0,
						's_discount_type' : 1,
						'discount_reason' : "",
						'discount_product' : "",
						'coupon_code' : "",
						'coupon_discount' : 0,
						'points' : 0,
						'point_discount' : 0,
					};
					
		var cart_totals = this.state.cart_totals;
		cart_totals.total_due = cart_totals.total*this.state.currency_rate - payment_data.cash - payment_data.debit;
		this.setState({ payment_data, cart_totals });
		setCookie('payment_data',payment_data);
	}
	 
  
	render() {
		const { customer, currencies, categories, bogo_infos, coupon_infos,	orders, products, cart, cart_totals, payment_data, is_utgst } = this.state;
		let data = JSON.parse(JSON.stringify(this.state.cart));
	  
		if (this.state.search) {
			data = data.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		let taxFields = [];
		//if(this.state.is_igst){
			taxFields = [
				{
					Header: "VAT"+" ("+this.state.tax_percentage+"%)",
					accessor: "sales_tax",				
					Cell: props => {
						const row = props.original;
						const formated_value = number_format(parseFloat(row['sales_tax']*this.state.currency_rate).toFixed(2),2,'.','');
						return(<p className="text-muted mt-1">{this.state.currency_symbol}{" "+formated_value}</p>)
					}
				}
			];
		/*}else{
			taxFields = [
				{
					Header: is_utgst ? "UTGST":"SGST",
					accessor: "sgst_tax",				
					Cell: props => {
						const row = props.original;
						const formated_value = number_format(parseFloat(row['sgst_tax']*this.state.currency_rate).toFixed(2),2,'.','');
						return(<p className="text-muted mt-1">{this.state.currency_symbol}{" "+formated_value}</p>)
					}
				},
				{
					Header: "CGST",
					accessor: "cgst_tax",				
					Cell: props => {
						const row = props.original;
						const formated_value = number_format(parseFloat(row['cgst_tax']*this.state.currency_rate).toFixed(2),2,'.','');
						return(<p className="text-muted mt-1">{this.state.currency_symbol}{" "+formated_value}</p>)
					}
				}
			]
		}*/
		
		const dataTableColumns = [
			{
				Header: "Product",
				accessor: "name",
				Cell: (props) => {
					const row = props.original;
					const variations = row.variations;
					var cart_row='';
					//if(variations.length){
						//let variation = variations.find(r=>{ return row['options']['variation_id']==r['variation_id'] });
						//if(variation!=null){
							//if(variation['stock']<row['qty']){							
								/*if(parseInt(parseFloat(props.original.max_quantity))<=0){
									cart_row=<Fragment><br/><span className="text-danger">Out of Stock. Please Remove this item.</span></Fragment>
								}else{
									cart_row=<Fragment><br/><span className="text-danger">Only {parseInt(parseFloat(props.original.max_quantity))} is left in stock</span></Fragment>;
								}*/						
							//}
						//}
					//}			
					return(<p className="list-item-heading mt-1">{props.value}{cart_row}</p>)
				}
			},
			{
				Header: "Quantity",
				accessor: "qty",
				width:110,
				Cell:  (props) => {
							let variation = props.original.variations.find(v=>{ return props.original['options']['variation_id']==v['variation_id'] });
							let variation_name='';
							if(variation!=null){
								variation_name = variation.variation_name;
							}
							return(<Fragment>
								{props.original['options']['is_free']==1 ? <span>{props.value} x </span>:<AvField
									name={"quantity_"+props.original.insert_id}
									id={"quantity_"+props.original.insert_id}
									type="number"
									className="w-100 mt-1" 
									value={props.value.toString()}
									min="0"
									onChange={(e)=>{
										const value = props.original['is_finished']==1 ? parseInt(e.target.value):e.target.value;										
									}}
									onBlur={(e)=>{
										const value = e.target.value!="" ? (props.original['is_finished']==1 ? parseInt(e.target.value):e.target.value):e.target.value;
										var cart = this.state.cart;

										if(value!='' && parseInt(value)>=0  ){ //&& parseFloat(value)<=parseFloat(parseFloat(props.original.max_quantity)) ){
											cart = cart.map(r=>{
												if(r.insert_id==props.original.insert_id || r.parent_product==props.original.insert_id){
													r.qty=parseInt(value)
												}
												return r;
											});
										}
										this.recalculateTotals(cart);
									}}
								/>}
								
								{parseInt(props.original['product_type'])==1 ? <Fragment>
									<AvField
										name={"actual_weight_"+props.original.insert_id}
										id={"actual_weight_"+props.original.insert_id}
										type="number"
										min="0"
										className="w-100 mt-1" 
										value={props.original.actual_weight.toString()}
										onChange={(e)=>{
											const value = e.target.value;
										}}
										onBlur={(e)=>{
											const value = e.target.value!="" ? (parseFloat(e.target.value)>=0 ? e.target.value:0):e.target.value
											var cart = this.state.cart;
											cart = cart.map(r=>{
												if(r.insert_id==props.original.insert_id || r.parent_product==props.original.insert_id){
													r.actual_weight=parseFloat(value)
												}
												return r;
											});
											this.recalculateTotals(cart);
										}}
									/>THC</Fragment>:""}
				            </Fragment>)
				}							
			},
			{
				Header: "Unit Price",
				accessor: "unit_price",				
				Cell: props => {
					const row = props.original;
					if(props.original['options']['is_free']==1){
						const formated_value = number_format(parseFloat((row['unit_price']*row['qty'])*this.state.currency_rate).toFixed(2),2,'.','');
						return(<p className="text-muted mt-1">{this.state.currency_symbol}{" "+formated_value}</p>);
					}else{
						return (<Fragment>
										{/*<div>Unit Price</div>*/}
										<AvField
											name={"unit_price_"+props.original.insert_id}
											id={"unit_price_"+props.original.insert_id}
											type="number"
											min="0"
											className="w-100 mt-1" 
											value={props.original.unit_price.toString()}
											onChange={(e)=>{
												const value = props.original['is_finished']==1 ? parseInt(e.target.value):e.target.value;										
											}}
											onBlur={(e)=>{
												const value = e.target.value;
												var cart = this.state.cart;

												if(value!='' && parseFloat(value)>=0  ){
													cart = cart.map(r=>{
														if(r.insert_id==props.original.insert_id || r.parent_product==props.original.insert_id){
															r.unit_price=parseFloat(value)
														}
														return r;
													});
												}
												this.recalculateTotals(cart);
											}}
										/>
										
								</Fragment>)
					}
					
				}
			},
			...taxFields,
			{
				Header: "Amount",
				accessor: "price",				
				Cell: props => {
					const row = props.original;
					const formated_value = number_format(parseFloat((row['unit_price']*row['qty']-row['discount']-row['coupon_discount']+row['exise_tax']+row['sales_tax'])*this.state.currency_rate).toFixed(2),2,'.','');
					return(<p className="text-muted mt-1">{this.state.currency_symbol}{" "+formated_value}</p>)
				}
			},
			{
				Header: "",
				accessor: "insert_id",
				width:60,
				Cell: props =><Fragment>
								{props.original['options']['is_free']==1 ? "":<span className="pl-1 pr-1 text-danger cursor-pointer cart-op-icons" onClick={(e)=>{ this.removeCart(props.value) }} ><i className="fa fa-times" /></span>}
				            </Fragment>
			}
		];
		
    return (
      <Fragment>
        <BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
			<Card className="p-2">
				<div className="d-flex">
					<div className="pos-top-left">
						{customer!=null ? <img alt={customer.vendor_name} src={customer.profile_pic} style={{maxWidth:'80px'}}/>:""}
					</div>
					<div className="pos-top-right">
						<Row className="align-items-center">
							<Colxx lg="10">
								<h3 className="text-center mt-4 text-danger">Purchase Order</h3>
								
								<AvForm
									className="av-tooltip tooltip-right-bottom d-none"
									onSubmit={()=>{}}
								>
									<h5 className="mb-2 text-center"><strong>Today's Conversion Rate</strong></h5>
									<Row className="align-items-center">
										<Colxx lg="3">
											<Label>Choose Currency</Label>
										</Colxx>
										<Colxx lg="4">
											<AvField
												type="select"
												name="currency_code"
												id="currency_code"
												value={this.state.currency_code}
												onChange={(e)=>{ 
													const currency_code = e.target.value;
													const selected_currency = currencies.find(row=>{ return row.currency_code==currency_code });
													var currency_rate = 1;
													var currency_symbol = '$';
													if(selected_currency!=null){
														currency_rate = selected_currency.currency_rate;
														currency_symbol = selected_currency.currency_symbol;
													}
													setCookie('currency_code',currency_code);
													this.setState({ currency_code, currency_rate, currency_symbol },()=>{ this.resetPaymentData(); });
													this.resetPaymentData();
												}}
												validate={{
													required: {
														value: true,
														errorMessage: "This field is required."
													}
												}}
											>
												{currencies.map(opt=>{
													return <option key={opt.id} value={opt.currency_code} >{opt.country_name+" - "+opt.currency_code}</option>
												})}
											</AvField>
										</Colxx>
										<Colxx lg="5">
											<Label>Currency Rate: {this.state.currency_rate}</Label>
										</Colxx>
									</Row>	
								</AvForm>
							</Colxx>
							<Colxx lg="2">
								<div className="clearfix">
									<div className="float-sm-right ml-2 mr-2">
										<NavLink href={"/app/wholesale/supplier-lookup"} className="btn btn-sm btn-primary mt-1" >Back</NavLink>
									</div>
									<div className="float-sm-right ml-2 mr-2 mt-2 d-none">
										<h5 className="mb-0"><strong>Change: </strong>{this.state.currency_symbol}{" "} {cart_totals.total_due<0 ? number_format(parseFloat(cart_totals.total_due*-1).toFixed(2),2,'.',''):"0"}</h5>
									</div>
									<div className="float-sm-right ml-2 mr-2 mt-2 d-none">
										<h5 className="mb-0"><strong>Current Payment: </strong>{this.state.currency_symbol}{" "} {payment_data!=null ? number_format(parseFloat(payment_data.cash+payment_data.debit).toFixed(2),2,'.',''):"0"}</h5>
									</div>
									<div className="float-sm-right ml-2 mr-2 mt-2 d-none">
										<h5 className="mb-0"><strong>Current {customerWord} Points: </strong>{customer!=null ? customer.price_points:"0"}</h5>
									</div>
								</div>
								{/*<hr className="mt-2 mb-2"/>
								<h5 className="mb-0 text-center"><strong>Unit Conversion Rates</strong> - <span><b>1 MG:</b> 0.001 G </span>, <span><b>1 Oz:</b> 28 G</span></h5>
								*/}
							</Colxx>
						</Row>
					</div>
				</div>				 
			</Card>
			<Row>
				<Colxx lg="5">
					<Card className="mt-3">
						<CardHeader>
							<Nav tabs className="card-header-tabs ">
								{categories.map((row,index)=>{
									const filtered_products = products.filter(r=>{ return r.category_id==row.category_id });
									if(filtered_products.length>0){
										return(<NavItem className="cursor-pointer" key={index}>
											<NavLink
												className={classnames({
												  active: this.state.activeFirstTab === (index+1).toString(),
												  "nav-link": true
												})}
												onClick={() => {
												  this.toggleFirstTab((index+1).toString());
												}}
												to="#"
											>
												{row.name}
											</NavLink>
										</NavItem>)
									}else{
										return "";
									}
								})}
								
							</Nav>
						</CardHeader>
						
						<div className="pos-product-wrapper">
							{categories.map((row,index)=>{
								const filtered_products = products.filter(r=>{ return r.category_id==row.category_id });
								if(filtered_products.length>0){
									return(<TabContent activeTab={this.state.activeFirstTab} key={index}>
										<TabPane tabId={(index+1).toString()}>
											<ProductGrid 
												products={filtered_products}
												currency_rate={this.state.currency_rate}
												currency_symbol={this.state.currency_symbol}
												toggleModalGetPackages={this.toggleModalGetPackages}
												index={index}
												addToCart={this.addToCart}
											/>
										</TabPane>
									</TabContent>)
								}else{
									return "";
								}
							})}
							
						</div>
					</Card>
				</Colxx>
				<Colxx lg="7" >
					 <Card className="mt-3">
						<div className="mt-4 mb-1 ml-4 mr-4"> <h3><strong>Order for {customer!=null ? customer.vendor_name:""} <span className="float-sm-right d-none"> (10:18:12)</span></strong></h3></div>
						<div className="mb-4 pt-4 pb-4 pr-2 pl-2 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value})}
									/>
								</Colxx>
							</FormGroup>			
							<AvForm
								className="av-tooltip tooltip-right-bottom compact-cart-form"
								onSubmit={()=>{}}
							>
								<ReactTable
									data={data}
									columns={dataTableColumns}
									defaultPageSize={10}
									minRows = {2}
									filterable={false}
									showPageJump={false}
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{id: "send_on",desc: false}]}
								/>
							</AvForm>
						</div>
						<Row className="text-right">
							<Colxx lg="6">
								<div className="d-none">
									<div className="bg-black ">
										<p className="pl-4 pr-4 pb-2 pt-2"><span className="font-weight-bold">Medical Marijuana </span>: {number_format(parseFloat(cart_totals.thc_medical).toFixed(2),2,'.','')}</p>
									</div>
									<div className="bg-black ">
										<p className="pl-4 pr-4 pb-2 pt-2"> <span className="font-weight-bold"> Edibles </span> : {number_format(parseFloat(cart_totals.thc_edible).toFixed(2),2,'.','')}</p>
									</div>
									<div className="bg-black ">
										<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold"> Non-Edibles </span> : {number_format(parseFloat(cart_totals.thc_non_edible).toFixed(2),2,'.','')}</p>
									</div>
									<div className="bg-black ">
										<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold"> Total THC </span>: {number_format(parseFloat(cart_totals.thc_medical+cart_totals.thc_edible+cart_totals.thc_non_edible).toFixed(2),2,'.','')}</p>
									</div>
								</div>
							</Colxx>
							<Colxx lg="6">
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2"><span className="font-weight-bold">Sub - Total </span>: {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.subtotal*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
								<div className="bg-black d-none">
									<p className="pl-4 pr-4 pb-2 pt-2"> <span className="font-weight-bold">Total Discount </span> : {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.discount*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
								{this.state.is_igst ? <div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">VAT ({this.state.tax_percentage}%) </span> : {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.tax*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>:<Fragment>
									<div className="bg-black ">
										<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">{this.state.is_utgst ? "UTGST":"SGST"} </span> : {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.sgst*this.state.currency_rate).toFixed(2),2,'.','')}</p>
									</div>
									<div className="bg-black ">
										<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">CGST </span> : {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.cgst*this.state.currency_rate).toFixed(2),2,'.','')}</p>
									</div>
								</Fragment>}
								<div className="bg-black ">
									<p className="pl-4 pr-4 pb-2 pt-2 "> <span className="font-weight-bold">Grand Total </span>: {this.state.currency_symbol}{" "}{number_format(parseFloat(cart_totals.total*this.state.currency_rate).toFixed(2),2,'.','')}</p>
								</div>
							</Colxx>
						</Row>
						
						<Row className="pl-2 big-btn-font pr-2">
							<Colxx lg="12" className="mb-4">
								<Button block size="sm" color="success" onClick={this.checkOut} >Update Order</Button>
							 </Colxx>
						</Row>
					</Card>
				</Colxx>
			</Row>
			
			{this.state.print_pos_label!=null ? <div className={"d-none"}>
				<ReactToPrint
					trigger={() => <span className="d-block cursor-pointer" id="print-pos-label-hidden" >Print</span>}
					content={() => this.componentRef}
					copyStyles={true}
					pageStyle={""}
				/>
				<PrintPackage 
					ref={el => (this.componentRef = el)} 
					print_pos_label={this.state.print_pos_label} 
					facility={this.state.facility}
				/>
			</div>:""}
			
			<PrintLabelModal
				modalOpen={this.state.modalOpenPrintLabel}
				toggleModal={this.toggleModalPrintLabel}
				order_id={this.state.order_id}
				modal_head={"Order has been updated"}
				success_msg={this.state.success_msg}
			/>
			
		</BlockUi>
      </Fragment>
    );
  }
}
