import React, { Component, Fragment }  from "react";
import moment from "moment";


export default class PrintPackageBarcodes extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const { print_pos_label, facility  } = this.props;

		return (
			<div >
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 2.25in 1in !important;
						  margin:10px 10px;
						}
					}
				`}} />
				{print_pos_label['order_products'].map((product,index)=>{
					return (<Fragment key={index}>
						<div className="print_pos_label" style={{padding:'2px 10px 2px 6px'}} key={index} >                  
							<div style={{width:'3.15in', height:'1in', fontSize:'9px', lineHeight:'1.3', fontFamily:"Arial, 'Times New Roman'"}} className="panel-body">
								<div className="row_data">
									<strong>This product contains THC</strong>
								</div>
								<div className="row_data">
									Patient: {print_pos_label['customer_name'].toUpperCase()}
								</div>
								{(typeof print_pos_label['vendor_barcode']!='undefined' && print_pos_label['vendor_barcode']!=null) ? <div className="row_data">Producer: Disp# {print_pos_label['vendor_barcode']}</div>:""}
								
								{print_pos_label['customer_license']!=null ? <div className="row_data">
									Patient License#: {print_pos_label['customer_license']}
								</div>:""}
								<div className="row_data">
									<strong>{product['product_name']}</strong> 
								</div>
								<div className="row_data">
									Store: {facility['facility_name']}									
								</div>
								<div className="row_data">
									QTY: {product['product_qty']+' x '+product['variation_name']}
								</div>
								<div className="row_data">
									Order Date: {moment().format("MM/DD/YYYY hh:mm a")}
								</div>
								<div className="row_data" style={{fontSize:'11px'}}>
									<strong>Price: ${parseFloat(product['product_price']).toFixed(2)}</strong>
								</div>
								<div className="row_data" style={{marginTop:'0px',fontSize:'7px'}}>
									OKLAHOMA DEPARTMENT OF HEALTH SERVICES' WARNING: 
									Marijuana use can be addictive and can impair an individual's ability to drive a motor vehicle or 
									operate heavy machinery. Marijuana smoke contains carcinogens and can lead to an increased risk for cancel;
									tachycardia, hypertension, heart attack, and lung infection. 
									KEEP OUT OF REACH OF CHILDREN. FOR MEDICAL USE ONLY 
								</div>
							</div>
						</div>
						<div style={{pageBreakBefore:"always"}}></div>
						</Fragment>
					)
				})}
			</div>
		);
	}
};