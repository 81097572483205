import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import users from './users';
import payments from './payments';
import subscriptions from './subscriptions';


const Reports = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}`} to={`${match.url}/users`} />
            <Redirect exact from={`${match.url}/`} to={`${match.url}/users`} />
            <Route path={`${match.url}/users`} component={users} />           
            <Route path={`${match.url}/payments`} component={payments} />           
            <Route path={`${match.url}/subscriptions`} component={subscriptions} />           
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Reports;