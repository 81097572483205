import React, { Component, Fragment } from "react";
import {
	Row,Input,FormGroup,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Table
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DataTablePagination from "../../components/DatatablePagination";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';
import ReactTable from "react-table";
  
class ViewAllCouponModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			search:""
		};	
	}

	render() {
		let data = this.props.coupons;
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.code.toLowerCase().includes(this.state.search.toLowerCase()) || row.discount.toLowerCase().includes(this.state.search.toLowerCase()) || row.product_name.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Code",
				accessor: "code",
				Cell: props => <p className="text-muted mb-0">{props.original.auto_apply=="0" ? props.value:""}</p>
			},
			{
				Header: "Discount",
				accessor: "discount",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Product",
				accessor: "product_name",
				Cell: props => <p className="text-muted mb-0">{props.original.is_product=="3" ? props.value:(props.original.is_product=="2" ? "All Products":"")}</p>
			},
			{
				Header: "Max Use",
				accessor: "max_uses",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Start Date",
				accessor: "start_date",
				Cell: props => <p className="text-muted mb-0">{moment(props.value).format("DD-MM-YYYY")}</p>
			},
			{
				Header: "End Date",
				accessor: "end_date",
				Cell: props => <p className="text-muted mb-0">{moment(props.value).format("DD-MM-YYYY")}</p>
			}		  
		];
	  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				All Active Coupons
			</ModalHeader>
		 
			<ModalBody>
				<Row>          
                    <Colxx xxs="12">
						<div className="mb-4 dropdown-edit">					
							<FormGroup row>
								<Colxx lg="12">
									<Input
									  type="text"
									  name="table_search"
									  id="coupons_table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value})}
									/>
							  </Colxx>
							</FormGroup>
							<Row>          
								<Colxx lg="12">					
									<ReactTable
									  data={data}
									  columns={dataTableColumns}
									  defaultPageSize={5}
									  filterable={false}
									  showPageJump={false}
									  PaginationComponent={DataTablePagination}
									  showPageSizeOptions={false}
									  defaultSorted={[{id: "send_on",desc: true}]}
									/>
								</Colxx>
							</Row>
						</div>
					</Colxx>
				</Row>
		    </ModalBody>
			
			<ModalFooter>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
					<IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		</Modal>
	  );
  }
};

export default ViewAllCouponModal;
