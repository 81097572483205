import React from "react";
import {
  Card,
  CardBody,
  CardTitle
} from "reactstrap";

import moment from "moment";
import ChartComponent, { Chart } from "react-chartjs-2";
import { ThemeColors } from '../../helpers/ThemeColors';
import { chartTooltip } from '../../components/charts/util';
const colors = ThemeColors();

const lineChartOptions = {
	legend: {
		display: false
	},
	responsive: true,
	maintainAspectRatio: false,
	tooltips: chartTooltip,
	plugins: {
		datalabels: {
			display: false
		}
	},
	scales: {
		yAxes: [
			{
				gridLines: {
					display: true,
					lineWidth: 1,
					color: 'rgba(0,0,0,0.1)',
					drawBorder: false
				},
				ticks: {
					beginAtZero: true,
					min: 0,
					padding: 20
				}
			}
		],
		xAxes: [
			{
				gridLines: {
					display: false
				}
			}
		]
	}
}

class LineChart extends React.Component {
  componentWillMount() {
    if (this.props.shadow) {
      Chart.defaults.lineWithShadow = Chart.defaults.line;
      Chart.controllers.lineWithShadow = Chart.controllers.line.extend({
        draw: function(ease) {
          Chart.controllers.line.prototype.draw.call(this, ease);
          var ctx = this.chart.ctx;
          ctx.save();
          ctx.shadowColor = "rgba(0,0,0,0.15)";
          ctx.shadowBlur = 10;
          ctx.shadowOffsetX = 0;
          ctx.shadowOffsetY = 10;
          ctx.responsive = true;
          ctx.stroke();
          Chart.controllers.line.prototype.draw.apply(this, arguments);
          ctx.restore();
        }
      });
    }
  }

  render() {
    const { data, shadow } = this.props;
    return (
      <ChartComponent
        ref={ref => (this.chart_instance = ref && ref.chart_instance)}
        type={shadow ? "lineWithShadow" : "line"}
        options={{
          ...lineChartOptions
        }}
        data={data}
		width={600} height={250}
      />
    );
  }
}

const VisitorHistory = ({data}) => {
	var labels =[];
	var dataset_data =[];
	for(let row of data){
		labels.push(row.day);
		dataset_data.push(parseInt(row.count));
	}
		
	const lineChartData = {
		  labels: labels,
		  datasets: [
			{
			  label: 'Visitors',
			  data: dataset_data,
			  borderColor: colors.themeColor1,
			  pointBackgroundColor: colors.foregroundColor,
			  pointBorderColor: colors.themeColor1,
			  pointHoverBackgroundColor: colors.themeColor1,
			  pointHoverBorderColor: colors.foregroundColor,
			  pointRadius: 6,
			  pointBorderWidth: 2,
			  pointHoverRadius: 8,
			  fill: true
			}
		  ]
	}

	return (
		<Card style={{"height": "370px"}}>
			<CardBody >
				<CardTitle>
					Visitor History (days)
				</CardTitle>
				<div style={{"height": "260px"}} >
					<LineChart shadow data={lineChartData} />
				</div>
			</CardBody>
		</Card>
	);
};

export default VisitorHistory;
