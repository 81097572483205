import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddTableTypeModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
		plant_sizes:[],			
	};
	
  }
	
   	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
		
			if(this.state.plant_sizes.length==0){
				swal("Warning!","Please select table capacities","warning");
				return;
			}
			
			document.getElementById("add-table").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("tableForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
         
			axios.post(REST_API_END_POINT+'growhouse/add-table-type',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-table").disabled=false;
				this.setState({ plant_sizes:[] })
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-table").disabled=false;
			});

		}
	}
 
  



	render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Table Type
		  </ModalHeader>
			<AvForm
		         id="tableForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Name<span className="text-danger">*</span></Label>
								  <AvField
									id="name"
									name="name"
									type="text"
									autoComplete="off"
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									  pattern: {
										value: "^[a-zA-Z ,.'-]+$",
										errorMessage: "Invalid name"
									  },
									  minLength: {
										value: 2,
										errorMessage:
										  "The name must be between 2 and 100 characters"
									  },
									  maxLength: {
										value: 100,
										errorMessage:
										  "The  name must be between 2 and 100 characters"
									  }
									}}
								  />
								</AvGroup>
						 </Colxx>				
					</Row>
					<Row>
						<Colxx lg="12" className="mb-4">
							<Label >Table Capacities<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"
								isMulti 						
								name="grow_table_capacities[]"
								id="grow_table_capacities"
								placeholder="--Select Table Type--"
								options={this.props.plant_sizes}
								value={this.state.plant_sizes}
								onChange={plant_sizes => { this.setState({ plant_sizes }) }}
							/>
						</Colxx>				
					</Row>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-table">
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		</AvForm>
	  </Modal>
	  );
  }
};

export default AddTableTypeModal;
