import React from "react";
import {
  Row,
  Card,
  CardBody,
  CardSubtitle,
  CardImg,
  CardText,  
  Badge,
  Button,
} from "reactstrap";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../components/common/CustomBootstrap";
import { BASE_URL } from "../../constants/defaultValues";

const PlanCard = ({ user, toggleEditModal, activateRow }) => {
	var plan_image=BASE_URL+'subscriptions/'+user.plan_image;
	
  return (
    <Colxx sm="6" lg="4" xl="3" className="mb-4" key={user.id}>
        <Card>
          <div className="position-relative">
            <div className="w-sm-100">
              <CardImg top alt={user.name} src={plan_image} />
            </div>
            <Badge
              color={parseInt(user.status)==1?"success":"danger"}
              pill
              className="position-absolute badge-top-left"
			  style={{fontSize:'100%'}}
            >
              {parseInt(user.status)===1?"Active":"Inactive"}
            </Badge>
          </div>
          <CardBody className="image-view-card-body pt-0">
            <Row>
              <Colxx xxs="12" className="mb-3">
                <CardSubtitle className="image-view-title text-center" style={{fontSize:'1.2rem'}}><div className="w-sm-100 text-primary">{user.name}</div></CardSubtitle>
                <CardText className="text-muted mb-0 text-center">
                  {user.description}
				 
                </CardText>	
				<div className="text-center pt-2">
					<Badge  pill className="badge-top-left badge-lg">{user.sales+" Sales"}</Badge>
				</div>
				<hr/>
				<div className="action-btns text-center mt-3">
					<Button className="mr-1" size="sm" color="success" onClick={(e) => toggleEditModal(e,user.id)} title="Edit"><i className="simple-icon-pencil"></i></Button>
					<Button className="mr-1" size="sm" color="default" onClick={() => activateRow(user.id,parseInt(user.status), user.sales)} title="Deactivate" ><i className={parseInt(user.status)==0?"simple-icon-check":"simple-icon-ban"}></i></Button>
					<Button className="mr-1" size="sm" color="danger" onClick={() => activateRow(user.id,2, user.sales)} title="Delete" ><i className="simple-icon-trash"></i></Button>
              </div>
              </Colxx>
            </Row>
          </CardBody>
        </Card>
    </Colxx>
  );
};

export default React.memo(PlanCard);
