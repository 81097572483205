import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,	CustomInput } from "reactstrap";

import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL, themeColor} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';


import ChangeCompanyLogo from "../../../components/visitor/ChangeCompanyLogo";
import CustomFields from "../../../components/visitor/CustomFields";
import CovidForm from "../../../components/visitor/CovidForm";
import AppSettings from "../../../components/visitor/AppSettings";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Visitors extends Component {
	
	constructor(props) {
		super(props);		
		
		this.state = {		 		 
			facility_data: null,
			activeFirstTab: '1',
			blocking: true,
		};
		
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		this.updateData = this.updateData.bind(this);
	}
	
	componentDidMount(){  
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'visitor/get-visitor-settings',formData).then((res)=>{
			var data = res.data;
			
			if(data.facility){
				this.setState({
					facility_data: data.facility,
					blocking:false
				});
			}else{
				this.setState({
					facility_data: null,
					blocking:false
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
	
	updateData(values) {
		var facility_data= this.state.facility_data;
		if(typeof values.company_logo!=='undefined'){
			facility_data.company_logo = values.company_logo
		}
		if(typeof values.capture_visitor_photo!=='undefined'){
			facility_data.capture_visitor_photo = values.capture_visitor_photo
		}
		if(typeof values.print_visitor_badge!=='undefined'){
			facility_data.print_visitor_badge = values.print_visitor_badge
		}
		if(typeof values.staff_notification!=='undefined'){
			facility_data.staff_notification = values.staff_notification
		}
		if(typeof values.enable_covid_form!=='undefined'){
			facility_data.enable_covid_form = values.enable_covid_form
		}
		this.setState({
			facility_data
		});		
    }
	
	render() {
		const { facility_data } = this.state;
		var covid_form=false;
		if(facility_data!=null){
			if(facility_data.enable_covid_form==1){
				covid_form=true;
			}
		}
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.settings" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					
					<Row className="visitor-settings-wrapper page-tabs">
						<Colxx md="4" xxl="3">
							<Card className="p-4">							
								<div className="text-center">
									<div className="text-center position-relative">
										<div className="text-center qrcode-img">
											{facility_data!=null ? <img src={BASE_URL+'visitor-qrcode/'+facility_data.visitor_qrcode} />:""} 
										</div>
									</div>		
								</div>
								<div className="text-center mt-3 mb-4">
									<h2 className="mb-0">{facility_data!=null ? facility_data.facility_name:""}</h2>
								</div>
								<Nav tabs className="card-header-tabs">
									<NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "1",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("1");
											}}
											to="#"
										>
											Company Logo
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "2",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("2");
											}}
											to="#"
										>
											Custom Fields
										</NavLink>
									</NavItem>
									{covid_form ? <NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "4",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("4");
											}}
											to="#"
										>
											Covid Questionnaire
										</NavLink>
									</NavItem>:""}
									<NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "3",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("3");
											}}
											to="#"
										>
											App Settings
										</NavLink>
									</NavItem>
								</Nav>
							</Card>
						</Colxx>
						<Colxx md="8" xxl="9">
							<Card className="p-4">
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="1">
										<ChangeCompanyLogo 
											facility_data={facility_data}
											updateData={this.updateData}
										/>
									</TabPane>
								</TabContent>
								{this.state.activeFirstTab=='2' ? <TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="2">
										<CustomFields />										
									</TabPane>
								</TabContent>:""}
								{covid_form && this.state.activeFirstTab=='4' ? <TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="4">
										<CovidForm />
									</TabPane>
								</TabContent>:""}
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="3">
										<AppSettings 
											facility_data={facility_data}
											updateData={this.updateData}
										/>
									</TabPane>
								</TabContent>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
