import React, { Component,Fragment } from "react";
import { Row, Card, FormGroup, Input, Button } from "reactstrap";

import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";
import moment from 'moment';

import InvoiceModal from "../../containers/pointofsale/InvoiceModal";

export default class Orders extends Component {	
	constructor(props) {
		super(props);
		
		this.state = {
			search:"",
			order_id:0,
			modalOpen:false
		};	
	}
	
	toggleModal = (e,order_id =0) => {
		this.setState({
		  order_id: parseInt(order_id),
		  modalOpen: !this.state.modalOpen
		});
	};

	render() {
		let data = this.props.orders;
		if (this.state.search) {
			data = data.filter(row => {
				return row.order_number.toLowerCase().includes(this.state.search.toLowerCase()) || row.total.toLowerCase().includes(this.state.search.toLowerCase()) || row.status_text.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [
			{
				Header: "Order Id",
				accessor: "order_number",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Amount",
				accessor: "total",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Order Date",
				accessor: "timestamp",
				Cell: props => <p className="text-muted mb-0">{moment(props.value*1000).format("MM/DD/YYYY")}</p>
			},
			{
				Header: "Status",
				accessor: "status_badge",
				Cell: props => props.value
			},
			{
				Header: "",
				accessor: "order_id",
				Cell: props => <Button size="xs" color="secondary" onClick={(e)=>{ this.toggleModal(e,props.value) }} >Invoice</Button>
			}		  
		];
		
		return (<Row>          
                    <Colxx xxs="12">
						<Card className="mt-3 pl-4 pr-4 pb-2">
							<div className="mt-4 mb-1 mr-4"><h3><strong>Orders</strong></h3></div>
							<div className="mb-4 dropdown-edit">					
								<FormGroup row>
									<Colxx lg="12">
										<Input
										  type="text"
										  name="table_search"
										  id="order_table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value})}
										/>
								  </Colxx>
								</FormGroup>
								<Row>          
									<Colxx lg="12">					
										<ReactTable
										  data={data}
										  columns={dataTableColumns}
										  defaultPageSize={5}
										  filterable={false}
										  showPageJump={false}
										  PaginationComponent={DataTablePagination}
										  showPageSizeOptions={false}
										  defaultSorted={[{id: "send_on",desc: false}]}
										/>
									</Colxx>
								</Row>
							</div>
						</Card>
						
						<InvoiceModal
							modalOpen={this.state.modalOpen}
							toggleModal={this.toggleModal}
							order_id={this.state.order_id}
						/>
					</Colxx>
				</Row>);
	}
}
