import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddMoneyExternalModal from "../../../containers/bankmanagement/AddMoneyExternalModal";
import MoveMoneyToInternalModal from "../../../containers/bankmanagement/MoveMoneyToInternalModal";
import TakeMoneyModal from "../../../containers/bankmanagement/TakeMoneyModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { AvForm, AvField } from "availity-reactstrap-validation";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Externalbankview extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			external_bank:null,		 
			banktotal:null,		 
			modalOpenAdd: false,
			modalOpenTake: false,
			modalOpenMove: false,
			search_cash: '',
			search_cash_taken: '',
			cash:[],
			currencies:[],
			currency_code:'USD',
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalMove = () => {
		this.setState({
		  modalOpenMove: !this.state.modalOpenMove
		});
	};
	
	toggleModalTake = () => {
		this.setState({
		  modalOpenTake: !this.state.modalOpenTake
		});
	};
	 
  	componentDidMount(){		
		this.dataListRender();
	}
	
	dataListRender() {		   
		var formData = new FormData();
		let shelf_structures;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('external_bank_id',this.props.match.params.external_bank_id );
		formData.append('currency_code',this.state.currency_code);
		
		axios.post(REST_API_END_POINT+'external-bank/view-bank',formData).then((res)=>{
			var data = res.data;
			var banktotal=data.bank_total;
			var cash=data.cash;
			var cash_taken=data.cash_taken;
			var external_bank=data.external_bank;
			var currencies=data.currencies;
			
			this.setState({			
				external_bank,
				banktotal,
				cash,	
				cash_taken,	
				currencies,	
			});	
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	render() {
		const currencies = this.state.currencies;
		let cash_data = this.state.cash;
		if (this.state.search_cash) {
			cash_data = cash_data.filter(row => {
				return row['1_s'].includes(this.state.search_cash) ||
					row['2_s'].includes(this.state.search_cash) ||
					row['5_s'].includes(this.state.search_cash) ||
					row['10_s'].includes(this.state.search_cash) ||
					row['20_s'].includes(this.state.search_cash) ||
					row['50_s'].includes(this.state.search_cash) ||
					row['100_s'].includes(this.state.search_cash) ||
					row['cents'].includes(this.state.search_cash) ||
					row['nickles'].includes(this.state.search_cash) ||
					row['dimes'].includes(this.state.search_cash) ||
					row['quarters'].includes(this.state.search_cash) ||
					row['50_cents'].includes(this.state.search_cash) ||
					row['1_d'].includes(this.state.search_cash) ||
					row['added_by'].toLowerCase().includes(this.state.search_cash) ||
					row['added_at'].toLowerCase().includes(this.state.search_cash)
			})
		}
		
		let cash_taken_data = this.state.cash_taken;
		if (this.state.search_cash_taken){
			cash_taken_data = cash_taken_data.filter(row => {
				return row['1_s'].includes(this.state.search_cash_taken) ||
					row['2_s'].includes(this.state.search_cash_taken) ||
					row['5_s'].includes(this.state.search_cash_taken) ||
					row['10_s'].includes(this.state.search_cash_taken) ||
					row['20_s'].includes(this.state.search_cash_taken) ||
					row['50_s'].includes(this.state.search_cash_taken) ||
					row['100_s'].includes(this.state.search_cash_taken) ||
					row['cents'].includes(this.state.search_cash_taken) ||
					row['nickles'].includes(this.state.search_cash_taken) ||
					row['dimes'].includes(this.state.search_cash_taken) ||
					row['quarters'].includes(this.state.search_cash_taken) ||
					row['50_cents'].includes(this.state.search_cash_taken) ||
					row['1_d'].includes(this.state.search_cash_taken) ||
					row['added_by'].toLowerCase().includes(this.state.search_cash_taken) ||
					row['added_at'].toLowerCase().includes(this.state.search_cash_taken)
			})
		}
		
		var banktotal = this.state.banktotal;		
		if(this.state.banktotal!=null){
			var total = parseInt(banktotal['1_s'])+parseInt(banktotal['2_s'])*2+parseInt(banktotal['5_s'])*5+parseInt(banktotal['10_s'])*10+parseInt(banktotal['20_s'])*20+parseInt(banktotal['50_s'])*50+parseInt(banktotal['100_s'])*100+parseInt(banktotal['cents'])*0.01+parseInt(banktotal['nickles'])*0.05+parseInt(banktotal['dimes'])*0.1+parseInt(banktotal['quarters'])*0.25+banktotal['50_cents']*0.5+parseInt(banktotal['1_d'])
		}else{
			var total=0;
		}
				
		const commonColumns = [		
			{
				Header: "1's",
				accessor: "1_s",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "2's",
				accessor: "2_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "5's",
				accessor: "5_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "10's",
				accessor: "10_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "20's",
				accessor: "20_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "50's",
				accessor: "50_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "100's",
				accessor: "100_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Cents",
				accessor: "cents",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Nickels",
				accessor: "nickles",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Dimes",
				accessor: "dimes",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Quarters",
				accessor: "quarters",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "50 Cents",
				accessor: "50_cents",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Dollar",
				accessor: "1_d",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
		];
		
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.state.currency_code });
		var bank_total_view = [];
		var finalCommonColumns = commonColumns;
		if(typeof selected_currency!='undefined'){
			finalCommonColumns = [];
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){
				finalCommonColumns.push(commonColumns.find(r=>{ return r.accessor==key }));
				
				bank_total_view.push(<Fragment key={key}>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<h1 className="font-weight-bold mt-3">{banktotal? ((banktotal[key]!=null)?banktotal[key]:'0'):'0' }</h1>
									</Colxx>
								</Fragment>)
				
			}
		}
		
		const dataTableColumns = [ ...finalCommonColumns,
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Added By",
				accessor: "added_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Added At",
				accessor: "added_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
	  
		];
		
		const dataTableColumns1 = [ ...finalCommonColumns,
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Reason",
				accessor: "reason",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Taken By",
				accessor: "added_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Taken At",
				accessor: "added_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			}	  
		];
	
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            {this.state.external_bank!=null ? <Breadcrumb heading_text={this.state.external_bank.bank_name} match={this.props.match} />:<Breadcrumb heading="" match={this.props.match} />}
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Row>
			<Colxx xxs="12">
				<div id="api_message"></div>
			</Colxx> 
		</Row>
		
		<Card className="p-4">
		   <Row>
			<Colxx xxs="12">
				<div className="float-sm-left d-none" style={{width:'200px'}}>
					<AvForm
						className="av-tooltip tooltip-right-bottom"
						onSubmit={()=>{}}
					>
						<AvField
							type="select"
							name="currency_code"
							id="currency_code"
							value={this.state.currency_code}
							onChange={(e)=>{ this.setState({ currency_code:e.target.value },()=>{ this.dataListRender() }) }}
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								}
							}}
						>
							{currencies.map(opt=>{
								return <option key={opt.id} value={opt.currency_code} >{opt.country_name+" - "+opt.currency_code}</option>
							})}
						</AvField>
					</AvForm>
				</div>
				<div className="float-sm-right">
					{this.state.external_bank!=null ? (this.state.external_bank.status==1 ? <Fragment>
						{" "}<Button
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							Deposit Money
						</Button>{" "}<Button
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalMove}
							>
							Transfer Money to Internal Bank
						</Button>{" "}<Button																		 
						  color="primary"
						  size="sm"
						  className=""
						  onClick={this.toggleModalTake}
						>
						 Take Money
					</Button></Fragment>:""):""}
				</div>
			</Colxx>
			<Colxx xxs="9">
				<Row className="currency-img align-items-center">
					{bank_total_view}					
				</Row>
				<Row className="mt-5">
					<Colxx xxs="5">
						<h1>Total</h1>	
					</Colxx>
					<Colxx xxs="7">
						<h1>{total.toFixed(2)}</h1>	
					</Colxx>
				</Row>
			</Colxx>
			<Colxx xxs="12">					
				<div className="mb-4 dropdown-edit">
					<Row className="mt-4">
                    </Row>	
					<h3 className="font-weight-bold">Cash Added to Bank</h3>
					<FormGroup row>
						<Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search"
							  placeholder="Search"
							  value={this.state.search_cash}
							  onChange={e => this.setState({search_cash: e.target.value.toLowerCase()})}
							/>
						</Colxx>
					</FormGroup>			
					<ReactTable
						data={cash_data}
						columns={dataTableColumns}
						defaultPageSize={5}
						filterable={false}
						showPageJump={false}
						responsive
						PaginationComponent={DataTablePagination}
						showPageSizeOptions={true}
						defaultSorted={[{id: "send_on",desc: true}]}
					/>
					
					<h3 className="font-weight-bold">Cash Taken From Bank</h3>
					<FormGroup row>
						<Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search_taken"
							  placeholder="Search"
							  value={this.state.search_cash_taken}
							  onChange={e => this.setState({search_cash_taken: e.target.value.toLowerCase()})}
							/>
						</Colxx>
					</FormGroup>
					<ReactTable
						data={cash_taken_data}
						columns={dataTableColumns1}
						defaultPageSize={5}
						filterable={false}
						showPageJump={false}
						responsive
						PaginationComponent={DataTablePagination}
						showPageSizeOptions={true}
						defaultSorted={[{id: "send_on",desc: true}]}
					/>						
				</div>					
			</Colxx>
			{this.state.modalOpenAdd ? <AddMoneyExternalModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
				currencies={this.state.currencies}
				currency_code={this.state.currency_code}
				external_bank_id={this.props.match.params.external_bank_id}
			/>:""}
			{this.state.modalOpenMove ? <MoveMoneyToInternalModal
				modalOpen={this.state.modalOpenMove}
				toggleModal={this.toggleModalMove}
				dataListRender={this.dataListRender}
				currencies={this.state.currencies}
				currency_code={this.state.currency_code}
				external_bank_id={this.props.match.params.external_bank_id}
			/>:""}
			{this.state.modalOpenTake ? <TakeMoneyModal
				modalOpen={this.state.modalOpenTake}
				toggleModal={this.toggleModalTake}
				dataListRender={this.dataListRender}
				external_bank_id={this.props.match.params.external_bank_id}
				currencies={this.state.currencies}
				currency_code={this.state.currency_code}
			/>:""}
			</Row>
		</Card>
      </Fragment>
    );
  }
}
