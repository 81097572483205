import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import {ChromePicker} from 'react-color';

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectStatus = [
    { label: "Active", value: "1", key: 0 },
    { label: "Inactive", value: "0", key: 1 }
];

export default class EditVendorTypeModal extends Component{
	constructor(props) {
    super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChangeComplete = this.handleChangeComplete.bind(this);
		this.state = {
			background: '#3f941b',
			statusType: { label: "Active", value: "1", key: 0 },
			vendor_type:null
		};
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-type").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("typeForm"));
			formData.append('type_id',this.props.type_id);
			formData.append('bg_color',this.state.background);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'wholesale/edit-vendor-type',formData).then((res)=>{
				var data = res.data;
				
				var vendor_type=this.state.vendor_type;
				vendor_type.name=values.name;
				this.setState({ vendor_type });
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-type").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-type").disabled=false;
			});

		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){	   
        if(this.props.modalOpen){
			let vendor_type; 		  
            if (prevProps.type_id !== this.props.type_id && this.props.type_id!=0) {
				var formData = new FormData();
				formData.append('type_id',this.props.type_id);
	
				axios.post(REST_API_END_POINT+'wholesale/vendor-type-by-id',formData).then((res)=>{
					var data = res.data;
					var status=null;
					vendor_type=data.vendor_type;
					var background=data.vendor_type.bg_color;
					
					selectStatus.forEach((element,index) => {
                       if(element.value==vendor_type.status){
							status=element;
						}
					});
					
					this.setState({ 
						vendor_type,
						background,
						statusType:status,
					});
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});	
			}
		}
	}

	handleChangeComplete = (color) => {
		this.setState({ background: color.hex });	
	};

  render() {
		if(this.state.vendor_type!=null){
			var vendor_type=this.state.vendor_type;
		}else{
			var vendor_type;
		}
		
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Vendor Type
		  </ModalHeader>
		  <AvForm
				id="typeForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="skill_name"
							name="name"
							type="text"
							autoComplete="off"
							value={ vendor_type?((vendor_type.name!=null)?vendor_type.name:''):'' }
							validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z0-9 ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}	
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">							
							<Label>Color<span className="text-danger">*</span></Label>
							<ChromePicker
								color={ this.state.background }
								onChangeComplete={ this.handleChangeComplete }
								className="no-box-shadow"
							/>
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Status</Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="status"
								id="status"
								value={this.state.statusType}						
								options={selectStatus}
								onChange={statusType => { this.setState({ statusType }) }}
							/>
						</AvGroup>					
					</Colxx>					
				</Row>							
																			
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-type">
			  <IntlMessages id="customer.save" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
