import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ChangePaymentModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {	
			cash_total:{
				'1_s':0,
				'2_s':0,
				'5_s':0,
				'10_s':0,
				'20_s':0,
				'50_s':0,
				'100_s':0,
				'cents':0,
				'nickles':0,
				'dimes':0,
				'quarters':0,
				'50_cents':0,
				'1_d':0
			}
		};	
	}
	
	componentDidMount(){
		const drawer_data = this.props.drawer_data;
		const change_data = this.props.change_data;
		var cash_total = this.state.cash_total;
		if(change_data!=null){
			cash_total={
				'1_s':change_data['1_s'],
				'2_s':change_data['2_s'],
				'5_s':change_data['5_s'],
				'10_s':change_data['10_s'],
				'20_s':change_data['20_s'],
				'50_s':change_data['50_s'],
				'100_s':change_data['100_s'],
				'cents':change_data['cents'],
				'nickles':change_data['nickles'],
				'dimes':change_data['dimes'],
				'quarters':change_data['quarters'],
				'50_cents':change_data['50_cents'],
				'1_d':change_data['1_d']
			}
		}else{
			var payment=this.props.total_due*-1;
			payment=parseFloat(payment.toFixed(2));
			
			if(parseInt(drawer_data['100_s'])>=parseInt(payment/100)){
				cash_total['100_s']=parseInt(payment/100);
				payment=payment%100;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['100_s']=parseInt(drawer_data['100_s']);				
				payment-=parseFloat(parseInt(drawer_data['100_s'])*100);
			}
			
			if(parseInt(drawer_data['50_s'])>=parseInt(payment/50)){
				cash_total['50_s']=parseInt(payment/50);
				payment=payment%50;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['50_s']=parseInt(drawer_data['50_s']);
				payment-=parseFloat(parseInt(drawer_data['50_s'])*50);
			}
			
			if(parseInt(drawer_data['20_s'])>=parseInt(payment/20)){
				cash_total['20_s']=parseInt(payment/20);
				payment=payment%20;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['20_s']=parseInt(drawer_data['20_s']);
				payment-=parseFloat(parseInt(drawer_data['20_s'])*20);
			}
			
			if(parseInt(drawer_data['10_s'])>=parseInt(payment/10)){
				cash_total['10_s']=parseInt(payment/10);
				payment=payment%10;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['10_s']=parseInt(drawer_data['10_s']);
				payment-=parseFloat(parseInt(drawer_data['10_s'])*10);
			}
			
			if(parseInt(drawer_data['5_s'])>=parseInt(payment/5)){
				cash_total['5_s']=parseInt(payment/5);
				payment=payment%5;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['5_s']=parseInt(drawer_data['5_s']);
				payment-=parseFloat(parseInt(drawer_data['5_s'])*5);
			}
			
			if(parseInt(drawer_data['2_s'])>=parseInt(payment/2)){
				cash_total['2_s']=parseInt(payment/2);
				payment=payment%2;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['2_s']=parseInt(drawer_data['2_s']);
				payment-=parseFloat(parseInt(drawer_data['2_s'])*2);
			}
			
			if(parseInt(drawer_data['1_s'])>=parseInt(payment/1)){
				cash_total['1_s']=parseInt(payment/1);
				payment=payment%1;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['1_s']=parseInt(drawer_data['1_s']);
				payment-=parseFloat(parseInt(drawer_data['1_s'])*1);
			}
			
			if(parseInt(drawer_data['1_d'])>=parseInt(payment/1)){
				cash_total['1_d']=parseInt(payment/1);
				payment=payment%1;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['1_d']=parseInt(drawer_data['1_d']);
				payment-=parseFloat(parseInt(drawer_data['1_d'])*1);
			}
			
			if(parseInt(drawer_data['50_cents'])>=parseInt(payment/0.5)){
				cash_total['50_cents']=parseInt(payment/0.5);
				payment=payment%0.5;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['50_cents']=parseInt(drawer_data['50_cents']);
				payment-=parseFloat(parseInt(drawer_data['50_cents'])*0.5);
			}
			
			if(parseInt(drawer_data['quarters'])>=parseInt(payment/0.25)){
				cash_total['quarters']=parseInt(payment/0.25);
				payment=payment%0.25;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['quarters']=parseInt(drawer_data['quarters']);
				payment-=parseFloat(parseInt(drawer_data['quarters'])*0.25);
			}
			
			if(parseInt(drawer_data['dimes'])>=parseInt(payment/0.1)){
				cash_total['dimes']=parseInt(payment/0.1);
				payment=payment%0.1;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['dimes']=parseInt(drawer_data['dimes']);
				payment-=parseFloat(parseInt(drawer_data['dimes'])*0.1);
			}
			
			if(parseInt(drawer_data['nickles'])>=parseInt(payment/0.05)){
				cash_total['nickles']=parseInt(payment/0.05);
				payment=payment%0.05;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['nickles']=parseInt(drawer_data['nickles']);
				payment-=parseFloat(parseInt(drawer_data['nickles'])*0.05);
			}
			
			if(parseInt(drawer_data['cents'])>=parseInt(payment/0.01)){
				cash_total['cents']=parseInt(payment/0.01);
				payment=payment%0.01;
				payment=parseFloat(payment.toFixed(2));
			}else{
				cash_total['cents']=parseInt(drawer_data['cents']);
				payment-=parseFloat(parseInt(drawer_data['cents'])*0.01);
			}
		}
		this.setState({	cash_total });
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0){
			var change_data = this.props.change_data;
			var cash_total = this.state.cash_total;
						
			document.getElementById("pay-change").disabled=true;
			
			if(change_data!=null){			
				change_data['1_s'] = cash_total['1_s'];
				change_data['2_s'] = cash_total['2_s'];
				change_data['5_s'] = cash_total['5_s'];
				change_data['10_s'] = cash_total['10_s'];
				change_data['20_s'] = cash_total['20_s'];
				change_data['50_s'] = cash_total['50_s'];
				change_data['100_s'] = cash_total['100_s'];
				change_data['cents'] = cash_total['cents'];
				change_data['nickles'] = cash_total['nickles'];
				change_data['dimes'] = cash_total['dimes'];
				change_data['quarters'] = cash_total['quarters'];
				change_data['50_cents'] = cash_total['50_cents'];
				change_data['1_d'] = cash_total['1_d'];
			}else{
				change_data = cash_total;
			}
			
			var change_data_total = 0;
			if(this.state.cash_total!=null){
				change_data_total = parseInt(cash_total['1_s'])+parseInt(cash_total['2_s'])*2+parseInt(cash_total['5_s'])*5+parseInt(cash_total['10_s'])*10+parseInt(cash_total['20_s'])*20+parseInt(cash_total['50_s'])*50+parseInt(cash_total['100_s'])*100+parseInt(cash_total['cents'])*0.01+parseInt(cash_total['nickles'])*0.05+parseInt(cash_total['dimes'])*0.1+parseInt(cash_total['quarters'])*0.25+cash_total['50_cents']*0.5+parseInt(cash_total['1_d'])
			}
			change_data_total = parseFloat(change_data_total.toFixed(2));
			
			var total_due=parseFloat(this.props.total_due.toFixed(2));
			
			if(change_data_total!=(total_due*-1)){
				swal("Warning!","Please pay the change correctly","warning");
				document.getElementById("pay-change").disabled=false;
				return;
			}else{
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData(document.getElementById("changeMoneyForm"));
				formData.append('facility_id',facility_id);
				formData.append('user_id',user_id);
				formData.append('drawer_id',this.props.drawer_id);
				formData.append('currency_code',this.props.currency_code);
				axios.post(REST_API_END_POINT+"drawer/check-drawer-stock",formData).then((res)=>{
					var data = res.data;
					
					if(data.proceed){
						this.props.updateChangeData(change_data);
					}else{
						swal("There is no enough money in the drawer");
						document.getElementById("pay-change").disabled=false;
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("pay-change").disabled=false;
				});
			}
			
			
		}
	}
  
	render() {

        var cash_total = this.state.cash_total;	
		var total=0;
		if(this.state.cash_total!=null){
			total = parseInt(cash_total['1_s'])+parseInt(cash_total['2_s'])*2+parseInt(cash_total['5_s'])*5+parseInt(cash_total['10_s'])*10+parseInt(cash_total['20_s'])*20+parseInt(cash_total['50_s'])*50+parseInt(cash_total['100_s'])*100+parseInt(cash_total['cents'])*0.01+parseInt(cash_total['nickles'])*0.05+parseInt(cash_total['dimes'])*0.1+parseInt(cash_total['quarters'])*0.25+cash_total['50_cents']*0.5+parseInt(cash_total['1_d'])
		}
		
		var total_due=this.props.total_due;
		if(parseFloat(total_due.toFixed(2))==0){
			total_due=0;
		}
		
		const currencies = this.props.currencies;
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.props.currency_code });
		var field_view = [];
		if(typeof selected_currency!='undefined'){
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){
				
				field_view.push(<Fragment key={key}>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<AvGroup className="denomination-qty">
											<InputGroup>
												<InputGroupAddon addonType="prepend" onClick={e=>{
													var cash_total = this.state.cash_total;
													cash_total[key]+=1;
													this.setState({
														cash_total
													});
												}} >+</InputGroupAddon>
												<AvField
													id={key}
													name={key}
													type="number"
													placeholder={currency_struct[key].alt}
													min="0"
													step="1"
													value={this.state.cash_total[key]!=0?this.state.cash_total[key]:''}
													onChange={(e)=>{
														var cash_total = this.state.cash_total;
														if(e.target.value!=''){
															cash_total[key]=e.target.value;
														}else{
															cash_total[key]=0;
														}
														this.setState({
															cash_total
														});
													}}
												/>
												<InputGroupAddon addonType="append" onClick={e=>{
													var cash_total = this.state.cash_total;
													if(cash_total[key]!=0){
														cash_total[key]-=1;
														this.setState({
															cash_total
														});
													}
												}} >-</InputGroupAddon>
											</InputGroup>
										</AvGroup>
									</Colxx>
								</Fragment>)
				
			}
		}
  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="lg"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Pay Change
			</ModalHeader>
			<AvForm
		        id="changeMoneyForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row className="align-items-center">
						{field_view}
					</Row>
					
					<Row className="mt-4">
						<Colxx xxs="3">
							<h3>Total Change</h3>
						</Colxx>
						<Colxx xxs="6">
							<AvGroup className="error-t-negative">
							  <AvField
								id="cash"
								name="cash"
								type="text"
								readOnly={true}
								placeholder="0"
								value={total.toFixed(2)}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row className="mt-4">
						<Colxx xxs="3">
							<h3>Total Due</h3>
						</Colxx>
						<Colxx xxs="6">
							<AvGroup className="error-t-negative">
								<AvField
									id="total_due"
									name="total_due"
									type="text"
									readOnly={true}
									placeholder="0"
									value={total_due.toFixed(2)}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					{parseFloat(total.toFixed(2))<(-1*parseFloat(total_due.toFixed(2))) ? <p className="text-danger text-center h5">If you don't have enough money in the drawer please fill it and refresh this page</p>:""}
				</ModalBody>
				<ModalFooter className="justify-content-start">
					<Button color="primary" id="pay-change" >
						Pay
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};
