import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import DropzoneExample from "../../containers/forms/DropzoneExample";

const selectCategories = [
	{ label: "Tissue Culture Clones", value: "1", key: 0 },
	{ label: "Trim", value: "2", key: 1 },
	{ label: "Fresh Frozen Flower", value: "3", key: 2 },
	{ label: "Frozen Trim", value: "4", key: 3 }
];

class EditGroupModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			modalOpenAdd: false,
		};

	}

	handleSubmit(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			this.props.toggleModal();
		}
	}
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	onChange = e => {
		switch (e.target.name) {
			case 'profile_pic':
				if (e.target.files[0]) {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
		}
	};


	render() {
		return (

			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Group
				</ModalHeader>
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Group Name</Label>
									<AvField
										id="name"
										name="name"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the  name"
											},
											pattern: {
												value: "^[a-zA-Z ,.'-]+$",
												errorMessage: "Invalid first name"
											},
											minLength: {
												value: 2,
												errorMessage:
													"The name must be between 2 and 100 characters"
											},
											maxLength: {
												value: 100,
												errorMessage:
													"The name must be between 2 and 100 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>City</Label>
									<AvField
										id="city"
										name="city"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "Field is required"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="mt-4">
							<Colxx lg="12">
								<Label >Category</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									isMulti
									id="select2-state_id-container"
									value={this.state.selectedOptions}
									onChange={this.handleChangeMulti}
									options={selectCategories}
								/>
							</Colxx>
						</Row>
						<Row className="mt-4">
							<Colxx lg="12" className="mb-2">
								<Label>Group Logo</Label>
								<DropzoneExample />
							</Colxx>
						</Row>


					</ModalBody>
					<ModalFooter>
						<Button color="primary" >
							<IntlMessages id="customer.save" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditGroupModal;
