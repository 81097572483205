import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";

import { REST_API_END_POINT } from "../../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class AddModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state={
			apply_type:1,
			auto_apply:false,
			start_date:null,
			end_date:null,
		}
	}

	async handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("add-discount").disabled = true;
			
			var data_upload = new FormData(document.getElementById("discount_form"));
			data_upload.append('added_by', localStorage.getItem('user_id') );
			data_upload.append('auto_apply', this.state.auto_apply ? 1:0 );
			data_upload.set('discount_code', values.discount_code.toUpperCase() );
			
			if(this.state.start_date!=null){
				data_upload.append('start_date', this.state.start_date.unix());
			}
			if(this.state.end_date!=null){
				data_upload.append('end_date', this.state.end_date.unix());
			}
			
			axios.post(REST_API_END_POINT+'admin/add-discount',data_upload).then((data)=>{ 
				if(data.data.exist){
					swal("Discount already exist!", "You have already used this code for another discount in our system.", "error");
				}else if(data.data.status==1){
					this.props.toggleModal();				
					this.props.dataListRender();
					this.setState({ apply_type:1, auto_apply:false,	start_date:null, end_date:null });
					swal("Success!", "Discount has been added successfully!", "success");				
				}else{
					swal("Failed to add discount!", "Please try after sometime.", "error");			
				}
				document.getElementById("add-discount").disabled = false;
			}).catch(e => console.log("Addition failed , Error ", e));
		  
		}
	}

  render() {
		var today_date = moment().startOf('day');
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Discount
		  </ModalHeader>
		  <AvForm
				id="discount_form"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			
				<AvGroup className="error-t-negative">
				  <Label>Name</Label>
				  <AvField
					name="name"
					type="text"
					autoComplete="off"
					validate={{
					  required: {
						value: true,
						errorMessage: "Please enter the name"
					  },
					  minLength: {
						value: 2,
						errorMessage:
						  "Your name must be between 2 and 150 characters"
					  },
					  maxLength: {
						value: 150,
						errorMessage:
						  "Your name must be between 2 and 150 characters"
					  }
					}}
				  />
				</AvGroup>
				
				<AvGroup className="error-t-negative">
				  <Label>Discount Code</Label>
				  <AvField
					name="discount_code"
					type="text"
					autoComplete="off"
					className="text-uppercase"
					validate={{
						required: {
							value: true,
							errorMessage: "Please enter the discount code"
						},
						pattern: {
							value: "^[a-zA-Z0-9 ]+$",
							errorMessage: "Invalid discount code"
						}
					}}
				  />
				</AvGroup>
				
				<Row>
					<Colxx md="6">
						<AvGroup className="error-t-negative">
							<Label>Start Date</Label>
							<DatePicker
								className="mb-2"
								placeholderText="Start Date"
								dateFormat="MM/DD/YYYY"
								selected={this.state.start_date}
								onChange={start_date=>{ 
									if(this.state.end_date!=null){
										if(this.state.end_date<start_date){
											swal("Warning!", "Start date should be less than end date.", "warning");
											return;
										}
									}								
									
									if(today_date.unix()>start_date.unix()){
										swal("Warning!", "Start date should be greater than or equal to today's date.", "warning");
										return;
									}
									
									this.setState({ start_date }) 
								}}
							/>
						</AvGroup>
					</Colxx>
					<Colxx md="6">
						<AvGroup className="error-t-negative">
							<Label>End Date</Label>
							<DatePicker
								className="mb-2"
								placeholderText="End Date"
								dateFormat="MM/DD/YYYY"
								selected={this.state.end_date}
								onChange={end_date=>{ 
									if(this.state.start_date!=null){
										if(this.state.start_date>end_date){
											swal("Warning!", "End date should be greater than start date.", "warning");
											return;
										}
									}
									this.setState({ end_date }) 
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row>
					<Colxx md="6">
						<AvGroup className="error-l-100 error-t-negative">
						  <Label>Discount Amount</Label>
						  <AvField
							name="amount"
							type="text"
							autoComplete="off"
							validate={{
							  number: {
								value: true,
								errorMessage: "Please enter a valid number"
							  },
							  required: {
								value: true,
								errorMessage: "Please enter the amount"
							  },
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx md="6">
						<AvGroup className="error-l-100 error-t-negative">
						  <Label>Discount Type</Label>
						  <AvField
							name="apply_type"
							type="select"
							autoComplete="off"
							value={this.state.apply_type}
							onChange={(e)=>{ this.setState({ apply_type:e.target.value }) }}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select the discount type"
							  },
							}}
						  >
							<option value="1">On First payment</option>
							<option value="2">Lifetime</option>
						  </AvField>
						</AvGroup>
					</Colxx>
				</Row>
				
				{/*<AvGroup className="error-t-negative">
					<Label>Auto Apply</Label><br/>
					<Label>													  
						<Switch
							className="custom-switch custom-switch-primary im-inline-block"
							checked={this.state.auto_apply}
							onChange={(auto_apply)=>{ this.setState({ auto_apply }) }}
						/>
					</Label>
				</AvGroup>*/}
							 
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-discount" >
			  <IntlMessages id="pages.submit" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="pages.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
