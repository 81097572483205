import React, { Component } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";

import {
	Input,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { NavLink } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import swal from 'sweetalert';

import {REST_API_END_POINT,dataEncryptKey} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddEventModal extends Component{
	constructor(props) {
		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFromDate = this.handleFromDate.bind(this);
		this.handleToDate = this.handleToDate.bind(this);
		
		this.state = {
			calendar_start_date: null,
			calendar_end_date: null,
			fromDate: null,
			toDate: null	 
		};
	}
	
	componentDidMount(){
		
		var calendar_start_date = this.props.calendar_start_date;
		var calendar_end_date = this.props.calendar_end_date;
		var fromDate = calendar_start_date;
		var toDate = calendar_end_date;
		this.setState({
			calendar_start_date,
			calendar_end_date,
			fromDate,
			toDate
		});
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.modalOpen===false && prevProps.calendar_start_date!=this.props.calendar_start_date && this.props.calendar_start_date!=null){
			var calendar_start_date = this.props.calendar_start_date;
			var calendar_end_date = this.props.calendar_end_date;
			
			var check_time = this.props.calendar_start_date.format("HH:00");
			
			if(check_time == '00:00'){
				calendar_start_date.set({hour:9,minute:0,second:0,millisecond:0})
				calendar_end_date.set({hour:15,minute:0,second:0,millisecond:0})
			}
			var fromDate = calendar_start_date;
			var toDate = calendar_end_date;
			this.setState({
				calendar_start_date,
				calendar_end_date,
				fromDate,
				toDate
			});
		}
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			if(this.state.fromDate.unix()>this.state.toDate.unix()){
				swal("Warning!", "The to date should be less than or equal to from date!", "warning");				
				return;
			}
			document.getElementById("add-shift-modal").disabled = true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('startTime',moment(this.state.fromDate).format('YYYY-MM-DD HH:mm'));
			formData.append('endTime',moment(this.state.toDate).format('YYYY-MM-DD HH:mm'));
			formData.append('user_id',user_id);
			formData.append('userId',values.userId);
			formData.append('schedDesc',values.schedDesc);
						
			axios.post(REST_API_END_POINT+'staff/add-scheduler-shift',formData).then((res)=>{ 
				if(res.data.status==1){
					this.props.toggleModal();
					this.props.dataListRender();
					swal("Success!", "The shift has been added successfully!", "success");				
				}else{
					swal("Failed to add shift!", "Please try after sometime.", "error");			
				}
				document.getElementById("add-shift-modal").disabled = false;
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	}
  
	handleFromDate = date => {
		this.setState({
		  fromDate: date
		});
	};
	
	handleToDate = date => {
		this.setState({
		  toDate: date
		});
	};
  
	render() {	
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add a Shift Schedule
				</ModalHeader>
				
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}>
					<ModalBody className="with-switch">			
						<Row>				
							<Colxx lg="12" className="mb-2">
								<Label>User<span className="text-danger">*</span></Label>
								<AvField
									name="userId"
									type="select"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "Please select the user"
										}
									}}
								>
									<option value="" >-- Select User --</option>
									{this.props.users.map(row=>{
										return(<option value={row.id} key={row.id}>{row.name}</option>)
									})}
								</AvField>
							</Colxx>									
						</Row>
												
						<Row>							
							<Colxx lg="12"  className="mb-2">				
								<AvGroup >
									<Label>Description<span className="text-danger">*</span></Label>
									<AvField
										name="schedDesc"
										type="textarea"
										placeholder="Description"
										rows="3"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the description"
											},
											minLength: {
												value: 5,
												errorMessage: "Your message must have minimum of 5 characters"
											},
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						
						<Row>							
							<Colxx lg="6"  className="mb-3">
								<span className="h8 d-block">Shift Start Time<span className="text-danger">*</span></span>
								<div className="">
									<DatePicker
										selected={this.state.fromDate}
										onChange={this.handleFromDate}
										placeholderText="Date and Time"
										showTimeSelect
										timeIntervals={30}
										name="startTime"
										dateFormat="MM/DD/YYYY HH:mm"
									/>
								</div>
							</Colxx>

							<Colxx lg="6"  className="mb-3">
								<span className="h8 d-block">Shift End Time<span className="text-danger">*</span></span>								
								<div className="">
									<DatePicker
										selected={this.state.toDate}
										onChange={this.handleToDate}
										placeholderText="Date and Time"
										showTimeSelect
										timeIntervals={30}
										name="endTime"
										dateFormat="MM/DD/YYYY HH:mm"
									/>
								</div>
							</Colxx>
						</Row>
						
						<Row>							
							<Colxx lg="12"  className="mb-2 text-left mt-4">								
								<Button color="primary" id="add-shift-modal">
								  <IntlMessages id="pages.submit" />
								</Button>{" "}&nbsp;&nbsp;
								<Button color="secondary" outline onClick={this.props.toggleModal}>
								  <IntlMessages id="pages.close" />
								</Button>{"  "}&nbsp;&nbsp;
							</Colxx>																		
						</Row>
					  
					</ModalBody>
				</AvForm>		  
			</Modal>
		);
  }
};

export default AddEventModal;
