import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row,Card, CardBody, CardTitle, Button} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { NavLink } from "react-router-dom";
import AddListModal from "../../../containers/staffmanagement/AddListModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class Stafftime extends Component {

	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			week:null,
			modalOpenAdd: false,
		};	
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};

	componentDidMount() {		
		this.dataListRender();
	}
	
	dataListRender() {
		var formData = new FormData();
		let week;
		formData.append('user_id',this.props.match.params.user_id);

		axios.post(REST_API_END_POINT+'staff/staff-time',formData).then((res)=>{
			var data = res.data;
			week=data.weeks;
			this.setState({ 
				week,	
			});			
		})
	}


	render() {	  
	  
		if(this.state.week!=null){
			var week=this.state.week;
		}else{
			var week;
		}
		
		var user_id = localStorage.getItem('user_id');
		
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.stafftime" match={this.props.match}/>
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
				<Row>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
					
					<Colxx xxs="12" className="mb-4">
						<div className="float-sm-right">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={this.toggleModalAdd}
								>
								Manual Time Entry							  
							</Button>
						</div>
					</Colxx>
				</Row>	
				{week ?<Row className="mb-2">
					{week.map((row,index)=>{
					  return (<Colxx xxs="6" sm="4" md="3" lg="3" className="mb-3" key={index}>		  
						<NavLink to={"/app/staffmanagement/listtime/"+row.year+"/"+row.week_number+"/"+row.user_id} >
							<Card >
								<CardBody className="text-center pl-1 pr-1 pt-2 pb-2 timezone-slots">
									<p className="text-center font-weight-semibold text-muted mb-0">Year: {row.year}</p>
									<p className="lead text-center mb-2">Week: {row ? ((row.week_number!=null)?row.week_number:''):'' }</p>
									<Separator/> 
									<p className=" mt-3 font-weight-semibold mb-0" >{row ? ((row.first_day!=null)?row.first_day:''):'' } through {row ? ((row.last_day!=null)?row.last_day:''):'' }</p>
									<p className="font-weight-semibold mb-0">Hours: {row ? ((row.total_hours!=null)?row.total_hours:''):'' }</p>							
								</CardBody>
							</Card>
						</NavLink>
					</Colxx>)})}
				</Row> :""}
								
				<AddListModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.dataListRender}
					user_id={user_id}
					target_user_id={this.props.match.params.user_id}
					week_start={null}
					week_end={null}
				/>
			</Fragment>
		);
	}
}

export default injectIntl(Stafftime);