import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, DropdownItem, DropdownMenu } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";
import { getCookie, setCookie } from "../../../helpers/Utils";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class selectFacility extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			facilities:[],
			search: '',
		};
		
		this.checkIn = this.checkIn.bind(this);
	}
	
	componentDidMount(){	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory-transfer/select-facility',formData).then((res)=>{
			var data = res.data;
			
			if(data.facilities){
				var facilities=data.facilities.filter(row=>{ return row.facility_id!=facility_id });
				facilities = facilities.map((row,index)=>{
					row.index=index+1;
					return row;
				})
				this.setState({
			        facilities: facilities,
				});
			}else{
				this.setState({
					facilities: [],
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	checkIn(facility_id){	
		setCookie('transfer_facility_id',facility_id);
		window.location = "/app/inventory/transfer";
	}
  
	render() {
		let data = this.state.facilities
		if (this.state.search) {
			data = data.filter(row => {
				return row.facility_name.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: "ID",
				width:60,
				accessor: "index",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Name",
				accessor: "facility_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Check In",
				accessor: "facility_id",
				Cell: props => <Fragment> <Button color="success" size="xs" className="mb-2" onClick={()=>{ this.checkIn(props.value) }}  >CheckIn</Button></Fragment>
			}
		];
		
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.select-facility" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
		
				<Row>
					<Colxx xxs="12">
						<Card>
							<CardBody>
								<div className="mb-4 dropdown-edit">					
									<FormGroup row>
										<Colxx lg="6">
											<Input
											  type="text"
											  name="table_search"
											  id="table_search"
											  placeholder="Search"
											  value={this.state.search}
											  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
											/>
										</Colxx>
									</FormGroup>			
									<ReactTable
									  data={data}
									  columns={dataTableColumns}
									  defaultPageSize={10}
									  minRows="5"
									  filterable={false}
									  showPageJump={false}
									  PaginationComponent={DataTablePagination}
									  showPageSizeOptions={true}
									  defaultSorted={[{id: "send_on",desc: false}]}
									/>
								</div>
							</CardBody>
						</Card>
					</Colxx>
				</Row>
		
			</Fragment>
		);
	}
}
