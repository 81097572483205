import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, Button, CardBody, FormGroup, Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";

import AddShiftModal from "../../../containers/staffmanagement/AddShiftModal";
import ViewShiftModal from "../../../containers/staffmanagement/ViewShiftModal";
import EditShiftModal from "../../../containers/staffmanagement/EditShiftModal";

import {Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import swal from 'sweetalert';

import { CalendarToolbar } from "../../../components/CalendarToolbar";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT, JQUERY_MODULE, dataEncryptKey} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const localizer = momentLocalizer(moment);

export default class Staffscheduler extends Component {
	
	constructor(props) {
		super(props);
    
		this.state = {
			modalOpenAddShift: false,
			modalOpenViewShift: false,
			modalOpenEditShift: false,
			calendarData:[],
			users:[],
			calendar_start_date:null,
			calendar_end_date:null,
			schedule:null,
		}
		
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleModalAddShift = this.toggleModalAddShift.bind(this);
		this.toggleModalViewShift = this.toggleModalViewShift.bind(this);
		this.toggleModalEditShift = this.toggleModalEditShift.bind(this);
	};
	
	componentDidMount(){
		this.dataListRender();
    }
	
	dataListRender(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'staff/scheduler-data',formData).then((res)=>{
			var data = res.data;
			if(data.status==1){
				this.setState({
					calendarData:data.scheduler,
					users:data.users
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
    }
		
	toggleModalAddShift = (slot) => {
		if(typeof slot!='undefined'){
			if(typeof slot.box!='undefined'){
				var calendar_start_date = moment(slot.start.toISOString());
				var calendar_end_date = moment(slot.end.toISOString());
				this.setState({ calendar_start_date, calendar_end_date });
			}
		}
		this.setState({
			modalOpenAddShift: !this.state.modalOpenAddShift
		});
	};
	
	toggleModalViewShift = (e,schedule=null) => {
		this.setState({
			schedule:schedule,
			modalOpenViewShift: !this.state.modalOpenViewShift
		});
	};
	
	toggleModalEditShift = (e,schedule=null) => {
		this.setState({
			schedule:schedule,
			modalOpenEditShift: !this.state.modalOpenEditShift,
			modalOpenViewShift:false
		});
	};
	
	deleteShift = (e,selectedId,delTitle)  => {
		
		var msg="Delete the schedule ?";
		var success_msg="The schedule has been deleted.";
		var failure_msg="Failed to delete schedule";
        
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-shift").disabled=true;
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('deleteId',selectedId);
				formData.append('delTitle',delTitle);
				axios.post(REST_API_END_POINT+'staff/delete-schedule',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("delete-shift").disabled=false;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					this.setState({ modalOpenViewShift:false });
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("delete-shift").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	render() {
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.staff-scheduler" match={this.props.match}/>
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<Row className=" ">
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
				</Row>
				
				<Row>			
					<Colxx lg="12"  className="mb-4">
						<Card>
							<CardBody>
								<Calendar
									selectable
									localizer={localizer}
									style={{ minHeight: "800px" }}
									events={this.state.calendarData}
									views={["month","week"]}
									className="dashboard_calender staff-scheduler"
									onSelectEvent ={schedule => this.toggleModalViewShift(null,schedule)}
									onSelectSlot={this.toggleModalAddShift}
									components={{
										toolbar: CalendarToolbar
									}}
								/>
							</CardBody>			  
						</Card>
					</Colxx>			 
				</Row>
				
				<AddShiftModal
					modalOpen={this.state.modalOpenAddShift}
					toggleModal={this.toggleModalAddShift}
					dataListRender={this.dataListRender}
					users={this.state.users}
					calendar_start_date={this.state.calendar_start_date}
					calendar_end_date={this.state.calendar_end_date}
				/>
				
				<ViewShiftModal
					modalOpen={this.state.modalOpenViewShift}
					toggleModal={this.toggleModalViewShift}
					deleteShift={this.deleteShift}
					toggleModalEditShift={this.toggleModalEditShift}
					schedule={this.state.schedule}
				/>
				
				<EditShiftModal
					modalOpen={this.state.modalOpenEditShift}
					toggleModal={this.toggleModalEditShift}
					dataListRender={this.dataListRender}
					schedule={this.state.schedule}
					users={this.state.users}
					calendar_start_date={this.state.calendar_start_date}
					calendar_end_date={this.state.calendar_end_date}
				/>
			
		  </Fragment>
		);
	}
}
