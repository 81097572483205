import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import Select from "react-select";
var dataCrypto = new SimpleCrypto(dataEncryptKey);
const selectStatus = [
    { label: "Active", value: "1", key: 0 },
    { label: "Inactive", value: "0", key: 1 }
];

class EditBankModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {       
			Status:[],	
		};	
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){		
            let externalbank;
			var status=null;
			if (prevProps.external_bank_id!== this.props.external_bank_id && this.props.external_bank_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('external_bank_id',this.props.external_bank_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'external-bank/get-external-bank-by-id',formData).then((res)=>{
					
					var data = res.data;
					externalbank=data.external_bank
					
					selectStatus.forEach((element,index) => {
						if(element.value==externalbank.status){
							status=element;
						}
					});
				
					this.setState({ 
						externalbank,
						statusType:status
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-bank").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("bankForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('external_bank_id',this.props.external_bank_id);

			axios.post(REST_API_END_POINT+'external-bank/edit-bank',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-bank").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-bank").disabled=false;
			});

		}
	}

  render() {

		if(this.state.externalbank!=null){
			var externalbank=this.state.externalbank;
		}else{
			var externalbank;
		}
  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit External Bank
		  </ModalHeader>
		  <AvForm
		        id="bankForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Name<span className="text-danger">*</span></Label>
							<AvField
								id="bank_name"
								name="bank_name"
								type="text"
								autoComplete="off"
								value={ externalbank?((externalbank.bank_name!=null)?externalbank.bank_name:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required"
								  },
								  pattern: {
									value: "^[a-zA-Z0-9 ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}
							/>
						</AvGroup>
					</Colxx>					
				</Row>			
				   <Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Status<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="status"
								id="status"
								value={this.state.statusType}						
								options={selectStatus}
								onChange={statusType => { this.setState({ statusType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>							
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-bank" >
			  <IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditBankModal;
