import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

import { REST_API_END_POINT, themeColor } from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';


export default class EditSubscriberModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state={
			edit_data:null,
			blocking: false
		}
	}
  
	componentDidUpdate(prevProps, prevState) {
		if(typeof(this.props.user_id)==='string'&&this.props.user_id!==''&&prevProps.modalOpen===false){
			this.setState({ blocking: true });
			
			var formData = new FormData();
			formData.append('user_id',this.props.user_id);
			
			axios.post(REST_API_END_POINT+'admin/get-user-by-id',formData).then((res)=>{
				if(res.data.status==1){
					this.setState({
						edit_data: res.data.data,
					});
				}
				this.setState({ blocking: false });
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				this.setState({ blocking: false });
			});
		}
	}

	async handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("edit-subscriber").disabled = true;
			var user = this.state.edit_data;
			
			var formData = new FormData(document.getElementById("edit_subscriber_form"));
			formData.append('user_id',user.id);
			
			axios.post(REST_API_END_POINT+'admin/update-user',formData).then((res)=>{ 
				if(res.data.status==1){
					this.props.toggleModal();				
					this.props.dataListRender();				
					swal("Success!", "Subscriber details have been updated successfully!", "success");				
				}else{
					swal("Failed to update subscriber details!", "Please try after sometime.", "error");			
				}
				document.getElementById("edit-subscription").disabled = false;
			}).catch(e => console.log("Addition failed , Error ", e));
			
		  
		}
	}
  

  render() {
		var edit_data = this.state.edit_data;
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Subscriber
		  </ModalHeader>
		  <BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}> 
		  <AvForm
				id="edit_subscriber_form"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			
				<AvGroup className="error-t-negative">
				  <Label><IntlMessages id="user.first_name" /></Label>
				  <AvField
					name="first_name"
					type="text"
					autoComplete="off"
					value={edit_data!=null ? edit_data.first_name:""}
					validate={{
					  required: {
						value: true,
						errorMessage: "Please enter the first name"
					  },
					  pattern: {
						value: "^[a-zA-Z ,.'-]+$",
						errorMessage: "Invalid first name"
					  },
					  minLength: {
						value: 2,
						errorMessage:
						  "Your first name must be between 2 and 100 characters"
					  },
					  maxLength: {
						value: 100,
						errorMessage:
						  "Your first name must be between 2 and 100 characters"
					  }
					}}
				  />
				</AvGroup>
				
				<AvGroup className="error-t-negative">
				  <Label><IntlMessages id="user.last_name" /></Label>
				  <AvField
					name="last_name"
					type="text"
					autoComplete="off"
					value={edit_data!=null ? edit_data.last_name:""}
					validate={{
					  pattern: {
						value: "^[a-zA-Z ,.'-]+$",
						errorMessage: "Invalid last name"
					  },
					  minLength: {
						value: 1,
						errorMessage:
						  "Your last name must be between 1 and 100 characters"
					  },
					  maxLength: {
						value: 100,
						errorMessage:
						  "Your last name must be between 1 and 100 characters"
					  }
					}}
				  />
				</AvGroup>

				<AvGroup className="error-t-negative">
				  <Label><IntlMessages id="user.email" /></Label>
				  <AvField
					name="email"
					type="email"
					autoComplete="off"
					value={edit_data!=null ? edit_data.email:""}
					validate={{
					  required: {
						value: true,
						errorMessage: "Please enter the email address"
					  },
					  email: {
						value: true,
						errorMessage: "Please enter a valid email address"
					  }
					}}
				  />
				</AvGroup>
				
				<AvGroup className="error-l-100 error-t-negative">
				  <Label>Phone</Label>
				  <AvField
					name="phone"
					type="text"
					autoComplete="off"
					value={edit_data!=null ? edit_data.phone:""}
					validate={{
					  number: {
						value: true,
						errorMessage: "Please enter a valid number"
					  },
					  minLength: {
						value: 7,
						errorMessage:
						  "Your name must be between 7 and 15 characters"
					  },
					  maxLength: {
						value: 15,
						errorMessage:
						  "Your name must be between 7 and 15 characters"
					  }
					}}
				  />
				</AvGroup>
				
				<AvGroup className="error-t-negative">
				  <Label>Company Name</Label>
				  <AvField
					name="company_name"
					type="text"
					autoComplete="off"
					value={edit_data!=null ? edit_data.company_name:""}
					validate={{
					  pattern: {
						value: "^[a-zA-Z0-9 ,.'-]+$",
						errorMessage: "Invalid company name"
					  },
					  minLength: {
						value: 2,
						errorMessage:
						  "Your company name must be between 2 and 100 characters"
					  },
					  maxLength: {
						value: 100,
						errorMessage:
						  "Your company name must be between 2 and 100 characters"
					  }
					}}
				  />
				</AvGroup>
				
			 
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="edit-subscriber" >
			  <IntlMessages id="pages.submit" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="pages.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
		   </BlockUi>  
	  </Modal>
	  
	  );
  }
};
