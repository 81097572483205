import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { NavLink } from "react-router-dom";

import number_format from "number_format-php";
import swal from 'sweetalert';

import { getCookie, setCookie } from "../../../helpers/Utils";

import PauseRestartProcessingModal from "../../../containers/processor/PauseRestartProcessingModal";
import AddToInventoryModal from "../../../containers/processor/AddToInventoryModal";
import PrintBatchUIDModal from "../../../containers/processor/PrintBatchUIDModal";
import PrintPackageUIDModal from "../../../containers/processor/PrintPackageUIDModal";

import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey, JQUERY_MODULE, LBS_CONSTANT, EQUIPMENT_STATUS} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const batch_types = [
    { label: "Distillate", value: "1", key: 1 }
];

export default class DistillationOperation extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			equipments:[],
			containers:[],
			products:[],
			equipment_id:null,
			equipment_status:null,
			equipment_name:"",
			manual_change_of_equipment_status:{ label: "IDLE", value: "0", key: 0 },
			record_batch_type:{ label: "Distillate", value: "1", key: 1 },
			record_batch_uid:"",
			container_uid:null,
			in_house_weight:0,
			in_house_weight_gram:0,
			total_in_house_weight:0,
			container_uids:[],
			equipment_load:[],
			process_step:1,
			
			micron_level:"",
			heater_temperature:"",
			patch_leftover:"",
			t_h_fractions:"",
			reclaim:"",
			crude_oil:"",
			yield:"",
			add_to_inventory_qty:"",
			
			input_disabled:{
				micron_level:false,
				heater_temperature:false,
				vaccum:false,
				
				reclaim:true,
				patch_leftover:true,
				t_h_fractions:true,
				
				crude_oil:true,
				
				start_processing:true,
				pause_restart_processing:true,
				end_processing:true,
				calculate_yield:true,
				add_to_inventory:true,
				print_batch_uid:true,
				load_again:true,
			},
			stop_timer:0,
			modalOpenPause: false,
			modalOpenAddToInventory: false,
			modalOpenPrintBatchUID: false,
			modalOpenPrintPackageUID: false,
			package_details:null,
			
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeEquipment = this.changeEquipment.bind(this);
		this.getBatchUID = this.getBatchUID.bind(this);
		this.getContainerDetails = this.getContainerDetails.bind(this);
		this.loadContainer = this.loadContainer.bind(this);
		this.removeContainer = this.removeContainer.bind(this);
		this.resetInformation = this.resetInformation.bind(this);
		this.completeBatchLoading = this.completeBatchLoading.bind(this);
		this.startProcessing = this.startProcessing.bind(this);
		this.pauseProcessing = this.pauseProcessing.bind(this);
		this.restartProcessing = this.restartProcessing.bind(this);
		this.endProcessing = this.endProcessing.bind(this);
		this.calculateYield = this.calculateYield.bind(this);
		this.getProcessTime = this.getProcessTime.bind(this);
		this.toggleModalPause = this.toggleModalPause.bind(this);
		this.toggleModalAddToInventory = this.toggleModalAddToInventory.bind(this);
		this.toggleModalPrintBatchUID = this.toggleModalPrintBatchUID.bind(this);
		this.toggleModalPrintPackageUID = this.toggleModalPrintPackageUID.bind(this);
		this.AddToInventory = this.AddToInventory.bind(this);
		this.loadAgain = this.loadAgain.bind(this);
	}
	
	componentDidMount(){
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/distillation-loading',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				var equipments = data.equipments;
				equipments = equipments.map(row=>{
					row.label=row.equipment_name;
					row.value=row.equipment_id;
					row.key=row.equipment_id;
					return row;
				});
				
				var containers = data.containers;
				containers = containers.map(row=>{
					row.label=row.batch_uid;
					row.value=row.batch_uid;
					row.key=row.load_id;
					return row;
				});
				
				var products = data.products;
				products = products.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				
				this.setState({
					equipments,
					containers,
					products
				});
			}else{
				this.setState({					
					equipments:[],
					containers:[],
					products: [],
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleModalPause = () => {
		this.setState({
			modalOpenPause: !this.state.modalOpenPause
		});
	};
	
	toggleModalAddToInventory = () => {
		this.setState({
			modalOpenAddToInventory: !this.state.modalOpenAddToInventory
		});
	};
	
	toggleModalPrintBatchUID = () => {
		this.setState({
			modalOpenPrintBatchUID: !this.state.modalOpenPrintBatchUID
		});
	};
	
	toggleModalPrintPackageUID = () => {
		this.setState({
			modalOpenPrintPackageUID: !this.state.modalOpenPrintPackageUID
		});
	};
	
	changeEquipment(equipment_id){
		var equipment_name="";
		var equipment_status=null;
		var manual_change_of_equipment_status=null;
		var equipment_load=[];
		var container_uids=[];
		var process_step = 1;
		
		if(equipment_id!=null){
			equipment_name= equipment_id.equipment_name;
			
			
			var equipment_load = getCookie('equipment_load_'+equipment_id.value,'object');
			if(equipment_load==null){
				equipment_load=[];
			}else{
				container_uids = equipment_load.map(row=>row.batch_uid);
			}
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('equipment_id',equipment_id.value);
			
			axios.post(REST_API_END_POINT+'processor/get-equipment-details',formData).then((res)=>{
				var data = res.data;
				var stop_timer = 0;
				if(data.status==1){
					
					const total_in_house_weight = parseFloat(data.total_gram);
					const batch_type_name = data.equipment.batch_type_name;
					var record_batch_type = { label: "Distillate", value: "1", key: 1 };
					if(batch_type_name!=null){
						record_batch_type = batch_types.find(row=>{ return row.label==batch_type_name });
					}
					const equipment = data.equipment;
					const load_details = equipment.load_details;
					
					var micron_level="";
					var heater_temperature="";
					
					var patch_leftover="";
					var t_h_fractions="";
					var reclaim="";
					
					var crude_oil="";
					var x_yield="";
					var add_to_inventory_qty="";
					
					var input_disabled={
						micron_level:false,
						heater_temperature:false,
						
						reclaim:true,
						patch_leftover:true,
						t_h_fractions:true,
				
						crude_oil:true,
						
						start_processing:true,
						pause_restart_processing:true,
						end_processing:true,
						calculate_yield:true,
						add_to_inventory:true,
						print_batch_uid:true,
						load_again:true,
					}
					
					if(equipment.equipment_status=="0" || equipment.equipment_status=="1"){
						equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="1" });
						manual_change_of_equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="1" });
					}else{
						equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value==equipment.equipment_status })
						manual_change_of_equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value==equipment.equipment_status })
					}
			
					if(equipment.equipment_status!="0"){
						process_step=2;
					}else if(equipment.equipment_status=="0"){
						this.getBatchUID(equipment_id.value);
					}
					
					var record_batch_uid=this.state.record_batch_uid;
					
					this.setState({
						equipment_status,
						manual_change_of_equipment_status,
						process_step
					},()=>{
						if(typeof load_details.process_time!='undefined'){
							record_batch_uid = load_details.batch_uid;
							
							if(equipment.equipment_status!="0" && equipment.equipment_status!="1"){
								micron_level = parseInt(load_details.micron_level);
								heater_temperature = parseInt(load_details.heater_temperature);								
							}
							
							if(equipment.equipment_status=="1"){
								input_disabled.micron_level=false;
								input_disabled.heater_temperature=false;								
								input_disabled.crude_oil=true;
							}else if(equipment.equipment_status=="4"){
								document.getElementById('process_time').innerHTML=load_details.process_time;
								
								input_disabled.micron_level=true;
								input_disabled.heater_temperature=true;
								input_disabled.patch_leftover=false;
								input_disabled.t_h_fractions=false;
								input_disabled.reclaim=false;						
								input_disabled.crude_oil=false;
								input_disabled.start_processing=true;
								input_disabled.pause_restart_processing=true;
								input_disabled.end_processing=true;
								input_disabled.calculate_yield=false;
								
								stop_timer = 0;	
								input_disabled.add_to_inventory=true;
								
								if((load_details.reclaim || load_details.patch_leftover || load_details.t_h_fractions) && load_details.output_product){
									patch_leftover = load_details.patch_leftover;
									t_h_fractions = load_details.t_h_fractions;
									reclaim = load_details.reclaim;
								
									crude_oil = parseInt(load_details.output_product);
									x_yield = parseFloat(load_details.yield);
									
									input_disabled.load_again=false;
									input_disabled.print_batch_uid=false;
									
									let added_to_inventory = load_details.added_to_inventory;
									if(added_to_inventory==0){
										input_disabled.add_to_inventory=false;
										add_to_inventory_qty=parseFloat(load_details.output_product);
									}
								}
							}else if(equipment.equipment_status=="3"){											
								document.getElementById('process_time').innerHTML=load_details.process_time;
								input_disabled.micron_level=true;
								input_disabled.heater_temperature=true;
								input_disabled.patch_leftover=true;
								input_disabled.t_h_fractions=true;
								input_disabled.reclaim=true;
								input_disabled.start_processing=false;
								input_disabled.pause_restart_processing=true;
								input_disabled.end_processing=false;
							}else if(equipment.equipment_status=="2"){
								input_disabled={
									micron_level:true,
									heater_temperature:true,
									
									reclaim:true,
									patch_leftover:true,
									t_h_fractions:true,
									crude_oil:true,
									
									start_processing:true,
									pause_restart_processing:false,
									end_processing:false,
									calculate_yield:true,
									add_to_inventory:true,
									print_batch_uid:true,
									load_again:true,
								};
								stop_timer = 1;
								setTimeout(this.getProcessTime, 1000);
							}

						}
						
						this.setState({
							equipment_status,
							manual_change_of_equipment_status,
							process_step,
							record_batch_type,
							stop_timer,
							micron_level,
							heater_temperature,
							reclaim,
							patch_leftover,
							t_h_fractions,
							crude_oil,
							yield:x_yield,
							add_to_inventory_qty,						
							input_disabled,
							total_in_house_weight,
							record_batch_uid
						});
					});
					
					
				}else{
					this.setState({					
						record_batch_type:{ label: "Distillate", value: "1", key: 1 },
						stop_timer,
						total_in_house_weight:0
					});
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
		this.setState({ equipment_id, equipment_name, equipment_load, container_uids })
	}
	
	getBatchUID(equipment_id){	
		const batch_type = this.state.record_batch_type!=null ? this.state.record_batch_type.value:"";
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('equipment_id',equipment_id);
        formData.append('batch_type',batch_type);
		
		axios.post(REST_API_END_POINT+'processor/get-batch-uid',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				const record_batch_uid = data.batch_uid
				
				this.setState({
					record_batch_uid
				});
			}else{
				this.setState({					
					record_batch_uid:""
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
	}
	
	getContainerDetails(container_id) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('batch_uid',container_id);
		
		axios.post(REST_API_END_POINT+'processor/get-batch-details',formData).then((res)=>{
			var data = res.data;
			var total_grams=0;
				
			if(data.status==1){
				total_grams = data.total_container_weight;
			}
			
			this.setState({
				in_house_weight:total_grams,
				in_house_weight_gram:total_grams,
			});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	loadContainer(){
		const equipment_id = this.state.equipment_id;
		
		if(equipment_id!=null){
			const container_uid = this.state.container_uid;
			if(container_uid!=null){
				var container_uids = this.state.container_uids;
				
				if(container_uids.indexOf(parseInt(container_uid.value))==-1){
					if(parseFloat(this.state.in_house_weight)>0){
						container_uids.push(parseInt(container_uid.value));
						
						var equipment_load = this.state.equipment_load;			
						
						var in_house_weight_lbs_val = 0;
						var in_house_weight_gram_val = parseInt(this.state.in_house_weight);	
						
						let container = {};
						container.container_id = 0;
						container.container_name = container_uid.label;
						container.batch_uid = container_uid.value;
						container.in_house_weight = parseFloat(this.state.in_house_weight);
						container.in_house_weight_lbs = in_house_weight_lbs_val;
						container.in_house_weight_gram = in_house_weight_gram_val;
						
						if(container.in_house_weight>0){
							equipment_load.push(container);
							setCookie('equipment_load_'+equipment_id.value,equipment_load);
						}
						
						this.setState({ container_uids, equipment_load  });
						this.resetInformation();
					}else{
						swal("Warning!","This Crude Oil UID is empty","warning");
					}
				}else{
					swal("Warning!","This Crude Oil UID is already loaded","warning");
				}
			}else{
				swal("Warning!","Please select a Crude Oil UID","warning");
			}
		}else{
			swal("Warning!","Please select an equipment","warning");
		}
	}
	
	removeContainer(batch_uid) {
		var equipment_load = this.state.equipment_load.filter(row=>{ return row.batch_uid!=batch_uid });
		var container_uids = this.state.container_uids.filter(row=>{ return row!=batch_uid });
		this.setState({ container_uids, equipment_load });
		
		const equipment_id = this.state.equipment_id;		
		if(equipment_id!=null){
			setCookie('equipment_load_'+equipment_id.value,equipment_load);
		}
	}
	
	resetInformation(){
		this.setState({ container_uid:null,	in_house_weight_lbs_val:"",	in_house_weight_gram_val:"", in_house_weight:0  });		
	}
	
	completeBatchLoading(){
		const equipment_id = this.state.equipment_id;
		if(equipment_id!=null){
			var container_uids = this.state.container_uids;			
			if(container_uids.length){
				const record_batch_type = this.state.record_batch_type;
				const record_batch_uid = this.state.record_batch_uid;
				const equipment_load = this.state.equipment_load;
				
				if(record_batch_uid==""){
					swal("Warning!",'Batch UID is not generated. Please select the equipment or refresh the page.',"warning");
					return;
				}
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('equipment_status',1);
				formData.append('equipment_id',equipment_id.value);
				formData.append('batch_type',record_batch_type.value);
				formData.append('batch_uid',record_batch_uid);
				formData.append('equipment_load',JSON.stringify(equipment_load));
				
				axios.post(REST_API_END_POINT+'processor/complete-batch-loading',formData).then((res)=>{
					var data = res.data;
						
					if(data.status==1){
						this.resetInformation();
						this.setState({ process_step:2  });
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});				
			}else{
				swal("Warning!","Please load at least one Crude Oil UID","warning");
			}
		}else{
			swal("Warning!","Please select an equipment","warning");
		}

	}
	
	startProcessing(){	
		const equipment_id = this.state.equipment_id;
		
		const micron_level = this.state.micron_level;
		const heater_temperature = this.state.heater_temperature;
		
		var input_disabled={
				micron_level:true,
				heater_temperature:true,
				reclaim:true,
				patch_leftover:true,
				t_h_fractions:true,
				crude_oil:true,
				
				start_processing:true,
				pause_restart_processing:false,
				end_processing:false,
				calculate_yield:true,
				add_to_inventory:true,
				print_batch_uid:true,
				load_again:true,
			};
		
		var user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('user_id',user_id);
		formData.append('facility_id',facility_id);
		formData.append('equipment_status',2);
		formData.append('equipment_id',equipment_id.value);
		formData.append('micron_level',micron_level);
		formData.append('heater_temperature',heater_temperature);
		
		axios.post(REST_API_END_POINT+'processor/update-batch-loading',formData).then((res)=>{
			var data = res.data;
				
			if(data.status==1){
				const equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="2" });
				const manual_change_of_equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="2" });
				const stop_timer=1;
				this.setState({ equipment_status, manual_change_of_equipment_status, input_disabled, stop_timer });
				setTimeout(this.getProcessTime, 1000);
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	pauseProcessing(){
		const equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="3" });
		const manual_change_of_equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="3" });
		const stop_timer=0;
		var input_disabled = this.state.input_disabled;
		input_disabled.pause_restart_processing=true;
		input_disabled.start_processing=false;
		this.setState({ equipment_status, manual_change_of_equipment_status, input_disabled, stop_timer });
	}
	
	restartProcessing(){
		const equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="2" });
		const manual_change_of_equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="2" });
		this.setState({ equipment_status, manual_change_of_equipment_status });
	}
	
	endProcessing(){
		swal({
		  title: "Are you sure?",
		  text: "End Process?",
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then((willDelete) => {
			if (willDelete) {
				const equipment_id = this.state.equipment_id;
				var input_disabled=this.state.input_disabled;
			
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('equipment_id',equipment_id.value);
				
				axios.post(REST_API_END_POINT+'processor/complete-run',formData).then((res)=>{
					var data = res.data;
						
					if(data.status==1){
						const equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="4" });
						const manual_change_of_equipment_status = EQUIPMENT_STATUS.find(row=>{ return row.value=="4" });
						const stop_timer=0;
						
						document.getElementById('process_time').innerHTML=data.load_details.process_time;
									
						input_disabled.crude_oil=false;
						input_disabled.patch_leftover=false;
						input_disabled.t_h_fractions=false;
						input_disabled.reclaim=false;
						input_disabled.start_processing=true;
						input_disabled.pause_restart_processing=true;
						input_disabled.end_processing=true;
						input_disabled.calculate_yield=false;
						
						if(this.state.record_batch_type.value=="2"){
							input_disabled.print_batch_uid=false;
							input_disabled.load_again=false;
						}
						
						input_disabled.add_to_inventory=true;
						
						this.setState({ equipment_status, manual_change_of_equipment_status, input_disabled, stop_timer });				
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}else{
				swal.close();
			}
		});
	}
	
	calculateYield(){
		const equipment_id = this.state.equipment_id;
		var input_disabled=this.state.input_disabled;
		
		var terps = 0;
		var patch_leftover = this.state.patch_leftover;
		var t_h_fractions = this.state.t_h_fractions;
		var reclaim = this.state.reclaim;
		var crude_oil = this.state.crude_oil;
		
		var total_weight_loaded = this.state.total_in_house_weight;
		if(total_weight_loaded==0 && this.state.equipment_load.length){
			total_weight_loaded = parseFloat(this.state.equipment_load.map(r=>r.in_house_weight).reduce((total,in_house_weight)=>{ return parseFloat(total)+parseFloat(in_house_weight) }));
		}
		
		if(reclaim!='' && crude_oil!=''){
			terps = parseFloat(terps);
			patch_leftover = patch_leftover!="" ? parseFloat(patch_leftover):0;
			t_h_fractions = t_h_fractions!="" ? parseFloat(t_h_fractions):0;
			reclaim = reclaim!="" ? parseFloat(reclaim):0;
			crude_oil = parseFloat(crude_oil);
			
			total_weight_loaded = parseFloat(total_weight_loaded);
			let x_yield = parseFloat(crude_oil/total_weight_loaded*100).toFixed(1);
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('equipment_id',equipment_id.value);
			formData.append('terps_qty',terps);
			formData.append('patch_leftover_qty',patch_leftover);
			formData.append('t_h_fractions_qty',t_h_fractions);
			formData.append('reclaim_qty',reclaim);
			formData.append('output_qty',crude_oil);
			formData.append('yield_qty',x_yield);
			
			axios.post(REST_API_END_POINT+'processor/calculate-yield',formData).then((res)=>{
				var data = res.data;
					
				if(data.status==1){
					let add_to_inventory_qty=0;
					input_disabled.print_batch_uid=false;
					input_disabled.load_again=false;
					
					input_disabled.add_to_inventory=true;
					if(data.load_details.added_to_inventory==0){
						input_disabled.add_to_inventory=false;
						add_to_inventory_qty=parseFloat(crude_oil);
					}
					
					this.setState({ input_disabled, yield:x_yield, add_to_inventory_qty });
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}else if(reclaim==''){
			swal("Warning!",'Please enter the quantity of reclaim',"warning");
		}else if(crude_oil==''){
			swal("Warning!",'Please enter the quantity of cannabis oil',"warning");
		}
	}
	
	AddToInventory(package_details){
		var input_disabled=this.state.input_disabled;
		input_disabled.add_to_inventory=true;
		this.setState({ input_disabled, package_details });	
		this.toggleModalPrintBatchUID();
	}
	
	getProcessTime(){
		const equipment_id = this.state.equipment_id;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('equipment_id',equipment_id.value);
		
		axios.post(REST_API_END_POINT+'processor/get-process-time',formData).then((res)=>{
			var data = res.data;
				
			if(data.status==1){
				if(data.load_details){
					const process_time = data.load_details.process_time;				
					document.getElementById('process_time').innerHTML=process_time;
				}
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		if(this.state.stop_timer!=0){
			setTimeout(this.getProcessTime, 1000); 
		}		
	}
	
	loadAgain(){
		const equipment_id = this.state.equipment_id;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('equipment_id',equipment_id.value);
		
		axios.post(REST_API_END_POINT+'processor/load-again',formData).then((res)=>{
			var data = res.data;
				
			if(data.status==1){
				setCookie('equipment_load_'+equipment_id.value,null);
				this.setState({ 
					products:[],
					equipment_id:null,
					equipment_status:null,
					equipment_name:"",
					manual_change_of_equipment_status:{ label: "IDLE", value: "0", key: 0 },
					record_batch_type:{ label: "Distillate", value: "1", key: 1 },
					record_batch_uid:"",
					container_uid:null,			
					in_house_weight:0,
					in_house_weight_gram:0,
					total_in_house_weight:0,
					container_uids:[],
					equipment_load:[],
					process_step:1,
					
					micron_level:"",
					heater_temperature:"",
					patch_leftover:"",
					t_h_fractions:"",
					reclaim:"",
					crude_oil:"",
					yield:"",
					add_to_inventory_qty:"",
					
					input_disabled:{
						micron_level:false,
						heater_temperature:false,
						
						reclaim:true,
						patch_leftover:true,
						t_h_fractions:true,
						crude_oil:true,
						
						start_processing:true,
						pause_restart_processing:true,
						end_processing:true,
						calculate_yield:true,
						add_to_inventory:true,
						print_batch_uid:true,
						load_again:true,
					},
					stop_timer:0,
					package_details:null
				});	
				
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {
		return;
	}
  
	render() {
		const { equipments, equipment_status, equipment_id, equipment_name, equipment_load, container_uids, products, process_step, input_disabled, record_batch_type } = this.state;
		var containers = this.state.containers;
		containers = containers.filter(row=>{ return container_uids.indexOf(row.value)==-1 });
		
		const user_data = JSON.parse(localStorage.getItem('user_data'));
		var total_in_house_weight_gram=0;
		var total_grams=this.state.total_in_house_weight;
		
		var final_equipment_load=equipment_load.map((row,index)=>{
			if(this.state.total_in_house_weight==0){
				total_grams+=parseFloat(row.in_house_weight);
			}
			return(<Row className="align-items-center mb-3" key={index}>
						<Colxx lg="4">
							<Input
								readOnly={true}
								value={row.container_name}
							/>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="10">
									<Input
										readOnly={true}
										value={row.in_house_weight_gram+" (Grams)"}
									/>
								</Colxx>
								<Colxx lg="2">
									<span className="text-danger" onClick={()=>{ this.removeContainer(row.batch_uid) }}><i className="fas fa-2x fa-times" /></span>
								</Colxx>
							</Row>
						</Colxx>	
					</Row>);
		});
		total_in_house_weight_gram=(total_grams).toFixed(5);
		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.distillation-operation" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
			
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<Card className="p-4 mt-4 mb-3">
						<h3 className="font-weight-bold mb-0">Equipment ID & Status</h3>
						<Row>
							<Colxx lg="4" className="mt-3">
								<AvGroup>
									<Label>Distillation Equipment ID<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="equipment_id"
										id="equipment_id"
										placeholder="-- Select Equipment --"
										value={this.state.equipment_id}						
										options={equipments}
										onChange={this.changeEquipment}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx lg="4" className="mt-3">
								<Label>Equipment Status<span className="text-danger">*</span></Label>
								<Input
									id="equipment_status"
									name="equipment_status"
									className="whitebg-equipment-status"
									readOnly={true}
									style={{color:equipment_status!=null ? equipment_status.color:"#000"}}
									value={equipment_status!=null ? equipment_status.label:""}
								/>
							</Colxx>
							
							{user_data.user_type=="1" ? <Colxx lg="4" className="mt-3">
								<AvGroup>
									<Label>Manual Change Of Equipment Status<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="manual_change_of_equipment_status"
										id="manual_change_of_equipment_status"
										value={this.state.manual_change_of_equipment_status}						
										options={EQUIPMENT_STATUS}
										onChange={manual_change_of_equipment_status => { this.setState({ manual_change_of_equipment_status }) }}
									/>
								</AvGroup>
							</Colxx>:""}
						</Row>
					</Card>
			
					{process_step==1 ? <Card className="p-4 mt-4">
						<h3 className="font-weight-bold">LOADING {equipment_name}</h3>
						<Row>
							<Colxx lg="6" className="mb-4 mt-4">
								<AvGroup>
									<Label>BATCH TYPE<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="record_batch_type"
										id="record_batch_type"
										value={this.state.record_batch_type}
										options={batch_types}
										onChange={record_batch_type => { 
											this.setState({ record_batch_type },()=>{
												if(this.state.equipment_id!=null){
													this.getBatchUID(this.state.equipment_id.value);
												}
											}) 
										}}
									/>
								</AvGroup>
							</Colxx>
							
							<Colxx lg="6" className="mb-4 mt-4">
								<Label>DISTILLATE BATCH UID<span className="text-danger">*</span></Label>
								<Input
									id="record_batch_uid"
									name="record_batch_uid"
									readOnly={true}
									value={this.state.record_batch_uid}
								/>
							</Colxx>
						</Row>

						<h3 className="font-weight-bold">Cannabis Oil to Distiller</h3>
						<Row>
							<Colxx lg="12">
								<Row>
									<Colxx lg="6"  className="mb-4" >
										<AvGroup className="error-t-negative">
											<Label>Enter/Scan Cannabis Oil UID</Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="container_uid"
												id="container_uid"
												placeholder="-- Select Container --"
												value={this.state.container_uid}						
												options={containers}
												onChange={container_uid => { 
													this.setState({ container_uid }) 
													if(container_uid!=null){
														this.getContainerDetails(container_uid.value);
													}
												}}
											/>
										</AvGroup>
									</Colxx>
									<Colxx lg="6" className="mb-4 form-group-mb-0">										
										<Label>In House Weight Loaded</Label>
										<AvField
											id="in_house_weight"
											name="in_house_weight"
											type="number"
											placeholder="(Grams)"
											value={this.state.in_house_weight}
											onChange={e=>{
												const in_house_weight = e.target.value;
												this.setState({ in_house_weight });
											}}
											autoComplete="off"
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  }
											}}
										/>
										<Label>Grams</Label>
									</Colxx>
								</Row>
								
								<Row>
									<Colxx xl="6">
										<Button	block color="success" size="lg"	className="mr-1 " onClick={this.loadContainer} >Load</Button> 
									</Colxx>
									<Colxx xl="6">
										<Button	block color="danger" size="lg" className="mr-1" onClick={this.completeBatchLoading}	>Complete</Button> <br/><br/>
									</Colxx>
								</Row>
								
								<div>
								{final_equipment_load}
								</div>
								
								<Row className="align-items-center">
									<Colxx lg="4">
										<Label>Total Cannabis Oil Weight Loaded</Label>
									</Colxx>
									
									<Colxx lg="8">
										<Row className="align-items-center">											
											<Colxx lg="10">
												<Input
													readOnly={true}
													value={total_in_house_weight_gram+" (Grams)"}
												/>
											</Colxx>
										</Row>
									</Colxx>
											
								</Row>
				
							</Colxx>
						</Row>
					</Card>:""}

					{process_step==2 ? <Card className="p-4 mt-4">
						<h3 className="font-weight-bold" >DISTILLATOR <span style={{color:equipment_status!=null ? equipment_status.color:"#000"}}>{equipment_status!=null ? equipment_status.label:""}</span> {" For "}{this.state.record_batch_type.label}</h3>
						<Row>	
							<Colxx lg="3" className="mt-2">
								<AvGroup className="error-t-negative">
									<Label>Micron Level<span className="text-danger">*</span></Label>
									<AvField
										id="micron_level"
										name="micron_level"
										min="0"
										type="number"
										readOnly={input_disabled.micron_level}
										value={this.state.micron_level}
										onChange={e=>{
											const micron_level = e.target.value;
											const heater_temperature = this.state.heater_temperature;
											const input_disabled = this.state.input_disabled;
											
											if(micron_level!="" && heater_temperature!=""){
												input_disabled.start_processing=false;
											}else{
												input_disabled.start_processing=true;
											}
											this.setState({ micron_level, input_disabled });
										}}
										autoComplete="off"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
									/>
								</AvGroup>
								
								<AvGroup className="error-t-negative">
									<Label>Body Temperature (&#8451;)<span className="text-danger">*</span></Label>
									<AvField
										id="heater_temperature"
										name="heater_temperature"
										type="number"
										readOnly={input_disabled.heater_temperature}
										value={this.state.heater_temperature}
										onChange={e=>{
											const heater_temperature = e.target.value;
											const micron_level = this.state.micron_level;
											const input_disabled = this.state.input_disabled;
											
											if(micron_level!="" && heater_temperature!=""){
												input_disabled.start_processing=false;
											}else{
												input_disabled.start_processing=true;
											}
											this.setState({ heater_temperature, input_disabled });
										}}
										autoComplete="off"
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
									/>
								</AvGroup>
								
								<Label>Total Crude Weight Loaded</Label>
								<Row className="align-items-center">
									<Colxx lg="12">
										<Input
											readOnly={true}
											value={total_in_house_weight_gram+" (Grams)"}
										/>
									</Colxx>
								</Row>	 
							</Colxx>
							<Colxx lg="3">
								<AvGroup className="error-t-negative">
									<br/>
										<div className="text-center">
											<img  alt="Evaporator" src={REST_API_BASE_URL+"img/processor/machine2.jpg"} className="img-responsive p-0" width="200" height="250"/>
										</div>
								</AvGroup>
								<AvGroup className="error-t-negative">
									<Label className="font-weight-bold">THC Distillate (Grams)</Label>
									<AvField
										id="crude_oil"
										name="crude_oil"
										min="0"
										type="number"
										autoComplete="off"
										readOnly={input_disabled.crude_oil}
										value={this.state.crude_oil}
										onChange={e=>{ this.setState({ crude_oil:e.target.value }) }}
										validate={{
										  required: {
											value: true,
											errorMessage: "This field is required."
										  }
										}}
									/>
									
									<Input
										id="yield"
										name="yield"
										readOnly={true}
										value={this.state.yield+" % yield"}
									/>
								</AvGroup>		
							</Colxx>
				
							<Colxx lg="3" >
								<AvGroup className="error-t-negative">
									<Label>Short Patch LeftOver</Label>
									<AvField
										id="patch_leftover"
										name="patch_leftover"
										autoComplete="off"
										readOnly={input_disabled.patch_leftover}
										value={this.state.patch_leftover}
										onChange={e=>{ this.setState({ patch_leftover:e.target.value }) }}
									/>
								</AvGroup>								
								
								<AvGroup className="error-t-negative">
									<Label>Tail/Head Fractions (Grams)</Label>
									<AvField
										id="t_h_fractions"
										name="t_h_fractions"
										autoComplete="off"
										readOnly={input_disabled.t_h_fractions}
										value={this.state.t_h_fractions}
										onChange={e=>{ this.setState({ t_h_fractions:e.target.value }) }}
									/>
								</AvGroup>
								
								<AvGroup className="error-t-negative">
									<Label>Reclaim (Grams)</Label>
									<AvField
										id="reclaim"
										name="reclaim"
										autoComplete="off"
										readOnly={input_disabled.reclaim}
										value={this.state.reclaim}
										onChange={e=>{ this.setState({ reclaim:e.target.value }) }}
									/>
								</AvGroup>
							</Colxx>
							<Colxx lg="3" className="process-btns">
								<Button type="button" block color="pink" size="lg" className="mb-3" >Process Run Time<br/>(Hrs:Min:Sec)<br/><span id="process_time">00:00:00</span></Button>
								<Button type="button" block color="success" size="lg" className="mb-3" disabled={input_disabled.start_processing} onClick={this.startProcessing} >Start Processing</Button> 
								<Button type="button" block color="warning" size="lg" className="mb-3" disabled={input_disabled.pause_restart_processing} onClick={this.toggleModalPause} >Pause/Restart Processing</Button> 
								<Button type="button" block color="danger" size="lg" className="mb-3" disabled={input_disabled.end_processing} onClick={this.endProcessing} >End Processing</Button> 
								<Button block color="info" size="lg" className="mb-3" disabled={input_disabled.calculate_yield} onClick={this.calculateYield} >Calculate Yield</Button>
								<Button block color="success" size="lg" className="mb-3" disabled={input_disabled.add_to_inventory} onClick={this.toggleModalAddToInventory} >Add to inventory</Button>
								<Button type="button" block color="success" size="lg" className="mb-3" disabled={input_disabled.print_batch_uid} onClick={this.toggleModalPrintBatchUID} >Print Batch UID</Button> 
								<Button type="button" block color="warning" size="lg" className="mb-3" disabled={input_disabled.load_again} onClick={this.loadAgain} >Load Again</Button>
							</Colxx>
						</Row>		
					</Card>:""}
					
				</AvForm>
				
				<PauseRestartProcessingModal
					modalOpen={this.state.modalOpenPause}
					toggleModal={this.toggleModalPause}
					pauseProcessing={this.pauseProcessing}
					restartProcessing={this.restartProcessing}
					equipment_id={this.state.equipment_id}
				/>
				
				<AddToInventoryModal
					modalOpen={this.state.modalOpenAddToInventory}
					toggleModal={this.toggleModalAddToInventory}
					AddToInventory={this.AddToInventory}
					crude_oil={this.state.crude_oil}
					record_batch_uid={this.state.record_batch_uid}
					equipment_id={this.state.equipment_id}
					products={this.state.products}
					source={5}
				/>
				
				<PrintBatchUIDModal
					modalOpen={this.state.modalOpenPrintBatchUID}
					toggleModal={this.toggleModalPrintBatchUID}
					crude_oil={this.state.crude_oil}
					record_batch_uid={this.state.record_batch_uid}
				/>
				
				<PrintPackageUIDModal
					modalOpen={this.state.modalOpenPrintPackageUID}
					toggleModal={this.toggleModalPrintPackageUID}
					package_details={this.state.package_details}
				/>
			</Fragment>
		);
	}
}
