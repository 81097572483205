import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import equipment_types from "../../data/equipment_types";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddEquipmentModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {	
			selectEquipmentType:{ label: "Extractor", value:"1", key: 0 },
			selectEquipmentDependencies:[],
		};	
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-equipments").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("equipmentForm"));
			formData.append('facility_id',facility_id);
			
			axios.post(REST_API_END_POINT+'processor/is-equipment-name-exist',formData).then((res)=>{
				var data = res.data;
				
				if(!data.status){
					var formData = new FormData(document.getElementById("equipmentForm"));
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);
					if(this.state.selectEquipmentDependencies.length>0){
						formData.append('equipment_dependences',this.state.selectEquipmentDependencies.map(r=>r.value).join(','));						
					}else{
						formData.append('equipment_dependences','');
					}

					axios.post(REST_API_END_POINT+'processor/add-equipment',formData).then((res)=>{
						var data = res.data;
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("add-equipments").disabled=false;
						this.props.toggleModal();
						this.setState({ 
							selectEquipmentType:{ label: "Extractor", value:"1", key: 0 }
						});
						this.props.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("add-equipments").disabled=false;
					});
				}else{
					swal("Equipment name already exist! Please use another name");
					document.getElementById("add-equipments").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-equipments").disabled=false;
			});
		}
	}  
 
	onChange = e => {
		switch (e.target.name) {
		  case 'thumbnail':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
  
	render() {	
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
			  <ModalHeader toggle={this.props.toggleModal}>
				Add Equipment
			  </ModalHeader>
			  <AvForm
					id="equipmentForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Equipment UID<span className="text-danger">*</span></Label>
							  <AvField
								id="equipment_name"
								name="equipment_name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Equipment Type</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="equipment_type"
									id="equipment_type"
									value={this.state.selectEquipmentType}						
									options={equipment_types}
									onChange={selectEquipmentType => { this.setState({ selectEquipmentType }) }}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Equipment Dependencies</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="equipment_dependencies"
									id="equipment_dependencies"
									value={this.state.selectEquipmentDependencies}
									isMulti
									options={this.props.equipment_dependencies}
									onChange={selectEquipmentDependencies => { this.setState({ selectEquipmentDependencies }) }}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row className="mt-4">
						<Colxx lg="12" className="mb-2">						
							<AvGroup className="error-l-125 error-t-negative">
								<Label>Thumbnail (Recommended size 320x320)</Label>
								<InputGroup className="mb-3">
									<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
									<CustomInput
										type="file"
										id=""
										name="thumbnail"
										accept="image/*"
										onChange={this.onChange}
									/>
								</InputGroup>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="add-equipments" >
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
		  </Modal>
		);
  }
};

export default AddEquipmentModal;
