import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import { REST_API_END_POINT,themeColor,dataEncryptKey } from "../../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import SimpleCrypto from "simple-crypto-js";

import AddTestimonialModal from "../../../containers/website/AddTestimonialModal";
import EditTestimonialModal from "../../../containers/website/EditTestimonialModal";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class TestimonialManagement extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			modalOpenAdd: false,
			modalOpenEdit: false,
			search: '',		
			selectAll: 0,		
			selectedIds: [],
			testimonials: []
		};
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		this.deleteTestimonial = this.deleteTestimonial.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/testimonials',formData).then((res)=>{ 
			this.setState({ testimonials: res.data.data, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,testimonial_id=0) => {
		this.setState({
			testimonial_id: parseInt(testimonial_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
		
    toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.testimonials.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });		
    }
	 
	deleteTestimonial = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one testimonial","warning");
			return;
		}
		var msg_word = 'testimonial';
		if(selectedIds.length>1){
			msg_word = 'testimonials';
		}
		
		var msg="";
        var success_msg="";
        var failure_msg="";
		
		if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-testimonials").disabled=true;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('testimonial_ids',JSON.stringify(selectedIds));
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('status',status);
				
				axios.post(REST_API_END_POINT+'website/delete-testimonials',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("delete-testimonials").disabled=false;					
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
					this.props.dataListRender();
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("delete-testimonials").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,testimonial_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(testimonial_id);
			
		}else{
		  var x=selectedIds.indexOf(testimonial_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.testimonials.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
		
	render() {
		let data = this.state.testimonials
		if (this.state.search) {
			data = data.filter(row => {
				if(row.excerpt==null){
					row.excerpt='';
				}
				return row.content.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
							<CustomInput type="checkbox" id={"testimonial_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "#",
				Cell: props => {
					return data.findIndex(r=>{ return r.id==props.original.id })+1
				}
			},
			{
				Header: "Testimonial",
				accessor: "content",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
			},
			{
				Header: "Actions",
				accessor: "id",
				sortable: false,
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >								
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalEdit(e,props.value) }}  >
									<span className="d-inline-block">Edit</span>
								</DropdownItem>
								{props.original.status==1 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.deleteTestimonial(e,[props.value],0) }}  >
									<span className="d-inline-block">Deactivate</span>
								</DropdownItem>:<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.deleteTestimonial(e,[props.value],1) }}  >
									<span className="d-inline-block">Activate</span>
								</DropdownItem>}
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.deleteTestimonial(e,[props.value],2) }}  >
									<span className="d-inline-block">Delete</span>
								</DropdownItem>
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.testimonials" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>   
						
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row>	
										<Colxx xxs="12">
											<div id="api_message"></div>
										</Colxx> 
										<Colxx xxs="12">
											<div className="float-sm-right">
												{" "}<Button																		 
													  color="primary"
													  size="sm"
													  className=""
													  onClick={this.toggleModalAdd}
													>
													  <IntlMessages id="pages.add-new" />							  
												</Button>
																							
												   {" "}<Button
													id="delete-testimonials"
													color="primary"
													size="sm"
													className=""
													onClick={event =>this.deleteTestimonial(event,this.state.selectedIds,2)}								
												  >
													<IntlMessages id="pages.delete" />
											  </Button>
											</div>
											<div className="mb-4 dropdown-edit">						
												<FormGroup row>
													<Colxx lg="6">
														<Input
														  type="text"
														  name="table_search"
														  id="table_search"
														  placeholder="Search"
														  value={this.state.search}
														  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
														/>
													</Colxx>
												</FormGroup>					
												<ReactTable
													data={data}
													columns={dataTableColumns}
													defaultPageSize={10}
													filterable={false}
													showPageJump={false}
													PaginationComponent={DataTablePagination}
													showPageSizeOptions={true}
													defaultSorted={[{id: "x",asc: true}]}
												/>
											</div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
					<AddTestimonialModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender={this.dataListRender}
					/>
					<EditTestimonialModal
						modalOpen={this.state.modalOpenEdit}
						toggleModal={this.toggleModalEdit}
						dataListRender={this.dataListRender}
						testimonial_id={this.state.testimonial_id}
					/>	
				</BlockUi>
			</Fragment>
		);
	}
}
