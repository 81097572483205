import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';
import { BASE_URL } from "../../constants/defaultValues";
import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import number_format from "number_format-php";
var default_logo;
export default class InvoiceModalContent extends Component {


	render() {
		const { order_data, order_products, facility_data, currencies, additionalRequirementsData } = this.props;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax = 0;
		var sgst_tax = 0;
		var cgst_tax = 0;
		var sub_total = 0;
		var p_discount = 0;
		let is_utgst = false;
		let is_igst = true;
		if (order_data != null) {
			var current_currency = currencies.find(row => { return row.currency_code == order_data.currency_code });
			if (current_currency != null) {
				//current_currency_symbol = current_currency.currency_symbol;
			}

			/*is_igst = order_data.customer_state_id !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}*/
		}

		if (order_products != null) {
			order_products.map((item, index) => {
				if (item.filter) {
					item.filter.map(row => {
						sales_tax += parseFloat(row.product_sales_tax);
						sgst_tax += parseFloat(row.product_sgst_tax);
						cgst_tax += parseFloat(row.product_cgst_tax);
						p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

						let line_total = parseFloat(row.product_unit_price) * Math.ceil(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

						if (row.variation_id == order_data.discount_variation_id) {
							line_total -= parseFloat(order_data.manual_discount);
						}
						sub_total += parseFloat(row.product_unit_price) * Math.ceil(row.product_qty);
					});
				}
			});

		}
		let additionalTotal = 0;
		return (
			<Row className="invoice-react">
				<Colxx xxs="12" className="">
					{facility_data != null ? (facility_data.facility_logo != null && facility_data.facility_mobile_logo != null) ?
						default_logo = <div className="mb-0 d-flex  justify-content-center " >
							<a href="/">
								<img src={BASE_URL + 'pages/' + facility_data.facility_logo} className="mb-0" style={{ width: 200 }} />
							</a>
						</div>
						: "" : ""}

					<div className="mb-5 invoice-contents">
						<CardBody className="d-flex flex-column justify-content-between">
							<div className="d-flex flex-column">
								<div className="d-flex flex-row justify-content-between pt-2 pb-2">
									<div className="">
										<div className="font-weight-bold h5">{facility_data != null ? facility_data.facility_name : ""}</div>
										{facility_data != null ? (facility_data.facility_address != null ? <p className="mb-0">{facility_data.facility_address}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_phone != null ? <p className="mb-0">{facility_data.facility_phone}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_email != null ? <p className="mb-0">{facility_data.facility_email}</p> : "") : ""}
										{/* {facility_data!=null ? (facility_data.gstin!=null ? <p className="mb-0">TRN: {facility_data.gstin}</p>:""):""} */}
									</div>
									{order_data != null ? <div className="w-40 justify-content-end text-right ">
										<div className="font-weight-bold text-uppercase h4">Invoice</div>
										<p className="mb-0">
											INVOICE # {moment(order_data.timestamp * 1000).format('YYYY')}/{zeroPad(order_data.order_number, 6)}<br />
											DATE: {moment(order_data.timestamp * 1000).format("MMMM DD, YYYY")}
											{/*order_data.payment_mode!=0 && order_data.payment_mode!="" ? <p className="mb-0">PAYMENT MODE: {order_data.payment_mode}</p>:""*/}
											{order_data.status == 4 ? <div className="mb-1 text-danger">Order Status: Canceled</div> : ""}
										</p>
									</div> : ""}
								</div>
								<div className="border-bottom pt-4 mb-5" />

								{order_data != null ? <div className="d-flex flex-row justify-content-between mb-5 ">

									<div className=" flex-column w-100 mr-2  bg-semi-muted p-4">
										<div className="font-weight-bold h5 mt-2 ">Client Info:</div>
										<p className="mb-0 ml-5">
											Customer Name:{order_data.customer_name}<br />
											{order_data.project != null ? <Fragment>Project:{order_data.project}<br /></Fragment> : ""}
											{order_data.billing_address != null ? <Fragment>Place:{order_data.billing_address}<br /></Fragment> : ""}
											{/* {order_data.customer_phone!=null ? <Fragment>Tel: {order_data.customer_phone}</Fragment>:""} */}
										</p>
									</div>
									{/* <div className="w-50 flex-column bg-semi-muted">
										<div className="font-weight-bold h5">SHIPPED TO:</div>
										<p className="mb-0">
											{order_data.customer_name}<br/>
											{order_data.shipping_address!=null ? <Fragment>{order_data.shipping_address}<br/></Fragment>:""}
											{order_data.customer_phone!=null ? <Fragment>Tel: {order_data.customer_phone}</Fragment>:""}
										</p>
									</div> */}
								</div> : ""}

								{order_data != null ? <Table borderless>
									<thead>
										<tr>
											<th className="mb-2">#</th>
											<th className="mb-2 text-left">Item</th>
											<th className="mb-2 text-left">Size</th>
											<th className="mb-2 text-left">Unit Price</th>
											<th className="mb-2 text-left">SQFT/Quantity</th>
											{/* <th className="mb-2 text-right">Price</th>
												
												{is_igst ? <th className="mb-2 text-right">TAX</th>:<Fragment>
													<th className="mb-2 text-right">{is_utgst ? "UTGST":"SGST"}</th>
													<th className="mb-2 text-right">CGST</th>
												</Fragment>} */}
											{/*<th className="mb-2 text-right">Discount</th>*/}
											<th className="mb-2 text-left">Total</th>
										</tr>
									</thead>
									<tbody>
										{order_products.map((row, index) => {
											// sales_tax+=parseFloat(row.product_sales_tax);
											// sgst_tax+=parseFloat(row.product_sgst_tax);
											// cgst_tax+=parseFloat(row.product_cgst_tax);
											// p_discount+=parseFloat(row.product_discount)+parseFloat(row.coupon_discount);

											// let line_total=parseFloat(row.product_unit_price)*Math.ceil(row.product_qty)+(parseFloat(row.product_excise_tax)+parseFloat(row.product_sales_tax)-parseFloat(row.product_discount)-parseFloat(row.coupon_discount))

											// if(row.variation_id==order_data.discount_variation_id){
											// 	line_total-=parseFloat(order_data.manual_discount);
											// }
											// sub_total+=parseFloat(row.product_unit_price)*Math.ceil(row.product_qty);
											return (<>
												<tr style={{ background: "#46bdc6" }}>
													<td>{index + 1}</td>
													<td>{row.category_name} </td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
												{row.filter.map((raw) => (
													<tr>
														<td style={{ border: 'none' }}></td>
														<td>{raw.product_name} {raw.variation_name != null ? "(" + raw.variation_name + ")" : ""}</td>
														<td>{raw.sqft == 1 ? <div>{parseInt(raw.length)}*{parseInt(raw.breadth)}*{parseInt(raw.height)}</div> : ""}</td>
														<td className=" text-left">{current_currency_symbol}{"" + number_format(parseFloat(raw.product_unit_price), 2, '.', '')}</td>
														<td className=" text-center">{Math.ceil(raw.product_qty)}</td>
														{/* <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat((parseFloat(row.product_unit_price)*Math.ceil(row.product_qty)).toFixed(2)),2,'.','')}</td> 
												 {is_igst ? <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sales_tax),2,'.','')}</td>:<Fragment>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sgst_tax),2,'.','')}</td>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_cgst_tax),2,'.','')}</td>
												</Fragment>}  */}
														{/*<td className="text-right">{current_currency_symbol}{""}{row.variation_id!=order_data.discount_variation_id ? number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount),2,'.',''):number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount)+parseFloat(order_data.manual_discount),2,'.','')}</td>*/}
														<td className=" text-left">{current_currency_symbol}{"" + number_format(parseFloat(raw.product_unit_price) * Math.ceil(raw.product_qty) + (parseFloat(raw.product_excise_tax) + parseFloat(raw.product_sales_tax) - parseFloat(raw.product_discount) - parseFloat(raw.coupon_discount)), 2, '.', '')}</td>
													</tr>
												))}
											</>)
										})}
									</tbody>
								</Table> : ""}
								{order_data != null ? <div className="d-flex flex-column">
									<div className="border-bottom pt-3 mb-2" />
									<Table borderless className="d-flex justify-content-end">
										<tbody>
											{/* <tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(additionalTotal, 2, '.', '')}</td>
										</tr> */}

											{/* {is_igst ? <tr>
											<td className="pb-0">Tax ({order_data.tax_percentage}%) :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
										</tr> : <Fragment>
											<tr>
												<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
											</tr>
											<tr>
												<td className="pb-0">CGST :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
											</tr>
										</Fragment>} */}

											<tr className="font-weight-bold">
												<td className="pb-0">Total :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(p_discount), 2, '.', '')}</td>
											</tr>
										</tbody>
									</Table>
								</div> : ""}
								{additionalRequirementsData.length > 0 ?
									<>
										<h4>Additional Requirements</h4>
										<Table borderless>
											<thead>
												<tr>
													<th className="mb-2">#</th>
													<th className="mb-2 text-left">Item</th>
													<th className="mb-2 text-left">Quantity</th>
													<th className="mb-2 text-left">Price</th>
													<th className="mb-2 text-left">Amount</th>

												</tr>
											</thead>
											<tbody>
												{additionalRequirementsData.map((row, index) => {
													additionalTotal += parseFloat(row.amount);
													return (<>
														<tr >
															<td>{index + 1}</td>
															<td>{row.name} </td>
															<td>{row.qty}</td>
															<td>{row.price}</td>
															<td>{row.amount}</td>

														</tr>


													</>)
												})}
											</tbody>
										</Table>
										<div className="d-flex flex-column">
											<div className="border-bottom pt-3 mb-2" />
											<Table borderless className="d-flex justify-content-end">
												<tbody>
													{/* <tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(additionalTotal, 2, '.', '')}</td>
										</tr> */}

													{/* {is_igst ? <tr>
											<td className="pb-0">Tax ({order_data.tax_percentage}%) :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
										</tr> : <Fragment>
											<tr>
												<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
											</tr>
											<tr>
												<td className="pb-0">CGST :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
											</tr>
										</Fragment>} */}

													<tr className="font-weight-bold">
														<td className="pb-0">Total :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
													</tr>

												</tbody>
											</Table>
										</div>
									</> : ""}
							</div>
							{order_data != null ? <div className="d-flex flex-column">
								<div className="border-bottom pt-3 mb-2" />
								<div className="d-flex justify-content-between">
									<Table borderless className="d-flex justify-content-start">
										<tbody>
											<tr>
												<td className="pl-0 h5" colspan="2" >Bank Details</td>
											</tr>
											<tr>
												<td className="pb-0 pt-0 pl-0">Bank Name:</td>
												<td className="text-right pb-0 pt-0 pl-0">{facility_data != null ? (facility_data.bank_name != null ? <p className="mb-0">{facility_data.bank_name}</p> : "") : ""}</td>
											</tr>
											<tr>
												<td className="pb-0 pt-0 pl-0">A/c No:</td>
												<td className="text-right pb-0 pt-0 pl-0">{facility_data != null ? (facility_data.bank_account_no != null ? <p className="mb-0">{facility_data.bank_account_no}</p> : "") : ""}</td>
											</tr>
											<tr >
												<td className="pb-0 pt-0 pl-0">Branch & Swift Code :</td>
												<td className="text-right pb-0 pt-0 pl-0">{facility_data != null ? (facility_data.branch_ifsc != null ? <p className="mb-0">{facility_data.branch_ifsc}</p> : "") : ""}</td>
											</tr>
										</tbody>
									</Table>
									<Table borderless className="d-flex justify-content-end">
										<tbody>
											{/* <tr>
												<td className="pb-0">Subtotal :</td>
												<td className="text-right pb-0">{current_currency_symbol}{""+number_format(sub_total,2,'.','')}</td>
											</tr> */}

											{/* {is_igst ? <tr>
													<td className="pb-0">TAX({order_data.tax_percentage}%):</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(sales_tax,2,'.','')}</td>
												</tr>:<Fragment>
													<tr>
														<td className="pb-0">{is_utgst ? "UTGST":"SGST"} :</td>
														<td className="text-right pb-0">{current_currency_symbol}{""+number_format(sgst_tax,2,'.','')}</td>
													</tr>
													<tr>
														<td className="pb-0">CGST :</td>
														<td className="text-right pb-0">{current_currency_symbol}{""+number_format(cgst_tax,2,'.','')}</td>
													</tr>
												</Fragment>} */}
											{/*<tr>
												<td className="pb-0">Discount :</td>
												<td className="text-right pb-0">{current_currency_symbol}{""+number_format(p_discount+parseFloat(order_data.auto_apply_discount)+parseFloat(order_data.coupon_discount)+parseFloat(order_data.manual_discount_value)+parseFloat(order_data.point_discount),2,'.','')}</td>
											</tr>*/}
											{/* {parseFloat(order_data.return_amount) != 0 ? <tr className="font-weight-bold">
												<td className="pb-0">Refund :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.return_amount), 2, '.', '')}</td>
											</tr> : ""}
											{parseFloat(order_data.total_due) != 0 ? <tr className="font-weight-bold">
												<td className="pb-0">Due Amount :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total_due), 2, '.', '')}</td>
											</tr> : ""}
											<tr className="font-weight-bold">
												<td className="pb-0">Total :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(p_discount), 2, '.', '')}</td>
											</tr> */
											}
											<tr className="font-weight-bold">
												<td className="pb-0">Grand Total :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) + parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
											</tr>
											<tr className="font-weight-bold">
												<td className="pb-0">Total Paid :</td>
												{order_data.total_due != 0  ? <td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(order_data.total_due) + parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td> : (order_data.payment_status)==1?<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(order_data.total_due) + parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>:<td class="text-right pb-0">0</td>}
											</tr>
											{order_data.payment_status == 2 || order_data.payment_status == 1 ? <tr className="font-weight-bold">
												
												<td className="pb-0">Due Amount :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total_due), 2, '.', '')}</td>
											</tr> : <tr className="font-weight-bold">
												<td className="pb-0">Due Amount :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total + additionalTotal) + parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
											</tr>}
										</tbody>

									</Table>

								</div>

								{order_data.payment_status == 1 ? <div className="d-flex  paidstamp">
									{/* <img src="/assets/img/paid.png" alt="paid" height={150} width={150} /> */}
									{/* <h1>PAID</h1> */}
								</div> : ""}

								<div className="border-bottom pt-2 mb-3" />
								<div className=" ">
									<div class="font-weight-bold h5 ">
										Terms and Conditions
									</div >
									{
										order_data['notes'] ? order_data['notes'] :
											<div>
												<p>1. Payment - 50% advance + 40% during project + 10% during hand over</p>
												<p>2. Union and other site related issues should take care by client itself</p>
												<p>3. Electricity and adequate workspace should provide by client side</p>
												<p>4. Quoted amount valid for 30 days only. </p>
												<p>5.Extra works than in quote will be charged extra.</p>

												<br></br>
												We trust our quotation proves of you.
												Thanks for showing interest in Textra interiors andexteriors. For more enquiries call on 9947 531 970
											</div>
									}
								</div>

								{/* {typeof this.props.removeNotice=='undefined' ? <p className="text-muted text-center mb-0">
									Payment in full due in 14 days<br/>
									If you have any questions concerning this invoice, contact {facility_data!=null ? (facility_data.facility_email!=null ? "<"+facility_data.facility_email+">":""):""}
								</p>:""} */}
							</div> : ""}
						</CardBody>
					</div>
				</Colxx>
			</Row>
		);
	}
};
