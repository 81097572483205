import React, { Component, Fragment } from "react";
import {
  Row,FormGroup, Input,
  CustomInput,
  Button,
  Modal,
  CardBody,CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DataTablePagination from "../../components/DatatablePagination";
import classnames from "classnames";

import ProductGrid from "../../components/pos/ProductGrid";

import { NavLink } from "react-router-dom";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT, themeColor} from "../../constants/defaultValues";

import ReactTable from "react-table";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ShowProductsModal extends Component{
	constructor(props) {
		super(props);
		
        this.state = {
			search:"",
			blocking:false,
		};		
	}

	render() {
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Choose Product
				</ModalHeader>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
					<ModalBody>					
						<Row>
							<Colxx lg="12">
								<div className="pos-product-wrapper pos-show-product-wrapper" >
									<ProductGrid 
										products={this.props.products}
										currency_rate={this.props.currency_rate}
										currency_symbol={this.props.currency_symbol}
										toggleModalGetPackages={this.props.toggleModalGetPackages}
										addToCart={this.props.addToCart}
									/>
								</div>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</BlockUi>
			</Modal>
		);
	}	
};