import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import officialrequests from "./officialrequests";
import payment from "./payment";
import assignment from "./assignment";
import rfprequest from "./rfprequest";
const Officialrequests = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
		
		
			<Route path={`${match.url}/official-requests`} component={officialrequests} />
			<Route path={`${match.url}/payment`} component={payment} />
			<Route path={`${match.url}/assignment`} component={assignment} />
			<Route path={`${match.url}/rfprequest`} component={rfprequest} />
			
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Officialrequests  ;