import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";

import ViewWasteModal from "../../../containers/inventory/ViewWasteModal";
import ApproveWasteModal from "../../../containers/inventory/waste-management/ApproveWasteModal";
import RejectWasteModal from "../../../containers/inventory/waste-management/RejectWasteModal"; 
import WasteViewModal from "../../../containers/inventory/waste-management/WasteViewModal"; 

import swal from 'sweetalert';
import moment from 'moment';

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class WasteManagement extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			modalOpenApprove: false,
			modalOpenReject: false,
			modalOpenView: false,
			data:[],
			search: '',
			waste_data:null,
		};
		
		this.dataListRender = this.dataListRender.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory/waste-management',formData).then((res)=>{
			var data = res.data;
		
			if(data.wastes){
				this.setState({
					data: data.wastes
				});
			}else{
				this.setState({
					data: []
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleModalApprove = (e,waste_data=null) => {
		this.setState({
			waste_data,
			modalOpenApprove: !this.state.modalOpenApprove
		})
	};
	
	toggleModalReject = (e,waste_data=null) => {
		this.setState({
			waste_data,
			modalOpenReject: !this.state.modalOpenReject
		})
	};
	
	toggleModalView = (e,waste_data=null) => {
		this.setState({
			waste_data,
			modalOpenView: !this.state.modalOpenView
		})
	};
	     
  
	render() {
		
		let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				if(row.added_by==null){
					row.added_by="";
				}
				return row.barcode.includes(this.state.search) || row.product_name.toLowerCase().includes(this.state.search) || row.waste_reason.toLowerCase().includes(this.state.search) || row.added_by.toLowerCase().includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
		  {
			Header: "Barcode",
			accessor: "barcode",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Product Name",
			accessor: "product_name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Quantity",
			accessor: "quantity",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Reason for Waste",
			accessor: "waste_reason",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Added By",
			accessor: "added_by",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Added At",
			accessor: "timestamp",
			Cell: props => <p className="list-item-heading">{moment(props.value*1000).format("MM/DD/YYYY HH:mm")}</p>
		  },
		  {
			Header: "Status",
			accessor: "status",
			Cell: props => <Badge color={props.value==="1" ? "warning":(props.value==="2" ? "primary":"danger")} pill>{props.value==="1" ? "Pending":(props.value==="2" ? "Approved":"Rejected")}</Badge>
		  },	  	  
		  {
			Header: "Actions",
			accessor: "",
			Cell: props => <Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
						<DropdownItem onClick={(e)=>{ this.toggleModalView(e,props.original) }}>
							<span className="d-inline-block cursor-pointer">View</span>
						</DropdownItem>			
						{props.original.status==="1" ? <DropdownItem onClick={(e)=>{ this.toggleModalApprove(e,props.original) }}>
							<span className="d-inline-block cursor-pointer">Approve</span>
						</DropdownItem>:""}
						{props.original.status==="1" ? <DropdownItem onClick={(e)=>{ this.toggleModalReject(e,props.original) }}>		 
							<span className="d-inline-block cursor-pointer">Reject</span>				
						</DropdownItem>:""}
				  </DropdownMenu>} /></div></Fragment>
		  }
		];
		
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.waste-management" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
				
				<Card className="p-4">
				   <Row>			  
						<Colxx xxs="12">					
							<div className="mb-4 dropdown-edit">						
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
									</Colxx>
								</FormGroup>			
								
								<ReactTable
								  data={data}
								  columns={dataTableColumns}
								  defaultPageSize={10}
								  minRows="5"
								  filterable={false}
								  showPageJump={false}
								  PaginationComponent={DataTablePagination}
								  showPageSizeOptions={true}
								  defaultSorted={[{id: "send_on",desc: false}]}
								/>
							</div>
								
							<RejectWasteModal
								modalOpen={this.state.modalOpenReject}
								toggleModal={this.toggleModalReject}
								waste_data = {this.state.waste_data}
								dataListRender={this.dataListRender}
							/>	
							
							<ApproveWasteModal
								modalOpen={this.state.modalOpenApprove}
								toggleModal={this.toggleModalApprove}
								waste_data = {this.state.waste_data}
								dataListRender={this.dataListRender}
							/>

							<WasteViewModal						  
								modalOpen={this.state.modalOpenView}
								toggleModal={this.toggleModalView}
								waste_data = {this.state.waste_data}
							/>	 
						</Colxx>
					</Row>
				</Card>
			</Fragment>
		);
	}
}
