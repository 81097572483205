import React, { Component, Fragment } from "react";
import { Row, FormGroup,Input,
    DropdownItem,
    DropdownMenu,CustomInput,Button } from "reactstrap";

import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import moment from "moment"; 

export default class Memberships extends Component {
	constructor(props) {
		super(props);
	}
	  
  render() {
	let data = this.props.memberships;
	  		
	const dataTableColumns = [
		{
			Header: "Name",
			accessor: "subscription_name",
			Cell: props => {
						var cancel_str = "";
						if(typeof props.original.cancel_reason!=='undefined'){
							cancel_str = <span className="text-danger d-block mt-2">{props.original.cancel_reason}</span>
						}
						return <p className="list-item-heading">{props.value}{cancel_str}</p>
					}
		},
		{
			Header: "Amount",
			accessor: "amount",
			Cell: props => "$"+props.value
		},
		{
			Header: "Activated on",
			accessor: "start_date",
			Cell: props => moment(props.value).format("MM/DD/YYYY")
		},
		{
			Header: "Expire on",
			accessor: "end_date",
			Cell: props => moment(props.value).format("MM/DD/YYYY")
		},
		{
			Header: "Last Payment",
			accessor: "last_payment_date",
			Cell: props => moment(props.value).format("MM/DD/YYYY")
		},
		{
			Header: "Next Payment",
			accessor: "next_payment_date",
			Cell: props => { if(typeof props.original.cancel_reason==='undefined' || props.original.cancel_reason===null){ return moment(props.value).format("MM/DD/YYYY"); }else{ return ""; } }
		},
		{
			Header: "Recurring",
			accessor: "recurring",
			Cell: props => props.value==1 ? "Yes":"No"
		}
	];
	
	
    return (
      <Fragment>
		<Row>			  
			<Colxx xxs="12">
				<div className="mb-4 dropdown-edit">					
					<ReactTable
						data={data}
						columns={dataTableColumns}
						defaultPageSize={10}
						filterable={false}
						showPageJump={false}
						PaginationComponent={DataTablePagination}
						showPageSizeOptions={true}
						defaultSorted={[{id: "id",desc: true}]}
					/>
				</div>
			</Colxx>
		</Row>
      </Fragment>
    );
  }
}
