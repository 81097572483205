import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import Select from "react-select";
import swal from 'sweetalert';
import CustomSelectInput from "../../components/common/CustomSelectInput";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddMemberModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
		  members:[]
		};
	}
	
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			if(this.state.members.length==0){
				swal("Warning!", "Please select a member!", "warning");				
				return;
			}
			
			document.getElementById("add-member").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("memberForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
            formData.append('task_id',this.props.task_id);
			
			axios.post(REST_API_END_POINT+'task/add-task-member',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-member").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-member").disabled=false;
			});

		}
	}
	
  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Member
		  </ModalHeader>
		  <AvForm
		         id="memberForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
					<AvGroup className="error-t-negative">
					  <Label>Select Member</Label>
					  <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"						
						name="target_user_id"
						id="userid"
						placeholder="--Select Members--"
						options={this.props.members}
						onChange={members => { this.setState({ members }) }}
					/>
					</AvGroup>
				</Colxx>					
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" id="add-member" >
				  <IntlMessages id="customer.add" />
				</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
				  <IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
