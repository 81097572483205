import React, { Component,Fragment } from "react";
import { Row, FormGroup, Input , Button} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import AddProductLabelModal from "../../containers/inventory/AddProductLabelModal";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ProductGrid extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search:'',
			modalOpenAdd: false,
			orderId:""
		}
		
		
		this.addToCart = this.addToCart.bind(this);
	}
	
	addToCart(product){
		var values={};
		
		values.product_id=product.id;
		values.product_price=product.product_price;
		values.product_unit_price=product.product_unit_price;		
		values.exise_tax=product.exise_tax;
		values.sales_tax=product.sales_tax;
		values.quantity=1;
		values.variation_id=product.variation_id;
		values.sqft=product.sqft;
		values.package_id=0;
		values.is_finished=0;
		
		this.props.addToCart(values);		
	}
	toggleModalAdd = () => {
	
		const order_id=this.props.orderId
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd,
		  orderId:this.props.orderId,
		});
		
	};
	dataListRender=()=>{
		
		
		console.log('DEEE');
		
	}	 
	render() {
		const{ products, currency_rate, currency_symbol, index } = this.props;
		
		var final_products = products;		
		if (this.state.search) {
			final_products = final_products.filter(row => {
				return  row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.barcode.includes(this.state.search)
			})
		}
		
		return (<Fragment>
			
				<div className="mb-2 p-2 product-item-search dropdown-edit">						
					<FormGroup row className="mb-0">
						<Colxx lg="8">
							<Input
								type="text"
								name={"product_search"+index}
								id={"product_search"+index}
								placeholder="Search"
								value={this.state.search}
								onChange={e => this.setState({search: e.target.value.toLowerCase()})}
							/>
						</Colxx>
						<Colxx lg="2">
						<Button color="primary" onClick={this.toggleModalAdd} ><b><i className="simple-icon-plus" /></b></Button>
						</Colxx>
					
					</FormGroup>
					
				</div>
				<PerfectScrollbar
				  option={{ suppressScrollX: true, wheelPropagation: false }}
				>
					<div className="d-flex flex-wrap">
					{final_products.map((row,index)=>{
						return(<Colxx md="3" xxl="2" className="text-center product-item" key={index} onClick={(e)=>{ this.addToCart(row); }}>
							{/* <img src={row.thumbnail_url} className="product-thumnail img-responsive" />	 */}
							<p className="mt-1 mb-1 product-item-name">{row.name.toUpperCase()}</p>
							{/*<p className="mb-0 text-danger font-weight-bold">{currency_symbol}{" "+(parseFloat(row.product_unit_price*currency_rate).toFixed(2))}</p>*/}
						</Colxx>)
					})}					
					</div>
				</PerfectScrollbar>
				<AddProductLabelModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.props.dataListRender}
					orderId={this.state.orderId}
					
				/>
			</Fragment>);
			
	}
}
