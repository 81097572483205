import React from "react";
import { Card, CustomInput, Badge,Button, DropdownItem, DropdownMenu, } from "reactstrap";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import { ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "../../../components/common/CustomBootstrap";
import ItemDropdown from "../../../components/customer/ItemDropdown";

const ThumbListView = ({ user, isSelect, collect, onCheckItem }) => {
  return (
    <Colxx xxs="12" key={user.id} className="mb-3">
        <Card
          onClick={event => onCheckItem(event, user.id)}
          className={classnames("d-flex flex-row", {
            active: isSelect
          })}
        >
          <NavLink to={`?p=${user.id}`} className="d-flex">
            <img
              alt={user.first_name+" "+user.last_name}
              src={user.img}
              className="list-thumbnail responsive border-0"
            />
          </NavLink>
          <div className="pl-2 d-flex flex-grow-1 min-width-zero">
            <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center p-2">
              <NavLink to={`?p=${user.id}`} className="w-12 w-sm-100 d-none d-lg-inline">
                <p className="list-item-heading mb-1 truncate">
                  {user.first_name}
                </p>
              </NavLink>
			  <NavLink to={`?p=${user.id}`} className="w-12 w-sm-100 d-none d-lg-inline">
                <p className="list-item-heading mb-1 truncate">
                  {user.last_name}
                </p>
              </NavLink>
               <NavLink to={`?p=${user.id}`} className="w-12 w-sm-100 d-none d-sm-inline d-md-none">
                <p className="list-item-heading mb-1">
                  {user.first_name}{" "}{user.last_name}
                </p>
              </NavLink>
			  
              <p className="mb-1 text-muted text-small w-12 w-sm-100">
                <span className="d-none d-sm-inline d-md-none ">Agency: </span>{user.agency_name}
              </p>
              <p className="mb-1 text-muted text-small w-12 w-sm-100">
                <span className="d-none d-sm-inline d-md-none ">Phone: </span>{user.phone}
              </p>
              <p className="mb-1 text-muted text-small w-12 w-sm-100">
                <span className="d-none d-sm-inline d-md-none ">Email: </span>{user.email}
              </p>
			  <p className="mb-1 text-muted text-small text-lg-center w-9 w-sm-100">
                <span className="d-none d-sm-inline d-md-none ">Client's agent: </span>{user.clients_agent}
              </p>
			  <p className="mb-1 text-muted text-small text-lg-center w-9 w-sm-100">
                <span className="d-none d-sm-inline d-md-none ">Seller's agent: </span>{user.sellers_agent}
              </p>
              <div className="mb-1 w-9 w-sm-100">
                <Badge color={user.statusColor} pill>
                  {user.status===1?"Active":"Inactive"}
                </Badge>
              </div>
              <div className="action-btns w-9 w-sm-100">
				  <ItemDropdown item={<DropdownMenu >
					<DropdownItem>
						<NavLink to="#" >
							<i className="simple-icon-pencil" />{" "}<span className="d-inline-block">Edit</span>
						</NavLink>						
					</DropdownItem>
					<DropdownItem>
						<NavLink to="#" >
							<i className="simple-icon-ban" />{" "}<span className="d-inline-block">Deactivate</span>
						</NavLink>					
					</DropdownItem>
					<DropdownItem>
					  <NavLink to="#" >
						<i className="simple-icon-trash" />{" "}<span className="d-inline-block">Delete</span>
					 </NavLink>
					</DropdownItem>
				  </DropdownMenu>} />
              </div>
            </div>
            <div className="custom-control custom-checkbox pl-1 align-self-center pr-4">
              <CustomInput
                className="item-check mb-0"
                type="checkbox"
                id={`check_${user.id}`}
                checked={isSelect}
                onChange={()=>{}}
                label=""
              />
            </div>
          </div>
        </Card>
    </Colxx>
  );
};

/* React.memo detail : https://reactjs.org/docs/react-api.html#reactpurecomponent  */
export default React.memo(ThumbListView);
