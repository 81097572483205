import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge, Collapse,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput, Label, InputGroup, InputGroupAddon } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import axios from 'axios';
import { uuid } from 'uuidv4';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor, BASE_URL, SITE_URL} from "../../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import SimpleCrypto from "simple-crypto-js";
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Settings extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			selectedIds: [],
			pages: [],
			menus: [],
			accordion: [true, false],
			blocking:true,
			refresh:false,
			url_link:'http://',
			url_name:''
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.addToMenu = this.addToMenu.bind(this);
		this.renderItem = this.renderItem.bind(this);
		this.onChangeNestable = this.onChangeNestable.bind(this);
		this.updateConfiguration = this.updateConfiguration.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/get-menus',formData).then((res)=>{ 
			this.setState({ pages: res.data.pages, menus: res.data.menus, refresh:true, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}	
	
	toggleAccordion = tab => {
		const prevState = this.state.accordion;
		const state = prevState.map((x, index) => (tab === index ? !x : false));
		this.setState({
		  accordion: state
		});
    };
	
	updateConfiguration = (event, errors, values)  => {
		if (errors.length === 0) {
			document.getElementById("update-configurations").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("updateSettingsForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('menu_structure',JSON.stringify(this.state.menus));
			
			axios.post(REST_API_END_POINT+'website/update-menus',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					swal("Success",data.msg,"success").then(()=>{
						window.location.reload();
					});
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				document.getElementById("update-configurations").disabled=false;
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-configurations").disabled=false;
			});
		}
	}
	
	renderItem({ item }){
		console.log(item);
		let isParent = typeof item.children!=='undefined';
		return <Card className="section-item">
					<CardBody className="p-0">
						<Button
								block
								color="outline-primary"
								className="text-left font-weight-bold rounded-0 section-item-btn"
								onClick={() => {
									let menus = this.state.menus;		
									menus = menus.map(row=>{
										if(row.id===item.id && isParent){
											row.opened = !row.opened;
										}else{
											row.children = row.children.map(c=>{
												if(c.id===item.id){
													c.opened = !c.opened;
												}
												return c;
											});
										}
										return row;
									});
									this.setState({menus, refresh:false},()=>{ this.setState({ refresh:true }) });
								}}
								
							   aria-expanded={item.opened}
							>
							<span className="section-item-name">{item.name}</span>						
						</Button>
						<Collapse isOpen={item.opened}>
							<div className="section-item-inner-contianer p-4">	
								<AvGroup className="error-t-negative">
									<Label>URL</Label>
									<AvField
										name="url_link"
										type="text"
										autoComplete="off"
										value={item.url_link}
										disabled={item.is_page}
										onChange={(e)=>{ 
											let menus = this.state.menus;		
											menus = menus.map(row=>{
												if(row.id===item.id && isParent){
													row.url_link = e.target.value;
												}else{
													row.children = row.children.map(c=>{
														if(c.id===item.id){
															c.url_link = e.target.value;
														}
														return c;
													});
												}
												return row;
											});
											this.setState({menus});
										}}
										onBlur={()=>{
											this.setState({refresh:false},()=>{ this.setState({ refresh:true }) });
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the url"
											}
										}}
									/>
								</AvGroup>
								<AvGroup className="error-t-negative">
									<Label>Link Text</Label>
									<AvField
										name="name"
										type="text"
										autoComplete="off"
										value={item.name}
										onChange={(e)=>{ 
											let menus = this.state.menus;		
											menus = menus.map(row=>{
												if(row.id===item.id && isParent){
													row.name = e.target.value;
												}else{
													row.children = row.children.map(c=>{
														if(c.id===item.id){
															c.name = e.target.value;
														}
														return c;
													});
												}
												return row;
											});
											this.setState({menus});
										}}
										onBlur={()=>{
											this.setState({refresh:false},()=>{ this.setState({ refresh:true }) });
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the name"
											}
										}}
									/>
								</AvGroup>
								<Button color="danger" type="button" 
									onClick={() => {
										swal({
										  title: "Are you sure?",
										  text: "Delete the menu?",
										  icon: "warning",
										  buttons: true,
										  dangerMode: true,
										})
										.then((willDelete) => {
											if (willDelete) {
												let menus = this.state.menus;
												
												menus = menus.filter(c=>{ return c.id!==item.id });
												menus = menus.map(row=>{
													row.children = row.children.filter(c=>{ return c.id!==item.id });
													return row;
												});
												this.setState({menus, refresh:false},()=>{ this.setState({ refresh:true }) });
											}else{
												swal("Your operation is canceled!");
											}
										});
									}}
							   >Remove</Button>
							</div>
						</Collapse>				   
					</CardBody>
				</Card>
	};
		
	activeItem=(e,page_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    if(e.target.checked){
			selectedIds.push(page_id);	
		}else{
		  var x=selectedIds.indexOf(page_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		this.setState({selectedIds});
	}
	
	onChangeNestable=({ items })=>{
		items = items.map(it=>{
			it.children = it.children.map(c=>{
				delete c.children;
				return c;
			})
			return it;
		});
		
		this.setState({menus:items, refresh:false},()=>{ this.setState({ refresh:true }) });
	}
	
	addToMenu=()=>{
		let selectedIds = this.state.selectedIds;
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one page","warning");
			return;
		}
		let menus = this.state.menus;
		for(let row of selectedIds){
			let currentItem = this.state.pages.find(r=>{ return r.id===row });
			if(currentItem){
				let menu = { id:uuid(),name:currentItem.name, children:[], url_link:SITE_URL+'pages/'+currentItem.alias_key, opened:false, is_page:true }
				menus.push(menu);	
			}
		}
		
		this.setState({menus, selectedIds:[], refresh:false},()=>{ this.setState({ refresh:true }) });
	}	
	
	addCustomToMenu=()=>{
		if(this.state.url_link=='http://'){
			swal("Warning!","Please enter the url","warning");
			return;
		}
		if(this.state.url_name==''){
			swal("Warning!","Please enter the name","warning");
			return;
		}
		let menus = this.state.menus;
		let menu = { id:uuid(),name:this.state.url_name, children:[], url_link:this.state.url_link, opened:false, is_page:false }
		menus.push(menu);
		
		this.setState({menus, selectedIds:[], refresh:false, url_link:'http://', url_name:''},()=>{ this.setState({ refresh:true }) });
	}
	
	render() {
		
		const { pages, menus, refresh } = this.state
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.menu" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row>	
										<Colxx xxs="12">
											<div id="api_message"></div>
										</Colxx> 
										<Colxx xxs="12">
											<AvForm												
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.updateConfiguration}
												id="updateSettingsForm"
											  >
												<Row>
													<Colxx md="5">
														<Card className="section-item">
															<CardBody className="p-0">
																<Button
																	  block
																	  color="outline-primary"
																	  className="text-left font-weight-bold rounded-0 section-item-btn"
																	   onClick={() => this.toggleAccordion(0)}
																	   aria-expanded={this.state.accordion[0]}
																	>
																	<span className="section-item-name">Pages</span>						
																</Button>
																<Collapse isOpen={this.state.accordion[0]}>
																	<div className="section-item-inner-contianer p-4">
																		<ul className="list-unstyled">
																		{pages.map((page,ind)=>{
																			return <li key={ind} >
																				<FormGroup className="w-100 d-flex align-items-center">
																					<span>
																						<CustomInput type="checkbox" id={"page_"+page.id} onChange={event =>this.activeItem(event,page.id) } checked={this.state.selectedIds.includes(page.id)} />
																					</span>
																					<Label for={"page_"+page.id} className="mb-0" style={{maxWidth:'calc(100% - 50px)'}}>{page.name}</Label>
																				</FormGroup>																				
																			</li>
																		})}
																		</ul>
																		<Button color="primary" type="button" onClick={this.addToMenu} >Add to Menu</Button>
																	</div>
																</Collapse>				   
															</CardBody>
														</Card>
														<Card className="section-item">
															<CardBody className="p-0">
																<Button
																	  block
																	  color="outline-primary"
																	  className="text-left font-weight-bold rounded-0 section-item-btn"
																	   onClick={() => this.toggleAccordion(1)}
																	   aria-expanded={this.state.accordion[1]}
																	>
																	<span className="section-item-name">Custom Links</span>						
																</Button>
																<Collapse isOpen={this.state.accordion[1]}>
																	<div className="section-item-inner-contianer p-4">
																		<AvGroup className="error-t-negative">
																			<Label>URL</Label>
																			<AvField
																				name="url_link"
																				type="text"
																				autoComplete="off"
																				value={this.state.url_link}
																				onChange={(e)=>{
																					this.setState({url_link:e.target.value});
																				}}
																			/>
																		</AvGroup>
																		<AvGroup className="error-t-negative">
																			<Label>Link Text</Label>
																			<AvField
																				name="url_name"
																				type="text"
																				autoComplete="off"
																				value={this.state.url_name}
																				onChange={(e)=>{ 
																					this.setState({url_name:e.target.value});
																				}}
																			/>
																		</AvGroup>
																		<Button color="primary" type="button" onClick={this.addCustomToMenu} >Add to Menu</Button>
																	</div>
																</Collapse>				   
															</CardBody>
														</Card>
													</Colxx>
												
													<Colxx md="7">
													{refresh ? <Nestable
															maxDepth={2}
															items={menus}
															renderItem={this.renderItem}
															renderItem={this.renderItem}
															onChange={this.onChangeNestable}
													/>:""}
													</Colxx>
												</Row>
												
												<Row className="mt-4">
													<Colxx lg="12">
														<Button color="primary" id="update-configurations" >Update</Button>
													</Colxx>
												</Row>
										   </AvForm>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
