import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddTableModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {		
			room_structures:[],
			table_val:null,
			selectTable:[],
			rooms:[],
			room_id:null,
			room_no:null
		};	
	}
  
    componentDidMount(){
		var available=[];
		var table_types=[];
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-data-for-add-table',formData).then((res)=>{
			var data = res.data;
		
			var room_id=null;
			if(typeof this.props.room_id!='undefined' && this.props.room_id!=null){
				room_id=this.props.room_id;
				this.changeRoom(room_id);
			}
		
			if(data.available){
				available=data.available;			
				table_types=data.type_of_tables;			
				var rooms=data.rooms;			
				
				rooms = rooms.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				table_types = table_types.map((element,index)=>{
					element.label=element.table_name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				this.setState({
					room_structures:available,
					selectTable:table_types,
					rooms,
					room_id
				});
			}else{
				this.setState({
					room_structures:[],
					selectTable:table_types,
					rooms:[],
					room_id
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}  
	
	changeRoom(room_id){
		var available=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('room_id',room_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-available-table-postions',formData).then((res)=>{
			var data = res.data;
			
			if(data.available){
				available=data.available;
				this.setState({
					room_structures:available,
					room_id
				});
			}else{
				this.setState({
					room_structures:[],
					room_id
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}  
	
   	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-table").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("tableForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
         
			
			axios.post(REST_API_END_POINT+'growhouse/add-table',formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("add-table").disabled=false;
				if(data.status==1){				
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					
					this.props.toggleModal();
					this.props.dataListRender();
				}else{
					swal("Warning!", data.msg, "warning");
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-table").disabled=false;
			});

		}
	}
 
  
	handleClick = (room_no) => {
        this.setState({
			room_no
        });
    };


	render() {	  
		const {  selectTable, room_id } = this.state;
		var room_structures= this.state.room_structures;
		
		var rooms = this.state.rooms;
		if(typeof this.props.rooms!='undefined' && this.props.rooms!=null){
			rooms=this.props.rooms;
		}
  
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add Table
			</ModalHeader>
			<AvForm
		        id="tableForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						<Colxx lg="12" className="mb-4">
							<Label >Room<span className="text-danger">*</span></Label>
							<AvField
								type="select"
								name="room_id"
								id="room_id"
								value={room_id}
								onChange={(e)=>{ this.changeRoom(e.target.value); }}
								placeholder="--Select Room--"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							>
							<option value="" >--Select Room--</option>
							{rooms.map(opt=>{
								return <option key={opt.key} value={opt.value} >{opt.label}</option>
							})}
							</AvField>
						</Colxx>				
					</Row>

					<Row>
						<Colxx lg="12" className="mb-4">
							<Label >Type of Table<span className="text-danger">*</span></Label>
							<AvField
								type="select"
								name="table_capacity_id"
								id="table_capacity_id"
								placeholder="--Select Table Type--"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							>
							<option value="" >--Select Table Type--</option>
							{selectTable.map(opt=>{
								return <option key={opt.key} value={opt.value} >{opt.label}</option>
							})}
							</AvField>
						</Colxx>				
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Table No. Starts at<span className="text-danger">*</span></Label>
								<AvField
									id="position"
									name="position"
									type="number"
									value={this.state.room_no}
									readOnly={true}
									validate={{
									  required: {
										value: true,
										errorMessage: "Please enter numeric value"
									  }
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<hr/>
					<Row className="mt-2">
						<Colxx lg="12">					
							<AvGroup className="error-t-negative">
								<Label>No. of Table<span className="text-danger">*</span></Label>
								<AvField
									id="no_of_tables"
									name="no_of_tables"
									type="number"
									autoComplete="off"
									min='0'
									validate={{
										required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
								/>
							</AvGroup>
						</Colxx>									
					</Row>
					
					<h5>Choose any one of available table</h5>
					{room_structures.map((row,index)=>{
						return (<span key={index} className={"w-40px p-2 m-1 border d-inline-block text-center cursor-pointer "+(this.state.room_no==row ? "room-active":"")} onClick={()=>{this.handleClick(row)}} >
								{row}
								</span>)
					})}	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-table">
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
	  </Modal>
	  );
  }
};

export default AddTableModal;
