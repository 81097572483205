import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import growhouse from "./growhouse";
import alltablestype from "./alltablestype";
import addplanttotable from "./addplanttotable";
import allPlants from "./allPlants";
import editPlantModal from "./editPlantModal";
import group from "./group";
import creategroup from "./creategroup";
import plantorders from "./plantorders";
import plantaudit from "./plantaudit";
import batch from "./batch";
import room from "./room";
import table from "./table";
import batchoption from "./batchoption";




const Growhouse = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
                      
			<Route path={`${match.url}/all-room`} component={growhouse} />
			<Route path={`${match.url}/all-table-types`} component={alltablestype} />
			<Route path={`${match.url}/add-plant-to-table`} component={addplanttotable} />
			<Route path={`${match.url}/all-plants`} component={allPlants} />
			<Route path={`${match.url}/edit-plant/:plant_id`} component={editPlantModal} />
			<Route path={`${match.url}/group`} component={group} />
			<Route path={`${match.url}/creategroup`} component={creategroup} />
			<Route path={`${match.url}/plant-orders`} component={plantorders} />
			<Route path={`${match.url}/plant-audit`} component={plantaudit} />
			<Route path={`${match.url}/batch`} component={batch} />
			<Route path={`${match.url}/room/:room_id`} component={room} />
			<Route path={`${match.url}/table/:table_id`} component={table} />
			<Route path={`${match.url}/batchoption`} component={batchoption} />
		
			<Route path={`${match.url}`} component={growhouse} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Growhouse;