import React, { Component, Fragment } from "react";
import { Row, Button, Card, FormGroup, Input, CustomInput } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import Breadcrumb from "../../../containers/navs/Breadcrumb";
import swal from 'sweetalert';
import DataTablePagination from "../../../components/DatatablePagination";
import ReactTable from "react-table";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

import AddNewDealModal from "../../../containers/pointofsale/daily-deals/AddNewDealModal";
import EditDealModal from "../../../containers/pointofsale/daily-deals/EditDealModal";
import ManageStockModal from "../../../containers/pointofsale/daily-deals/ManageStockModal";
import ReduceStockModal from "../../../containers/pointofsale/daily-deals/ReduceStockModal";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Coupons extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this); //bind the function if it invoked to another component
		this.toggleModalReduceStock = this.toggleModalReduceStock.bind(this); 
		this.state = {	 
			modalOpenAdd: false,
			modalOpenEdit:false,
			modalOpenManageStock:false,
			modalOpenReduceStock:false,
			data:[],
			selected:{},
			selectedIds:[],
			deal_id:0,
			selectAll:0,
			coupon:[],
			search: '',
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
		
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.coupon.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one deal","warning");
			return;
		}
		var msg="";
		var success_msg="";
		var failure_msg="";
        if(status===1){
            msg="Activate the deal?";
            success_msg="The deal has been activated.";
            failure_msg="Failed to activate deal";
        }else if(status===2){
            msg="Delete the deal?";
            success_msg="The deal has been deleted.";
            failure_msg="Failed to delete deal";
        }else{
            msg="Deactivate the deal?";
            success_msg="The deal has been deactivated.";
            failure_msg="Failed to deactivate deal";
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-coupon").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('deal_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'point-of-sale/delete-daily-deals',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-coupon").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(parseInt(data.status)===1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-coupon").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	activeItem=(e,deal_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(deal_id);			
		}else{
		  var x=selectedIds.indexOf(deal_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.coupon.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,deal_id=0) =>{
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
			deal_id
		});
	};
	
	toggleModalManageStock = (e,deal_id=0) =>{
		this.setState({
			modalOpenManageStock: !this.state.modalOpenManageStock,
			deal_id
		});
	};
	
	toggleModalReduceStock = (e,deal_id=0) =>{
		this.setState({
			modalOpenReduceStock: !this.state.modalOpenReduceStock,
			deal_id
		});
	};
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-daily-deals',formData).then((res)=>{
			var data = res.data;
		
			if(data.deals){
				this.setState({
					coupon: data.deals,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					coupon: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
    componentDidMount(){  
		this.dataListRender();		   
	}
	
	render() {		
		let data = this.state.coupon;
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.product_name.includes(this.state.search)  
			})
		}
		
		const dataTableColumns = [		
		  {
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {
				return ( <FormGroup>
					<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
					</FormGroup> );
			}
		  },
		  {
			Header: "Name",
			accessor: "name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Products",
			accessor: "product_name",
			Cell: props => <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "Price",
			accessor: "amount",
			Cell: props =>  <p className="text-muted">{props.value}</p>
		  },
		  /*{
			Header: "Available",
			accessor: "stock",
			width:100,
			Cell: props =>  <p className="text-muted">{props.value}</p>
		  },*/
		  {
			Header: "",
			accessor: "id",
			sortable: false,
			Cell: props => <Fragment>
							<Button color="primary" size="sm" className="mr-2" onClick={(e)=> this.toggleModalEdit(e,props.value) } >Edit</Button>
							{/*<Button color="primary" size="sm" className="mr-2" onClick={(e)=> this.toggleModalManageStock(e,props.value) } >Add Stock</Button>
							<Button color="primary" size="sm" onClick={(e)=> this.toggleModalReduceStock(e,props.value) } >Reduce Stock</Button>*/}
						</Fragment>
		  }
		];
	
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.daily-deals" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				
				<Card className="p-4">
				   <Row>
						<Colxx xxs="12">
							<div id="api_message"></div>
						</Colxx>
						<Colxx xxs="12">
							<div className="float-sm-right">
								{" "}<Button																		 
									  color="primary"
									  size="sm"
									  className=""
									  onClick={this.toggleModalAdd}									  
									>
									  <IntlMessages id="pages.add-new" />							  
								</Button>{" "}<Button
									color="primary"
									size="sm"
									id="delete-coupon"
									className=""
									onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 							
								  >
									<IntlMessages id="pages.delete" />
								</Button>
							</div>
								
							<div className="mb-4 dropdown-edit">								
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
									</Colxx>
								</FormGroup>			
									<ReactTable
										data={data}
										columns={dataTableColumns}
										defaultPageSize={10}
										filterable={false}
										showPageJump={false}
										PaginationComponent={DataTablePagination}
										showPageSizeOptions={true}
										defaultSorted={[{id: "send_on",desc: true}]}
									/>
							</div>							
						</Colxx>
					</Row>
				</Card>
				
				<AddNewDealModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.dataListRender}
				/>
				  
				<EditDealModal
					modalOpen={this.state.modalOpenEdit}
					toggleModal={this.toggleModalEdit}
					deal_id={this.state.deal_id}
					dataListRender={this.dataListRender}
				/>
				
				<ManageStockModal
					modalOpen={this.state.modalOpenManageStock}
					toggleModal={this.toggleModalManageStock}
					deal_id={this.state.deal_id}
					dataListRender={this.dataListRender}
				/>
				
				<ReduceStockModal
					modalOpen={this.state.modalOpenReduceStock}
					toggleModal={this.toggleModalReduceStock}
					deal_id={this.state.deal_id}
					dataListRender={this.dataListRender}
				/>
			</Fragment>
		);
	}
}
