const customfieldtemplate = [
{
    id: 1,
    name: "Profile", 
	vendor: "Emerald City",   
	team: "05/14/2019 03:06:56", 
	
	status: {
      statusid: 2,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Sample 1", 
	vendor: "Emerald City",     
	team: "05/14/2019 03:03:13", 
	
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 3,
    name: "RESEARCH COLLABORATION QUESTIONNAIRE",
	vendor: "Emerald City",  
	team: "05/14/2019 03:06:56", 
	
	status: {
      statusid: 1,
      statusColor: "danger",
    }
  }
]

export default customfieldtemplate
