import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,ButtonDropdown,DropdownToggle,
    DropdownMenu,Label } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactTable from "react-table";

import moment from 'moment';
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PlantAudit extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.state = {		  
		  modalOpenTable: false,
		  modalOpenEditTable: false,
		  modalOpenPlantTable: false,
		  modalOpenHarvest: false,
		  modalOpenCure: false,
		  modalOpenCut: false,
		  dropdownSplitOpen: false,
		  all_room:true,
		  specific_room:false,
		  remove_remaining:true,
		  move_remaining:false,
		  search: '',
		  selectedIds:[],
		  selected:[],
		  unselectedIds:[],
		  selectRooms:[],
		  selectTable:[],
		  selectMoveTable:[],
		  tableAll:[],
		  plants:[]
		 
		};
	}
	toggleModalTable = () => {
		this.setState({
		  modalOpenTable: !this.state.modalOpenTable
		});
	};

	toggleSplit = () => {
		this.setState(prevState => ({
		  dropdownSplitOpen: !prevState.dropdownSplitOpen
		}));
    };
	
    toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.plants.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	componentDidMount(){  
		this.dataListRender();		   
	}
	
	dataListRender() {
			
		var rooms=[];	
		var plants=[];	
		 
        let Rooms = document.getElementById("room_id").value; 			
        let Tables = document.getElementById("table_id").value; 			
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		
		if(this.state.specific_room){
			formData.append('room_id',Rooms);
			formData.append('table_id',Tables);
		}else{
			formData.append('room_id',"0");
			formData.append('table_id',"0");
		}
		
		axios.post(REST_API_END_POINT+'growhouse/plant-audit',formData).then((res)=>{
			var data = res.data;
			
			rooms=data.rooms;
			
		    rooms = rooms.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
		if(data.plants){
			
				this.setState({
					plants: data.plants,
					selectRooms:rooms,
					selectAll:0,
					selectedIds:[],
					unselectedIds:data.plants.map(row=>row.id),
				});
		}else{
				this.setState({
					plants: [],
				    selectRooms:rooms,
					selectAll:0,
					selectedIds:[],
					unselectedIds:[]
				});
		}		
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	getRoomData(room_id,key='selectTable'){
		var tables=[];
		var rooms=[];
		
		var facility_id = localStorage.getItem('facility_id');
		
		facility_id =  dataCrypto.decrypt(facility_id);
			
		var formData = new FormData();
        formData.append('room_id',room_id);
		
		
		axios.post(REST_API_END_POINT+'growhouse/get-active-tables-by-room',formData).then((res)=>{
			var data = res.data;
				
			tables=data.tables;	
			
			tables = tables.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			if(key=='selectTable'){
				if(data.tables){
					this.setState({
						selectTable:tables,
					});
				}else{
					this.setState({
						selectTable:tables,
					});
				}
			}else{
				if(data.tables){
					this.setState({
						selectMoveTable:tables,
					});
				}else{
					this.setState({
						selectMoveTable:tables,
					});
				}
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			
		});	
	}
      

    handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("moveForm"));
			
			if(this.state.unselectedIds.length){
				document.getElementById("move-plant").disabled=true;
				if(this.state.move_remaining){				
					formData.append('facility_id',facility_id);
					formData.append('current_user_id',user_id);
					formData.append('plant_ids',this.state.unselectedIds.join(","));
					formData.append('reason_for_movement',"move plant from plant audit");
				
					axios.post(REST_API_END_POINT+'growhouse/move-plants',formData).then((res)=>{
						var data = res.data;
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						document.getElementById("move-plant").disabled=false;						
						document.body.scrollTop = 0; // For Safari
						document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
						this.dataListRender();
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("move-plant").disabled=false;
					});               
				
				}else{				
					formData.append('facility_id',facility_id);
					formData.append('current_user_id',user_id);
					formData.append('plant_ids',this.state.unselectedIds.join(","));
					formData.append('delete_reason',"remove plant from plant audit");
				
					axios.post(REST_API_END_POINT+'growhouse/remove-plants',formData).then((res)=>{
						var data = res.data;
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						document.getElementById("move-plant").disabled=false;
						this.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("move-plant").disabled=false;
					});
				}
			}else{
				if(this.state.move_remaining){	
					swal("Warning","There is no unaccounted plants to move","warning");
				}else{
					swal("Warning","There is no unaccounted plants to remove","warning");
				}
			}
		}
	}
	
    activeItem=(e,plant_ids)=>{		
	    let selectedIds = this.state.selectedIds;
       	let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(plant_ids);
			
			
		}else{
		  var x=selectedIds.indexOf(plant_ids);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.plants.length){
			selectAll=1;
			
		}else{
			selectAll=0;
		}
		
		var all_plant_ids = this.state.plants.map(x => x.id);
		
		var unselectedIds = all_plant_ids.filter(row=>{ return selectedIds.indexOf(row)==-1 });
		this.setState({selectedIds,selectAll,unselectedIds});
	}  
	
    render() {
		
		let data = this.state.plants
	  
		var {selectRooms,selectTable,selectMoveTable} = this.state;
	  
		if (this.state.search) {
			data = data.filter(row => {
				if(row.strain==null){
					row.strain='';
				}
				return row.barcode.includes(this.state.search) || row.strain.toLowerCase().includes(this.state.search) || row.room.toLowerCase().includes(this.state.search) || row.birth_date.includes(this.state.search)|| row.expected_harvest_date.includes(this.state.search)|| row.time_in_room.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1}  />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"plant_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>	
			},
			{
				Header: "Strain",
				accessor: "strain",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Room",
				accessor: "room",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Birth Date",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{moment(props.value).format("MM/DD/YYYY HH:mm")}</p>
			},
			{
				Header: "Expected Harvest Date",
				accessor: "expected_harvest_date",
				Cell: props => <p className="text-muted">{props.value!=0 ? moment(props.value*1000).format("MM/DD/YYYY HH:mm"):""}</p>
			},
			{
				Header: "Time in Room",
				accessor: "time_in_room",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
		];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.plant-audit" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
			<Card className="p-4">
				<div id="api_message"></div>
				<AvForm>
					<Row>
						<Colxx lg="4" className="mt-2">
							<Label></Label>	&nbsp;&nbsp;
							<Label>
							  <CustomInput
								id="all_room"
								type="radio"
								name="all_room"							
								value="1"
								checked={this.state.all_room}
								onChange={(e) => { this.setState({ all_room:true,specific_room:false }); }}
								className="d-inline-block"
							  />
							  {" "}All Rooms
							</Label>&nbsp;&nbsp;
							<Label>
							  <CustomInput
								id="specific_room"
								type="radio"
								name="all_room"							
								value="2"
								checked={this.state.specific_room}
								onChange={() => { this.setState({ all_room:false,specific_room:true }); }}
								className="d-inline-block"
							  />
								{" "}Specific Room
							</Label>
						</Colxx>
						<Colxx lg="4">
								<AvGroup className="error-t-negative">
									<Label>Rooms<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="room_id"
										disabled={this.state.all_room}
										id="room_id"
										placeholder="--Select Room--"
										validate={{
										  required: {
											value: this.state.specific_room,
											errorMessage: "This field is required."
										  }
										}}
										value={this.state.room_id}
										onChange={(e)=>{
											this.setState({
												room_id:e.target.value
											},()=>{
												this.getRoomData(this.state.room_id)
											});
			
										}}
									>
									<option value="" >--Select Room--</option>
									{selectRooms.map(opt=>{
										return <option key={opt.key} value={opt.value} >{opt.label}</option>
									})}
									</AvField>						  
								</AvGroup>
							</Colxx>
							<Colxx lg="4" className="mb-2">						
								<AvGroup className="error-t-negative">
								  <Label>Table<span className="text-danger">*</span></Label>
										<AvField
											type="select"
											name="table_id"
											disabled={this.state.all_room}
											id="table_id"
											placeholder="--Select Table--"
											value={this.state.table_id}
											onChange={(e)=>{
												this.setState({
													table_id:e.target.value
												});
				
											}}
											validate={{
											  required: {
												value: this.state.specific_room,
												errorMessage: "This field is required."
											  }
											}}
										>
											<option value="" >--Select Table--</option>
											{selectTable.map(opt=>{
												return <option key={opt.key} value={opt.value} >{opt.label}</option>
											})}		
										</AvField>
								</AvGroup>								
							</Colxx>								
					</Row>
		 
					<Row>
						<Colxx xxs="9">
						</Colxx>
						<Colxx xxs="3">
							<div className="float-sm-right">
								{" "}<Button
									   onClick={this.dataListRender}
									   color="primary"
									   size="sm"
									   className="mb-4"
									>
									  Okay						  
								</Button>
							{" "}
							</div>
						</Colxx>
					</Row>	
				</AvForm>
					<Row>
						<Colxx xxs="12">				 
							<div className="mb-4 dropdown-edit">
								<FormGroup row>
									<Colxx lg="6" >
										<Input
										  type="text"
										  autoComplete="off"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
										/>
									</Colxx>
								</FormGroup>		

								<ReactTable
								  data={data}
								  columns={dataTableColumns}
								  defaultPageSize={10}
								  filterable={false}
								  showPageJump={false}
								  PaginationComponent={DataTablePagination}
								  showPageSizeOptions={true}
								  defaultSorted={[{id: "send_on",desc: true}]}
								/>
							</div>
						</Colxx>
					</Row>
					<AvForm
						id="moveForm"
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					>
					<Row>
						<Colxx lg="6" className="mt-4 mb-4">
							<AvGroup className="error-t-negative">
							  <Label>Accounted For</Label>
							  <AvField
								id="accounted_for"
								name="accounted_for"
								type="number"
								readOnly={true}
								value={this.state.selectedIds.length>0 ? this.state.selectedIds.length:"0"}
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6" className="mt-4 mb-4">
							<AvGroup className="error-t-negative">
							  <Label>Remaining</Label>
							  <AvField
								id="remaining_count"
								name="remaining_count"
								type="number"
								readOnly={true}
								value={this.state.unselectedIds.length>0 ? this.state.unselectedIds.length:"0"}
								placeholder=""
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="2" className="mt-2">
								<Label>Remove Remaining</Label>	&nbsp;&nbsp;
								
								  <CustomInput
									id="remove_remaining"
									type="radio"
									name="remaining"							
									value="1"
									checked={this.state.remove_remaining}
									onChange={() => { this.setState({ remove_remaining:true,move_remaining:false }) }}
									className="d-inline-block"
								  />
						</Colxx>
						<Colxx lg="2" className="mt-2">
							<Label>Move Remaining To</Label>	&nbsp;&nbsp;
							
							  <CustomInput
								id="move_remaining"
								type="radio"
								name="remaining"							
								value="2"
								checked={this.state.move_remaining}
								onChange={() => { this.setState({ move_remaining:true,remove_remaining:false }) }}
								className="d-inline-block"
							  />
						</Colxx>
						<Colxx lg="3">
							<AvGroup className="error-t-negative">
								<Label>Rooms<span className="text-danger">*</span></Label>
								<AvField
									type="select"
									name="room_id"
									disabled={this.state.remove_remaining}
									id="room_id"
									placeholder="--Select Room--"
									validate={{
									  required: {
										value: this.state.specific_room,
										errorMessage: "This field is required."
									  }
									}}
									value={this.state.move_room_id}
									onChange={(e)=>{
										this.setState({
											move_room_id:e.target.value
										},()=>{
											this.getRoomData(this.state.move_room_id,'selectMoveTable')
										});
		
									}}
								>
								<option value="" >--Select Room--</option>
								{selectRooms.map(opt=>{
									return <option key={opt.key} value={opt.value} >{opt.label}</option>
								})}
								</AvField>						  
							</AvGroup>
						</Colxx>
						<Colxx lg="3" className="mb-2">						
							<AvGroup className="error-t-negative">
								<Label>Table<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="table_id"
										disabled={this.state.remove_remaining}
										id="table_id"
										placeholder="--Select Table--"
										value={this.state.move_table_id}
										onChange={(e)=>{
											this.setState({
												move_table_id:e.target.value
											});
			
										}}
										validate={{
										  required: {
											value: this.state.specific_room,
											errorMessage: "This field is required."
										  }
										}}
									>
										<option value="" >--Select Table--</option>
										{selectMoveTable.map(opt=>{
											return <option key={opt.key} value={opt.value} >{opt.label}</option>
										})}		
									</AvField>
							</AvGroup>								
						</Colxx>		
						<Colxx xxs="1">
							<div className="float-sm-right">
								{" "}<Button

									   id="move-plant"
									   color="primary"
									   size="sm"
									   className="mt-4"
									 
									>
									  Okay						  
								</Button>
								{" "}
							</div>
						</Colxx>
						<Colxx xxs="1">
							<div className="float-sm-right">
									{" "}<Button

											onClick={this.dataListRender}
											color="primary"
											size="sm"
											className="mt-4"
										  
										>
										 Cancel						  
									</Button>
								{" "}
							</div>
						</Colxx>				
					</Row>
			</AvForm>			
		</Card>
		
      </Fragment>
    );
  }
}
