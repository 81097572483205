import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";

import axios from 'axios';
import { REST_API_END_POINT,projectTitle } from "../../constants/defaultValues";

import swal from 'sweetalert';

export default class ChangePasswordSubscriberModal extends Component{
  constructor(props) {
    super(props);
	this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, errors, values) {
    if (errors.length === 0) {
		document.getElementById("update_password_btn").disabled = true;
		
		var formData = new FormData(document.getElementById("update_password"));
		formData.append('user_id',this.props.user_id);
		if(values!=null){
			axios.post(REST_API_END_POINT+'/admin/update-password',formData).then((data)=>{ 
				if(data.data.status==1){
					swal("Success!", "Subscriber's password has been updated successfully!", "success");		
					document.getElementById("update_password").reset();
					document.getElementById("update_password_btn").disabled = false;
					this.props.toggleModal();					
				}else{
					swal("Failed to update subscriber details!", "Failed to update password! Please try after sometime.", "error");
				}
			}).catch(e => { 
				console.log("Addition failed , Error ", e); 
				swal("Failed to update subscriber details!", "Failed to update password! Please try after sometime.", "error");
			});			
		}
    }
  }
  


  render() {
	
	  return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="lg"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Change password
		  </ModalHeader>
		  <AvForm
				id="update_password"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>			
				<AvGroup className="tooltip-right-bottom">
				  <Label><IntlMessages id="user.password" /></Label>
				  <AvField
					name="new_password"
					type="password"
					autoComplete="new_password"
					validate={{
					  required: {
						value: true,
						errorMessage: "Please enter a strong password"
					  },
					}}
				  />
				</AvGroup>				
				<AvGroup className="tooltip-right-bottom">
				  <Label><IntlMessages id="user.confirmpassword" /></Label>
				  <AvField
					name="confirm_password"
					type="password"
					autoComplete="new_password"
					validate={{
					  required: {
						value: true,
						errorMessage: "Please enter a confirm password"
					  },
					  match:{
						value:'new_password',
						errorMessage: "The new password and confirm password must be the same"
					  }					  
					}}
				  />
				</AvGroup>								
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update_password_btn" >
			  <IntlMessages id="pages.update" />
			</Button>
			
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
