import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';
import { REST_API_BASE_URL } from "../../constants/defaultValues";


import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import number_format from "number_format-php";

export default class InvoiceModalContent extends Component {

	render() {
		const { order_data, order_products, facility_data, currencies } = this.props;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax = 0;
		var sgst_tax = 0;
		var cgst_tax = 0;
		var sub_total = 0;
		var p_discount = 0;
		let is_utgst = false;
		let is_igst = true;
		if (order_data != null) {
			var current_currency = currencies.find(row => { return row.currency_code == order_data.currency_code });
			if (current_currency != null) {
				//current_currency_symbol = current_currency.currency_symbol;
			}

			/*is_igst = order_data.customer_state_id !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}*/
		}
		const data = [
			{
			 slno:1,
			  name: 'Kitchen',
			  products: [
				{  name: 'Product A', price: 10, total: 100 },
				{ name: 'Product B', price: 20, total: 200 },
			  ],
			},
			{
		   slno:2,
			  name: 'Accessories',
			  products: [
				{  name: 'Product C', price: 15, total: 150 },
				{  name: 'Product D', price: 25, total: 250 },
			  ],
			},
		  ];
		  const products = [];

		  data.forEach(item => {
			item.products.forEach(product => {
			  products.push(product);
			});
		  });
		  
		  console.log(products);
		return (
			<Row className="invoice-react">
				<Colxx xxs="12" className="">







					<div className="mb-5 invoice-contents">

						<CardBody className="d-flex flex-column justify-content-between">
							<Table class="table table-bordered-dark">
								<thead className="Invoice-logo-and-destination">
									<tr>
										<th><img src={REST_API_BASE_URL + "img/email/logo.png"}
											className="modal-extimate-image img-fluid " style={{width:200 }} /></th>
										<th>
											<div className="">
												<div className="font-weight-bold h5">{facility_data != null ? facility_data.facility_name : ""}</div>
												{facility_data != null ? (facility_data.facility_address != null ? <p className="mb-0">{facility_data.facility_address}</p> : "") : ""}
												{facility_data != null ? (facility_data.facility_phone != null ? <p className="mb-0">{facility_data.facility_phone}</p> : "") : ""}
												{facility_data != null ? (facility_data.facility_email != null ? <p className="mb-0">{facility_data.facility_email}</p> : "") : ""}
												{facility_data != null ? (facility_data.gstin != null ? <p className="mb-0">TRN: {facility_data.gstin}</p> : "") : ""}
											</div>
										</th>
									</tr>
									<tr>
										<div className="Invoice-table-client-details">
											<td>
												<p>Client :</p>
												<p>Project :</p>
												<p>Name :</p>
											</td>
											<td><p>Date:</p></td>
										</div>
									</tr>
									<tr>Quotation</tr>
								</thead>
								<tbody>

									<tr>
										<td colspan="2">
											<table class="table table-bordered">
												<thead>
													<tr>
														<th>SI NO</th>
														<th>ITEM</th>
														<th>SIZE</th>
														<th>SQFT/No</th>
														<th>AMOUNT</th>
													</tr>
												</thead>
												<tbody>
													{data.map((row)=>{
													return(
														<>
													    <tr>
															<td>{row.slno}</td>
															<td>{row.name}</td>
														</tr>
													    {row.products.map((r)=>(
															<tr>
															<td style={{border:'none'}}></td>
															<td>{r.name}</td>
															<td>{r.price}</td>
															<td>{r.total}</td>
															</tr>
														))}
														</>
														)
													})}
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</Table>
						</CardBody>
					</div>
				</Colxx>
			</Row>
		);
	}
};
