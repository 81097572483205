import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import moment from 'moment';
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectBlood = [
	{ label: "A+ve", value:"1", key: 1 },
	{ label: "A-ve", value:"2", key: 2 },
	{ label: "B+ve", value:"3", key: 3 },
	{ label: "B-ve", value:"4", key: 4 },
	{ label: "AB+ve", value:"5", key: 5 },
	{ label: "AB-ve", value:"6", key: 6 },
	{ label: "O+ve", value:"7", key: 7 },
	{ label: "O-ve", value:"8", key: 8 }, 
];


const AvailableStatus = [
	{ label: "Inactive", value:"0", key: 1 },
	{ label: "Available", value:"1", key: 2 },
	{ label: "Break", value:"2", key: 3 },
	{ label: "In Transit", value:"3", key: 4 }
];
  

class EditDriverModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {	
			driver:null,
			startDate: null,
			selectBloodType:null,
			selectAvailableStatus:null,
			selectWorkType:null,
			Facilities:[],
			genderType:false,
		};	
	}
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let driver; 					
            if (prevProps.driver_id !== this.props.driver_id && this.props.driver_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('driver_id',this.props.driver_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'transportation/get-driver-by-id',formData).then((res)=>{
					
					var data = res.data;
					
					driver=data.driver;
					var Facilities=data.facilities;
					Facilities = Facilities.map((element,index)=>{
						element.label=element.name;
						element.value=element.id;
						element.key=index;
						return element;
					});
					
					var selectBloodType = selectBlood.find(row=>{ return row.value==driver.blood_group });
					var selectAvailableStatus = AvailableStatus.find(row=>{ return row.value==driver.available_status });
					var selectWorkType = Facilities.find(row=>{ return row.value==driver.facility_id });
					var startDate = moment(driver.date_of_birth);
					
					this.setState({ 
						driver,
						selectBloodType,
						selectAvailableStatus,
						selectWorkType,
						startDate,
						genderType:false,
						Facilities
					},()=>{
						this.setState({
							genderType:true
						})
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    } 
  
  
	handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
				
			document.getElementById("update-driver").disabled=true;
			let dateOfBirth = document.getElementById("dob").value.trim();
			let errorMessage='';

			if(dateOfBirth.length==0)
			{
				errorMessage+="Date of Birth is empty\n";
			}

			if(errorMessage.length!=0)
			{
				swal("Warning",errorMessage,"warning");
				document.getElementById("update-driver").disabled=false;
				return false;
			}
						
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('email',values.email);
			formData.append('user_id',this.state.driver.user_id);
			
			axios.post(REST_API_END_POINT+'staff/is-email-exist',formData).then((res)=>{
				var data = res.data;
				
				if(data.status!=1){
					var formData = new FormData(document.getElementById("driverForm"));
					formData.append('driver_id',this.props.driver_id);
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);

					axios.post(REST_API_END_POINT+'transportation/edit-driver',formData).then((res)=>{
						
						var data = res.data;
						
						var driver=this.state.driver;
						driver.name=values.driver;
						this.setState({ driver });
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("update-driver").disabled=false;
						this.props.toggleModal();
						this.props.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("update-driver").disabled=false;
					});
				}else{
					swal("Email already exist! Please use another email");
					document.getElementById("update-driver").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-driver").disabled=false;
			});	

		}
	}
	
	onChange = e => {
		switch (e.target.name) {
			case 'files[]':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
				break;
			default: e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};


	render() {	
  
		if(this.state.driver!=null){
			var driver=this.state.driver;
		}else{
			var driver;
		}
  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Edit Driver
			</ModalHeader>
			<AvForm
		        id="driverForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Full Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							value={ driver?((driver.name!=null)?driver.name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
				<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label>Gender<span className="text-danger">*</span></Label>
						</AvGroup>
						{this.state.genderType ? <AvRadioGroup
							inline
							name="gender"
							onChange={(e) => {}}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select any of the options"
							  }
							}}
							defaultValue={ driver?((driver.gender!=null)?driver.gender:'1'):'1' }
							>							
							<AvRadio customInput label="Male" value="1" />
							<AvRadio customInput label="Female" value="2" />
						</AvRadioGroup >:""}
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="6">
						<Label><IntlMessages id="customer.dob" /><span className="text-danger">*</span></Label>
						<DatePicker
							id="dob"
							name="dob"
							className="mb-5"
							maxDate={moment().subtract(18, "years")}
							selected={this.state.startDate}
							onChange={this.handleChangeDate}
							placeholderText=""
							dateFormat="YYYY-MM-DD"
						  />
					</Colxx>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Blood Group<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="blood_group"
								id="blood_group"
								value={this.state.selectBloodType}							
								options={selectBlood}
								onChange={selectBloodType => { this.setState({ selectBloodType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<Label>Address<span className="text-danger">*</span></Label>	&nbsp;&nbsp;
						<AvGroup className="error-t-negative">
						  <AvField
							id="address"
							name="address"
							type="textarea"
							autoComplete="off"
							value={ driver?((driver.address!=null)?driver.address:''):'' }
							validate={{
								  required: {
									value: true, 
									errorMessage: "This field is required."
								  },
								  minLength: {
									value: 10,
									errorMessage:"Your message must have minimum of 10 characters"
								  },
								}}
							placeholder=""
							rows="3"
						  />
						</AvGroup>					
					</Colxx>					
				</Row>
				{ driver?((driver.license_doc!='')? <Row>
					<Colxx lg="12">
						<AvGroup className="error-l-125 error-t-negative">
							<Label>Driving License</Label>
							<div>
								<Button color="primary" onClick={()=>{
									window.open('http://skyniche.website/new-motagistics/uploads/staffs/'+driver.license_doc)
								}} >{ driver.license_doc }</Button>
							</div>
						</AvGroup>
					</Colxx>					
				</Row>:''):'' }
				
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label><IntlMessages id="customer.phone" /><span className="text-danger">*</span></Label>
						  <AvField
							id="phone"
							name="phone"
							type="phone"
							autoComplete="off"
							value={ driver?((driver.phone!=null)?driver.phone:''):'' }				
                            validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  number: {
								value: true,
								errorMessage: "Please enter a valid number"
							  },
							  minLength: {
								value: 7,
								errorMessage:
								  "Your name must be between 7 and 15 characters"
							  },
							  maxLength: {
								value: 15,
								errorMessage:
								  "Your name must be between 7 and 15 characters"
							  }
							}}							
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
						  <Label><IntlMessages id="customer.email" /><span className="text-danger">*</span></Label>
						  <AvField
							id="email"
							name="email"
							type="email"
							autoComplete="off"
							value={ driver?((driver.email_id!=null)?driver.email_id:''):'' }	
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required. "
							  },
							  email: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>License Number<span className="text-danger">*</span></Label>
							<AvField
								id="license"
								name="license"
								type="text"
								value={ driver?((driver.license_no!=null)?driver.license_no:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }							
								}}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Work Location<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="work_location"
								id="work_location"
								value={this.state.selectWorkType}						
								options={this.state.Facilities}
								onChange={selectWorkType => { this.setState({ selectWorkType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>

				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Available Status<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="available_status"
								id="available_status"
								value={this.state.selectAvailableStatus}							
								options={AvailableStatus}
								onChange={selectAvailableStatus => { this.setState({ selectAvailableStatus }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>

		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-driver" >
			  <IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditDriverModal;
