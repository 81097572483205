import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectType = [
    { label: "Increase", value: "1", key: 0 },
    { label: "Decrease", value: "2", key: 1 }
];
  
class EditPricingModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			dataType: { label: "Increase", value: "1", key: 0 },
			pricing_group:null
		};	
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {			
			document.getElementById("update-pricing-group").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("pricingForm"));
			formData.append('pricing_id',this.props.pricing_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'inventory/edit-pricing-group',formData).then((res)=>{
				var data = res.data;
				
				var pricing_group=this.state.pricing_group;
				pricing_group.name=values.name;
				pricing_group.type=values.type;
				pricing_group.rate=values.rate;
				
				this.setState({ pricing_group:pricing_group });
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-pricing-group").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-pricing-group").disabled=false;
			});

		}
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
	   
        if(this.props.modalOpen){
			let pricing_group; 		  
            if (prevProps.pricing_id !== this.props.pricing_id) {
				var formData = new FormData();
				formData.append('pricing_id',this.props.pricing_id);
	
				axios.post(REST_API_END_POINT+'inventory/get-pricing-group-by-id',formData).then((res)=>{
					var data = res.data;
					var type={ label: "Increase", value: "1", key: 0 };
					pricing_group=data.pricing;
					
					selectType.forEach((element,index) => {
                       if(element.value==pricing_group.type){
							type=element;
						}
					});
					this.setState({ 
						pricing_group,
						dataType:type,
					});
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});	
			}
		}
	}
	
	render() {
		if(this.state.pricing_group!=null){
			var pricing_group=this.state.pricing_group;
		}else{
			var pricing_group;
		}
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Edit Price Group
			</ModalHeader>
			<AvForm
		         id="pricingForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="pname"
								name="name"
								type="text"
								autoComplete="off"
								value={ pricing_group?((pricing_group.name!=null)?pricing_group.name:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z0-9 ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							 <Label>Type</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									id="type"						
									name="type"								
									value={this.state.dataType}						
									options={selectType}
									onChange={dataType => { this.setState({ dataType }) }}
								/>
						</Colxx>
					</Row>
					<Row className="mt-3">
						<Colxx lg="12">
							 <Label>Rate(%)<span className="text-danger">*</span></Label>
								<AvGroup className="error-t-negative">						  
								  <AvField
									id="rate"
									name="rate"
									type="number"
									min='0'
									autoComplete="off"
									value={ pricing_group?((pricing_group.rate!=null)?pricing_group.rate:''):'' }
									validate={{
										required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
								  />
								</AvGroup>
						</Colxx>
					</Row>
									
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-pricing-group" >Update</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
	}
};

export default EditPricingModal;
