import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody,
    CustomInput } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL, REST_API_BASE_URL, GOOGLE_API_KEY, PUBNUB_CREDENTIALS} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Trackdrivers extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			drivers:[]
		};
	}
	
	componentDidMount(){        	
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'transportation/track-drivers',formData).then((res)=>{
			var drivers=[];
			var data = res.data;
			if(data.status==1){
				var drivers= data.drivers;
				this.setState({ 
					drivers
				});
				
			}	
			var subscriber_id = localStorage.getItem('subscriber_id');
			var direction_channel = ['tridant_'+subscriber_id+'_'+facility_id];
			var local_channel = 'tridant_2_'+subscriber_id+'_'+facility_id;
				
			setTimeout(function(){
				const container = document.getElementById('map');
				if(container) {
					const PubNub=window.PubNub;
					const L=window.L;
					const eon=window.eon;

					var pn = new PubNub({
						publishKey: PUBNUB_CREDENTIALS.publishKey,
						subscribeKey: PUBNUB_CREDENTIALS.subscribeKey,
						ssl: (('https:' == document.location.protocol) ? true : false)
					});
					
					var pn_loc = new PubNub({
						publishKey: PUBNUB_CREDENTIALS.publishKey,
						subscribeKey: PUBNUB_CREDENTIALS.subscribeKey,
						ssl: (('https:' == document.location.protocol) ? true : false)
					});
					
					L.RotatedMarker = L.Marker.extend({
						options: { angle: 0 },
						_setPos: function(pos) {
							L.Marker.prototype._setPos.call(this, pos);
							if (L.DomUtil.TRANSFORM) {	// use the CSS transform rule if available
								this._icon.style[L.DomUtil.TRANSFORM] += ' rotate(' + this.options.angle + 'deg)';
							} else if (L.Browser.ie) { // fallback for IE6, IE7, IE8
								var rad = this.options.angle * L.LatLng.DEG_TO_RAD,costheta = Math.cos(rad),sintheta = Math.sin(rad);
								this._icon.style.filter += ' progid:DXImageTransform.Microsoft.Matrix(sizingMethod=\'auto expand\', M11=' + costheta + ', M12=' + (-sintheta) + ', M21=' + sintheta + ', M22=' + costheta + ')';
							}
						}
					});
					
					var torchys = drivers.map(row=>{
							return { 
								marker: new L.RotatedMarker([0,0], {
								  icon: L.icon({
									iconUrl: REST_API_BASE_URL+'img/truck-T.png',
									iconSize: [120, 68]
								  })
								}),
								user_id: parseInt(row.driver_user_id),
								data: row.name,
								latlng: [0,0]
							}
						})
					
					pn.publish({
						channel: local_channel,
						message: torchys
					});

					pn_loc.addListener({
						message: function (loc_data) {
							if(loc_data.message.user_id){
								var new_torchys = JSON.parse(JSON.stringify(torchys));
								for (var i = 0; i < new_torchys.length; i++) {						
									if(loc_data.message.user_id==new_torchys[i].user_id){
										if(new_torchys[i].latlng){
											new_torchys[i].latlng= {lat:loc_data.message.latitude,lng:loc_data.message.longitude};
											new_torchys[i].marker= new L.RotatedMarker(new_torchys[i].latlng, {
														icon: L.icon({
															iconUrl: REST_API_BASE_URL+'img/truck-T.png',
															iconSize: [120, 68]
														  })
													});
										}
									}
								}
								torchys = new_torchys;
								pn.publish({
											channel: local_channel,
											message: new_torchys
										});
							}
						}
					});
					pn_loc.subscribe({ channels: direction_channel });
					
					var old_latLng = { lat: -34.397, lng: 150.644 };
					
					var map = eon.map({
						pubnub: pn,
						id: 'map',
						googleKey: GOOGLE_API_KEY,
						options : { zoom:8, center:{ lat: -34.397, lng: 150.644 } },
						channels:[local_channel],
						provider: 'google',
						rotate: true,
						history: true,
						connect: function () {
							var me = {
								icon: {
									'marker-color': '#ce1126'
								}
							};
							var them = { 
								icon: {
									'marker-color': '#29abe2'
								}
							};
						},
						message: function (data) {
							if(typeof data!='undefined'){
								if(typeof data[0]!='undefined'){
									if(data[0]['user_id']){
										map.setView(data[0].latlng, 8);
									}
								}
							}
						},
						marker: function (latlng, data) {
							var marker = new L.RotatedMarker(latlng, {
							  icon: L.icon({
								iconUrl: REST_API_BASE_URL+'img/truck-T.png',
								iconSize: [120, 68]
							  })
							});
							if(data){
								marker.bindPopup(data);
							}
							return marker;
						}
					});
				}
			}, 300);
				
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
    }
	
	render() {		
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.track-drivers" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
			
				<Card className="p-4">
					<Row>			  
						<Colxx xxs="12">
							<div id="map" style={{width:'100%', height:'550px'}}></div>
						</Colxx>
					</Row>
				</Card>
			</Fragment>
		);
	}
}
