import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
//import DatePicker from "react-datepicker";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);



class AddFileModal extends Component{

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			startTime: null,
			startDate: null,
			
		};
	}

	handleChangeDate = date => {
		this.setState({
		  startDate: date
		});
	  };

    handleExpirationDate = date => {
		this.setState({
		  expirationDate: date
		});
	};

    handleCareDob = date => {
		this.setState({
		  careDob: date
		});
	};

    handleMMJCardExpDate = date => {
		this.setState({
		  mmjCardExpDate: date
		});
	};

	handleChangeTime = time => {
		this.setState({
		  startTime: time
		});
	};

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			
			document.getElementById("add-file").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("fileForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('task_id',this.props.task_id);
			
			axios.post(REST_API_END_POINT+'task/add-file',formData).then((res)=>{	
					var data = res.data;
					if(data.status==1){
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').off("click",function(){}).on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("add-file").disabled=false;
						
						this.setState({
							
						});						
						
						this.props.toggleModal();
						this.props.dataListRender();
					}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-file").disabled=false;
			});		

		}
	}

	onChange = e => {
		switch (e.target.name) {
		  case 'files':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  case 'caregiver_license_img':
			if(e.target.files[0]){

				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		 }
	};


  render() {
      
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Attach File
		  </ModalHeader>
		  <AvForm
                id="fileForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
				<AvGroup className="error-l-125 error-t-negative">
						<Label>Select File</Label>
							<InputGroup className="mb-3">
							  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
							  <CustomInput
								type="file"
								name="files"
								id="files"
								accept="image/*"
								onChange={this.onChange}
							  />
							</InputGroup>
					</AvGroup>
				<Row>
						
					</Row>

				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-file" >
					  <IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddFileModal;
