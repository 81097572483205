import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";
import swal from 'sweetalert';
import DatePicker from "react-datepicker";
import moment from "moment";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class AddPricePointModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			start_time:null,
			end_time:null,
		};
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			if(this.state.start_time===null){
				swal("Warning", "Please select start date", "error");
				return false;
			}else if(this.state.end_time===null){
				swal("Warning", "Please select end date", "error");
				return false;
			}
			
			document.getElementById("add-product-points").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("addPointForm"));
			formData.append('facility_id',facility_id);
			formData.append('product_id',this.props.product.id);
			formData.append('product_name',this.props.product.name);
			formData.append('user_id',user_id);
			formData.append('start_date',moment(this.state.start_time).format("YYYY-MM-DD"));
			formData.append('end_date',moment(this.state.end_time).format("YYYY-MM-DD"));
			
			axios.post(REST_API_END_POINT+'inventory/add-price-point',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById("api_message_points");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					this.setState({					
						start_time:null,
						end_time:null,
					})
					document.getElementById("add-product-points").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-product-points").disabled=false;
			});
		}
	}
	
	
	handleChangeStartTime = time => {
		if(parseInt(moment(this.state.end_time).unix()/100)>=parseInt(time.unix()/100) || this.state.end_time==null){
			this.setState({ start_time: time });
		}else{
			swal("", "Start date must be less than end time", "error");		
		}
		
	};
	
	handleChangeEndTime = time => {
		if(parseInt(moment(this.state.start_time).unix()/100)<=parseInt(time.unix()/100) || this.state.start_time==null){
			this.setState({ end_time: time });
		}else{
			swal("", "End date must be greater than start time", "error");		
		}
	};

	
	render() {
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Add Price Point
				</ModalHeader>
				<AvForm
					id="addPointForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="6">
							<Label>Money Spend<span className="text-danger">*</span></Label>						
							<AvGroup >								
								<AvField
									id="money_spend"
									name="money_spend"
									type="number"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										min: {
											value: "0",
											errorMessage: "Please enter a value greater than or equal to 0"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					
						<Colxx lg="6">
							<Label>Price Point<span className="text-danger">*</span></Label>						
							<AvGroup >								
								<AvField
									id="price_points"
									name="price_points"
									type="number"
									autoComplete="off"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: "0",
											errorMessage: "Please enter a value greater than or equal to 0"
										},
										step:{
											value: "1",
											errorMessage: "Decimal values are not allowed"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="6" >		
							<AvGroup className="disabled_time_hide">
								<Label>Start Date<span className="text-danger">*</span></Label><br/>					
								<DatePicker
									className="mb-2"
									placeholderText=""
									selected={this.state.start_time}
									onChange={this.handleChangeStartTime}
									dateFormat="MM/DD/YYYY"
									minDate={moment()}
									popperPlacement="bottom"
									popperModifiers={{
										flip: {
											behavior: ["bottom"] // don't allow it to flip to be above
										},
										preventOverflow: {
											enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
										},
										hide: {
											enabled: true // turn off since needs preventOverflow to be enabled
										}
									}}
								/>
							</AvGroup>
						</Colxx>
						
						<Colxx lg="6" >		
							<AvGroup className="disabled_time_hide">
								<Label>End Date<span className="text-danger">*</span></Label><br/>					
								<DatePicker
									className="mb-2"
									placeholderText=""
									selected={this.state.end_time}
									onChange={this.handleChangeEndTime}
									dateFormat="MM/DD/YYYY"
									minDate={moment()}
									popperPlacement="bottom"
									popperModifiers={{
										flip: {
											behavior: ["bottom"] // don't allow it to flip to be above
										},
										preventOverflow: {
											enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
										},
										hide: {
											enabled: true // turn off since needs preventOverflow to be enabled
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="add-product-points" >
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};