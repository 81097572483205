import React, { Component } from "react";
import {
	Row,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input, Media
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DataTablePagination from "../../components/DatatablePagination";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import moment from "moment";
import Rating from "../../components/common/FilledRating";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ViewLogsModal extends Component{
	constructor(props) {
		super(props);
		this.state = {	
			visitor_data: null,
			logs: []
		};
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){	   
        if(this.props.modalOpen){
			if(prevProps.visitor_id!== this.props.visitor_id && this.props.visitor_id!=0){
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('visitor_id',this.props.visitor_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'visitor/get-visitor-logs',formData).then((res)=>{
				    var data = res.data;
					let logs=data.logs;
					let visitor_data=data.visitor_data;
					if(visitor_data.last_checkout==null){
						visitor_data.last_checkout=0;
					}
					this.setState({ 
						logs,
						visitor_data						
					});					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
            }
        }
    }
	
	render() {
		const visitor_data= this.state.visitor_data;
		let data = this.state.logs.map(row=>{
				let time_in='';
				let time_out='';
				if(parseInt(row.time_in)!==0){
					time_in = moment(row.time_in*1000).format("MM/DD/YYYY HH:mm");
				}
				if(parseInt(row.time_out)!==0){
					time_out = moment(row.time_out*1000).format("MM/DD/YYYY HH:mm");
				}
				row.time_in_str = time_in;
				row.time_out_str = time_out;
				return row;
			});
		if (this.state.search) {
			data = data.filter(row => {
				return row.time_in_str.includes(this.state.search) || row.time_out_str.includes(this.state.search)
			})
		}

		const dataTableColumns = [
			{
				Header: "Check In",
				accessor: "time_in_str",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Check Out",
				accessor: "time_out_str",
				Cell: props => <p className="text-muted mb-0">{props.value}</p>
			},
			{
				Header: "Feedback",
				accessor: "rating",
				Cell: props => {
					if(parseInt(props.original.time_out)!==0){
						return <div className="text-muted mb-0 small-size-rating">
							<Rating total={5} rating={parseInt(props.value)} interactive={false} />
							{props.original.feedback_message!=null ? <p>{props.original.feedback_message}</p>:""}
						</div>
					}else{
						return "";
					}
				}
			}
		];
 
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Visitor Logs
			</ModalHeader>
		 
			<ModalBody>
				{visitor_data!=null ? <div className="mb-3 task-members-wrapper">
					<Media>
						<Media left className="mr-2 ">
							<Media object width="100" title={visitor_data.name} src={visitor_data.profile_pic_url} alt={visitor_data.name} />										
							{parseInt(visitor_data.last_checkin)>0 && parseInt(visitor_data.last_checkout)===0 ? <div className="online-dot-user" title="Checked In"></div>:<div className="offline-dot-user" title="Checked Out"></div>}
						</Media>
					</Media>
					
					<h1 className="d-block mb-0 pb-0">{visitor_data.name}</h1>						  
					<span className="text-muted">{visitor_data.phone}</span>
					{visitor_data.visitor_custom_fields.map((r,index)=>{
						let final_value = r.value;
						if(final_value.startsWith('[')){
							let temp_value = JSON.parse(final_value);
							
							final_value = <ul style={{paddingLeft: '15px'}}>
							{temp_value.map(l=>{ return <li>{l}</li> })}
							</ul>
						}
						return(<Row className="mt-2">
							<Colxx sm="4" md="3">
								<span className="h8">{r.name}</span>
							</Colxx>
							<Colxx sm="8" md="9">
								<span className="h8">{final_value}</span>
							</Colxx>
						</Row>)
					})}
					{visitor_data.visitor_covid_fields.length ? <h2 className="mt-4 mb-2">Covid Questions</h2>:""}
					{visitor_data.visitor_covid_fields.map((r,index)=>{	
						let final_value = r.value;
						if(final_value.startsWith('[')){
							let temp_value = JSON.parse(final_value);
							
							final_value = <ul style={{paddingLeft: '15px'}}>
							{temp_value.map(l=>{ return <li>{l}</li> })}
							</ul>
						}
						return(<Row className="mt-3">
							<Colxx sm="12">
								<span className="h6">Q) {r.name}</span>
							</Colxx>
							<Colxx sm="12">
								<span className="h8">Ans) {final_value}</span>
							</Colxx>
						</Row>)
					})}
					<hr/>
				</div>:""}
				<div className="mb-4 dropdown-edit small-padd-table">
					<FormGroup row>
						<Colxx lg="6">
							<Input
							  type="text"
							  name="table_search"
							  id="table_search"
							  placeholder="Search"
							  value={this.state.search}
							  onChange={e => this.setState({search: e.target.value})}
							/>
						</Colxx>
					</FormGroup>
					<ReactTable
						data={data}
						columns={dataTableColumns}
						defaultPageSize={10}
						minRows = {2}
						filterable={false}
						showPageJump={false}
						PaginationComponent={DataTablePagination}
						showPageSizeOptions={true}
						defaultSorted={[{id: "send_on",desc: false}]}
					/>
				</div>
			</ModalBody>
		</Modal>
	  );
  }
};