import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);


class AddAdditiveModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.state = {	
		selectAdditiveType : [],
		selectAdd:[],
		};
	
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-additive").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("AdditiveForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_ids',this.props.plant_ids);
            
			
			axios.post(REST_API_END_POINT+'growhouse/add-additives-to-plants',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-additive").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-additive").disabled=false;
			});

		}
	}
  
    componentDidMount(){
		
		var additives=[];	
			
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'growhouse/get-additives',formData).then((res)=>{
				var data = res.data;
			
				additives=data.additives;
				
				additives = additives.map((element,index)=>{
				    element.label=element.name;
				    element.value=element.id;
				    element.key=index;
				    return element;
		        });
				this.setState({
					
					selectAdd:additives,
					
				});
	   }).catch(e =>{
			
     		console.log("Addition failed , Error ", e);
		});

	}

    render() {

    var {selectAdd} = this.state; 

	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
			  <ModalHeader toggle={this.props.toggleModal}>
				Add Additive
			  </ModalHeader>
			<AvForm
		        id="AdditiveForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Additive<span className="text-danger">*</span></Label>
							 <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="additive_id"
								id="additive_id"
								value={this.state.selectAdditiveType}						
								options={selectAdd}
								onChange={selectAdditiveType => { this.setState({ selectAdditiveType }) }}
							/>		
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Additive Weight<span className="text-danger">*</span></Label>
							 <AvField
								id="additive_wg"
								name="additive_wg"
								type="number"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									min: {
										value: "0",
										errorMessage: "Value should be greater than 0"
									}
								}}
							  />	
							</AvGroup>
						</Colxx>	
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Additive Cost ($)<span className="text-danger">*</span></Label>
							 <AvField
								id="additive_cst"
								name="additive_cst"
								type="number"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									},
									min:{
										value: "0",
										errorMessage: "Value should be greater than 0"
									}
								}}
							  />	
							</AvGroup>
						</Colxx>	
					</Row>						
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-additive" >
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
			  </ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default AddAdditiveModal;
