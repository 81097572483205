import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";

import { REST_API_END_POINT } from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";


export default class EditSubscriptionPlanModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	this.state={
		edit_data:null,
		recurring:true,
		visible_to_users:true,
		period_type:1,
	}
  }
  
	componentDidUpdate(prevProps, prevState) {
		if(typeof(this.props.subscription_id)==='string'&&this.props.subscription_id!==''&&prevProps.modalOpen===false){
			var formData = new FormData();
			formData.append('subscription_id',this.props.subscription_id);
			
			axios.post(REST_API_END_POINT+'admin/get-subscription-by-id',formData).then((res)=>{ 
				if(res.data.status==1){
					var edit_data = res.data.data;
					if(edit_data.cost_per_facility==null){
						edit_data.cost_per_facility= 0;
					}

					this.setState({
						edit_data,
						period_type:edit_data.period_type,
						recurring:edit_data.recurring==1 ? true:false,
						visible_to_users:edit_data.visible_to_users==1 ? true:false,
					});
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	}

  async handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
		document.getElementById("edit-subscription").disabled = true;
		var subscription = this.state.edit_data;
		
		var data_upload = new FormData(document.getElementById("edit_subscription_form"));
		data_upload.append('recurring', this.state.recurring ? 1:0 );
		data_upload.append('visible_to_users', this.state.visible_to_users ? 1:0 );
		data_upload.append('subscription_id',this.props.subscription_id);
				
		axios.post(REST_API_END_POINT+'admin/update-subscription',data_upload).then((data)=>{ 
			if(data.data.status==1){
				this.props.toggleModal();				
				this.props.dataListRender();				
				swal("Success!", "Subscription plan has been updated successfully!", "success");				
			}else if(data.data.exist){
				swal("Subscription plan already exist!", "You have already used this name for another subscription plan in our system.", "error");
			}else{
				swal("Failed to update subscription plan!", "Please try after sometime.", "error");			
			}
			document.getElementById("edit-subscription").disabled = false;
		}).catch(e => console.log("Addition failed , Error ", e));
		
      
    }
  }
  
	checkMimeType=(event)=>{
		//getting file object
		let files = event.target.files 
		//define message container
		let err = ''
		// list allow mime type
		const types = ['image/png', 'image/jpeg', 'image/gif']
		// loop access array
		for(let x = 0; x<files.length; x++) {
			// compare file type find doesn't matach
			if (types.every(type => files[x].type !== type)) {
			   // create error message and assign to container   
			   err += files[x].name+' is not a supported format\n';
			}
		};

		if (err !== '') { // if message not same old that mean has error 
			event.target.value = null;
			swal("Warning", err, "warning");	
			return false; 
		}
		return true;
	}
	
	checkFileSize=(event)=>{
		 let files = event.target.files
		 let size = 5000000 
		 let err = ""; 
		 for(var x = 0; x<files.length; x++) {
			 if (files[x].size > size) {
			  err += files[x].name+' is too large, please pick a file having size less than 5MB \n';
			}
		  };
		if (err !== '') {
		 event.target.value = null;
		 swal("Warning", err, "warning");
		 console.log(err)
		 return false
		}

		return true;

	}
  
  onChange = e => {
    switch (e.target.name) {
      case 'plan_image':
		
		if(e.target.files[0]){
			if(this.checkMimeType(e)&& this.checkFileSize(e)){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


  render() {
		var edit_data = this.state.edit_data;
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Subscription Plan
		  </ModalHeader>
		  <AvForm
				id="edit_subscription_form"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			
				<AvGroup className="error-t-negative">
				  <Label>Name</Label>
				  <AvField
					name="name"
					type="text"
					autoComplete="off"
					value={edit_data!=null ? edit_data.name:""}
					validate={{
					  required: {
						value: true,
						errorMessage: "Please enter the name"
					  },
					  pattern: {
						value: "^[a-zA-Z ,.'-]+$",
						errorMessage: "Invalid name"
					  },
					  minLength: {
						value: 2,
						errorMessage:
						  "Your name must be between 2 and 100 characters"
					  },
					  maxLength: {
						value: 100,
						errorMessage:
						  "Your name must be between 2 and 100 characters"
					  }
					}}
				  />
				</AvGroup>
				
				<AvGroup className="error-l-100 error-t-negative">
				  <Label>Price</Label>
				  <AvField
					name="price"
					type="text"
					autoComplete="off"
					value={edit_data!=null ? edit_data.price.toString():""}
					validate={{
					  number: {
						value: true,
						errorMessage: "Please enter a valid number"
					  },
					  required: {
						value: true,
						errorMessage: "Please enter the price"
					  },
					}}
				  />
				</AvGroup>
				
				<Row>
					<Colxx md="6">
						<AvGroup className="error-l-100 error-t-negative">
						  <Label>Period</Label>
						  <AvField
							name="period"
							type="text"
							autoComplete="off"
							value={edit_data!=null ? edit_data.period:""}
							validate={{
							  number: {
								value: true,
								errorMessage: "Please enter a valid number"
							  },
							  required: {
								value: true,
								errorMessage: "Please enter the period"
							  },
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx md="6">
						<AvGroup className="error-l-100 error-t-negative">
						  <Label>&nbsp;</Label>
						  <AvField
							name="period_type"
							type="select"
							autoComplete="off"
							value={this.state.period_type}
							onChange={(e)=>{ this.setState({ period_type:e.target.value }) }}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select the period type"
							  },
							}}
						  >
							<option value="1">Days</option>
							<option value="2">Months</option>
							<option value="3">Years</option>
						  </AvField>
						</AvGroup>
					</Colxx>
				</Row>
				
				<AvGroup className="error-t-negative">
				  <Label>Short Description</Label>
				  <AvField
					name="description"
					type="textarea"
					rows="4"
					autoComplete="off"
					value={edit_data!=null ? edit_data.description:""}
					validate={{
						required: {
							value: true,
							errorMessage: "Please enter the description"
						},
					}}
				  />
				</AvGroup>
				
				<AvGroup className="error-t-negative">
				  <Label>Long Description</Label>
				  <AvField
					name="long_description"
					type="textarea"
					rows="6"
					autoComplete="off"
					value={edit_data!=null ? edit_data.long_description:""}
					validate={{
						required: {
							value: true,
							errorMessage: "Please enter the long description"
						},
					}}
				  />
				</AvGroup>
				
				<AvGroup className="error-t-negative">
					<Label>Recurring Payment</Label><br/>
					<Label>													  
						<Switch
							className="custom-switch custom-switch-primary im-inline-block"
							checked={this.state.recurring}
							onChange={(recurring)=>{ this.setState({ recurring }) }}
						/>
					</Label>
				</AvGroup>
				
				<AvGroup className="error-t-negative">
					<Label>Visible to Users</Label><br/>
					<Label>													  
						<Switch
							className="custom-switch custom-switch-primary im-inline-block"
							checked={this.state.visible_to_users}
							onChange={(visible_to_users)=>{ this.setState({ visible_to_users }) }}
						/>
					</Label>
				</AvGroup>
				
				<AvGroup className="error-l-125 error-t-negative">
					<Label>Image</Label>
					<InputGroup className="mb-3">
					  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
					  <CustomInput
						type="file"
						name="plan_image"
						id="plan_image"
						accept="image/png, image/jpeg, image/gif"
						onChange={this.onChange}
					  />
					</InputGroup>
				</AvGroup>
				
				<Row>
					<Colxx md="12">
						<AvGroup className="error-l-100 error-t-negative">
							<Label>Cost for additional facility</Label>
							<AvField
								name="cost_per_facility"
								type="text"
								autoComplete="off"
								value={edit_data!=null ? edit_data.cost_per_facility.toString():""}
								validate={{
								  number: {
									value: true,
									errorMessage: "Please enter a valid number"
								  }
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
			 
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="edit-subscription" >
			  <IntlMessages id="pages.submit" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="pages.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
