import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu,  FormGroup, Input, CustomInput, Label } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import axios from 'axios';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor} from "../../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class FooterManagement extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			configurations: {},
			blocking:true
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.updateConfiguration = this.updateConfiguration.bind(this);
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{ 
			this.setState({ configurations: res.data.configurations, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	updateConfiguration = (event, errors, values)  => {
		if (errors.length === 0) {
			document.getElementById("update-configurations").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			for(let key in values){
				formData.append(key,values[key]);
			}
			
			axios.post(REST_API_END_POINT+'website/update-configurations',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				document.getElementById("update-configurations").disabled=false;
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-configurations").disabled=false;
			});
		}
	}
	
		
	render() {
		
		const configurations = this.state.configurations	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.footer-management" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">								
								<CardBody>
									<Row>	
										<Colxx xxs="12">
											<div id="api_message"></div>
										</Colxx> 
										<Colxx xxs="12">
											<AvForm												
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.updateConfiguration}
											  >
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>About Us<span className="text-danger">*</span></Label>
															<AvField
																id="footer_about"
																name="footer_about"
																type="textarea"
																autoComplete="off"
																rows="4"
																value={configurations.footer_about}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Locations<span className="text-danger">*</span></Label>
															<AvField
																id="website_locations"
																name="website_locations"
																type="textarea"
																autoComplete="off"
																rows="4"
																value={configurations.website_locations}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Working Hours<span className="text-danger">*</span></Label>
															<AvField
																id="working_hours"
																name="working_hours"
																type="textarea"
																autoComplete="off"
																rows="4"
																value={configurations.working_hours}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Twitter Link<span className="text-danger">*</span></Label>
															<AvField
																id="social_twitter_link"
																name="social_twitter_link"
																type="text"
																autoComplete="off"
																value={configurations.social_twitter_link}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Facebook Link<span className="text-danger">*</span></Label>
															<AvField
																id="social_facebook_link"
																name="social_facebook_link"
																type="text"
																autoComplete="off"
																value={configurations.social_facebook_link}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Instagram Link<span className="text-danger">*</span></Label>
															<AvField
																id="social_instagram_link"
																name="social_instagram_link"
																type="text"
																autoComplete="off"
																value={configurations.social_instagram_link}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<AvGroup className="error-t-negative">
															<Label>Linkedin Link<span className="text-danger">*</span></Label>
															<AvField
																id="social_linkedin_link"
																name="social_linkedin_link"
																type="text"
																autoComplete="off"
																value={configurations.social_linkedin_link}
																validate={{
																	required: {
																		value: true,
																		errorMessage: "This field is required"
																	}
																}}
															/>
														</AvGroup>
													</Colxx>
												</Row>
												<Row>
													<Colxx lg="12">
														<Button color="primary" id="update-configurations" >Update</Button>
													</Colxx>
												</Row>
										   </AvForm>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
