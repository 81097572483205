import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Customers from "../../../components/customer/Customers";
import AddGetStarted from "../../../containers/customer/AddGetStarted";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import TemplateApplicationMenu from "../../../containers/template/TemplateApplicationMenu";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddNewDistributionModal from "../../../containers/accounts/AddNewDistributionModal";
import EditDistributionModal from "../../../containers/accounts/EditDistributionModal";
import ReactTable from "react-table";

import data from "../../../data/kitchen";

export default class Kitchen extends Component {
	
	constructor(props) {
		super(props);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		 modalOpenAdd: false,
		 modalOpenEdit: false,
		  modalOpenStaff: false,
		  data:data,
		  search: '',
		};
	}
	
	 toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
     
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
		
	  {
		Header: "",
		accessor: "",
		width:50,
		Cell: props => <FormGroup><CustomInput type="checkbox" id="exampleCustomCheckbox"/></FormGroup>
	  },
	  {
		Header: "Customer",
		accessor: "customer",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Order",
		accessor: "order",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Wholesale Type",
		accessor: "wholesaletype",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Payment Type",
		accessor: "paymenttype",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Grand Total",
		accessor: "grandtotal",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Direction",
		accessor: "direction",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Order Date",
		accessor: "orderdate",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Delivery Date",
		accessor: "deliverydate",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Status",
		accessor: "",
		Cell: props => <Fragment> <Button color="primary" size="sm" className="" onClick={this.toggleModalEdit}>Assign Driver</Button></Fragment>
	  },
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.transportation" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}
					</div>
					 <EditDistributionModal
							modalOpen={this.state.modalOpenEdit}
							toggleModal={this.toggleModalEdit}
						  />
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={2}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
			  </Colxx>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
