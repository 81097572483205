import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from "moment";
import { ToWords } from 'to-words';

import { zeroPad, getCurrencySymbol, getCurrencyCode } from "../../helpers/Utils";
//import number_format from "number_format-php";
import { REST_API_BASE_URL } from "../../constants/defaultValues";

function number_format(value,decimal,decimalSep,thouSpe){
	return parseFloat(value).toLocaleString('en-IN', { minimumFractionDigits: decimal, maximumFractionDigits: decimal });
}

export default class PrintShippingManifestModalContent extends Component {
  
	getInvoiceHead(){
		const {
		  transfer_details,
		  products_details,
		  delivery_details,
		  order_details,
		  driver_details,
		  signature,
		  facility_data,
		} = this.props;
		
		let invoiceHead = <div className="header-row">
                  <div className="head">
                    <table className="table-layout">
                      <tbody>
                        {/* #1 */}
                        <tr>
                          <td className="seller-info" rowSpan={3} valign="top">
                            <strong className="text-uppercase">{facility_data['facility_name']!=null ? facility_data['facility_name'].toUpperCase():""}</strong><br/>
							(ISO 9001:2015 CERTIFIED COMPANY)<br/>
							<div dangerouslySetInnerHTML={{	__html:facility_data['facility_address']!=null ? facility_data['facility_address'].toUpperCase().split(',').join("<br/>"):""}} />
							{facility_data['facility_zip_code']!=null ? <Fragment>{facility_data['facility_zip_code']}<br/></Fragment>:""}
							{facility_data['gstin']!=null ? <Fragment>{'GSTIN/UIN : '+facility_data['gstin']}<br/></Fragment>:""}
							{facility_data['facility_state_name']!=null ? <Fragment>{'State Name : '+facility_data['facility_state_name']}, {'Code : '+facility_data['facility_state_code']}<br/></Fragment>:""}
							{facility_data['facility_phone']!=null ? <Fragment>{'Contact : '+facility_data['facility_phone']}<br/></Fragment>:""}
							{facility_data['facility_email']!=null ? <Fragment>{'E-Mail : '+facility_data['facility_email']}<br/></Fragment>:""}
							
                          </td>
                          <td className="header second">
                            <div className="">
                              <p>Invoice No.</p>
                              <h5
                                style={{ marginBottom: "0px", fontWeight: 700 }}
                              >
                                EA{transfer_details['order_number']}
                              </h5>
                            </div>
                          </td>
                          <td className="header third">
                            <div className="">
                              <p>Dated</p>
                              <h5
                                style={{ marginBottom: "0px", fontWeight: 700 }}
                              >
								{moment(transfer_details['timestamp']*1000).format("DD-MMM-YYYY")}
                              </h5>
                            </div>
                          </td>
                        </tr>
                        {/* #2 */}
                        <tr>
                          <td className="header second">
                            <div className="">
                              <p style={{ marginBottom: "20px" }}>
                                Delivery Note.
                              </p>
                            </div>
                          </td>
                          <td className="header third">
                            <div className="">
                              <p></p>
                              <h5
                                style={{ marginBottom: "0px", fontWeight: 700 }}
                              ></h5>
                            </div>
                          </td>
                        </tr>

                        {/* #3 */}
                        <tr>
                          <td className="header second">
                            <div className="">
                              <p style={{ marginBottom: "20px" }}>
                                Suppliers's Ref
                              </p>
                            </div>
                          </td>
                          <td className="header third">
                            <div className="">
                              <p style={{ marginBottom: "20px" }}>
                                Other Reference(s)
                              </p>
                            </div>
                          </td>
                        </tr>

                        {/* #4 */}
                        <tr>
                          <td className="seller-info" rowSpan={4} valign="top">
                            Buyer<br/>
							<strong>{order_details['customer_name']!=null ? order_details['customer_name']:""}</strong><br/>
							{order_details['shipping_address']!=null ? order_details['shipping_address']:""}<br/>
							{order_details['customer_phone']!=null ? <Fragment>{'PH : '+order_details['customer_phone']}<br/></Fragment>:""}
							{order_details['gstin']!=null ? <Fragment>{'GSTIN/UIN : '+order_details['gstin']}<br/></Fragment>:""}
							{order_details['customer_state_name']!=null ? <Fragment>{'State Name : '+order_details['customer_state_name']}, {'Code : '+facility_data['facility_state_code']}<br/></Fragment>:""}
							{order_details['customer_state_name']!=null ? <Fragment>{'Place of Supply : '+order_details['customer_state_name']}</Fragment>:""}
						  </td>
                          <td className="header second">
                            <div className="">
                              <p style={{ marginBottom: "20px" }}>
                                Buyers Order No.
                              </p>
                            </div>
                          </td>
                          <td className="header third">
                            <div className="">
                              <p style={{ marginBottom: "20px" }}>Dated.</p>
                            </div>
                          </td>
                        </tr>

                        {/* #5 */}
                        <tr>
                          <td className="header second">
                            <div className="">
                              <p style={{ marginBottom: "20px" }}>
                                Despatch Document no.
                              </p>
                            </div>
                          </td>
                          <td className="header third">
                            <div className="">
                              <p style={{ marginBottom: "20px" }}>
                                Delivery Note Date.
                              </p>
                            </div>
                          </td>
                        </tr>

                        {/* #6 */}
                        <tr>
                          <td className="header second">
                            <div className="">
                              <p>Despatched Through</p>
                              <h5
                                style={{ marginBottom: "0px", fontWeight: 700 }}
                              >
                                Road
                              </h5>
                            </div>
                          </td>
                          <td className="header third">
                            <div className="">
                              <p>Destination</p>
                              <h5
                                style={{ marginBottom: "0px", fontWeight: 700 }}
                              >
                                Trivandrum
                              </h5>
                            </div>
                          </td>
                        </tr>

                        {/* #7 */}
                        <tr>
                          <td className="header second">
                            <div className="">
                              <p>Bill of Lading/LR-RR No.</p>
                              <h5
                                style={{ marginBottom: "0px", fontWeight: 700 }}
                              >
                                dt. {moment(transfer_details['timestamp']*1000).format("DD-MMM-YYYY")}
                              </h5>
                            </div>
                          </td>
                          <td className="header third">
                            <div className="">
                              <p>Motor Vehicle No</p>
                              <h5
                                style={{ marginBottom: "0px", fontWeight: 700 }}
                              >
                                {delivery_details['no_plate']!=null ? delivery_details['no_plate']:""}
                              </h5>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>;
		return invoiceHead;
	}
  
  render() {
    const {
      transfer_details,
      products_details,
      delivery_details,
      order_details,
      driver_details,
      signature,
      facility_data,
    } = this.props;
    var current_currency_symbol = getCurrencySymbol();
    var current_currency_code = getCurrencyCode();
	const toWords = new ToWords();
    var sales_tax=0;
	var sgst_tax=0;
	var cgst_tax=0;
	var sub_total=0;
	var p_discount=0;
	var total_quantity_count=0;
	let is_utgst = false;
	let is_igst = false;
	let taxable = {};
	let taxable_table = [];
	let total_taxable_value=0;
	let total_cgst_value=0;
	let total_sgst_value=0;
	let total_sales_tax_value=0;
	let total_tax_value=0;
	
    if (transfer_details == null) {
      return "";
    }
	
	if(order_details!=null){
		is_igst = order_details.customer_state_id !== facility_data.facility_state_id;
		if(!is_igst){
			is_utgst = order_details.is_utgst=="1";
		}
	}
	
	if(products_details.length){
		for(let row of products_details){
			sales_tax+=parseFloat(row.product_sales_tax);
			sgst_tax+=parseFloat(row.product_sgst_tax);
			cgst_tax+=parseFloat(row.product_cgst_tax);
			p_discount+=parseFloat(row.product_discount)+parseFloat(row.coupon_discount);
			let line_total=parseFloat(row.product_unit_price)*parseFloat(row.product_qty)+(parseFloat(row.product_excise_tax)+parseFloat(row.product_sales_tax)-parseFloat(row.product_discount)-parseFloat(row.coupon_discount))
			if(row.variation_id==order_details.discount_variation_id){
				line_total-=parseFloat(order_details.manual_discount);
			}
			sub_total+=parseFloat(row.product_unit_price)*parseFloat(row.product_qty);
			
			total_quantity_count+=parseFloat(row.product_qty);
			
			if(typeof(taxable[row.hsn_code])=='undefined'){
				taxable[row.hsn_code] = {
					hsn_code:row.hsn_code,
					taxable_value:0,
					cgst_rate:0,
					cgst_value:0,
					sgst_rate:0,
					sgst_value:0,
					sales_tax_rate:0,
					sales_tax_value:0,
					total_tax_value:0
				}
			}
			
			let taxable_value=parseFloat(row.product_unit_price)*parseFloat(row.product_qty)-parseFloat(row.product_discount)-parseFloat(row.coupon_discount);
			if(row.variation_id==order_details.discount_variation_id){
				taxable_value-=parseFloat(order_details.manual_discount);
			}
			
			taxable[row.hsn_code].taxable_value = taxable_value;
			taxable[row.hsn_code].cgst_rate = parseFloat(row.product_unit_price)==0 ? 0:parseFloat((parseFloat(row.product_cgst_tax)/(parseFloat(row.product_unit_price)*parseFloat(row.product_qty))*100).toFixed(2));
			taxable[row.hsn_code].cgst_value = parseFloat(row.product_cgst_tax);
			taxable[row.hsn_code].sgst_rate = parseFloat(row.product_unit_price)==0 ? 0:parseFloat((parseFloat(row.product_sgst_tax)/(parseFloat(row.product_unit_price)*parseFloat(row.product_qty))*100).toFixed(2));
			taxable[row.hsn_code].sgst_value = parseFloat(row.product_sgst_tax);
			taxable[row.hsn_code].sales_tax_rate = parseFloat(row.product_unit_price)==0 ? 0:parseFloat((parseFloat(row.product_sales_tax)/(parseFloat(row.product_unit_price)*parseFloat(row.product_qty))*100).toFixed(2));
			taxable[row.hsn_code].sales_tax_value = parseFloat(row.product_sales_tax);
			taxable[row.hsn_code].total_tax_value = parseFloat(row.product_sales_tax);
			
			total_taxable_value+=taxable[row.hsn_code].taxable_value;
			total_cgst_value+=taxable[row.hsn_code].cgst_value;
			total_sgst_value+=taxable[row.hsn_code].sgst_value;
			total_tax_value+=taxable[row.hsn_code].total_tax_value;
		}
	}
	
	for(let hsn_code in taxable){
		let row = taxable[hsn_code];
		taxable_table.push(<tr className="totals" key={hsn_code}>
                        <td>{row.hsn_code}</td>
						<td className="align-right">{number_format(row.taxable_value,2,'.','')}</td>
						{is_igst ? <Fragment>							
							<td className="align-right">{row.sales_tax_rate}%</td>
							<td className="align-right">{number_format(row.sales_tax_value,2,'.','')}</td>
						</Fragment>:<Fragment>							
							<td className="align-right">{row.cgst_rate}%</td>
							<td className="align-right">{number_format(row.cgst_value,2,'.','')}</td>
							<td className="align-right">{row.sgst_rate}%</td>
							<td className="align-right">{number_format(row.sgst_value,2,'.','')}</td>
						</Fragment>}
                        <td className="align-right">{number_format(row.total_tax_value,2,'.','')}</td>
                      </tr>)
	}
	
	let grandTotal = parseFloat(order_details.total)-parseFloat(p_discount);
	let roundOff = parseFloat(grandTotal.toFixed(0))-grandTotal;
	
	let total_content_rows = taxable_table.length+6;
	let total_product_rows = 0;
	
	for(let row of products_details){
		total_product_rows+=1;
		if(row.product_per_box!="1"){
			total_product_rows+=2;
		}
	}
	total_content_rows+=total_product_rows;
	
    var page_count = Math.ceil(total_content_rows / 24);
	
	const lastSection = <Fragment>
			<div className="sales-row" style={{marginTop:'0px'}}>
				<table className="table-layout">
                    <tbody>
                      <tr className="totals">
                        <td style={{ width: "100%", padding: "0px 8px" }}>
                          <div>
                            <p>Amount chargable (in words)</p>
                            <h5 className="bold">
								{current_currency_code} {toWords.convert(parseFloat(grandTotal.toFixed(0)), { currency: true })}
                            </h5>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                </table>

                  <table className="table-layout footer-table">
                    <thead>
                      <tr>
                        <td rowSpan={2} style={{ width: "40%" }}>
                          HSN/SAC
                        </td>
                        <td rowSpan={2}>Taxable Value</td>
                        {is_igst ? <td colSpan={2}>Integrated Tax</td>:<Fragment>
							<td colSpan={2}>Central Tax</td>
							<td colSpan={2}>State Tax</td>
						</Fragment>}
                        <td rowSpan={2}>Total Tax Amount</td>
                      </tr>
                      <tr>
						{!is_igst ? <Fragment>
							<td>Rate</td>
							<td>Amount</td>
						</Fragment>:""}
                        <td>Rate</td>
                        <td>Amount</td>
                      </tr>
                    </thead>
                    <tbody>
						{taxable_table}                      
                    </tbody>
                    <tfoot>
						<tr>
						  <td className="align-right">Total</td>
						  <td className="align-right">{number_format(total_taxable_value,2,'.','')}</td>
						  {is_igst ? <Fragment>
								<td className="align-right"></td>
								<td className="align-right">{number_format(total_tax_value,2,'.','')}</td>
							</Fragment>:<Fragment>
								<td className="align-right"></td>
								<td className="align-right">{number_format(total_cgst_value,2,'.','')}</td>
								<td className="align-right"></td>
								<td className="align-right">{number_format(total_sgst_value,2,'.','')}</td>
							</Fragment>}
						  <td className="align-right">{number_format(total_tax_value,2,'.','')}</td>
						</tr>
                    </tfoot>
                  </table>
                </div>

                <div className="clearfix"></div>
				
				<div className="tail">
                  <span>
                    <p style={{ display: "inline-block" }}>
                      Tax Amount (in words) :{" "}
                    </p>
                    <h6 className="bold" style={{ display: "inline-block" }}>
						{current_currency_code} {toWords.convert(total_tax_value, { currency: true })}
                    </h6>
                  </span>
                  <div style={{ display: "flex", marginTop: "32px" }}>
                    <div style={{ width: "50%" }}>
                      <h6>Declaration</h6>
                      <p>
                        We declare that this invoice shows the actual price of
                        the goods described and that all particulars are true and correct.
                        <br />
                        Terms and Condition :
                      </p>
                      <ol style={{ padding: "12px", paddingTop:0 }}>
                        <li>
                          Payment with in 15 days from the date of receipt of
                          goods.
                        </li>
                        <li>
                          Payment should be in Crossed Cheques/ RTGS/ NEFT.in
                          favour of the Company.
                        </li>
                      </ol>
                    </div>
                    <div style={{ width: "50%" }}>
                      <h6>Company's Bank Details</h6>
                      <table>
						<tbody>
                        <tr>
                          <td>
                            Bank Name{" "}
                            <span
                              style={{ float: "right", marginLeft: "15px" }}
                            >
                              :
                            </span>
                          </td>
                          <td className="bold">{facility_data['bank_name']!=null ? facility_data['bank_name']:""}</td>
                        </tr>
                        <tr>
                          <td>
                            A/c No{" "}
                            <span
                              style={{ float: "right", marginLeft: "15px" }}
                            >
                              :
                            </span>
                          </td>
                          <td className="bold">{facility_data['bank_account_no']!=null ? facility_data['bank_account_no']:""}</td>
                        </tr>
                        <tr>
                          <td>
                            Branch & IFS Code{" "}
                            <span
                              style={{ float: "right", marginLeft: "15px" }}
                            >
                              :
                            </span>
                          </td>
                          <td className="bold">{facility_data['branch_ifsc']!=null ? facility_data['branch_ifsc']:""}</td>
                        </tr>
                        </tbody>
                      </table>
                      <div
                        style={{
                          height: "85px",
                          width: "100%",
                          borderLeft: "1px solid #000",
                          borderTop: "1px solid #000",
                          marginTop: "10px",
                          padding: "5px",
                        }}
                      >
                        <div style={{ textAlign: "right" }}>
                          <p className="bold" style={{ marginBottom: "35px" }}>
                            for {facility_data['facility_name']!=null ? facility_data['facility_name'].toUpperCase():""}
                          </p>
                          <p> Authorised Signatory</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
		</Fragment>;
		
		
    var additional_pages = [];
	
	if(page_count>0){
		var current_page=1; 
		var start_i=0;	
		var last_i=start_i+(total_product_rows>34 ? 34:(current_page==page_count ? 12:22));
		var show_total=false;
		
		for(let j=0;j<page_count;j++){
			let products_6=[];
			let additional_rows=[];
			for(let i=start_i;i<last_i;i++){
				if(typeof products_details[i]!='undefined'){
					let row = products_details[i];
					
					let line_total=parseFloat(row.product_unit_price)*parseFloat(row.product_qty)-parseFloat(row.product_discount)-parseFloat(row.coupon_discount)
							
					if(row.variation_id==order_details.discount_variation_id){
						line_total-=parseFloat(order_details.manual_discount);
					}
					
					let discount_col = row.variation_id!=order_details.discount_variation_id ? number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount),2,'.',''):number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount)+parseFloat(order_details.manual_discount),2,'.','');

						
					products_6.push(<tr key={i}>
							<td>{i+1}</td>
							<td className="bold">{row.product_name} {row.variation_name!=null ? "("+row.variation_name+")":""}</td>
							<td>{row.hsn_code}</td>
							<td className="align-right">{number_format(parseFloat(row.product_mrp_price),2,'.','')}/nos</td>
							<td className="align-right">
								<div className="rel">
									<p className="bold">{number_format(parseFloat(row.product_qty),3,'.','')} nos</p>
									{row.product_per_box!="1" ? <p className="box-count">({number_format(parseFloat(row['product_qty']/row['product_per_box']).toFixed(3),3,'.','')} box)</p>:""}
								</div>
							</td>
							<td className="align-right">{number_format(parseFloat(row.product_unit_price),2,'.','')}</td>
							<td>nos</td>
							<td className="align-right">{parseFloat(discount_col)>0 ? discount_col:""}</td>
							<td className="bold align-right">{number_format(line_total,2,'.','')}</td>
						</tr>);
					total_product_rows-=1;
					if(row.product_per_box!="1"){
						total_product_rows-=2;
					}
				}else{
					additional_rows.push(<tr key={i}>
							<td>&nbsp;</td>
							<td className="bold">&nbsp;</td>
							<td></td>
							<td className="align-right">&nbsp;</td>
							<td className="align-right">&nbsp;</td>
							<td className="align-right">&nbsp;</td>
							<td>&nbsp;</td>
							<td className="align-right">&nbsp;</td>
							<td className="bold align-right">&nbsp;</td>
						</tr>);
				}
			}
			
			if(total_product_rows<=34){
				if(current_page<page_count && total_product_rows<20){
					show_total=true;
				}else if(current_page==page_count && !show_total){
					show_total=true;
				}else if(current_page==page_count && show_total){
					show_total=false;
				}
			}
	
			additional_pages.push(<div
            id="p1"
            className="pageArea"
            style={{
              overflow: "hidden",
              position: "relative",
              marginTop: "20px",
              marginLeft: "auto",
              marginRight: "auto",
              background: "#fff",
            }}
          >
            <div className="content-wrapper">
              <div className="page_head">Tax Invoice {current_page!=1 ? "(Page "+current_page+")":""}</div>

              <div className="clearfix">
				{this.getInvoiceHead()}

                <div className="clearfix"></div>

                {/* TOC */}

                <div className="clearfix"></div>

                <div className="sales-row">
                  <table className="table-layout information2">
                    <thead>
                      <tr>
                        <td>Sl.no.</td>
                        <td>Description of Goods</td>
                        <td>HSN/SAC</td>
                        <td>MRP/ Marginal</td>
                        <td>Quantity</td>
                        <td>Rate</td>
                        <td>per</td>
                        <td>Disc. %</td>
                        <td>Amount</td>
                      </tr>
                    </thead>
                    <tbody>
						{products_6}

						{show_total ? <tr rowSpan={2}>
							<td></td>
							<td className="bold align-right i"></td>
							<td></td>
							<td className="align-right"></td>
							<td className="align-right">
							  <div className="rel">
								<p className="bold"></p>
							  </div>
							</td>
							<td className="align-right"></td>
							<td></td>
							<td> </td>
							<td className="bold align-right" >
							  <p className="total" style={{borderTop:'1px solid #000', fontWeight:500}}>{number_format(sub_total,2,'.','')}</p>
							</td>
						</tr>:""}
						
						{show_total ? (is_igst ? <tr>
								<td></td>
								<td className="bold align-right i">Outward IGST</td>
								<td></td>
								<td className="align-right"></td>
								<td className="align-right">
								  <div className="rel">
									<p className="bold"></p>
								  </div>
								</td>
								<td className="align-right"></td>
								<td></td>
								<td> </td>
								<td className="bold align-right">{number_format(sales_tax,2,'.','')}</td>
						  </tr>:<Fragment>
								<tr>
									<td></td>
									<td className="bold align-right i">Outward CGST</td>
									<td></td>
									<td className="align-right"></td>
									<td className="align-right">
									  <div className="rel">
										<p className="bold"></p>
									  </div>
									</td>
									<td className="align-right"></td>
									<td></td>
									<td> </td>
									<td className="bold align-right">{number_format(cgst_tax,2,'.','')}</td>
								</tr>
								<tr>
									<td></td>
									<td className="bold align-right i">Outward {is_utgst ? "UTGST":"SGST"}</td>
									<td></td>
									<td className="align-right"></td>
									<td className="align-right">
									  <div className="rel">
										<p className="bold"></p>
									  </div>
									</td>
									<td className="align-right"></td>
									<td></td>
									<td> </td>
									<td className="bold align-right">{number_format(sgst_tax,2,'.','')}</td>
								</tr>
						</Fragment>):""}                      
                      
                      {current_page==page_count ? (roundOff!=0 ? <tr>
                        <td></td>
                        <td className="bold align-right i">RoundOff</td>
                        <td></td>
                        <td className="align-right"></td>
                        <td className="align-right">
                          <div className="rel">
                            <p className="bold"></p>
                          </div>
                        </td>
                        <td className="align-right"></td>
                        <td></td>
                        <td> </td>
                        <td className="bold align-right">{number_format(roundOff,3,'.','')}</td>
                      </tr>:""):""}
					  
                      {total_product_rows>34 ? "":(current_page==page_count || total_product_rows<20 ? additional_rows:"")}
                    </tbody>
                    {total_product_rows>34 ? <tfoot>
						<tr>
							<td valign="center" colSpan={9} className="align-right" style={{ height: "50px" }} >continued ...</td>
						</tr>
                    </tfoot>:(current_page==page_count ? <tfoot>
						<tr>
							<td></td>
							<td className="bold align-right i">Total</td>
							<td></td>
							<td className="align-right"></td>
							<td className="align-right">
								<div className="rel">
									<p className="bold">{number_format(total_quantity_count,3,'.','')}</p>
								</div>
							</td>
							<td className="align-right"></td>
							<td></td>
							<td> </td>
							<td
								className="bold align-right "
								style={{ fontSize: "16px" }}
							>
								{number_format(parseFloat(grandTotal.toFixed(0)),2,'.','')}
							</td>
						</tr>
                    </tfoot>:<tfoot>
						<tr>
							<td valign="center" colSpan={9} className="align-right" style={{ height: "50px" }} >continued ...</td>
						</tr>
                    </tfoot>)}
                  </table>
				</div>
				
                  {current_page==page_count ? lastSection:""}
                
              </div>
            </div>
          </div>);
			
			current_page++;
			start_i=last_i;
			last_i=start_i+(total_product_rows>34 ? 34:(current_page==page_count ? (18-taxable_table.length):22));
			
		}
	}

    return (
      <div>
        <style
          id="fonts1"
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `
					@font-face {
						font-family: TwCenMT-Regular_1a0;
						src: url("${REST_API_BASE_URL}administrator/manifest/fonts/TwCenMT-Regular_1a0.woff") format("woff");
					}

					@font-face {
						font-family: TwCenMT-Bold_19-;
						src: url("${REST_API_BASE_URL}administrator/manifest/fonts/TwCenMT-Bold_19-.woff") format("woff");
					}

					@font-face {
						font-family: TwCenMT-BoldItalic_1a2;
						src: url("${REST_API_BASE_URL}administrator/manifest/fonts/TwCenMT-BoldItalic_1a2.woff") format("woff");
					}
				`,
          }}
        />

        <style
          id="body_style"
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: `
					body{padding:0px; margin:0px; font-family:Arial, Helvetica, sans-serif;}

					@page {
						size:A4;
						margin:0;
					}

					@media print{
						body{
							background-color:#fff;
						}
					}
					
					.pageArea{ width: 935px; height: 1304px; -webkit-print-color-adjust: exact;}
					.pageArea *{ color: #000!important; }
					.pageArea:last-child{ height: 1300px; }
					.content-wrapper{ margin-top: 30px; margin-left: 46px; margin-right:55px; }
					.clearfix{ clear:both; }
					.bold{font-weight:700;}
					.align-right{text-align:right !important;}
					p{ margin-bottom:0px; }
					.pageArea p{ font-family: Arial, Helvetica, sans-serif;}
					
					.i{font-style: italics;}
					thead td {text-align: center !important;}

					.page_head{ font-size: 24px; font-weight: 700; text-align: center;margin-bottom: 20px; }

					.header-row{ margin-top: 15px; }
					.header-row:first-child{ margin-top: 2px; }
					.header-row .head{ width:100% }
					.header-row p{ margin-bottom: 5px; font-size: 12px; }
					.header-row h5{ font-size: 14px; }
					
					.table-layout {	border-collapse: collapse; width:100%; }					
					.table-layout th,.table-layout td{ border: 1px solid #000; text-align: left;}
					.invoice_number_text{ font-size: 15px; line-height: 1; font-family: TwCenMT-Bold_19-; color: rgb(0,0,0); padding: 4px 7px; text-align: left; background:#f1f1f1; width: 142px; }
					.invoice_number{ font-size: 21px; color:#000; line-height: 1.6; font-family:Arial, Helvetica, sans-serif; padding: 4px 7px; text-align: left; width:230px;}
					.invoice_number2{ font-size: 21px; color:#000; font-weight: 700; font-family:Arial, Helvetica, sans-serif; padding: 4px 7px; text-align: left; width:212px;}

					.page_number{ font-size: 20px; font-weight: 700; font-family:Arial, Helvetica, sans-serif; padding: 4px 7px; text-align: center !important; width:30px; }
					.page_number_of{ width:30px; }
					.page_number_total{ width:120px; height:30px;}

					.seller-info{ font-size: 14px; padding: 0px 4px; line-height: 1; }
					.header.second{ width:22.5%;  padding: 0px 4px; height:44px; }
					.header.third{ width:22.5%; padding: 0px 4px; height:44px; }
					

					.tick-box{ border:2px solid #000; width:28px; height:25px; float:left; text-align: center; margin-right:4px; padding-top:4px; line-height: 1; }
					.tick-box:last-child{ margin-right:0; }
					.tick-box2{ border:2px solid #000; width:30px; height:19px; float:left; margin-bottom:1px; text-align: center; line-height: 1; margin-right: 3px; }

					.left{ float:left; margin-right:25px;}
					.left:last-child{ margin-right:0; }

					.date_format{ width:120px; font-weight:700; padding-left:6px; line-height: 1; }
					.time_format{ width:115px; font-family:TwCenMT-Bold_19-; font-size: 14px;}
					.time_format .am{ float:left; margin-right:4px; font-weight:700;}
					.time_format .time{ float:left; width:52px; height:40px; padding-left:6px; line-height: 40px; font-size: 18px; font-family:Arial, Helvetica, sans-serif; font-weight:700; }

					.information th{ width:389px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.information td{ text-align:left; padding: 3px 6px; font-size: 15px; font-family: TwCenMT-Regular_1a0; color: #000;}
					.information td.first{ width:140px; }
					.information td.second{ width:230px; font-size: 13px; font-family:Arial, Helvetica, sans-serif;}
					.information td.third{ width:25px; padding:0; border-bottom:0; border-top:0;}

					.sales-row{ margin-top: 10px; }
					.sales-row td{ vertical-align: top !important; }
					.sales-row tbody td{ vertical-align: top !important; border-top: 1px solid transparent;border-bottom: 1px solid transparent; }
					.sales-row td .box-count{ margin-top: 5px; margin-bottom:0px; font-size: 12px; line-height: 1.3; }
					.sales-row .total{margin-bottom: 10px !important;}
					.sales-row  p{font-size: 12px !important; margin-bottom:0px !important;}
					.sales-row tfoot td{ vertical-align: top !important; border: 1px solid #000; }
					.sales-row tbody tr:last-of-type td{ vertical-align: top !important; border: 1px solid #000; }
					.sales-row tbody .totals td{ vertical-align: top !important; border-top: transparent !important; border-bottom: transparent !important;  }

					.information2 th{ width:389px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.information2 td{  padding: 0px 6px; font-size: 12px; color: #000;}
					.information2 td.first{ width:143px; }
					.information2 td.second{ width:233px; font-size: 13px; font-family:Arial, Helvetica, sans-serif;}
					.information2 td.third{ width:25px; padding:0; border-bottom:0; border-top:0;}
					.information2 .last-row { height:48px;}

					.shipping-detail th{ width:833px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.shipping-detail .sub-head{ font-family: TwCenMT-Bold_19-; text-align:center; font-size: 15px; }
					.shipping-detail .sub-head2{ font-family: TwCenMT-Regular_1a0; text-align:center; font-size: 15px; font-weight: 200; text-transform: none;}
					.shipping-detail td{ text-align:left; padding: 2px 6px; font-size: 14px; font-family: TwCenMT-Regular_1a0; color: #000;}
					.shipping-detail .head-row { height:20px; text-transform:uppercase;}
					.shipping-detail .head-row td{ line-height: 1; font-family: TwCenMT-Bold_19-; font-size: 12px; text-align:center;}
					.shipping-detail .head-row td span{ font-size:11px; }
					.shipping-detail .tag-number{ width:226px; }
					.shipping-detail .item-name{ width:202px; }
					.shipping-detail .qty-ordered{ width:63px; text-align: center;}
					.shipping-detail .qty-received{ width:55px; }
					.shipping-detail .unit-cost{ width:40px; text-align: right; }
					.shipping-detail .total-cost{ width:64px; text-align: right; }
					.shipping-detail .retail-only{ width:144px; }
					.shipping-detail .unit-retail{ width:49px; }
					.shipping-detail .total-retail{ width:95px; }
					.shipping-detail .gray-shade{ background:#bebebe; }
					.shipping-detail .body-row { height:20px;}
					.shipping-detail .body-row td{ padding: 4px 6px; font-family:Arial, Helvetica, sans-serif;}

					.rejection th{ width:833px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.rejection .sub-head{ font-family: TwCenMT-BoldItalic_1a2; text-align:center; font-size: 16px; }
					.rejection td{ line-height: 1; text-align:left; padding: 2px 6px; font-size: 16px; font-family: TwCenMT-Regular_1a0; color: #000;}
					.rejection td.reason{ width:175px; }
					.rejection .last-row { height:54px;}

					.receipt-confirm th{ border-top:0; width:833px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.receipt-confirm .sub-head{ line-height: 1.1; font-family: TwCenMT-Bold_19-; text-align:left; font-size: 14px; padding:6px 7px;}
					.receipt-confirm td{ line-height: 1.1; text-align:left; padding: 2px 6px; font-size: 14px; font-family: TwCenMT-Regular_1a0; color: #000; }
					.receipt-confirm td.person-receiving{ width:230px; background:#f1f1f1;}
					.receipt-confirm td.second{ width:340px; }
					.receipt-confirm td.third{ width:18px; padding:0; border-bottom:0; border-top:0;}
					.receipt-confirm td.forth{ width:74px; background:#f1f1f1; text-align:right;}
					.receipt-confirm .last-row { height:37px;}
					.receipt-confirm .last-row .attach_invoice{ width: 485px; }
					.receipt-confirm .last-row .attach_invoice_no{ font-size: 20px; font-family: Arial, Helvetica, sans-serif; padding: 4px 7px; }
					.extra-last{ width:100%; }
					.extra-last .last-row:first-child td{border-top:0px;}

					.tail{ padding: 15px 0px 0px 10px ; border:1px solid #000;border-top:transparent; border-botom: transparent; }

					.clearfix{ width:100%;clear:both; }

				`,
          }}
        />

        <div>
          {additional_pages}
        </div>
      </div>
    );
  }
}
