import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import swal from 'sweetalert';

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class MoveToInventorySingle extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {
			product_id: null
		};
	
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			if(this.state.product_id==null){
				swal("Warning!","Please select the product","warning");
				return;
			}
			
			document.getElementById("send-to-lab").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("moveToInventoryForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			formData.append('bucket_id',this.props.bucket_data.bucket_id);		
			formData.append('product_id',this.state.product_id.value);		
			
			axios.post(REST_API_END_POINT+'distillate-bucket/move-to-inventory-single',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("send-to-lab").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("send-to-lab").disabled=false;
			});

		}
	}

	render() {	
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Move to Inventory
				</ModalHeader>
				<AvForm
					id="moveToInventoryForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Product<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="product_id"
										id="product_id"
										value={this.state.product_id}						
										options={this.props.products}
										onChange={product_id => { this.setState({ product_id }) }}
									/>
								</AvGroup>
							</Colxx>					
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Quantity<span className="text-danger">*</span></Label>
								  <AvField
									id="quantity"
									name="quantity"
									type="number"
									min="0"
									value={this.props.bucket_data!=null ? this.props.bucket_data.quantity:""}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										max:{
											value: this.props.bucket_data!=null ? this.props.bucket_data.quantity:"",
											errorMessage: "Please enter a value less than "+(this.props.bucket_data!=null ? parseFloat(this.props.bucket_data.quantity):"0")
										}
									}}
								  />
								</AvGroup>
							</Colxx>					
						</Row>
					</ModalBody>
					<ModalFooter className="justify-content-start">
						<Button color="primary" id="send-to-lab">
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
