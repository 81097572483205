import React, { Component, Fragment } from "react";
import { Row, Card, CardBody, Table, Button, Label } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { Separator, Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { NavLink } from "react-router-dom";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class Closecomplaint extends Component {
	
	constructor(props) {
		super(props);
		  this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			selectStatus:{ label: "Active", value: "1", key: 1 },
			readOnly: true,
			complaint:null,
			comments:null,
			complaint_id:null
		};
	
	}
	
	componentDidMount() {		
		var formData = new FormData();
		let complaint;
		let comments;
		formData.append('complaint_id',this.props.match.params.complaint_id);

		axios.post(REST_API_END_POINT+'complaint/get-complaint-by-id',formData).then((res)=>{
			var data = res.data;
			complaint=data.complaint;
			comments=data.comments;
				
			this.setState({ 
				complaint,
				comments			
			});			
		})
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("close-complaint").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("complaintForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('complaint_id',this.props.match.params.complaint_id);
			

			axios.post(REST_API_END_POINT+'complaint/close-complaint',formData).then((res)=>{
				var data = res.data;
				if(data.status==1){
					swal({
					  title: "",
					  text: data.msg,
					  icon: "info",
					})
					.then(willDelete => {
						window.location="/app/complaints-and-comments";
					});
				}else{
					swal(data.msg);
				}
				document.getElementById("close-complaint").disabled=false;

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("close-complaint").disabled=false;
			});
		}
	}

	
	
  render() {
	  
	if(this.state.complaint!=null){
		var complaint=this.state.complaint;
	}else{
		var complaint;
	}  
	  
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.complaint-close" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
             
        <Row className="invoice-react">
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-5 invoice-contents">
              <CardBody className="d-flex flex-column justify-content-between">
                <div className="d-flex flex-column">                  
					<div className="d-flex flex-row justify-content-between mb-5">
						<div className="d-flex flex-column w-70 mr-2 p-4 text-semi-muted ">
							<p className="mb-0">Complaint #: {complaint ? ((complaint.complaint_number!=null)?complaint.complaint_number:''):'' }</p>
							<br/>
							<p className="mb-0"> Customer: {complaint ?((complaint.customer_name!=null)?complaint.customer_name:''):'' }</p>
							<br/>
							{complaint ?((complaint.product_name!=null)? <p className="mb-0">Product: {complaint.product_name} <br/></p>:''):'' } 
							{complaint ?((complaint.batch_number!=null)? <p className="mb-0">Batch number:  {complaint.batch_number} <br/></p>:''):'' } 
						 
							<p className="mb-0">Complaint:  { complaint?((complaint.suggestion!=null)?complaint.suggestion:''):'' } </p>                      
						</div>
					</div>
                </div>
				
				<div className="d-flex flex-column">
					<AvForm
						id="complaintForm"
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					>
						<Row className="mt-4">
							<Colxx lg="12" className="mb-2">	
								<AvGroup>
									<Label>Comment<span className="text-danger">*</span></Label>
									<AvField 
										name="body" 
										type="textarea" 
										autoComplete="off"
										rows="6"
										validate={{
											  required: {
												value: true,
												errorMessage: "Please enter the  message"
											  },
											  minLength: {
												value: 2,
												errorMessage:
												  "The message must have atleast 2 characters"
											  }
											}}
									/>
								</AvGroup>
							</Colxx>
						</Row>							
						<Button color="primary" id="close-complaint" > Close Complaint </Button>{" "}				
						<NavLink to="/app/complaints-and-comments" className="btn btn-outline-secondary ">Cancel</NavLink>			
					</AvForm>                  
                </div>
		
              </CardBody>
            </Card>
			
          </Colxx>
        </Row>

      </Fragment>
    );
  }
}
export default Closecomplaint;
