import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import bankmanagement from "./bankmanagement";
import externalbanks from "./externalbanks";
import internalbank from "./internalbank";
import drawersmanagement from "./drawersmanagement";
import payouts from "./payouts";
import externalbankview from "./externalbankview";
import drawerview from "./drawerview";


const Bankmanagement = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/external-banks`} />
			{/*<Route path={`${match.url}/bank-management`} component={bankmanagement} />*/}
			<Route path={`${match.url}/external-banks`} component={externalbanks} />
			<Route path={`${match.url}/internal-bank`} component={internalbank} />
			<Route path={`${match.url}/payouts`} component={payouts} />
			<Route path={`${match.url}/drawers-management`} component={drawersmanagement} />
			<Route path={`${match.url}/external-bank-view/:external_bank_id`} component={externalbankview} />
			<Route path={`${match.url}/drawer-view/:drawer_id`} component={drawerview} />
			<Redirect to="/error" />
        </Switch>
    </div>
);
export default Bankmanagement  ;