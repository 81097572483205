import React from "react";
import { AvForm, AvInput } from "availity-reactstrap-validation";

const ShopSearch = ({ shopSearchSubmit }) => {
  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Search </h4>
      <div className="pro-sidebar-search mb-3 mt-2">
		<AvForm
			className="pro-sidebar-search-form"
			onSubmit={shopSearchSubmit}
		  >        
			<AvInput 
				type="text" 
				name="shop_search" 
				placeholder="Search here..." 
				onChange={(e)=>{
					if(e.target.value==''){
						document.getElementById("shop-search-btn").click();
					}
				}}
			/>
			<button id="shop-search-btn">
				<i className="fas fa-search" />
			</button>
        </AvForm>
      </div>
    </div>
  );
};

export default ShopSearch;
