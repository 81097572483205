import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import {	
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
  const selectSource = [
    { label: "Pound", value: "1", key: 1 },
    { label: "KG", value: "2", key: 2},
    
  ];
const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);
class Ingridients extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require("mousetrap");
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dataListRender = this.dataListRender.bind(this);
    this.state = {
		plant_ingredients: [], 
		selectSourceType:{ label: "Pound", value: "1", key: 1 }
    };
  }
  
  handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-ingridients").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantIngridientsForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_id',this.props.plant_id);
			
			axios.post(REST_API_END_POINT+'growhouse/add-plant-ingredients',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_ingridients");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-ingridients").disabled=false;
				this.form && this.form.reset();
				this.setState({ selectSourceType:{ label: "Pound", value: "1", key: 1 } })
				this.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-ingridients").disabled=false;
			});

		}
	}
  
  
    componentDidMount(){		
		this.dataListRender();	   
	}

	
    dataListRender() {		
		var plant_ingredients=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('plant_id',this.props.plant_id);
		axios.post(REST_API_END_POINT+'growhouse/get-plant-ingredients',formData).then((res)=>{
			var data = res.data;
			
			plant_ingredients=data.plant_ingredients;

			if(data.plant_ingredients){
				this.setState({
			        plant_ingredients: data.plant_ingredients,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					plant_ingredients: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}

  render() {
	   let data = this.state.plant_ingredients 
	  const {selectAdditives} = this.state;  
	  
	  
    const dataTableColumns = [			  
	  {
		Header: "Formula Name",
		accessor: "formula",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "UOM",
		accessor: "uom",
		Cell: props => <p className="list-item-heading">{(selectSource.find(r=>{ return r.value==props.value }))!=null ? (selectSource.find(r=>{ return r.value==props.value })).label:""}</p>
	  },
	  	  {
		Header: "QTY",
		accessor: "quantity",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  }
	];	
    return( 
      <Fragment>       
        <div className="p-4">	
      <div id="api_message_ingridients"></div>		
		  <div className="mb-4 dropdown-edit">
			{this.props.user_privileges.indexOf(147)!==-1 ? <AvForm
		        id="plantIngridientsForm"
				ref={c => (this.form = c)}
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			  	<Row className="mt-2">
					<Colxx xxs="4">	
						<AvGroup className="error-t-negative">	
                        <Label>Formula Name<span className="text-danger">*</span></Label>						
							<AvField
								id="note"
								name="formula"
								type="text"	
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							/>
						 </AvGroup>
					</Colxx>
					<Colxx lg="4" >
						<AvGroup className="error-t-negative">
							<Label>UOM<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="uom"								
								id="additive"
								value={this.state.selectSourceType}						
								options={selectSource}
							   onChange={selectSourceType => { this.setState({ selectSourceType }) }}	
							/>
						</AvGroup>
					</Colxx>
				
					<Colxx lg="4" >
						<AvGroup className="error-t-negative">
						  <Label>Quantity<span className="text-danger">*</span></Label>
						  <AvField
							id="additive_weight"
							name="quantity"
							type="number"
							value=""
							validate={{
								required: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				<Row className="mt-2 mb-4">		
					<Colxx xxs="6">
						<Button
							id="add-ingridients"
							color="primary"
							size="sm"
							className=""
							>
							Add Ingredients
						</Button>					
					</Colxx>					
				</Row>
			</AvForm>:""}
			<hr/>
				<ReactTable
				  data={data}
				  TbodyComponent={CustomTbodyComponent}
				  columns={dataTableColumns}
				  defaultPageSize={5}
				  showPageJump={false}
				  showPageSizeOptions={true}
				  showPagination={true}
				  PaginationComponent={DataTablePagination}
				 
				/>
			</div>
		</div>	         
      </Fragment>
    );
  }
  
};
export default Ingridients;
