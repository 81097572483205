import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,Card,
  CustomInput,
  Button,Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup, Table, 
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import AddStrainModal from "../../containers/inventory/AddStrainModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ThumbnailImage from "../../components/cards/ThumbnailImage"

import RemoveSectionInspection from "../../components/template/RemoveSectionInspection";
import RemoveSectionAdditive from "../../components/template/RemoveSectionAdditive";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
const selectWorkflow = [
    { label: "Requirement Workflow", value: "1", key: 0 },
    { label: "Test Workflow", value: "2", key: 1 }
  ];
class ManageWorkflow extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
		 modalOpenAdd: false,
		
		  search: '', 
		};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


  render() {
 let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}	  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Manage Onboarding Workflows
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			<div className="mb-4 dropdown-edit">						
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Select Workflows</Label>
						  <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"							
								id="select2-workflow_id-container"
								value={this.state.selectedOptions}
								onChange={this.handleChangeMulti}
								options={selectWorkflow}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						<span>Requirement Workflow <RemoveSectionInspection/>	</span>
					</Colxx>
				</Row>
				
			</div>
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  Save
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default ManageWorkflow;
