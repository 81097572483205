import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Label } from "reactstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import Breadcrumb from "../../containers/navs/Breadcrumb";

import { REST_API_END_POINT,themeColor } from "../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';


export default class Settings extends Component {
	
	constructor(props) {
		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			blocking: false,
			admin_settings:null,
		};
	}
	
	componentDidMount() {
		this.setState({ blocking: true });
		axios
		  .get(
			`${REST_API_END_POINT+'admin/settings'}`
		  )
		  .then(res => {
			return res.data;
		  })
		  .then(data => {			
			this.setState({
				admin_settings: data.data,
				blocking: false
			});
		  });
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			document.getElementById("update-payment").disabled = true;
			var data_upload = new FormData(document.getElementById("settings_form"));
			axios.post(REST_API_END_POINT+'admin/update-settings',data_upload).then((data)=>{ 
				if(data.data.status==1){
					swal("Success!", "Payment settings has been updated successfully!", "success");				
				}else{
					swal("Failed to update payment settings!", "Please try after sometime.", "error");			
				}
				document.getElementById("update-payment").disabled = false;
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	}
	  
		
	render() {
		const { admin_settings } = this.state;
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.settings" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>

					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Row className="mb-3">
								<Colxx lg="12">
									<h3>Payment Gateway Settings</h3>
									<hr/>
								</Colxx>
							</Row>
								
							<Row className="mt-3">
								<Colxx lg="6">
									<AvForm
										id="settings_form"
										className="av-tooltip tooltip-right-bottom"
										onSubmit={this.handleSubmit}
									  >							 
										<AvGroup className="error-t-negative">
										  <Label>Authorize api login id</Label>
										  <AvField
											name="api_login_id"
											type="text"
											autoComplete="off"
											value={admin_settings!=null ? admin_settings.authorizednet_credentials.api_login_id:""}
											onChange={(e)=>{
												var admin_settings = this.state.admin_settings;
												admin_settings.authorizednet_credentials.api_login_id = e.target.value;
												this.setState({ admin_settings });
											}}
											validate={{
											  required: {
												value: true,
												errorMessage: "Please enter the api login id"
											  }											  
											}}
										  />
										</AvGroup>
										
										<AvGroup className="error-t-negative">
										  <Label>Authorize api transaction key</Label>
										  <AvField
											name="api_transaction_key"
											type="text"
											autoComplete="off"
											value={admin_settings!=null ? admin_settings.authorizednet_credentials.api_transaction_key:""}
											onChange={(e)=>{
												var admin_settings = this.state.admin_settings;
												admin_settings.authorizednet_credentials.api_transaction_key = e.target.value;
												this.setState({ admin_settings });
											}}
											validate={{
											  required: {
												value: true,
												errorMessage: "Please enter the api transaction key"
											  }											  
											}}
										  />
										</AvGroup>
										
										<AvGroup className="error-t-negative">
										  <Label>Authorize public client key</Label>
										  <AvField
											name="public_client_key"
											type="text"
											autoComplete="off"
											value={admin_settings!=null ? admin_settings.authorizednet_credentials.public_client_key:""}
											onChange={(e)=>{
												var admin_settings = this.state.admin_settings;
												admin_settings.authorizednet_credentials.public_client_key = e.target.value;
												this.setState({ admin_settings });
											}}
											validate={{
											  required: {
												value: true,
												errorMessage: "Please enter the public client key"
											  }											  
											}}
										  />
										</AvGroup>
										
										
										<AvGroup className="error-t-negative">
										  <Label>Invoice Starting Number</Label>
										  <AvField
											name="invoice_starting_number"
											type="text"
											autoComplete="off"
											value={admin_settings!=null ? admin_settings.invoice_starting_number:""}
											onChange={(e)=>{
												var admin_settings = this.state.admin_settings;
												admin_settings.invoice_starting_number = e.target.value;
												this.setState({ admin_settings });
											}}
											validate={{
											  required: {
												value: true,
												errorMessage: "Please enter the starting number of invoice"
											  }											  
											}}
										  />
										</AvGroup>
									
										<Button color="primary" id="update-payment">
										  <IntlMessages id="pages.submit" />
										</Button>
								
									</AvForm>
								</Colxx>		
							</Row>
							
						</Colxx>
					</Row>
					
				</BlockUi>  
			</Fragment>
		);
	}
}
