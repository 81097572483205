import React, { Component, Fragment } from "react";
import moment from 'moment';
import Barcode from 'react-barcode';

import {REST_API_BASE_URL} from "../../../constants/defaultValues";

export default class WasteViewContent extends Component{
	constructor(props) {
		super(props);
		
	}
  
	render() {
		const { waste_data } = this.props;
		return (
			<div className="px-4 pt-4">
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 3in 3.5in;
						  margin:0;
						}
					}
				`}} />
			{waste_data!=null ? <div className="mb-5">
				<Barcode value={waste_data.barcode} height={40} width={1.35} margin={0} marginTop={5} displayValue={false} />
				<h4>{(waste_data.barcode.match(/.{1,4}/g)).join(' ')}</h4>
				
				<div className="d-flex flex-column w-70 mr-2 pt-4 ">
					<p className="mb-0"><strong>Product Name</strong>: {waste_data.product_name}</p>
					<br/>
					<p className="mb-0"><strong>Quantity</strong>: {waste_data.quantity}</p>
					<br/>
					<p className="mb-0"><strong>Reason for Waste</strong>: {waste_data.waste_reason}</p>
					<br/>
					
					{waste_data.reject_reason!=null ? <Fragment>
					<p className="mb-0"><strong>Reason for Rejection</strong>: {waste_data.reject_reason}</p>
					<br/></Fragment>:""}
					
					<p className="mb-0"><strong>Status</strong>:  {waste_data.status==="1" ? "Pending":(waste_data.status==="2" ? "Approved":"Rejected")}</p>
					<br/>
					<p className="mb-0"><strong>Added by</strong>: {waste_data.added_by}</p>
					<br/>
					<p className="mb-0"><strong>Added at</strong>: {moment(waste_data.timestamp*1000).format("MM/DD/YYYY HH:mm")}</p>
					
					{waste_data.signature!=null ? <p className="mb-0"><strong>Signature</strong>: <img src={REST_API_BASE_URL+"uploads/waste-sign/"+waste_data.signature} style={{height:'50px'}} /></p>:""}
				</div>
			</div>:""}
			</div>
		);
	}
};
