import React, { Component } from "react";
import { AvForm, AvField, AvGroup, } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,InputGroup, InputGroupAddon, Card, CardBody
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { uuid } from 'uuidv4';
import swal from 'sweetalert';
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const WeekdayOptions = [
	{ label: "All Day", value: "0", key: -1 },
	{ label: "Monday", value: "1", key: 0 },
	{ label: "Tuesday", value: "2", key: 1 },
	{ label: "Wednesday", value: "3", key: 2 },
	{ label: "Thursday", value: "4", key: 3 },
	{ label: "Friday", value: "5", key: 4 },
	{ label: "Saturday", value: "6", key: 5 },
	{ label: "Sunday", value: "7", key: 6 }
];
 
class EditDealModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			products:[],
			product_variations:[],
			selected_products:[],
			no_of_items:"",
			deal:null,
			week_day: { label: "All Day", value: "0", key: -1 },
		};
	}
		
	componentDidUpdate(prevProps, prevState, snapshot){
		
        if(this.props.modalOpen){
			if (prevProps.deal_id !== this.props.deal_id && this.props.deal_id!=0) {
				var formData = new FormData();
				formData.append('deal_id',this.props.deal_id);
	
				axios.post(REST_API_END_POINT+'point-of-sale/get-daily-deal-by-id',formData).then((res)=>{
					var data = res.data;					
					let deal = data.deal;			 
					
					let selected_products = deal.products;
					let week_day = WeekdayOptions.find(p=>{ return p.value==deal.week_day });
					
					this.setState({ 
						deal,
						week_day,
						selected_products,
						no_of_items:""
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}
		}
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			let errorMessage='';
			let product_ids = this.state.selected_products.map(r=>{ return r.product_id!=null ? r.product_id.value:null});
			let variation_ids = this.state.selected_products.map(r=>{ return r.variation_id!=null ? r.variation_id.value:null});
			
			if(product_ids.indexOf(null)!=-1){
				swal("Warning","Please select the product","warning");
				return false;
			}
			if(variation_ids.indexOf(null)!=-1){
				swal("Warning","Please select the variation","warning");
				return false;
			}
			
			document.getElementById("process-deal-stock").disabled=true;
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			
			var ingredients = this.state.selected_products;
			var no_of_items = this.state.no_of_items;
			if(no_of_items==''){
				no_of_items=0;
			}
			
			var product_list = [];
			for(let ingredient of ingredients){
				let p={};
				p.product_id = ingredient.product_id.value;
				p.variation_id = ingredient.variation_id.value;
				p.quantity= parseFloat(ingredient.quantity)*parseFloat(no_of_items);
				product_list.push(p);
			}
			formData.append('product_list',JSON.stringify(product_list));
			
			
			let user_id = localStorage.getItem('user_id');
			formData.append('user_id',user_id);		
			formData.append('deal_id',this.state.deal.id);	
			formData.append('no_of_items',this.state.no_of_items);		
						
			axios.post(REST_API_END_POINT+'point-of-sale/reduce-deal-stock',formData).then((res)=>{
				var data = res.data;
				if(data.status==0){
					swal("Warning!",data.msg,"warning");
					document.getElementById("process-deal-stock").disabled=false;
				}else{
					swal("Success!",data.msg,"success").then(()=>{
						this.props.toggleModal();								
					});
					this.props.dataListRender();
				}					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("process-deal-stock").disabled=false;
			});
		}
	}

	render(){
		if(this.state.deal!=null){
			var deal=this.state.deal;
		}else{
			var deal;
		}
		
		const { no_of_items } = this.state;
		var final_no_of_items = 0;
		if(no_of_items!=''){
			final_no_of_items=no_of_items;
		}
		
		const dataTableColumns = [
			{
				Header: "Product",
				accessor: "product_id",
				Cell: props => <p className="list-item-heading">{props.value.label}</p>
			},
			{
				Header: "Variation",
				accessor: "variation_id",
				Cell: props => <p className="list-item-heading">{props.value.label}</p>
			},
			{
				Header: "Quantity",
				accessor: "quantity",
				Cell: props => {
						return(<p className="list-item-heading"><strong>{parseFloat(props.value)*parseFloat(final_no_of_items)}</strong></p>)
				}
			}
		];
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Reduce Stock
				</ModalHeader>
				<AvForm
					id="customercouponForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12" className="h5">								
								<Label>Current Stock:</Label>
								<Label className="font-weight-bold text-danger ml-1">{ deal?((deal.stock!=null)?deal.stock:''):'' }</Label>
							</Colxx>
						</Row>
						<Row className="mt-2">
							<Colxx lg="12">
								<AvGroup>
									<Label>No. of items<span className="text-danger">*</span></Label>
									<AvField
										id="no_of_items"
										name="no_of_items"
										type="number"
										min="0"
										step="1"
										value={no_of_items}
										onChange={e => { this.setState({ no_of_items:e.target.value }) }}
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											max: {
												value: deal?((deal.stock!=null)?deal.stock.toString():'0'):'0',
												errorMessage: "Please enter a value less than"+(deal?((deal.stock!=null)?deal.stock.toString():'0'):'0')+" ."
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						
						<div className="mb-4 dropdown-edit">						
							<ReactTable
								data={this.state.selected_products}
								columns={dataTableColumns}
								defaultPageSize={10}
								minRows = {2}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>
						
						<div className="text-danger text-center h5">The stock will be added to your inventory</div>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="process-deal-stock" >
						  <IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
						  <IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditDealModal;
