import PropTypes from "prop-types";
import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { Colxx } from "../../components/common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, themeColor} from "../../constants/defaultValues";
import Mailchimp from 'react-mailchimp-form';

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
const dataCrypto = new SimpleCrypto(dataEncryptKey);

class MailChimpIntegration extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			configurations: null,
		};
	}
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{ 
			this.setState({ configurations: res.data.configurations, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed, Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	render() {
		const configurations = this.state.configurations;
		var action_url = 'https://undergrounddispensary.us3.list-manage.com/subscribe/post?u=0bca6fde571d70250d434c217&amp;id=780183ade8'
		if(configurations!=null){
			if(configurations.mailchimp_form_url!=null){
				action_url = configurations.mailchimp_form_url;
			}
		}
		return (
			<Fragment>
				<div className=" pb-4 pt-4 grey p-l-30 ">
					<div className="row pt-4 pb-4">
						<div className="col-lg-12 pl-4 pr-4 mailchimp-subscription-form">
							<div><h3 className="discount-text">GET EXCLUSIVE NEWS, DEALS & DISCOUNTS</h3></div>
							<Mailchimp
								action={action_url}
								fields={[																	  
								  {
									name: 'EMAIL',
									placeholder: 'enter your email',
									type: 'email',
									required: true
								  }
								]}
								messages = {
									{
									  sending: "Sending...",
									  success: "Thank you for subscribing!",
									  error: "An unexpected internal error has occurred.",
									  empty: "You must write an e-mail.",
									  duplicate: "Too many subscribe attempts for this email address",
									  button: "Submit"
									}
								}
							/>
						</div>
					</div>
				</div>
			</Fragment>
		);
	};
};


export default MailChimpIntegration;
