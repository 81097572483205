import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const quillModules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	["bold", "italic", "underline"],
	[{ 'align': [] }],
     // dropdown with defaults from theme	
	[
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],    	    
    
  ]
};

const quillFormats = [
  "header",
  "bold",
  "italic",
  "underline",
  "align",  
  "list",
  "bullet",
  "indent", 
];

class EditPostModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {       
			text_content:''
		};
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			var status=null;
			if (prevProps.testimonial_id!== this.props.testimonial_id && this.props.testimonial_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('testimonial_id',this.props.testimonial_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'website/get-testimonial-by-id',formData).then((res)=>{					
					var data = res.data;
					let post_data=data.data
					
					this.setState({ 
						post_data
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-testimonial").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("testimonialForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('testimonial_id',this.props.testimonial_id);

			axios.post(REST_API_END_POINT+'website/edit-testimonial',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-testimonial").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-testimonial").disabled=false;
			});

		}
	}

	render() {

		if(this.state.post_data!=null){
			var post_data=this.state.post_data;
		}else{
			var post_data;
		}
  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Testimonial
		  </ModalHeader>
		  <AvForm
		        id="testimonialForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Excerpt<span className="text-danger">*</span></Label>
							<AvField
								id="content"
								name="content"
								type="textarea"
								autoComplete="off"
								rows="4"
								value={ post_data ? ((post_data.content!=null)? post_data.content:''):'' }
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required"
									}
								}}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Featured Image</Label>
							{post_data!=null ? (post_data.featured_image!=null ? <Row className="mt-2 mb-3">
									<Colxx lg="12" >
										<div className="d-flex justify-content-start">
											<div className="text-center img-view-wrapper">
												<img src={BASE_URL+'pages/'+post_data.featured_image} alt="Featured Image" className="img-responsive" />
											</div>
										</div>
									</Colxx>
								</Row>:""):""}
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									name="featured_image"
									accept="image/png,image/jpeg,image/jpg,image/gif"
									onChange={(e)=>{							
										if(e.target.files[0]){
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
										}else{
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
										}
									}}
								/>
							</InputGroup>
						</AvGroup>
					</Colxx>					
				</Row>							
			</ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-testimonial" >
			  <IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditPostModal;
