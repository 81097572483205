import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import FinishedPackages from "../FinishedPackages";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


const VariationViewStockModal = (props) =>{
	
	const finished_products = props.finished_products.filter(r=>{ return r.variation_id==props.product_variation.variation_id });
	console.log('props.product_variation',props.product_variation);
	console.log('finished_products',finished_products);
	return (	  
		<Modal
			isOpen={props.modalOpen}
			toggle={props.toggleModal}
			wrapClassName="modal-right modal-right-lg"
			size="md"
		>
			<ModalHeader toggle={props.toggleModal}>
				Variation Stock
			</ModalHeader>
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<FinishedPackages 
							product_id={props.product_id} 
							product={props.product} 
							dataListRender={props.dataListRender}
							finished_products={finished_products}
							product_variations={props.product_variations}
							unused_packages={props.unused_packages}
							facilities={props.facilities}
							warehouses={props.warehouses}
							facility={props.facility}
						/>
					</Colxx>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button color="secondary" outline onClick={props.toggleModal}>
					<IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		</Modal>
	);
};

export default VariationViewStockModal