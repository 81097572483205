import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Badge,
  Card, CardHeader, Table, Media,  InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';
import ReactToPrint from 'react-to-print';

import PrintProductLabel from "../../../components/receive-inventory/PrintProductLabel";

import { zeroPad, getCurrencySymbol } from "../../../helpers/Utils";
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE, DEFAULT_QTY_TYPES, LBS_CONSTANT } from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class ViewOrderModal extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			order_data: null,
			whole_order_data: null,
			facility_data: null,
			order_products: [],
		};
		
		this.dataListRender = this.dataListRender.bind(this);
		this.cancelOrder = this.cancelOrder.bind(this);
	
    }
	
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }	
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				this.dataListRender();
            }
        }
    }
	
	dataListRender() {
		this.setState({ showFile:false });
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('order_id',this.props.order_id);
		formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'receive-inventory/invoice',formData).then((res)=>{
			
			var data = res.data;
			if(data.status==1){
				this.setState({ 
					order_data:data.order_data,
					whole_order_data:data.whole_order_data,
					order_products:data.order_products,
					facility_data:data.facility_data,
				});
			}else{
				this.props.toggleModal();
				swal("Warning!","There is no such order","warning");
			}					
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		}); 
	}
	
	cancelOrder = ()  => {
		var msg="Cancel this order?";
        var success_msg="The order has been canceled.";
        var failure_msg="Failed to cancel order";
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("cancel-view-order").disabled=true;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('order_ids',JSON.stringify([this.props.order_id]));
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'receive-inventory/cancel-orders',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("cancel-view-order").disabled=false;					
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
					this.props.dataListRender();
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("cancel-view-order").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
    render() {
		const { order_data, order_products, whole_order_data, facility_data } = this.state;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax=0;
		var sub_total=0;
		var status_text ="";
		var status_badge ="";
		if(order_data!=null){
			
			if(order_data.status==0 && order_data.process_count==0){ 
				status_text = "Pending";
				status_badge = <Badge color="warning" >Pending</Badge>;
			}else if(order_data.status==1){
				status_text = "Approved";
				status_badge = <Badge color="info" >Approved</Badge>;
			} else if(order_data.status==2||(order_data.process_count>0&&order_data.status!=3&&order_data.status!=4&&order_data.status!=6&&order_data.status!=7&&order_data.status!=8)){ 
				status_text = "In Process";
				status_badge = <Badge color="info" >In Process</Badge>;
			} else if(order_data.status==3){
				status_text = "Completed";
				status_badge = <Badge color="success" >Completed</Badge>;
			}  else if(order_data.status==5){
				status_text = "Received";
				status_badge = <Badge color="info" >Received</Badge>;
			}  else if(order_data.status==6){
				status_text = "Ready to send to lab";
				status_badge = <Badge color="info" >Ready to send to lab</Badge>;
			} else if(order_data.status==7){
				status_text = "Sent to lab";
				status_badge = <Badge color="info" >Sent to lab</Badge>;
			} else if(order_data.status==8){
				status_text = "Test Result Submitted";
				status_badge = <Badge color="info" >Test Result Submitted</Badge>;
			} else {
				status_text = "Canceled";
				status_badge = <Badge color="danger" >Canceled</Badge>;
			}
		}
		
		let is_igst=true;
		let is_utgst=false;
		/*if(facility_data!=null && order_data!=null){
			is_igst = order_data.vendor_state !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}
		}*/
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right modal-right-lg"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					View Order
				</ModalHeader>
					
				<ModalBody >
					{order_data!=null ? <Fragment>
						<Row>
							<Colxx md="9">
								<div className="view-order-table">
									<div className="font-weight-bold text-uppercase h4">#{zeroPad(order_data.order_number,6)}</div>
									<div className="border-bottom pt-2 mb-3" />
									<Table responsive bordered className="special-table-layout">
										<thead>
											<tr>
												<th className="mb-2">Product</th>						
												<th className="mb-2 text-right">Unit Cost</th>						
												<th className="mb-2 text-right">Quantity</th>
												<th className="mb-2 text-right">Total</th>						
												<th className="mb-2">Notes</th>						
												<th className="mb-2">On Consignment</th>						
											</tr>
										</thead>
										<tbody>
											{order_products.map((row,index)=>{
												let qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==row.qty_type });
												let qty = parseFloat(row.qty)
												if(qty_type.value=="2"){
													qty/=LBS_CONSTANT;
												}
												return(<Fragment key={index}><tr >
												<td>{row.product} {row.variation_name!=null ? "("+row.variation_name+")":""}</td>
												<td className="text-right">{current_currency_symbol}{number_format(parseFloat(row.unit_price),2,'.','')}</td>
												<td className="text-right">{number_format(parseFloat(qty),2,'.','')}{" "}{qty_type.label}</td>
												<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.total_amount),2,'.','')}</td>
												<td>{row.notes}</td>
												<td>{row.on_consignment==1 ? "Yes":"No"}</td>
											</tr>
											{row.order_batches.length ? <tr>
												<td colSpan="6">
													<Table bordered>
														<thead>
															<tr>
																<th className="mb-2">Batch #</th>						
																{row.product_type==1 ? <th className="mb-2">Harvest Date</th>:null}
																<th className="mb-2">Package Date</th>
																<th className="mb-2">Exp Date</th>
																<th className="mb-2">Stock ID</th>
																{/*row.qty_type!=3 && row.content_type==0 ? <Fragment>
																	<th className="mb-2 text-right">Gross Weight</th>
																	<th className="mb-2 text-right">Net Weight</th>
																	<th className="mb-2 text-right">Label Weight</th>
																</Fragment>:*/}
																<Fragment>
																	<th className="mb-2 text-right">Quantity</th>
																	<th className="mb-2 text-right">Received Quantity</th>
																</Fragment>
																{row.product_type==1 ? <th className="mb-2 text-right">Amount of Flower</th>:null}
															</tr>
														</thead>
														<tbody>
														{row.order_batches.map((r,i)=>{
															let qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==row.qty_type });
															if(row.content_type!=0){
																qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==3 });
															}
															return(<tr key={i}>
																<td>{r.batch_number}</td>
																{row.product_type==1 ? <td>{r.harvest_date!=0 ? moment(r.harvest_date*1000).format('MM/DD/YYYY'):""}</td>:null}
																<td>{r.package_date!=0 ? moment(r.package_date*1000).format('MM/DD/YYYY'):""}</td>
																<td>{r.exp_date!=0 ? moment(r.exp_date*1000).format('MM/DD/YYYY'):""}</td>
																<td>{r.package_uid}</td>
																{/*row.qty_type!=3 && row.content_type==0 ? <Fragment>
																<td className="text-right">{number_format(parseFloat(r.gross_pkg),2,'.','')}{" "}{qty_type.label}</td>
																<td className="text-right">{number_format(parseFloat(r.net_mmj),2,'.','')}{" "}{qty_type.label}</td>
																<td className="text-right">{number_format(parseFloat(r.rcvd_total),2,'.','')}{" "}{qty_type.label}</td>
																</Fragment>:*/}
																<Fragment>
																	<td className="text-right">{number_format(parseFloat(r.gross_pkg),2,'.','')}{" "}{qty_type.label}</td>
																	<td className="text-right">{number_format(parseFloat(r.rcvd_total),2,'.','')}{" "}{qty_type.label}</td>
																</Fragment>
																{row.product_type==1 ? <td className="text-center">{r.rcvd_amt_of_flower}</td>:null}
															</tr>)
														})}
														</tbody>
													</Table>
												</td>
											</tr>:""}
											</Fragment>)
											})}
										</tbody>
									</Table>
									
									{order_data!=null ?<div className="d-flex flex-column">
										<div className="border-bottom pt-3 mb-2" />
										<Table borderless className="d-flex justify-content-end">
											<tbody>
												<tr>
													<td className="pb-0">Subtotal :</td>
													<td className="text-right pb-0">{current_currency_symbol}{number_format(parseFloat(order_data.sub_total),2,'.','')}</td>
												</tr>
												{is_igst ? <tr>
													<td className="pb-0">Tax :</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(parseFloat(order_data.vat_amount),2,'.','')}</td>
												</tr>:<Fragment>
													<tr>
														<td className="pb-0">{is_utgst ? "UTGST":"SGST"} :</td>
														<td className="text-right pb-0">{current_currency_symbol}{""+number_format(parseFloat(order_data.sgst_amount),2,'.','')}</td>
													</tr>
													<tr>
														<td className="pb-0">CGST :</td>
														<td className="text-right pb-0">{current_currency_symbol}{""+number_format(parseFloat(order_data.cgst_amount),2,'.','')}</td>
													</tr>
												</Fragment>}
												<tr>
													<td className="pb-0">Discount :</td>
													<td className="text-right pb-0">{current_currency_symbol}{number_format(parseFloat(order_data.discount),2,'.','')}</td>
												</tr>
												<tr className="font-weight-bold">
													<td className="pb-0">Grand Total :</td>
													<td className="text-right pb-0">{current_currency_symbol}{number_format(parseFloat(order_data.grand_total),2,'.','')}</td>
												</tr>
											</tbody>
										</Table>
									</div>:""}
								</div>
							</Colxx>
							<Colxx md="3">
								<Media>
									<Media left className="mr-2">
										<Media object width="50" src={order_data.client_avatar!=null ? REST_API_BASE_URL+"uploads/users/"+order_data.client_avatar:REST_API_BASE_URL+"uploads/users/avatar.jpg"} alt={order_data.vendor_name} />
									</Media>
									<Media body>
										<p className="mb-1">Supplier Name: {order_data.vendor_name}</p>
										<p className="mb-1">Created: {moment(order_data.timestamp*1000).format('MM/DD/YYYY HH:mm')}</p>
										<p className="mb-1">Status: {status_badge}</p>
										{order_data.status==1 && order_data.payment_mode!=0 ? <p className="mb-1">Payment Mode: {order_data.payment_mode}</p>:""}
										{order_data.status==1 && order_data.ap_username!=null ? <p className="mb-1">Approved By: {order_data.ap_username}</p>:""}										
									</Media>
								</Media>
								<div className="border-bottom pt-2 mb-3" />
								{order_data.status!=3 && order_data.status!=4 ? <Button size="sm" id="cancel-view-order" color="danger" onClick={this.cancelOrder} >Cancel Order</Button>:""}
								<div className="mt-3">
									<ReactToPrint
										trigger={() => <Button color="primary" size="sm"  >Print Product Label</Button>}
										content={() => this.productLabelRef}
										copyStyles={true}
										pageStyle={""}
									/>{" "}	
								</div>
							</Colxx>
						</Row>
						
						<div className="d-none">
						{whole_order_data!=null ? <PrintProductLabel 
							ref={el => (this.productLabelRef = el)} 
							print_pos_label={whole_order_data} 
							facility={facility_data}
						/>:""}
						</div>
						
					</Fragment>:""}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.close" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
  }
};
