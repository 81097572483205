import React, { Component, Fragment } from "react";
import { injectIntl } from 'react-intl';
import { Row, Card } from "reactstrap";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";

import { AreaChart } from "../../components/charts";
import IconCard from "../../components/cards/AdminIconCard";

import { areaChartOptions } from "../../components/charts/config";
import { makeColorGradient } from '../../helpers/Utils'
import { ThemeColors } from '../../helpers/ThemeColors';

import { REST_API_END_POINT,themeColor } from "../../constants/defaultValues";
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

const colors = ThemeColors()

var area_chart_options = JSON.parse(JSON.stringify(areaChartOptions));

class Dashboard extends Component {
	constructor(props) {
		super(props);
		
		
		this.state = {	
			blocking: false,
			countData: [
				{ title: 'Registered Users', icon: "iconsminds-conference", value: 0 },
				{ title: 'Active Users', icon: "iconsminds-business-mens", value: 0 },
				{ title: 'Active User Subscriptions', icon: "iconsminds-network", value: 0 },
				{ title: 'Active Subscription Plans', icon: "iconsminds-id-card", value: 0  }
			  ],
			TotalCollected : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			subscriptions:[],
			subscription_charts:[]
		};
	}
	
	componentDidMount() {		
		this.setState({ blocking: true });
		axios.get(REST_API_END_POINT+'admin/dashboard').then(res => {
				return res.data;
			}).then(data => {
				var countData = [
					{ title: 'Registered Users', icon: "iconsminds-conference", value: data.counts.registered_users },
					{ title: 'Active Users', icon: "iconsminds-business-mens", value: data.counts.active_users },
					{ title: 'Active User  Subscriptions', icon: "iconsminds-network", value: data.counts.active_subscriptions },
					{ title: 'Active Subscription Plans', icon: "iconsminds-id-card", value: data.counts.active_subscription_plans  }
				  ];
				var TotalCollected = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
				data.payments.map((d)=>{
					let i = d._id - 1;
					TotalCollected[i]=d.ratio;
				});
				
				var subscription_charts = data.subscription_charts;
				var subscriptions = data.subscriptions;
				
				this.setState({
					countData,
					TotalCollected,
					subscriptions,
					subscription_charts,
					blocking: false
				});
			});
	}
	
	render() {
		let countData = this.state.countData;
		
		var collected_area_chart_options = JSON.parse(JSON.stringify(area_chart_options));
		const TotalCollected = {
			labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
			datasets: [
			  {
				label: 'Amount',
				data: this.state.TotalCollected,
				borderColor: colors.themeColor1,
				pointBackgroundColor: colors.foregroundColor,
				pointBorderColor: colors.themeColor1,
				pointHoverBackgroundColor: colors.themeColor1,
				pointHoverBorderColor: colors.foregroundColor,
				pointRadius: 4,
				pointBorderWidth: 2,
				pointHoverRadius: 5,
				fill: true,
				borderWidth: 2,
				backgroundColor: colors.themeColor1_10
			  }
			]
		}
		
		let maxvalue = Math.max(...this.state.TotalCollected);
		let range = Math.ceil((maxvalue/10)/50)*50
		if(maxvalue>50){
			collected_area_chart_options.scales.yAxes[0].ticks.max  = Math.ceil(maxvalue/50)*50;
		}
		collected_area_chart_options.scales.yAxes[0].ticks.stepSize  = range;
		
		var len=this.state.subscriptions.length;
		var frequency = 0.22*len;
		var Chartcolors = makeColorGradient(frequency, frequency, frequency, 0, 2, 4, 128, 127, len);
		
		const SubscriptionData = {
				labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
				datasets: []
			};
		const subscriptions = this.state.subscriptions;
		const subscription_charts = this.state.subscription_charts;
		
		for(let key in subscriptions){
			let subscription = subscriptions[key];
			let subscription_chart = subscription_charts.find(r=>{ return r._id==subscription.id });
			let dataset = {
				label: subscription.name,
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
				borderColor: Chartcolors[key],
				pointBackgroundColor: colors.foregroundColor,
				pointBorderColor: Chartcolors[key],
				pointHoverBackgroundColor: Chartcolors[key],
				pointHoverBorderColor: colors.foregroundColor,
				pointRadius: 4,
				pointBorderWidth: 2,
				pointHoverRadius: 5,
				fill: false,
				borderWidth: 2,
			};
			
			if(typeof subscription_chart!='undefined'){
				subscription_chart.plan.map((d)=>{
										let i = d.month - 1;
										dataset.data[i]=d.total;
									});
			}
			SubscriptionData.datasets.push(dataset);
		}
	
    return (
		<Fragment>
			<Row>
			  <Colxx xxs="12">
				<Breadcrumb heading="menu.dashboard" match={this.props.match}/>
				<Separator className="mb-5" />
			  </Colxx>
			</Row>
		
			<Row className="admin-cards-container p-2">
				{countData.map((item, index) => {
					return (<IconCard key={index} {...item} className="col-lg-3 admin-metric-card mb-4"/>);
				})}
			</Row>
			<Row className="pb-4">			
				<Colxx lg="12" >
					<h3>Total Collcted all time</h3>
					<Card className="dashboard-filled-line-chart pt-5">
						<div className="chart card-body pt-0">
							<AreaChart shadow data={TotalCollected} chartoptions={collected_area_chart_options} />
						</div>
					</Card>
				</Colxx>
			</Row>
			<Row className="mt-4">	
				<Colxx lg="12" >
					<h3>Total collected based on subscriptions</h3>
					<Card>
						<div className="chart card-body pt-4">
							<AreaChart shadow data={SubscriptionData} />
						</div>
					</Card>
				</Colxx>
			</Row>        
		</Fragment>
    );
  }
}

export default injectIntl(Dashboard);