import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import moment from 'moment';
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "../../components/common/CustomDateTimePicker";
import TimePicker from "react-datepicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
 

class AddListModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {		
			startDate: null,
			time_slot:null,
			time_slot_end:null,
		};
	}
	
	handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	handleChangeTime = time => {
		this.setState({ time_slot: time });
	};
	handleChangeTimeend = time => {
		this.setState({ time_slot_end: time });
	};
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-time").disabled=true;
			
			let dateOfBirth = document.getElementById("dob").value.trim();
			let errorMessage='';

			if(dateOfBirth.length==0)
			{
				errorMessage+="Date is empty\n";
			}else if(this.state.time_slot==null){
				errorMessage+="Time in is empty\n";
			}else if(this.state.time_slot_end==null){
				errorMessage+="Time out is empty\n";
			}else if(moment(this.state.startDate.format('YYYY-MM-DD')+' '+this.state.time_slot.format('HH:mm:ss')).unix()>moment(this.state.startDate.format('YYYY-MM-DD')+' '+this.state.time_slot_end.format('HH:mm:ss')).unix()){
				errorMessage+="Time out should be greater than Time in\n"; 
			}

			if(errorMessage.length!=0)
			{
				swal("Warning",errorMessage,"warning");
				document.getElementById("add-time").disabled=false;
				return false;
			}
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("timeForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('target_user_id',this.props.target_user_id);

			
			axios.post(REST_API_END_POINT+'staff/manual-entry-time',formData).then((res)=>{
				var data = res.data;
								
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-time").disabled=false;
				this.props.toggleModal();
				this.setState({ 
					startDate: null,
					time_slot:null,
					time_slot_end:null,
				});
				
				this.props.dataListRender();
					
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-time").disabled=false;
			});

		}
	}
	

  render() {
 	  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Manual Time Entry
			</ModalHeader>
			<AvForm
				id="timeForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					
					<Row>
						<Colxx lg="12">
							<Label>Reason<span className="text-danger">*</span></Label>
							<AvGroup className="error-t-negative">
							  <AvField
								id="manualReason"
								name="manualReason"
								type="textarea"
								autoComplete="off"
								rows="5"
								validate={{
									  required: {
										value: true, 
										errorMessage: "This field is required."
									  },
									  minLength: {
										value: 10,
										errorMessage: "Your message must have minimum of 10 characters"
									  },
									}}
								value={this.state.textarea_content}
								placeholder=""
							  />
							</AvGroup>					
						</Colxx>
						
					</Row>	
					
					
					<Row>
					    <Colxx lg="12">
							<Label>Date<span className="text-danger">*</span></Label>
							<DatePicker
								id="dob"
								name="entryDate"
								autoComplete="off"
								className="mb-5"
								selected={this.state.startDate}
								onChange={this.handleChangeDate}
								maxDate={this.props.week_end!=null ? moment(this.props.week_end):moment()}
								minDate={this.props.week_start!=null ? moment(this.props.week_start):null}
								placeholderText=""
								dateFormat="YYYY-MM-DD"
							/>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="12">
							
							  <Label>Time in<span className="text-danger">*</span></Label>
							  <TimePicker
								className="mb-2"
								placeholderText=""
								autoComplete="off"
								name="entryTimeIn"
								selected={this.state.time_slot}
								onChange={this.handleChangeTime}
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={5}
								dateFormat="HH:mm a"
                                timeCaption="Time"
                                popperPlacement="bottom"
								popperModifiers={{
									flip: {
										behavior: ["bottom"] // don't allow it to flip to be above
									},
									preventOverflow: {
										enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
									},
									hide: {
										enabled: true // turn off since needs preventOverflow to be enabled
									}
								}}
							/>
							
							
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							 <Label>Time Out<span className="text-danger">*</span></Label>
							  <TimePicker
								className="mb-2"
								placeholderText=""
								autoComplete="off"
								name="entryTimeOut"
								selected={this.state.time_slot_end}
								onChange={this.handleChangeTimeend}
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={5}
								dateFormat="HH:mm a"
                                timeCaption="Time"
                                popperPlacement="bottom"
								popperModifiers={{
									flip: {
										behavior: ["bottom"] // don't allow it to flip to be above
									},
									preventOverflow: {
										enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
									},
									hide: {
										enabled: true // turn off since needs preventOverflow to be enabled
									}
								}}
							/>
						</Colxx>					
					</Row>
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-time" >
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default AddListModal;
