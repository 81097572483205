import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class MoveToWarehouseModal extends Component{
	constructor(props) {
		super(props);
		
		this.state={
			shelves:[],
			shelf:null,
			shelf_id:null,
			warehouse_id:null
		}
		this.handleSubmit = this.handleSubmit.bind(this);	
		this.changeWarehouse = this.changeWarehouse.bind(this);	
	}
	
	componentDidMount() {		
		var warehouse = null;
		if(this.props.warehouses.length){
			warehouse=this.props.warehouses[0];
		}
		
		this.changeWarehouse(warehouse);
		
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("move-package-to-warehouse").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("movePackageToWarehouseForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('package_id',this.props.product_package.package_id);
			
			axios.post(REST_API_END_POINT+'inventory/move-to-warehouse',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById(this.props.api_message);
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("move-package-to-warehouse").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("move-package-to-warehouse").disabled=false;
			});
		}
	}
	
	changeWarehouse(warehouse){
		if(warehouse!=null){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('warehouse_id',warehouse.id);
			
			axios.post(REST_API_END_POINT+'inventory/get-shelves',formData).then((res)=>{
				var data = res.data;
				
				if(data.status===1){
					var shelves = data.shelves;
					
					shelves = shelves.map((element,index)=>{
						element.label=element.name;
						element.value=element.id;
						element.key=index;
						return element;
					});

					var shelf = null;
					var shelf_id = null;
					if(shelves.length){
						shelf = shelves[0];
						shelf_id = shelves[0].id;
					}
					
					this.setState({
						shelves,
						shelf,
						shelf_id,
						warehouse_id:warehouse.id
					});
				}else{
					window.location="/app/inventory/inventory";
				}
							
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}else{
			this.setState({
				shelves:[],
				shelf:null,
				shelf_id:null,
				warehouse_id:null
			});
		}
	}
	
	
	render() {
		const qty_type = DEFAULT_QTY_TYPES.find(r=>{ return r.value==this.props.product.qty_type });
		const product_package = this.props.product_package;
		var quantity=product_package.quantity;
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
			  <ModalHeader toggle={this.props.toggleModal}>
				Move to warehouse
			  </ModalHeader>
			  <AvForm
					id="movePackageToWarehouseForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Warehouse<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="move_warehouse_id"
									name="warehouse_id"
									type="select"
									value={this.state.warehouse_id}
									onChange={e=>{
										var warehouse = this.props.warehouses.find(r=>{ return r.id==e.target.value })
										this.changeWarehouse(warehouse);
									}}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								>
								{this.props.warehouses.map((row,index)=>{
									return <option value={row.value} key={index}>{row.label}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>Shelf<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="move_warehouse_shelf"
									name="shelf_id"
									type="select"
									value={this.state.shelf_id}
									onChange={e=>{
										var shelf = this.state.shelves.find(r=>{ return r.id==e.target.value });
										if(shelf!=null){
											this.setState({ shelf_id:shelf.value,shelf });
										}else{
											this.setState({ shelf_id:null,shelf:null });
										}
									}}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								>
								{this.state.shelves.map((row,index)=>{
									return <option value={row.value} key={index} >{row.label}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>Position<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="move_warehouse_position"
									name="position"
									type="number"
									autoComplete="off"
									min="1"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										max: {
											value: this.state.shelf!=null ? this.state.shelf.capacity:99999999,
											errorMessage: "Please enter a value less than or equal to "+(this.state.shelf!=null ? this.state.shelf.capacity:99999999)
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>Quantity ({typeof qty_type!='undefined' ? qty_type.label:""})<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="move_warehouse_quantity"
									name="quantity"
									type="number"
									autoComplete="off"
									value={product_package.remainig_to_fill}
									min="0"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="move-package-to-warehouse" >
						<IntlMessages id="customer.save" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};