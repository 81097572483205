import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, CardImg, Badge, Label } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import IntlMessages from "../../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { REST_API_END_POINT,themeColor } from "../../../constants/defaultValues";
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class PaymentReports extends Component {
	
	constructor(props) {
		super(props);

		this.dataListRender = this.dataListRender.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.exportToSpreadsheet = this.exportToSpreadsheet.bind(this);
		this.state = {
			blocking: false,
			subscriber_id:{ label: "All", value: "", key: 1 },
			payment_gateway:{ label: "All", value: "", key: 1 },
			start_date:null,
			end_date:null,
			items:[],
			subscribers:[{ label: "All", value: "", key: 1 }],
			exportData:[]
		};
	}
	
	componentDidMount() {
		this.dataListRender();
	}
	 
	dataListRender() {
		this.setState({ blocking: true });
		
		const {
			subscriber_id,
			payment_gateway,
			start_date,
			end_date
		} = this.state;
		
		var formData = new FormData();
		
		if(subscriber_id!=null){
			if(subscriber_id.value!=""){
				formData.append('subscriber_id',subscriber_id.value);
			}
		}
		
		if(payment_gateway!=null){
			if(payment_gateway.value!=""){
				formData.append('payment_gateway',payment_gateway.value);
			}
		}
		
		if(start_date!=null){
			formData.append('start_date',moment(start_date.format('YYYY-MM-DD')+' 00:00:00').unix());
		}
		
		if(end_date!=null){
			formData.append('end_date',moment(end_date.format('YYYY-MM-DD')+' 23:59:59').unix());
		}
		
		axios.post(REST_API_END_POINT+'admin/payments-reports',formData).then(res => {
			return res.data;
		}).then(data => {
			var subscribers=[{ label: "All", value: "", key: 1 }];
			for(let subscriber of data.subscribers){
				subscribers.push({ label: subscriber.first_name+" "+subscriber.last_name, value: subscriber.id, key: subscriber.id })
			}
			this.setState({
				items: data.payments,
				subscribers:subscribers,
				blocking: false
			});
		});
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
	
	exportToSpreadsheet = () => {
		var data = this.state.items;
		var data_export = [];
		
		for(let key in data){
			var row = data[key];
			data_export.push([
				row.invoice_no, 
				row.subscriber_name, 
				row.subscription_name, 
				moment(row.timestamp*1000).format("MM/DD/YYYY"),
				"$"+row.amount,
				row.transaction_id,
				parseInt(row.status)==1 ? "Success":"Declined",
				row.payment_gateway
			]);
		}
		var exportData = [{
			columns: [
				"Invoice #", 
				"Subscriber Name", 
				"Plan", 
				"Date", 
				"Amount", 
				"Transaction ID",
				"Status",
				"Gateway"
			],
			data: data_export
		}];
		
		this.setState({
			exportData: exportData
		},()=>{
			setTimeout(function(){
				document.getElementById('export_btn').querySelector("button").click();
			}, 500);
		});
	};
	
	
	render() {
		let data = this.state.items;
				
		const dataTableColumns = [
			{
				Header: "Invoice #",
				accessor: "invoice_no",
				Cell: props => props.value
			},
			{
				Header: "Subscriber Name",
				accessor: "subscriber_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Plan",
				accessor: "subscription_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Date",
				accessor: "timestamp",
				Cell: props => moment(props.value*1000).format("MM/DD/YYYY")
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: props => "$"+props.value
			},
			{
				Header: "Transaction ID",
				accessor: "transaction_id",
				Cell: props => props.value
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props =>{ if(parseInt(props.value)==1){ return "Success" }else{ return "Declined" } }
			},
			{
				Header: "Gateway",
				accessor: "payment_gateway",
				Cell: props => props.value
			}
		];	
				
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.payments-reports" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row className="mb-4">			  
										<Colxx xxs="12">
											<AvForm
												id="payment_filter_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											  >
												<Row>
													<Colxx md="6">
														<AvGroup className="error-t-negative">
															<Label>Subscriber</Label>
															<Select
																className="react-select col-lg-12 p-0"
																classNamePrefix="react-select"
																name="subscriber_id"
																placeholder="Select subscriber"
																value={this.state.subscriber_id}
																onChange={subscriber_id=>{ this.setState({ subscriber_id }) }}
																options={this.state.subscribers}
															/>
														</AvGroup>
													</Colxx>
													<Colxx md="6">
														<AvGroup className="error-t-negative">
															<Label>Gateway</Label>
															<Select
																className="react-select col-lg-12 p-0"
																classNamePrefix="react-select"
																name="payment_gateway"
																placeholder="Select gateway"
																value={this.state.payment_gateway}
																onChange={payment_gateway=>{ this.setState({ payment_gateway }) }}
																options={[
																		{ label: "All", value: "", key: 1 },
																		{ label: "Authorize.net", value: "Authorize.net", key: 2 },
																		{ label: "Stripe", value: "Stripe", key: 3 },
																	]}
															/>
														</AvGroup>
													</Colxx>
													<Colxx md="6">
														<AvGroup className="error-t-negative">
															<Label>Start Date</Label>
															<DatePicker
																className="mb-2"
																placeholderText="Start Date"
																dateFormat="MM/DD/YYYY"
																selected={this.state.start_date}
																onChange={start_date=>{ 
																	if(this.state.end_date!=null){
																		if(this.state.end_date<start_date){
																			swal("Warning!", "Start date should be less than end date.", "warning");
																			return;
																		}
																	}
																	this.setState({ start_date }) 
																}}
															/>
														</AvGroup>
													</Colxx>
													<Colxx md="6">
														<AvGroup className="error-t-negative">
															<Label>End Date</Label>
															<DatePicker
																className="mb-2"
																placeholderText="End Date"
																dateFormat="MM/DD/YYYY"
																selected={this.state.end_date}
																onChange={end_date=>{ 
																	if(this.state.start_date!=null){
																		if(this.state.start_date>end_date){
																			swal("Warning!", "End date should be greater than start date.", "warning");
																			return;
																		}
																	}
																	this.setState({ end_date }) 
																}}
															/>
														</AvGroup>
													</Colxx>
													<Colxx md="12">									 
														<Button color="primary" >
														  <IntlMessages id="pages.submit" />
														</Button>{" "}<Button color="primary" type="button" onClick={this.exportToSpreadsheet} >
															Export to Excel
														</Button>
													</Colxx>
												</Row>
										   </AvForm>
										</Colxx>
									</Row>			  
									<Row>			  
										<Colxx xxs="12">
											<div className="mb-4 dropdown-edit">					
												<ReactTable
													data={data}
													columns={dataTableColumns}
													defaultPageSize={10}
													filterable={false}
													showPageJump={false}
													PaginationComponent={DataTablePagination}
													showPageSizeOptions={true}
													defaultSorted={[{id: "id",asc: true}]}
												/>
											</div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
					<Row className="d-none" id="export_btn">
						<ExcelFile filename={"payments-"+moment().format('MM-DD-YYYY')}>
							<ExcelSheet dataSet={this.state.exportData} name="Payments" />
						</ExcelFile>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
