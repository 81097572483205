import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, DropdownItem, CustomInput, Badge, DropdownMenu } from "reactstrap";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {REST_API_END_POINT, REST_API_BASE_URL,dataEncryptKey,JQUERY_MODULE} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import printJS from 'print-js'

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class drawerview extends Component {
	
	constructor(props) {
		super(props); 
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
		this.printLabel = this.printLabel.bind(this);
		
		this.state = {		  
			modalOpenAdd: false,		  
			search_cash: '',
			search_cash_taken: '',
			drawer:null,
			currencies:[],
			currency_code:'USD',
		};
	}
	
	dataListRender() {
		let drawer;
		let cash;
		let cashtaken;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('detail_id',this.props.match.params.detail_id);
		formData.append('currency_code',this.state.currency_code);

		axios.post(REST_API_END_POINT+'report/cash-close-detail',formData).then((res)=>{
			var data = res.data;
			drawer=data.drawer;
			cash=data.cash;
			cashtaken=data.cash_taken;
			var currencies=data.currencies;
				
			this.setState({				
				drawer,
				cash,
				cashtaken,
				currencies
			},()=>{
				this.createDataTable()
			});			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	componentDidMount() {		
		this.dataListRender();
	}
	
	createDataTable(){		
		this.$el1 = $(this.el1);			
		this.$el1.DataTable().clear().destroy();
		this.$el1.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('cash'),
				aaSorting:[],
				order: [[ 15, "desc" ]],
				buttons: [
					'csv', 'excel', {
							extend: 'pdf',
							orientation: 'landscape',
							pageSize: 'A4',
						}, 'print'
				]
			});
			
		this.$el2 = $(this.el2);			
		this.$el2.DataTable().clear().destroy();
		this.$el2.DataTable({
				dom: 'Bfrtip',
				data: this.makeArray('cashtaken'),
				aaSorting:[],
				order: [[ 15, "desc" ]],
				buttons: [
					'csv', 'excel', {
							extend: 'pdf',
							orientation: 'landscape',
							pageSize: 'A4',
						}, 'print'
				]
			});
			
	}
	
	makeArray(key){
		const reports = this.state[key];
		var formated_data=[];		
		for(let row of reports){
			formated_data.push([
				row['1_s'],
				row['2_s'],
				row['5_s'],
				row['10_s'],
				row['20_s'],
				row['50_s'],
				row['100_s'],
				row['cents'],
				row['nickles'],
				row['dimes'],
				row['quarters'],
				row['50_cents'],
				row['1_d'],
				row['total'],
				row['added_by'],
				row['added_at_date'],
			])
		}
		
		return formated_data;
	}
	
	printLabel(){
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);		
		printJS(REST_API_BASE_URL+"print-cash-close-detail/"+this.props.match.params.detail_id+"/"+facility_id+"/"+this.state.currency_code);
    }
	 
	render() {
		const currencies = this.state.currencies;
		
		var drawer = this.state.drawer;	
		
		if(this.state.drawer!=null){
			var total = parseInt(drawer['1_s'])+parseInt(drawer['2_s'])*2+parseInt(drawer['5_s'])*5+parseInt(drawer['10_s'])*10+parseInt(drawer['20_s'])*20+parseInt(drawer['50_s'])*50+parseInt(drawer['100_s'])*100+parseInt(drawer['cents'])*0.01+parseInt(drawer['nickles'])*0.05+parseInt(drawer['dimes'])*0.1+parseInt(drawer['quarters'])*0.25+drawer['50_cents']*0.5+parseInt(drawer['1_d'])
		}else{
			var total=0;
		}
		
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.state.currency_code });
		var bank_total_view = [];
		
		if(typeof selected_currency!='undefined'){			
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){
				bank_total_view.push(<Fragment>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<h1 className="font-weight-bold mt-3">{drawer? ((drawer[key]!=null)?drawer[key]:'0'):'0' }</h1>
									</Colxx>
								</Fragment>)
				
			}
		}
		
	
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.cash-close-detail" match={this.props.match} />
					<Separator className="mb-5" />
				</Colxx>
			</Row>
		
		<Card className="p-4">
			<Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
				<Colxx xxs="12">
					<div className="float-sm-left" style={{width:'200px'}}>
						<AvForm
							className="av-tooltip tooltip-right-bottom d-none"
							onSubmit={()=>{}}
						>
							<AvField
								type="select"
								name="currency_code"
								id="currency_code"
								value={this.state.currency_code}
								onChange={(e)=>{ this.setState({ currency_code:e.target.value },()=>{ this.dataListRender() }) }}
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							>
								{currencies.map(opt=>{
									return <option key={opt.id} value={opt.currency_code} >{opt.country_name+" - "+opt.currency_code}</option>
								})}
							</AvField>
						</AvForm>
					</div>
				</Colxx>
			
				<Colxx xxs="12">
					<h1 className="font-weight-bold mt-3"><span className="font-weight-normal">Assigned to:</span> {drawer? ((drawer['assigned_to']!=null)?drawer['assigned_to']:''):'' }</h1>
				</Colxx>
				
				<Colxx xxs="9">
					<Row className="currency-img align-items-center">
						{bank_total_view}
					</Row>
				
					<Row className="mt-5">
						<Colxx xxs="5">
							<h1>Total</h1>	
						</Colxx>
						<Colxx xxs="7">
							<h1>{total.toFixed(2)}</h1>		
						</Colxx>
					</Row>
				</Colxx>
			
			</Row>
			{currencies.length!=0 ? <Row className="mt-3">				
				<Colxx xxs="12">
					<Button color="primary" type="button" onClick={this.printLabel}>Print Denominations</Button>		
				</Colxx>
			</Row>:""}
			
			
			<Row className="mt-4">
				<Colxx xxs="12">
					<div className="mb-4 dropdown-edit">
						<h2>Cash Added to Drawer</h2>
						<table className="display table table-bordered table-striped" ref={el=>this.el1=el} >
							<thead>
								<tr>				
									<th>1's</th>
									<th>2's</th>
									<th>5's</th>
									<th>10's</th>
									<th>20's</th>
									<th>50's</th>
									<th>100's</th>
									<th>Cents</th>
									<th>Nickels</th>
									<th>Dimes</th>
									<th>Quarters</th>
									<th>50 Cents</th>
									<th>Dollar</th>
									<th>Total</th>
									<th>Added By</th>
									<th>Added At</th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
					
					<div className="mb-4 dropdown-edit">
						<h2>Cash Taken From Drawer</h2>
						<table className="display table table-bordered table-striped" ref={el=>this.el2=el} >
							<thead>
								<tr>				
									<th>1's</th>
									<th>2's</th>
									<th>5's</th>
									<th>10's</th>
									<th>20's</th>
									<th>50's</th>
									<th>100's</th>
									<th>Cents</th>
									<th>Nickels</th>
									<th>Dimes</th>
									<th>Quarters</th>
									<th>50 Cents</th>
									<th>Dollar</th>
									<th>Total</th>
									<th>Taken By</th>
									<th>Taken At</th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
					</div>
				</Colxx>
			</Row>		
		</Card>
      </Fragment>
    );
  }
}
