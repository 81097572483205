import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Shipperinformation extends Component {
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			facility_data:null
		};
	}
	
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
	
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'transportation/edit-facility-data',formData).then((res)=>{			
			var data = res.data;
			
			var facility_data=data.facility;			
			this.setState({ 
				facility_data
			});									
			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		}); 
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
				
			document.getElementById("update-shipper-information").disabled=true;
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("shipperForm"));
			formData.append('facility_id',facility_id);
			
			axios.post(REST_API_END_POINT+'transportation/edit-facility',formData).then((res)=>{				
				var data = res.data;
				
				swal("Success!",data.msg,"success");
				
				document.getElementById("update-shipper-information").disabled=false;
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-shipper-information").disabled=false;
			});
				

		}
	}
  
	render() {
		if(this.state.facility_data!=null){
			var facility_data=this.state.facility_data;
		}else{
			var facility_data;
		}
		
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.shipper-information" match={this.props.match} />
					<Separator className="mb-5" />
				  </Colxx>
				</Row>
				
				<Card className="p-4">
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
					
					<AvForm
						id="shipperForm"
						className="av-tooltip tooltip-right-bottom"
						onSubmit={this.handleSubmit}
					  >
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>State License #</Label>
								  <AvField
									id="license_number"
									name="license_number"
									placeholder="M11-18-000231-TEMP"
									value={ facility_data?((facility_data.license_number!=null)?facility_data.license_number:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Type of License</Label>
								  <AvField
									id="type_of_license"
									name="type_of_license"
									placeholder="State Licensed"
									value={ facility_data?((facility_data.type_of_license!=null)?facility_data.type_of_license:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>GSTIN/UIN</Label>
								  <AvField
									id="gstin"
									name="gstin"
									placeholder="GSTIN/UIN"
									value={ facility_data?((facility_data.gstin!=null)?facility_data.gstin:''):'' }
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										pattern: {
											value: "^[A-Z0-9]+$",
											errorMessage: "Invalid GSTIN"
										},
										minLength: {
											value: 15,
											errorMessage: "The GSTIN must be a 15 digits alpha-numeric number"
										},
										maxLength: {
											value: 15,
											errorMessage: "The GSTIN must be a 15 digits alpha-numeric number"
										}
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Business Name</Label>
								  <AvField
									id="facility_name"
									name="facility_name"
									placeholder="Business Name"
									value={ facility_data?((facility_data.facility_name!=null)?facility_data.facility_name:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Business Address</Label>
								  <AvField
									id="facility_address"
									name="facility_address"
									placeholder="16276 Koala Rd. "
									value={ facility_data?((facility_data.facility_address!=null)?facility_data.facility_address:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>City, State, ZIP Code</Label>
								  <AvField
									id="facility_zip_code"
									name="facility_zip_code"
									placeholder="Adelanto, CA 92301"
									value={ facility_data?((facility_data.facility_zip_code!=null)?facility_data.facility_zip_code:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Email</Label>
								  <AvField
									id="facility_email"
									name="facility_email"
									placeholder="info@tridant.com"
									value={ facility_data?((facility_data.facility_email!=null)?facility_data.facility_email:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Phone Number</Label>
								  <AvField
									id="facility_phone"
									name="facility_phone"
									placeholder="123-456-7890"
									value={ facility_data?((facility_data.facility_phone!=null)?facility_data.facility_phone:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Contact Name</Label>
								  <AvField
									id="contact_name"
									name="contact_name"
									placeholder="John Doe"
									value={ facility_data?((facility_data.contact_name!=null)?facility_data.contact_name:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Bank Name</Label>
								  <AvField
									id="bank_name"
									name="bank_name"
									value={ facility_data?((facility_data.bank_name!=null)?facility_data.bank_name:''):'' }
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>A/c No.</Label>
								  <AvField
									id="bank_account_no"
									name="bank_account_no"
									type="text"
									value={ facility_data?((facility_data.bank_account_no!=null)?facility_data.bank_account_no:''):'' }
									validate={{
									  number: {
										value: true,
										errorMessage: "Only numbers are allowed."
									  },
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Branch & IFS Code</Label>
								  <AvField
									id="branch_ifsc"
									name="branch_ifsc"
									type="text"
									value={ facility_data?((facility_data.branch_ifsc!=null)?facility_data.branch_ifsc:''):'' }
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="mt-4">					
							<Colxx lg="12">
								<Button
									id="update-shipper-information"
									color="primary"
									size="sm"
									className="mr-2"							  
								>
									Submit						  
								</Button>					
							</Colxx>
						</Row>
					</AvForm>
				</Card>			
			</Fragment>
		);
	}
}
