import React, { Component } from "react";
import { AvForm, AvField, AvGroup,AvRadio,AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Table
 } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectType = [
    { label: "$", value: "1", key: 0 },
    { label: "%", value: "2", key: 1 }
];
  
class AddNewDistributionModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			selectedType:{ label: "$", value: "1", key: 0 },
			is_product:1,
			product_val:[],
			category_val:[],
			selectProducts:[],
			selectCategories:[],
			selectAccounts:[]
		};	
	}
	
	componentDidMount() {		
		var products=[];
		var categories=[];
		var accounts=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'account/get-data-add-distribution',formData).then((res)=>{
			var data = res.data;
		
			products=data.products;
			categories=data.categories;
			accounts=data.accounts;
			
			products= products.map((element,index)=>{
				element.label=element.name+" ("+element.facility_name+")";
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			categories = categories.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			accounts = accounts.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectProducts:products,
				selectCategories:categories,
				selectAccounts:accounts
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			var productVal= this.state.product_val.map(row=>row.value);
			var categoryVal= this.state.category_val.map(row=>row.value);
			
			document.getElementById("add-distribution").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("distributionForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			
			axios.post(REST_API_END_POINT+'account/add-distribution',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-distribution").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
				this.setState({ product_val:[],	category_val:[] });

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-distribution").disabled=false;
			});

		}
	}
	
	showRadio = e =>{	
	   var elements = document.querySelectorAll('input[name="buy_type"]');
		var value='0';
		for (var element of elements) {
			if(element.checked==true){
				value=element.value;
			}
		}
		if(value==='2'){		
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='block';
			this.setState({ is_category_specific:true,is_product_specific:false })
		}else if(value==='1'){		
			document.getElementById('show_products').style.display='block';
			document.getElementById('show_categories').style.display='none';
			this.setState({ is_category_specific:false,is_product_specific:true })		
		}	
	}


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Distribution
		  </ModalHeader>
		  <AvForm
		        id="distributionForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z0-9 ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row >				
					<Colxx lg="12" className="mt-2 mb-2" >
						<Label>Buy Product In </Label>	&nbsp;&nbsp;
						<AvRadioGroup  
							inline 
							name="buy_type" 
							onChange={(e) => this.showRadio(e)}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select any of the options"
							  }
							}}
							defaultValue='1'
							>
							<AvRadio customInput label="Specific Products" value="1" />
							<AvRadio customInput label="Specific Categories" value="2" />
						</AvRadioGroup >			
					</Colxx>
					
				</Row>
				<div id="show_categories" style={{display:(this.state.is_product===2)?'block':'none'}}>
					<Label >Choose Categories</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						name="category_id[]"
						value={this.state.category_val}
						onChange={category_val => { this.setState({ category_val:category_val }) }}
						options={this.state.selectCategories}
					/>
				</div>
				<div id="show_products" style={{display:(this.state.is_product===1)?'block':'none'}} >
					<Label >Choose Products</Label>
					 <Select
						components={{ Input: CustomSelectInput }}
						className="react-select"
						classNamePrefix="react-select"
						isMulti
						name="product_id[]"
						value={this.state.product_val}
						onChange={product_val => { this.setState({ product_val:product_val}) }}
						options={this.state.selectProducts}
					/>
				</div>
				<hr/>
				<Table borderless>
                    <thead>
						<tr>
							<th className="text-muted text-extra-small mb-2">ACCOUNT NAME</th>
							<th className="text-muted text-extra-small mb-2">SHARE</th>
							<th className="text-muted text-extra-small mb-2">SHARE TYPE</th>
						</tr>
                    </thead>					
                    <tbody>
						{this.state.selectAccounts.map(row=>{
							return (<tr key={row.id}>
								<td>{row.name}</td>
								<td><AvGroup className="error-t-negative">						  
								  <AvField
									id={"account_share_"+row.id}
									name={"account_share["+row.id+"]"}
									type="number"
									value="0"
									min="0"
								  />
								</AvGroup></td>
								<td>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										id={"account_share_type_"+row.id}			
										name={"account_share_type["+row.id+"]"}
										defaultValue={this.state.selectedType}
										options={selectType}
									/>									
								</td>                                      
							  </tr> );
						})}
                    </tbody>
                  </Table>							
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-distribution">
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddNewDistributionModal;
