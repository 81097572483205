import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import {
	Row, CustomInput, Button,
	Modal, ModalHeader,	ModalBody, ModalFooter,
	Label,FormGroup
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
  
export default class SendToLab extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("send-to-lab").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("sendToLabForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			formData.append('lab_bucket_id',this.props.bucket_data.bucket_id);		
			
			axios.post(REST_API_END_POINT+'distillate-bucket/send-to-lab',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("send-to-lab").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("send-to-lab").disabled=false;
			});

		}
	}
	
	render() {	
		return (
	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Send To Lab
				</ModalHeader>
				<AvForm
					id="sendToLabForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">						
								<h6>Tests Needed to Run</h6>						  						
							</Colxx>					
						</Row>
					
						<Row className="mt-4 custom-control-inline-100">
							<Colxx lg="12">						
								<AvCheckboxGroup
									inline
									name="test_details[]"
									validate={{
									  required: {
										value: true,
										errorMessage: "Please select at least one test"
									  }
									}}
								>
									
									<AvCheckbox customInput label="Cannabinoid Profile Test" value="1" className="mb-1"/>
									<AvCheckbox customInput label="Moisture Content and Water Activity" value="3" className="mb-1"/>
									<AvCheckbox customInput label="Residual Solvent Detection" value="5" className="mb-1"/>
									<AvCheckbox customInput label="Microbial Impurities" value="2" className="mb-1"/>
									<AvCheckbox customInput label="Foreign Material Analysis" value="4" className="mb-1"/>
									<AvCheckbox customInput label="Pesticide Test" value="6" className="mb-1"/>
								</AvCheckboxGroup >
							</Colxx>								
						</Row>
					
						<Row>
							<Colxx lg="12">
								<AvGroup className="mb-0">
									<Label>Sample Amount to Test<span className="text-danger">*</span></Label>
									<AvField
										id="send_to_lab_qty"
										name="send_to_lab_qty"
										type="number"
										min="0"
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											},
											max:{
												value: this.props.bucket_data!=null ? this.props.bucket_data.quantity:"",
												errorMessage: "Please enter a value less than "+(this.props.bucket_data!=null ? parseFloat(this.props.bucket_data.quantity):"0")
											}
										}}
									/>
									<Label>Grams</Label>
								</AvGroup>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter className="justify-content-start">
						<Button color="primary" id="send-to-lab">
							<IntlMessages id="customer.submit" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
