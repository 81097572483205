import React, { Component } from "react";
import { AvForm, AvField, AvGroup,AvInput } from "availity-reactstrap-validation";
import {
  CustomInput,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import SignaturePad from 'react-signature-pad';

import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class RejectWasteModal extends Component{
  constructor(props) {
    super(props);
		this.submitSignature = this.submitSignature.bind(this);
	}

	submitSignature(event, errors, values) {
		if (errors.length === 0) {
			var signature = this.refs.mySignature;
			if(signature.isEmpty()){
				swal("Please put your signature before approve!");
			}else{
				document.getElementById('add-sign-photo').disabled=true; 
				var attachment = signature.toDataURL();
				
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);			
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('waste_id',this.props.waste_data.id);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('status',3);
				formData.append('reason',values.reason);
				formData.append('sign_agreement_image_hidden',attachment);
				
				axios.post(REST_API_END_POINT+'inventory/approve-waste',formData).then((res)=>{
					var data = res.data;
					if(data.status==1){
						this.props.dataListRender();
						signature.clear();
						this.props.toggleModal();
						swal("Success",data.msg,"success");
					}else{
						swal("Failed",data.msg,"warning");
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		}
	}
  
	render() {
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Sign and Reject
				</ModalHeader>
			  
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.submitSignature}
				>
					<ModalBody>	
						<h5 className="text-danger">This process will return the quantity back to the inventory</h5>
						
						<AvGroup className="mb-3">
							<Label >Reason</Label>		
							<AvField
								name="reason"
								id="reason"
								type="textarea"
								autoComplete="off"
								placeholder=""
								rows="3"
								validate={{
									required: {
										value: true,
										errorMessage:"This field is required"
									},
									minLength: {
										value: 5,
										errorMessage:"Your message must have minimum of 5 characters"
									}
								}}
							/>
						</AvGroup>
						
						<div className="left-aligned-sign">
							<SignaturePad ref="mySignature" />
						</div>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button color="danger" id="add-sign-photo" >
							Reject
						</Button>
						{" "}
						<Button type="button" onClick={()=>{
							var signature = this.refs.mySignature;
							signature.clear();
						}}>
							Clear
						</Button>{" "}
						<Button outline type="button" onClick={this.props.toggleModal}>
							Cancel
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};