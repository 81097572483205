import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {	
  CustomInput,
  Button,
  Label,
  Card,
  Input, CardBody
} from "reactstrap";
import swal from 'sweetalert';
import IntlMessages from "../../helpers/IntlMessages";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { NavLink } from "react-router-dom";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class MyTime extends Component {
	constructor(props) {
		super(props);
		this.state = {
			week:null,
		};
	}
	
	componentDidMount() {
		var user_id = localStorage.getItem('user_id');
		var formData = new FormData();
		let week;
		formData.append('user_id',user_id);

		axios.post(REST_API_END_POINT+'staff/staff-time',formData).then((res)=>{
			var data = res.data;
			week=data.weeks;
			this.setState({ 
				week,	
			});			
		})
	}
	 
  
	render() {
		if(this.state.week!=null){
			var week=this.state.week;
		}else{
			var week;
		}
		
		var user_id = localStorage.getItem('user_id');
		
		return( 
			<Fragment>
				<h2>Recent Activities</h2>
				<hr/>
				
				<div className="mb-4 dropdown-edit">
					{week ?<Row className="mb-2">
						{week.map((row,index)=>{
						  return (<Colxx xxs="6" sm="4" md="3" lg="3" className="mb-3" key={index}>		  
							<NavLink to={"/app/staffmanagement/listtime/"+row.year+"/"+row.week_number+"/"+row.user_id} >
								<Card className="list-time-special-bg">
									<CardBody className="text-center pl-1 pr-1 pt-2 pb-2 timezone-slots">
										<p className="text-center font-weight-semibold text-muted mb-0">Year: {row.year}</p>
										<p className="lead text-center mb-2">Week: {row ? ((row.week_number!=null)?row.week_number:''):'' }</p>
										<Separator/> 
										<p className=" mt-3 font-weight-semibold mb-0" >{row ? ((row.first_day!=null)?row.first_day:''):'' } through {row ? ((row.last_day!=null)?row.last_day:''):'' }</p>
										<p className="font-weight-semibold mb-0">Hours: {row ? ((row.total_hours!=null)?row.total_hours:''):'' }</p>							
									</CardBody>
								</Card>
							</NavLink>
						</Colxx>)})}
					</Row> :""}
				</div>       
			</Fragment>
		);
	}
  
};
