import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  Row
} from "reactstrap";
import { Colxx } from "../../../components/common/CustomBootstrap";
import IntlMessages from "../../../helpers/IntlMessages";

import { REST_API_END_POINT,themeColor } from "../../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class EditModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state={
			edit_data:null,
			apply_type:1,
			auto_apply:false,
			start_date:null,
			end_date:null,
		}
	}
  
	componentDidUpdate(prevProps, prevState) {
		if(typeof(this.props.discount_id)==='string'&&this.props.discount_id!==''&&prevProps.modalOpen===false){
			var formData = new FormData();
			formData.append('discount_id',this.props.discount_id);
			
			this.setState({ blocking:true });
			
			axios.post(REST_API_END_POINT+'admin/get-discount-by-id',formData).then((res)=>{ 
				if(res.data.status==1){
					var edit_data = res.data.data;
					var start_date=null, end_date=null;
					if(typeof edit_data.start_date!='undefined' && edit_data.start_date!=null){
						start_date = moment(edit_data.start_date*1000);
					}
					if(typeof edit_data.end_date!='undefined' && edit_data.end_date!=null){
						end_date = moment(edit_data.end_date*1000);
					}
					
					this.setState({
						edit_data: edit_data,
						apply_type:edit_data.apply_type,
						auto_apply:edit_data.auto_apply==1 ? true:false,
						start_date,
						end_date,
						blocking:false
					});
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		}
	}

	async handleSubmit(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			this.setState({ blocking:true });
			document.getElementById("update-discount").disabled = true;
			
			var data_upload = new FormData(document.getElementById("discount_form"));
			data_upload.append('added_by', localStorage.getItem('user_id') );
			data_upload.append('auto_apply', this.state.auto_apply ? 1:0 );
			data_upload.set('discount_code', values.discount_code.toUpperCase() );
			data_upload.append('discount_id',this.props.discount_id);
			
			if(this.state.start_date!=null){
				data_upload.append('start_date', this.state.start_date.unix());
			}
			if(this.state.end_date!=null){
				data_upload.append('end_date', this.state.end_date.unix());
			}
			
			axios.post(REST_API_END_POINT+'admin/update-discount',data_upload).then((data)=>{ 
				if(data.data.exist){
					swal("Discount already exist!", "You have already used this code for another discount in our system.", "error");
				}else if(data.data.status==1){
					this.props.toggleModal();				
					this.props.dataListRender();
					this.setState({ apply_type:1, auto_apply:false,	start_date:null, end_date:null });					
					swal("Success!", "Discount has been updated successfully!", "success");				
				}else{
					swal("Failed to update discount!", "Please try after sometime.", "error");			
				}
				this.setState({ blocking:false });
				document.getElementById("update-discount").disabled = false;
			}).catch(e => console.log("Addition failed , Error ", e));
			
		  
		}
	}

	render() {
		var edit_data = this.state.edit_data;
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
			  <ModalHeader toggle={this.props.toggleModal}>
				Edit Discount
			  </ModalHeader>
			  <AvForm
					id="discount_form"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
			  <ModalBody>
				
					<AvGroup className="error-t-negative">
					  <Label>Name</Label>
					  <AvField
						name="name"
						type="text"
						autoComplete="off"
						value={edit_data!=null ? edit_data.name:""}
						validate={{
						  required: {
							value: true,
							errorMessage: "Please enter the name"
						  },
						  minLength: {
							value: 2,
							errorMessage:
							  "Your name must be between 2 and 150 characters"
						  },
						  maxLength: {
							value: 150,
							errorMessage:
							  "Your name must be between 2 and 150 characters"
						  }
						}}
					  />
					</AvGroup>
					
					<AvGroup className="error-t-negative">
					  <Label>Discount Code</Label>
					  <AvField
						name="discount_code"
						type="text"
						autoComplete="off"
						className="text-uppercase"
						value={edit_data!=null ? edit_data.discount_code:""}
						validate={{
							required: {
								value: true,
								errorMessage: "Please enter the discount code"
							},
							pattern: {
								value: "^[a-zA-Z0-9 ]+$",
								errorMessage: "Invalid discount code"
							}
						}}
					  />
					</AvGroup>
					
					<Row>
						<Colxx md="6">
							<AvGroup className="error-t-negative">
								<Label>Start Date</Label>
								<DatePicker
									className="mb-2"
									placeholderText="Start Date"
									dateFormat="MM/DD/YYYY"
									selected={this.state.start_date}
									onChange={start_date=>{ 
										console.log(start_date);
										if(this.state.end_date!=null && start_date!=null){
											if(this.state.end_date<start_date){
												swal("Warning!", "Start date should be less than end date.", "warning");
												return;
											}
										}								
																			
										this.setState({ start_date }) 
									}}
								/>
							</AvGroup>
						</Colxx>
						<Colxx md="6">
							<AvGroup className="error-t-negative">
								<Label>End Date</Label>
								<DatePicker
									className="mb-2"
									placeholderText="End Date"
									dateFormat="MM/DD/YYYY"
									selected={this.state.end_date}
									onChange={end_date=>{ 
										if(this.state.start_date!=null && end_date!=null){
											if(this.state.start_date>end_date){
												swal("Warning!", "End date should be greater than start date.", "warning");
												return;
											}
										}
										this.setState({ end_date }) 
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx md="6">
							<AvGroup className="error-l-100 error-t-negative">
							  <Label>Discount Amount</Label>
							  <AvField
								name="amount"
								type="text"
								autoComplete="off"
								value={edit_data!=null ? edit_data.amount:""}
								validate={{
								  number: {
									value: true,
									errorMessage: "Please enter a valid number"
								  },
								  required: {
									value: true,
									errorMessage: "Please enter the amount"
								  },
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx md="6">
							<AvGroup className="error-l-100 error-t-negative">
							  <Label>Discount Type</Label>
							  <AvField
								name="apply_type"
								type="select"
								autoComplete="off"
								value={this.state.apply_type}
								onChange={(e)=>{ this.setState({ apply_type:e.target.value }) }}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select the discount type"
								  },
								}}
							  >
								<option value="1">On First payment</option>
								<option value="2">Lifetime</option>
							  </AvField>
							</AvGroup>
						</Colxx>
					</Row>
					
					{/*<AvGroup className="error-t-negative">
						<Label>Auto Apply</Label><br/>
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.auto_apply}
								onChange={(auto_apply)=>{ this.setState({ auto_apply }) }}
							/>
						</Label>
					</AvGroup>*/}
								 
			  </ModalBody>
			  <ModalFooter>
				<Button color="primary" id="update-discount" >
				  <IntlMessages id="pages.submit" />
				</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
				  <IntlMessages id="pages.cancel" />
				</Button>{" "}
			  </ModalFooter>
			   </AvForm>
			</BlockUi>
	  </Modal>
	  );
  }
};
