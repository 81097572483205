import React, { PureComponent } from 'react';
import moment from 'moment';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, CustomInput,InputGroup, InputGroupAddon, Label } from "reactstrap";
import { Colxx } from "./CustomBootstrap";

class ImageCropper extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			src: null,
			crop: {
				unit: 'px',
				width: 360,
				aspect: 1 / 1,
			},
			is_minwidth:true
		};
	}
  
	onChange = e => {
		switch (e.target.name) {
		  case 'thumbnail_crop':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				const reader = new FileReader();
				reader.addEventListener('load', () => this.setState({ src: reader.result })	);
				reader.readAsDataURL(e.target.files[0]);
			}else{
				this.setState({ src: null,is_minwidth:true });
				this.props.updateCroppedImageUrl(null,true);
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
			break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			this.setState({ src: null,is_minwidth:true });
			this.props.updateCroppedImageUrl(null,true);
		}
	};
	
	// If you setState the crop in here you should return false.
	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropComplete = crop => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop, percentCrop) => {
		this.setState({ crop });
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(this.imageRef,crop,'category-image'+moment().unix()+'.png');
			this.setState({ croppedImageUrl });
			this.props.updateCroppedImageUrl(croppedImageUrl,this.state.is_minwidth)
		}
	}

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');
		
		if(image.width<320 || image.height<320){
			this.setState({ is_minwidth:false });
		}else{
			this.setState({ is_minwidth:true });
		}

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					//reject(new Error('Canvas is empty'));
					console.error('Canvas is empty');
					return;
				}
				blob.name = fileName;				
				this.fileUrl = blob;
				resolve(this.fileUrl);
			}, 'image/png');
		});
	}
  
  render() {
    const { crop, croppedImageUrl, src } = this.state;

    return (
      <div>
        <Row className="mt-4">
			<Colxx lg="12" >						
				<AvGroup className="error-l-125 error-t-negative">
					<Label>{this.props.label}</Label>
					<InputGroup className="mb-3">
					  <InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
					  <CustomInput
						type="file"
						id=""
						name="thumbnail_crop"
						accept="image/*"
						onChange={this.onChange}
					  />
					</InputGroup>
				</AvGroup>
			</Colxx>
		</Row>
		{!this.state.is_minwidth ? <p className="text-danger">Minimum required dimension of the image is 320x320</p>:""}
        {src && (<div className="mb-2">
			<ReactCrop
				src={src}
				crop={crop}
				ruleOfThirds
				minWidth={320}
				keepSelection={true}
				onImageLoaded={this.onImageLoaded}
				onComplete={this.onCropComplete}
				onChange={this.onCropChange}
			/>
        </div>)}
      </div>
    );
  }
}
export default ImageCropper;
