import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardHeader, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { zeroPad } from "../../helpers/Utils";

import {REST_API_END_POINT, dataEncryptKey,JQUERY_MODULE } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
var dataCrypto = new SimpleCrypto(dataEncryptKey);
const quillModules = {
	toolbar: [
	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	  ["bold", "italic", "underline"],
	   // dropdown with defaults from theme	
	  [
		{ list: "ordered" },
		{ list: "bullet" },
		{ indent: "-1" },
		{ indent: "+1" }
	  ],    	    
	  
	]
  };
  
  const quillFormats = [
	"header",
	"bold",
	"italic",
	"underline", 
	"list",
	"bullet",
	"indent", 
  ];
export default class EditInvoiceQuotation extends Component{
	constructor(props) {
		super(props);
		
		this.state = {
			invoice_date: null,
			due_date: null,
			order_data: null,
			textQuillStandart:null,
			quotationNo:null,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
	
    }
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'purchase-order/get-order-detail',formData).then((res)=>{
					var data = res.data;
					
					if(data.status==1){
						let invoice_date = moment(data.order_data.timestamp*1000);
						let due_date = data.order_data.due_date!=null ? moment(data.order_data.due_date*1000):moment(data.order_data.timestamp*1000).add(14,'days');
						
						this.setState({ 
							invoice_date,
							due_date,
							order_data:data.order_data,
							textQuillStandart:data.order_data.notes,
							quotationNo:data.order_data.quotation_no,
						});
						
					}
					else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
	
		
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }	
	handleChangeDate = date =>{
		this.setState({ invoice_date:date })
	}
	
	handleChangeDueDate = date =>{
		this.setState({ due_date:date })
	}
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			if(this.state.invoice_date==null){
				swal("Warning","Please enter invoice date","warning");
				return;
			}
			if(this.state.due_date==null){
				swal("Warning","Please enter due date","warning");
				return;
			}
			if(moment(this.state.invoice_date.format('YYYY-MM-DD')).unix()>moment(this.state.due_date.format('YYYY-MM-DD')).unix()){
				swal("Warning","Due date must be greater than invoice date","warning");
				return;
			}
			if(this.state.textQuillStandart=='<p><br></p>'||this.state.textQuillStandart==null){
				this.setState({textQuillStandart:""})
			}
			document.getElementById("edit-invoice").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("editInvoiceForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);
			formData.append('notes',this.state.textQuillStandart);

			axios.post(REST_API_END_POINT+'purchase-order/edit-invoice',formData).then((res)=>{
				var data = res.data;
				
				document.getElementById("edit-invoice").disabled=false;
				this.props.dataListRender();
				this.props.toggleModal();
				
				if(data.status==1){
					swal("Success",data.msg,"success");
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("edit-invoice").disabled=false;
			});

		}
	}
	
    render() {
		const { order_data } = this.state;
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Purchase Order
				</ModalHeader>
				
				<AvForm
					id="editInvoiceForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >
						{order_data!=null ? 
							<Row>
								<Colxx md="12">
									<div className="view-order-table">
										<div className="font-weight-bold text-uppercase h4">#{moment(order_data.timestamp*1000).format('YYYY')}/{zeroPad(order_data.order_number,6)}</div>
										<div className="border-bottom pt-2 mb-3" />
									</div>
								</Colxx>
							</Row>:""}
							
						<Row>
							<Colxx md="12">
								<AvGroup>
									<Label>Quotation Date<span className="text-danger">*</span></Label>
									<DatePicker
										ref={el => { if (el && el.input) { el.input.readOnly = true; } }}
										id="invoice_date"
										name="invoice_date"
										autoComplete="off"
										className="mb-5"
										selected={this.state.invoice_date}
										placeholderText=""
										dateFormat="YYYY-MM-DD"
										onChange={ this.handleChangeDate }
										maxDate={moment()}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						
						<Row>
							<Colxx md="12">
								<AvGroup>
									<Label>Due Date<span className="text-danger">*</span></Label>
									<DatePicker
										ref={el => { if (el && el.input) { el.input.readOnly = true; } }}
										id="due_date"
										name="due_date"
										autoComplete="off"
										className="mb-5"
										selected={this.state.due_date}
										placeholderText=""
										dateFormat="YYYY-MM-DD"
										onChange={ this.handleChangeDueDate }
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="">					
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Company Name</Label>
								  <AvField
									id="customer_name"
									name="customer_name"
   								    value={order_data?order_data.customer_name?order_data.customer_name:"":""}
									autoComplete="off"
									type="text"
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="">					
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Contact Person</Label>
								  <AvField
									id="contact_person"
									name="contact_person"
   								    value={order_data?order_data.contact_person?order_data.contact_person:"":""}
									autoComplete="off"
									type="text"
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="">					
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Address</Label>
								  <AvField
									id="address"
									name="address"
   								    value={order_data?order_data.billing_address?order_data.billing_address:order_data.vendor_address?order_data.vendor_address:"":""}
									autoComplete="off"
									type="text"
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row className="">					
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Lpo No</Label>
								  <AvField
									id="lpo"
									name="lpo"
   								    value={order_data?order_data.lpono?order_data.lpono:"":""}
									autoComplete="off"
									type="text"
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx md="10">
								
									<Row className="mt-3">
										<Colxx lg="12" className="mb-2">
											<AvGroup>
												<Label>Terms & Conditions<span className="text-danger"></span></Label>
												<ReactQuill
													theme="snow"
													name="body"
													value={this.state.textQuillStandart}
													onChange={this.handleChangeQuillStandart}
													placeholder=""
													modules={quillModules}
													formats={quillFormats}
												/>
											</AvGroup>
										</Colxx>
									</Row>
									
																	
								
								
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="edit-invoice">
							<IntlMessages id="customer.submit" />
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
  }
};
