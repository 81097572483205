import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
  CustomInput, Badge,
     } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import AddGetStarted from "../../containers/customer/AddGetStarted";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import TemplateApplicationMenu from "../../containers/template/TemplateApplicationMenu";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../components/DatatablePagination";
import AddDepartmentModal from "../../containers/department/AddDepartmentModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Department extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
		  modalOpenAdd: false,
		  modalOpenStaff: false,
		  search: '',
		};
	}
	
	 toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
   toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.taxes.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    } 
     
  deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        		var strain_word="department";
		
		if(selectedIds.length>1){
			strain_word="departmentes";
		}
		
        if(status===1){
            msg="Activate the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been activated.";
            failure_msg="Failed to activate "+strain_word;
        }else if(status===2){
            msg="Delete the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been deleted.";
            failure_msg="Failed to delete "+strain_word;
        }else{
            msg="Deactivate the "+strain_word+" ?";
            success_msg="The "+strain_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+strain_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-department").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('department_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/delete-department-categories',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-department").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-department").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	activeItem=(e,department_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(department_id);
			
		}else{
		  var x=selectedIds.indexOf(department_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.departmentes.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'inventory/get-all-department-categories',formData).then((res)=>{
			var data = res.data;
		
			if(data.department_categories){
				this.setState({
					departmentes: data.department_categories,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					departmentes: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
		componentDidMount(){
	   this.dataListRender();
	}
	
	
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
		
		
		{
			Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
			accessor: "id",
			width:50,
			sortable: false,
			Cell: ( rowInfo )=> {
				return ( <FormGroup>
					<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
					</FormGroup> );
			}
		},
		
		
	  {
		Header: "",
		accessor: "",
		width:50,
		Cell: props => <FormGroup><CustomInput type="checkbox" id="exampleCustomCheckbox"/></FormGroup>
	  },
	  {
		Header: "Name",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Address",
		accessor: "address",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.department" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  <Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New 							  
						</Button>																		
						  {" "}<Button
							color="primary"
							size="sm"
							className=""						
						  >
							<IntlMessages id="pages.delete" />
					  </Button>
					</div>
					
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={2}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
			  </Colxx>
			   <AddDepartmentModal
							modalOpen={this.state.modalOpenAdd}
							toggleModal={this.toggleModalAdd}
						  />
			  		  
			</Row>
		</Card>
      </Fragment>
    );
  }
}
