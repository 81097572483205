import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class AddTestimonialModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);	
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("add-testimonial").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("testimonialForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'website/add-testimonial',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				if(data.status==1){
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				}else{
					api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
				}
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-testimonial").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-testimonial").disabled=false;
			});

		}
	}

	render() {	
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Testimonial
		  </ModalHeader>
		  <AvForm
		        id="testimonialForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Testimonial<span className="text-danger">*</span></Label>
							<AvField
								id="content"
								name="content"
								type="textarea"
								autoComplete="off"
								rows="4"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required"
									}
								}}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Featured Image</Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									name="featured_image"
									accept="image/png,image/jpeg,image/jpg,image/gif"
									onChange={(e)=>{							
										if(e.target.files[0]){
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
										}else{
											e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
										}
									}}
								/>
							</InputGroup>
						</AvGroup>
					</Colxx>					
				</Row>	
			</ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-testimonial" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddTestimonialModal;
