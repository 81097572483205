import React, { Component } from "react";
import { Row, Card, CardTitle, FormText,CustomInput, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import { Formik, Form, Field } from "formik";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { projectTitle,REST_API_END_POINT } from "../../constants/defaultValues";

import axios from 'axios';
import swal from 'sweetalert';

import {
  FormikCustomCheckbox,
} from "../../containers/form-validations/FormikFields";

class Register extends Component {
	constructor(props) {
		super(props);
    
		this.validate = this.validate.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
  
	handleSubmit(values) {
		document.getElementById("register-button").disabled = true;
		
		values = new FormData(document.getElementById("registration"));
		axios.post(REST_API_END_POINT+'register/subscriber',values).then((res)=>{ 
			if(res.data.email_exist){
				swal("Account already exist!", "Your email is already in our system. Please try to login to your account.", "error");
			}else if(res.data.status==1){
				document.getElementById("registration").reset();
				swal("Account has been created!", "A verification email has been sent to your registered email.", "success");
			}else{
				swal("Failed to create an account!", "Please try after sometime.", "error");			
			}
			document.getElementById("register-button").disabled = false;
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			document.getElementById("register-button").disabled = false;
			swal("Failed to create an account!", "Please check your internet connection", "error");
		});	
	}
    
	validate(values) {
        let errors = {};

        if (!values.email) {
            errors.email = 'Please enter your email address';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.first_name) {
            errors.first_name = 'Please enter your first name';
        }  else if (!/^[a-z ,.'-]+$/i.test(values.first_name)) {
            errors.first_name = 'Invalid first name';
        }else if (values.first_name.length<=2) {
            errors.first_name = 'First name must be longer than 2 characters';
        }
		
        if (!/^[a-z ,.'-]*$/i.test(values.last_name)) {
            errors.last_name = 'Invalid last name';
        }else if (values.last_name.length<=2) {
            errors.last_name = 'Last name must be longer than 2 characters';
        }
		
        if (!values.company_name) {
            errors.company_name = 'Please enter your company name';
        } else if (values.company_name.length<=2) {
            errors.company_name = 'Company name must be longer than 2 characters';
        }
		
        if (!values.phone) {
            errors.phone = 'Please enter your phone';
        } else if (!/^[1-9]{1}[0-9]{9}$/i.test(values.phone)) {
            errors.phone = 'Invalid phone number';
        }else if (values.phone.length<=9) {
            errors.phone = 'Phone must be of 10 numbers';
        }
		
        if (!values.password) {
            errors.password = 'Please enter a strong password';
        } else if (!/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/i.test(values.password)) {
            errors.password = 'Password must contain atleast one uppercase letter, one lowercase letter, one digit and any of the following special characters !, @, #, $, & and atleast 8 characters long';
        }
		if (!values.terms_of_use) {
            errors.terms_of_use = 'Must agree to the terms of use and privacy policy';
        }
        return errors;
    }

  render() {
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="6" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="form-side">
              <NavLink to={`/`} className="white">
                <span className="logo-single" />
              </NavLink>
              <CardTitle className="mb-4">
                <IntlMessages id="user.register" />
              </CardTitle>
			 <Formik
				validate={this.validate}
                initialValues={{
                  first_name: "",
                  last_name: "",
                  company_name: "",
                  phone: "",
                  email: "",
                  password: "",
                  terms_of_use: false
                }}
                onSubmit={this.handleSubmit}
              >
                {({ setFieldValue,
                  setFieldTouched,
                  handleChange,
                  handleBlur,
                  values,errors, touched,isValidating }) => (
                  <Form className="av-tooltip tooltip-right-bottom" id="registration">
						
						<Label className="form-group has-float-label mb-4">
						    <Field name="first_name" autoComplete="off"  className="form-control" type="name" />
							{errors.first_name && touched.first_name && <div className="invalid-feedback d-block">{errors.first_name}</div>}
						    <IntlMessages id="user.firstname" />
						</Label>							  
												
						<Label className="form-group has-float-label mb-4">
						  <Field type="name" autoComplete="off"  name="last_name" className="form-control"  />
						  {errors.last_name && touched.last_name && <div className="invalid-feedback d-block">{errors.last_name}</div>}
						  <IntlMessages id="user.lastname" />
						</Label>
						
						<Label className="form-group has-float-label mb-4">
						  <Field type="name" autoComplete="off"  name="company_name" className="form-control" />
						  {errors.company_name && touched.company_name && <div className="invalid-feedback d-block">{errors.company_name}</div>}
						  <IntlMessages id="user.companyname" />
						</Label>
						
						<Label className="form-group has-float-label mb-4">
						  <Field type="text" name="phone" autoComplete="off" className="form-control" />
						  {errors.phone && touched.phone && <div className="invalid-feedback d-block">{errors.phone}</div>}
						  <IntlMessages id="user.phone" />
						</Label>
						
						<Label className="form-group has-float-label mb-4">
						  <Field name="email" autoComplete="off"  className="form-control" type="email" />
						  {errors.email && touched.email && <div className="invalid-feedback d-block">{errors.email}</div>}
						  <IntlMessages id="user.email" />
						</Label>
						
						<Label className="form-group has-float-label mb-4">
						  <Field type="password" name="password" autoComplete="new_password" className="form-control" />
						  {errors.password && touched.password && <div className="invalid-feedback d-block">{errors.password}</div>}
						  <IntlMessages	id="user.password" />
						</Label>
						
						<div className="register-checkbox">
						  <Label>
							<FormikCustomCheckbox
								type="checkbox"
								id="terms_of_use"
								name="terms_of_use"
								value={values.terms_of_use}                        
								onChange={setFieldValue}
								onBlur={setFieldTouched}
							  />
							  <div className="custom-checkbox-label">
							  I agree to the {" "}<NavLink to="" target="_blank" className="">Terms of Use</NavLink> and <NavLink to="" target="_blank" className="">Privacy Policy</NavLink>
							  </div>
						  </Label>
						  {errors.terms_of_use && touched.terms_of_use && <div className="invalid-feedback d-block">{errors.terms_of_use}</div>}
						</div>
						
						<div className="d-flex justify-content-center align-items-center">
						  <Button
							id="register-button"
							color="primary"
							className="btn-shadow"
							size="lg"
							type="submit"
						  >
							<IntlMessages id="user.register-button" />
						  </Button>
						</div>
					 </Form>
                )}
              </Formik>
			  
				<p className="text-center mt-sm mb-0">
					Already a Member {" "}
					<NavLink to={`/user/login`} className="">
					  Login Now!
					</NavLink>
				</p>
				<p className="text-center mt-sm mb-0">
					Forgot your password? {" "}
					<NavLink to={`/user/forgot-password`} className="">
					  Reset Password
					</NavLink>
				</p>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    registerUser
  }
)(Register);
