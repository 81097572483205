
import { projectTitle } from "../../constants/defaultValues";

const admin_email_fields = [
	{
	  placement: "bottom",
	  text: "Software Name",
	  body: "This inserts code that will be replaced by the software name. (i.e. '"+projectTitle+"')"
	},
	{
	  placement: "bottom",
	  text: "Customer Name",
	  body: "This inserts code that will be replaced by the customer name. (i.e. 'John Smith')"
	},
	{
	  placement: "bottom",
	  text: "Customer First Name",
	  body: "This inserts code that will be replaced by the customer's first name. (i.e. 'John')"
	},
	{
	  placement: "bottom",
	  text: "Customer Last Name",
	  body: "This inserts code that will be replaced by the customer's last name. (i.e. 'Smith')"
	},
	{
	  placement: "bottom",
	  text: "Customer Email",
	  body: "This inserts code that will be replaced by the customer's email. (i.e. 'johnsmith@gmail.com')"
	},
	{
	  placement: "bottom",
	  text: "Customer Phone",
	  body: "This inserts code that will be replaced by the customer's phone. (i.e. '0123456789')"
	},
	{
	  placement: "bottom",
	  text: "Company Name",
	  body: "This inserts code that will be replaced by the customer's company name. (i.e. 'ABC Inc.')"
	},
	{
	  placement: "bottom",
	  text: "Membership Details",
	  body: "This inserts code that will be replaced by the customer's membership detail."
	},
	{
	  placement: "bottom",
	  text: "Subscription Expire Date",
	  body: "This inserts code that will be replaced by the customer's subscription expire date."
	},
	{
	  placement: "bottom",
	  text: "Login URL",
	  body: "This inserts code that will be replaced by the login url. (i.e. 'https://tridant.com/user/login')"
	},
];

export default admin_email_fields;
