import React, { Component } from "react";
import { AvForm, AvField, AvGroup,AvRadioGroup,AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import DatePicker from "../../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import moment from 'moment';
import swal from 'sweetalert';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectType = [
	{ label: "--Select Limit--", value: "0", key: -1 },
    { label: "Normal", value: "1", key: 0 },
    { label: "Veterans", value: "2", key: 1 },
    { label: "Senior Citizens", value: "3", key: 2 },
    { label: "Student", value: "4", key: 3 },
    { label: "Inbound Customer", value: "5", key: 4 }
];

class EditNewBuyOneModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			start_date: null,
			end_date: null,
			auto_apply_yes:false,
			auto_apply_yes_value:1,
			auto_apply_no_value:0,
			auto_apply_no:true,
			selectProducts:[],
			selectCategories:[],
			selectStrains:[],
			product_val:[],
			category_val:[],
			strain_val:[],
			customer_type: { label: "--Select Customer Type--", value: "0", key: -1 },
			discountCode: null,
			offer:null,
		};

	}
	
	componentDidMount() {		
		var products=[];
		var categories=[];
		var strains=[];
		var discountCode=null;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'point-of-sale/get-strains-categories-and-products',formData).then((res)=>{
			var data = res.data;
		
			products=data.products;
			categories=data.categories;
			strains=data.strains;
			discountCode=data.discount_code;
			
			products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			categories.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectProducts:products,
				selectCategories:categories,
				selectStrains:strains,
				discountCode:discountCode
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		
        if(prevProps.modalOpen==false){
			var offer;
			var that=this;
            if (prevProps.bogo_id !== this.props.bogo_id) {
                var formData = new FormData();
				formData.append('bogo_id',this.props.bogo_id);
	
				axios.post(REST_API_END_POINT+'point-of-sale/bogo-by-id',formData).then((res)=>{
					var data = res.data;
					var customer_type = null;
					var auto_apply_yes = true;
					var auto_apply_no = false;
					
			        offer=data.bogo;
					
					selectType.forEach((element,index) => {
						if(element.value==offer.customer_type){
							customer_type = element;
						}
					});						
					
					if(offer.auto_apply==1){
						auto_apply_yes = true;
						auto_apply_no = false;
					}else{
						auto_apply_yes = false;
						auto_apply_no = true;
					}
					
					let Ids = offer.product_id.split(',');
					var product_val = this.state.selectProducts.filter(row=>{ return Ids.indexOf(row.value)!==-1 });
					
					Ids = offer.category_id.split(',');
					var category_val = this.state.selectCategories.filter(row=>{ return Ids.indexOf(row.value)!==-1 })
					
					Ids = offer.strain_id.split(',');
					var strain_val = this.state.selectStrains.filter(row=>{ return Ids.indexOf(row.value)!==-1 })
					
					this.setState({ 
						offer,
						customer_type,
						auto_apply_yes,
						auto_apply_no,
						product_val,
						category_val,
						strain_val,
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		}
	}
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			let vStartDate = document.getElementById("start_date").value.trim();
			let vEndDate = document.getElementById("end_date").value.trim();
			
			let errorMessage='';
			if(vStartDate.length==0){
				errorMessage+="Start Date is empty\n";
			}
			if(vEndDate.length==0){
				errorMessage+="End Date is empty\n";
			}

			if(errorMessage.length!=0){
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			var productVal= this.state.product_val.map(row=>row.value);
			var categoryVal= this.state.category_val.map(row=>row.value);
			var strainVal= this.state.strain_val.map(row=>row.value);
			
			document.getElementById("update-bogo").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("buyoneofferForm"));
			formData.append('bogo_id',this.props.bogo_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);		
			formData.append('product_id',productVal);
			formData.append('category_id',categoryVal);
			formData.append('strain_id',strainVal);

			axios.post(REST_API_END_POINT+'point-of-sale/edit-bogo-offer',formData).then((res)=>{
				var data = res.data;
				
				var offer=this.state.offer;
				offer.name=values.name;
				offer.code=values.code;
				offer.start_date=formData.get('start_date');
				offer.end_date=formData.get('end_date');
				offer.buy_type=values.buy_type;
				offer.strain_value=values.strain_value;
				offer.category_value=values.category_value;
				offer.product_value=values.product_value;
				offer.auto_apply=values.auto_apply;
				offer.customer_type=values.customer_type;
				this.setState({ offer:offer });
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-bogo").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-bogo").disabled=false;
			});

		}
	}
  
	showRadio = e =>{
		var elements = document.querySelectorAll('input[name="buy_type"]');
		var value='0';
		for (var element of elements) {
			if(element.checked==true){
				value=element.value;
			}
		}
		if(value==='2'){
			document.getElementById('show_strain').style.display='block';
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='4'){
			document.getElementById('show_strain').style.display='none';
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='block';
		}else if(value==='6'){
			document.getElementById('show_strain').style.display='none';
			document.getElementById('show_products').style.display='block';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='1'){
			document.getElementById('show_strain').style.display='none';
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='none';
		}else if(value==='5'){
			document.getElementById('show_strain').style.display='none';
			document.getElementById('show_products').style.display='none';
			document.getElementById('show_categories').style.display='none';
		}
	}


	render() {

		const {selectProducts, selectCategories, selectStrains, start_date, end_date } = this.state;

		if(this.state.offer!=null){
			var offer=this.state.offer;
		}else{
           var offer;
		}

		return (

			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
			  <ModalHeader toggle={this.props.toggleModal}>
				Edit Buy One Get One Offer
			  </ModalHeader>
			  <AvForm
					id="buyoneofferForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
			  <ModalBody>
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								value={ offer?((offer.name!=null)?offer.name:''):'' }
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Code<span className="text-danger">*</span></Label>
							  <AvField
								id="code"
								name="code"
								type="text"
								autoComplete="off"
								readOnly={true}
								value={ offer?((offer.code!=null)?offer.code:''):'' }
								validate={{
										required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row >
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							  <Label>Start Date<span className="text-danger">*</span></Label>
								 <DatePicker
									id="start_date"
									name="start_date"
									minDate={new Date()}
									onChange={e => {
										let tempEndDate=null;
										if(this.state.end_date){
											tempEndDate=this.state.end_date.unix();
										}else{
											if(offer){
												tempEndDate=(offer.end_date)?moment(offer.end_date,"YYYY-MM-DD").unix():0;
											}
										}

										if(tempEndDate<e.unix()){
											swal("Warning", "End date should be greater than start date!", "warning");
										}else{
											this.setState({start_date: e })
										}
									}}
									selected={ (this.state.start_date)?this.state.start_date:(offer?((offer.start_date)?moment(offer.start_date,'YYYY-MM-DD'):null):null) }
									placeholderText=""
									dateFormat="YYYY-MM-DD"
								  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>End Date<span className="text-danger">*</span></Label>
								 <DatePicker
									id="end_date"
									name="end_date"
									minDate={(start_date)?start_date:(offer?((offer.start_date)?moment(offer.start_date,'YYYY-MM-DD'):(new Date())):(new Date()))}
									selected={ (this.state.end_date)?this.state.end_date:(offer?((offer.end_date)?moment(offer.end_date,'YYYY-MM-DD'):null):null) }
									onChange={e =>{
											let tempStartDate=((start_date)?start_date:offer.start_date);
											if(tempStartDate){
												this.setState({end_date: e })
											}else{
												swal("Warning", "End Date should be greater than!", "warning").then(()=>{
													this.setState({end_date:null});
												});
											}
									}}
									placeholderText=""
									dateFormat="YYYY-MM-DD"
								  />
							</AvGroup>
						</Colxx>
					</Row>

					<Row>
						<Colxx lg="12" className="mt-2 mb-2">
							<Label className="font-weight-bold">Buy Product In </Label>	&nbsp;&nbsp;
							<AvRadioGroup
								inline
								name="buy_type"
								onChange={(e) => this.showRadio(e)}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select any of the options"
								  }
								}}
								value={ offer?((offer.buy_type!=null)?offer.buy_type:'5'):5}
								>
								{/*<AvRadio customInput label="All Strains" value="1" />
								<AvRadio customInput label="Specific Strains" value="2" />*/}
								<AvRadio customInput label="Specific Categories" value="4" />
								<AvRadio customInput label="All Products" value="5" />
								<AvRadio customInput label="Specific Products" value="6" />
							</AvRadioGroup >

						</Colxx>

					</Row>
					<div id="show_strain" style={{display:offer?((offer.buy_type!=null&& offer.buy_type==2)?'block':'none'):'none'}}>
						<Label >Choose Strains</Label>
						 <Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							isMulti
							name="strain_val[]"
							value={this.state.strain_val}
							onChange={strain_val => { this.setState({ strain_val:strain_val }) }}
							options={this.state.selectStrains}
						/>
					</div>
					<div id="show_categories" style={{display:offer?((offer.buy_type!=null&& offer.buy_type==4)?'block':'none'):'none'}}>
						<Label >Choose Categories</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							isMulti						
							name="category_val[]"
							value={this.state.category_val}						
							onChange={category_val => { this.setState({ category_val:category_val }) }}
							options={this.state.selectCategories}
						/>
					</div>
					<div id="show_products" style={{display:offer?((offer.buy_type!=null&& offer.buy_type==6)?'block':'none'):'none'}}>
						<Label >Choose Products</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							isMulti						
							name="product_val[]"						
							value={this.state.product_val}						
							onChange={product_val => { this.setState({ product_val:product_val}) }}
							options={this.state.selectProducts}
						/>
					</div>
					<Row className="mt-2">
						<Colxx lg="12" className="mt-2 mb-2">
							<Label>Auto Apply</Label>	&nbsp;&nbsp;
							<Label>
							  <CustomInput
								id="auto_apply_yes"
								type="radio"
								name="auto_apply"
								value={this.state.auto_apply_yes_value}
								checked={this.state.auto_apply_yes}
								onChange={() => { this.setState({ auto_apply_yes:true,auto_apply_no:false }) }}
								className="d-inline-block"
							  />
							  {" "}Yes
							</Label>&nbsp;&nbsp;
							<Label>
							  <CustomInput
								id="auto_apply_no"
								type="radio"
								name="auto_apply"
								value={this.state.auto_apply_no_value}
								checked={this.state.auto_apply_no}
								onChange={() => { this.setState({ auto_apply_yes:false,auto_apply_no:true }) }}
								className="d-inline-block"
							  />
								{" "}No
							</Label>&nbsp;&nbsp;
						</Colxx>
					</Row>
					<Row className="d-none">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Type of Customer</Label>
							  <Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"
									name="customer_type"
									id="customer_type"
									value={this.state.customer_type}
									onChange={customer_type => { this.setState({ customer_type:customer_type}) }}
									options={selectType}
								/>
							</AvGroup>
						</Colxx>
					</Row>

			  </ModalBody>
			  <ModalFooter>
				<Button color="primary" id="update-bogo" >Update</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
				  <IntlMessages id="customer.cancel" />
				</Button>{" "}
			  </ModalFooter>
			   </AvForm>
		  </Modal>
		);
	}
};

export default EditNewBuyOneModal;
