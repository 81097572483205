import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, CustomInput, Badge } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Stafftimezone extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			search: '',
			staffs:[],
		};
	}
	
    dataListRender() {
		var user_id = localStorage.getItem('user_id');	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		
		
		axios.post(REST_API_END_POINT+'staff/get-all-staff-timezones',formData).then((res)=>{
			var data = res.data;
		
			if(data.staff_timezones){
				this.setState({
					staffs: data.staff_timezones,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					staffs: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  		componentDidMount(){
	   this.dataListRender();
	}
	
  render() {
	  let data = this.state.staffs
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search) || row.designation.toLowerCase().includes(this.state.search) || row.total_time.toLowerCase().includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
	  {
		Header: "Staff Name",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Designation",
		accessor: "designation",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Total Time",
		accessor: "total_time",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "",
		accessor: "userid",
		sortable: false,
		Cell: props => <Fragment> <NavLink to={"/app/staffmanagement/stafftime/"+props.value} className="mb-2 btn btn-xs btn-secondary ">View Details</NavLink></Fragment>
	  }
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.staff-time-zone" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
				<Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>  
				<Colxx xxs="12">					
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
									type="text"
									name="table_search"
									id="table_search"
									placeholder="Search"
									value={this.state.search}
									onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							responsive
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>					
				</Colxx>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
