import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey)


class AssigntoDriverModel extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {	
		startTime: null,
		startDate: null,
	    driver_val:null
		};
	
   }
 
handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			let drivers = this.state.driver_val;
			
			document.getElementById("assign-driver").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("driverForm"));
			
			formData.append('vehicle_id',this.props.vehicle_id);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'transportation/assign-vehicle',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("assign-driver").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();
				
				this.setState({
					selectDriverType: null,							
				});
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("assign-driver").disabled=false;
			});

		}
	}
  


  render() {	
  
  const {  selectDrivers } = this.state;
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Assign to Driver
		  </ModalHeader>
		  <AvForm
				id="driverForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
		  <Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Driver<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="driver_id"
								id="driver_id"
								value={this.state.selectDriverType}						
								options={this.props.drivers}
								onChange={selectDriverType => { this.setState({ selectDriverType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>		
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="assign-driver">
			  Submit
			</Button>
			 </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AssigntoDriverModel;
