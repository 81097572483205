import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import moment from 'moment';

import { zeroPad } from "../../../helpers/Utils";
import number_format from "number_format-php";

export default class InvoiceModalContent extends Component{
		
    render() {
		const { order_data, facility_data } = this.props;
		var current_currency_symbol = '$';
		var total=0;
		var order_products=[];
		if(order_data!=null){
			order_products=order_data.order_items;
		}
		return (			
			<Row className="invoice-react">
				<Colxx xxs="12" className="mb-4">
					<div className="mb-5 invoice-contents">
						<CardBody className="d-flex flex-column justify-content-between">
							<div className="d-flex flex-column">
								<div className="d-flex flex-row justify-content-between pt-2 pb-2">
									<div className="">
										<div className="font-weight-bold h5">{facility_data!=null ? facility_data.facility_name:""}</div>
										{facility_data!=null ? (facility_data.facility_address!=null ? <p className="mb-0">{facility_data.facility_address}</p>:""):""}
										{facility_data!=null ? (facility_data.facility_phone!=null ? <p className="mb-0">{facility_data.facility_phone}</p>:""):""}
										{facility_data!=null ? (facility_data.facility_email!=null ? <p className="mb-0">{facility_data.facility_email}</p>:""):""}
										{facility_data!=null ? (facility_data.license_number!=null ? <p className="mb-0">Lic No: {facility_data.license_number}</p>:""):""}
									</div>
									{order_data!=null ? <div className="w-30 justify-content-end text-right ">
										<div className="font-weight-bold text-uppercase h4">Invoice</div>
										<p className="mb-0">
											INVOICE # {zeroPad(order_data.order_number,6)}<br/>
											DATE: {moment(order_data.timestamp*1000).format("MMMM DD, YYYY")}
										</p>
									</div>:""}
								</div>
								<div className="border-bottom pt-4 mb-5" />

								{order_data!=null ? <div className="d-flex flex-row justify-content-between mb-5">
									<div className="d-flex flex-column w-50 mr-2 p-4 bg-semi-muted">
										<div className="font-weight-bold h5">TO:</div>
										<p className="mb-0">
											{order_data.vendor_name}<br/>
											{order_data.vendor_address!=null ? <Fragment>{order_data.vendor_address}<br/></Fragment>:""}
											{order_data.vendor_phone!=null ? <Fragment>Tel: {order_data.vendor_phone}</Fragment>:""}
										</p>
									</div>
									<div className="d-flex w-50 flex-column p-4 bg-semi-muted">
										<div className="font-weight-bold h5">SHIPPED TO:</div>
										<p className="mb-0">
											{order_data.vendor_name}<br/>
											{order_data.vendor_address!=null ? <Fragment>{order_data.vendor_address}<br/></Fragment>:""}
											{order_data.vendor_phone!=null ? <Fragment>Tel: {order_data.vendor_phone}</Fragment>:""}
										</p>
									</div>
								</div>:""}

								{order_data!=null ?<Table borderless>
									<thead>
										<tr>
											<th className="mb-2">#</th>						
											<th className="mb-2">Strain</th>						
											<th className="mb-2">Birth Date</th>						
											<th className="mb-2">Phase</th>						
											<th className="mb-2 text-right">Price</th>						
											<th className="mb-2 text-right">Quantity</th>					
											<th className="mb-2 text-right">Total</th>						
										</tr>
									</thead>
									<tbody>
										{order_products.map((row,index)=>{
											total+=parseFloat(row.sub_total);
											return(<tr key={index}>
											<td>{index+1}</td>
											<td>{row.strain_name}</td>
											<td>{moment(row.birth_date*1000).format("MM/DD/YYYY")}</td>
											<td>{row.phase_name}</td>
											<td className="text-right">{current_currency_symbol+" "+number_format(parseFloat(row.product_price),2,'.','')}</td>
											<td className="text-right">{number_format(parseFloat(row.product_qty),2,'.','')}</td>
											<td className="text-right">{current_currency_symbol+" "+number_format(parseFloat(row.sub_total),2,'.','')}</td>
										</tr>)
										})}                                                   
									</tbody>
								</Table>:""}							  
							</div>
							{order_data!=null ?<div className="d-flex flex-column">
								<div className="border-bottom pt-3 mb-2" />
								<Table borderless className="d-flex justify-content-end">
									<tbody>
										<tr className="font-weight-bold">
											<td className="pb-0">Grand Total :</td>
											<td className="text-right pb-0">{current_currency_symbol+" "+number_format(parseFloat(total),2,'.','')}</td>
										</tr>
									</tbody>
								</Table>
								<div className="border-bottom pt-2 mb-3" />
								<p className="text-muted text-center mb-0">
									Payment in full due in 14 days<br/>
									If you have any questions concerning this invoice, contact {facility_data!=null ? (facility_data.facility_email!=null ? "<"+facility_data.facility_email+">":""):""}
								</p>
							</div>:""}
						</CardBody>
					</div>
				</Colxx>
			</Row>
		);
  }
};
