import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import complaintview from "./complaintview";
import closecomplaint from "./closecomplaint";

const Complaints = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			<Route path={`${match.url}/complaint-view/:complaint_id`} component={complaintview} />
			<Route path={`${match.url}/close-complaint/:complaint_id`} component={closecomplaint} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Complaints;