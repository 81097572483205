import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class YieldsStatistics extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			strains:[],
			daterange:[],
			search: '',
			strain_id: null,
			year: {label:parseInt(moment().format("YYYY")),value:parseInt(moment().format("YYYY")),key:parseInt(moment().format("YYYY"))},
			blocking: true,
			dataTableRefresh: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
		this.makeCols = this.makeCols.bind(this);
	}

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		if(this.state.strain_id!=null){
			formData.append('strain_id',this.state.strain_id.value);
		}else{
			formData.append('strain_id',0);
		}
		
		if(this.state.year!=null){
			formData.append('year',this.state.year.value);
		}else{
			formData.append('year',0);
		}
				
		axios.post(REST_API_END_POINT+'report/yields-statistics',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				
				var strains = data.strains;
				strains = strains.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				strains.unshift({ label:"All", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					strains,
					daterange: data.daterange,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					strains:[],
					daterange:[],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		var that = this;
		this.setState({ dataTableRefresh:false  });
		setTimeout(function(){
			that.setState({ dataTableRefresh:true },()=>{
				that.$el = $(that.el);	
				that.$el.DataTable({
					dom: 'Bfrtip',
					bPaginate: false,
					bSort: false,
					columns: that.makeCols(),
					data: that.makeArray(),
					aaSorting:[],
					buttons: [
						'csv', 'excel', {
							extend: 'pdfHtml5',
							orientation: 'landscape',
							pageSize: 'LEGAL'
						}, 'print'
					]
				});
			});
		}, 300);
	}
	
	makeArray(){
		const reports = this.state.reports;
		const daterange = this.state.daterange;
		var formated_data=[];
		var last_row={index:'',barcode:'Total',total:0};
		var i=1;
		for(let key in reports){
			let row = reports[key]
			let final_row_data={
				index:i++,
				barcode:row.barcode,
				total:row.total
			};
			last_row.total+=parseFloat(row.total);
			let row_data=daterange.map(r=>{
				let e={};
				e[r] = row[r];
				if(typeof last_row[r]!='undefined'){
					last_row[r]+=parseFloat(row[r]);
				}else{
					last_row[r]=0;
				}
				final_row_data = Object.assign(final_row_data,e);
				return e;
			});
			
			formated_data.push(final_row_data);
		}
		
		if(formated_data.length){
			formated_data.push(last_row);			
		}
		
		return formated_data;
	}
	
	makeCols(){
		const daterange = this.state.daterange;
		let row_data=daterange.map(r=>{
			return {title: r, data:r };
		});
		
		var formated_data=[
			{ title: '&nbsp;', data:'index' },
			{ title: 'Plant', data:'barcode' },
			...row_data,
			{ title: 'Total', data:'total' }
		];
		return formated_data;
	}
	
  
	render() {	
		const reports = this.state.reports;
		const { strains,daterange } = this.state;
		var years = [];
		var year;
		for(year=(parseInt(moment().format("YYYY"))-10);year<=parseInt(moment().format("YYYY"));year++){
			years.push({label:year,value:year,key:year});
		}

		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.yields-statistics" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="4">
								<Label>Year</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="year"
									id="year"
									placeholder="All"
									value={this.state.year}						
									options={years}
									onChange={year => { this.setState({ year }) }}
								/>
							</Colxx>
							<Colxx lg="6">
								<Label>Strain</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="strain_id"
									id="strain_id"
									placeholder="All"
									value={this.state.strain_id}						
									options={strains}
									onChange={strain_id => { this.setState({ strain_id }) }}
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm>
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									<table className="display table table-bordered table-striped" ref={el=>this.el=el} >
										<thead>
											<tr>				
												<th>&nbsp;</th>
												<th className="w-33">Plant</th>
												{daterange.map((r,i)=>{
													return <th className="w-33" key={i}>{r}</th>
												})}
												<th className="w-33">Total</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
