import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Card, CardHeader, Table
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';

import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import { REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey, JQUERY_MODULE } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class CompleteOrderModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order_data: null,
			facility_data: null,
			additionalRequirementsData: [],
		};

		this.handleSubmit = this.handleSubmit.bind(this);

	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.modalOpen) {
			if (prevProps.order_id !== this.props.order_id && this.props.order_id != 0) {
				var facility_id = localStorage.getItem('facility_id');
				var user_id = localStorage.getItem('user_id');
				facility_id = dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('order_id', this.props.order_id);
				formData.append('facility_id', facility_id);
				formData.append('user_id', user_id);
				axios.post(REST_API_END_POINT + 'estimate/get-order-detail', formData).then((res) => {

					var data = res.data;
					if (data.status == 1) {
						this.setState({
							order_data: data.order_data,
							facility_data: data.facility_data,
							additionalRequirementsData: data.additional_requirements,
						});
					} else {
						this.props.toggleModal();
						swal("Warning!", "There is no such estimate", "warning");
					}
				}).catch(e => {
					console.log("Addition failed , Error ", e);
				});
			}
		}
	}

	handleSubmit(event, errors, values) {

		if (errors.length === 0) {

			document.getElementById("complete-order").disabled = true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("completeForm"));
			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);
			formData.append('order_id', this.props.order_id);

			axios.post(REST_API_END_POINT + 'estimate/convert-to-invoice', formData).then((res) => {
				var data = res.data;

				document.getElementById("complete-order").disabled = false;
				this.props.dataListRender();
				this.props.toggleModal();

				if (data.status == 1) {
					swal("Success", data.msg, "success");
				} else {
					swal("Failed", data.msg, "warning");
				}

			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("complete-order").disabled = false;
			});

		}
	}

	render() {
		const { order_data, facility_data, additionalRequirementsData } = this.state;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax = 0;
		var sgst_tax = 0;
		var cgst_tax = 0;
		var sub_total = 0;
		var p_discount = 0;
		let is_utgst = false;
		let is_igst = true;

		/*if(order_data!=null){
			is_igst = order_data.customer_state_id !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}
		}*/
		if (order_data != null) {
			order_data.final.map((item, index) => {
				if (item.filter) {
					item.filter.map(row => {
						sales_tax += parseFloat(row.product_sales_tax);
						sgst_tax += parseFloat(row.product_sgst_tax);
						cgst_tax += parseFloat(row.product_cgst_tax);
						p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

						let line_total = parseFloat(row.unitPrice) * Math.ceil(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

						if (row.variation_id == order_data.discount_variation_id) {
							line_total -= parseFloat(order_data.manual_discount);
						}
						sub_total += parseFloat(row.unitPrice) * Math.ceil(row.product_qty);
					});
				}
			});

		}
		let additionalTotal = 0;
		return (
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right modal-right-lg"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Convert to Invoice
				</ModalHeader>

				<AvForm
					id="completeForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody >
						{order_data != null ? <Fragment>
							<Row>
								<Colxx md="12">
									<div className="view-order-table">
										<div className="font-weight-bold text-uppercase h4">#{moment(order_data.timestamp * 1000).format('YYYY')}/{zeroPad(order_data.order_number, 6)}</div>
										<div className="border-bottom pt-2 mb-3" />
										<Table responsive bordered>
											<thead>
												<tr>
													<th className="mb-2">#</th>
													<th className="mb-2">Item</th>
													<th className="mb-2 ">Size</th>
													<th className="mb-2 text-right">Unit Price</th>
													<th className="mb-2 text-right">SQFT/Quantity</th>
													{/* <th className="mb-2 text-right">Price</th>
												
												{is_igst ? <th className="mb-2 text-right">TAX</th>:<Fragment>
													<th className="mb-2 text-right">{is_utgst ? "UTGST":"SGST"}</th>
													<th className="mb-2 text-right">CGST</th>
												</Fragment>} */}
													{/*<th className="mb-2 text-right">Discount</th>*/}
													<th className="mb-2 text-right">Total</th>
												</tr>
											</thead>
											<tbody>
												{order_data.final.map((row, index) => {
													// sales_tax += parseFloat(row.product_sales_tax);
													// sgst_tax += parseFloat(row.product_sgst_tax);
													// cgst_tax += parseFloat(row.product_cgst_tax);
													// p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

													// let line_total = parseFloat(row.unitPrice) * Math.ceil(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

													// if (row.variation_id == order_data.discount_variation_id) {
													// 	line_total -= parseFloat(order_data.manual_discount);
													// }
													// sub_total += parseFloat(row.unitPrice) * Math.ceil(row.product_qty);
													return (<>
														<tr>
															<td>{index + 1}</td>
															<td>{row.category_name} </td>
														</tr>
														{row.filter.map((raw) => (
															<tr>
																<td style={{ border: 'none' }}></td>
																<td>{raw.product_name} {raw.variation_name != null ? "(" + raw.variation_name + ")" : ""}</td>
																<td>{raw.sqft == 1 ? <div>{parseInt(raw.length)}*{parseInt(raw.breadth)}*{parseInt(raw.height)}</div> : ""}</td>
																<td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(raw.unitPrice), 2, '.', '')}</td>
																<td className="text-right">{Math.ceil(raw.product_qty)}</td>
																{/* <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat((parseFloat(row.unitPrice)*Math.ceil(row.product_qty)).toFixed(2)),2,'.','')}</td> 
												 {is_igst ? <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sales_tax),2,'.','')}</td>:<Fragment>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sgst_tax),2,'.','')}</td>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_cgst_tax),2,'.','')}</td>
												</Fragment>}  */}
																{/*<td className="text-right">{current_currency_symbol}{""}{row.variation_id!=order_data.discount_variation_id ? number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount),2,'.',''):number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount)+parseFloat(order_data.manual_discount),2,'.','')}</td>*/}
																<td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(raw.unitPrice) * Math.ceil(raw.product_qty) + (parseFloat(raw.product_excise_tax) + parseFloat(raw.product_sales_tax) - parseFloat(raw.product_discount) - parseFloat(raw.coupon_discount)), 2, '.', '')}</td>
															</tr>
														))}
													</>)
												})}
											</tbody>
										</Table>
										<div className="d-flex flex-column">
											<div className="border-bottom pt-3 mb-2" />
											<Table borderless className="d-flex justify-content-end">
												<tbody>
													{/* <tr>
														<td className="pb-0">Subtotal :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sub_total, 2, '.', '')}</td>
													</tr> */}
													{/* {is_igst ? <tr>
														
														<td className="pb-0">TAX ({order_data.tax_percentage
														}%) :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sub_total * order_data.tax_percentage/ 100, 2, '.', '')}</td>
													</tr> : <Fragment>
														<tr>
															<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
															<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
														</tr>
														<tr>
															<td className="pb-0">CGST :</td>
															<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
														</tr>
													</Fragment>} */}
													{/*<tr>
														<td className="pb-0">Discount :</td>
														<td className="text-right pb-0">{current_currency_symbol}{""+number_format(p_discount+parseFloat(order_data.auto_apply_discount)+parseFloat(order_data.coupon_discount)+parseFloat(order_data.manual_discount_value)+parseFloat(order_data.point_discount),2,'.','')}</td>
													</tr>*/}
													{parseFloat(order_data.return_amount) != 0 ? <tr className="font-weight-bold">
														<td className="pb-0">Refund :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.return_amount), 2, '.', '')}</td>
													</tr> : ""}
													<tr className="font-weight-bold">
														<td className="pb-0">Total :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(sub_total + sub_total * order_data.tax_percentage / 100) - parseFloat(p_discount), 2, '.', '')}</td>
													</tr>
												</tbody>
											</Table>
											{additionalRequirementsData.length > 0 ?
												<>
													<h4>Additional Requirements</h4>
													<Table borderless>
														<thead>
															<tr>
																<th className="mb-2">#</th>
																<th className="mb-2 text-left">Item</th>
																<th className="mb-2 text-left">Quantity</th>
																<th className="mb-2 text-left">Price</th>
																<th className="mb-2 text-left">Amount</th>

															</tr>
														</thead>
														<tbody>
															{additionalRequirementsData.map((row, index) => {
																additionalTotal += parseFloat(row.amount);
																return (<>
																	<tr >
																		<td>{index + 1}</td>
																		<td>{row.name} </td>
																		<td>{row.qty}</td>
																		<td>{row.price}</td>
																		<td>{row.amount}</td>

																	</tr>


																</>)
															})}
														</tbody>
													</Table>
													<div className="d-flex flex-column">
														<div className="border-bottom pt-3 mb-2" />
														<Table borderless className="d-flex justify-content-end">
															<tbody>
																{/* <tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(additionalTotal, 2, '.', '')}</td>
										</tr> */}

																{/* {is_igst ? <tr>
											<td className="pb-0">Tax ({order_data.tax_percentage}%) :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
										</tr> : <Fragment>
											<tr>
												<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
											</tr>
											<tr>
												<td className="pb-0">CGST :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
											</tr>
										</Fragment>} */}

																<tr className="font-weight-bold">
																	<td className="pb-0">Total :</td>
																	<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
																</tr>
																<tr className="font-weight-bold">
																	<td className="pb-0">Grand Total :</td>
																	<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) + parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
																</tr>
															</tbody>
														</Table>
													</div>
												</> : ""}
											<div className="border-bottom pt-2 mb-3" />
										</div>
										{order_data.delivery_date != 0 ? <h4>Delivery Date  : {moment(order_data.delivery_date * 1000).format('MM/DD/YYYY')}</h4> : ""}
									</div>
								</Colxx>
							</Row>
							<Row>
								<Colxx>
									<div>
										<hr />
										<Row>
											<Colxx md="12">
												<AvGroup>
													<Label>New Order Status<span className="text-danger">*</span></Label>
													<AvField
														id="order_status"
														name="order_status"
														type="select"
														autoComplete="off"
														defaultValue="1"
														validate={{
															required: {
																value: true,
																errorMessage: "This field is required."
															}
														}}
													>
														<option value="1">Approved</option>
														<option value="3">Completed</option>
													</AvField>
												</AvGroup>
											</Colxx>
										</Row>
									</div>
								</Colxx>
							</Row>
						</Fragment> : ""}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="complete-order">
							Convert
						</Button>{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};
