import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditLocationModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {	
			selectlocationType:null,
			location_data:null
		};	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let location_data; 					
            if (prevProps.location_id!== this.props.location_id && this.props.location_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('location_id',this.props.location_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'metrc-location/get-location-by-id',formData).then((res)=>{
					
					var data = res.data;
					if(data.location){
						location_data=data.location;
						var selectlocationType = this.props.location_types.find(row=>{ return row.value==location_data.location_type_name });
						this.setState({ 
							location_data,
							selectlocationType,
						});
					}					
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    } 

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			if(this.state.selectlocationType==null){
				swal("Warning!","Please select the location type","warning")
				return;
			}
			document.getElementById("edit-locations").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("locationForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('location_id',this.props.location_id);
			
			axios.post(REST_API_END_POINT+'metrc-location/edit-location',formData).then((res)=>{
				var data = res.data;
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("edit-locations").disabled=false;
					this.props.toggleModal();
					this.setState({ 
						selectlocationType:null
					});
					this.props.dataListRender();
				}else{
					document.getElementById("edit-locations").disabled=false;
					this.props.toggleModal();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("edit-locations").disabled=false;
			});
		}
	}
  
	render() {	
	
		if(this.state.location_data!=null){
			var location_data=this.state.location_data;
		}else{
			var location_data;
		}
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
			  <ModalHeader toggle={this.props.toggleModal}>
				Edit Location
			  </ModalHeader>
			  <AvForm
					id="locationForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Name<span className="text-danger">*</span></Label>
								<AvField
									id="name"
									name="name"
									type="text"
									autoComplete="off"
									value={ location_data?((location_data.name!=null)?location_data.name:''):'' }
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
									}}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Location Type</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="location_type_name"
									id="location_type_name"
									value={this.state.selectlocationType}						
									options={this.props.location_types}
									onChange={selectlocationType => { this.setState({ selectlocationType }) }}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="edit-locations" >
						<IntlMessages id="customer.update" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
		  </Modal>
		);
  }
};

export default EditLocationModal;
