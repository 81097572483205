import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import {
	Row,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const quillModules = {
	toolbar: [
		["bold", "italic", "underline", "strike", "blockquote"],
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" }
		],
		["link", "image"],
		["clean"]
	]
};

const quillFormats = [
	"header",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image"
];

class SignagreementModal extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
			modalOpenAdd: false,
			textQuillStandart: "",
		};

	}

	handleSubmit(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
			this.props.toggleModal();
		}
	}
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd,

		});
	};
	onChange = e => {
		switch (e.target.name) {
			case 'profile_pic':
				if (e.target.files[0]) {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = e.target.files[0].name;
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
				break;
			default:
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
		}
	};


	handleChangeQuillStandart = (textQuillStandart) => {
		this.setState({ textQuillStandart });
	}

	render() {
		return (

			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Create Sign Agreement
				</ModalHeader>
				<AvForm
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<ModalBody>

						<Row className="mt-2">
							<Colxx lg="12" className="mb-2">
								<AvGroup>
									<Label>Agreement Name</Label>
									<AvField
										name="suggestion"
										type="text"
										rows="2"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter agreement"
											},
											minLength: {
												value: 2,
												errorMessage:
													"The message must have atleast 2 characters"
											}
										}}
									/>
								</AvGroup>
							</Colxx>
						</Row>




						<Row className="mt-4">
							<Colxx xxs="12">

								<Label>Agreement</Label>
								<ReactQuill
									theme="snow"
									value={this.state.textQuillStandart}
									onChange={this.handleChangeQuillStandart}
									modules={quillModules}
									formats={quillFormats}
								/>

							</Colxx>
						</Row>







					</ModalBody>
					<ModalFooter>
						<Button color="primary" >
							<IntlMessages id="customer.save" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default SignagreementModal;
