import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader as h1,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
// import DatePicker from "../../components/common/CustomDateTimePicker";

import IntlMessages from "../../../helpers/IntlMessages";
// import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Select from "react-select";
// import CustomSelectInput from "../../components/common/CustomSelectInput";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { uuid } from 'uuidv4';

// import ProductRow from "./ProductRow";
import ProductRow from "../../../containers/enquiries/ProductRow";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
// import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
 

class accountManagement extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			enquiry_data:null,
			facility_data:null,

		};
	
	}
	
	componentDidMount() {				
		let facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        let formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'point-of-sale/edit-account-management',formData).then((res)=>{
			var data = res.data['account_data'];
			this.setState({enquiry_data:data})
		
		
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.enquiry_id!== this.props.enquiry_id && this.props.enquiry_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('enquiry_id',this.props.enquiry_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'point-of-sale/edit-account-management',formData).then((res)=>{
					var data = res.data;
					
					if(data.status==1){

					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such enquiry","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
	
	handleSubmit(event, errors, values) {		


		if (errors.length === 0) {	

			var bankAccountData = JSON.stringify(values);
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();			
			formData.append('facility_id',facility_id);
			formData.append('bank_account_data',bankAccountData);

			axios.post(REST_API_END_POINT+'point-of-sale/update-account-management',formData).then((res)=>{
				var data = res.data;
				document.getElementById("create-order").disabled=false;
				if(data.status==1){
					swal("Success!",data.msg,'success');
				/*	this.setState({
						product_rows:[{
							id:uuid(),
							product_id:this.state.products.length ? this.state.products[0]:null,
							product_quantity:"",
						}]
					});	*/				
				}else{
					swal("Warning!",data.msg,'warning');
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("create-order").disabled=false;
			});

		}
	}
	
	render() {	
		const { products, product_rows, facility_data, enquiry_data } = this.state;
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  parseInt(dataCrypto.decrypt(facility_id));
		
		return (	  
	
			<div>
			<AvForm
				id="editOrderForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				
					<Row>
						<Colxx md="6">
							<AvGroup className="error-t-negative">
							  <Label>Bank Name<span className="text-danger">*</span></Label>
							  <AvField
								id="bank_name"
								name="bank_name"
								type="text"
								autoComplete="off"
								value={enquiry_data!=null ? enquiry_data.bank_name:""}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please enter the Bank Name"
								  },
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx md="6">
							<AvGroup className="error-t-negative">
							  <Label>Account Number<span className="text-danger">*</span></Label>
							  <AvField
								id="account_number"
								name="account_number"
								type="number"
								autoComplete="off"
								value={enquiry_data!=null ? enquiry_data.account_number:""}
								validate={{
								  required: {
									value: true,
									errorMessage: "It is not acceptable to have an empty account number."
								  },
								  maxLength: {value: 17, errorMessage: 'Account Number maximum is 17 digit long'}
						
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>

					</Row>
					<Row>
						<Colxx md="6">
							<AvGroup className="error-t-negative">
							  <Label>Swift Code<span className="text-danger">*</span></Label>
							  <AvField
								id="swift_code"
								name="swift_code"
								type="text"
								autoComplete="off"
								value={enquiry_data!=null ? enquiry_data.swift_code:""}
								validate={{
								  required: {
									value: true,
									errorMessage: "Swift Code should not be empty"
								  },
								  pattern: {
									value: "^[A-Za-z0-9]+$",
									errorMessage: "Invalid Swift Code"

								},
								minLength: {value: 8, errorMessage: 'Swift Code must be between 8 and 11 characters'},
								maxLength: {value: 11, errorMessage: 'Swift Code must be between 8 and 11 characters'}
								}}
							  />
							</AvGroup>
						</Colxx>

						<Colxx md="6">
							<AvGroup className="error-t-negative">
								<Label>Branch<span className="text-danger">*</span></Label>
								<AvField
									id="branch"
									name="branch"
									type="text"
									autoComplete="off"
									value={enquiry_data!=null ? enquiry_data.branch:""}
									validate={{
										required: {
											value: true,
											errorMessage: "Branch is required."
										},
										
									}}
								/>
							</AvGroup>
						</Colxx>
				
					<Colxx md="6">
							<AvGroup className="error-t-negative">
							  <Label>IBAN Number<span className="text-danger">*</span></Label>
							  <AvField
								id="iban_number"
								name="iban_number"
								type="text"
								autoComplete="off"
								value={enquiry_data!=null ? enquiry_data.iban_number:""}
								validate={{
								  required: {
									value: true,
									errorMessage: "It is not acceptable to have an empty IBAN number."
								  },
								//   maxLength: {value: 17, errorMessage: 'Account Number maximum is 17 digit long'}
						
								}}
							  />
							</AvGroup>
						</Colxx>
						</Row>
				{/* <div className="d-flex justify-content-center"> */}
				<div className="mt-4">
					<Button color="primary" type="submit" id="create-order" className="m-1">
						<IntlMessages id="customer.submit" />
					</Button>
					
					</div>
				{/* </div> */}
					
				
			</AvForm>
			{/* <Button color="secondary" outline  className="m-1">
						<IntlMessages id="customer.cancel" />
					</Button>{" "} */}
			</div>
		
	  );
  }
};

export default accountManagement;
