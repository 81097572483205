import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import DatePicker from "../../components/common/CustomDateTimePicker";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { uuid } from 'uuidv4';

import ProductRow from "./ProductRow";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
 

class EditEnquiryModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateProductRow = this.updateProductRow.bind(this);
		
		this.state = {
			enquiry_data:null,
			facility_data:null,
			products:[],
			product_rows:[],
			expDate:'',
			tax_percentage:''
		};
	
	}
	
	componentDidMount() {		
		let products=[];
		
		let facility_data = localStorage.getItem('facility_data');
		facility_data =  dataCrypto.decrypt(facility_data);
		
		let facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        let formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'enquiries/get-data-for-enquiries',formData).then((res)=>{
			var data = res.data;
		
			products=data.products;
			
			products = products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			var product_rows = [{
					id:uuid(),
					product_id:products.length ? products[0]:null,
					product_quantity:"",
				}];
			
			this.setState({
				products,
				product_rows,
				facility_data,
				tax_percentage:data.tax_percentage,
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.enquiry_id!== this.props.enquiry_id && this.props.enquiry_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('enquiry_id',this.props.enquiry_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'enquiries/get-enquiry-detail',formData).then((res)=>{
					var data = res.data;
					
					if(data.status==1){
						var enquiry_data = data.enquiry_data;
						//var expDate= moment(enquiry_data.expiry_date,'YYYY-MM-DD');
						var enquiry_products = data.enquiry_data.enquiry_products;
						var product_rows =[];
						
						for(let row of enquiry_products){						
							let product_id = this.state.products.find(r=>{ return r.value==row.product_id });
							let quantity_val=row.quantity;
							
							let product_row = {
								id:uuid(),
								product_id:product_id,
								product_quantity:quantity_val,
							}
							
							product_rows.push(product_row);
							
						}
			
						this.setState({ 
							enquiry_data,
							product_rows,
							//expDate
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such enquiry","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				}); 
            }
        }
    }
	
	
	addProductRow = (value,id) => {
		var product_rows = this.state.product_rows;
		
		product_rows.push({
				id:uuid(),
				product_id:this.state.products.length ? this.state.products[0]:null,
				product_quantity:"",
			});
				
		this.setState({
			product_rows: product_rows
		});
	}
	
	updateProductRow = (value) => {
		var product_rows = this.state.product_rows;
		product_rows = product_rows.map(row=>{
			if(row.id===value.id){
				row = value;
			}
			return row;
		});
		
		this.setState({
			product_rows,
			expDate:null,
		});
	}
	
	removeProductRow = (id) => {
		var product_rows = this.state.product_rows;
		product_rows = product_rows.filter(row=>{ return row.id!=id });
		
		this.setState({
			product_rows
		});
	}
	handleExpDate = date => {
		this.setState({
		  expDate: date
		});
	  };
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {	
			
			let errorMessage='';

			if(this.state.product_rows.length==0){
				errorMessage="Please add at least one product";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			document.getElementById("create-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("editOrderForm"));
			
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('enquiry_id',this.props.enquiry_id);

			axios.post(REST_API_END_POINT+'enquiries/edit-enquiry',formData).then((res)=>{
				var data = res.data;
				document.getElementById("create-order").disabled=false;
				if(data.status==1){
					swal("Success!",data.msg,'success');
					this.props.toggleModal();					
					this.props.dataListRender();
					
					this.setState({
						product_rows:[{
							id:uuid(),
							product_id:this.state.products.length ? this.state.products[0]:null,
							product_quantity:"",
							
						}],
						expDate:"",
					});					
				}else{
					swal("Warning!",data.msg,'warning');
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("create-order").disabled=false;
			});

		}
		
	}
	
	render() {	
		const { products, product_rows, facility_data, enquiry_data } = this.state;
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  parseInt(dataCrypto.decrypt(facility_id));
			
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
			
			<ModalHeader toggle={this.props.toggleModal}>
				Edit Enquiry
			</ModalHeader>
			<AvForm
				id="editOrderForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="customer_name"
								name="customer_name"
								type="text"
								autoComplete="off"
								value={enquiry_data!=null ? enquiry_data.customer_name:""}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please enter the name"
								  },
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Email<span className="text-danger"></span></Label>
							  <AvField
								id="customer_email"
								name="customer_email"
								type="email"
								autoComplete="off"
								value={enquiry_data!=null ? enquiry_data.customer_email:""}
								// validate={{
								//   required: {
								// 	value: true,
								// 	errorMessage: "Please enter a valid email address"
								//   },
								// }}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Phone<span className="text-danger">*</span></Label>
								<AvField
									id="customer_phone"
									name="customer_phone"
									type="text"
									autoComplete="off"
									value={enquiry_data!=null ? enquiry_data.customer_phone:""}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										pattern: {
											value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
											errorMessage: "Invalid phone number"
										}
									}}
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="6 d-none">
						
						<AvGroup className="error-t-negative">
								  <Label>Expiry Date</Label>
								  <DatePicker
									id="exp_date"
									name="exp_date"
									className="mb-2"
									
									selected={(this.state.expDate)?this.state.expDate:(enquiry_data?((enquiry_data.expiry_date)?moment(enquiry_data.expiry_date,'YYYY-MM-DD'):''):'')}
									placeholderText=""
									dateFormat="YYYY-MM-DD"
									onChange={ this.handleExpDate }
								  />
						   </AvGroup>							
						</Colxx>
					</Row>
					
					{this.state.products.length >0 ? product_rows.map((product,index)=>{
						return(<ProductRow 
							key={index}
							index={index}
							tax_percentage={this.state.tax_percentage}
							product_row={product}
							products={this.state.products} 
							updateProductRow={this.updateProductRow}							
							removeProductRow={this.removeProductRow}							
							product_rows_length={product_rows.length}							
						/>);
					}):<p className="text-danger">The inventory has no products. Please add products to inventory.</p>}
					
					{this.state.products.length >0 && <Row>
						<Colxx lg="12" className="mt-3 mb-3">
							<Button type="button" color="primary" onClick={this.addProductRow} >Add Row</Button>
						</Colxx>					
					</Row>}
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Description</Label>
								<AvField
									id="description"
									name="description"
									type="textarea"
									rows="3"
									autoComplete="off"
									value={enquiry_data!=null ? enquiry_data.description:""}
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Message</Label>
								<AvField
									id="message"
									name="message"
									type="textarea"
									rows="3"
									autoComplete="off"
									value={enquiry_data!=null ? enquiry_data.message:""}
								/>
							</AvGroup>
						</Colxx>					
					</Row>

					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="create-order" >
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default EditEnquiryModal;
