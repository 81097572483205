import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge,  DropdownItem, DropdownMenu, } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import { REST_API_END_POINT,themeColor } from "../../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

export default class EmailManagement extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			email_templates: []
		};
	}
	
	
	componentDidMount() {
		
		axios.get(REST_API_END_POINT+'admin/email-templates').then((res)=>{ 
			this.setState({ email_templates: res.data.data, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}
	 
		
	render() {
		let data = this.state.email_templates;
				
		const dataTableColumns = [
			{
				Header: "#",
				Cell: props => {
					return data.findIndex(r=>{ return r.id==props.original.id })+1
				}
			},
			{
				Header: "Template Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Subject",
				accessor: "subject",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Actions",
				accessor: "alias_key",
				Cell: props => 	<NavLink to={'/administrator/email-management/edit/'+props.value} className="btn btn-primary" >
									<i className="simple-icon-pencil" />{" "}<span className="d-inline-block">Edit</span>
								</NavLink>
			}
		];	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.email-management" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row>			  
										<Colxx xxs="12">
											<div className="mb-4 dropdown-edit">					
												<ReactTable
													data={data}
													columns={dataTableColumns}
													defaultPageSize={10}
													filterable={false}
													showPageJump={false}
													PaginationComponent={DataTablePagination}
													showPageSizeOptions={true}
													defaultSorted={[{id: "x",asc: true}]}
												/>
											</div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
