import React, { Component,Fragment } from "react";
import { connect } from "react-redux";
import { NavItem, Badge,Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import Sortable from "react-sortablejs";

import swal from 'sweetalert';

import IntlMessages from "../../helpers/IntlMessages";
import ApplicationMenu from "../../components/common/ApplicationMenu";


export class TemplateApplicationMenu extends Component {
  constructor(props) {
    super();
	this.state = {		
	}
  }
    		
	deleteSection(e){
		var section_name = e.target.parentNode.parentNode.getElementsByClassName('section-name')[0].innerText;
		var li = e.target.parentNode.parentNode.parentNode;
			swal({
			  title: "Are you sure?",
			  text: "Delete section "+section_name+" ?",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
			  if (willDelete) {
				if(li.classList.contains('active')){
					if(li.nextSibling){
						li.nextSibling.classList.add("active");
					}
				}
				li.parentNode.removeChild(li);
				swal("The section has been deleted!", {
				  icon: "success",
				});
			  } else {
				swal("Your section is safe!");
			  }
			});
	}

  render() {
    
    return (
      <ApplicationMenu>
        <PerfectScrollbar
          option={{ suppressScrollX: true, wheelPropagation: false }}
        >
          <div className="p-4">
            <h3 className="font-weight-bold">
              Checked In Customers
            </h3>
				<div className="text-left mt-2 text-muted">	
					No checked in customers
				</div>
              <Sortable tag="ul" className="list-unstyled section-list">
                 <li className="section-li">
					<div className= "d-inline-block">
						<i className="iconsminds-male-2" style={{fontSize:'25px'}} />	
						<span className="section-name">AARON(15:02:33)</span>	
					</div>                   
                  </li>
				  <li className="section-li">
                    <div className= "d-inline-block">
						<i className="iconsminds-male-2" style={{fontSize:'25px'}} />							
						<span className="section-name">BENTON(14:01:35)</span>						
					</div>
                  </li>
                </Sortable>            
				<div className="text-center">	
				<Button
				  color="primary"
				  className=""
				  onClick={this.toggleModalAddSection}
				>
				  {" "}Checkout All Customers
				</Button>
				</div>
				
          </div>
		  <div className="pl-4 pr-4 pb-4 pt-2">
            <h3 className="font-weight-bold">
              Checked In Hypur Customers
            </h3>
			<div className="text-left text-muted">	
				No checked in hypur customers
			</div>
               <Sortable tag="ul" className="list-unstyled section-list">
                 <li className="section-li">
					
                    <div className= "d-inline-block">
						<i className="iconsminds-male-2" style={{fontSize:'25px'}} />	
						<span className="section-name">AARON(15:02:33)</span>						
					</div>
                  </li>
				  <li className="section-li">
                    <div className= "d-inline-block">
						<i className="iconsminds-male-2" style={{fontSize:'25px'}} />							
						<span className="section-name">BENTON(14:01:35)</span>						
					</div>
                  </li>
                </Sortable>                         			
		 </div>
        </PerfectScrollbar>
      </ApplicationMenu>
    );
  }
}

export default TemplateApplicationMenu;
