import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

 const selectStatus = [
    { label: "Active", value: "1", key: 0 },
    { label: "Inactive", value: "0", key: 1 }
];


class EditShelfModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.getWarehouseData = this.getWarehouseData.bind(this);
		this.state = {
			Warehouses:[],		
			ShelfTypes:[],
			warehouse_id:null,
			Status:[],		
			shelf_no: '',
			selectShelfType:{ label: "-- Select Shelf Type --", value:"", key: 0 },
			selectWarehouse:{ label: "--Select Warehouse --", value:"", key: 0 },
			shelf_structures: []
		};
	}
	
	componentDidMount() {
		this.setState({
			warehouse_id:this.props.warehouse_id
		});
		
		this.getWarehouseData(this.props.warehouse_id);
	}
	
	getWarehouseData(warehouse_id){
		var shelf_types=[];
		var facility_id = localStorage.getItem('facility_id');
		
		facility_id =  dataCrypto.decrypt(facility_id);
			
		var formData = new FormData();
		formData.append('facility_id',facility_id);		
		formData.append('warehouse_id',warehouse_id);
		
		axios.post(REST_API_END_POINT+'warehouse/get-data-for-add-shelf',formData).then((res)=>{
			var data = res.data;
			var shelf_nos = data.shelf_nos;
			shelf_types=data.shelf_types;
			var warehouses=data.warehouses;
			
			shelf_types = shelf_types.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			warehouses = warehouses.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			if(data.shelf_nos){
				this.setState({
					shelf_structures:shelf_nos,
					ShelfTypes:shelf_types,
					Warehouses:warehouses
				});
			}else{
				this.setState({
					shelf_structures:[],
					ShelfTypes:shelf_types,
					Warehouses:warehouses
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			document.getElementById("add-shelf").disabled=false;
		});	
	}
		
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let shelf;
			var status=null;
			
            if (prevProps.shelf_id!== this.props.shelf_id && this.props.shelf_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('shelf_id',this.props.shelf_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'warehouse/get-shelf-by-id',formData).then((res)=>{
					
					var data = res.data;
					shelf=data.shelf;
					
					this.setState({ 
						shelf,
						shelf_no:shelf.position,
						warehouse_id:shelf.warehouse_id,
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
    handleSubmit(event, errors, values) {		
		document.getElementById("update-shelf").disabled=true;
		
		var user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var formData = new FormData(document.getElementById("shelftypeForm"));
		formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		formData.append('shelf_id',this.props.shelf_id);
		
		 
		axios.post(REST_API_END_POINT+'warehouse/edit-shelf',formData).then((res)=>{
			var data = res.data;
			
			var api_message=document.getElementById("api_message");
			
			if(data.status==1 && typeof data.msg!='undefined'){
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
			}else if(typeof data.msg!='undefined'){
				api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
			}
			window.setTimeout(function() {
				JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
					JQUERY_MODULE.$(this).remove();
				});
			}, 5000);
			
			JQUERY_MODULE.$('.alert .close').on("click", function(e){
				JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
					JQUERY_MODULE.$(this).remove();
				});
			});
			
			document.getElementById("update-shelf").disabled=false;
			this.props.toggleModal();
			this.props.dataListRender();

		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			document.getElementById("update-shelf").disabled=false;
		});
		
	}
	
	handleClick = (shelf_no) => {
        this.setState({
			shelf_no
        });
    };

  
  render() {

		if(this.state.shelf!=null){
			var shelf=this.state.shelf;
		}else{
			var shelf;
		}
		
		if(JSON.stringify(this.state.shelf_structures)!=JSON.stringify(this.props.shelf_structures) && this.state.warehouse_id==this.props.warehouse_id){
			var shelf_structures= this.props.shelf_structures;
		}else{
			var shelf_structures= this.state.shelf_structures;
		}

		var {Warehouses,ShelfTypes} = this.state;
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Shelf
		  </ModalHeader>
			<AvForm
		        id="shelftypeForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							value={ shelf?((shelf.name!=null)?shelf.name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Warehouse<span className="text-danger">*</span></Label>
							<AvField
								type="select"
								name="warehouse_id"
								id="warehouse_id"
								placeholder="--Select Warehouse--"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
								value={this.state.warehouse_id}
								onChange={(e)=>{
									this.setState({
										warehouse_id:e.target.value
									},()=>{
										this.getWarehouseData(this.state.warehouse_id)
									});
								}}
							>
							<option value="" >--Select Warehouse--</option>
							{Warehouses.map(opt=>{
								return <option key={opt.key} value={opt.value} >{opt.label}</option>
							})}
							</AvField>						  
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Type Of Shelf<span className="text-danger">*</span></Label>
							<AvField
								type="select"
								name="shelf_type_id"
								id="shelf_type_id"
								placeholder="--Select Shelf Type--"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
								value={ shelf?((shelf.shelf_type_id!=null)?shelf.shelf_type_id:''):'' }
							>
								<option value="" >--Select Shelf Type--</option>
								{ShelfTypes.map(opt=>{
									return <option key={opt.key} value={opt.value} >{opt.label}</option>
								})}
							</AvField>
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Shelf No<span className="text-danger">*</span></Label>
						  <AvField
							id="table-no"
							name="position"
							type="text"
							readOnly={true}
							value={this.state.shelf_no}
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<br/>
				<h4 className="font-weight-bold">Choose any one of Available Shelf position</h4>	
                   {shelf_structures.map((row,index)=>{
					return (<span key={index} className="w-40px p-2 m-1 border d-inline-block text-center" onClick={()=>{this.handleClick(row.value)}}>
							{row.value}
							</span>)
				})}
				<Row className="mt-2">
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Status<span className="text-danger">*</span></Label>
							<AvField
								type="select"
								name="status"
								id="status"
								placeholder="--Select Status--"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
								value={ shelf?((shelf.status!=null)?shelf.status:''):'' }
							>
								{selectStatus.map(opt=>{
									return <option key={opt.key} value={opt.value} >{opt.label}</option>
								})}
							</AvField>							
						</AvGroup>
					</Colxx>					
				</Row>			 
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary"  id="update-shelf">
			  <IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditShelfModal;
