import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Badge,
  Card,
  Input
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
//import DatePicker from "react-datepicker";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import DataTablePagination from "../../components/DatatablePagination";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import 'react-quill/dist/quill.bubble.css';
import ReactTable from "react-table";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class MovePlantModal extends Component{

	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.state = {
	 		selectRoom:[],
	        selectTable:[]
		};
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("move-plant").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("moveForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_ids',this.props.plant_ids);
		
		
			axios.post(REST_API_END_POINT+'growhouse/move-plants',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\"  role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("move-plant").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("move-plant").disabled=false;
			});

		}
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		

		var rooms=[];
		var tables=[];
        if(this.props.modalOpen){
			let plant;
			let rooms;
		    var room=null;
		    var type=null;
			
            if (prevProps.plant_ids !== this.props.plant_ids) {
				var formData = new FormData();
				formData.append('plant_ids',this.props.plant_ids);
				formData.append('room_id',this.props.room_id);
				formData.append('table_id',this.props.table_id);
	
				axios.post(REST_API_END_POINT+'growhouse/data-for-move-plants',formData).then((res)=>{
					var data = res.data;
					plant = data.plants;
					rooms=data.rooms;			
			        tables=data.tables;		
					
					rooms = rooms.map((element,index)=>{
							element.label=element.name;
							element.value=element.id;
							element.key=index;
							return element;
						});
							
					tables = tables.map((element,index)=>{
						element.label=element.name;
						element.value=element.id;
						element.key=index;
						return element;
					});	
					this.state.selectRoom.forEach((element,index) => { 
			
							if(element.value==rooms.name){
							
								rooms = element;
								
							}
					});
					
					const room_id = this.props.room_id;
					const table_id = this.props.table_id;
					
					this.getRoomData(room_id);
					
					
					
					this.setState({ 
					  plant,
					  selectRoom:rooms,
					  rooms,
					  tables,
					  room_id,
					  table_id,
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}
		}
	}

	getRoomData(room_id){
		var tables=[];

		var facility_id = localStorage.getItem('facility_id');
		
		facility_id =  dataCrypto.decrypt(facility_id);
			
		var formData = new FormData();
		formData.append('room_id',room_id);		
		
		
		axios.post(REST_API_END_POINT+'growhouse/get-active-tables-by-room',formData).then((res)=>{
			var data = res.data;
			

			if(data.tables){
				
				tables=data.tables;	
				tables = tables.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				this.setState({
					selectTable:tables,
				});
			}else{
				this.setState({

					selectTable:tables,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			
		});	
	}

    render() {

		let data = this.state.plant

        if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search.toLowerCase()) || row.strain.toLowerCase().includes(this.state.search.toLowerCase())|| row.phase.toLowerCase().includes(this.state.search.toLowerCase()) 
			})
		}		
		
		  
		const {selectRoom,selectTable} = this.state;   
		  
      	const dataTableColumns = [
			  {
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			  },
			  {
				Header: "Strain",
				accessor: "strain",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
			  {
				Header: "Phase",
				accessor: "phase",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
			  {
				Header: "BirthDate",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
				{
				Header: "Time in Room",
				accessor: "time_in_room",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
			   {
				Header: "Position",
				accessor: "position",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
				 {
				Header: "Group",
				accessor: "group_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
					 {
				Header: "Mother",
				accessor: "mother",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
					 {
				Header: "Reminder",
				accessor: "remainder",
				Cell: props => <p className="text-muted">{props.value}</p>
			  },
			  {
				Header: "Status",
				accessor: "status",
				Cell: props => <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
			  },	  	  
	 
		];
	  return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
			<ModalHeader toggle={this.props.toggleModal}>
				Move Plant 
			</ModalHeader>
			<Card className="p-4 mt-4">			
					<div className="mb-4 dropdown-edit">
						<FormGroup row>
							<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value})}
									/>
							</Colxx>
						</FormGroup>
						<ReactTable
							  data={data}
							  columns={dataTableColumns}
							  defaultPageSize={10}
							  filterable={false}
							  showPageJump={false}
							  PaginationComponent={DataTablePagination}
							  showPageSizeOptions={true}
							  defaultSorted={[{id: "send_on",desc: true}]}
							/>
					</div>
			</Card>		
				<AvForm
					id="moveForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
				<ModalBody>
						<Row className="mt-4">
								<Colxx lg="6">
									<AvGroup className="error-t-negative">
										<Label>Rooms<span className="text-danger">*</span></Label>
										<AvField
											type="select"
											name="room_id"
											id="room_id"
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  }
											}}
											value={this.state.room_id}
											onChange={(e)=>{
												this.setState({
													room_id:e.target.value
												},()=>{
													this.getRoomData(this.state.room_id)
												});
											}}
										>
										<option value="" >--Select Room--</option>
										{selectRoom.map(opt=>{
											return <option key={opt.key} value={opt.value} >{opt.label}</option>
										})}
										</AvField>						  
									</AvGroup>
								</Colxx>
								<Colxx lg="6" className="mb-2">						
									<AvGroup className="error-t-negative">
											<Label>Table<span className="text-danger">*</span></Label>
											<AvField
												type="select"
												name="table_id"
												id="table_id"
												placeholder="--Select Table--"
												value={this.state.table_id}
												validate={{
												  required: {
													value: true,
													errorMessage: "This field is required."
												  }
												}}
											>
												<option value="" >--Select Table--</option>
												{selectTable.map(opt=>{
													return <option key={opt.key} value={opt.value} >{opt.label}</option>
												})}		
											</AvField>
									</AvGroup>								
							</Colxx>
						</Row>	
						<AvGroup className="error-t-negative">
							<Label>Reason</Label>	&nbsp;&nbsp;
							<AvField
								id="reason_for_movement"
								name="reason_for_movement"
								type="textarea"
								autoComplete="off"
								value={this.state.textarea_content}
								placeholder=""
								rows="3"
								validate={{
									minLength: {
										value: 5,
										errorMessage:"Your message must have minimum of 5 characters"
									},
								}}
							/>
						</AvGroup>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="move-plant" >
					  <IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default MovePlantModal;
