import React, { Component }  from "react";
import Barcode from 'react-barcode';
import moment from 'moment';


export default class PrintTag extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const { plant_print  } = this.props;
		
		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 160px 160px;
						  margin:0;
						}
					}
				`}} />
				
				<div style={{padding:'12px 10px 2px 12px',display:'flex'}} >                  
					<div style={{width:'160px', height:'150px', fontFamily: 'sans-serif', fontSize:'11px'}}>
						<strong>Strain</strong>: {plant_print.strain} <br/>
						<strong>Phase</strong>: {plant_print.phase} <br/>
						{plant_print.group!='' ? <div><strong>Group</strong>: {plant_print.group}<br/></div>:""} 			
						{plant_print.birth_date!=null ? <div><strong>Birth Date</strong>: {moment(plant_print.birth_date).format('MM/DD/YYYY HH:mm')}<br/>	</div>:""}
						<strong>Room</strong>: {plant_print.room} <br/>
						<strong>Position</strong>: {plant_print.position} <br/>
						{plant_print.vendor!='' ? <div><strong>Vendor</strong>: {plant_print.vendor}<br/>	</div>:""} <br/>
						<div className="text-center">
							<Barcode value={plant_print.barcode} height={25} width={0.95} margin={0} marginTop={5} displayValue={false} />
							<p className="text-center">{(plant_print.barcode.match(/.{1,4}/g)).join(' ')}</p>
						</div>
					</div>
				</div>					
			</div>
		);
	}
};