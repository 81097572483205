import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import DatePicker from "../../components/common/CustomDateTimePicker";

import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import moment from "moment";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import { uuid } from 'uuidv4';

import ProductRow from "./ProductRow";

import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import AddNewCustomerModal from "../../containers/customer/AddNewCustomerModal";
import { error } from "jquery";
var dataCrypto = new SimpleCrypto(dataEncryptKey);
 

class AddEnquiryModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateProductRow = this.updateProductRow.bind(this);
		this.toggleModalAdd=this.toggleModalAdd.bind(this);
		this.dataListRender=this.dataListRender.bind(this);
		this.state = {
			facility_data:null,
			products:[],
			product_rows:[],
			tax_percentage:"",
			expDate: null,
			customers:[],
			selectCustomer:"",
			modalOpenAdd: false,
		};
	
	}
	
	componentDidMount() {
		
				
		let products=[];
		let customers=[];
		let facility_data = localStorage.getItem('facility_data');
		facility_data =  dataCrypto.decrypt(facility_data);
		
		let facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        let formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'enquiries/get-data-for-enquiries',formData).then((res)=>{
			var data = res.data;
		
			products=data.products;
		    customers=data.customers;
				
			
			products = products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			customers = customers.map((element,index)=>{
				element.label=element.vendor_name;
				element.value=element.vendor_name;
				element.key=index;
				return element;
			});
			
			var product_rows = [{
					id:uuid(),
					product_id:products.length ? products[0]:null,
					product_quantity:"",
					
				}];
			
			this.setState({
				products,
				product_rows,
				facility_data,
				tax_percentage:data.tax_percentage,
				customers,
				modalOpenAdd: false,
				
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	addProductRow = (value,id) => {
		var product_rows = this.state.product_rows;
		
		product_rows.push({
				id:uuid(),
				product_id:this.state.products.length ? this.state.products[0]:null,
				product_quantity:"",
				
			});
				
		this.setState({
			product_rows: product_rows,
			
		});
	}
	
	updateProductRow = (value) => {
		var product_rows = this.state.product_rows;
		product_rows = product_rows.map(row=>{
			if(row.id===value.id){
				row = value;
			}
			return row;
		});
		
		this.setState({
			product_rows
		});
	}
	
	removeProductRow = (id) => {
		var product_rows = this.state.product_rows;
		product_rows = product_rows.filter(row=>{ return row.id!=id });
		
		this.setState({
			product_rows
		});
	}
	handleExpDate = date => {
		this.setState({
		  ExpDate: date
		});
	};
	toggleModalAdd(){
	
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd,
			
		  });
	}
	dataListRender=()=>{
		
		console.log('Deret');
		
		let products=[];
	    let customers=[];
		// let product_rows=this.state.product_rows
		let facility_data = localStorage.getItem('facility_data');
		facility_data =  dataCrypto.decrypt(facility_data);
	
		let facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        let formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'enquiries/get-data-for-enquiries',formData).then((res)=>{
			var data = res.data;
		
			products=data.products;
			customers=data.customers;
		
			
			products = products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			customers = customers.map((element,index)=>{
				element.label=element.vendor_name;
				element.value=element.vendor_name;
				element.key=index;
				return element;
			});
			
			// product_rows = product_rows.map(row=>{
			// 	console.log('RRRrrr',row.id);
			// 	console.log('RRRrdd',products[0]);
			// 	if(row.id===products[0].product_id){
			// 		row = products[0];
			// 		console.log('RRRrrr',row.id);
			// 	}
			// 	return row;
			// });
			
			// this.setState({
			// 	product_rows
			// });
			
			this.setState({
				products,
				customers,
			
			
				
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	/*handleSubmit(event, errors, values) {
		{console.log('Errors',errors);
		}		
		if (errors.length === 0) {	
			
			let errorMessage='';
			
			if(this.state.selectCustomer.length==0){
				errorMessage="Please Choose  a Customer";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			if(this.state.product_rows.length==0){
				errorMessage="Please add at least one product";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			document.getElementById("create-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("orderForm"));
			
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('tax_percentage',this.state.tax_percentage);
			formData.append('customer_id', this.state.selectCustomer.vendor_id);

			axios.post(REST_API_END_POINT+'enquiries/add-enquiry',formData).then((res)=>{
				var data = res.data;
				document.getElementById("create-order").disabled=false;
				if(data.status==1){
					swal("Success!",data.msg,'success');
					this.setState({
						product_rows:[{
							id:uuid(),
							product_id:this.state.products.length ? this.state.products[0]:null,
							product_quantity:"",
							
						}],
						expDate:null,
					});	
					this.form && this.form.reset();
					this.props.toggleModal();					
					this.props.dataListRender();
					
								
				}else{
					swal("Warning!",data.msg,'warning');
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("create-order").disabled=false;
			});

		}
		
	}*/

	handleSubmit() {
		console.log('De232545ret');
		let errorMessage='';
			if(this.state.selectCustomer.length==0){
				errorMessage="Please Choose  a Customer";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			if(this.state.product_rows.length==0){
				errorMessage="Please add at least one product";
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			document.getElementById("create-order").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("orderForm"));
			
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('tax_percentage',this.state.tax_percentage);
			formData.append('customer_id', this.state.selectCustomer.vendor_id);

			axios.post(REST_API_END_POINT+'enquiries/add-enquiry',formData).then((res)=>{
				var data = res.data;
				document.getElementById("create-order").disabled=false;
				if(data.status==1){
					swal("Success!",data.msg,'success');
					this.setState({
						product_rows:[{
							id:uuid(),
							product_id:this.state.products.length ? this.state.products[0]:null,
							product_quantity:"",
							
						}],
						expDate:null,
					});	
					this.form && this.form.reset();
					this.props.toggleModal();					
					this.props.dataListRender();
					
								
				}else{
					swal("Warning!",data.msg,'warning');
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("create-order").disabled=false;
			});

		
		
	}
	render() {	
		const { products, product_rows, facility_data,customers } = this.state;
		var facility_id = localStorage.getItem('facility_id');
			facility_id =  parseInt(dataCrypto.decrypt(facility_id));
		
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right modal-right-lg"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add Enquiry
			</ModalHeader>
			<AvForm
				id="orderForm"
				ref={c => (this.form = c)}
				className="av-tooltip tooltip-right-bottom"
				// onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						<Colxx lg="9">
							<AvGroup className="error-t-negative">
								<Label>Select Customer<span className="text-danger">*</span>{facility_id==12 ? <span className="ml-2">(<strong>Com</strong>: Company, <strong>Ind</strong>: Individual)</span>:""}</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="customer_name"
									id="customer_name"
									value={this.state.selectCustomer}						
									options={customers}
									onChange={selectCustomer => { this.setState({ selectCustomer }) }}
									required
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="2 m-4">
						<Button color="primary" onClick={this.toggleModalAdd} ><b><i className="simple-icon-plus" /></b></Button>
						</Colxx>
					</Row>
				
					{/* <Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="customer_name"
								name="customer_name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "Please enter the name"
								  },
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row> */}
					
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Email<span className="text-danger"></span></Label>
							  <AvField
								id="customer_email"
								name="customer_email"
								type="email"
								autoComplete="off"
								readOnly={true}
								value={this.state.selectCustomer!=null ? this.state.selectCustomer.email_id:null}
								// validate={{
								//   required: {
								// 	value: true,
								// 	errorMessage: "Please enter a valid email address"
								//   },
								// }}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Phone<span className="text-danger">*</span></Label>
								<AvField
									id="customer_phone"
									name="customer_phone"
									type="text"
									autoComplete="off"
									// validate={{
									// 	required: {
									// 		value: true,
									// 		errorMessage: "This field is required."
									// 	},
									// 	pattern: {
									// 		value: "^[0-9]{3}[0-9]{3}[0-9]{4}$",
									// 		errorMessage: "Invalid phone number"
									// 	}
									// }}
									readOnly={true}
									value={this.state.selectCustomer!=null ? this.state.selectCustomer.phone:null}
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="6 d-none">
						<AvGroup className="error-t-negative">
								  <Label>Expiry Date</Label>
								  <DatePicker
									id="exp_date"
									name="exp_date"
									className="mb-2"
									selected={this.state.ExpDate}
									placeholderText=""
									dateFormat="YYYY-MM-DD"
									onChange={ this.handleExpDate }
								  />
								</AvGroup>
					</Colxx>
					</Row>
					
					{this.state.products.length >0 ? product_rows.map((product,index)=>{
						return(<ProductRow 
							key={index}
							index={index}
							product_row={product}
							tax_percentage={this.state.tax_percentage}
							products={this.state.products} 
							updateProductRow={this.updateProductRow}							
							removeProductRow={this.removeProductRow}
							dataListRender={this.dataListRender}							
							product_rows_length={product_rows.length}							
						/>);
					}):<p className="text-danger">The inventory has no products. Please add products to inventory.</p>}
					
					{this.state.products.length >0 && <Row>
						<Colxx lg="12" className="mt-3 mb-3">
							<Button type="button" color="primary" onClick={this.addProductRow} >Add Row</Button>
						</Colxx>					
					</Row>}
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Description</Label>
								<AvField
									id="description"
									name="description"
									type="textarea"
									rows="3"
									autoComplete="off"
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Message</Label>
								<AvField
									id="message"
									name="message"
									type="textarea"
									rows="3"
									autoComplete="off"
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="create-order"  onClick={this.handleSubmit} >
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
			
			{this.state.modalOpenAdd?<AddNewCustomerModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				 dataListRender={this.dataListRender}
				 productRow={this.state.product_rows}
			/>:""}
		</Modal>
	  );
  }
};

export default AddEnquiryModal;
