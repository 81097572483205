const pageStructure = [
	{
		name:'Heading',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'sub_head',
				label:'Sub Title',
				type:'text',
				value:''
			},
			{
				key:'button',
				label:'Button Text',
				type:'text',
				value:''
			},
			{
				key:'button_link',
				label:'Button Link',
				type:'text',
				value:''
			}
		]
	},
	{
		name:'Quote',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'quote',
				label:'Content',
				type:'text',
				value:''
			},
			{
				key:'author',
				label:'Author',
				type:'text',
				value:''
			}
		]
	},
	{
		name:'Left Right Block',
		fields:[
			{
				key:'head',
				label:'Title',
				type:'text',
				value:''
			},
			{
				key:'image_url',
				label:'Image URL',
				type:'file',
				value:''
			},
			{
				key:'image_position',
				label:'Image Position',
				type:'select',
				options:[
					{key:1,label:'Left',value:'left'},
					{key:2,label:'Right',value:'right'},
				],
				value:'left'
			},
			{
				key:'image_hover',
				label:'Image Right Content',
				type:'text',
				value:''
			},
			{
				key:'content',
				label:'Content',
				type:'quill',
				value:''
			},
			{
				key:'background',
				label:'Background Color',
				type:'color',
				value:'#ffffff'
			},
			{
				key:'text_color',
				label:'Text Color',
				type:'color',
				value:'#303030'
			}
		]
	}
];
export default pageStructure;
