import React, { Component } from "react";
import { Row, Card, CardTitle, Label, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Formik, Form, Field } from "formik";
import swal from 'sweetalert';

import { projectTitle,REST_API_END_POINT,dataEncryptKey,BASE_URL } from "../../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { seo } from "../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			configurations: {
				facility_footer_logo:null,
				facility_logo:null
			},
		};
		this.validate = this.validate.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		seo({
			title: 'Forgot Password | '+projectTitle,
		});
	}
  
	componentDidMount(){
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id!=null){
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			axios.post(REST_API_END_POINT+'website/configurations',formData).then((res)=>{
				this.setState({ configurations: res.data.configurations, blocking:false });
			}).catch(e =>{ 
				console.log("Addition failed, Error ", e) 
				this.setState({ blocking:false });
			});
		}
	}
  
	handleSubmit(values) {
		//console.log(values);
		document.getElementById("reset-password-button").disabled = true;
		var form_data = new FormData();
		form_data.append("email",values.email);
		
		axios.post(REST_API_END_POINT+'register/forgot-password',form_data).then((data)=>{ 
			
			if(data.data.not_active){
				swal("The account is not active!", "Please verify your email to activate the account.", "error");
			}else if(data.data.no_user){
				swal("No account found!", "Please check your email address.", "error");
			}else if(data.data.email_send){
				document.getElementById("reset-password").reset();
				swal("Email has been sent!", "A verification email has been sent to your registered email.", "success");
			}else{
				swal("Failed to send email!", "Please try after sometime.", "error");
			}
			document.getElementById("reset-password-button").disabled = false;
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			document.getElementById("reset-password-button").disabled = false;
			swal("Failed to send email!", "Please check your internet connection", "error");
		});	
	}
  
	validate(values) {
        let errors = {};

        if (!values.email) {
            errors.email = 'Please enter your email address';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }
	
  render() {
		const { configurations } = this.state;
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="9" lg="7" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="form-side">
				<div className="d-block text-center">
				  <NavLink to={`/`} className="white">
					  {configurations.facility_logo==null ? <span className="logo-single" />:<img src={BASE_URL+'pages/'+configurations.facility_logo} className="img-responsive mb-3" style={{maxHeight:'60px'}}   />}
				  </NavLink>
				</div>
              <CardTitle className="mb-4">
                <IntlMessages id="user.forgot-password" />
              </CardTitle>
			  <p className="mb-4">
                Please use your e-mail to reset your password.                
              </p>
              <Formik
				validate={this.validate}
                initialValues={{
                  email: ""
                }}
                onSubmit={this.handleSubmit}
              >
                {({ errors, touched,isValidating }) => (
				<Form className="av-tooltip tooltip-right-bottom" id="reset-password">
					<Label className="form-group has-float-label mb-4">
					  <Field name="email"  className="form-control" type="email" />
					  {errors.email && touched.email && <div className="invalid-feedback d-block">{errors.email}</div>}
					  <IntlMessages id="user.email" />
					</Label>

					<div className="d-flex justify-content-center align-items-center">
					  <Button						
						color="primary"
						className="btn-shadow"
						size="lg"
						type="submit"
						id="reset-password-button"
					  >
						<IntlMessages id="user.reset-password-button" />
					  </Button>
					</div>
              </Form>
                )}
              </Formik>
			  
			  <p className="text-center mt-sm mb-0">
					Already a Member {" "}
					<NavLink to={`/user/login`} className="">
					  Login Now!
					</NavLink>
				</p>
			  
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
