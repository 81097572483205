import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const MenuCart = ({ cartData, currency, deleteFromCart }) => {
	let cartSubtotalPrice = 0;
	let cartTaxPrice = 0;
	let cartTotalPrice = 0;
	let cartDiscount = 0;
	const { addToast } = useToasts();
  
	if(cartData && cartData.length >= 1){
		let coupon_discount=0;
		var coupon_data = localStorage.getItem('shopCartCoupon');
		if(coupon_data!=null){
			coupon_data = JSON.parse(coupon_data);
			if(coupon_data['is_product']=='1' && coupon_data['type']=='0'){					
				coupon_discount += parseFloat(coupon_data['amount']);
			}
		}
		
		cartData.map((cartItem, key) => {
			const finalProductPrice = (cartItem.price * currency.currencyRate).toFixed(2);
			
			cartSubtotalPrice += cartItem.product_unit_price * cartItem.quantity;
			cartTaxPrice += cartItem.sales_tax * cartItem.quantity;
			cartTotalPrice += finalProductPrice * cartItem.quantity;
			
			cartDiscount+= typeof cartItem.coupon_discount!='undefined' ? cartItem.coupon_discount*currency.currencyRate:0
			return cartItem;
		});
		
		if(coupon_data!=null){
			if(coupon_data['is_product']=='1' && coupon_data['type']=='1'){
				coupon_discount+=cartTotalPrice*parseFloat(coupon_data['amount'])/100;
			}
		}
		
		if(cartTotalPrice<coupon_discount){
			coupon_discount=0;
		}
		cartDiscount+=coupon_discount;
		cartTotalPrice-=cartDiscount;
	}
  
  return (
    <div className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData.map((single, key) => {
              const finalProductPrice = (single.price * currency.currencyRate).toFixed(2);              
              
			  let variation = typeof single.variations!='undefined' ? single.variations.find(s => s.variation_id === single.variation_id):{variation_name:""};
              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                      <img
                        alt=""
                        src={single.thumbnail_url}
                        className="img-fluid"
                      />
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                        {" "}
                        {single.name}{" "}
						{variation ? <span className="d-block font-weight-bold">{variation.variation_name}</span>:""}
                    </h4>
                    <h6>Qty: {single.quantity}</h6>
                    <span>
                      {currency.currencySymbol}{finalProductPrice}
                    </span>
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => deleteFromCart(single, addToast)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h5>
				Sub total{" "}<span>{currency.currencySymbol}{cartSubtotalPrice.toFixed(2)}</span>
			</h5>
			<h5>
				Tax{" "}<span>{currency.currencySymbol}{cartTaxPrice.toFixed(2)}</span>
			</h5>
			<h5 className="mb-2">
				Discount{" "}<span>{currency.currencySymbol}{cartDiscount.toFixed(2)}</span>
			</h5>
			<h4>
              Total :{" "}
              <span className="shop-total">
                {currency.currencySymbol}{cartTotalPrice.toFixed(2)}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="btn btn-primary btn-sm text-uppercase" to={"/cart"} >
              view cart
            </Link>
            <Link className="btn btn-primary btn-sm text-uppercase" to={"/checkout"} >
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <h6 className="text-center mt-2">No items added to cart</h6>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func
};

export default MenuCart;
