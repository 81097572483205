import React, { Component } from "react";
import { Colxx } from "../../components/common/CustomBootstrap";

import {
	Input,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Badge
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";

import moment from 'moment';

const ViewModal = ({ modalOpen, toggleModal, deleteShift, toggleModalEditShift, schedule }) => {
	
	if(schedule!=null){
		return (	  
			<Modal
			  isOpen={modalOpen}
			  toggle={toggleModal}
			  size="md"
			>
				<ModalHeader toggle={toggleModal}>
					Schedule for {schedule.usersName}
				</ModalHeader>
				
				<ModalBody className="with-switch">
				{schedule.shiftStart} - {schedule.shiftEnd} <Badge color="primary" className="font-normal-size">Added by: {schedule.createdBy}</Badge>
				<div className="h4 font-weight-normal">
				{schedule.description}
				</div>
				  
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={(e)=>{ toggleModalEditShift(e,schedule) } } >
						<IntlMessages id="customer.edit" />
					</Button>
					<Button color="danger" id="delete-shift" onClick={(e)=>{ deleteShift(e,schedule.schedId,schedule.usersName) }} >
						<IntlMessages id="customer.delete" />
					</Button>{" "}
					<Button color="secondary" outline onClick={toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>				
			</Modal>
		);
	}else{
		return "";
	}
};

export default ViewModal;
