import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Customers from "../../../components/customer/Customers";
import AddGetStarted from "../../../containers/customer/AddGetStarted";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import TemplateApplicationMenu from "../../../containers/template/TemplateApplicationMenu";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddNewPaymentModal from "../../../containers/officialrequests/AddNewPaymentModal";
import EditDistributionModal from "../../../containers/accounts/EditDistributionModal";
import ReactTable from "react-table";

import data from "../../../data/officialrequests";

export default class Payment extends Component {
	
	constructor(props) {
		super(props);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		 modalOpenAdd: false,
		 modalOpenEdit: false,
		  modalOpenStaff: false,
		  data:data,
		  search: '',
		};
	}
	
	 toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
     
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
	  {
		Header: "",
		accessor: "",
		width:50,
		Cell: props => <FormGroup></FormGroup>
	  },
	  {
		Header: "",
		accessor: "status",
		Cell: props =><Badge className="mt-4" color="secondary">Paid</Badge>
	  },	 
	  {
		Header: "",
		accessor: "id",
		Cell: props => <p className="mt-4"> Awarded to NGM IBSS</p>
	  },
	  {
		Header: "",
		accessor: "name",
		Cell: props => <p className="mt-4"> 5000.00 USD</p>
	  },
	  {
		Header: "",
		accessor: "address",
		Cell: props =><img className="image-center mt-4"  alt="Responsive image" src="/assets/img/avatar.jpg" width="40" height="40"/>

	  },
	  {
		Header: "",
		accessor: "email",
		Cell: props => <p className="mt-4">NGM IBSS</p>
	  },
	  {
		Header: "",
		accessor: "",
		Cell: props =><Fragment><div className="action-btns w-9 w-sm-100"><ItemDropdown item={<DropdownMenu >
				<DropdownItem>
					<NavLink to="#" onClick={this.toggleModalEdit} >
						<span className="d-inline-block pl-2">View</span>
					</NavLink>									
				</DropdownItem>			
			  </DropdownMenu>} /></div></Fragment> 
			  
	  }
	  
	];
    return (
      <Fragment>
        <Row>
          <Colxx lg="12">
            <Breadcrumb heading="menu.payment" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		<Card className="p-4">
		   <Row>
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New payment							  
						</Button>																		
						   <AddNewPaymentModal
							modalOpen={this.state.modalOpenAdd}
							toggleModal={this.toggleModalAdd}
						  />
					</div>
					 <EditDistributionModal
							modalOpen={this.state.modalOpenEdit}
							toggleModal={this.toggleModalEdit}
						  />
				</Colxx>
				
		    </Row>
		
		
		<div className="mb-4">
		   <Row>
		      <Colxx lg="4 mt-4 ">
			       <Card className="p-2 payments_box"  style={{backgroundColor:"#00AEEF" , color:"#fff"}}>
				      <div className="text-center mt-4 mb-4" >
					       <h2>New(0)</h2>
				      </div>
				  </Card>
			  </Colxx>
			 <Colxx lg="4 mt-4">
                  <Card className="p-2 payments_box" style={{backgroundColor:"#00AEEF" , color:"#fff"}}>
				      <div className="text-center mt-4 mb-4">
				        <h2>Paid(1)</h2>
					  </div>
				  </Card>
			  </Colxx>
			 <Colxx lg="4 mt-4">
                  <Card className="p-2 payments_box" style={{backgroundColor:"#00AEEF" , color:"#fff"}}>
				      <div className="text-center mt-4 mb-4">
				    <h2>All Payments(1)</h2>
					  </div>
				  </Card>
			  </Colxx>
			 </Row>
		</div>
		
		<div className="mt-4 mb-4">
		     <ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={1}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
		</div>
		
			
		</Card>
      </Fragment>
    );
  }
}
