const processor = [
{
    id: 1,
    name: "Room 1", 
	roomno: "2",   
	productCount: "Test Product 10 (Station,Distribution,Genetics), Sample Product 8 (Distribution)",   
	createdAt: "2019-04-19 00:28:54",   
	createdBy: "",   
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Room 2",
	roomno: "2",   
	productCount: "Test Product 10 (Station,Distribution,Genetics), Sample Product 8 (Distribution)",   
	createdAt: "2019-04-19 00:28:54",   
	createdBy: "", 
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default processor
