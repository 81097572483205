import React, { Component } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import AdminLayout from "../../layout/AdminLayout";
import dashboard from "./dashboard";
import users from "./users";
import userDetail from "./user-detail";
import subscriptions from "./subscriptions";
import discounts from "./discounts";
import paymentHistory from "./payment-history";
import emailManagement from "./email-management";
import reports from "./reports";
import settings from "./settings";

class Admin extends Component {
  render() {
    const { match } = this.props;

    return (
      <AdminLayout>
        <Switch>
			<Redirect exact from={`${match.url}/`} to={`${match.url}/dashboard`} />
			<Route path={`${match.url}/dashboard`} component={dashboard} />
			<Route path={`${match.url}/users`} component={users} />
			<Route path={`${match.url}/user-detail/:id`} component={userDetail} />
			<Route path={`${match.url}/subscription-plans`} component={subscriptions} />
			<Route path={`${match.url}/discounts`} component={discounts} />
			<Route path={`${match.url}/payment-history`} component={paymentHistory} />
			<Route path={`${match.url}/email-management`} component={emailManagement} />
			<Route path={`${match.url}/reports`} component={reports} />
			<Route path={`${match.url}/settings`} component={settings} />
          <Redirect to="/error" />
        </Switch>
      </AdminLayout>
    );
  }
}
const mapStateToProps = ({ menu }) => {
  const { containerClassnames } = menu;
  return { containerClassnames };
};

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Admin)
);
