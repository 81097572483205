import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Customers from "../../../components/customer/Customers";
import AddGetStarted from "../../../containers/customer/AddGetStarted";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import TemplateApplicationMenu from "../../../containers/template/TemplateApplicationMenu";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import Requests from "../../../components/officialrequests/Requests";
import Updates from "../../../components/officialrequests/Updates"; 
import Public from "../../../components/officialrequests/Public"; 
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddDepartmentModal from "../../../containers/department/AddDepartmentModal";
import AddNewRequestModal from "../../../containers/officialrequests/AddNewRequestModal";
import EditDistributionModal from "../../../containers/accounts/EditDistributionModal";
import ReactTable from "react-table";

import data from "../../../data/officialrequests";

export default class Assignment extends Component {
	
	constructor(props) { 
		super(props);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		 modalOpenAdd: false,
		 modalOpenEdit: false,
		  modalOpenStaff: false,
		  data:data,
		  search: '',
		};
	}
	
	 toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
     
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
	const dataTableColumns = [
	
		
	  
	];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.assignment" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button
							color="primary"
							size="sm"
							className=""						
						  >
							<IntlMessages id="Options" />
					  </Button>
						{" "}
					</div>
					 
					
					
			  </Colxx>
			</Row>
		
		
		
		
		 <Colxx lg="12" className="page-tabs">
              <Card className="mb-4 mt-4 ">
                <CardHeader>
                  <Nav tabs className="card-header-tabs ">
                <div>
					<NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeFirstTab === "1",
                          "nav-link": true
                        })}
                        onClick={() => {
                          this.toggleFirstTab("1");
                        }}
                        to="#"
						
                      >
                       <p style={{fontSize:"15px"}}> Requests</p>
                      </NavLink>
                    </NavItem>
				</div>
				<div>
					<NavItem>
					<NavLink
                        className={classnames({
                          active: this.state.activeFirstTab === "2",
                          "nav-link": true
                        })}
                        onClick={() => {
                          this.toggleFirstTab("2");
                        }}
                        to="#"
                      >
                        <p style={{fontSize:"15px"}}>Public</p>
                      </NavLink>
                    </NavItem>
				</div>
				<div>
					<NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeFirstTab === "3",
                          "nav-link": true
                        })}
                        onClick={() => {
                          this.toggleFirstTab("3");
                        }}
                        to="#"
                      >
                        <p style={{fontSize:"15px"}}>Updates</p>
                      </NavLink>
                    </NavItem>
				</div>
                  </Nav>
				  
				  
				  
				    <TabContent activeTab={this.state.activeFirstTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
							<Requests {...this.props} />
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
				  
                  <TabPane tabId="2">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
						 <Public {...this.props} />
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
				  
				  <TabPane tabId="3">
                    <Row>
                      <Colxx sm="12">
                        <CardBody>
						<Updates {...this.props} />
                        </CardBody>
                      </Colxx>
                    </Row>
                  </TabPane>
				  
                </TabContent>
				   
				  
				  
				  
				  
                </CardHeader>
				</Card>
				</Colxx>
		
		
		</Card>
		
		
      </Fragment>
    );
  }
}
