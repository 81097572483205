import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  Card
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectStatus = [
    { label: "Active", value: "1", key: 0 },
    { label: "Inactive", value: "0", key: 1 }
];

class EditTableViewModal  extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			room_id: null,
			room_no: '',
			readOnly: true,
			rooms:[],
			room_structures:[],
			selectShelfTypes:[],
			selectTable:[],	
		};	
    }

    componentDidMount() {	
        var rooms=[];	
		var available=[];
		var table_types=[];
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'growhouse/get-data-for-add-table',formData).then((res)=>{
			var data = res.data;
		    rooms=data.rooms;
			available=data.available;			
			table_types=data.type_of_tables;

            rooms = rooms.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});				
			
			table_types = table_types.map((element,index)=>{
				element.label=element.table_name;
				element.value=element.id;
				element.key=index;
				return element;
			});
		
			this.setState({
				room_structures:available,
				rooms,
				selectTable:table_types,
			});
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	 componentDidUpdate(prevProps, prevState, snapshot){
		
        if(this.props.modalOpen){
			let table;
			
            if (prevProps.table_id !== this.props.table_id) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('table_id',this.props.table_id);
				formData.append('facility_id',facility_id);
	
				axios.post(REST_API_END_POINT+'growhouse/get-table-by-id',formData).then((res)=>{
					var data = res.data;
					table = data.table;
					
				    var room_id=null;
				    var type=null;
				 
				    this.state.rooms.forEach((element,index) => { 
						if(element.value==table.room_id){
							room_id = element.value;
						}
					});
					
					this.state.selectTable.forEach((element,index) => { 
						if(element.value==table.table_capacity_id){
							type = element.value;
						}
					});
					
					var statusType = selectStatus.find(r=>{ return r.value==table.status })
					
					this.setState({
						table,
						room_id,
						type,
						statusType						
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}
		}
	}
	
	
	  handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-room").disabled=true;
			
			
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("roomForm"));
			formData.append('table_id',this.props.table_id);
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);

			axios.post(REST_API_END_POINT+'growhouse/edit-table',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-room").disabled=false;
				this.props.toggleModal();				
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-room").disabled=false;
			});

		}
	}
		
    handleClick = (room_no) => {
        this.setState({
			room_no
        });
    };
	
    render() {	
	
		const {  selectTable,selectRoom,rooms } = this.state;
	
		if(this.state.table!=null){
			var table=this.state.table;
		}else{
			var table;
		}        
	  
		var room_structures= this.state.room_structures;
				
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Table
		  </ModalHeader>
		  <AvForm
		         id="roomForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							value={table?((table.name!=null)?table.name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z0-9 ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				
				<Row>
					<Colxx lg="12" className="mb-4">
						<Label >Room<span className="text-danger">*</span></Label>
						<AvField
							type="select"
							name="room_id"
							id="room_id"
							value={this.state.room_id}
							placeholder="--Select Room--"
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								}
							}}
						>
						<option value="" >--Select Room--</option>
						{rooms.map(opt=>{
							return <option key={opt.key} value={opt.value} >{opt.label}</option>
						})}
						</AvField>
					</Colxx>				
				</Row>

				<Row>
					<Colxx lg="12" className="mb-4">
						<Label >Type of Table<span className="text-danger">*</span></Label>
						<AvField
							type="select"
							name="table_capacity_id"
							id="table_capacity_id"
							placeholder="--Select Table Type--"
							value={this.state.type}
							validate={{
								required: {
									value: true,
									errorMessage: "This field is required."
								}
							}}
						>
						<option value="" >--Select Table Type--</option>
						{selectTable.map(opt=>{
							return <option key={opt.key} value={opt.value} >{opt.label}</option>
						})}
						</AvField>
					</Colxx>				
				</Row>
			
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Table No.<span className="text-danger">*</span></Label>
						  <AvField
							id="position"
							name="position"
							type="number"
							value={table?((table.position!=null)?table.position:''):'' }
                            readOnly={true}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please enter numeric value"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<hr/>
				   <Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Status<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="status"
								id="status"
								value={this.state.statusType}						
								options={selectStatus}
								onChange={statusType => { this.setState({ statusType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>	
				<h5>Choose any one of Available Room</h5>
				{room_structures.map((row,index)=>{
					return (<span key={index} className={"w-40px p-2 m-1 border d-inline-block text-center cursor-pointer "+(this.state.room_no==row ? "room-active":"")} onClick={()=>{this.handleClick(row)}} >
							{row}
							</span>)
				})}					
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-room" >
			  <IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditTableViewModal;
