import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
//import Paginator from 'react-hooks-paginator';
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";

import {REST_API_END_POINT,dataEncryptKey, BASE_URL } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import MailChimpIntegration from "../components/front-end/MailChimpIntegration";
import { seo } from "../helpers/Utils";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class About extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		  template_data:null
		};
		this.dataListRender = this.dataListRender.bind(this);
		seo({
			title: 'About | '+projectTitle,
			metaDescription: 'Know our story'
		});
	}
  
	componentDidMount() {
		
		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id){
			this.dataListRender();
		}else{
			window.location='/';
		}
	}
	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var data_upload = new FormData();
		data_upload.append('facility_id',facility_id);
		data_upload.append('template_id','about');
		axios.post(REST_API_END_POINT+'website/get-page-template-by-alias',data_upload).then((res)=>{ 
			if(res.data.status==1){
				this.setState({ template_data: res.data.data, blocking:false });				
			}else{
				this.setState({ blocking:false });					
			}			
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e)
			this.setState({ blocking:false });
		});
	}

	render() {
		
		const { template_data } = this.state;
		var content_text='';
		var banner_image='';
		var page_name='';
		var page_sub_title='';
		if(template_data!=null){
			let content_text_row = (template_data.content.find(r=>{ return r.key=='content_text' }));
			if(content_text_row!=null){
				content_text = content_text_row.value;
			}
			let banner_image_row = (template_data.content.find(r=>{ return r.key=='banner_image' }));
			if(banner_image_row!=null){
				banner_image = banner_image_row.value;
			}
			page_name=template_data.name;
			page_sub_title=template_data.sub_title;
		}
		return (
			<Fragment>
				<ToastProvider placement="bottom-left">
					<div className="main-pages-layout">
						<main>
							<HomeHeader />								    
									{banner_image!='' ? <div className="front-page-banner" style={{backgroundImage:`url(${BASE_URL}pages/${banner_image})`}}>
										<div className="front-page-header-wrapper d-flex flex-wrap-wrap align-items-center">
											<div className="front-page-header-text">
												<h1>{page_name}</h1>
												{page_sub_title!='' ? <h3 className="h3">{page_sub_title}</h3>:""}
											</div>
										</div>
									</div>:""}
									
									<div className="row h-50 p-l-30">
										<div className="col-lg-12">
											<div className="dynamic-page-content" dangerouslySetInnerHTML={{ __html: content_text }} ></div>
										</div>
									</div>
									
									{/*discount ends */}
										<MailChimpIntegration />
									{/*discount ends */}
							<HomeFooter />
						</main>	
					</div>
				</ToastProvider>
			</Fragment>	
		);
	}
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(About);
