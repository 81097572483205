import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import moment from 'moment';
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "../../components/common/CustomDateTimePicker";
import TimePicker from "react-datepicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
 

class EditListModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			time_entry:null,
			time_slot:null,
			time_slot_end:null,
			
		};
	}
	
	handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	
	handleChangeTime = time => {
		this.setState({ time_slot: time });
	};
	handleChangeTimeend = time => {
		this.setState({ time_slot_end: time });
	};

	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let time_entry; 					
            if (prevProps.timeId!== this.props.timeId && this.props.timeId!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			
				var formData = new FormData();
				formData.append('timeId',this.props.timeId);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'staff/get-time-entry-by-id',formData).then((res)=>{
					
					var data = res.data;
				    time_entry=data.time_entry;
					var time_slot = moment(time_entry.entryDate+' '+time_entry.startTime); 
					var time_slot_end = moment(time_entry.entryDate+' '+time_entry.endTime); 
					this.setState({ 
						time_entry,
						time_slot,
						time_slot_end
					});									
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    } 
	
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-time").disabled=true;
			let errorMessage='';

			if(this.state.time_slot==null){
				errorMessage+="Time in is empty\n";
			}else if(this.state.time_slot_end==null){
				errorMessage+="Time out is empty\n";
			}else if(moment(this.state.time_entry.entryDate+' '+this.state.time_slot.format('HH:mm:ss')).unix()>moment(this.state.time_entry.entryDate+' '+this.state.time_slot_end.format('HH:mm:ss')).unix()){
				errorMessage+="Time out should be greater than Time in\n"; 
			}

			if(errorMessage.length!=0)
			{
				swal("Warning",errorMessage,"warning");
				document.getElementById("update-time").disabled=false;
				return false;
			}
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("timeForm"));
			formData.append('timeId',this.props.timeId);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

			axios.post(REST_API_END_POINT+'staff/edit-time-entry',formData).then((res)=>{
				var data = res.data;
							
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-time").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-time").disabled=false;
			});

		}
	} 
  



  render() {

	if(this.state.time_entry!=null){
			var time_entry=this.state.time_entry;
		}else{
			var time_entry;
		}  
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Manual Time Entry
			</ModalHeader>
			<AvForm
				id="timeForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<Label>Reason<span className="text-danger">*</span></Label>
							<AvGroup className="error-t-negative">
							  <AvField
								id="reason"
								name="reason"
								type="textarea"
								autoComplete="off"
								value={ time_entry?((time_entry.clock_out_reason!=null)?time_entry.clock_out_reason:''):'' }
								placeholder=""
								rows="5"
								validate={{
									  required: {
										value: true, 
										errorMessage: "This field is required."
									  },
									  minLength: {
										value: 10,
										errorMessage: "Your message must have minimum of 10 characters"
									  },
									}}
							  />
							</AvGroup>					
						</Colxx>
						
					</Row>	
										
					<Row>
						<Colxx lg="12">
							
							  <Label>Time In<span className="text-danger">*</span></Label>
							  <TimePicker
								className="mb-2"
								placeholderText=""
								autoComplete="off"
								name="time_in"
								selected={this.state.time_slot}
								onChange={this.handleChangeTime}
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={30}
								dateFormat="HH:mm a"
                                timeCaption="Time"
                                popperPlacement="bottom"
								popperModifiers={{
									flip: {
										behavior: ["bottom"] // don't allow it to flip to be above
									},
									preventOverflow: {
										enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
									},
									hide: {
										enabled: true // turn off since needs preventOverflow to be enabled
									}
								}}
							/>
							
							
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							
							  <Label>Time Out<span className="text-danger">*</span></Label>
							  <TimePicker
								className="mb-2"
								placeholderText=""
								autoComplete="off"
								name="time_out"
								selected={this.state.time_slot_end}
								onChange={this.handleChangeTimeend}
								showTimeSelect
								showTimeSelectOnly
								timeIntervals={30}
								dateFormat="HH:mm a"
                                timeCaption="Time"
                                popperPlacement="bottom"
								popperModifiers={{
									flip: {
										behavior: ["bottom"] // don't allow it to flip to be above
									},
									preventOverflow: {
										enabled: true // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
									},
									hide: {
										enabled: true // turn off since needs preventOverflow to be enabled
									}
								}}
							/>
							
							
						</Colxx>					
					</Row>
					
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-time" >
						<IntlMessages id="customer.update" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default EditListModal;
