import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';
import { getCookie, setCookie } from "../../helpers/Utils";

class AddBogoOfferModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state={
			bogo_code:''
		}
	}
	
	componentDidMount(){
		let bogo_key = typeof this.props.bogo_key=='undefined' ? 'bogo_code':this.props.bogo_key
		var bogo_code = getCookie(bogo_key,'string');
		this.setState({ bogo_code });
	}
		
	handleSubmit(event, errors, values) {	
		if(errors.length === 0){
			let bogos = this.props.bogos;
			let current_time = moment().unix();
			let is_valid = bogos.find(r=>{ 
				let start_date = moment(r.start_date+" 00:00:00").unix(); 
				let end_date = moment(r.end_date+" 23:59:59").unix(); 
				return r.code==values.bogo_code && start_date<=current_time && current_time<=end_date
			});
			if(is_valid){
				let bogo_key = typeof this.props.bogo_key=='undefined' ? 'bogo_code':this.props.bogo_key
				setCookie(bogo_key,values.bogo_code);
				swal("Success!","Bogo has been applied successfully","success").then(()=>{
					this.props.toggleModal();
				});
			}else{
				swal("Warning!","This code is not applicable now","warning");
			}
		}
	}
	
	render() {	
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Bogo Offer Code
				</ModalHeader>
				<AvForm
					id="applyBogoOfferForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Code<span className="text-danger">*</span></Label>
									<AvField
										id="bogo_code"
										name="bogo_code"
										type="text"
										autoComplete="off"
										value={this.state.bogo_code}
										onChange={e=>{ this.setState({ bogo_code:e.target.value }); }}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter bogo code"
											}
										}}
									/>
								</AvGroup>
							</Colxx>				
						</Row>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" >
							Apply
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default AddBogoOfferModal;
