import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input,  DropdownItem,CustomInput, Badge,  DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddNewDriverModal from "../../../containers/transportation/AddNewDriverModal";
import EditDriverModal from "../../../containers/transportation/EditDriverModal";
import ReactTable from "react-table";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Drivers extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			modalOpenAdd: false,
			modalOpenEdit: false,
			drivers:[],
			driver_id:0,
			search: '',
			user_privileges:[]
		};
		this.deleteItem = this.deleteItem.bind(this);
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,driver_id=0) => {
		this.setState({
			driver_id: parseInt(driver_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.drivers.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
  
   	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
		var msg_word="driver";
		
		if(selectedIds.length>1){
			msg_word="drivers";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-drivers").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					var formData = new FormData();
					formData.append('driver_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'transportation/delete-drivers',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-drivers").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-drivers").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	} 
  
    activeItem=(e,driver_ids)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(driver_ids);
			
		}else{
		  var x=selectedIds.indexOf(driver_ids);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.drivers.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
  
  
	dataListRender(){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'transportation/get-all-drivers',formData).then((res)=>{
			var data = res.data;
		
			if(data.drivers){
				this.setState({
					drivers: data.drivers,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					drivers: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
  
	render() {
		let data = this.state.drivers;
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search) || row.name.toLowerCase().includes(this.state.search) || row.blood_group.toLowerCase().includes(this.state.search) || row.address.toLowerCase().includes(this.state.search) || row.phone.toLowerCase().includes(this.state.search)  || row.email_id.toLowerCase().includes(this.state.search) 
			})
		}
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"driver_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "ID",
				accessor: "barcode",
				width:110,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Name",
				accessor: "name",
				width:140,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			/*{
				Header: "Gender",
				accessor: "gender",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Date Of Birth",
				accessor: "date_of_birth",
				Cell: props => <p className="text-muted">{props.value}</p>
			},*/
			{
				Header: "Blood Group",
				accessor: "blood_group",
				width:100,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Address",
				accessor: "address",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Phone",
				accessor: "phone",
				width:110,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Email",
				accessor: "email_id",
				width:180,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Available Status",
				accessor: "available_status",
				width:120,
				Cell: props => {
					let available_status="Inactive";
					
					if(props.value==1){
						available_status="Available";
					}else if(props.value==2){
						available_status="Break";
					}else if(props.value==3){
						available_status="In Transit";
					}
					return(<p className="">{available_status}</p>)
				}
			},
			{
				Header: "Options",
				accessor: "id",
				width:70,
				sortable: false,
				Cell: props => this.state.user_privileges.indexOf(105)!==-1 ? <Button color="primary" size="xs" className="mb-2"  onClick={(e)=>this.toggleModalEdit(e,props.value)} >Edit</Button>:""
			},
		];
		
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.drivers" match={this.props.match} />
					<Separator className="mb-5" />
				</Colxx>
			</Row>
			
			<Card className="p-4">
				<Row>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
					<Colxx xxs="12">
						<div className="float-sm-right">
							{" "}{this.state.user_privileges.indexOf(104)!==-1 ? <Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={this.toggleModalAdd}
								>
								Add New Driver							  
							</Button>:""}																	
							{" "}{this.state.user_privileges.indexOf(106)!==-1 ? <Button
								id="delete-drivers"
								color="primary"
								size="sm"
								className=""	
								onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
							  >
								<IntlMessages id="pages.delete" />
							</Button>:""}
						</div>
						<div className="mb-4 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
									/>
								</Colxx>
							</FormGroup>			
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>					
					</Colxx>
					
					{this.state.user_privileges.indexOf(104)!==-1 ? <AddNewDriverModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender={this.dataListRender}
					/>:""}
					{this.state.user_privileges.indexOf(105)!==-1 ? <EditDriverModal
						modalOpen={this.state.modalOpenEdit}
						toggleModal={this.toggleModalEdit}
						dataListRender={this.dataListRender}
						driver_id={this.state.driver_id}
					/>:""}
				</Row>		
			</Card>
		</Fragment>
    );
  }
}
