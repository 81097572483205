import React, { Component } from "react";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import PrintPackageUIDContent from "./PrintPackageUIDContent";
import ReactToPrint from 'react-to-print';

export default class PrintPackageUIDModal extends Component{
	constructor(props) {
		super(props);
	}

	render() {	
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="md"
			  backdrop="static"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Print Label
				</ModalHeader>
			 
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<PrintPackageUIDContent
								ref={el => (this.componentRef = el)} 
								package_details={this.props.package_details}
							/>
						</Colxx>					
					</Row>
				</ModalBody>
				<ModalFooter className="justify-content-start">
					<ReactToPrint
						trigger={() => <Button color="primary" ><IntlMessages id="customer.print" /></Button>}
						content={() => this.componentRef}
						copyStyles={true}
						pageStyle={""}
					/>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
	  );
  }
};
