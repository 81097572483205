import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Label,
  Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';

import { zeroPad } from "../../helpers/Utils";
import number_format from "number_format-php";

export default class Receipt extends Component{
		
    render() {
		const { order_data, order_products, facility_data, currencies } = this.props;
		var current_currency_symbol = '$';
		var sales_tax=0;
		var sub_total=0;
		var p_discount=0;
		if(order_data!=null){
			var current_currency = currencies.find(row=>{ return row.currency_code==order_data.currency_code });			
			if(current_currency!=null){
				current_currency_symbol = current_currency.currency_symbol;
			}
		}
		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 80mm 4.6in !important;
						  margin:0;
						}
						.table{
							font-size:12px;
						}
						.table th, .table td {
							padding: 0.4rem .5rem;
						}
					}
				`}} />
				{order_data!=null ? <div className="print_pos_label" style={{padding:'2px 10px 2px 3px',width:'80mm'}} >                  
					<div style={{width:'80mm', fontSize:'13px', fontFamily:"Arial, 'Times New Roman'"}} className="panel-body">
						<div className="row_data" style={{textAlign:'center', fontWeight: '700', fontSize:'13px'}} >
							<u>{facility_data['facility_name']}</u> 
						</div>
						
						<div className="row_data text-center" >Disp ID: {facility_data['license_number']}</div>						
						<div className="row_data text-center" >Phone: {facility_data['facility_phone']}</div>
						<div className="row_data text-center" >Email: {facility_data['facility_email']}</div>
						
						<div className="row_data" style={{marginTop:'10px'}}>
							<div className="float-right">Bill #: {zeroPad(order_data.order_number,6)}</div>
						</div>
						<div className="row_data">
							<div className="float-right">Bill Date: {moment(order_data.timestamp*1000).format("MM/DD/YYYY")}</div>
						</div>			
						<div className="row_data">
							<div className="float-right">Patient Name: {order_data.vendor_name}</div>
						</div>
						<Table className="table table-striped table-hover mt-4 w-100">                              
							<thead>                              
								<tr>                                  
									<th>Item</th>
									<th className="text-right">U.Price</th>
									<th className="text-right">QTY</th>
									<th className="text-right">Tax</th>
									<th className="text-right">Total</th>                              
								</tr>                              
							</thead>                              
							<tbody>
								{order_products.map((row,index)=>{
									sales_tax+=parseFloat(row.product_sales_tax);
									p_discount+=parseFloat(row.product_discount)+parseFloat(row.coupon_discount);
									
									let line_total=parseFloat(row.product_unit_price)*parseFloat(row.product_qty)+(parseFloat(row.product_excise_tax)+parseFloat(row.product_sales_tax)-parseFloat(row.product_discount)-parseFloat(row.coupon_discount))
									
									if(row.variation_id==order_data.discount_variation_id){
										line_total-=parseFloat(order_data.manual_discount);
									}
									sub_total+=parseFloat(row.product_unit_price)*parseFloat(row.product_qty)+(parseFloat(row.product_excise_tax)+parseFloat(row.product_sales_tax));
									return(<Fragment key={index}><tr >
									<td colSpan="5" >{row.product_name} {row.variation_name!=null ? "("+row.variation_name+")":""}</td>
									</tr>
								<tr >
									<td></td>
									<td className="text-right">{current_currency_symbol+""+number_format(parseFloat(row.product_unit_price),2,'.','')}</td>
									<td className="text-right">{number_format(parseFloat(row.product_qty),2,'.','')}</td>
									<td className="text-right">{current_currency_symbol+""+number_format(parseFloat(row.product_sales_tax),2,'.','')}</td>
									<td className="text-right">{current_currency_symbol+""+number_format(line_total,2,'.','')}</td>
								</tr></Fragment>)
								})}
							</tbody>                          
						</Table>
						<div className="row">                              
							<div className="col-lg-8 invoice-block float-right">
								<Table borderless className="d-flex justify-content-end">
									<tbody>
										<tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol+""+number_format(sub_total,2,'.','')}</td>
										</tr>
										<tr>
											<td className="pb-0">Tax :</td>
											<td className="text-right pb-0">{current_currency_symbol+""+number_format(sales_tax,2,'.','')}</td>
										</tr>
										<tr>
											<td className="pb-0">Discount :</td>
											<td className="text-right pb-0">{current_currency_symbol+""+number_format(p_discount+parseFloat(order_data.auto_apply_discount)+parseFloat(order_data.coupon_discount)+parseFloat(order_data.manual_discount)+parseFloat(order_data.point_discount),2,'.','')}</td>
										</tr>
										<tr className="font-weight-bold">
											<td className="pb-0">Grand Total :</td>
											<td className="text-right pb-0">{current_currency_symbol+""+number_format(parseFloat(order_data.total)-parseFloat(p_discount),2,'.','')}</td>
										</tr>
									</tbody>
								</Table>                              
							</div>                          
						</div>
						<div className="row_data" style={{paddingLeft:'5px',paddingRight:'5px',fontSize:'10px'}}>
							OKLAHOMA DEPARTMENT OF HEALTH SERVICES' WARNING: 
							Marijuana use can be addictive and can impair an individual's ability to drive a motor vehicle or 
							operate heavy machinery. Marijuana smoke contains carcinogens and can lead to an increased risk for cancel;
							tachycardia, hypertension, heart attack, and lung infection. 
							KEEP OUT OF REACH OF CHILDREN. FOR MEDICAL USE ONLY 
						</div>
					</div>
				</div>:""}
			</div>
		);
  }
};
