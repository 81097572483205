import React, { Component, Fragment } from "react";
import { Row, FormGroup,Input,
    DropdownItem,
    DropdownMenu,CustomInput,Button } from "reactstrap";

import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import moment from "moment"; 

export default class Payments extends Component {
	constructor(props) {
		super(props);
		
	}
	  
  render() {
	let data = this.props.payments;
	  		
	const dataTableColumns = [
		{
			Header: "Invoice #",
			accessor: "invoice_no",
			Cell: props => props.value
		},
		{
			Header: "Plan",
			accessor: "subscription_name",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Date",
			accessor: "timestamp",
			Cell: props => moment(props.value*1000).format("MM/DD/YYYY")
		},
		{
			Header: "Amount",
			accessor: "amount",
			Cell: props => "$"+props.value
		},		
		{
			Header: "Transaction ID",
			accessor: "transaction_id",
			Cell: props => props.value
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: props =>{ if(parseInt(props.value)==1){ return "Success" }else{ return "Declined" } }
		},
		{
			Header: "Gateway",
			accessor: "payment_gateway",
			Cell: props => props.value
		}
	];
	
	
    return (
		<Fragment>		
			<Row>			  
				<Colxx xxs="12">
					<div className="mb-4 dropdown-edit">					
						<ReactTable
							data={data}
							columns={dataTableColumns}
							defaultPageSize={10}
							filterable={false}
							showPageJump={false}
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "id",asc: true}]}
						/>
					</div>
				</Colxx>
			</Row>
		</Fragment>
    );
  }
}
