import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, DropdownItem, CustomInput, Badge } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import PrintAccount from "../../../containers/accounts/PrintAccount";
import ReactToPrint from 'react-to-print';
import ReactTable from "react-table";
import moment from 'moment';
import ReactExport from "react-export-excel";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class ViewAccount extends Component {
	
	constructor(props) {
		super(props);
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			data:[],
			exportData:[],
			search: '',
			account: null,
			total: 0.00,
		};
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('account_id',this.props.match.params.account_id);
		
		axios.post(REST_API_END_POINT+'account/view-account',formData).then((res)=>{
			var data = res.data;
			
			var total = 0;
			
			if(data.orders){
				for(let order of data.orders){
					total+=parseFloat(order.account_total)
				}
				
				this.setState({
					data: data.orders,
					account:data.account,
					total
				});
			}else{
				this.setState({
					data: [],
					account:null,
					total
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	exportToSpreadsheet = () => {
		var data = this.state.data;
		var re = new RegExp(String.fromCharCode(160), "g");
		var template_export = [];
		
		for(let d of data){
			template_export.push([d.order_number, d.customer_name, d.account_total, d.added_by, moment(d.added_on).format('MM/DD/YYYY HH:mm:ss')])
		}
		
		var exportData = [{
			columns: [
				"Order ID", 
				"Customer Name", 
				"Amount Received", 
				"Added By",
				"Added On"
			],
			data: template_export
		}];
		
		this.setState({
			exportData: exportData
		});
		setTimeout(function(){
			document.getElementById('export_template').querySelector("button").click();
		}, 500);
		
	};
	
  render() {
		let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.order_id.includes(this.state.search) || row.customer_name.includes(this.state.search) || row.account_total.includes(this.state.search)
				|| row.added_by.toLowerCase().includes(this.state.search) || row.added_on.toLowerCase().includes(this.state.search) 
			})
		}
		
	const dataTableColumns = [
		{
			Header: "Order ID",
			accessor: "order_number",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		},
		{
			Header: "Customer Name",
			accessor: "customer_name",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
		{
			Header: "Amount Received",
			accessor: "account_total",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
		{
			Header: "Added By",
			accessor: "added_by",
			Cell: props => <p className="text-muted">{props.value}</p>
		},
		{
			Header: "Added On",
			accessor: "added_on",
			Cell: props => <p className="list-item-heading">{moment(props.value).format('MM/DD/YYYY HH:mm:ss')}</p>
		},
	  
	];
	
    return (
      <Fragment>
        <Row>
			<Colxx xxs="12">
				<Breadcrumb heading="menu.view-account" match={this.props.match} />
				<Separator className="mb-5" />
			</Colxx>
        </Row>
					
		<Card className="p-4">
			<Row className="mb-5">
				<Colxx xxs="12">
					<h3 className="font-weight-bold">Account: {this.state.account!=null ? this.state.account.name:""}</h3> 
				</Colxx>
				<Colxx xxs="12">
					<h2>Total Payment Received: ${this.state.total.toFixed(2)}</h2> 
                </Colxx>					
			</Row>
			
			<Row>			  
			  <Colxx xxs="12">
					<div className="float-sm-right">
						{" "}<Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.exportToSpreadsheet}
							>
							Excel							  
						</Button>{" "}<ReactToPrint
								trigger={() => <Button color="primary" size="sm" className="" >Print</Button>}
								content={() => this.componentRef}
								copyStyles={true}
								pageStyle={""}
							/>
						
					</div>
					 
					<div className="mb-4 dropdown-edit">						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
						  </Colxx>
						</FormGroup>
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  minRows = {2}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: false}]}
						/>
					</div>
					<div className="d-none">
						<PrintAccount ref={el => (this.componentRef = el)} orders={this.state.data} account={this.state.account} />
					</div>
			  </Colxx>
			</Row>
			
			<Row className="d-none" id="export_template">
				<ExcelFile filename={"Account-"+moment().format('MM-DD-YYYY')}>
					<ExcelSheet dataSet={this.state.exportData} name="Account" />
				</ExcelFile>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
