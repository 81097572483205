const questionnairetemplates = [
{
    id: 1,
    name: "Ajay", 
	vendor: "aj",   
	team: "boys", 
stage:1,	
	status: {
      statusid: 2,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Joel ", 
	vendor: "aj",     
	team: "boys", 
	stage:2,
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 3,
    name: "Business Impact Analysis Questionnaire",
	vendor: "Emerald City",  
	team: "05/07/2019 05:20:39", 
	
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default questionnairetemplates
