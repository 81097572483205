import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import moment from "moment";
import {	
  CustomInput,
  Button,
  Label,FormGroup,
  Card, Table, 
  Input, Badge,
  DropdownMenu, DropdownItem,
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT, LITER_CONSTANT, INVENTORY_SOURCES} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import ReactToPrint from 'react-to-print';

import AddPackageModal from "./Packages/AddPackageModal";
import PrintPackageBarcodes from "./Packages/PrintPackageBarcodes";
import AdjustInventoryModal from "./Packages/AdjustInventoryModal";
import MoveToWarehouseModal from "./Packages/MoveToWarehouseModal";
import ViewPackageDetailModal from "./Packages/ViewPackageDetailModal";
import MoveToWasteModal from "./Packages/MoveToWasteModal";


var dataCrypto = new SimpleCrypto(dataEncryptKey);

const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

export default class AllPackages extends Component {
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		
		this.state = {
			selectedIds: [],
			selectAll: 0,
			modalOpenAddPackage: false,
			modalOpenViewPackageDetail: false,
			modalOpenAdjustInventory: false,
			modalOpenMoveToWarehouse: false,
			modalOpenMoveToWaste: false,
			product_package:null,
			search:"",
			print_order_class:"d-block",
			print_packages:[],
			product_package_id:0
		};

		this.toggleModalAddPackage = this.toggleModalAddPackage.bind(this);
		this.toggleModalAdjustInventory = this.toggleModalAdjustInventory.bind(this);
		this.toggleModalMoveToWarehouse = this.toggleModalMoveToWarehouse.bind(this);
		this.toggleModalMoveToWaste = this.toggleModalMoveToWaste.bind(this);
		
		this.combinePackages = this.combinePackages.bind(this);
		this.printBarcodes = this.printBarcodes.bind(this);
		this.activeItem = this.activeItem.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
	}
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.props.product_packages.forEach(x => {
                newSelected.push(x.package_id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }
	
	activeItem=(e,package_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(package_id);			
		}else{
			var x=selectedIds.indexOf(package_id);
			if(x>=0){
				selectedIds.splice(x,1);
			}
		}
		if(selectedIds.length!=0 && selectedIds.length==this.props.product_packages.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	toggleModalAddPackage = () => {
		this.setState({
		  modalOpenAddPackage: !this.state.modalOpenAddPackage
		});
	};
	
	toggleModalViewPackageDetail = (e,product_package_id=0) => {
		this.setState({
			product_package_id: product_package_id,
			modalOpenViewPackageDetail: !this.state.modalOpenViewPackageDetail,
		});
	};
	
	toggleModalAdjustInventory = (e,product_package=null) => {
		this.setState({
			product_package: product_package,
			modalOpenAdjustInventory: !this.state.modalOpenAdjustInventory,
		});
	};
	
	toggleModalMoveToWarehouse = (e,product_package=null) => {
		this.setState({
			product_package: product_package,
			modalOpenMoveToWarehouse: !this.state.modalOpenMoveToWarehouse,
		});
	};
	
	toggleModalMoveToWaste = (e,product_package=null) => {
		this.setState({
			product_package: product_package,
			modalOpenMoveToWaste: !this.state.modalOpenMoveToWaste,
		});
	};
	
	combinePackages = ()  => {
		var selectedIds = this.state.selectedIds;        
		if(selectedIds.length<2){
			swal("Warning","Please select at least two stocks","warning");
			return;
		}
		
        swal({
          title: "Are you sure?",
          text: "Do you want to combine these stocks",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("combine-product-packages").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
			
					var formData = new FormData();
					formData.append('product_id',this.props.product.id);
					formData.append('facility_id',facility_id);
					formData.append('package_ids',selectedIds.join(','));
					formData.append('user_id',user_id);
					
					axios.post(REST_API_END_POINT+'inventory/combine-product-packages',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("combine-product-packages").disabled=false;
						
						var api_message=document.getElementById("api_message_packages");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.props.dataListRender();
						this.setState({ selectedIds:[] });
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("combine-product-packages").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
	printBarcodes = (e,package_ids=null)  => {
		var selectedIds = package_ids!=null ? package_ids:this.state.selectedIds;        
		if(selectedIds.length==0){
			swal("Warning","Please select at least one stock","warning");
			return;
		}else{
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('package_ids',selectedIds.join(','));
			
			axios.post(REST_API_END_POINT+'inventory/get-print-barcode-packages',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var print_packages = data.packages;
					print_packages = print_packages.map(row=>{
						row.quantity=row.product_qty;
						return row;
					})
										
					this.setState({ print_packages });
					setTimeout(function(){ document.getElementById('print-finished-barcodes-hidden').click() }, 1000)
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
  
  
	render() {
		var data = [];
		if(typeof this.props.product_packages!='undefined'){
			data = JSON.parse(JSON.stringify(this.props.product_packages));
			data = data.map(row=>{
				if(row.qty_type==1){
					row.qty_type='Grams';
					row.quantity= row.quantity;
					row.remainig_to_fill = row.remainig_to_fill;
				}else if(row.qty_type==2){
					row.qty_type='Kilograms';
					row.quantity= (row.quantity/LBS_CONSTANT).toFixed(2);
					row.remainig_to_fill =  parseFloat(row.remainig_to_fill/LBS_CONSTANT).toFixed(2);
				}else if(row.qty_type==4){
					row.qty_type='Litres';
					row.quantity= (row.quantity/LITER_CONSTANT).toFixed(2);
					row.remainig_to_fill =  parseFloat(row.remainig_to_fill/LITER_CONSTANT).toFixed(2);
				}else{
					row.qty_type='Units';
					row.quantity= Math.round(row.quantity);
					row.remainig_to_fill = Math.round(row.remainig_to_fill);
				}
				if(row.batch_uid==null){
					row.batch_uid="";
				}
				row.moved_to_warehouse = parseFloat(row.quantity-row.remainig_to_fill).toFixed(2);
				return row;
			});
		}
		
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search) || row.batch_uid.toLowerCase().includes(this.state.search) || INVENTORY_SOURCES[row.source].toLowerCase().includes(this.state.search)
			})
		}
	  
		const dataTableColumns = [			  
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "package_id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					if(rowInfo.original.status==1){
						return ( <FormGroup>
							<CustomInput type="checkbox" id={"package_id_"+rowInfo.original.package_id} onChange={event =>this.activeItem(event,rowInfo.original.package_id) } checked={this.state.selectedIds.includes(rowInfo.original.package_id)} />
							</FormGroup> );
					}else{
						return "";
					}
				}
			},
			{
				Header: "Barcode",
				accessor: "barcode",
				width:170,
				Cell: props => <span onClick={e=>{ this.toggleModalViewPackageDetail(e,props.original.package_id) }}><span className="btn-link cursor-pointer">{(props.original.source>=3 && props.original.source<=5) ? props.original.batch_uid:props.original.barcode}</span> {moment(parseInt(props.original.timestamp)*1000).format('YYYY-MM-DD')===moment().format('YYYY-MM-DD') ? <Badge color="success">New</Badge>:""} {props.original.status==3 ? <Badge color="warning">On Hold</Badge>:""}</span>
			},
			{
				Header: "Available",
				accessor: "quantity",
				Cell: props => <p className="list-item-heading">{props.value+' '+props.original.qty_type}</p>
			},
			/*{
				Header: "Moved to Warehouse",
				accessor: "moved_to_warehouse",
				Cell: props => <p className="list-item-heading">{props.original.moved_to_warehouse+' '+props.original.qty_type}</p>
			},*/
			{
				Header: "Source",
				accessor: "source",
				Cell: props => <p className="list-item-heading">{INVENTORY_SOURCES[props.value]}</p>
			},
			{
				Header: "Batch UID",
				accessor: "batch_uid",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Filed Date",
				accessor: "timestamp",
				Cell: props => <p className="list-item-heading">{moment(parseInt(props.value)*1000).format('MM-DD-YYYY')}</p>
			},
			{
				Header: "Time In Room",
				accessor: "time_in_room",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Options",
				accessor: "package_id",
				width:70,
				sortable: false,
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
									<DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalViewPackageDetail(e,props.value) }} >
										<span className="d-inline-block" >View Detail</span>				
									</DropdownItem>
									{props.original.status==1 ? <Fragment>
										<DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalAdjustInventory(e,props.original) }} >
											<span className="d-inline-block" >Adjust Inventory</span>				
										</DropdownItem>
										{/*props.original.remainig_to_fill>0 ? <DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalMoveToWarehouse(e,props.original) }} >
											<span className="d-inline-block" >Move to Warehouse</span>
										</DropdownItem>:""*/}	
										<DropdownItem className="cursor-pointer" onClick={e=>{ this.toggleModalMoveToWaste(e,props.original) }} >
											<span className="d-inline-block" >Move to Waste</span>
										</DropdownItem>
									</Fragment>:""}
								</DropdownMenu>}
							/>
						</div>
					</Fragment>
			}
	];	
    return( 
		<Fragment>
			<div id="api_message_packages"></div>
			<div className="float-sm-right">
				{" "}<Button
					  color="primary"
					  size="sm"
					  className=""
					  onClick={this.toggleModalAddPackage}
					>
					Add Stock
				</Button>
				{" "}<Button
					color="primary"
					size="sm"
					id="combine-product-packages"
					className=""
					onClick={this.combinePackages}
				  >
					Combine Stocks
				</Button>
				{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.toggleSelectAll}
				  >
					Select All
				</Button>{" "}<Button
					color="primary"
					size="sm"
					className=""
					onClick={this.printBarcodes}
				  >					
					{this.state.selectedIds.length ? <ReactToPrint
						trigger={() => <span className="d-block cursor-pointer" id="print-package-barcodes-hidden" >Print Barcodes</span>}
						content={() => this.componentRef}
						copyStyles={true}
						pageStyle={""}
					/>:"Print Barcodes"}
				</Button>
			</div>
			<div className="mb-4 dropdown-edit small-padd-table">	
				<FormGroup row>
					<Colxx lg="6">
						<Input
							type="text"
							name="package_table_search"
							id="package_table_search"
							placeholder="Search"
							value={this.state.search}
							onChange={e => this.setState({search: e.target.value.toLowerCase()})}
						/>
					</Colxx>
				</FormGroup>
				<ReactTable
					data={data}
					TbodyComponent={CustomTbodyComponent}
					columns={dataTableColumns}
					defaultPageSize={10}
					minRows = {2}
					filterable={false}
					showPageJump={false}
					PaginationComponent={DataTablePagination}
					showPageSizeOptions={true}
					defaultSorted={[{id: "package_id",desc: true}]}
				/>
			</div>
			
			{this.props.product!=null ? <AddPackageModal
				modalOpen={this.state.modalOpenAddPackage}
				toggleModal={this.toggleModalAddPackage}
				product={this.props.product}
				dataListRender={this.props.dataListRender}
			/>:""}
			
			{this.props.product!=null && this.state.print_packages.length ? <div className={"d-none"}>
				<ReactToPrint
					trigger={() => <span className="d-block cursor-pointer" id="print-finished-barcodes-hidden" >Print Barcodes</span>}
					content={() => this.componentRef}
					copyStyles={true}
					pageStyle={""}
				/>
				<PrintPackageBarcodes 
					ref={el => (this.componentRef = el)} 
					product_packages={this.state.print_packages} 
					product={this.props.product}
					facility={this.props.facility}					
					selectedIds={this.state.selectedIds}
					finishedProduct={false}
				/>
			</div>:""}
			
			{this.props.product!=null && this.state.product_package!=null ? <AdjustInventoryModal
				modalOpen={this.state.modalOpenAdjustInventory}
				toggleModal={this.toggleModalAdjustInventory}
				product={this.props.product}
				product_package={this.state.product_package}
				dataListRender={this.props.dataListRender}
				api_message={"api_message_packages"}
			/>:""}
			
			{this.props.product!=null && this.state.product_package_id!=0 ? <ViewPackageDetailModal
				modalOpen={this.state.modalOpenViewPackageDetail}
				toggleModal={this.toggleModalViewPackageDetail}
				printBarcodes={this.printBarcodes}
				product={this.props.product}
				product_package_id={this.state.product_package_id}
				is_finished={false}
			/>:""}

			{this.props.product!=null && this.state.product_package!=null ? <MoveToWarehouseModal
				modalOpen={this.state.modalOpenMoveToWarehouse}
				toggleModal={this.toggleModalMoveToWarehouse}
				product={this.props.product}
				warehouses={this.props.warehouses}
				product_package={this.state.product_package}
				dataListRender={this.props.dataListRender}
				api_message={"api_message_packages"}
			/>:""}			
			
			{this.props.product!=null && this.state.product_package!=null ? <MoveToWasteModal
				modalOpen={this.state.modalOpenMoveToWaste}
				toggleModal={this.toggleModalMoveToWaste}
				product={this.props.product}
				product_package={this.state.product_package}
				dataListRender={this.props.dataListRender}
			/>:""}
			
		</Fragment>
    );
  }
  
};
