import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


class AddComplaintandcommentsModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {		
			selectCustomers:[],
			selectProducts:[],
			customer_val:null,
			product_val:[],
		};
	
	}
	
	componentDidMount() {		
		var customers=[];
		var products=[];
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'complaint/get-data-for-add-complaint',formData).then((res)=>{
			var data = res.data;
		
			customers=data.customers;
			products=data.products;
			
			customers = customers.map((element,index)=>{
				element.label=element.vendor_name;
				element.value=element.vendor_id;
				element.key=index;
				return element;
			});
			
			products = products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				selectCustomers:customers,
				selectProducts:products,				
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			let customer = this.state.customer_val;
			let errorMessage='';

			if(typeof customer=='undefined' || customer==null)
			{
				errorMessage+="Customer is not selected\n";
			}
		
			if(errorMessage.length!=0){
				swal("Warning",errorMessage,"warning");
				return false;
			}
			
			document.getElementById("add-complaint").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("complaintForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'complaint/add-complaint',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-complaint").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-complaint").disabled=false;
			});

		}
	}
	
	
  render() {	
  
  const {  selectCustomers,selectProducts } = this.state;
				
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Add Complaint
		  </ModalHeader>
		  <AvForm
		        id="complaintForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			
					<Row className="mt-4">
					<Colxx lg="12">
						<Label >Customer<span className="text-danger">*</span></Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							name="customer_id"
							id="customer_id"
							value={this.state.customer_val}						
							onChange={customer_val => { this.setState({ customer_val:customer_val}) }}
							options={selectCustomers}
						/>
					</Colxx>									
				</Row>
				<Row className="mt-4">
					<Colxx lg="12">
						<Label >Product</Label>
						<Select
							components={{ Input: CustomSelectInput }}
							className="react-select"
							classNamePrefix="react-select"
							id="product_id"
							name="product_id"
							value={this.state.use_product_type}
							onChange={use_product_type => { this.setState({ use_product_type }) }}
							options={selectProducts}
						/>
					</Colxx>									
				</Row>
				<Row className="mt-4">
					<Colxx lg="12" className="mb-2">	
					<AvGroup>
					  <Label>Message<span className="text-danger">*</span></Label>
					  <AvField 
						name="suggestion" 
						type="textarea" 
						autoComplete="off"
						rows="6"
						validate={{
							  required: {
								value: true,
								errorMessage: "Please enter the  message"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The message must have atleast 2 characters"
							  }
							}}
						/>
					</AvGroup>
					</Colxx>
				</Row>								
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="add-complaint">
			  <IntlMessages id="customer.save" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default AddComplaintandcommentsModal;
