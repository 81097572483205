import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import Cart from "../components/front-end/Cart";

import {REST_API_END_POINT,dataEncryptKey } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { seo } from "../helpers/Utils";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class CartPage extends Component {
	
	constructor(props) {
		super(props);
		seo({
			title: 'Cart | '+projectTitle,
			metaDescription: 'Shop with us'
		});
	}
  
	componentDidMount() {
		
		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var facility_id = localStorage.getItem('facility_id');
		if(!facility_id){			
			window.location='/';
		}
		var user_id = localStorage.getItem('user_id');
		if(typeof user_id=='undefined' || user_id==null){
			localStorage.setItem('redirect_url','/cart');
		}
	}
	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}

	render() {
		return (
			<Fragment>
				<ToastProvider placement="bottom-left">
					<div className="main-pages-layout">
						<main>
							<HomeHeader />
							<div className="shop-area pt-5 pb-5">
								<div className="container">
									<Cart/>
								</div>
							</div>
							<HomeFooter />
						</main>	
					</div>
				</ToastProvider>
			</Fragment>	
		);
	}
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(CartPage);
