import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
  Row,
  Input,
  Button,
  Modal,
  Table,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import swal from 'sweetalert';
import { zeroPad } from "../../helpers/Utils";
import number_format from "number_format-php";
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class CompleteOrderModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {
			selectPayment: null,
			order_data: null,
			order_products: null
		};	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){				
            if (prevProps.order_id!== this.props.order_id && this.props.order_id!=0) {
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
			    var formData = new FormData();
				formData.append('order_id',this.props.order_id);
				formData.append('facility_id',facility_id);
				axios.post(REST_API_END_POINT+'inventory-transfer/invoice',formData).then((res)=>{
					
					var data = res.data;
					if(data.status==1){
						this.setState({ 
							order_data:data.order_data,
							order_products:data.order_products
						});
					}else{
						this.props.toggleModal();
						swal("Warning!","There is no such order","warning");
					}					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("complete-order-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("completeOrderForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('order_id',this.props.order_id);
			
			axios.post(REST_API_END_POINT+'inventory-transfer/complete-order',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("complete-order-btn").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("complete-order-btn").disabled=false;
			});
		}
		
	}
	
	render() {	
		const { order_data, order_products } = this.state;
		return (	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Complete Order
			</ModalHeader>
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
				id="completeOrderForm"
			>
			
				<ModalBody>
					<Row>
						<Colxx lg="12">
							{order_data!=null ? <h2 className="mb-4 font-weight-bold">#{zeroPad(order_data.order_number,6)}</h2>:""}
						</Colxx>															
					</Row>
					
					<Table responsive>
						<thead>
							<tr>
								<th>Product</th>
								<th>Package ID</th>
								<th>Transfer Quantity</th>
								<th>Quantity Accepted</th>
								<th>Quantity Returned</th>												
								<th>Move returned quantity to</th>												
							</tr>
						</thead>					
						
						<tbody>							
							{order_data!=null ? order_products.map(row=>{
								return(<tr key={row.id}>
									<td>{row.product_name} {row.variation_name!=null ? "("+row.variation_name+")":""}
									<Input type="hidden" id={"order_product_id"+row.id} name={"order_product_id["+row.id+"]"} value={row.id} />
									<Input type="hidden" id={"product_ids"+row.id} name={"product_ids["+row.id+"]"} value={row.product_id} />
									<Input type="hidden" id={"package_ids"+row.id} name={"package_ids["+row.id+"]"} value={row.package_id} />
									<Input type="hidden" id={"return_quantity"+row.id} name={"return_quantity["+row.id+"]"} value={row.return_quantity} />
									</td>
									<td>{row.package_barcode}</td>
									<td>{number_format(parseFloat(row.product_qty),2,'.','')}</td>
									<td>{number_format(parseFloat(row.accept_quantity),2,'.','')}</td>
									<td>{number_format(parseFloat(row.return_quantity),2,'.','')}</td>
									<td>
										<AvGroup>						  
											<AvField
												id={"moved_to"+row.id} 
												name={"moved_to["+row.id+"]"}
												className={row.return_quantity==0 ? 'd-none':""}
												type="select"
											>
												<option value="1" >Waste</option>
												<option value="2" >Inventory</option>
											</AvField>
										</AvGroup>
									</td>
								</tr>)
							}):""}
						</tbody>
					</Table>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="complete-order-btn">
						Complete
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
		);
	}
};

export default CompleteOrderModal;
