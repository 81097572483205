import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import allwarehouses from "./allwarehouses";
import shelftypes from "./shelftypes";
import warehouseview from "./warehouseview";
import warehouseviewlist from "./warehouseviewlist";
import shelfView from "./shelfView";


const Warehouse = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			
			<Route path={`${match.url}/all-warehouses`} component={allwarehouses} />
			<Route path={`${match.url}/shelf-types`} component={shelftypes} />		
			<Route path={`${match.url}/warehouse-view/:warehouse_id`} component={warehouseview} />
			<Route path={`${match.url}/warehouseviewlist`} component={warehouseviewlist} />
			<Route path={`${match.url}/shelf-view/:shelf_id`} component={shelfView} />
			<Route path={`${match.url}`} component={allwarehouses} />
			<Redirect to="/error" />
        </Switch>
    </div>
);
export default Warehouse  ;