import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, DropdownItem, CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddMoneyInternalModal from "../../../containers/bankmanagement/AddMoneyInternalModal";
import AddDepositMoneyModal  from "../../../containers/bankmanagement/AddDepositMoneyModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { AvForm, AvField } from "availity-reactstrap-validation";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Internalbank extends Component {
	
	constructor(props) {
		super(props);		
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		  
			modalOpenAdd: false,
			modalOpenAdd1: false,
			modalOpenAdd2: false,
			modalOpenEdit: false,
			modalOpenStaff: false,		  
			search_cash: '',
			search_cash_taken: '',
			search_cash_drop: '',
			search_cash_moved: '',
			cash:[],
			cashtaken:[],
			cashmoved:[],
			cashdropped:[],
			externalbanks:[],
			currencies:[],
			currency_code:'USD',
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalAdd1 = () => {
		this.setState({
			modalOpenAdd1: !this.state.modalOpenAdd1
		});
	};
	
	toggleModalAdd2 = () => {
		this.setState({
			modalOpenAdd2: !this.state.modalOpenAdd2
		});
	};
	
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
	
	clearBank = (e)  => {
		var success_msg="The internal bank has been cleared successfully";
		var failure_msg="Failed to clear internal bank";
		swal({
          title: "Are you sure to clear the bank?",
          text: "",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("clear-internalbank").disabled=true;
			
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				formData.append('currency_code',this.state.currency_code);
				
				axios.post(REST_API_END_POINT+'bank/clear-bank',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("clear-internalbank").disabled=false;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					this.dataListRender();
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("clear-internalbank").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}

	dataListRender() {			
		let bank;
		let cash;
		let cashtaken;
		let cashmoved;
		let cashdropped;
		let externalbanks;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('currency_code',this.state.currency_code);

		axios.post(REST_API_END_POINT+'bank/get-bank',formData).then((res)=>{
		 	var data = res.data;
		    bank=data.bank;
			cash=data.cash;
			cashtaken=data.cash_taken;
			cashmoved=data.cash_moved;
			cashdropped=data.cash_dropped;
			externalbanks=data.external_banks;		 	
			var currencies=data.currencies;
			
			externalbanks = externalbanks.map(row=>{
				row.label=row.bank_name;
				row.value=row.bank_id;
				row.key=row.bank_id;
				return row;
			});
			
		 	this.setState({			
			 	bank,
				cash,
				cashtaken,
				cashmoved,
				cashdropped,
				externalbanks,
				currencies,	
			});			
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	   
	componentDidMount() {
		this.dataListRender();	
	}
	 
	 
	render() {
		const currencies = this.state.currencies;
		let data = this.state.cash;
		if (this.state.search_cash) {
			data = data.filter(row => {
				return row['1_s'].includes(this.state.search_cash) ||
					row['2_s'].includes(this.state.search_cash) ||
					row['5_s'].includes(this.state.search_cash) ||
					row['10_s'].includes(this.state.search_cash) ||
					row['20_s'].includes(this.state.search_cash) ||
					row['50_s'].includes(this.state.search_cash) ||
					row['100_s'].includes(this.state.search_cash) ||
					row['cents'].includes(this.state.search_cash) ||
					row['nickles'].includes(this.state.search_cash) ||
					row['dimes'].includes(this.state.search_cash) ||
					row['quarters'].includes(this.state.search_cash) ||
					row['50_cents'].includes(this.state.search_cash) ||
					row['1_d'].includes(this.state.search_cash) ||
					row['total'].includes(this.state.search_cash) ||
					row['added_by'].toLowerCase().includes(this.state.search_cash) ||
					row['added_at_date'].toLowerCase().includes(this.state.search_cash)
			})
		}
		
		let datataken = this.state.cashtaken;
		if (this.state.search_cash_taken) {
			datataken = datataken.filter(row => {
				return row['1_s'].includes(this.state.search_cash_taken) ||
					row['2_s'].includes(this.state.search_cash_taken) ||
					row['5_s'].includes(this.state.search_cash_taken) ||
					row['10_s'].includes(this.state.search_cash_taken) ||
					row['20_s'].includes(this.state.search_cash_taken) ||
					row['50_s'].includes(this.state.search_cash_taken) ||
					row['100_s'].includes(this.state.search_cash_taken) ||
					row['cents'].includes(this.state.search_cash_taken) ||
					row['nickles'].includes(this.state.search_cash_taken) ||
					row['dimes'].includes(this.state.search_cash_taken) ||
					row['quarters'].includes(this.state.search_cash_taken) ||
					row['50_cents'].includes(this.state.search_cash_taken) ||
					row['1_d'].includes(this.state.search_cash_taken) ||
					row['total'].includes(this.state.search_cash_taken) ||
					row['drawer_name'].toLowerCase().includes(this.state.search_cash_taken) ||
					row['taken_by'].toLowerCase().includes(this.state.search_cash_taken) ||
					row['taken_at'].toLowerCase().includes(this.state.search_cash_taken)
			})
		}
		
		let datadropped = this.state.cashdropped;
		if (this.state.search_cash_drop) {
			datadropped = datadropped.filter(row => {
				return row['1_s'].includes(this.state.search_cash_drop) ||
					row['2_s'].includes(this.state.search_cash_drop) ||
					row['5_s'].includes(this.state.search_cash_drop) ||
					row['10_s'].includes(this.state.search_cash_drop) ||
					row['20_s'].includes(this.state.search_cash_drop) ||
					row['50_s'].includes(this.state.search_cash_drop) ||
					row['100_s'].includes(this.state.search_cash_drop) ||
					row['cents'].includes(this.state.search_cash_drop) ||
					row['nickles'].includes(this.state.search_cash_drop) ||
					row['dimes'].includes(this.state.search_cash_drop) ||
					row['quarters'].includes(this.state.search_cash_drop) ||
					row['50_cents'].includes(this.state.search_cash_drop) ||
					row['1_d'].includes(this.state.search_cash_drop) ||
					row['total'].includes(this.state.search_cash_drop) ||
					row['drawer_name'].toLowerCase().includes(this.state.search_cash_drop) ||
					row['taken_by'].toLowerCase().includes(this.state.search_cash_drop) ||
					row['taken_at'].toLowerCase().includes(this.state.search_cash_drop)
			})
		}
		
		let datamoved = this.state.cashmoved;
		if (this.state.search_cash_moved) {
			datamoved = datamoved.filter(row => {
				return row['1_s'].includes(this.state.search_cash_moved) ||
					row['2_s'].includes(this.state.search_cash_moved) ||
					row['5_s'].includes(this.state.search_cash_moved) ||
					row['10_s'].includes(this.state.search_cash_moved) ||
					row['20_s'].includes(this.state.search_cash_moved) ||
					row['50_s'].includes(this.state.search_cash_moved) ||
					row['100_s'].includes(this.state.search_cash_moved) ||
					row['cents'].includes(this.state.search_cash_moved) ||
					row['nickles'].includes(this.state.search_cash_moved) ||
					row['dimes'].includes(this.state.search_cash_moved) ||
					row['quarters'].includes(this.state.search_cash_moved) ||
					row['50_cents'].includes(this.state.search_cash_moved) ||
					row['1_d'].includes(this.state.search_cash_moved) ||
					row['total'].includes(this.state.search_cash_moved) ||
					row['bank_name'].toLowerCase().includes(this.state.search_cash_moved) ||
					row['taken_by'].toLowerCase().includes(this.state.search_cash_moved) ||
					row['taken_at'].toLowerCase().includes(this.state.search_cash_moved)
			})
		}
		
		var bank = this.state.bank;	
		
		if(this.state.bank!=null){
			var total = parseInt(bank['1_s'])+parseInt(bank['2_s'])*2+parseInt(bank['5_s'])*5+parseInt(bank['10_s'])*10+parseInt(bank['20_s'])*20+parseInt(bank['50_s'])*50+parseInt(bank['100_s'])*100+parseInt(bank['cents'])*0.01+parseInt(bank['nickles'])*0.05+parseInt(bank['dimes'])*0.1+parseInt(bank['quarters'])*0.25+bank['50_cents']*0.5+parseInt(bank['1_d'])
		}else{
			var total=0;
		}
		
		const commonColumns = [		
			{
				Header: "1's",
				accessor: "1_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "2's",
				accessor: "2_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "5's",
				accessor: "5_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "10's",
				accessor: "10_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "20's",
				accessor: "20_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "50's",
				accessor: "50_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "100's",
				accessor: "100_s",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Cents",
				accessor: "cents",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Nickels",
				accessor: "nickles",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Dimes",
				accessor: "dimes",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Quarters",
				accessor: "quarters",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "50 Cents",
				accessor: "50_cents",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Dollar",
				accessor: "1_d",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Total",
				accessor: "total",
				Cell: props => <p className="list-item-heading">{parseFloat(props.value).toFixed(2)}</p>
			}
		];
		
		const selected_currency = currencies.find(row=>{ return row.currency_code==this.state.currency_code });
		var bank_total_view = [];
		var finalCommonColumns = commonColumns;
		if(typeof selected_currency!='undefined'){
			finalCommonColumns = [];
			const currency_struct = JSON.parse(selected_currency.currency_struct);
			for(let key in currency_struct){
				finalCommonColumns.push(commonColumns.find(r=>{ return r.accessor==key }));
				
				bank_total_view.push(<Fragment>
									<Colxx xxs="3" className="mt-4">
										<img alt={currency_struct[key].alt} src={REST_API_BASE_URL+currency_struct[key].src} className="img-responsive" />
									</Colxx>
									<Colxx xxs="3" className="mt-4">
										<h1 className="font-weight-bold mt-3">{bank? ((bank[key]!=null)?bank[key]:'0'):'0' }</h1>
									</Colxx>
								</Fragment>)
				
			}
		}
		
		const dataTableColumns = [ ...finalCommonColumns,
			{
				Header: "Added By",
				accessor: "added_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Added At",
				accessor: "added_at_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			}	  
		];
	
		const dataTableColumns1 = [	...finalCommonColumns,	
	  
			{
				Header: "Drawer Name",
				accessor: "drawer_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Taken By",
				accessor: "taken_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Taken At",
				accessor: "taken_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			}	  
		];
		
		const dataTableColumns2 = [ ...finalCommonColumns,			  
			{
				Header: "Drawer Name",
				accessor: "drawer_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Taken By",
				accessor: "taken_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Taken At",
				accessor: "taken_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
	  
		];
		
		const dataTableColumns3 = [ ...finalCommonColumns,
			{
				Header: "Bank Name",
				accessor: "bank_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Moved By",
				accessor: "taken_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Moved At",
				accessor: "taken_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			}	  
		];
	
	
    return (
      <Fragment>
        <Row>
			<Colxx xxs="12">
				<Breadcrumb heading="menu.internal-bank" match={this.props.match} />
				<Separator className="mb-5" />
			</Colxx>
        </Row>
		
		<Row>
			<Colxx xxs="12">
				<div id="api_message"></div>
			</Colxx>
		</Row>
		
		<Card className="p-4">
			<Row>				
				<Colxx xxs="12">
					<div className="float-sm-left d-none" style={{width:'200px'}}>
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={()=>{}}
						>
							<AvField
								type="select"
								name="currency_code"
								id="currency_code"
								value={this.state.currency_code}
								onChange={(e)=>{ this.setState({ currency_code:e.target.value },()=>{ this.dataListRender() }) }}
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							>
								{currencies.map(opt=>{
									return <option key={opt.id} value={opt.currency_code} >{opt.country_name+" - "+opt.currency_code}</option>
								})}
							</AvField>
						</AvForm>
					</div>
					<div className="float-sm-right">
						{" "}<Button
							  color="primary"
							  size="sm"
							  className=" d-none"
							  onClick={this.toggleModalAdd}
							>
							Add Money
						</Button>{" "}{this.state.externalbanks.length ? <Button
								color="primary"
								size="sm"
								className=""
								onClick={this.toggleModalAdd1}							
							>
								Deposit Money to External Bank						
						</Button>:""}{" "}<Button
							id="clear-internalbank"
							color="primary"
							size="sm"
							className=""
							onClick={event =>this.clearBank(event)} 							
						  >
							Clear Bank
						</Button>
					</div>
				</Colxx>
				<Colxx xxs="9">
					<Row className="currency-img align-items-center">
						{bank_total_view}
					</Row>
				
					<Row className="mt-5">
						<Colxx xxs="5">
							<h1>Total</h1>	
						</Colxx>
						<Colxx xxs="7">
							<h1>{total.toFixed(2)}</h1>		
						</Colxx>
					</Row>
				</Colxx>
			
				<Colxx xxs="12">
					<div className="mb-4 dropdown-edit">					
						<h3 className="font-weight-bold">Cash Added to Bank</h3>
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search_cash}
								  onChange={e => this.setState({search_cash: e.target.value.toLowerCase()})}
								/>
						  </Colxx>
						</FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={5}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "added_at_date",desc: true}]}
						/>
					</div>	
					<div className="mb-4 dropdown-edit">
						<h3 className="font-weight-bold">Cash Taken From Bank</h3>
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search_taken"
								  placeholder="Search"
								  value={this.state.search_cash_taken}
								  onChange={e => this.setState({search_cash_taken: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
						  data={datataken}
						  columns={dataTableColumns1}
						  defaultPageSize={5}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "taken_at",desc: true}]}
						/>
					</div>	
					<div className="mb-4 dropdown-edit">	
						<h3 className="font-weight-bold">Cash Dropped From Drawers</h3>
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search_dropped"
								  placeholder="Search"
								  value={this.state.search_cash_drop}
								  onChange={e => this.setState({search_cash_drop: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
                          data={datadropped}
						  columns={dataTableColumns2}
						  defaultPageSize={5}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "taken_at",desc: true}]}
						/>
					</div>	
					<div className="mb-4 dropdown-edit">	
						<h3 className="font-weight-bold">Cash Moved to External Bank</h3>
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search_moved"
								  placeholder="Search"
								  value={this.state.search_cash_moved}
								  onChange={e => this.setState({search_cash_moved: e.target.value.toLowerCase()})}
								/>
							</Colxx>
						</FormGroup>			
						<ReactTable
							data={datamoved}
							columns={dataTableColumns3}
							defaultPageSize={5}
							filterable={false}
							showPageJump={false}
							responsive
							PaginationComponent={DataTablePagination}
							showPageSizeOptions={true}
							defaultSorted={[{id: "taken_at",desc: true}]}
						/>						
					</div>					
				</Colxx>
			</Row>
			
			<AddMoneyInternalModal
				modalOpen={this.state.modalOpenAdd}
				toggleModal={this.toggleModalAdd}
				dataListRender={this.dataListRender}
				currencies={this.state.currencies}
				currency_code={this.state.currency_code}
			/>
			<AddDepositMoneyModal
				modalOpen={this.state.modalOpenAdd1}
				toggleModal={this.toggleModalAdd1}
				dataListRender={this.dataListRender}
				external_banks={this.state.externalbanks}
				currencies={this.state.currencies}
				currency_code={this.state.currency_code}
			/>		
		</Card>
      </Fragment>
    );
  }
}
