import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label, Input
} from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../common/CustomBootstrap";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE,DEFAULT_QTY_TYPES, OUNCE_CONSTANT } from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class FillModal extends Component{
	constructor(props) {
		super(props);
		
		this.state={
			package_idd:null,
			product_package:null,
			fill_facility_id:null,
			fill_variation:null,
			fill_variation_id:null,
			no_finished:0,
			max_no_finished:1,
			remaining_qty_finished:0
		}
		
		this.handleSubmit = this.handleSubmit.bind(this);	
		this.updateField = this.updateField.bind(this);	
		this.updateFinishedProducts = this.updateFinishedProducts.bind(this);	
	}
	
	componentDidMount() {		
		var product_package = null;
		var package_idd = null;
		var fill_variation = null;
		var fill_variation_id = null;
		if(this.props.unused_packages.length){
			product_package=this.props.unused_packages[0];
			package_idd=product_package.package_id;
		}
		
		if(this.props.product_variations.length){
			fill_variation=this.props.product_variations[0];
			fill_variation_id = fill_variation.variation_id;
		}
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		this.setState({ product_package, package_idd, fill_variation, fill_variation_id, fill_facility_id:facility_id },()=>{
			this.updateField();
		}); 
		
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("fill-finished-products").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("fillForm"));
			formData.append('product_id',this.props.product.id);
			formData.append('user_id',user_id);
			
			axios.post(REST_API_END_POINT+'inventory/fill-finished-products',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){						
					var api_message=document.getElementById("api_message_finished_products");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
					
					document.getElementById("fill-finished-products").disabled=false;
					this.props.toggleModal();
					this.props.dataListRender();
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("fill-finished-products").disabled=false;
			});
		}
	}
	
	updateField(){	
		const fill_variation = this.state.fill_variation;
		const product_package = this.state.product_package;
		
		if(fill_variation!=null && product_package!=null){
			var capacity=parseFloat(fill_variation.quantity);
			var variation_type=parseInt(fill_variation.qty_type);
			var package_qty=parseFloat(product_package.quantity);
			
			if(variation_type===0||variation_type==='0'){
				capacity = capacity/1000;
			}else if(variation_type==2){
				capacity = capacity*OUNCE_CONSTANT;
			}
			var no_finished=Math.floor(package_qty/capacity);
			var remaining_qty_finished = (package_qty-(no_finished*capacity)).toFixed(2);
			
			this.setState({ no_finished, remaining_qty_finished, max_no_finished:no_finished });
		}		
	}
	
	updateFinishedProducts(no_finished){
		const fill_variation = this.state.fill_variation;
		const product_package = this.state.product_package;
		
		var capacity=parseFloat(fill_variation.quantity);
		var variation_type=parseInt(fill_variation.qty_type);
		var package_qty=parseFloat(product_package.quantity);
		
		if(variation_type===0||variation_type==='0'){
			capacity = capacity/1000;
		}else if(variation_type==2){
			capacity = capacity*OUNCE_CONSTANT;
		}
		
		var remaining_qty_finished = (package_qty-(no_finished*capacity)).toFixed(2);
		
		this.setState({ remaining_qty_finished, no_finished });			
	}
	
	render() {
		const qty_type = this.props.product.qty_type==3 ? "Units":"Grams";
		const facility_ids=  this.props.product.facility_id.split(',');
		var facilities = this.props.facilities.filter(r=>{ return facility_ids.indexOf(r.facility_id)!=-1 });
		const { no_finished, product_package, max_no_finished } = this.state;
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Fill to Make Finished Products
				</ModalHeader>
				<AvForm
					id="fillForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
				<ModalBody>
					<Row>
						<Colxx lg="3">
							<Label>Choose Facility</Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="fill_facility_id"
									name="facility_id"
									type="select"
									value={this.state.fill_facility_id}
									onChange={(e)=>{ this.setState({ fill_facility_id:e.target.value }) }}
								>
								{facilities.map((row,index)=>{
									return <option value={row.facility_id} key={index} >{row.facility_name}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>Variation<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="fill_variation_id"
									name="fill_variation_id"
									type="select"
									value={this.state.fill_variation_id}
									onChange={e=>{
										var fill_variation = this.props.product_variations.find(r=>{ return r.variation_id==e.target.value });
										if(fill_variation!=null){
											this.setState({ fill_variation_id:fill_variation.variation_id,fill_variation },()=>{
												this.updateField();
											});
										}else{
											this.setState({ fill_variation_id:null,fill_variation:null });
										}
									}}
								>
								{this.props.product_variations.map((row,index)=>{
									return <option value={row.variation_id} key={index} >{row.variation_name}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>Package<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="unused_packages"
									name="package_idd"
									type="select"
									value={this.state.package_idd}
									onChange={e=>{
										var product_package = this.props.unused_packages.find(r=>{ return r.package_id==e.target.value });
										if(product_package!=null){
											this.setState({ package_idd:product_package.package_id,product_package },()=>{
												this.updateField();
											});
										}else{
											this.setState({ package_idd:null,product_package:null });
										}
									}}
								>
								{this.props.unused_packages.map((row,index)=>{
									return <option value={row.package_id} key={index} >{row.barcode}</option>
								})}
								</AvField>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3">
							<Label>Available<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="6">
							<AvGroup >								
								<AvField
									id="qty_available"
									name="qty_available"
									type="text"
									autoComplete="off"
									value={product_package!=null ? product_package.quantity:""}
									readOnly={true}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="3">
							<p className="pt-2">{qty_type}</p>
						</Colxx>
					</Row>
					
					<Row>
						<Colxx lg="3">
							<Label>No. Finished Product<span className="text-danger">*</span></Label>
						</Colxx>
						<Colxx lg="9">
							<AvGroup >								
								<AvField
									id="no_finished"
									name="no_finished"
									type="number"
									autoComplete="off"
									value={no_finished}
									onChange={e=>{
										this.updateFinishedProducts(e.target.value);									
									}}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										number: {
											value: true,
											errorMessage: "This entry is invalid."
										},
										min:{
											value: 1,
											errorMessage: "Please enter a value greater than or equal to 1"
										},
										max:{
											value: max_no_finished,
											errorMessage: "Please enter a value less than or equal to "+max_no_finished
										}
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="3">
							<Label>Remaining</Label>
						</Colxx>
						<Colxx lg="6">
							<AvGroup >
								<AvField
									id="remaining_qty_finished"
									name="remaining_qty_finished"
									type="number"
									autoComplete="off"
									value={this.state.remaining_qty_finished}
									readOnly={true}
								/>
							</AvGroup>
						</Colxx>
						<Colxx lg="3">
							<p className="pt-2">{qty_type}</p>
						</Colxx>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary"  id="fill-finished-products" >
						<IntlMessages id="customer.submit" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			   </AvForm>
			</Modal>
	  );
  }
};