const alltable = [
{
    id: 1,
    name: "Table 1", 
	tableno: "2",   
	productCount: "Test Product 10 (Station,Distribution,Genetics), Sample Product 8 (Distribution)",   
	createdAt: "2019-04-19 00:28:54",   
	createdBy: "",   
	plantCount: "4",   
	status: {
      statusid: 1,
      statusColor: "success",
    }	
  },
  {
    id: 2,
    name: "Table 2",
	tableno: "2",   
	productCount: "Test Product 10 (Station,Distribution,Genetics), Sample Product 8 (Distribution)",   
	createdAt: "2019-04-19 00:28:54",   
	createdBy: "",    
	plantCount: "4", 
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  }
]

export default alltable
