import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Input, InputGroup, InputGroupAddon } from "reactstrap";
import queryString from 'query-string';
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";

import { REST_API_END_POINT,themeColor } from "../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import Pagination from "../../containers/pages/Pagination";
import UserCard from "../../containers/admin/UserCard";
import EditSubscriberModal from "../../containers/admin/EditSubscriberModal";
import ChangePasswordSubscriberModal from "../../containers/admin/ChangePasswordSubscriberModal";

export default class Users extends Component {
	
	constructor(props) {
		super(props);
		
		this.dataListRender = this.dataListRender.bind(this);	
		this.toggleModalEdit=this.toggleModalEdit.bind(this);
		this.toggleModalChangePassword=this.toggleModalChangePassword.bind(this);
		this.activateRow=this.activateRow.bind(this);
		this.resendConfirmEmail=this.resendConfirmEmail.bind(this);
		this.onChangePage=this.onChangePage.bind(this);
		this.searchStartWith=this.searchStartWith.bind(this);
		this.state = {
			modalOpen: false,
			modalOpenEdit: false,
			modalOpenChangePassword: false,
			blocking: false,
			orderByOrder: 'desc',
			selectedPageSize: 12,		  
			pageSizes: [12, 24, 36, 60, 100],
			currentPage: 0,
			totalItemCount: 0,
			totalPage: 1,
			search: "",
			startWith: "",
			items:[],
			user_id:0,
			p_user_id:0
		};
	}
	
	componentDidMount() {
		this.dataListRender();
	}
	
	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen
		});
	};

	toggleModalEdit = (e,user_id=0) => {
		this.setState({
			modalOpenEdit: !this.state.modalOpenEdit,
			user_id
		});
	};
	
	toggleModalChangePassword = (e,p_user_id=0) => {
		this.setState({
			modalOpenChangePassword: !this.state.modalOpenChangePassword,
			p_user_id
		});
	};
	
	activateRow = (row_id,status) => {		
		var formData = new FormData();
		formData.append('user_id',row_id);
		
		var msg="";
		if(status==1){
			formData.append('status',3);
			msg="You want to deactivate this subscriber!"
		}else if(status==2){
			formData.append('status',2);
			msg="You want to delete this subscriber!"
		}else{
			formData.append('status',1);
			msg="You want to activate this subscriber!"			
		}
		swal({
		  title: "Are you sure?",
		  text: msg,
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then((willDelete) => {
		  if (willDelete) {
			axios.post(REST_API_END_POINT+'admin/update-user-status',formData).then((data)=>{ 
				if(data.data.status==1){
					this.dataListRender();
					if(status==1){
						swal("Success!", "The subscriber has been deactivated successfully!", "success");
					}else if(status==2){
						swal("Success!", "The subscriber has been deleted successfully!", "success");
					}else{
						swal("Success!", "The subscriber has been activated successfully!", "success");
					}								
				}else{
					if(status==1){
						swal("Failed to deactivate subscriber!", "Please try after sometime.", "error");
					}else if(status==2){
						swal("Failed to delete subscriber!", "Please try after sometime.", "error");
					}else{
						swal("Failed to activate subscriber!", "Please try after sometime.", "error");
					}		
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		  } else {
			swal("You have canceled the operation!");
		  }
		});
	};
	
	resendConfirmEmail = (row_id) => {
		var formData = new FormData();
		formData.append('user_id',row_id);
		var msg="You want to resend the confirm email?";
		
		swal({
		  title: "Are you sure?",
		  text: msg,
		  icon: "warning",
		  buttons: true,
		  dangerMode: true,
		})
		.then((willDelete) => {
		  if (willDelete) {
			axios.post(REST_API_END_POINT+'admin/resend-confirm-mail',formData).then((data)=>{ 
				if(data.data.status==1){
					swal("Success!", "The confirmation email has been sent successfully!", "success");			
				}else{
					swal("Failed to sent email!", "Please try after sometime.", "error");
				}
			}).catch(e => console.log("Addition failed , Error ", e));
		  } else {
				swal("You have canceled the operation!");
		  }
		});
	};
	
	onChangePage = page => {
		this.setState({	currentPage: page },() => this.dataListRender());
	};
	
	searchStartWith = (startWith) => {
		this.setState({ startWith },()=>{
			this.dataListRender();
		});
	};
	
	searchName = (value) => {
		this.setState({ search: value.toLowerCase() }, () =>{ if(value==''){ this.dataListRender() } });		
	};
	
	searchNamePress = (e) => {
		if (e.key === "Enter") {
			this.setState({ search: e.target.value.toLowerCase() }, () => this.dataListRender() );
		}
	};
	
	dataListRender() {
		this.setState({ blocking: true });
		var subscriber_id;
		const {
		  selectedPageSize,
		  currentPage,
		  orderByOrder,
		  search,
		  startWith
		} = this.state;
		axios
		  .get(
			`${REST_API_END_POINT+'admin/subscribers'}?pageSize=${selectedPageSize}&currentPage=${currentPage}&orderBy=timestamp&orderByOrder=${orderByOrder}&startWith=${startWith}&search=${search}`
		  )
		  .then(res => {
			return res.data;
		  })
		  .then(data => {			
			this.setState({
				totalPage: data.totalPage,
				items: data.data,
				totalItemCount: data.totalItem,
				blocking: false
			});
		  });
	}
	  
		
	render() {
		const { items } = this.state;
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.users" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>

					<Row className="people-layout">          		  
						<Colxx lg="12" className="page-tabs">
							<Row className="mb-3">
								<Colxx lg="12">
									<InputGroup>
										<Input name="search_user" id="search_user" value={this.state.search} placeholder="Search" onChange={(e)=>{ this.searchName(e.target.value) }} onKeyPress={(e)=>{ this.searchNamePress(e) }} />
										<InputGroupAddon addonType="append" className="pt-2 pb-2 pl-3 pr-3 align-items-center" style={{background:'#fff',border: '1px solid #d7d7d7'}} onClick={this.dataListRender}><i className="iconsminds-magnifi-glass" /></InputGroupAddon>
									</InputGroup>
									
								</Colxx>
							</Row>
							<Row className="mb-3">
								<Colxx lg="12" className="text-center alpha-sort">
									<Button size="md" color="link" className={this.state.startWith=="" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("") }} >ALL</Button>
									<Button size="md" color="link" className={this.state.startWith=="A" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("A") }} >A</Button>
									<Button size="md" color="link" className={this.state.startWith=="B" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("B") }} >B</Button>
									<Button size="md" color="link" className={this.state.startWith=="C" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("C") }} >C</Button>
									<Button size="md" color="link" className={this.state.startWith=="D" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("D") }} >D</Button>
									<Button size="md" color="link" className={this.state.startWith=="E" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("E") }} >E</Button>
									<Button size="md" color="link" className={this.state.startWith=="F" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("F") }} >F</Button>
									<Button size="md" color="link" className={this.state.startWith=="G" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("G") }} >G</Button>
									<Button size="md" color="link" className={this.state.startWith=="H" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("H") }} >H</Button>
									<Button size="md" color="link" className={this.state.startWith=="I" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("I") }} >I</Button>
									<Button size="md" color="link" className={this.state.startWith=="J" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("J") }} >J</Button>
									<Button size="md" color="link" className={this.state.startWith=="K" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("K") }} >K</Button>
									<Button size="md" color="link" className={this.state.startWith=="L" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("L") }} >L</Button>
									<Button size="md" color="link" className={this.state.startWith=="M" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("M") }} >M</Button>
									<Button size="md" color="link" className={this.state.startWith=="N" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("N") }} >N</Button>
									<Button size="md" color="link" className={this.state.startWith=="O" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("O") }} >O</Button>
									<Button size="md" color="link" className={this.state.startWith=="P" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("P") }} >P</Button>
									<Button size="md" color="link" className={this.state.startWith=="Q" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("Q") }} >Q</Button>
									<Button size="md" color="link" className={this.state.startWith=="R" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("R") }} >R</Button>
									<Button size="md" color="link" className={this.state.startWith=="S" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("S") }} >S</Button>
									<Button size="md" color="link" className={this.state.startWith=="T" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("T") }} >T</Button>
									<Button size="md" color="link" className={this.state.startWith=="U" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("U") }} >U</Button>
									<Button size="md" color="link" className={this.state.startWith=="V" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("V") }} >V</Button>
									<Button size="md" color="link" className={this.state.startWith=="W" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("W") }} >W</Button>
									<Button size="md" color="link" className={this.state.startWith=="X" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("X") }} >X</Button>
									<Button size="md" color="link" className={this.state.startWith=="Y" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("Y") }} >Y</Button>
									<Button size="md" color="link" className={this.state.startWith=="Z" ? "font-weight-bold":""} onClick={()=>{ this.searchStartWith("Z") }} >Z</Button>
								</Colxx>
							</Row>
								
							<Row className="mt-3">
								{items.map((row,index)=>{
									return <UserCard 
										key={index} 
										user={row}  
										toggleModalEdit={this.toggleModalEdit}
										toggleModalChangePassword={this.toggleModalChangePassword}
										activateRow={this.activateRow}
										resendConfirmEmail={this.resendConfirmEmail}
										/>
								})}
							</Row>
							<Row>
								<Pagination
									currentPage={this.state.currentPage}
									totalPage={this.state.totalPage}
									onChangePage={i => this.onChangePage(i)}
								/>
							</Row>
								
						</Colxx>
					</Row>
					
					<EditSubscriberModal
						modalOpen={this.state.modalOpenEdit}
						toggleModal={this.toggleModalEdit}
						dataListRender={this.dataListRender}
						user_id={this.state.user_id}
					/>
					
					<ChangePasswordSubscriberModal
						modalOpen={this.state.modalOpenChangePassword}
						toggleModal={this.toggleModalChangePassword}
						dataListRender={this.dataListRender}
						user_id={this.state.p_user_id}
					/>
									  
				</BlockUi>  
			</Fragment>
		);
	}
}
