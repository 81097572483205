import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, FormGroup, Input, Label, CustomInput, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import DatePicker from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import number_format from "number_format-php";
import swal from 'sweetalert';
import moment from 'moment';

import {REST_API_END_POINT, dataEncryptKey, JQUERY_MODULE, LBS_CONSTANT} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import { uuid } from "uuidv4";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const finishedStruct = {
	id:uuid(),
	product_id:null,
	variation_id:null,
	quantity:0,
	variations:[],
}

export default class RawMaterialStaging extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			rrs:[],
			containers:[],
			product_packages:[],
			packages_dropdown:[],
			consolidations:[],
			container_uid:null,
			container_capacity:0,
			in_house_weight_lbs_val:"",
			in_house_weight_gram_val:"",
			waste:"",
			rr:"",
			package_uid:null,		  
			step:1,	
			batchStartTime:null,
			batchEndTime:null,
			products:[],
			finishedProducts:[JSON.parse(JSON.stringify(finishedStruct))],
			submitted:false,
		};
		
		this.getContainerDetails = this.getContainerDetails.bind(this);
		this.addConsolidation = this.addConsolidation.bind(this);
		this.removeConsolidation = this.removeConsolidation.bind(this);
		this.completeConsolidation = this.completeConsolidation.bind(this);
		this.updateFinishedProduct = this.updateFinishedProduct.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'processor/raw-material',formData).then((res)=>{
			var data = res.data;
		
			if(data.status==1){
				var containers = data.containers;
				containers = containers.map(row=>{
					row.label=row.container_name+" ("+row.barcode+")";
					row.value=row.container_id;
					row.key=row.container_id;
					return row;
				});
				
				var products = data.products;
				products = products.map(row=>{
					let row_data={};
					row_data.label=row.name;
					row_data.value=row.id;
					row_data.key=row.id;
					return row_data;
				});
				
				let product_packages = data.packages;
				product_packages = product_packages.map(row=>{
					row.label=row.name+" ("+row.barcode+")";
					row.value=row.package_id;
					row.key=row.package_id;
					row.package=true;
					return row;
				});
				
				let rrs = data.rrs;
				rrs = rrs.map(row=>{
					row.label=row.name+" ("+row.batch_uid+")";
					row.value=row.id;
					row.key=row.id;
					row.package=false;
					return row;
				});
				
				let packages_dropdown = [...product_packages,...rrs]
				
				this.setState({
					containers,
					product_packages,
					rrs,
					packages_dropdown,
					products
				});
			}else{
				this.setState({					
					containers:[],
					product_packages: [],
					rrs: [],
					packages_dropdown: [],
					products:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	
	getContainerDetails(container_id) {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('container_id',container_id);
		
		axios.post(REST_API_END_POINT+'processor/get-container-details',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){				
				var consolidations = data.consolidations;
				var container_capacity = data.container_capacity;
				this.setState({
					consolidations,
					container_capacity
				});
			}else{
				this.setState({					
					consolidations:[],
					container_capacity:0,
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	addConsolidation() {
		const container_uid=this.state.container_uid;
		const package_uid=this.state.package_uid;
		const consolidations=this.state.consolidations;
		const container_capacity=this.state.container_capacity;
		if(container_uid!=null){
			var container_id=container_uid.value;
			if(package_uid!=null){
				var package_id=package_uid.value;
				var in_house_weight_lbs_val =0;
				var in_house_weight_gram_val =0;
				
				var package_current_qty = parseFloat(package_uid.quantity);
				
				if(this.state.in_house_weight_lbs_val!=''){
					in_house_weight_lbs_val = parseFloat(this.state.in_house_weight_lbs_val);
				}
				
				var in_house_weight = parseFloat(((in_house_weight_lbs_val*LBS_CONSTANT)+in_house_weight_gram_val).toFixed(4));
				if(package_current_qty<in_house_weight){
					let package_current_qty_lbs=parseFloat(package_current_qty/LBS_CONSTANT).toFixed(4);
					swal("Warning!",'The package has only '+package_current_qty_lbs+' Ton ',"warning");
					return;
				}
				
				var total_grams=0;
				if(consolidations.length){
					total_grams = parseFloat(consolidations.map(r=>r.in_house_weight).reduce((total,in_house_weight)=>{ return parseFloat(total)+parseFloat(in_house_weight) }));
				}
								
				if(parseFloat((total_grams+in_house_weight).toFixed(2))>container_capacity){
					swal("Warning!",'The addition of this stock will exceed the vessel capacity ',"warning");
					return;
				}
				
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				formData.append('facility_id',facility_id);
				formData.append('container_id',container_id);
				if(package_uid.package){
					formData.append('package_id',package_id);
					formData.append('staging_load_id',0);
				}else{
					formData.append('package_id',0);
					formData.append('staging_load_id',package_id);
				}
				formData.append('sack_stock_id',0);
				formData.append('in_house_weight',in_house_weight);
				
				axios.post(REST_API_END_POINT+'processor/add-consolidation',formData).then((res)=>{
					var data = res.data;
					
					if(data.status==1){				
						let consolidations = data.consolidations;
						
						var product_packages = data.packages;
						product_packages = product_packages.map(row=>{
							row.label=row.name+" ("+row.barcode+")";
							row.value=row.package_id;
							row.key=row.package_id;
							row.package=true;
							return row;
						});
						
						let rrs = data.rrs;
						rrs = rrs.map(row=>{
							row.label=row.name+" ("+row.batch_uid+")";
							row.value=row.id;
							row.key=row.id;
							row.package=false;
							return row;
						});
						
						let packages_dropdown = [...product_packages,...rrs]

						this.setState({
							consolidations,
							product_packages,
							rrs,
							packages_dropdown,
							package_uid:null, 
							in_house_weight_lbs_val:"",
							in_house_weight_gram_val:""
						});
					}else{
						swal("Warning!","Failed consolidate stock to vessel","warning");
					}
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}else{
				swal("Warning!","Please select a stock/RR","warning");
			}
		}else{
			swal("Warning!","Please select a vessel","warning");
		}
	}
	
	removeConsolidation(biomass_id){
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('biomass_id',biomass_id);
		
		axios.post(REST_API_END_POINT+'processor/remove-consolidation',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				var product_packages = data.packages;
				product_packages = product_packages.map(row=>{
					row.label=row.name+" ("+row.barcode+")";
					row.value=row.package_id;
					row.key=row.package_id;
					row.package=true;
					return row;
				});
				
				let rrs = data.rrs;
				rrs = rrs.map(row=>{
					row.label=row.name+" ("+row.batch_uid+")";
					row.value=row.id;
					row.key=row.id;
					row.package=false;
					return row;
				});
				
				let packages_dropdown = [...product_packages,...rrs]
				
				var consolidations = this.state.consolidations.filter(row=>{ return row.biomass_id!=biomass_id });
				this.setState({
					consolidations,
					product_packages,
					rrs,
					packages_dropdown
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	completeConsolidation(){
		var total_grams=0;
		this.state.consolidations.map((consolidation,index)=>{			
			total_grams+=parseFloat(consolidation.in_house_weight);
		});
		
		if(total_grams!=0){
			this.setState({ step:2 });
		}else{
			swal("Warning!","Please consolidate at least one stock","warning");
		}
		/*this.setState({
			consolidations:[],
			container_uid:null,
			container_capacity:0,
			in_house_weight_lbs_val:"",
			in_house_weight_gram_val:"",
			package_uid:null,
		});*/
	}
	
	handleSubmit(event, errors, values) {
		return;
	}
	
	handleChangeBatchStartTime = date => {
		if(date!=null && this.state.batchEndTime!=null){
			if(date.unix()>this.state.batchEndTime.unix()){
				swal("Warning!", "The start time should be less than or equal to end time!", "warning");				
				return;
			}
		}
		this.setState({
		  batchStartTime: date
		});
	};
	
	handleChangeBatchEndTime = date => {
		if(date!=null && this.state.batchStartTime!=null){
			if(date.unix()<this.state.batchStartTime.unix()){
				swal("Warning!", "The end time should be less than or equal to start time!", "warning");				
				return;
			}
		}
		this.setState({
		  batchEndTime: date
		});
	};
	

	addFinishedProduct = () =>{
		let finishedProducts = this.state.finishedProducts;
		let finishedProduct = JSON.parse(JSON.stringify(finishedStruct));
		finishedProduct.id=uuid();
		finishedProducts.push(finishedProduct);
		this.setState({ finishedProducts });
	}
	
	removeFinishedProduct = (id) =>{
		let finishedProducts = this.state.finishedProducts;
		finishedProducts = finishedProducts.filter(row=>{ return row.id!==id });
		this.setState({ finishedProducts });
	}
	
	updateFinishedProduct = (id,key,value) => {
		if(key==='product_id'){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('product_id',value.value);
			axios.post(REST_API_END_POINT+'processor/get-variations',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var product_variations = data.product_variations;
					product_variations = product_variations.map(row=>{
						row.label=row.variation_name;
						row.value=row.variation_id;
						row.key=row.variation_id;
						return row;
					});
					
					let finishedProducts = this.state.finishedProducts;
					finishedProducts = finishedProducts.map(row=>{
						if(row.id==id){
							row[key] = value;
							row.variation_id = null;
							row.variations = product_variations;
						}
						return row;
					});
					this.setState({ finishedProducts });
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}else{
			let finishedProducts = this.state.finishedProducts;
			finishedProducts = finishedProducts.map(row=>{
				if(row.id==id){
					row[key] = value;
				}
				return row;
			});
			this.setState({ finishedProducts });
		}
	};

	renderFinishedProducts = () => {
		const { submitted } = this.state;
		let finishedProducts = [];
		if(this.state.finishedProducts.length>0){
			finishedProducts = this.state.finishedProducts.map((finished,index)=>{
				return (<Row className="align-items-center mb-2" key={index}>
							<Colxx md="4">
								<AvGroup className="error-t-negative">
									<Label>Product<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"product_id"+index}
										id={"product_id"+index}
										placeholder="-- Select Product --"
										value={finished.product_id}						
										options={this.state.products}
										onChange={product_id => { 
											this.updateFinishedProduct(finished.id,'product_id',product_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select product</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="4">
								<AvGroup className="error-t-negative">
									<Label>Variation<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"variation_id"+index}
										id={"variation_id"+index}
										placeholder="-- Select Variation --"
										value={finished.variation_id}						
										options={finished.variations}
										onChange={variation_id => { 
											this.updateFinishedProduct(finished.id,'variation_id',variation_id);
										}}
									/>
									{submitted ? (finished.product_id==null ? <span className="text-danger">Please select variation</span>:""):""}
								</AvGroup>
							</Colxx>
							<Colxx md="3">
								<AvGroup className="error-t-negative">
									<Label>Quantity<span className="text-danger">*</span></Label>
									<AvField
										id={"f_quantity_"+index}
										name={"f_quantity_"+index}
										type="number"												
										placeholder="(Unit)"
										value={finished.quantity}
										onChange={e => { 
											this.updateFinishedProduct(finished.id,'quantity',e.target.value);
										}}
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter the quantity"
											},
											min: {
												value: 0,
												errorMessage: "Quantity must be greater than 0"
											},
											step: {
												value: 1,
												errorMessage: "Quantity must be a whole number"
											}
										}}
									/>									
								</AvGroup>
							</Colxx>
							<Colxx md="1">
								<span className="text-danger" onClick={()=>{ this.removeFinishedProduct(finished.id) }}><i className="fas fa-2x fa-times" /></span>
							</Colxx>	
						</Row>)
			})
		}
		return finishedProducts;
	};
	
	submitRawMaterialStaging = () => {
		this.setState({ submitted:true })
		if(this.state.batchStartTime==null || this.state.batchEndTime==null || this.state.finishedProducts.length==0){
			if(this.state.finishedProducts.length==0){
				swal("Warning!","Please choose at least one product","warning");
			}
			return false;
		}
		let inValidfinishedProducts = this.state.finishedProducts.filter(row=>{
			return row.product_id==null || row.variation_id==null || row.quantity==0 || row.quantity==""
		});
		if(inValidfinishedProducts.length>0){
			return false;
		}
		
		var facility_id = localStorage.getItem('facility_id');
		var user_id = localStorage.getItem('user_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		formData.append('user_id',user_id);
		formData.append('container_id',this.state.container_uid.value);
		formData.append('batch_start_time',this.state.batchStartTime.format("YYYY-MM-DD HH:mm:ss"));
		formData.append('batch_end_time',this.state.batchEndTime.format("YYYY-MM-DD HH:mm:ss"));
		formData.append('waste',this.state.waste);
		formData.append('rr',this.state.rr);
		
		let total_grams = parseFloat(this.state.consolidations.map(r=>r.in_house_weight).reduce((total,in_house_weight)=>{ return parseFloat(total)+parseFloat(in_house_weight) }));
		formData.append('container_qty',total_grams);
		
		let finishedProducts = JSON.parse(JSON.stringify(this.state.finishedProducts)).map(row=>{
			row.product_id = row.product_id.value;
			row.variation_id = row.variation_id.value;
			delete row.id;
			delete row.variations;
			return row;
		});
		
		formData.append('finished_products',JSON.stringify(finishedProducts));
		axios.post(REST_API_END_POINT+'processor/finish-raw-material-staging',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				swal("Success!",data.msg,"success").then(()=>{
					window.location.reload();
				});
			}else{
				swal("Warning!",data.msg,'warning')
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	};
	
	
	
	render() {
		const { containers, product_packages, packages_dropdown, consolidations, container_capacity, submitted } = this.state;
		var total_grams=0;
		var total_container_weight_lbs=0;
		var total_container_weight_gram=0;
		
		var final_consolidations=consolidations.map((consolidation,index)=>{			
			total_grams+=parseFloat(consolidation.in_house_weight);
			return(<Row className="align-items-center mb-3" key={index}>
						<Colxx lg="4">
							<Input
								readOnly={true}
								value={consolidation.barcode}
							/>
						</Colxx>
						<Colxx lg="8">
							<Row className="align-items-center">
								<Colxx lg="10">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_lbs+" (Kilograms)"}
									/>
								</Colxx>
								<Colxx lg="5" className="d-none">
									<Input
										readOnly={true}
										value={consolidation.in_house_weight_gram+" (Grams)"}
									/>
								</Colxx>
								<Colxx lg="2">
									<span className="text-danger" onClick={()=>{ this.removeConsolidation(consolidation.biomass_id) }}><i className="fas fa-2x fa-times" /></span>
								</Colxx>
							</Row>
						</Colxx>	
					</Row>);
		})
		total_container_weight_lbs=parseFloat(parseFloat(total_grams/LBS_CONSTANT).toFixed(4));
		total_container_weight_gram=parseFloat(((total_grams/LBS_CONSTANT)%1)*LBS_CONSTANT).toFixed(5);
		
		var fill = 0;
		if(container_capacity!=0){
			fill = (total_grams/container_capacity)*100;
		}
		
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading_text="Raw Material Staging" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
			<AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				{this.state.step===1 ? <Row>
					<Colxx lg="12" className="mb-4">
						<Row className="processor-container-fill">
							<Colxx lg="10">
								<AvGroup className="error-t-negative">
									<Label>Enter/Scan Destination Vessel UID</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="container_uid"
										id="container_uid"
										placeholder="-- Select Container --"
										value={this.state.container_uid}						
										options={containers}
										onChange={container_uid => { 
											this.setState({ container_uid }); 
											if(container_uid!=null){
												this.getContainerDetails(container_uid.value);
											}
										}}
									/>
								</AvGroup>
							
								<Row>
									<Colxx lg="6" className="mb-4">
										<AvGroup className="error-t-negative">
											<Label>Enter/Scan Stock/RR UID To Consolidate</Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"						
												name="package_uid"
												id="package_uid"
												placeholder="-- Select Packages --"
												value={this.state.package_uid}						
												options={packages_dropdown}
												onChange={package_uid => { 
													if(package_uid!=null){
														let in_house_weight_lbs_val=parseFloat(parseFloat(package_uid.quantity/LBS_CONSTANT).toFixed(4));
														this.setState({ package_uid, in_house_weight_lbs_val })
													}else{
														this.setState({ package_uid })
													}
												}}
											/>
										</AvGroup>
									</Colxx>
									<Colxx lg="6" className="mb-4 form-group-mb-0">
										<AvGroup>
											<Label>In House Weight</Label>
											<AvField
												id="in_house_weight_lbs_val"
												name="in_house_weight_lbs_val"
												type="number"												
												placeholder="(Kilograms)"
												value={this.state.in_house_weight_lbs_val}
												onChange={e => { this.setState({ in_house_weight_lbs_val:e.target.value }) }}												
											/>
											<Label>Kilograms</Label>
										</AvGroup>
									</Colxx>						
									<Colxx lg="4" className="mb-4 d-none form-group-mb-0">
										<AvGroup>
											<Label>&nbsp;</Label>
											<AvField
												id="in_house_weight_gram_val"
												name="in_house_weight_gram_val"
												type="number"	
												placeholder="(Grams)"
												value={this.state.in_house_weight_gram_val}
												onChange={e => { this.setState({ in_house_weight_gram_val:e.target.value }) }}
											/>
											<Label>Grams</Label>
										</AvGroup>
									</Colxx>
								</Row>
								
								<Row className="text-center mb-4">
									<Colxx lg="6">
										<Button	color="success" block size="lg" className="mr-1" disabled={this.state.container_uid==null} onClick={this.addConsolidation} >Consolidate Next Stock To Vessel</Button> 
									</Colxx>
									<Colxx lg="6">
										<Button color="danger" type="button" block size="lg" className="mr-1" disabled={this.state.container_uid==null} onClick={this.completeConsolidation} >Complete Vessel Consolidation</Button>
									</Colxx>
								</Row>
								
								<div>
								{final_consolidations}
								</div>
								
								<Row className="align-items-center">
									<Colxx lg="4">
										<Label>Total Vessel Weight </Label>
									</Colxx>
									<Colxx lg="8">
										<Row className="align-items-center">
											<Colxx lg="10">																	
												<Input
													readOnly={true}
													value={total_container_weight_lbs+" (Kilograms)"}
												/>
											</Colxx>
											<Colxx lg="5" className="d-none">
												<Input
													readOnly={true}
													value={total_container_weight_gram+" (Grams)"}
												/>
											</Colxx>
										</Row>
									</Colxx>
								</Row>
							</Colxx>
							<Colxx lg="2">
								<div className="meter animate">
									<span style={{height: (fill*3.52)+"px"}}><span></span></span>
								</div>
								
								<h2 className="text-center mt-3 mb-0" id="container_filled">{number_format(parseFloat(fill),2,'.','')}%</h2>
								<p className="text-center mb-0" style={{fontSize:'14px'}}>is occupied</p>
								<p className="text-center mb-2" style={{fontSize:'14px'}}>Capacity: <span>{parseInt(container_capacity/LBS_CONSTANT)+' Kilograms '}</span></p>
							</Colxx>
							
						</Row>

					</Colxx>	 
				</Row>:<Row>
					<Colxx lg="12" className="mb-4">
						<Row className="processor-container-fill">
							<Colxx lg="12">
								<Row className="align-items-center">
									<Colxx lg="12">
										<AvGroup>
											<Button color="primary" type="button" onClick={()=>{ this.setState({ step:1 }); }} >Back</Button>
										</AvGroup>
									</Colxx>
								</Row>
								<Row className="align-items-center">
									<Colxx lg="6">
										<AvGroup>
											<Label>Total Vessel Weight </Label>
										</AvGroup>
									</Colxx>
									<Colxx lg="6">
										<AvGroup>
											<Input
												readOnly={true}
												value={total_container_weight_lbs+" (Kilograms)"}
											/>
										</AvGroup>
									</Colxx>
								</Row>
								
								<Row >
									<Colxx lg="6">
										<AvGroup>
											<Label>Batch Start Time<span className="text-danger">*</span></Label>
											<DatePicker
												id="batch_start_time"
												name="batch_start_time"
												autoComplete="off"
												className="mb-2"
												maxDate={moment()}
												value={this.state.batchStartTime}
												placeholderText=""
												dateFormat="DD-MM-YYYY"
												timeFormat="hh:mm a"
												onChange={this.handleChangeBatchStartTime}
											  />
											  {submitted ? (this.state.batchStartTime==null ? <span className="text-danger">Please select batch start time</span>:""):""}
										</AvGroup>
									</Colxx>
									<Colxx lg="6">
										<AvGroup>
											<Label>Batch End Time<span className="text-danger">*</span></Label>
											<DatePicker
												id="batch_end_time"
												name="batch_end_time"
												autoComplete="off"
												className="mb-2"
												maxDate={moment()}
												value={this.state.batchEndTime}
												placeholderText=""
												dateFormat="DD-MM-YYYY"
												timeFormat="hh:mm a"
												onChange={this.handleChangeBatchEndTime}
											  />
											{submitted ? (this.state.batchEndTime==null ? <span className="text-danger">Please select batch end time</span>:""):""}
										</AvGroup>
									</Colxx>
								</Row>
								
								<div className="mb-4">
									{this.renderFinishedProducts()}
									
									<Button color="primary" type="button" onClick={this.addFinishedProduct}>
										Add another finished product
									</Button>
								</div>
								
								<Row className="align-items-center">
									<Colxx lg="6" className="form-group-mb-0">
										<AvGroup>
											<Label>Waste</Label>
											<AvField
												id="waste"
												name="waste"
												type="number"												
												placeholder="(Gram)"
												value={this.state.waste}
												onChange={e => { this.setState({ waste:e.target.value }) }}												
											/>
											<Label>Grams</Label>
										</AvGroup>
									</Colxx>
									<Colxx lg="6" className="form-group-mb-0">
										<AvGroup>
											<Label>RR</Label>
											<AvField
												id="rr"
												name="rr"
												type="number"												
												placeholder="(Gram)"
												value={this.state.rr}
												onChange={e => { this.setState({ rr:e.target.value }) }}												
											/>
											<Label>Grams</Label>
										</AvGroup>
									</Colxx>
								</Row>
								
								<Row className="align-items-center">
									<Colxx lg="12">
										<Button	color="primary" onClick={this.submitRawMaterialStaging} >Submit</Button> 
									</Colxx>
								</Row>
							</Colxx>							
						</Row>
					</Colxx>	 
				</Row>}						
			</AvForm>
		</Card>
		
      </Fragment>
    );
  }
}
