import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label,
	Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';


import { BASE_URL } from "../../constants/defaultValues";

import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import number_format from "number_format-php";
var default_logo;
export default class InvoiceModalContent extends Component {

	render() {
		const { order_data, order_products, facility_data, currencies, additionalRequirementsData } = this.props;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax = 0;
		var sgst_tax = 0;
		var cgst_tax = 0;
		var sub_total = 0;
		var p_discount = 0;
		let is_utgst = false;
		let is_igst = true;
		if (order_data != null) {
			var current_currency = currencies.find(row => { return row.currency_code == order_data.currency_code });
			if (current_currency != null) {
				//current_currency_symbol = current_currency.currency_symbol;
			}

			/*is_igst = order_data.customer_state_id !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}*/
		}
		if (order_products != null) {
			order_products.map((item, index) => {
				if (item.filter) {
					item.filter.map(row => {
						sales_tax += parseFloat(row.product_sales_tax);
						sgst_tax += parseFloat(row.product_sgst_tax);
						cgst_tax += parseFloat(row.product_cgst_tax);
						p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

						let line_total = parseFloat(row.unitPrice) * Math.ceil(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

						if (row.variation_id == order_data.discount_variation_id) {
							line_total -= parseFloat(order_data.manual_discount);
						}
						sub_total += parseFloat(row.unitPrice) * Math.ceil(row.product_qty);
					});
				}
			});

		}
		let additionalTotal = 0;

		return (
			<Row className="invoice-react">
				<Colxx xxs="12" className="">

					{facility_data != null ? (facility_data.facility_logo != null && facility_data.facility_mobile_logo != null) ?
						default_logo = <div className="mb-0 d-flex  justify-content-center " >
							<a href="/">
								<img src={BASE_URL + 'pages/' + facility_data.facility_logo} className="mb-0" style={{ width: 200 }} />
							</a>
						</div>
						: "" : ""}




					<div className="mb-5 invoice-contents">

						<CardBody className="d-flex flex-column justify-content-between">
							<div className="d-flex flex-column">
								<div className="d-flex flex-row justify-content-between pt-2 pb-2">
									<div className="">
										<div className="font-weight-bold h5">{facility_data != null ? facility_data.facility_name : ""}</div>
										{facility_data != null ? (facility_data.facility_address != null ? <p className="mb-0">{facility_data.facility_address}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_phone != null ? <p className="mb-0">{facility_data.facility_phone}</p> : "") : ""}
										{facility_data != null ? (facility_data.facility_email != null ? <p className="mb-0">{facility_data.facility_email}</p> : "") : ""}
										{/* {facility_data != null ? (facility_data.gstin != null ? <p className="mb-0">TRN: {facility_data.gstin}</p> : "") : ""} */}
									</div>
									{order_data != null ? <div className="w-40 justify-content-end text-right ">
										<div className="font-weight-bold text-uppercase h4">{this.props.orderTypeData == "Enquiry" ? "Enquiry" : "Estimate"}</div>
										<p className="mb-0">
											{this.props.orderTypeData == "Enquiry" ? "ENQUIRY" : "ESTIMATE"} # {moment(order_data.timestamp * 1000).format('YYYY')}/{zeroPad(order_data.order_number, 6)}<br />
											DATE: {moment(order_data.timestamp * 1000).format("MMMM DD, YYYY")}
											{order_data.status == 4 ? <p className="mb-1 text-danger">Status: Canceled</p> : ""}
										</p>
									</div> : ""}
								</div>
								<div className="border-bottom pt-4 mb-5" />

								{order_data != null ? <div className="d-flex flex-row  mb-5">
									<div className="d-flex flex-column w-100 bg-semi-muted">
										<div className="font-weight-bold h5 mt-2">Client Info:</div>
										<Table borderless className="d-flex justify-content-start ml-4">
											<tbody>
												<tr>
													<td className="pb-0 text-right ml-5  ">Client Name:</td>
													<td className="text-left pb-0 ">{order_data.customer_name ? order_data.customer_name : ""}</td>
												</tr>
												<tr>
													<td className="pb-0 text-right ml-5 ">Project:</td>
													<td className="text-left pb-0">{order_data.project ? order_data.project : ""}</td>
												</tr>
												<tr>
													<td className="pb-0  text-right ml-5 ">Address:</td>
													<td className="text-left pb-0">{order_data.billing_address != null ? <Fragment>{order_data.billing_address}<br /></Fragment> : ""}</td>
												</tr>
											</tbody>
										</Table>
										{/* <p className="mb-0">
										Client Name:{order_data.customer_name?order_data.customer_name:""}<br />
										Project:{order_data.project?order_data.project:""}<br />
										Address:{order_data.billing_address != null ? <Fragment>{order_data.billing_address}<br /></Fragment> : ""}
										 {order_data.customer_phone != null ? <Fragment>Tel: {order_data.customer_phone}</Fragment> : ""} 
									</p> */}
									</div>
								</div> : ""}

								{order_data != null ? <Table borderless>
									<thead>
										<tr>
											<th className="mb-2">#</th>
											<th className="mb-2 text-left">Item</th>
											<th className="mb-2 text-left">Size</th>
											<th className="mb-2 text-left">Unit Price</th>
											<th className="mb-2 text-left">SQFT/Quantity</th>
											{/* <th className="mb-2 text-right">Price</th>
												
												{is_igst ? <th className="mb-2 text-right">TAX</th>:<Fragment>
													<th className="mb-2 text-right">{is_utgst ? "UTGST":"SGST"}</th>
													<th className="mb-2 text-right">CGST</th>
												</Fragment>} */}
											{/*<th className="mb-2 text-right">Discount</th>*/}
											<th className="mb-2 text-left">Total</th>
										</tr>
									</thead>
									<tbody>
										{order_products.map((row, index) => {
											// sales_tax+=parseFloat(row.product_sales_tax);
											// sgst_tax+=parseFloat(row.product_sgst_tax);
											// cgst_tax+=parseFloat(row.product_cgst_tax);
											// p_discount+=parseFloat(row.product_discount)+parseFloat(row.coupon_discount);

											// let line_total=parseFloat(row.unitPrice)*Math.ceil(row.product_qty)+(parseFloat(row.product_excise_tax)+parseFloat(row.product_sales_tax)-parseFloat(row.product_discount)-parseFloat(row.coupon_discount))

											// if(row.variation_id==order_data.discount_variation_id){
											// 	line_total-=parseFloat(order_data.manual_discount);
											// }
											// sub_total+=parseFloat(row.unitPrice)*Math.ceil(row.product_qty);
											return (<>
												<tr style={{ background: "#46bdc6" }}>
													<td>{index + 1}</td>
													<td>{row.category_name} </td>
													<td></td>
													<td></td>
													<td></td>
													<td></td>
												</tr>
												{row.filter.map((raw) => (
													<tr>
														<td style={{ border: 'none' }}></td>
														<td>{raw.product_name} {raw.variation_name != null ? "(" + raw.variation_name + ")" : ""}</td>
														<td>{raw.sqft == 1 ? <div>{parseInt(raw.length)}*{parseInt(raw.breadth)}*{parseInt(raw.height)}</div> : ""}</td>
														<td className=" text-left">{current_currency_symbol}{"" + number_format(parseFloat(raw.unitPrice), 2, '.', '')}</td>
														<td className=" text-center">{Math.ceil(raw.product_qty)}</td>
														{/* <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat((parseFloat(row.unitPrice)*Math.ceil(row.product_qty)).toFixed(2)),2,'.','')}</td> 
												 {is_igst ? <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sales_tax),2,'.','')}</td>:<Fragment>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sgst_tax),2,'.','')}</td>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_cgst_tax),2,'.','')}</td>
												</Fragment>}  */}
														{/*<td className="text-right">{current_currency_symbol}{""}{row.variation_id!=order_data.discount_variation_id ? number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount),2,'.',''):number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount)+parseFloat(order_data.manual_discount),2,'.','')}</td>*/}
														<td className=" text-left">{current_currency_symbol}{"" + number_format(parseFloat(raw.unitPrice) * Math.ceil(raw.product_qty) + (parseFloat(raw.product_excise_tax) + parseFloat(raw.product_sales_tax) - parseFloat(raw.product_discount) - parseFloat(raw.coupon_discount)), 2, '.', '')}</td>
													</tr>
												))}
											</>)
										})}
									</tbody>
								</Table> : ""}
							</div>
							{order_data != null ? <div className="d-flex flex-column">
								<div className="border-bottom pt-3 mb-2" />
								<Table borderless className="d-flex justify-content-end">
									<tbody>
										{/* <tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sub_total, 2, '.', '')}</td>
										</tr> */}

										{/* {is_igst ? <tr>
											<td className="pb-0">Tax ({order_data.tax_percentage}%) :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
										</tr> : <Fragment>
											<tr>
												<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
											</tr>
											<tr>
												<td className="pb-0">CGST :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
											</tr>
										</Fragment>} */}
										{parseFloat(order_data.return_amount) != 0 ? <tr className="font-weight-bold">
											<td className="pb-0">Refund :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.return_amount), 2, '.', '')}</td>
										</tr> : ""}
										<tr className="font-weight-bold">
											<td className="pb-0"> Total :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(p_discount), 2, '.', '')}</td>
										</tr>
									</tbody>
								</Table>
								{additionalRequirementsData.length > 0 ?
									<>
										<h4>Additional Requirements</h4>
										<Table borderless>
											<thead>
												<tr>
													<th className="mb-2">#</th>
													<th className="mb-2 text-left">Item</th>
													<th className="mb-2 text-left">Quantity</th>
													<th className="mb-2 text-left">Price</th>
													<th className="mb-2 text-left">Amount</th>

												</tr>
											</thead>
											<tbody>
												{additionalRequirementsData.map((row, index) => {
													additionalTotal += parseFloat(row.amount);
													return (<>
														<tr >
															<td>{index + 1}</td>
															<td>{row.name} </td>
															<td>{row.qty}</td>
															<td>{row.price}</td>
															<td>{row.amount}</td>

														</tr>


													</>)
												})}
											</tbody>
										</Table>
										<div className="d-flex flex-column">
											<div className="border-bottom pt-3 mb-2" />
											<Table borderless className="d-flex justify-content-end">
												<tbody>
													{/* <tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(additionalTotal, 2, '.', '')}</td>
										</tr> */}

													{/* {is_igst ? <tr>
											<td className="pb-0">Tax ({order_data.tax_percentage}%) :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
										</tr> : <Fragment>
											<tr>
												<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
											</tr>
											<tr>
												<td className="pb-0">CGST :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
											</tr>
										</Fragment>} */}

													<tr className="font-weight-bold">
														<td className="pb-0">Total :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
													</tr>
													<tr className="font-weight-bold">
														<td className="pb-0">Grand Total :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) + parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</> : ""}
								<div className="border-bottom pt-2 mb-3" />

								{order_data['notes'] ?
									<div>
										<div class="font-weight-bold h6">
											Notes<br />
										</div>
										<div dangerouslySetInnerHTML={{ __html: order_data['notes'] }} />
									</div>
									: ""}
								<div class="font-weight-bold h5">
									Terms and Conditions
								</div>


								<div>
									<p>1. Payment - 50% advance + 40% during project + 10% during hand over</p>
									<p>2. Union and other site related issues should take care by client itself</p>
									<p>3. Electricity and adequate workspace should provide by client side</p>
									<p>4. Quoted amount valid for 30 days only. </p>
									<p>5.Extra works than in quote will be charged extra.</p>

									<br></br>
									We trust our quotation proves of you.
									Thanks for showing interest in Textra interiors andexteriors. For more enquiries call on 9947 531 970
								</div>

							</div> : ""}

						</CardBody>
					</div>
				</Colxx>
			</Row>
		);
	}
};
