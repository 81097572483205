import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class EditCommentModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {		
			comment:null
		};
	
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			let comment; 					
            if (prevProps.comment_id !== this.props.comment_id && this.props.comment_id!=0) {				
				var formData = new FormData();
				formData.append('comment_id',this.props.comment_id);
				axios.post(REST_API_END_POINT+'complaint/get-comment-by-id',formData).then((res)=>{					
					var data = res.data;					
					comment=data.comment;
					
					this.setState({ 
						comment
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
            }
        }
    }
		
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-comment").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("editCommentForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('comment_id',this.props.comment_id);
			
			axios.post(REST_API_END_POINT+'complaint/edit-comment',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("update-comment").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-comment").disabled=false;
			});

		}
	}
	
	
	render() {	
		if(this.state.comment!=null){
			var comment=this.state.comment;
		}else{
			var comment;
		}
	  
	  return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Edit Comment
			</ModalHeader>
			<AvForm
		        id="editCommentForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
				<ModalBody>			
					<Row className="mt-4">
						<Colxx lg="12" className="mb-2">	
							<AvGroup>
								<Label>Comment Body<span className="text-danger">*</span></Label>
								<AvField 
									name="body" 
									type="textarea" 
									autoComplete="off"
									rows="6"
									value={ comment?((comment.body!=null)?comment.body:''):'' }
									validate={{
									  required: {
										value: true,
										errorMessage: "Please enter the  message"
									  },
									  minLength: {
										value: 2,
										errorMessage:
										  "The message must have atleast 2 characters"
									  }
									}}
								/>
							</AvGroup>
						</Colxx>
					</Row>				
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-comment">
						<IntlMessages id="customer.save" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
	}
};
