import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import pageManagement from './page-management';
import edit from './edit';
import postManagement from './post-management';
import testimonialManagement from './testimonial-management';
import homeSliderManagement from './home-slider-management';
import footerManagement from './footer-management';
import contactForm from './contact-form';
import contactFormEntries from './contact-form-entries';
import Settings from './settings';
import menu from './menu';

const Reports = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
            <Route exact path={`${match.url}`} component={pageManagement} />
            <Route path={`${match.url}/pages`} component={pageManagement} />
            <Route path={`${match.url}/edit-page/:id`} component={edit} />   
            <Route path={`${match.url}/posts`} component={postManagement} />          
            <Route path={`${match.url}/testimonials`} component={testimonialManagement} />          
            <Route path={`${match.url}/home-sliders`} component={homeSliderManagement} />          
            <Route path={`${match.url}/footer-management`} component={footerManagement} />          
            <Route path={`${match.url}/contact-form`} component={contactForm} />          
            <Route path={`${match.url}/contact-form-entries`} component={contactFormEntries} />          
            <Route path={`${match.url}/settings`} component={Settings} />          
            <Route path={`${match.url}/menu`} component={menu} />          
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Reports;