import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import {	
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
  const selectSource = [
    { label: "Pound", value: "1", key: 1 },
    { label: "KG", value: "2", key: 2},
    
  ];
const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);
class Nutrients extends Component {
  constructor(props) {
    super(props);
    this.mouseTrap = require("mousetrap");
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dataListRender = this.dataListRender.bind(this);
    this.state = {
    
    };
  }

  handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-Nutrients").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantNutrientsForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant',this.props.plant_id);
			

			axios.post(REST_API_END_POINT+'growhouse/add-plantNutrients',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_nutrients");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				this.dataListRender();
				document.getElementById("add-Nutrients").disabled=false;
				
				this.form && this.form.reset();			

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-Nutrients").disabled=false;
			});

		}
	}
  

    componentDidMount(){
		
		this.dataListRender();
	   
	}

	
    dataListRender() {
		
		var nutirentDetails=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('plant_id',this.props.plant_id);
		axios.post(REST_API_END_POINT+'growhouse/plant-nutrient-details',formData).then((res)=>{
			var data = res.data;
			
				nutirentDetails=data.nutirentDetails;

			if(data.nutirentDetails){
				this.setState({
			        nutirentDetails: data.nutirentDetails,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					nutirentDetails: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}

  render() {
	   let data = this.state.nutirentDetails 
	  const {selectAdditives} = this.state;  
	  
	  
    const dataTableColumns = [			  
	  {
		Header: "Serving Size",
		accessor: "servingsize",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Calories",
		accessor: "calories",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  	  {
		Header: "Caloriesfromfat",
		accessor: "caloriesfromfat",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	    {
		Header: "Totalfat",
		accessor: "totalfat",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	      {
		Header: "Saturatedfat",
		accessor: "saturatedfat",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	   	  {
		Header: "Cholestrol",
		accessor: "cholestrol",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	    {
		Header: "Sodium",
		accessor: "sodium",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	      {
		Header: "Totalcarbs",
		accessor: "totalcarbs",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	    	  {
		Header: "Dietryfiber",
		accessor: "dietryfiber",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	    {
		Header: "Sugars",
		accessor: "sugars",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	      {
		Header: "Protien",
		accessor: "protien",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	      	  {
		Header: "VitaminaA",
		accessor: "vitamina",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	    {
		Header: "Vitaminc",
		accessor: "vitaminc",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	      {
		Header: "Calcium",
		accessor: "calcium",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	     {
		Header: "Iron",
		accessor: "iron",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	      {
		Header: "Formula Notes",
		accessor: "formulanotes",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	];	
    return( 
      <Fragment>       
        <div className="p-4">		
			<div id="api_message_nutrients"></div>		
		  <div className="mb-4 dropdown-edit">
			{this.props.user_privileges.indexOf(148)!==-1 ? <AvForm
		        id="plantNutrientsForm"
				ref={c => (this.form = c)}
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			  	<Row className="mt-2">
					<Colxx xxs="4">	
						<AvGroup className="error-t-negative">	
                        <Label>Serving Size<span className="text-danger">*</span></Label>						
							<AvField
								id="servingsize"
								name="servingsize"
								type="text"	
                                validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}								
							/>
						 </AvGroup>
					</Colxx>
					<Colxx xxs="4">	
						<AvGroup className="error-t-negative">	
                        <Label>Calories<span className="text-danger">*</span></Label>						
							<AvField
								id="calories"
								name="calories"
								type="text"	
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							/>
						 </AvGroup>
					</Colxx>
					<Colxx xxs="4">	
						<AvGroup className="error-t-negative">	
							<Label>Calories From Fat<span className="text-danger">*</span></Label>						
								<AvField
									id="caloriesfromfat"
									name="caloriesfromfat"
									type="text"
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
						 </AvGroup>
					</Colxx>
				</Row>
				<Row className="mt-2">
					<Colxx lg="4">
						<AvGroup className="error-t-negative">
						  <Label>Total Fat(g)<span className="text-danger">*</span></Label>
						  <AvField
							id="totalfat"
							name="totalfat"
							type="number"
							value=""
							validate={{
								required: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="4">
						<AvGroup className="error-t-negative">
						  <Label>Saturated Fat(g)<span className="text-danger">*</span></Label>
						  <AvField
							id="saturatedfat"
							name="saturatedfat"
							type="number"
							value=""
							validate={{
								required: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="4">
						<AvGroup className="error-t-negative">
						  <Label>Cholestrol(g)<span className="text-danger">*</span></Label>
						  <AvField
							id="cholestrol"
							name="cholestrol"
							type="number"
							value=""
							validate={{
								required: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>	
				<Row className="mt-2">
						<Colxx lg="4">
							<AvGroup className="error-t-negative">
								<Label>Sodium(g)<span className="text-danger">*</span></Label>
								  <AvField
									id="sodium"
									name="sodium"
									type="number"
									value=""
									validate={{
										required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
								  />
							</AvGroup>
						</Colxx>
						<Colxx lg="4">
							<AvGroup className="error-t-negative">
							  <Label>Total Carbs(g)<span className="text-danger">*</span></Label>
							  <AvField
								id="totalcarbs"
								name="totalcarbs"
								type="number"
								value=""
								validate={{
									required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="4">
							<AvGroup className="error-t-negative">
								<Label>Dietry Fiber(g)<span className="text-danger">*</span></Label>
								<AvField
									id="dietryfiber"
									name="dietryfiber"
									type="number"
									value=""
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
							</AvGroup>
						</Colxx>
                </Row>
                <Row className="mt-2">				
						<Colxx lg="4">
							<AvGroup className="error-t-negative">
							  <Label>Sugar(g)<span className="text-danger">*</span></Label>
							  <AvField
								id="sugars"
								name="sugars"
								type="number"
								value=""
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="4">
							<AvGroup className="error-t-negative">
							  <Label>Protein(g)<span className="text-danger">*</span></Label>
							  <AvField
								id="protien"
								name="protien"
								type="number"
								value=""
								validate={{
									required: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
						<Colxx xxs="4">	
							<AvGroup className="error-t-negative">	
								<Label>Vitamin A<span className="text-danger">*</span></Label>						
									<AvField
										id="vitaminA"
										name="vitaminA"
										type="text"
										validate={{
											required: {
												value: true,
												errorMessage: "This field is required."
											}
										}}
									/>
							 </AvGroup>
						</Colxx>
				</Row>
				<Row className="mt-2">
				
					<Colxx xxs="4">	
						<AvGroup className="error-t-negative">	
							<Label>Vitamin C<span className="text-danger">*</span></Label>						
							<AvField
								id="vitaminC"
								name="vitaminC"
								type="text"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							/>
						 </AvGroup>
					</Colxx>
					<Colxx xxs="4">	
						<AvGroup className="error-t-negative">	
							<Label>Calcium<span className="text-danger">*</span></Label>						
							<AvField
								id="calcium"
								name="calcium"
								type="text"	
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							/>
						 </AvGroup>
					</Colxx>
					<Colxx xxs="4">	
						<AvGroup className="error-t-negative">	
							<Label>Iron<span className="text-danger">*</span></Label>						
								<AvField
									id="iron"
									name="iron"
									type="text"	
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
							 </AvGroup>
					</Colxx>
				</Row>
				<Row className="mt-2">	
						<Colxx xxs="6">	
						<AvGroup className="error-t-negative">	
                        <Label>Formula Notes<span className="text-danger">*</span></Label>						
							<AvField
								id="formulanotes"
								name="formulanotes"
								type="text"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}
							/>
						 </AvGroup>
					</Colxx>
				</Row>
				<Row className="mt-2 mb-4">
		
				<Colxx xxs="6">
					<Button
					    id="add-Nutrients"
						color="primary"
						size="sm"
						className=""
						>
						Add 
					</Button>					
				</Colxx>					
			</Row>
			</AvForm>:""}
			<hr/>
				<ReactTable
				  data={data}
				  TbodyComponent={CustomTbodyComponent}
				  columns={dataTableColumns}
				  defaultPageSize={5}
				  showPageJump={false}
				  showPageSizeOptions={true}
				  showPagination={true}
				  PaginationComponent={DataTablePagination}
				/>
			</div>
		</div>	         
      </Fragment>
    );
  }
  
};
export default Nutrients;
