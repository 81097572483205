const groupList = [
{
    id: 1,
    first_name: "Volkan",
    last_name: "Tazegül",
	email: "volkan.tazegül@example.com",
    phone: "(320)-301-1432",
    img:"/assets/img/men/96.jpg",
    address:"Cecilia Chapman 711-2880 Nulla St.",
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 2,
    first_name: "Leo",
    last_name: "Pierce",
	email: "leo.pierce@example.com",
    phone: "0737-232-385",
    img:"/assets/img/men/8.jpg",
    address:"Mankato Mississippi 96522 (257) 563-7401",
    clients_agent:1,
    sellers_agent:1,
	dispensary:"Phinest Tissue Culture",
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 3,
    first_name: "Nanna",
    last_name: "Pedersen",
	email: "nanna.pedersen@example.com",
    phone: "43672992",
    img:"/assets/img/women/68.jpg",
    address:"Frederick Nebraska 20620 (372) 587-2335",
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 4,
    first_name: "Amelia",
    last_name: "Mercier",
	email: "amelia.mercier@example.com",
    phone: "(168)-747-5950",
    img:"/assets/img/women/91.jpg",
    address:"Calista Wise 7292 Dictum Av.",
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 5,
    first_name: "Sarah",
    last_name: "Oliver",
	email: "sarah.oliver@example.com",
    phone: "0761-814-654",
    img:"/assets/img/women/73.jpg",
    address:"Theodore Lowe Ap #867-859 Sit Rd.",
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  },
  {
    id: 6,
    first_name: "Sheryl",
    last_name: "Carr",
	email: "sheryl.carr@example.com",
    phone: "0731-134-689",
    img:"/assets/img/women/75.jpg",
    address:"Azusa New York 39531",
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 1,
      statusColor: "success",
    }
  },
  {
    id: 7,
    first_name: "Miguel",
    last_name: "Sanz",
	email: "miguel.sanz@example.com",
    phone: "636-912-353",
    img:"/assets/img/men/71.jpg",
    address:"San Antonio MI 47096",
    clients_agent:1,
    sellers_agent:1,
	dispensary:"Phinest Tissue Culture",
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  },
  {
    id: 8,
    first_name: "Benjamin",
    last_name: "Beck",
	email: "benjamin.beck@example.com",
    phone: "0459-316-814",
    img:"/assets/img/men/61.jpg",
    address:"Tamuning PA 10855",
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 2,
      statusColor: "danger",
    }
  },
  {
    id: 9,
    first_name: "Emeline",
    last_name: "Charles",
	email: "emeline.charles@example.com",
    phone: "(311)-279-7621",
    img:"/assets/img/women/90.jpg",
    address:"Corona New Mexico 08219",
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 2,
      statusColor: "danger",
    }
	},
   {
    id: 10,
    first_name: "Melany",
    last_name: "Wijngaard",
	email: "melany.wijngaard@example.com",
    phone: "(727)-033-9347",
    img:'/assets/img/women/70.jpg',
    address:'Muskegon KY 12482',
    clients_agent:1,
    sellers_agent:1,
	dispensary:	"Phinest Tissue Culture",
	status: {
      statusid: 1,
      statusColor: "success",
    }
	}
  
]

export default groupList
