import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, CardImg, Badge, Label } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import Select from "react-select";
import IntlMessages from "../../../helpers/IntlMessages";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { REST_API_END_POINT,themeColor } from "../../../constants/defaultValues";
import moment from 'moment';
import axios from 'axios';
import swal from 'sweetalert';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class UserReports extends Component {
	
	constructor(props) {
		super(props);

		this.dataListRender = this.dataListRender.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.exportToSpreadsheet = this.exportToSpreadsheet.bind(this);
		this.state = {
			blocking: false,
			subscription_id:{ label: "All", value: "", key: 1 },
			start_date:null,
			end_date:null,
			items:[],
			subscriptions:[{ label: "All", value: "", key: 1 }],
			exportData:[]
		};
	}
	
	componentDidMount() {
		this.dataListRender();
	}
	 
	dataListRender() {
		this.setState({ blocking: true });
		
		const {
			subscription_id,
			start_date,
			end_date
		} = this.state;
		
		var formData = new FormData();
		
		var item={};
		if(subscription_id!=null){
			if(subscription_id.value!=""){
				formData.append('subscription_id',subscription_id.value);
			}
		}
		
		if(start_date!=null){
			formData.append('start_date',moment(start_date.format('YYYY-MM-DD')+' 00:00:00').unix());
		}
		
		if(end_date!=null){
			formData.append('end_date',moment(end_date.format('YYYY-MM-DD')+' 23:59:59').unix());
		}
		axios.post(REST_API_END_POINT+'admin/subscribers-reports',formData).then(res => {
			return res.data;
		}).then(data => {
			var subscriptions=[{ label: "All", value: "", key: 1 }];
			for(let subscription of data.subscriptions){
				subscriptions.push({ label: subscription.name, value: subscription.id, key: subscription.id })
			}
			this.setState({
				items: data.subscribers,
				subscriptions:subscriptions,
				blocking: false
			});
		});
	}
	
	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
	
	exportToSpreadsheet = () => {
		var data = this.state.items;
		var data_export = [];
		
		for(let key in data){
			var row = data[key];
			data_export.push([parseInt(key)+1, row.first_name+" "+row.last_name, row.email, row.phone,row.subscriber_plan_name,parseInt(row.status)==1 ? "Active":"Inactive" ]);
		}
		var exportData = [{
			columns: [
				"#", 
				"Subscriber Name", 
				"Email", 
				"Phone", 
				"Plan", 
				"Status"
			],
			data: data_export
		}];
		
		this.setState({
			exportData: exportData
		},()=>{
			setTimeout(function(){
				document.getElementById('export_btn').querySelector("button").click();
			}, 500);
		});
	};
	
	
	render() {
		let data = this.state.items;
	 		
		const dataTableColumns = [
			{
				Header: "#",
				Cell: props => {
					return data.findIndex(r=>{ return r.id==props.original.id })+1
				}
			},
			{
				Header: "Subscriber Name",
				accessor: "first_name",
				Cell: props => <p className="list-item-heading">{props.original.first_name+" "+props.original.last_name}</p>
			},
			{
				Header: "Email",
				accessor: "email",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Phone",
				accessor: "phone",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Plan",
				accessor: "subscriber_plan_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => <Badge
								  color={parseInt(props.value)==1?"success":"danger"}
								  pill
								  className="badge-top-left"
								  style={{fontSize:'90%'}}
								>
								  {parseInt(props.value)===1?"Active":"Inactive"}
								</Badge>
			}
		];	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.user-reports" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row className="mb-4">			  
										<Colxx xxs="12">
											<AvForm
												id="payment_filter_form"
												className="av-tooltip tooltip-right-bottom"
												onSubmit={this.handleSubmit}
											  >
												<Row>
													<Colxx md="4">
														<AvGroup className="error-t-negative">
															<Label>Subscription Plan</Label>
															<Select
																className="react-select col-lg-12 p-0"
																classNamePrefix="react-select"
																name="subscription_id"
																placeholder="Select subscription plan"
																value={this.state.subscription_id}
																onChange={subscription_id=>{ this.setState({ subscription_id }) }}
																options={this.state.subscriptions}
															/>
														</AvGroup>
													</Colxx>
													<Colxx md="4">
														<AvGroup className="error-t-negative">
															<Label>Start Date</Label>
															<DatePicker
																className="mb-2"
																placeholderText="Start Date"
																dateFormat="MM/DD/YYYY"
																selected={this.state.start_date}
																onChange={start_date=>{ 
																	if(this.state.end_date!=null){
																		if(this.state.end_date<start_date){
																			swal("Warning!", "Start date should be less than end date.", "warning");
																			return;
																		}
																	}
																	this.setState({ start_date }) 
																}}
															/>
														</AvGroup>
													</Colxx>
													<Colxx md="4">
														<AvGroup className="error-t-negative">
															<Label>End Date</Label>
															<DatePicker
																className="mb-2"
																placeholderText="End Date"
																dateFormat="MM/DD/YYYY"
																selected={this.state.end_date}
																onChange={end_date=>{ 
																	if(this.state.start_date!=null){
																		if(this.state.start_date>end_date){
																			swal("Warning!", "End date should be greater than start date.", "warning");
																			return;
																		}
																	}
																	this.setState({ end_date }) 
																}}
															/>
														</AvGroup>
													</Colxx>
													<Colxx md="12">									 
														<Button color="primary" >
														  <IntlMessages id="pages.submit" />
														</Button>{" "}<Button color="primary" type="button" onClick={this.exportToSpreadsheet} >
															Export to Excel
														</Button>
													</Colxx>
												</Row>
										   </AvForm>
										</Colxx>
									</Row>
									<Row>			  
										<Colxx xxs="12">
											<div className="mb-4 dropdown-edit">					
												<ReactTable
													data={data}
													columns={dataTableColumns}
													defaultPageSize={10}
													filterable={false}
													showPageJump={false}
													PaginationComponent={DataTablePagination}
													showPageSizeOptions={true}
													defaultSorted={[{id: "x",asc: true}]}
												/>
											</div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
					<Row className="d-none" id="export_btn">
						<ExcelFile filename={"users-"+moment().format('MM-DD-YYYY')}>
							<ExcelSheet dataSet={this.state.exportData} name="Users" />
						</ExcelFile>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
