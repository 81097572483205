import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import moment from 'moment';
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import Select from "react-select";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
 
const selectBlood = [
	{ label: "A+ve", value:"1", key: 1 },
	{ label: "A-ve", value:"2", key: 2 },
	{ label: "B+ve", value:"3", key: 3 },
	{ label: "B-ve", value:"4", key: 4 },
	{ label: "AB+ve", value:"5", key: 5 },
	{ label: "AB-ve", value:"6", key: 6 },
	{ label: "O+ve", value:"7", key: 7 },
	{ label: "O-ve", value:"8", key: 8 }, 
];


class AddNewDriverModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			selectBloodType: { label: "A+ve", value:"1", key: 1 },
			startDate: null,
		};
	}
	
	handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-driver").disabled=true;
			
			let dateOfBirth = document.getElementById("dob").value.trim();
			let errorMessage='';

			if(dateOfBirth.length==0)
			{
				errorMessage+="Date of Birth is empty\n";
			}

			if(errorMessage.length!=0)
			{
				swal("Warning",errorMessage,"warning");
				document.getElementById("add-driver").disabled=false;
				return false;
			}
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('email',values.email);
			
			axios.post(REST_API_END_POINT+'staff/is-email-exist',formData).then((res)=>{
				var data = res.data;
				
				if(data.status!=1){
			
					var formData = new FormData(document.getElementById("driverForm"));
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);

					axios.post(REST_API_END_POINT+'transportation/add-driver',formData).then((res)=>{
						var data = res.data;
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("add-driver").disabled=false;
						this.props.toggleModal();
						this.setState({
							startDate: null,
							selectBloodType: { label: "A+ve", value:"1", key: 1 },							
						});
						this.props.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("add-driver").disabled=false;
					});
				}else{
					swal("Email already exist! Please use another email");
					document.getElementById("add-driver").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-driver").disabled=false;
			});

		}
	}
  
	onChange = e => {
		switch (e.target.name) {
			case 'files[]':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
				break;
			default: e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};


  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Add New Driver
			</ModalHeader>
			<AvForm
				id="driverForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			>
				<ModalBody>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>Name<span className="text-danger">*</span></Label>
							  <AvField
								id="name"
								name="name"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  pattern: {
									value: "^[a-zA-Z0-9 ,.'-]+$",
									errorMessage: "Invalid name"
								  },
								  minLength: {
									value: 2,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  },
								  maxLength: {
									value: 100,
									errorMessage:
									  "The name must be between 2 and 100 characters"
								  }
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="6" className="mt-2">
							<AvGroup className="error-t-negative">
							  <Label>Gender<span className="text-danger">*</span></Label>
							</AvGroup>
							<AvRadioGroup
								inline
								name="gender"
								onChange={(e) => {}}
								validate={{
								  required: {
									value: true,
									errorMessage: "Please select any of the gender"
								  }
								}}
								defaultValue='1'
								>
								<AvRadio customInput label="Male" value="1" />
								<AvRadio customInput label="Female" value="2" />
							</AvRadioGroup >		
						</Colxx>					
					</Row>
					<Row>
					<Colxx lg="6">
							<Label><IntlMessages id="customer.dob" /><span className="text-danger">*</span></Label>
							<DatePicker
								id="dob"
								name="dob"
								autoComplete="off"
								className="mb-5"
								maxDate={moment().subtract(18, "years")}
								selected={this.state.startDate}
								onChange={this.handleChangeDate}
								placeholderText=""
								dateFormat="YYYY-MM-DD"
							  />
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>Blood Group<span className="text-danger">*</span></Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="blood_group"
									id="blood_group"
									value={this.state.selectBloodType}						
									options={selectBlood}
									onChange={selectBloodType => { this.setState({ selectBloodType }) }}
									required
								/>
							</AvGroup>
						</Colxx>					
					</Row>
					<Row>
						<Colxx lg="12">
							<Label>Address<span className="text-danger">*</span></Label>
							<AvGroup className="error-t-negative">
							  <AvField
								id="address"
								name="address"
								type="textarea"
								autoComplete="off"
								validate={{
									  required: {
										value: true, 
										errorMessage: "This field is required."
									  },
									  minLength: {
										value: 10,
										errorMessage: "Your message must have minimum of 10 characters"
									  },
									}}
								value={this.state.textarea_content}
								placeholder=""
								rows="3"
							  />
							</AvGroup>					
						</Colxx>
						
					</Row>	
					
					<Row>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label><IntlMessages id="customer.phone" /><span className="text-danger">*</span></Label>
							  <AvField
								id="phone"
								name="phone"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								  number: {
									value: true,
									errorMessage: "Please enter a valid number"
								  },
								  minLength: {
									value: 7,
									errorMessage:
									  "Your name must be between 7 and 15 characters"
								  },
								  maxLength: {
									value: 15,
									errorMessage:
									  "Your name must be between 7 and 15 characters"
								  }
								}}							
							  />
							</AvGroup>
						</Colxx>
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label><IntlMessages id="customer.email" /><span className="text-danger">*</span></Label>
							  <AvField
								id="email"
								name="email"
								type="email"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required. "
								  },
								  email: {
									value: true,
									errorMessage: "This field is required."
								  }
								}}
							  />
							</AvGroup>
						</Colxx>
					</Row>
					<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
							  <Label>License Number<span className="text-danger">*</span></Label>
							  <AvField
								id="license"
								name="license"
								type="text"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  }							
								}}
							  />
							</AvGroup>
						</Colxx>					
					</Row>
					<AvGroup className="error-l-125 error-t-negative">
						<Label>Drivers License</Label>
						<InputGroup className="mb-3">
							<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
							<CustomInput
								type="file"
								name="files[]"
								id="files[]"
								accept="image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
								onChange={this.onChange}
							/>
						</InputGroup>
					</AvGroup>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="add-driver" >
						<IntlMessages id="customer.add" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
  }
};

export default AddNewDriverModal;
