import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, DropdownItem, CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import AddStaffModal from "../../../containers/staffmanagement/AddStaffModal";
import EditStaffModal from "../../../containers/staffmanagement/EditStaffModal";
import ViewStaffModal from "../../../containers/staffmanagement/ViewStaffModal";
import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Staffdirectory extends Component {
	
	constructor(props) {
		super(props);
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenView: false,
			search: '',			
			selectedIds:[],
			selectAll:0,
			user_privileges:[]
		};
	}
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalView = (e,staff_id=0) => {
		this.setState({
			staff_id: parseInt(staff_id),
			modalOpenView: !this.state.modalOpenView,
		});
	};
	
	toggleModalEdit = (e,staff_id=0) => {
		this.setState({
			staff_id: parseInt(staff_id),
			modalOpenEdit: !this.state.modalOpenEdit,
		});
	};
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.staffs.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }   

	componentDidMount(){
		this.dataListRender();
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	}
   
	deleteItem = (e,selectedIds,status)  => {
		if(selectedIds.length==0){
			swal("Warning","Please select at least one staff","warning");
			return false;
		}
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="staff";
		
		if(selectedIds.length>1){
			msg_word="staffs";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+" ?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-staff").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('staff_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'staff/delete-staffs',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-staff").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-staff").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}	 
	 
	 
	activeItem=(e,staff_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(staff_id);
			
		}else{
		  var x=selectedIds.indexOf(staff_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.staffs.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	 
	 
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'staff/get-all-staffs',formData).then((res)=>{
			var data = res.data;
		
			if(data.staffs){
				this.setState({
					staffs: data.staffs,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					staffs: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	render() {
		let data = this.state.staffs;
		if (this.state.search){
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search) || row.name.toLowerCase().includes(this.state.search) || row.gender_name.toLowerCase().includes(this.state.search) || row.date_of_birth.toLowerCase().includes(this.state.search) || row.address.toLowerCase().includes(this.state.search) || row.email_id.toLowerCase().includes(this.state.search) || row.phone.toLowerCase().includes(this.state.search)
			})
		}
			
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"coupon_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			/*{
				Header: "",
				accessor: "status",
				width:80,
				Cell: props => <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{props.value==1?"Active":"Inactive"}</Badge>
			},*/	
			{
				Header: "ID",
				accessor: "barcode",
				width:110,
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Name",
				accessor: "name",
				width:150,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			/*{
				Header: "Gender",
				accessor: "gender_name",
				width:70,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Date Of Birth",
				accessor: "date_of_birth",
				width:90,
				Cell: props => <p className="text-muted">{props.value}</p>
			},	*/  	
			{
				Header: "Address",
				accessor: "address",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Phone",
				accessor: "phone",
				width:110,
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Email",
				accessor: "email_id",
				width:200,
				Cell: props => <p className="text-muted">{props.value}</p>
			},	
			{
				Header: "Options",
				accessor: "id",
				sortable: false,
				width:85,
				Cell: props => this.state.user_privileges.indexOf(100)!==-1 || this.state.user_privileges.indexOf(101)!==-1 ? <div className="action-btns w-9 w-sm-100">
								<ItemDropdown item={<DropdownMenu >
									<DropdownItem onClick={(e)=> this.toggleModalView(e,props.value) }  >						
										<span className="d-inline-block" >View</span>
									</DropdownItem>
									
									{this.state.user_privileges.indexOf(100)!==-1 ? <DropdownItem onClick={(e)=> this.toggleModalEdit(e,props.value) }  >						
										<span className="d-inline-block" >Edit</span>
									</DropdownItem>:""}
									{/*<DropdownItem onClick={this.toggleModalEdit}>						
										<span className="d-inline-block">View</span>
									</DropdownItem>*/}
									{this.state.user_privileges.indexOf(101)!==-1 ? <DropdownItem  id="delete-staff"  onClick={event =>this.deleteItem(event,[props.value],2)} >						
										<span className="d-inline-block">Delete</span>
									</DropdownItem>:""}							
									</DropdownMenu>} 
								/>
							</div>:""
			}
		];
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.staff-directory" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Card className="p-4">
		   <Row>
			  <Colxx xxs="12">
					<div id="api_message"></div>
				</Colxx>
			  <Colxx xxs="12">
					<div className="float-sm-right mb-2">
						{" "}{this.state.user_privileges.indexOf(99)!==-1 ? <Button																		 
							  color="primary"
							  size="sm"
							  className=""
							  onClick={this.toggleModalAdd}
							>
							  Add New						  
						</Button>:""}{" "}{this.state.user_privileges.indexOf(101)!==-1 ? <Button
							id="delete-staff"
							color="primary"
							size="sm"
							className=""	
							onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
						  >
							<IntlMessages id="pages.delete" />
					  </Button>:""}
					</div>
					
					
					<div className="mb-4 dropdown-edit">
						
						<FormGroup row>
							<Colxx lg="6">
								<Input
								  type="text"
								  name="table_search"
								  id="table_search"
								  placeholder="Search"
								  value={this.state.search}
								  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>
						  </Colxx>
						  </FormGroup>			
						<ReactTable
						  data={data}
						  columns={dataTableColumns}
						  defaultPageSize={10}
						  filterable={false}
						  showPageJump={false}
						  responsive
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
					</div>
					
				</Colxx>
				{this.state.user_privileges.indexOf(99)!==-1 ? <AddStaffModal
					modalOpen={this.state.modalOpenAdd}
					toggleModal={this.toggleModalAdd}
					dataListRender={this.dataListRender}
				/>:""}
				{this.state.user_privileges.indexOf(100)!==-1 ? <EditStaffModal
					modalOpen={this.state.modalOpenEdit}
					toggleModal={this.toggleModalEdit}
					dataListRender={this.dataListRender}
					staff_id={this.state.staff_id}
				/>:""}
				
				<ViewStaffModal
					modalOpen={this.state.modalOpenView}
					toggleModal={this.toggleModalView}
					staff_id={this.state.staff_id}
				/>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
