import React, { Component }  from "react";
import Barcode from 'react-barcode';


export default class PrintBarcode extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		const { barcode  } = this.props;
		
		return (
			<div>
				<style dangerouslySetInnerHTML={{__html: `
					@media print {
						@page {
						  size: 144px 60px;
						  margin:0;
						}
					}
				`}} />
				
				<div style={{padding:'12px 10px 2px 12px',display:'flex'}} >                  
					<div style={{width:'144px', height:'50px', fontFamily: 'sans-serif'}}>
						<div className="text-center">
							<Barcode value={barcode} height={25} width={0.95} margin={0} marginTop={5} displayValue={false} />
							<p className="text-center">{(barcode.match(/.{1,4}/g)).join(' ')}</p>
						</div>
					</div>
				</div>					
			</div>
		);
	}
};