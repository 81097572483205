import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import staffmanagement from "./staffmanagement";
import staffdirectory from "./staffdirectory";
import userroles from "./userroles";
import staffscheduler from "./staffscheduler";
import stafftimezone from "./stafftimezone";
import stafftime from "./stafftime";
import listtime from "./listtime";


const Staffmanagement = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>		
			<Redirect exact from={`${match.url}`} to={`${match.url}/staff-directory`} />	
			<Route path={`${match.url}/staff-directory`} component={staffdirectory} />
			<Route path={`${match.url}/user-roles`} component={userroles} />
			<Route path={`${match.url}/staff-scheduler`} component={staffscheduler} />
			<Route path={`${match.url}/staff-time-zone`} component={stafftimezone} />
			<Route path={`${match.url}/stafftime/:user_id`} component={stafftime} />
			<Route path={`${match.url}/listtime/:year/:week_number/:user_id`} component={listtime} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Staffmanagement;