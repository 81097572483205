import React, { Component, Fragment } from "react";
import {
  Row,FormGroup, Input,
  CustomInput,
  Button,
  Modal,
  CardBody,CardHeader,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import classnames from "classnames";

import MixProductGrid from "../../components/pos/MixProductGrid";

import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, LBS_CONSTANT, themeColor} from "../../constants/defaultValues";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ShowProductsModal extends Component{
	constructor(props) {
		super(props);
		
        this.state = {
			search:"",
			products:[],
			selected_products:[],
			blocking:false,
		};	
		this.selectProduct = this.selectProduct.bind(this);
		this.updateProductQuantity = this.updateProductQuantity.bind(this);
		this.addToCart = this.addToCart.bind(this);
	}
	
	componentDidMount(){
        this.setState({ blocking:false })
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('facility_id',facility_id);
		let mixdeal_row = this.props.mixdeal_row;
		
		formData.append('variation_quantity',mixdeal_row.variation_quantity);
		formData.append('variation_qty_type',mixdeal_row.variation_qty_type);
		formData.append('category_id',mixdeal_row.category_id);
		formData.append('product_ids',mixdeal_row.product_ids);
		
		axios.post(REST_API_END_POINT+'point-of-sale/get-deal-products',formData).then((res)=>{
			var data = res.data;
			if(data.status==1){				
				var products=data.products;	
				products = products.filter(row=>{ return parseInt(row.stock)>0 });
				products = products.map(row=>{
					row.selected=false;
					row.quantity=1;
					return row;
				})
				this.setState({
					products,
					blocking:false
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false })
		});
    }
	
	selectProduct(row){
		var products=this.state.products;				
		products = products.map(r=>{
			if(r.variation_id==row.variation_id){
				r.selected=!r.selected;
			}			
			return r;
		});
		this.setState({ products })
	}
	
	updateProductQuantity(row,quantity){
		var products=this.state.products;				
		products = products.map(r=>{
			if(r.variation_id==row.variation_id){
				r.quantity=quantity;
			}
			return r;
		});
		this.setState({ products })
	}
	
	addToCart(){
		var product_package = this.props.mixdeal_row;
		var values={};		
		values.product_id="";
		values.deal_id=product_package.deal_id;
		values.product_price=product_package.price;
		values.product_unit_price=product_package.amount;
		values.exise_tax=product_package.exise_tax;
		values.sales_tax=product_package.sales_tax;
		values.quantity=1;
		values.variation_id=product_package.variation_id;
		values.including_tax=product_package.including_tax;
		values.amount_of_flower=product_package.amount_of_flower;
		
		values.package_id=product_package.package_id;
		values.is_finished=1;
		values.is_mix_match=1;
		values.name=product_package.name;
		
		let selected_products = JSON.parse(JSON.stringify(this.state.products)).filter(r=>{ return r.selected });
		values.real_stock=Math.min(...selected_products.map(r=>parseInt(r.stock/parseInt(r.quantity))));
		
		values.deal_products = JSON.stringify(selected_products.map(r=>{ 
			delete r.selected; 
			delete r.stock; 
			delete r.name; 
			delete r.thumbnail; 
			delete r.thumbnail_url;
			return r; 
		}));
		
		this.props.addToCart(values);
		this.props.toggleModal();
	}

	render() {
		let selected_products = this.state.products.filter(r=>{ return r.selected }).map(r=>r.quantity!='' ? parseInt(r.quantity):0);
		let total_selected=0;
		if(selected_products.length>0){
			total_selected = selected_products.reduce((total,r)=>{ return total+r });
		}
		
		return (	  
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  size="lg"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Choose {this.props.mixdeal_row.no_of_products} Quantity of Products
				</ModalHeader>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>} message="Please wait" keepInView>
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<div className="pos-product-wrapper pos-show-product-wrapper" style={{minHeight:'400px'}} >
									<MixProductGrid 
										products={this.state.products}
										currency_rate={this.props.currency_rate}
										currency_symbol={this.props.currency_symbol}
										selectProduct={this.selectProduct}
										updateProductQuantity={this.updateProductQuantity}
									/>
								</div>
							</Colxx>
						</Row>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						{total_selected==this.props.mixdeal_row.no_of_products ? <Button color="primary" onClick={this.addToCart}>
							<IntlMessages id="customer.submit" />
						</Button>:""}{" "}
						<Button color="secondary" outline onClick={this.props.toggleModal}>
							<IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</BlockUi>
			</Modal>
		);
	}	
};