import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';



import onboarding from "./onboarding";
import customfieldtemplate from "./customfieldtemplate";
import questionnairetemplates from "./questionnairetemplates";
import signagreements from "./signagreements";
import createworkflow from "./createworkflow";



const Onboarding = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
                      
		
          <Route path={`${match.url}/onboarding`} component={onboarding} />
          <Route path={`${match.url}/customfieldtemplate`} component={customfieldtemplate} />
          <Route path={`${match.url}/questionnairetemplates`} component={questionnairetemplates} />
          <Route path={`${match.url}/signagreements`} component={signagreements} />
          <Route path={`${match.url}/createworkflow`} component={createworkflow} />
          
			
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default  Onboarding;
