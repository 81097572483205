import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { addToCart } from "../../redux/cart/cartActions";
import DealGridListSingle from "./product/DealGridListSingle";

const DealGrid = ({
  product,
  currency,
  addToCart,
  cartItems,
  sliderClassName,
  spaceBottomClass
}) => {
	return (   
		<DealGridListSingle
			sliderClassName={sliderClassName+" col-6 "}
			spaceBottomClass={spaceBottomClass}
			product={product}
			currency={currency}
			addToCart={addToCart}
			cartItem={
			  cartItems.filter(cartItem => cartItem.id === product.id)[0]
			}            
			key={product.id}
		/>        
	);
};

DealGrid.propTypes = {
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  currency: PropTypes.object,
  products: PropTypes.array,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
	  variationId
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
		  variationId
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DealGrid);
