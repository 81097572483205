import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import CreateOrderModal from "../../../containers/growhouse/GrowInventory/CreateOrderModal";
import ReactTable from "react-table";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { zeroPad } from "../../../helpers/Utils";

import InvoiceModal from "../../../containers/growhouse/GrowInventory/InvoiceModal";
import ApproveOrderModal from "../../../containers/growhouse/GrowInventory/ApproveOrderModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PlantOrders extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			modalOpenCreate:false,
			orders:[],
			search: '',
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.toggleModalCreate = this.toggleModalCreate.bind(this);
		this.toggleModalInvoice = this.toggleModalInvoice.bind(this);
		this.toggleModalApprove = this.toggleModalApprove.bind(this);
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'growhouse/grow-inventory',formData).then((res)=>{
			var data = res.data;
		
			if(data.orders){
				this.setState({
					orders: data.orders,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					orders: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidMount(){
	   this.dataListRender();
	}
	
	toggleModalCreate = () => {
		this.setState({
			modalOpenCreate: !this.state.modalOpenCreate
		});
	};
	
	toggleModalInvoice = (e,order_id =0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenInvoice: !this.state.modalOpenInvoice
		});
	};
	
	toggleModalApprove = (e,order_id =0) => {
		this.setState({
			order_id: parseInt(order_id),
			modalOpenApprove: !this.state.modalOpenApprove
		});
	};
	
	toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.orders.forEach(x => {
				if(x.status<3){
					newSelected.push(x.id);
				}
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }	
	
    activeItem=(e,order_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(order_id);
			
		}else{
		  var x=selectedIds.indexOf(order_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.orders.filter(r=>{ return r.status<3 }).length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}

	cancelOrders = (e,selectedIds)  => {
		if(selectedIds.length==0){
			swal("Warning!","Please select at least one order","warning");
			return;
		}
		
		var msg="Cancel these orders?";
        var success_msg="The orders has been canceled.";
        var failure_msg="Failed to cancel orders";
		
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("cancel-orders").disabled=true;
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				
				var user_id = localStorage.getItem('user_id');
				var formData = new FormData();
				formData.append('order_ids',JSON.stringify(selectedIds));
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'growhouse/cancel-orders',formData).then((res)=>{
					var data = res.data;
				
					document.getElementById("cancel-orders").disabled=false;					
					if(data.status==1){
						this.dataListRender();
						swal("Success",success_msg,"success");
					}else{
						swal("Failed",failure_msg,"warning");
					}
					this.props.dataListRender();
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
					document.getElementById("cancel-orders").disabled=false;
				});
				
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
  
    render() {
		let data = this.state.orders
		if (this.state.search) {
			data = data.filter(row => {
				return row.vendor_name.toLowerCase().includes(this.state.search.toLowerCase()) || row.added_by.toLowerCase().includes(this.state.search.toLowerCase()) || row.order_number.includes(this.state.search)
			})
		}
		
		const dataTableColumns = [
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					if(rowInfo.original.status<3){
						return ( <FormGroup>
							<CustomInput type="checkbox" id={"order_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
							</FormGroup> );
					}else{
						return "";
					}
				}
			},
			{
				Header: "Order #",
				accessor: "order_number",
				Cell: props => <p className="list-item-heading">{zeroPad(props.value,6)}</p>	
			},
			{
				Header: "Vendor",
				accessor: "vendor_name",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Added By",
				accessor: "added_by",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Added On",
				accessor: "timestamp",
				Cell: props => <p className="list-item-heading">{moment(props.value*1000).format('MM/DD/YYYY HH:mm')}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => {			
					if(props.value==0){
						return(<Badge color="warning" pill>Waiting for Approval</Badge>);
					}else if(props.value==1){
						return(<Badge color="primary" pill>Approved</Badge>);
					} else if(props.value==2){
						return(<Badge color="primary" pill>Processed</Badge>);
					} else if(props.value==3){
						return(<Badge color="success" pill>Completed</Badge>);
					} else {
						return(<Badge color="danger" pill>Canceled</Badge>);
					}
				}
			},	  
			{
				Header: "Options",
				accessor: "id",
				Cell: props => <Fragment>
						<div className="action-btns p-0">
							<ItemDropdown item={<DropdownMenu >
								<DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalInvoice(e,props.value) }} >
									<span className="d-inline-block">Invoice</span>
								</DropdownItem>
								{props.original.status<3 ? <DropdownItem className="cursor-pointer" onClick={(e)=>{ this.toggleModalApprove(e,props.value) }} >
									<span className="d-inline-block" >Approve Order</span>
								</DropdownItem>:""}
							</DropdownMenu>} />
						</div>
					</Fragment>
			}
		];
    return (
		<Fragment>
			<Row>
				<Colxx xxs="12">
					<Breadcrumb heading="menu.plantorders" match={this.props.match} />
					<Separator className="mb-5" />
				</Colxx>
			</Row>
			
			<Card className="p-4">
				<Row>
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx> 
					<Colxx xxs="12">
						<div className="float-sm-right mb-2">
							{" "}<Button																		 
								  color="primary"
								  size="sm"
								  className=""
								  onClick={this.toggleModalCreate}
								>
								Create Order							  
							</Button>																		
							   {" "}<Button
									color="primary"
									size="sm"
									id="cancel-orders"
									onClick={(e)=>{ this.cancelOrders(e,this.state.selectedIds) }}             						
								  >
								Cancel Order
							</Button>
						</div>					 
						
						<div className="mb-4 dropdown-edit">						
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value.toLowerCase()})}
									/>
								</Colxx>
							</FormGroup>			
							<ReactTable
								data={data}
								columns={dataTableColumns}
								defaultPageSize={10}
								filterable={false}
								showPageJump={false}
								PaginationComponent={DataTablePagination}
								showPageSizeOptions={true}
								defaultSorted={[{id: "send_on",desc: false}]}
							/>
						</div>
					</Colxx>
				</Row>
				
				<CreateOrderModal
					modalOpen={this.state.modalOpenCreate}
					toggleModal={this.toggleModalCreate}
					dataListRender={this.dataListRender}
				/>
				
				<InvoiceModal
					modalOpen={this.state.modalOpenInvoice}
					toggleModal={this.toggleModalInvoice}
					order_id={this.state.order_id}
				/>
				
				<ApproveOrderModal
					modalOpen={this.state.modalOpenApprove}
					toggleModal={this.toggleModalApprove}
					dataListRender={this.dataListRender}
					order_id={this.state.order_id}
				/>
			</Card>
		</Fragment>
    );
  }
}
