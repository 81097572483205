import React, { Component } from "react";
import { AvForm, AvField, AvGroup, } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,InputGroup, InputGroupAddon, Card, CardBody
} from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx } from "../../../components/common/CustomBootstrap";
import { uuid } from 'uuidv4';
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import axios from 'axios';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const WeekdayOptions = [
	{ label: "All Day", value: "0", key: -1 },
	{ label: "Monday", value: "1", key: 0 },
	{ label: "Tuesday", value: "2", key: 1 },
	{ label: "Wednesday", value: "3", key: 2 },
	{ label: "Thursday", value: "4", key: 3 },
	{ label: "Friday", value: "5", key: 4 },
	{ label: "Saturday", value: "6", key: 5 },
	{ label: "Sunday", value: "7", key: 6 }
];
 
class EditDealModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onProductChange = this.onProductChange.bind(this);
		this.addRow = this.addRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);

		this.state = {
			products:[],
			tax_categories:[],
			product_variations:[],
			selected_products:[{
				id:uuid(),
				product_id:null,
				variation_id:null,
				product_variations:[],
				quantity:0,
			}],
			product_id:null,
			variation_id:null,
			deal:null,
			inclusive_taxes:false,
			week_day: { label: "All Day", value: "0", key: -1 },
		};
	}
	
	
	componentDidMount() {		
		var products=[];
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'point-of-sale/get-product-and-category-for-discount',formData).then((res)=>{
			var data = res.data;
			products=data.products;
			var tax_categories=data.tax_categories;
			
			products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			tax_categories.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			this.setState({
				products:products,
				tax_categories
			});			
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	componentDidUpdate(prevProps, prevState, snapshot){
		
        if(this.props.modalOpen){
			if (prevProps.deal_id !== this.props.deal_id && this.props.deal_id!=0) {
				var formData = new FormData();
				formData.append('deal_id',this.props.deal_id);
	
				axios.post(REST_API_END_POINT+'point-of-sale/get-daily-deal-by-id',formData).then((res)=>{
					var data = res.data;					
					let deal = data.deal;			 
					
					let selected_products = deal.products;
					let week_day = WeekdayOptions.find(p=>{ return p.value==deal.week_day });
					let tax_category = this.state.tax_categories.find(p=>{ return p.value==deal.tax_category });
					let inclusive_taxes = deal.including_tax==1;
					
					this.setState({ 
						deal,
						week_day,
						tax_category,
						inclusive_taxes,
						selected_products
					});
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
				
			}
		}
	}

	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			let errorMessage='';
			let product_ids = this.state.selected_products.map(r=>{ return r.product_id!=null ? r.product_id.value:null});
			let variation_ids = this.state.selected_products.map(r=>{ return r.variation_id!=null ? r.variation_id.value:null});
			
			if(this.state.tax_category==null){
				swal("Warning","Please select the tax category","warning");
				return false;
			}
			
			if(product_ids.indexOf(null)!=-1){
				swal("Warning","Please select the product","warning");
				return false;
			}
			if(variation_ids.indexOf(null)!=-1){
				swal("Warning","Please select the variation","warning");
				return false;
			}
			
			document.getElementById("add-coupon").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("customercouponForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);
			formData.append('tax_category',this.state.tax_category.value);	
			formData.append('inclusive_taxes',this.state.inclusive_taxes ? 1:0);
			let selected_products = this.state.selected_products.map(r=>{
				let row={};
				row.product_id = r.product_id.value;
				row.variation_id = r.variation_id.value;
				row.quantity = r.quantity;
				return row;
			})
			formData.append('selected_products',JSON.stringify(selected_products));
			formData.append('deal_id',this.state.deal.id);

			axios.post(REST_API_END_POINT+'point-of-sale/edit-daily-deal',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					var api_message=document.getElementById("api_message");
					api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					}, 5000);
					
					JQUERY_MODULE.$('.alert .close').on("click", function(e){
						JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove();
						});
					});
				}
				
				document.getElementById("add-coupon").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-coupon").disabled=false;
			});

		}
	}
		
	onProductChange(product_id,id){
		if(product_id!=null){
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('product_id',product_id.value);
			
			axios.post(REST_API_END_POINT+'receive-inventory/get-product-variations',formData).then((res)=>{
				var data = res.data;
				var product_variations=data.variations;
				product_variations = product_variations.map((element,index)=>{
					element.label=element.variation_name;
					element.value=element.variation_id;
					element.key=index;
					return element;
				});
				
				
				let selected_products = this.state.selected_products;
				selected_products = selected_products.map(r=>{
					if(r.id==id){
						r.product_id = product_id;
						r.product_variations = product_variations;
					}
					return r;
				})
				this.setState({ selected_products });
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
			});
		}
	}
	
	onChange(e){
		switch (e.target.name) {
		  case 'thumbnail':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	addRow(){		
		let selected_products = this.state.selected_products;
		selected_products.push({
			id:uuid(),
			product_id:null,
			variation_id:null,
			product_variations:[],
			quantity:0,
		});
		this.setState({ selected_products });
	};
	
	deleteRow(id){		
		let selected_products = this.state.selected_products;
		selected_products = selected_products.filter(r=>{ return r.id!=id });
		this.setState({ selected_products });
	};


	render(){
		if(this.state.deal!=null){
			var deal=this.state.deal;
		}else{
			var deal;
		}
		
		return (
			<Modal
			  isOpen={this.props.modalOpen}
			  toggle={this.props.toggleModal}
			  wrapClassName="modal-right"
			  size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					Edit Daily Deal
				</ModalHeader>
				<AvForm
					id="customercouponForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<ModalBody>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
								  <Label>Name<span className="text-danger">*</span></Label>
								  <AvField
									id="name"
									name="name"
									type="text"
									value={ deal?((deal.name!=null)?deal.name:''):'' }
									autoComplete="off"
									validate={{
									  required: {
										value: true,
										errorMessage: "This field is required."
									  },
									  minLength: {
										value: 2,
										errorMessage:"The name must be between 2 and 255 characters"
									  },
									  maxLength: {
										value: 255,
										errorMessage:"The name must be between 2 and 255 characters"
									  }
									}}
								  />
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-t-negative">
									<Label>Description</Label>
									<AvField
										id="description"
										name="description"
										type="textarea"
										autoComplete="off"
										value={ deal?((deal.description!=null)?deal.description:''):'' }
										rows="4"									
									/>
								</AvGroup>
							</Colxx>
						</Row>
						{deal ? (deal.thumbnail!=null ? <Row className="mt-4">
							<Colxx lg="12" >
								<div className="d-flex justify-content-start">
									<div className="text-center img-view-wrapper">
										<img src={BASE_URL+'products/'+deal.thumbnail} alt="Product Image" className="img-responsive" />
									</div>
								</div>
							</Colxx>
						</Row>:""):""}
						<Row>
							<Colxx lg="12" className="mb-2">						
								<AvGroup className="error-t-negative">
									<Label>Thumbnail (Recommended size:500x500)</Label>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
										<CustomInput
											type="file"
											id="thumbnail"
											name="thumbnail"
											accept="image/*"
											onChange={this.onChange}
										/>
									</InputGroup>
								</AvGroup>					
							</Colxx>
						</Row>
						<Row>
							<Colxx md="6">
								<AvGroup className="error-t-negative">
									<Label>Price<span className="text-danger">*</span></Label>
									<AvField
										id="amount"
										name="amount"
										type="number"
										min='0'
										value={ deal?((deal.amount!=null)?deal.amount:''):'' }
										autoComplete="off"
										validate={{
											required: {
												value: true,
												errorMessage: "Please enter numeric value"
											},
											min: {
												value: "0",
												errorMessage: "Please enter a value greater than or equal to 0"
											}
										}}
									/>
								</AvGroup>
							</Colxx>						
							<Colxx lg="6">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Weekday</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="week_day"
										id="week_day"
										value={this.state.week_day}
										onChange={week_day => { this.setState({ week_day }) }}
										options={WeekdayOptions}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12">
								<AvGroup className="error-l-100 error-t-negative">
									<Label>Tax Category<span className="text-danger">*</span></Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"
										name="tax_category"
										id="tax_category"
										value={this.state.tax_category}
										onChange={tax_category => { this.setState({ tax_category }) }}
										options={this.state.tax_categories}
									/>
								</AvGroup>
							</Colxx>
						</Row>
						<Row>
							<Colxx lg="12" className="mb-2">
								<Label>													  
									<Switch
										className="custom-switch custom-switch-primary im-inline-block"
										checked={this.state.inclusive_taxes}
										onChange={status => { this.setState({ inclusive_taxes:!this.state.inclusive_taxes }) }}
									/>
									<span className="h6 ml-4">Inclusive Taxes</span>
								</Label>
							</Colxx>
						</Row>
						{this.state.selected_products.map((row,index)=>{
							return (<Card className="mt-2" key={index}>
								<CardBody className="p-2">
									<Row>
										<Colxx md="5">
											<Label >Product<span className="text-danger">*</span></Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"
												name={"product_id["+index+"]"}
												value={row.product_id}						
												onChange={(product_id)=>{this.onProductChange(product_id,row.id)}}
												options={this.state.products}
											/>
										</Colxx>
										<Colxx md="4">
											<Label >Variation<span className="text-danger">*</span></Label>
											<Select
												components={{ Input: CustomSelectInput }}
												className="react-select"
												classNamePrefix="react-select"
												name={"variation_id["+index+"]"}
												value={row.variation_id}						
												onChange={variation_id => { 
													let variation_ids = this.state.selected_products.map(r=>{ return r.variation_id!=null ? r.variation_id.value:null});
													if(variation_ids.indexOf(variation_id.value)==-1){
														let selected_products = this.state.selected_products;
														selected_products = selected_products.map(r=>{
															if(r.id==row.id){
																r.variation_id = variation_id;
															}
															return r;
														})
														this.setState({ selected_products })
													}else{
														swal("Warning!","The variation is already selected","warning");
													}
												}}
												options={row.product_variations}
											/>
										</Colxx>
									
										<Colxx md="2">
											<AvGroup >
												<Label>Quantity<span className="text-danger">*</span></Label>
												<AvField
													name={"quantity["+index+"]"}
													type="number"
													autoComplete="off"
													validate={{
														required: {
															value: true,
															errorMessage: "Please enter numeric value"
														},
														min: {
															value: "1",
															errorMessage: "Please enter a value greater than or equal to 1"
														},
														max: {
															value: "9999999999999999999999",
															errorMessage: "Please enter a value less than or equal to 9999999999999999999999"
														},
														step: {
															value: "1",
															errorMessage: "Decimal values are not allowed"
														}
													}}
													value={row.quantity}						
													onChange={e => {														
														let selected_products = this.state.selected_products;
														selected_products = selected_products.map(r=>{
															if(r.id==row.id && e.target.value>=0){
																r.quantity = e.target.value;
															}
															return r;
														})
														this.setState({ selected_products })														
													}}
												/>
											</AvGroup>
										</Colxx>
										{index!==0 ? <Colxx md="1" className="text-center">
											<span className="text-danger d-inline-block mt-4" onClick={()=>{ this.deleteRow(row.id); }}><i className="fas fa-2x fa-trash-alt"></i></span>
										</Colxx>:""}
									</Row>
								</CardBody>
							</Card>)
						})}
						<div className="mt-4 text-center">
							<Button color="primary" onClick={this.addRow}>Add Row</Button>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" id="add-coupon" >
						  <IntlMessages id="customer.update" />
						</Button>
						<Button color="secondary" outline onClick={this.props.toggleModal}>
						  <IntlMessages id="customer.cancel" />
						</Button>{" "}
					</ModalFooter>
				</AvForm>
			</Modal>
		);
	}
};

export default EditDealModal;
