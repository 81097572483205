import React, { Component, Fragment } from "react";
import {
  Collapse,
  Button,
  Row,
  Card,
  CardBody,
  Label,
  CardHeader,
  Nav, NavItem, TabContent, TabPane
} from "reactstrap";
import classnames from "classnames";
//import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";

import Details from "../../../containers/wholesale/Details";
import MoreInfo from "../../../containers/wholesale/MoreInfo";
import Portfolio from "../../../containers/wholesale/Portfolio";
import Request from "../../../containers/wholesale/Request";
import Payments from "../../../containers/wholesale/Payments";
import Documents from "../../../containers/wholesale/Documents";

import Switch from "rc-switch";
import "rc-switch/assets/index.css";

export default class ViewVendors extends Component {
	constructor(props) {
		super(props);

		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		this.state = {
		  activeFirstTab: "1",
		};
	}

	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
		  this.setState({
			activeFirstTab: tab
		  });
		}
	}
  
  render() {
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.view-vendor" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		
		<Row>
          		  
			<Colxx lg="12" className="page-tabs">
				<Card className="mb-4">
					<CardHeader>
					  <Nav tabs className="card-header-tabs ">
						<NavItem>
						  <NavLink
							className={classnames({
							  active: this.state.activeFirstTab === "1",
							  "nav-link": true
							})}
							onClick={() => {
							  this.toggleFirstTab("1");
							}}
							to="#"
						  >
							 NAME
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({
							  active: this.state.activeFirstTab === "2",
							  "nav-link": true
							})}
							onClick={() => {
							  this.toggleFirstTab("2");
							}}
							to="#"
						  >
							MORE INFO
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({
							  active: this.state.activeFirstTab === "3",
							  "nav-link": true
							})}
							onClick={() => {
							  this.toggleFirstTab("3");
							}}
							to="#"
						  >
							PORTFOLIO
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({
							  active: this.state.activeFirstTab === "4",
							  "nav-link": true
							})}
							onClick={() => {
							  this.toggleFirstTab("4");
							}}
							to="#"
						  >
							REQUEST
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({
							  active: this.state.activeFirstTab === "5",
							  "nav-link": true
							})}
							onClick={() => {
							  this.toggleFirstTab("5");
							}}
							to="#"
						  >
							PAYMENTS
						  </NavLink>
						</NavItem>
						<NavItem>
						  <NavLink
							className={classnames({
							  active: this.state.activeFirstTab === "6",
							  "nav-link": true
							})}
							onClick={() => {
							  this.toggleFirstTab("6");
							}}
							to="#"
						  >
							DOCUMENTS
						  </NavLink>
						</NavItem>
					  </Nav>
					</CardHeader>
					<TabContent activeTab={this.state.activeFirstTab}>
					  <TabPane tabId="1">
						<Row>
						  <Colxx sm="12">
							<CardBody>
								<Details />
							</CardBody>
						  </Colxx>
						</Row>
					  </TabPane>
					  <TabPane tabId="2">
						<Row>
						  <Colxx sm="12">
							<CardBody>
								<MoreInfo />
							</CardBody>
						  </Colxx>
						</Row>
					  </TabPane>
					  <TabPane tabId="3">
						<Row>
						  <Colxx sm="12">
							<CardBody>
								<Portfolio/>
							</CardBody>
						  </Colxx>
						</Row>
					  </TabPane>
					  <TabPane tabId="4">
						<Row>
						  <Colxx sm="12">
							<CardBody>
								<Request />
							</CardBody>
						  </Colxx>
						</Row>
					  </TabPane>
					  <TabPane tabId="5">
						<Row>
						  <Colxx sm="12">
							<CardBody>
								<Payments />
							</CardBody>
						  </Colxx>
						</Row>
					  </TabPane>
					  <TabPane tabId="6">
						<Row>
						  <Colxx sm="12">
							<CardBody>
								<Documents />
							</CardBody>
						  </Colxx>
						</Row>
					  </TabPane>
					  
					</TabContent>
				</Card>
            </Colxx>

        </Row>
      </Fragment>
    );
  }
}
