import React, { Component } from "react";
import { AvForm, AvField, AvInput, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import { uuid } from 'uuidv4';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class MoveToJarModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.addRow = this.addRow.bind(this);
		this.state={
			selected_jars:[{
				id:uuid(),
				jar_id:"",
				capacity:0,
				quantity:0
			}]
		}
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add_to_inventory-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("pauseRestartForm"));
			formData.append('user_id',user_id);
			formData.append('facility_id',facility_id);
			formData.append('equipment_id',this.props.equipment_id.value);
			formData.append('batch_uid',this.props.record_batch_uid);
			
			formData.append('quantity',this.props.crude_oil);
			formData.append('source',this.props.source);
			formData.append('selected_jars',JSON.stringify(this.state.selected_jars));
			
			axios.post(REST_API_END_POINT+'processor/move-to-jars',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					document.getElementById("add_to_inventory-btn").disabled=false;
					this.props.toggleModal();
					this.props.moveToJar();
					this.setState({ selected_jars:[{
										id:uuid(),
										jar_id:"",
										capacity:0,
										quantity:0
									}] 
								})
					swal("Success!","Crude oil has been moved to jars","success");
				}else{
					swal("Failed to move the crude oil to jars");
					document.getElementById("add_to_inventory-btn").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add_to_inventory-btn").disabled=false;
			});
		}
	}
	
	addRow(){
		let selected_jars = this.state.selected_jars;
		selected_jars.push({
				id:uuid(),
				jar_id:"",
				capacity:0,
				quantity:0
			});
		this.setState({ selected_jars });
	}
	
	removeRow(id){
		let selected_jars = this.state.selected_jars.filter(row=>{ return row.id!=id });
		this.setState({ selected_jars });
	}
 

	render() {
		let total_selected_quantity = this.state.selected_jars.map(r=>r.quantity!="" ? parseFloat(r.quantity):0).reduce((total,r)=>{ return total+r });
		
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="lg"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Move to Jar
			</ModalHeader>
			<AvForm
				id="pauseRestartForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Batch UID</Label>
							<AvField
								id="add_to_inventory_batch_uid"
								name="add_to_inventory_batch_uid"
								autoComplete="off"
								readOnly={true}
								value={this.props.record_batch_uid}
							/>
						</AvGroup>
					</Colxx>
					
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Total Quantity</Label>
							<AvField
								id="add_to_inventory_qty"
								name="add_to_inventory_qty"
								autoComplete="off"
								readOnly={true}
								value={this.props.crude_oil+" (Grams)"}
							/>
						</AvGroup>
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						{this.state.selected_jars.map((row,index)=>{
							return(<Row className="align-items-center mb-3" key={index}>
								<Colxx lg="6">
									<AvGroup className="error-t-negative">
										<Label>Jar<span className="text-danger">*</span></Label>
										<AvField
											name={"jar_id["+index+"]"}
											id={"jar_id["+index+"]"}
											type="select"
											autoComplete="off"
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  },
											}}
											onChange={e => { 
												let selected_jars = this.state.selected_jars;
												selected_jars = selected_jars.map(r=>{
													if(r.id==row.id){
														r.jar_id=e.target.value;
														let jar = this.props.jars.find(j=>{ return j.id==r.jar_id });
														if(jar){
															r.capacity=parseFloat(jar.capacity);
														}else{
															r.capacity=0;
														}
													}
													return r;
												})
												this.setState({ selected_jars }) 
											}}
										>
										<option value="">-- Select Jar--</option>
										{this.props.jars.map((row,index)=>{
											return(<option value={row.id} key={index}>{row.name}</option>)
										})}
										</AvField>
									</AvGroup>
								</Colxx>
								<Colxx lg="6">
									<Row className="align-items-center">
										<Colxx lg="10">
											<Label>Quantity<span className="text-danger">*</span></Label>
											<AvInput
												name={"jar_quantity["+index+"]"}
												id={"jar_quantity["+index+"]"}
												type="number"
												autoComplete="off"
												validate={{
												  required: {
													value: true,
													errorMessage: "This field is required."
												  },
												  min: {
													value: "0",
													errorMessage: "This field is required."
												  },
												}}
												value={row.quantity}
												onChange={e => { 
													let selected_jars = this.state.selected_jars;
													selected_jars = selected_jars.map(r=>{
														if(r.id==row.id){
															r.quantity=e.target.value;
														}
														return r;
													})
													this.setState({ selected_jars }) 
												}}
											/>
											Grams
										</Colxx>
										{index!=0 ? <Colxx lg="2">
											<span className="text-danger" onClick={()=>{ this.removeRow(row.id) }}><i className="fas fa-2x fa-times" /></span>
										</Colxx>:""}
									</Row>
								</Colxx>	
							</Row>)
						})}
					</Colxx>
				</Row>
				<Row>
					<Colxx lg="12">
						<Button color="primary" type="button" onClick={this.addRow} >Add Row</Button>
					</Colxx>
				</Row>
			</ModalBody>
			<ModalFooter className="justify-content-start">
				<Button color="primary" id="add_to_inventory-btn" disabled={this.props.crude_oil!=total_selected_quantity} >
					<IntlMessages id="customer.submit" />
				</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
					<IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
