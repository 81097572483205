import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import locations from "./locations";
import labelSettings from "./label-settings";

const Locations = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
			<Route path={`${match.url}/locations`} component={locations} />
			<Route path={`${match.url}/label-settings`} component={labelSettings} />
			<Route path={`${match.url}`} component={locations} />
            <Redirect to="/error" />
        </Switch>
    </div>
);
export default Locations;