import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody, FormGroup, Input, Label, CustomInput } from "reactstrap";
import IntlMessages from "../../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../../containers/navs/Breadcrumb";
import DatePicker from "react-datepicker";
import { AvForm } from "availity-reactstrap-validation";

import Select from "react-select";
import CustomSelectInput from "../../../../components/common/CustomSelectInput";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import {REST_API_END_POINT,dataEncryptKey, LBS_CONSTANT, themeColor} from "../../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const $ = require('jquery');
$.DataTable = require('datatables.net');
require( 'datatables.net-buttons/js/dataTables.buttons.min' );
const jzip = require( 'jszip');
require( 'pdfmake');
const pdfFonts = require( 'pdfmake/build/vfs_fonts');
require( 'datatables.net-buttons/js/buttons.html5.min' );
require( 'datatables.net-buttons/js/buttons.print.min' );

window.JSZip = jzip;
window.pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class SalesTrends extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {		 
			reports:[],
			products:[],
			daterange:[],
			search: '',
			start_date: moment().startOf('day'),
			end_date: moment().endOf('day'),
			product_id: null,
			blocking: true,
			dataTableRefresh: true,
		};
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.createDataTable = this.createDataTable.bind(this);
		this.makeArray = this.makeArray.bind(this);
		this.makeCols = this.makeCols.bind(this);
	}
	
	handleChangeDate = date => {
		this.setState({
			start_date: date
		});
	}
	
	handleChangeEndDate = date => {
		this.setState({
			end_date: date
		});
	} 

	handleSubmit(event, errors, values) {
		if (errors.length === 0) {
			this.dataListRender();
		}
	}
     
	componentDidMount(){  
		this.dataListRender();		   
	}
    
	dataListRender() {
		this.setState({ blocking:true });
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		if(this.state.start_date!=null){
			formData.append('start_date',moment(this.state.start_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('start_date','');
		}
		
		if(this.state.end_date!=null){
			formData.append('end_date',moment(this.state.end_date).format('YYYY-MM-DD HH:mm'));
		}else{
			formData.append('end_date','');
		}

		if(this.state.product_id!=null){
			formData.append('product_id',this.state.product_id.value);
		}else{
			formData.append('product_id',0);
		}
					
		
		axios.post(REST_API_END_POINT+'report/sales-trends',formData).then((res)=>{
			var data = res.data;
			if(data.reports){
				var products = data.products;
				products = products.map(row=>{
					row.label=row.name;
					row.value=row.id;
					row.key=row.id;
					return row;
				});
				products.unshift({ label:"All", value:0, key:0 });
				
				this.setState({
					reports: data.reports,
					daterange: data.daterange,
					products,
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}else{
				this.setState({
					reports: [],
					daterange: [],
					products: [],
					blocking:false
				},()=>{
					this.createDataTable();
				});
			}
				
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
	}
	
	createDataTable(){		
		this.$el = $(this.el);			
		this.$el.DataTable().clear().destroy();
		var that = this;
		this.setState({ dataTableRefresh:false  });
		setTimeout(function(){
			that.setState({ dataTableRefresh:true },()=>{
				that.$el = $(that.el);	
				that.$el.DataTable({
					dom: 'Bfrtip',
					columns: that.makeCols(),
					data: that.makeArray(),
					aaSorting:[],
					buttons: [
						'csv', 'excel', 'pdf', 'print'
					]
				});
			});
		}, 300);
	}
	
	makeArray(){
		const reports = this.state.reports;
		const daterange = this.state.daterange;
		var formated_data=[];
		var i=1;
		for(let row of reports){
			let final_row_data={
				index:i++,
				product:row.product,
				total:row.total
			};
			let row_data=daterange.map(r=>{
				let e={};
				e[`s_${r.split('-').join('_')}`] = row[r];
				final_row_data = Object.assign(final_row_data,e);
				return e;
			});
			
			formated_data.push(final_row_data);
		}
		return formated_data;
	}
	
	makeCols(){
		const daterange = this.state.daterange;
		let row_data=daterange.map(r=>{
			return {title: moment(r).format('MM/DD/YYYY'), data:'s_'+r.split('-').join('_') };
		});
		
		var formated_data=[
			{ title: '&nbsp;', data:'index' },
			{ title: 'Product', data:'product' },
			...row_data,
			{ title: 'Total', data:'total' }
		];
		return formated_data;
	}
	
	
  
	render() {	
		const reports = this.state.reports;
		const { products,daterange } = this.state;
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.sales-trends" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Card className="p-4">				
						<AvForm
							className="av-tooltip tooltip-right-bottom"
							onSubmit={this.handleSubmit}
						>
						<Row>
							<Colxx lg="4">
								<Label>Start date<span className="text-danger">*</span></Label>
								<DatePicker
									id="start_date"
									name="start_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.start_date}
									onChange={this.handleChangeDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							
							<Colxx lg="4">
								<Label>End date<span className="text-danger">*</span></Label>
								<DatePicker
									id="end_date"
									name="end_date"
									className="mb-2"
									autoComplete="off"
									selected={this.state.end_date}
									onChange={this.handleChangeEndDate}
									showTimeSelect
									timeIntervals={30}
									placeholderText=""
									dateFormat="MM/DD/YYYY hh:mm a"
								/>
							</Colxx>
							<Colxx lg="2">
								<Label>Product</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="product_id"
									id="product_id"
									placeholder="All"
									value={this.state.product_id}						
									options={products}
									onChange={product_id => { this.setState({ product_id }) }}
								/>
							</Colxx>
							<Colxx lg="2">
								<Label className="d-block">&nbsp;</Label>
								<Button	onClick={this.dataListRender} color="primary" size="sm" >View Report</Button>
							</Colxx>
						</Row>
						</AvForm> 	
						<Row className="mt-4">
							<Colxx xxs="12">
								<div className="mb-4 dropdown-edit">
									{this.state.dataTableRefresh ? <table className="display table table-bordered table-striped table-responsive" ref={el=>this.el=el} >
										<thead>
											<tr>
												<th>&nbsp;</th>
												<th className="w-33">Product</th>
												{daterange.map((r,i)=>{
													return <th className="w-33" key={i}>{moment(r).format('MM/DD/YYYY')}</th>
												})}
												<th className="w-33">Total</th>
											</tr>
										</thead>
										<tbody></tbody>
									</table>:""}
								</div>					
							</Colxx>
						</Row>
					</Card>
				</BlockUi>
			</Fragment>
		);
	}
}
