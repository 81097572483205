import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, FormGroup, Input, CustomInput, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import classnames from "classnames";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL, themeColor} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

import ProfileData from "../../components/profile/ProfileData";
import ChangePassword from "../../components/profile/ChangePassword";
import RecentActivities from "../../components/profile/RecentActivities";
import MyTime from "../../components/profile/MyTime";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const styles = { 
  progressBar: {
    width: '0',
    backgroundColor: '#5cb85c',
  },
};

export default class Profile extends Component {

	constructor(props) {
		super(props);
		this.state = {		 		 
			activeFirstTab: '1',
			user_data: null,
			blocking: true,
		};
		
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.handleValidImageSubmit = this.handleValidImageSubmit.bind(this);
		this.clockIn = this.clockIn.bind(this);
		this.clockOut = this.clockOut.bind(this);
	}
	
	componentDidMount(){  
		var user_id = localStorage.getItem('user_id');
		var formData = new FormData();
        formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'profile/user',formData).then((res)=>{
			var data = res.data;
			
			if(data.user){
				this.setState({
					user_data: data.user,
					blocking:false
				});
			}else{
				this.setState({
					user_data: null,
					blocking:false
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	toggleFirstTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeFirstTab: tab
			});
		}
    }
	
	updateUser(values) {
		var user_data= this.state.user_data;
		if(typeof values.first_name!=='undefined'){
			user_data.first_name = values.first_name
		}
		if(typeof values.last_name!=='undefined'){
			user_data.last_name = values.last_name
		}
		this.setState({
			user_data
		});		
    }
	
	handleValidImageSubmit = () => {
		this.setState({ blocking:true });
		var user_id = localStorage.getItem('user_id');			
		var formData = new FormData(document.getElementById('customUploadForm'));
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'profile/update-profile-pic',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				swal("Success!",data.msg,"success");
				var user_data= this.state.user_data;
				
				user_data.profile_pic = data.profile_pic;
				document.getElementById("user_profile_img").src=BASE_URL+'users/'+user_data.profile_pic;
				
				this.setState({
					user_data,
					blocking:false
				});
			}else{
				this.setState({ blocking:false });
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			this.setState({ blocking:false });
		});
    }
	
	clockIn = () => {
		document.getElementById("clock-in-out").disabled=true;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var user_id = localStorage.getItem('user_id');			
		var formData = new FormData();
		formData.append('user_id',user_id);
		formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'profile/clock-in',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				swal("Success!",data.msg,"success");
				var user_data= this.state.user_data;
				user_data.running=1;
				user_data.timeId=data.timeId;
				
				this.setState({
					user_data
				});
			}
			document.getElementById("clock-in-out").disabled=false;
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			document.getElementById("clock-in-out").disabled=false;
		});
    }
	
	clockOut = () => {
		document.getElementById("clock-in-out").disabled=true;
		const user_data= this.state.user_data;
		
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		var user_id = localStorage.getItem('user_id');			
		var formData = new FormData();
		formData.append('user_id',user_id);
		formData.append('facility_id',facility_id);
		formData.append('id',user_data.timeId);

		axios.post(REST_API_END_POINT+'profile/clock-out',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){
				swal("Success!",data.msg,"success");
				var user_data= this.state.user_data;
				user_data.running=0;
				
				this.setState({
					user_data
				});
			}
			document.getElementById("clock-in-out").disabled=false;
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			document.getElementById("clock-in-out").disabled=false;
		});
    }
	
	render(){
		const user_data= this.state.user_data;
		var running=0;
		
		if(user_data!=null){
			running = user_data.running;
		}
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor}/>}> 
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading_text={user_data!=null ? user_data.first_name+' '+user_data.last_name:"Profile"} match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					
					<Row className="profile-wrapper page-tabs">
						<Colxx md="4" xxl="3">
							<Card className="p-4">							
								<div className="text-center profile-img-wrapper">
									<div className="text-center position-relative">
										<div className="text-center profile-img" onClick={()=>{ document.getElementById('profile_pic').click(); }}>
											<img src={user_data!=null ? BASE_URL+'users/'+user_data.profile_pic:BASE_URL+'users/avatar.jpg'} />
										</div>	
										<i className="simple-icon-pencil edit-profile-img" onClick={()=>{ document.getElementById('profile_pic').click(); }} />
									</div>		
								</div>		
								<div className="d-flex justify-content-center">
									<form id="customUploadForm" >
										<input type="file" name='thumbnail' id="profile_pic" accept="image/png, image/jpeg, image/gif" className="d-none" onChange={()=>{ document.getElementById('profile_pic_upload').click(); }} />
										<input type="hidden" name="user_id" id="user_id" value={this.state.user_data!==null ? this.state.user_data.id:0} />
										<button type="button" id="profile_pic_upload" className="d-none" onClick={this.handleValidImageSubmit}>Upload</button>
									</form>
									  
								</div>
								<div className="text-center mt-3">
									<h2 className="mb-0">{user_data!=null ? user_data.first_name+' '+user_data.last_name:""}</h2>
									<p>{user_data!=null ? user_data.email:""}</p>
								</div>
								<div className="text-center mb-3">
									<Button id="clock-in-out" color={running ? "danger":"info"} onClick={()=>{ if(running==1){ this.clockOut(); }else{ this.clockIn() } }} size="md" >{running==1 ? "Clock Out":"Clock In"}</Button>
								</div>
								<Nav tabs className="card-header-tabs">
									<NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "1",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("1");
											}}
											to="#"
										>
											<i className="simple-icon-user mr-2" />{" "}Profile
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "2",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("2");
											}}
											to="#"
										>
											<i className="simple-icon-lock mr-2" />{" "}Change Password
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "3",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("3");
											}}
											to="#"
										>
											<i className="simple-icon-list mr-2" />{" "}Recent Activities 
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={classnames({
											  active: this.state.activeFirstTab === "4",
											  "nav-link": true
											})}
											onClick={() => {
											  this.toggleFirstTab("4");
											}}
											to="#"
										>
											<i className="simple-icon-clock mr-2" />{" "}My Time Log
										</NavLink>
									</NavItem>
									
								</Nav>
							</Card>
						</Colxx>
						<Colxx md="8" xxl="9">
							<Card className="p-4">
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="1">
										<ProfileData  user_data={user_data} updateUser={this.updateUser} />
									</TabPane>
								</TabContent>
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="2">
										<ChangePassword />
									</TabPane>
								</TabContent>
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="3">
										<RecentActivities />
									</TabPane>
								</TabContent>
								<TabContent activeTab={this.state.activeFirstTab}>
									<TabPane tabId="4">
										<MyTime />
									</TabPane>
								</TabContent>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
