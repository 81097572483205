import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/customer/ItemDropdown";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectQuantity = [
    { label: "Gram", value: "1", key: 0 },
    { label: "Lbs", value: "2", key: 1 }
];

class CutTableModal extends Component{	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	
		this.state = {		
			search: '',
			selectProducts:[],
			selectProductType:null
		};	
	}
	
    handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
		
			if(this.state.selectProductType==null){
				swal("Warning","Please select the product","warning")
				return;
			}
			
			document.getElementById("cut-plant").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("cutForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
            formData.append('plant_list_name',this.state.plant_list_name);
			formData.append('strain_id',this.state.strain_id);
			formData.append('strain_name',this.state.strain_name);
			formData.append('plant_ids',this.state.plant_ids);
			formData.append('room_id',this.state.room_id);
						
			axios.post(REST_API_END_POINT+'growhouse/table-cut-plants',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==0){
					swal("Success",data.msg,"success").then(()=>{
						this.props.toggleModal();
						this.props.dataListRender();
						document.getElementById("cut-plant").disabled=false;
					});
				}else{
					let strain_name=data.strain_name;
					let strain_id=data.strain_id;
					let plant_ids=data.plant_ids;
					this.form && this.form.reset();
					
					this.setState({
						plant_ids,
						strain_id:strain_id,
						strain_name:strain_name,
						plants : data.plants,
						selectProductType:null
					});
					
					document.getElementById("cut-plant").disabled=false;
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("cut-plant").disabled=false;
			});

		}
	}
  
	componentDidUpdate(prevProps, prevState, snapshot){
		
		var rooms=[];
		var tables=[];
		var strain_id=[];
		var plant_list_name=[];
		var cureoptions=[];
		var strain_name=[];
		var plant_ids=[];
		var room_id=[];
		var table_id=[];
		
		
        if(this.props.modalOpen){					
            if (prevProps.table_ids !== this.props.table_ids && this.props.table_ids!=0) {
				var cure_options=[];
				var products=[];
				var rooms=[];
				var strain_id=[];
				var strain_name=[];
				var tables=[];
				var plants=[];
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('current_user_id',user_id);
				formData.append('facility_id',facility_id);	
				formData.append('table_ids',this.props.table_ids)
				formData.append('room_id',this.props.room_id);
				
					
				axios.post(REST_API_END_POINT+'growhouse/create-cut-plants-list',formData).then((res)=>{
					var data = res.data;
					
					let status=data.status;
					
					var msg =data.msg;
					
					if(status==0){
						swal(msg);
						this.props.toggleModal();
						return false;
					}
				
					products=data.products;
					
	                products = products.map((element,index)=>{
				        element.label=element.name;
				        element.value=element.id;
				        element.key=index;
				        return element;
		                });

					plant_list_name = data.plant_list_name;	   
					strain_id = data.strain_id;	   
					strain_name = data.strain_name;	   
					plant_ids = data.plant_ids;	   
					room_id = data.room_id;	   
					table_id = data.table_id;
					plants = data.plants;
						
					this.setState({
						products: products,
						selectProducts:products,
						plant_list_name:plant_list_name,
						strain_id:strain_id,
						strain_name:strain_name,
						plant_ids:plant_ids,
						room_id:room_id,
						table_id:table_id,
						plants					
					});
				})	
				.catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		}
	}  
	
	render() {
		
	   	let datas = this.state.plants;		
		
		const {selectProducts} = this.state;   
		  
		if(this.state.strain_name!=null){
			var strain_name=this.state.strain_name;
		}else{
			var strain_name;
		}

		if (this.state.search) {
			datas = datas.filter(row => {
				return row.barcode.includes(this.state.search.toLowerCase()) || row.strain.toLowerCase().includes(this.state.search.toLowerCase()) || row.phase.toLowerCase().includes(this.state.search.toLowerCase())
			})
		}
		
		const dataTableColumns = [			  
		  {
			Header: "Barcode",
			accessor: "barcode",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Strain",
			accessor: "strain",
			Cell: props => <p className="list-item-heading">{props.value}</p>
		  },
		  {
			Header: "Phase",
			accessor: "phase",
			Cell: props => <p className="text-muted">{props.value}</p>
		  },
		  {
			Header: "Birth Date",
			accessor: "birth_date",
			Cell: props => <p className="text-muted">{props.value}</p>
		  }
		  ,
		  {
			Header: "Time in Room",
			accessor: "time_in_room",
			Cell: props => <p className="text-muted">{props.value}</p>
		  }
		];	  
		return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Cut Plants
			</ModalHeader>
			
			<ModalBody>
				<Row>
					<Colxx xxs="12">
						<Label>Strain: <strong>{strain_name ? ((strain_name!=null)?strain_name:''):'' }</strong></Label>
					</Colxx>
				</Row>
				<AvForm
					id="cutForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<Row className="mt-2">
						<Colxx lg="6">
							<Label >Products</Label>
							 <Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="product_id"
								id="product"
								value={this.state.selectProductType}						
								options={selectProducts}
								onChange={selectProductType => { this.setState({ selectProductType }) }}
							/>
						</Colxx>	
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
							  <Label>Quantity<span className="text-danger">*</span></Label>
							  <AvField
								id="quantity"
								name="quantity"
								min="0"
								type="number"
								validate={{
									required: {
										value: true,
										errorMessage: "This field is required."
									}
								}}							
							  />
							</AvGroup>
						</Colxx>
									
					</Row>
							
					<Row className="mt-4">					
						<Colxx lg="12">
							<Button	
									id="cut-plant"					
								  color="primary"
								  size="sm"
								  className="mr-2"							  
								>
								  Cut Plant							  
							</Button>										
						</Colxx>
					</Row>
				</AvForm>
				<Card className="p-4 mt-4">									
					<div className="mb-4 dropdown-edit">							
							<FormGroup row>
								<Colxx lg="6">
									<Input
									  type="text"
									  name="table_search"
									  id="table_search"
									  placeholder="Search"
									  value={this.state.search}
									  onChange={e => this.setState({search: e.target.value})}
									/>
							  </Colxx>
							  </FormGroup>			
							<ReactTable
							  data={datas}
							  columns={dataTableColumns}
							  defaultPageSize={10}
							  filterable={false}
							  showPageJump={false}
							  PaginationComponent={DataTablePagination}
							  showPageSizeOptions={false}
							  defaultSorted={[{id: "send_on",desc: true}]}
							/>
					</div>
				</Card>	
			</ModalBody>
		
	  </Modal>
	  );
  }
};

export default CutTableModal;
