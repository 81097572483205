import React, { Component, Fragment } from "react";
import { Row } from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import PerfectScrollbar from "react-perfect-scrollbar";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import {	
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import swal from 'sweetalert';
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const selectSource = [
    { label: "Nutrient A", value: "1", key: 1 },
    { label: "Plant Pesticide", value: "2", key: 2},
    { label: "Spider Mite Spray", value: "3", key: 3 },
    { label: "Miracle Grow", value: "4", key: 4 },
];
  
const CustomTbodyComponent = props => (
  <div {...props} className={classnames("rt-tbody", props.className || [])}>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      {props.children}
    </PerfectScrollbar>
  </div>
);

class Documents extends Component {
	constructor(props) {
		super(props);
		this.mouseTrap = require("mousetrap");
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataListRender = this.dataListRender.bind(this);
		this.state = {     
			search: "",
			selectedItems: [],
			lastChecked: null,
			isLoading: false,
			selectSourceType : []
		};
	}
  
  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("add-document").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantDocumentForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_id',this.props.plant_id);
			

			axios.post(REST_API_END_POINT+'growhouse/upload-documents-to-plant',formData).then((res)=>{
				var data = res.data;
				
				var api_message=document.getElementById("api_message_documents");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-document").disabled=false;
				this.form && this.form.reset();
				this.dataListRender();

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-document").disabled=false;
			});

		}
	}
  
  
    componentDidMount(){		
		this.dataListRender();	   
	}
	
	onChange = e => {
		switch (e.target.name) {
		  case 'file_doc':
			if(e.target.files[0]){
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
			}else{
				e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
			}
		  break;
		  default:
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};

	
    dataListRender() {
		
		var plant_documents=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		formData.append('plant_id',this.props.plant_id);
		axios.post(REST_API_END_POINT+'growhouse/get-plant-documents',formData).then((res)=>{
			var data = res.data;
			
			plant_documents=data.plant_documents;

			if(data.plant_documents){
				this.setState({
			        plant_documents: data.plant_documents,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					plant_documents: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
  
  
  
  
  render() {
	   let data = this.state.plant_documents 
		const {selectAdditives} = this.state;  
	  
	  
    const dataTableColumns = [			  
	  {
		Header: "Documents",
		accessor: "name",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  },
	  {
		Header: "Date",
		accessor: "created_at",
		Cell: props => <p className="list-item-heading">{props.value}</p>
	  }
	];	
    return( 
      <Fragment>       
        <div className="p-4">		
        <div id="api_message_documents"></div>		
		  <div className="mb-4 dropdown-edit">
			{this.props.user_privileges.indexOf(146)!==-1 ? <AvForm
		        id="plantDocumentForm"
				ref={c => (this.form = c)}
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			  	<Row className="mt-2">
					<Colxx lg="6">
						<AvGroup className="error-l-125 error-t-negative">
							<Label>Documents</Label>
							<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
								<CustomInput
									type="file"
									name="file_doc"
									id="file_doc"
									accept="image/*"
									onChange={this.onChange}
								/>
							</InputGroup>
					</AvGroup>
					</Colxx>
				</Row>
			
				<Row className="mt-2 mb-4">
					<Colxx xxs="6">
						<Button
							id="add-document"
							color="primary"
							size="sm"
							className=""
							>
							Add
						</Button>
					</Colxx>
				</Row>
			</AvForm>:""}
			<hr/>
				<ReactTable
				  data={data}
				  TbodyComponent={CustomTbodyComponent}
				  columns={dataTableColumns}
				  defaultPageSize={5}
				  showPageJump={false}
				  showPageSizeOptions={true}
				  showPagination={true}
				  PaginationComponent={DataTablePagination}
				/>
			</div>
		</div>	         
      </Fragment>
    );
  }
  
};
export default Documents;
