import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup,AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";

import { Row, Card,Button, CardBody, CardTitle, FormGroup, Input,	Label, CustomInput, Badge } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import DatePicker from "react-datepicker";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { NavLink } from "react-router-dom";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactTable from "react-table";
import DataTablePagination from "../../../components/DatatablePagination";
import moment from 'moment';
import Switch from "rc-switch";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Batch extends Component {
	
	constructor(props) {
		super(props);
		
		this.handleSubmit = this.handleSubmit.bind(this);
		this.discardnoSubmit = this.discardnoSubmit.bind(this);
		this.discardyesSubmit = this.discardyesSubmit.bind(this);
		this.state = {
			textQuillStandart: "",
			selectJobType: null,
			selectTypes:[],
			selectStrain:[],
			selectStrains:[],
			selectRoom:[],
			selectTable:[],
			selectProducts:[],
			strain_id:[],
	        strain_name:[],
	        plant_ids:[],
	        batch_type:[],
	        h_ids:[],
			isCreateBatch:0,
			total:[],
			daterange:0,
			show_date:true,
			search: '',
			searchyes: '',
			searchno: '',
			discard_yes:false,discard_no:true,
			harvested:[],
			harvestedyes:[],
			harvestedno:[],
			
		};
	}
	
    handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
    
    handleChangeDate = date => {
		this.setState({
			startDate: date
		});
	};
	
	handleChangeDaten = date => {
		this.setState({
			endDate: date
		});
	};
	

    showDate = e =>{	
		if(e.currentTarget.value=='1'){				
			this.setState({ showdate:true })
		}else {				
			this.setState({ showdate:false })	
		}
	}	
	  
	handleSubmit(event, errors, values) {	
	
	   var harvestedyes=[];
	   var harvestedno=[];
	   var products=[];
	   var expected_weight=[];
	   var plant_ids=[];
	   var plant_ids1=[];
	   var h_ids=[];
	   var strain_id=[];
	   var strain_name=[];
	   var batch_type=[];
	
		if (errors.length === 0) {
			
			document.getElementById("add-batch").disabled=true;
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var user_id = localStorage.getItem('user_id');
			var formData = new FormData(document.getElementById("batchForm"));
            formData.append('use_date',this.state.show_date ? 1:0);			
			formData.append('facility_id',facility_id);
			
			axios.post(REST_API_END_POINT+'growhouse/create-batch',formData).then((res)=>{
				var data = res.data;
				if(data.status==1){
					harvestedyes=data.selected_harvest;
					harvestedno=data.selected_harvest;
				}
				var selected_harvest=data.selected_harvest;
				plant_ids=data.plant_ids;
			  			
				h_ids=data.h_ids;	
				products=data.products;	
				expected_weight=data.expected_weight;	
			
			
			if(typeof products!='undefined'){
			
			  products = products.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			    });
			}
				
		        if(this.state.discard_yes){
					this.setState({
						isCreateBatch:1,
						harvestedyes,
						plant_ids:plant_ids,
						expected_weight,
						h_ids:h_ids,
						strain_id:strain_id,
						strain_name:strain_name,
						batch_type:batch_type,
						selected_harvest
					});
				}else{
					this.setState({
						isCreateBatch:2,
						selectProducts:products,
						harvestedno,
						expected_weight,
						plant_ids:plant_ids,
						h_ids:h_ids,
						strain_id:strain_id,
						strain_name:strain_name,
						batch_type:batch_type,
						selected_harvest
					});
				}
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				//document.getElementById("add-batch").disabled=false;
			});

		}
	}
	 

    discardnoSubmit(event, errors, values) {
	
		if (errors.length === 0) {
			
			document.getElementById("add-discardno").disabled=true;
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var user_id = localStorage.getItem('user_id');
						
			var formData = new FormData(document.getElementById("discardnoForm"));			
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_ids',this.state.plant_ids);
			formData.append('h_ids',this.state.h_ids);
			formData.append('strain_id',this.state.selected_harvest[0].strain_id);
			formData.append('strain_name',this.state.selected_harvest[0].strain);
			formData.append('batch_type',this.state.selected_harvest[0].type);
			
			axios.post(REST_API_END_POINT+'growhouse/batch',formData).then((res)=>{
				var data = res.data;
				
			
				
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-discardno").disabled=false;
				document.body.scrollTop = 0; // For Safari
				document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
				this.form && this.form.reset();
				
				this.setState({	isCreateBatch:0 });
				this.listDataRender();
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-discardno").disabled=false;
			});

		}
	}

    discardyesSubmit(event, errors, values) {	
	
		if (errors.length === 0) {
			
			document.getElementById("add-discardyes").disabled=true;
			
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var user_id = localStorage.getItem('user_id');
			
			var formData = new FormData(document.getElementById("discardyesForm"));			
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
			formData.append('plant_ids',this.state.plant_ids);
			formData.append('quantity',this.state.selected_harvest.map(row=>row.qty).join(","));
			formData.append('h_ids',this.state.h_ids);
			formData.append('strain_id',this.state.selected_harvest[0].strain_id);
			formData.append('strain_name',this.state.selected_harvest[0].strain);
			formData.append('batch_type',this.state.selected_harvest[0].type);
			
			axios.post(REST_API_END_POINT+'growhouse/batch-waste',formData).then((res)=>{
				var data = res.data;
								
				var api_message=document.getElementById("api_message");
				api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
				
				window.setTimeout(function() {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);
				
				JQUERY_MODULE.$('.alert .close').on("click", function(e){
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
						JQUERY_MODULE.$(this).remove();
					});
				});
				
				document.getElementById("add-discardyes").disabled=false;
				document.body.scrollTop = 0; // For Safari
				document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
				this.form && this.form.reset();
  
				this.setState({	isCreateBatch:0 });
				this.listDataRender();
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-discardyes").disabled=false;
			});

		}
	}

    componentDidMount(){  
		this.listDataRender();
	} 
	
	listDataRender(){  
		var types=[];	
		var strains=[];	
		var rooms=[];
		var table=[];
		var harvested=[];
		
	    var user_id = localStorage.getItem('user_id');
	    var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);		
		
		axios.post(REST_API_END_POINT+'growhouse/data-for-create-batch',formData).then((res)=>{
			var data = res.data;
			

			types=data.types;
			harvested=data.harvested;
			strains=data.strains;
			rooms=data.rooms;
			table=data.table;
			
			
			types = types.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			strains = strains.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
		    rooms = rooms.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
				this.setState({
				    harvested,
					selectTypes:types,
					selectStrain:strains,
					selectRoom:rooms,
					selectAll:0,
					selectedIds:[],
					selectType:null,
					selectStrains:null,
					room_id:"",
					table_id:"",
					startDate:null,
					endDate:null,
					discard_yes:false,discard_no:true,
					showdate:false
				});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
		
	}
	
	getRoomData(room_id){
	  
		var tables=[];
		var rooms=[];
		var facility_id = localStorage.getItem('facility_id');
		
		facility_id =  dataCrypto.decrypt(facility_id);
			
		var formData = new FormData();
		formData.append('room_id',room_id);		
		
		
		axios.post(REST_API_END_POINT+'growhouse/get-active-tables-by-room',formData).then((res)=>{
			var data = res.data;
				
			tables=data.tables;	

			tables = tables.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			if(data.tables){
				this.setState({
					selectTable:tables,
				});
			}else{
				this.setState({

					selectTable:tables,
				});
			}
		}).catch(e =>{
			console.log("Addition failed , Error ", e);
			
		});	
	}
	
	subItem(){
        const { expected_weight,weight } = this.state;
		
		var pr = expected_weight!="" ? parseFloat(expected_weight):0;
		var qt = weight!="" ? parseFloat(weight):0;
		
		var total = pr-qt;
      
		this.setState({
			total
		});
	}

	render() {

		 let data = this.state.harvested
		 let data1 = this.state.harvestedyes
		 let data2 = this.state.harvestedno
		
		const {selectTypes,selectStrain,selectRoom,selectTable,selectProducts} = this.state;
		
		if (this.state.search) {
			data = data.filter(row => {
				return row.strain.toLowerCase().includes(this.state.search) || row.type.toLowerCase().includes(this.state.search) || row.barcode.includes(this.state.search)|| row.qty.includes(this.state.search) || row.room.toLowerCase().includes(this.state.search) || row.created_at.includes(this.state.search)  
			})
		}


    const dataTableColumns = [
	  {
		Header: "Strain",
		accessor: "strain",
		Cell: props => <p className="list-item-heading">{props.value}</p>	
	  },
	  {
		Header: "Type",
		accessor: "type",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Barcode",
		accessor: "barcode",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Weight",
		accessor: "qty",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Room",
		accessor: "room",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Table",
		accessor: "table_name",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	{
		Header: "Date",
		accessor: "created_at",
		Cell: props => <p className="text-muted">{props.value}</p>
	  }	  

	];
	
	
	if (this.state.searchyes) {
			data1 = data1.filter(row => {
			return row.strain.toLowerCase().includes(this.state.searchyes) || row.barcode.includes(this.state.searchyes)|| row.qty.includes(this.state.searchyes)  || row.created_at.includes(this.state.searchyes)  
		})
	}

    
    const dataTableColumns1 = [

	  {
		Header: "Strain",
		accessor: "strain",
		Cell: props => <p className="list-item-heading">{props.value}</p>	
	  },
	  {
		Header: "Barcode",
		accessor: "barcode",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	
	  {
		Header: "Weight",
		accessor: "qty",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	
      {
		Header: "Date",
		accessor: "created_at",
		Cell: props => <p className="text-muted">{props.value}</p>
	  }	  

	];
	
	if (this.state.searchno) {
			data2 = data2.filter(row => {
			return row.strain.toLowerCase().includes(this.state.searchno) || row.barcode.includes(this.state.searchno)|| row.qty.includes(this.state.searchno)  || row.created_at.includes(this.state.searchno)  
		})
	}
	
	const dataTableColumns2 = [
		
	
	  {
		Header: "Strain",
		accessor: "strain",
		Cell: props => <p className="list-item-heading">{props.value}</p>	
	  },
	
	  {
		Header: "Barcode",
		accessor: "barcode",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
	  {
		Header: "Weight",
		accessor: "qty",
		Cell: props => <p className="text-muted">{props.value}</p>
	  },
  
      {
		Header: "Date",
		accessor: "created_at",
		Cell: props => <p className="text-muted">{props.value}</p>
	  }	  

	];
	
	 if(this.state.isCreateBatch==0){
    return (
		<Fragment>
			<Row>
			  <Colxx xxs="12">
				<Breadcrumb heading="menu.batch" match={this.props.match} />
				<Separator className="mb-5" />
			  </Colxx>			    
			</Row>
		
			<Card className="p-4">
				<div id="api_message"></div>
				<AvForm
					id="batchForm"
					ref={c => (this.form = c)}
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				>
					<Row className="mt-4">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Type</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="type"
										id="type"
										placeholder="--Select Type--"
										value={this.state.selectType}						
										options={selectTypes}
										onChange={selectType => { this.setState({ selectType }) }}
									/>
							</AvGroup>
						</Colxx>
					</Row>
				
					<Row className="mb-4">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Strain</Label>
									<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name="strain_id"
										id="strain_id"
										placeholder="--Select Strain--"
										value={this.state.selectStrains}						
										options={selectStrain}
										onChange={selectStrains => { this.setState({ selectStrains }) }}
									/>
							</AvGroup>
						</Colxx>
					</Row>
					<Row className="mt-4">
						<Colxx lg="6">
							<AvGroup className="error-t-negative">
								<Label>From Rooms<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="room_id"
										id="room_id"
										placeholder="--Select Room--"									
										value={this.state.room_id}
										onChange={(e)=>{
											this.setState({
												room_id:e.target.value
											},()=>{
												this.getRoomData(this.state.room_id)
											});
										}}
									>
									<option value="" >--Select Room--</option>
									{selectRoom.map(opt=>{
										return <option key={opt.key} value={opt.value} >{opt.label}</option>
									})}
									</AvField>						  
							</AvGroup>
						</Colxx>
						<Colxx lg="6" className="mb-2">						
						<AvGroup className="error-t-negative">
							  <Label>From Table<span className="text-danger">*</span></Label>
									<AvField
										type="select"
										name="table_id"
										id="table_id"
										placeholder="--Select Table--"
										value={this.state.table_id}
										onChange={(e)=>{
											this.setState({
												table_id:e.target.value
											});
										}}
									>
										<option value="" >--Select Table Type--</option>
										{selectTable.map(opt=>{
											return <option key={opt.key} value={opt.value} >{opt.label}</option>
										})}		
									</AvField>
							</AvGroup>								
						</Colxx>
					</Row>

					<Row>
						<Colxx lg="12 mb-2 mt-2">
							<Label>													  
								<Switch
									className="custom-switch custom-switch-primary im-inline-block"
									defaultChecked={false}
									onChange={status => { this.setState({ showdate:status }) }}
								/>
								<span className="h6 ml-4">Use date</span>
							</Label>
						</Colxx>
					</Row>					
					
                    {this.state.showdate==true?<Row className="mt-2">
						<Colxx lg="6" className="mt-2">
							 <Label >From</Label>
							 <DatePicker
								className="p-0"
								selected={this.state.startDate}
								onChange={this.handleChangeDate}
								placeholderText="Date"
								dateFormat="MM/DD/YYYY HH:mm"
								id="from_date"
								name="from_date"
								autoComplete = "off"
								
							  />
						</Colxx>
						<Colxx lg="6" className="mt-2">
							 <Label >To</Label>
							 <DatePicker
								className="p-0"
								selected={this.state.endDate}
								onChange={this.handleChangeDaten}
								placeholderText="Date"
								dateFormat="MM/DD/YYYY HH:mm"
								id="to_date"
								name="to_date"
								autoComplete = "off"
								
							  />
						</Colxx>			
					</Row>:""}					
					<Row className="mt-2">
						<Colxx lg="6" className="mt-2">
							<Label>Discard</Label>	&nbsp;&nbsp;
							<Label>
							  <CustomInput
								id="discard_yes"
								type="radio"
								name="discard"							
								value="1"
								checked={this.state.discard_yes}
								onChange={() => { this.setState({ discard_yes:true,discard_no:false }) }}
								className="d-inline-block"
							  />
							  {" "}Yes
							</Label>&nbsp;&nbsp;
							<Label>
							  <CustomInput
								id="discard_no"
								type="radio"
								name="discard"							
								value="0"
								checked={this.state.discard_no}
								onChange={() => { this.setState({ discard_yes:false,discard_no:true }) }}
								className="d-inline-block"
							  />
								{" "}No
							</Label>
						</Colxx>
					</Row>  
				
					<Button className="mt-4 mb-4" color="primary" id="add-batch" >
						<IntlMessages id="customer.submit" />
					</Button>	 
				</AvForm>
					<Row>
						<Colxx lg="6">
							<FormGroup row>
								<Input
									type="text"
									name="table_search"
									id="table_search"
									placeholder="Search"
									value={this.state.search}
									onChange={e => this.setState({search: e.target.value.toLowerCase()})}
								/>							
							</FormGroup>	
						</Colxx>
				    </Row>
					<Row className="para-mb-0">
					  <Colxx xxs="12">				 
							<div className="mb-4 dropdown-edit">
								<ReactTable
								  data={data}
								  defaultPageSize={10}
								  columns={dataTableColumns}
								  filterable={false}
								  showPageJump={false}
								  PaginationComponent={DataTablePagination}
								  showPageSizeOptions={true}
								  defaultSorted={[{id: "send_on",desc: true}]}
								/>
							</div>
					  </Colxx>
					</Row>
			</Card>
			
		</Fragment>
    );
	  }else if(this.state.isCreateBatch==1){
		  return (		  
		  
		<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.batch" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>					
				</Row>
			<Card className="p-4">
			<div id="api_message"></div>
			<AvForm
			id="discardyesForm"
			className="av-tooltip tooltip-right-bottom"
            onSubmit={this.discardyesSubmit}
			>
			
			   <Row>	
					<FormGroup row>
						<Colxx lg="12">
							<Input
								type="text"
								name="table_search"
								id="table_search_yes"
								placeholder="Search"
								value={this.state.searchyes}
								onChange={e => this.setState({searchyes: e.target.value.toLowerCase()})}
								/>
						</Colxx>
					</FormGroup>	
				</Row>
			
				<Row>
			        <Colxx xxs="12">
						<ReactTable
						  data={data1}
						  defaultPageSize={10}
						  columns={dataTableColumns1}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
				    </Colxx>
				</Row>
				<Row className="mb-4">
					<Colxx lg="12">			
						<Button color="primary" id="add-discardyes" >
							Move to Waste
						</Button>{" "}<Button type="button" color="secondary" outline onClick={()=>{ this.setState({ isCreateBatch:0 }) }}>
							Cancel
						</Button>
					</Colxx>
				</Row>
	
			</AvForm>
			</Card>
		</Fragment>
		  );
	 }else{
  return (		  
		  
		<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.batch" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>				
				</Row>
			<Card className="p-4">
			<div id="api_message"></div>
			<AvForm
			id="discardnoForm"
			className="av-tooltip tooltip-right-bottom"
            onSubmit={this.discardnoSubmit}
			>
				<Row>
					<Colxx lg="6">
						<FormGroup row>
							<Input
								type="text"
								name="table_search"
								id="table_search_no"
								placeholder="Search"
								value={this.state.searchno}
								onChange={e => this.setState({searchno: e.target.value.toLowerCase()})}
								/>
						
						</FormGroup>	
					</Colxx>
				</Row>
			
			
				<Row className="para-mb-0">
			        <Colxx xxs="12">
						<ReactTable
						  data={data2}
						  defaultPageSize={10}
						  columns={dataTableColumns2}
						  filterable={false}
						  showPageJump={false}
						  PaginationComponent={DataTablePagination}
						  showPageSizeOptions={true}
						  defaultSorted={[{id: "send_on",desc: true}]}
						/>
				    </Colxx>
				</Row>
				<Row>
					<Colxx lg="12" className="mb-2">
						<AvGroup className="error-t-negative">
								  <Label>Expected Weight</Label>
								  <AvField
									id="expected_weight"
									name="expected_weight"
									type="text"
									readOnly={true}
									value={this.state.expected_weight}
									onChange={e => this.setState({expected_weight: e.target.value})}
									validate={{
										required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
								  />
						</AvGroup>
						
					</Colxx>
                </Row>
            	<Row>
					<Colxx lg="12" className="mb-2">
						<AvGroup className="error-t-negative">
								  <Label>Weight<span className="text-danger">*</span></Label>
								  <AvField
									id="actual_weight"
									name="actual_weight"
									type="number"									
									value={this.state.weight}
									onChange={e => this.setState({ weight: e.target.value}, ()=>{ this.subItem() })}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										},
										min: {
											value: 0,
											errorMessage: "Please enter a value greater than or equal to 0"
										},
										max: {
											value: this.state.expected_weight,
											errorMessage: "Weight must not exceed expected weight"
										},
									}}
								  />
						</AvGroup>
						
					</Colxx>
                </Row>					
				<Row>
					<Colxx lg="12" className="mb-2">
						<AvGroup className="error-t-negative">
								  <Label>Waste<span className="text-danger">*</span></Label>
								  <AvField
									id="waste"
									name="waste"
									type="text"
									readOnly={true}
									value={this.state.total}
									onChange={e => this.setState({waste: e.target.value})}
									validate={{
										required: {
										value: true,
										errorMessage: "This field is required."
									  }
									}}
								  />
						</AvGroup>
					</Colxx>
                </Row>
            	<Row className="mb-2">
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Product</Label>
								<Select
									components={{ Input: CustomSelectInput }}
									className="react-select"
									classNamePrefix="react-select"						
									name="product_id"
									id="product_id"
									placeholder="--Select Product--"
									value={this.state.selectProduct}						
									options={selectProducts}
									onChange={selectselectProduct => { this.setState({ selectselectProduct }) }}
								/>
							</AvGroup>
						</Colxx>
				</Row>
				<Row className="mb-4">
					<Colxx lg="12">			
						<Button color="primary" id="add-discardno" >
							Create
						</Button>{" "}<Button type="button" color="secondary" outline onClick={()=>{ this.setState({ isCreateBatch:0 }) }}>
							Cancel
						</Button>
					</Colxx>
				</Row>			
			</AvForm>
			</Card>
	    </Fragment>
		  );
	 }
  }
}
