import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Card, CardBody, Table
} from "reactstrap";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';

import { zeroPad, getCurrencySymbol } from "../../helpers/Utils";
import number_format from "number_format-php";
import {REST_API_BASE_URL} from "../../constants/defaultValues";

export default class PrintShippingManifestModalContent extends Component{
		
    render() {
		const { transfer_details, products_details, delivery_details, order_details, driver_details, signature, facility_data } = this.props;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax=0;
		var sub_total=0;
		if(transfer_details==null){
			return "";
		}
		
		var count_of_product=products_details.length;
		
		var first_6_products=[];
		for(let i=0;i<6;i++){
			if(typeof products_details[i]!='undefined'){
				let row = products_details[i];
				
				first_6_products.push(<tr className="body-row" key={i}>
									<td className="tag-number">{typeof row['barcode']!='undefined' ? row['barcode']:"&nbsp;"}</td>
									<td className="item-name ">{typeof row['product_name']!='undefined' ? row['product_name']:""}</td>
									<td className="qty-ordered">{typeof row['product_qty']!='undefined' ? row['product_qty']:""}</td>
									<td className="qty-received gray-shade" ></td>
									<td className="unit-cost">{typeof row['product_unit_price']!='undefined' ? <Fragment>{current_currency_symbol}{row['product_unit_price']}</Fragment>:""}</td>
									<td className="total-cost">{typeof row['product_total']!='undefined' ? <Fragment>{current_currency_symbol}{row['product_total']}</Fragment>:""}</td>
									<td className="gray-shade"></td>
									<td className="gray-shade"></td>
								</tr>);
			}
		}
		
		var page_count=0;
		
		if(count_of_product>6){
			page_count=Math.ceil((count_of_product-6)/19);
		}
		
		var additional_pages=[];
		
		if(page_count>0){
			var current_page=1; 
			var start_i=6;	
			var last_i=start_i+20;
		
			for(let j=0;j<page_count;j++){
				let products_6=[];
				for(let i=start_i;i<last_i;i++){
					if(typeof products_details[i]!='undefined'){
						let row = products_details[i];
						
						products_6.push(<tr className="body-row" key={i}>
											<td className="tag-number">{typeof row['barcode']!='undefined' ? row['barcode']:"&nbsp;"}</td>
											<td className="item-name ">{typeof row['product_name']!='undefined' ? row['product_name']:""}</td>
											<td className="qty-ordered">{typeof row['product_qty']!='undefined' ? row['product_qty']:""}</td>
											<td className="qty-received gray-shade" ></td>
											<td className="unit-cost">{typeof row['product_unit_price']!='undefined' ? <Fragment>{current_currency_symbol}{row['product_unit_price']}</Fragment>:""}</td>
											<td className="total-cost">{typeof row['product_total']!='undefined' ? <Fragment>{current_currency_symbol}{row['product_total']}</Fragment>:""}</td>
											<td className="gray-shade"></td>
											<td className="gray-shade"></td>
										</tr>);
					}
				}
		
				additional_pages.push(<div id={"p"+(current_page+1)} key={j} className="pageArea" style={{overflow: 'hidden', position: 'relative', marginTop:'20px', marginLeft:'auto', marginRight:'auto', background:'#fff'}} >
					<div className="content-wrapper">
						<div className="page_head">SALES INVOICE/ SHIPPING MANIFEST</div>
						<div className="page_head">PRODUCT DETAILS ATTACHMENT PAGE</div>
						<div className="permanent_ink">PLEASE USE PERMANENT INK FOR ALL ITEMS</div>
						<div>
							<div className="sales-row">
								<div className="left">
									<table className="table-layout">
										<tbody>
											<tr>
												<td className="attached_page">INVOICE/MANIFEST NUMBER <br/>ATTACHED TO:</td>
												<td className="invoice_number2">#{zeroPad(transfer_details['order_number'],6)}</td>								
											</tr>
										</tbody>			
									</table>
								</div>

								<div className="left">
									<table className="table-layout">						
										<tbody>
											<tr>
												<td className="attached_page">ATTACHED PAGE</td>
												<td className="page_number">{current_page}</td>
												<td className="attached_page page_number_of">OF</td>
												<td className="page_number">{page_count}</td>
												<td className="attached_page page_number_total">TOTAL PAGES</td>
											</tr>
										</tbody>			
									</table>
								</div>
							</div>

							<div className="clearfix"></div>
							<div className="clearfix"></div>

							<div className="sales-row">
								<table className="table-layout shipping-detail">
									<thead>
										<tr>
											<th colSpan="8">
											PRODUCT SHIPPED DETAILS <br/>
											<div className="sub-head">SHIPPER COMPLETES ALL THE UNSHADED COLUMNS BELOW. RECEIVER COMPLETES <u>ONLY</u> THE SHADED COLUMNS BELOW</div>
											<div className="sub-head2">(Please attach additional pages, if needed)</div>
											</th>
										</tr>
									</thead>

									<tbody>
										<tr className="head-row">
											<td className="tag-number" rowSpan="2">UID TAG NUMBER <br/><span>(IF APPLICABLE)</span></td>
											<td className="item-name " rowSpan="2">ITEM NAME <u>AND</u> <br/>PRODUCT DESCRIPTION <br/><span>(INCLUDE WEIGHT OR COUNT)</span></td>							
											<td className="qty-ordered" rowSpan="2">QTY ORDERED</td>							
											<td className="qty-received gray-shade" rowSpan="2">QTY REC’D</td>							
											<td className="unit-cost" rowSpan="2">UNIT COST</td>							
											<td className="total-cost" rowSpan="2">TOTAL COST</td>							
											<td className="retail-only gray-shade" colSpan="2" >RETAIL ONLY</td>							
										</tr>

										<tr className="head-row">						
											<td className="unit-retail gray-shade">UNIT RETAIL VALUE</td>							
											<td className="total-retail gray-shade">TOTAL RETAIL VALUE</td>							
										</tr>

										{products_6}

									</tbody>			
								</table>								
							</div>

							<div className="clearfix"></div>

							<div className="sales-row">
								<table className="table-layout rejection">
									<thead>
										<tr>
											<th colSpan="2">PRODUCT REJECTION</th>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td colSpan="2" className="sub-head">IF PRODUCTS ARE REJECTED, PLEASE CIRCLE THE ITEMS BEING REJECTED IN THE PRODUCT SHIPPED DETAILS SECTION ABOVE</td>							
										</tr>

										<tr className="last-row">
											<td className="reason">REASON FOR REJECTION: </td>
											<td></td>							
										</tr>
									</tbody>			
								</table>

								<table className="table-layout receipt-confirm">
									<thead>
										<tr>
											<th colSpan="5">PRODUCT RECEIPT CONFIRMATION</th>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td colSpan="5" className="sub-head">
												I CONFIRM THAT THE CONTENTS OF THIS SHIPMENT MATCH IN WEIGHT AND COUNT AS INDICATED ABOVE. <br/>
												I AGREE TO TAKE CUSTODY OF ALL ITEMS AS INDICATED RECEIVED ABOVE – AND WHICH ARE NOT CIRCLED. <br/>
												THE PRODUCTS CIRCLED ABOVE ARE REJECTED FOR DELIVERY AND REMAIN IN THE CUSTODY OF THE DISTRIBUTOR FOR RETURN TO THE SHIPPER AS INDICATED ON THIS FORM.
											</td>							
										</tr>						

										<tr className="last-row">
											<td className="attach_invoice">THIS PRODUCT DETAILS ATTACHMENT PAGE IS ATTACHED TO INVOICE #</td>											
											<td className="attach_invoice_no">{zeroPad(transfer_details['order_id'],6)}</td>							
										</tr>
									</tbody>			
								</table>

								<table className="table-layout receipt-confirm extra-last">
									<tbody>						
										<tr className="last-row">
											<td className="person-receiving">NAME OF PERSON RECEIVING AND/OR REJECTING PRODUCT: </td>
											<td className="second"></td>							
											<td className="third"></td>							
											<td className="forth">PHONE NUMBER:</td>				
											<td></td>							
										</tr>

										<tr className="last-row">
											<td className="person-receiving">SIGNATURE OF PERSON RECEIVING AND/OR REJECTING PRODUCT: </td>
											<td className="second">{signature!=null ? <img src={REST_API_BASE_URL+"uploads/sign/"+signature.sign_img} style={{height:"36px"}} />:""}</td>							
											<td className="third"></td>								
											<td className="forth">DATE SIGNED:</td>				
											<td></td>							
										</tr>

									</tbody>			
								</table>				
							</div>
						</div>
					</div>
				</div>);
				
				start_i=last_i;
				last_i=start_i+20;
				current_page++;
			}
		}
		
		return (
			<div>				
				<style id="fonts1" type="text/css" dangerouslySetInnerHTML={{__html: `
					@font-face {
						font-family: TwCenMT-Regular_1a0;
						src: url("${REST_API_BASE_URL}administrator/manifest/fonts/TwCenMT-Regular_1a0.woff") format("woff");
					}

					@font-face {
						font-family: TwCenMT-Bold_19-;
						src: url("${REST_API_BASE_URL}administrator/manifest/fonts/TwCenMT-Bold_19-.woff") format("woff");
					}

					@font-face {
						font-family: TwCenMT-BoldItalic_1a2;
						src: url("${REST_API_BASE_URL}administrator/manifest/fonts/TwCenMT-BoldItalic_1a2.woff") format("woff");
					}
				`}} />

				<style id="body_style" type="text/css" dangerouslySetInnerHTML={{__html: `
					body{padding:0px; margin:0px; font-family:Arial, Helvetica, sans-serif;}

					@page {
						size:A4;
						margin:0;
					}

					@media print{
						body{
							background-color:#fff;
						}
					}
					
					.pageArea{ width: 935px; height: 1354px; -webkit-print-color-adjust: exact;}
					.pageArea *{ color: #000!important; }
					.pageArea:last-child{ height: 1350px; }
					.content-wrapper{ margin-top: 30px; margin-left: 46px; margin-right:55px; }
					.clearfix{ clear:both; }

					.page_head{ font-size: 28px; font-family: TwCenMT-Bold_19-; color: rgb(56,86,35) !important; text-align: center; }
					.permanent_ink{ font-size: 12px; font-family: TwCenMT-BoldItalic_1a2; color: #000; text-align:left; display:inline-block; }

					.sales-row{ margin-top: 15px; }
					.sales-row:first-child{ margin-top: 2px; }
					.sales-row .left:first-child{ width:403px }
					.sales-row .left:last-child{ width:404px }
					
					.table-layout {	border-collapse: collapse; width:100%; }					
					.table-layout th,.table-layout td{ border: 1px solid #000; text-align: left;}
					.invoice_number_text{ font-size: 15px; line-height: 1; font-family: TwCenMT-Bold_19-; color: rgb(0,0,0); padding: 4px 7px; text-align: left; background:#f1f1f1; width: 142px; }
					.invoice_number{ font-size: 21px; color:#000; line-height: 1.6; font-family:Arial, Helvetica, sans-serif; padding: 4px 7px; text-align: left; width:230px;}
					.invoice_number2{ font-size: 21px; color:#000; font-weight: 700; font-family:Arial, Helvetica, sans-serif; padding: 4px 7px; text-align: left; width:212px;}

					.page_number{ font-size: 20px; font-weight: 700; font-family:Arial, Helvetica, sans-serif; padding: 4px 7px; text-align: center !important; width:30px; }
					.page_number_of{ width:30px; }
					.page_number_total{ width:120px; height:30px;}

					.attached_page{ font-size: 14px; font-family: TwCenMT-Bold_19-; color: rgb(0,0,0); padding: 4px 7px; line-height: 1; }
					.attached_page.second{ width:60px;  text-align:center; padding: 2px 6px; }
					.attached_page.third{ width:105px; }
					.attached_page.forth{ width:25px; font-size: 20px; text-align: center; font-weight: 700; font-family:Arial, Helvetica, sans-serif;}

					.tick-box{ border:2px solid #000; width:28px; height:25px; float:left; text-align: center; margin-right:4px; padding-top:4px; line-height: 1; }
					.tick-box:last-child{ margin-right:0; }
					.tick-box2{ border:2px solid #000; width:30px; height:19px; float:left; margin-bottom:1px; text-align: center; line-height: 1; margin-right: 3px; }

					.left{ float:left; margin-right:25px;}
					.left:last-child{ margin-right:0; }

					.date_format{ width:120px; font-weight:700; padding-left:6px; line-height: 1; }
					.time_format{ width:115px; font-family:TwCenMT-Bold_19-; font-size: 14px;}
					.time_format .am{ float:left; margin-right:4px; font-weight:700;}
					.time_format .time{ float:left; width:52px; height:40px; padding-left:6px; line-height: 40px; font-size: 18px; font-family:Arial, Helvetica, sans-serif; font-weight:700; }

					.information th{ width:389px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.information td{ text-align:left; padding: 3px 6px; font-size: 15px; font-family: TwCenMT-Regular_1a0; color: #000;}
					.information td.first{ width:140px; }
					.information td.second{ width:230px; font-size: 13px; font-family:Arial, Helvetica, sans-serif;}
					.information td.third{ width:25px; padding:0; border-bottom:0; border-top:0;}

					.information2 th{ width:389px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.information2 td{ text-align:left; padding: 3px 6px; font-size: 15px; font-family: TwCenMT-Regular_1a0; color: #000;}
					.information2 td.first{ width:143px; }
					.information2 td.second{ width:233px; font-size: 13px; font-family:Arial, Helvetica, sans-serif;}
					.information2 td.third{ width:25px; padding:0; border-bottom:0; border-top:0;}
					.information2 .last-row { height:48px;}

					.shipping-detail th{ width:833px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.shipping-detail .sub-head{ font-family: TwCenMT-Bold_19-; text-align:center; font-size: 15px; }
					.shipping-detail .sub-head2{ font-family: TwCenMT-Regular_1a0; text-align:center; font-size: 15px; font-weight: 200; text-transform: none;}
					.shipping-detail td{ text-align:left; padding: 2px 6px; font-size: 14px; font-family: TwCenMT-Regular_1a0; color: #000;}
					.shipping-detail .head-row { height:20px; text-transform:uppercase;}
					.shipping-detail .head-row td{ line-height: 1; font-family: TwCenMT-Bold_19-; font-size: 12px; text-align:center;}
					.shipping-detail .head-row td span{ font-size:11px; }
					.shipping-detail .tag-number{ width:226px; }
					.shipping-detail .item-name{ width:202px; }
					.shipping-detail .qty-ordered{ width:63px; text-align: center;}
					.shipping-detail .qty-received{ width:55px; }
					.shipping-detail .unit-cost{ width:40px; text-align: right; }
					.shipping-detail .total-cost{ width:64px; text-align: right; }
					.shipping-detail .retail-only{ width:144px; }
					.shipping-detail .unit-retail{ width:49px; }
					.shipping-detail .total-retail{ width:95px; }
					.shipping-detail .gray-shade{ background:#bebebe; }
					.shipping-detail .body-row { height:20px;}
					.shipping-detail .body-row td{ padding: 4px 6px; font-family:Arial, Helvetica, sans-serif;}

					.rejection th{ width:833px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.rejection .sub-head{ font-family: TwCenMT-BoldItalic_1a2; text-align:center; font-size: 16px; }
					.rejection td{ line-height: 1; text-align:left; padding: 2px 6px; font-size: 16px; font-family: TwCenMT-Regular_1a0; color: #000;}
					.rejection td.reason{ width:175px; }
					.rejection .last-row { height:54px;}

					.receipt-confirm th{ border-top:0; width:833px; text-align:center; padding: 2px 6px; font-size: 20px; font-family: TwCenMT-Bold_19-; color: #000; background:#f1f1f1; text-transform:uppercase;}
					.receipt-confirm .sub-head{ line-height: 1.1; font-family: TwCenMT-Bold_19-; text-align:left; font-size: 14px; padding:6px 7px;}
					.receipt-confirm td{ line-height: 1.1; text-align:left; padding: 2px 6px; font-size: 14px; font-family: TwCenMT-Regular_1a0; color: #000; }
					.receipt-confirm td.person-receiving{ width:230px; background:#f1f1f1;}
					.receipt-confirm td.second{ width:340px; }
					.receipt-confirm td.third{ width:18px; padding:0; border-bottom:0; border-top:0;}
					.receipt-confirm td.forth{ width:74px; background:#f1f1f1; text-align:right;}
					.receipt-confirm .last-row { height:37px;}
					.receipt-confirm .last-row .attach_invoice{ width: 485px; }
					.receipt-confirm .last-row .attach_invoice_no{ font-size: 20px; font-family: Arial, Helvetica, sans-serif; padding: 4px 7px; }
					.extra-last{ width:100%; }
					.extra-last .last-row:first-child td{border-top:0px;}

					.clearfix{ width:100%;clear:both; }

				`}} />

				<div>

					<div id="p1" className="pageArea" style={{overflow: 'hidden', position: 'relative', marginTop:'20px', marginLeft:'auto', marginRight:'auto', background:'#fff'}}>
						<div className="content-wrapper">
							<div className="page_head">SALES INVOICE/ SHIPPING MANIFEST</div>
							<div className="clearfix">
								<div className="permanent_ink" style={{ verticalAlign: 'bottom' }}>PLEASE USE PERMANENT INK FOR ALL ITEMS</div>
								<div style={{display: 'inline-block', width: '618px',textAlign: 'right', marginTop: '-35px'}} >
									{delivery_details!=null ? (typeof delivery_details.qr_code_img!='undefined' && delivery_details.qr_code_img!='' ? <img src={REST_API_BASE_URL+"uploads/qrcode/"+delivery_details.qr_code_img} style={{width: "100px"}} />:""):""}
								</div>
							</div>

							<div className="clearfix">
								<div className="sales-row">
									<div className="left" >
										<table className="table-layout">
											<thead>
												<tr>
													<th className="invoice_number_text">INVOICE/MANIFEST <br/>NUMBER:</th>
													<th colSpan="3" className="invoice_number">#{zeroPad(transfer_details['order_number'],6)}</th>
												</tr>
											</thead>

											<tbody>
												<tr>
													<td className="attached_page">ATTACHED PAGE(S)? </td>
													<td className="attached_page second">
														YES &nbsp;&nbsp;&nbsp;NO <br/>
														<div className="tick-box">{count_of_product>6 ? <i className="fa fa-check" aria-hidden="true" />:""}</div>
														<div className="tick-box">{count_of_product<=6 ? <i className="fa fa-check" aria-hidden="true" />:""}</div>
													</td>
													<td className="attached_page third"># OF ATTACHED <br/>PAGES: </td>
													<td className="attached_page forth">{count_of_product>6 ? Math.ceil((count_of_product-6)/19):0}</td>
												</tr>
											</tbody>			
										</table>
									</div>

									<div className="left" >
										<table className="table-layout">
											<thead>
												<tr>
													<th className="invoice_number_text">ACTUAL DATE AND <br/>TIME OF DEPARTURE: </th>
													<th className="date_format">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? moment(delivery_details['accept_timestamp']*1000).format('MM/DD/YYYY'):""}</th>
													<th className="time_format">
														<div className="time">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? moment(delivery_details['accept_timestamp']*1000).format('hh:mm'):""}</div>
														<div style={{float:'left'}}>
															<div className="tick-box2">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? (moment(delivery_details['accept_timestamp']*1000).format('A')=='AM' ? <i className="fa fa-check" aria-hidden="true"/>:""):""}</div><div className="am">AM</div>
															<div className="clearfix"></div>
															<div className="tick-box2">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? (moment(delivery_details['accept_timestamp']*1000).format('A')=='PM' ? <i className="fa fa-check" aria-hidden="true"/>:""):""}</div><div className="am">PM</div>
														</div>
													</th>
												</tr>
											</thead>

											<tbody>
												<tr>
													<td className="attached_page">ESTIMATED DATE AND <br/>TIME OF ARRIVAL: </td>
													<td className="date_format">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? moment(delivery_details['expected_timestamp']*1000).format('MM/DD/YYYY'):""}</td>
													<td className="time_format">
														<div className="time">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? moment(delivery_details['expected_timestamp']*1000).format('hh:mm'):""}</div>
														<div style={{float:'left'}}>
															<div className="tick-box2">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? (moment(delivery_details['expected_timestamp']*1000).format('A')=='AM' ? <i className="fa fa-check" aria-hidden="true"/>:""):""}</div><div className="am">AM</div>
															<div className="clearfix"></div>
															<div className="tick-box2">{delivery_details['accept_time']!='0000-00-00 00:00:00' ? (moment(delivery_details['expected_timestamp']*1000).format('A')=='PM' ? <i className="fa fa-check" aria-hidden="true"/>:""):""}</div><div className="am">PM</div>
														</div>
													</td>
												</tr>
											</tbody>			
										</table>
									</div>
								</div>

								<div className="clearfix"></div>

								<div className="sales-row">
									<div className="left">
										<table className="table-layout information">
											<thead>
												<tr>
													<th colSpan="2">SHIPPER INFORMATION</th>
												</tr>
											</thead>

											<tbody>
												<tr>
													<td className="first">STATE LICENSE #</td>
													<td className="second">{facility_data['license_number']!=null ? facility_data['license_number']:""}</td>							
												</tr>

												<tr>
													<td className="first">TYPE OF LICENSE</td>
													<td className="second">{facility_data['type_of_license']!=null ? facility_data['type_of_license']:""}</td>							
												</tr>

												<tr>
													<td className="first">BUSINESS NAME</td>
													<td className="second">{facility_data['facility_name']!=null ? facility_data['facility_name']:""}</td>							
												</tr>

												<tr>
													<td className="first">BUSINESS ADDRESS</td>
													<td className="second">{facility_data['facility_address']!=null ? facility_data['facility_address']:""}</td>							
												</tr>

												<tr>
													<td className="first">CITY, STATE, ZIP CODE</td>
													<td className="second">{facility_data['facility_zip_code']!=null ? facility_data['facility_zip_code']:""}</td>							
												</tr>

												<tr>
													<td className="first">PHONE NUMBER</td>
													<td className="second">{facility_data['facility_phone']!=null ? facility_data['facility_phone']:""}</td>							
												</tr>

												<tr>
													<td className="first">CONTACT NAME</td>
													<td className="second">{facility_data['contact_name']!=null ? facility_data['contact_name']:""}</td>							
												</tr>

											</tbody>			
										</table>
									</div>

									<div className="left">
										<table className="table-layout information">
											<thead>
												<tr>
													<th colSpan="2">RECEIVER INFORMATION</th>
												</tr>
											</thead>

											<tbody>
												<tr>
													<td className="first">STATE LICENSE #</td>
													<td className="second">{order_details['license']!=null ? order_details['license']:""}</td>							
												</tr>

												<tr>
													<td className="first">TYPE OF LICENSE</td>
													<td className="second">{order_details['type_of_license']!=null ? order_details['type_of_license']:""}</td>							
												</tr>

												<tr>
													<td className="first">BUSINESS NAME</td>
													<td className="second">{order_details['company_name']!=null ? order_details['company_name']:""}</td>							
												</tr>

												<tr>
													<td className="first">DELIVERY ADDRESS</td>
													<td className="second">{order_details['shipping_address']!=null ? order_details['shipping_address']:""}</td>							
												</tr>

												<tr>
													<td className="first">CITY, STATE, ZIP CODE</td>
													<td className="second">{order_details['zip_code']!=null ? order_details['zip_code']:""}</td>							
												</tr>

												<tr>
													<td className="first">PHONE NUMBER</td>
													<td className="second">{order_details['customer_phone']!=null ? order_details['customer_phone']:""}</td>							
												</tr>

												<tr>
													<td className="first">CONTACT NAME</td>
													<td className="second">{order_details['customer_name']!=null ? order_details['customer_name']:""}</td>							
												</tr>

											</tbody>			
										</table>
									</div>
								</div>

								<div className="clearfix"></div>

								<div className="sales-row">
									<table className="table-layout information2">
										<thead>
											<tr>
												<th colSpan="6">DISTRIBUTOR INFORMATION</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="first">STATE LICENSE #</td>
												<td className="second">{facility_data['license_number']!=null ? facility_data['license_number']:""}</td>
												<td className="third"></td>
												<td className="first">DRIVER’S NAME </td>
												<td className="second" colSpan="2">{driver_details!=null ? driver_details['name']:""}</td>
											</tr>							

											<tr>
												<td className="first">BUSINESS NAME</td>
												<td className="second">{facility_data['facility_name']!=null ? facility_data['facility_name']:""}</td>
												<td className="third"></td>
												<td className="first">CA DRIVER’S LICENSE#</td>
												<td className="second" colSpan="2">{driver_details!=null ? driver_details['license_no']:""}</td>								
											</tr>

											<tr>
												<td className="first">STREET ADDRESS</td>
												<td className="second">{facility_data['facility_address']!=null ? facility_data['facility_address']:""}</td>
												<td className="third"></td>
												<td className="first">VEHICLE MAKE</td>
												<td className="second" colSpan="2">{delivery_details!=null ? (typeof delivery_details['vehicle_make']!='undefined' ? delivery_details['vehicle_make']:""):""}</td>
											</tr>

											<tr>
												<td className="first">CITY, STATE, ZIP</td>
												<td className="second">{facility_data['facility_zip_code']!=null ? facility_data['facility_zip_code']:""}</td>	
												<td className="third"></td>
												<td className="first">VEHICLE MODEL</td>
												<td className="second" colSpan="2">{delivery_details!=null ? (typeof delivery_details['vehicle_model']!='undefined' ? delivery_details['vehicle_model']:""):""}</td>
											</tr>

											<tr>
												<td className="first">PHONE NUMBER</td>
												<td className="second">{facility_data['facility_phone']!=null ? facility_data['facility_phone']:""}</td>
												<td className="third"></td>
												<td className="first">VEHICLE LIC. PLATE #</td>
												<td className="second" colSpan="2">{delivery_details!=null ? (typeof delivery_details['no_plate']!='undefined' ? delivery_details['no_plate']:""):""}</td>
											</tr>

											<tr className="last-row">
												<td className="first">CONTACT NAME</td>
												<td className="second">{facility_data['contact_name']!=null ? facility_data['contact_name']:""}</td>
												<td className="third"></td>
												<td className="first">ACTUAL DATE AND <br/>TIME OF ARRIVAL</td>
												<td className="date_format"></td>
												<td className="time_format">
													<div className="time"></div>
													<div style={{float:'left'}}>
														<div className="tick-box2"></div><div className="am">AM</div>
														<div className="clearfix"></div>
														<div className="tick-box2"></div><div className="am">PM</div>
													</div>
												</td>
											</tr>

										</tbody>			
									</table>				
								</div>

								<div className="clearfix"></div>

								<div className="sales-row">
									<table className="table-layout shipping-detail">
										<thead>
											<tr>
												<th colSpan="8">
												PRODUCT SHIPPED DETAILS <br/>
												<div className="sub-head">SHIPPER COMPLETES ALL THE UNSHADED COLUMNS BELOW. RECEIVER COMPLETES <u>ONLY</u> THE SHADED COLUMNS BELOW</div>
												<div className="sub-head2">(Please attach additional pages, if needed)</div>
												</th>
											</tr>
										</thead>

										<tbody>
											<tr className="head-row">
												<td className="tag-number" rowSpan="2">UID TAG NUMBER <br/><span>(IF APPLICABLE)</span></td>
												<td className="item-name " rowSpan="2">ITEM NAME <u>AND</u> <br/>PRODUCT DESCRIPTION <br/><span>(INCLUDE WEIGHT OR COUNT)</span></td>							
												<td className="qty-ordered" rowSpan="2">QTY ORDERED</td>							
												<td className="qty-received gray-shade" rowSpan="2">QTY REC’D</td>							
												<td className="unit-cost" rowSpan="2">UNIT COST</td>							
												<td className="total-cost" rowSpan="2">TOTAL COST</td>							
												<td className="retail-only gray-shade" colSpan="2" >RETAIL ONLY</td>							
											</tr>

											<tr className="head-row">						
												<td className="unit-retail gray-shade">UNIT RETAIL VALUE</td>							
												<td className="total-retail gray-shade">TOTAL RETAIL VALUE</td>							
											</tr>

											{first_6_products}
										</tbody>			
									</table>								
								</div>
							
								<div className="clearfix"></div>

								<div className="sales-row">
									<table className="table-layout rejection">
										<thead>
											<tr>
												<th colSpan="2">PRODUCT REJECTION</th>
											</tr>
										</thead>

										<tbody>
											<tr>
												<td colSpan="2" className="sub-head">IF PRODUCTS ARE REJECTED, PLEASE CIRCLE THE ITEMS BEING REJECTED IN THE PRODUCT SHIPPED DETAILS SECTION ABOVE</td>							
											</tr>
											<tr className="last-row">
												<td className="reason">REASON FOR REJECTION: </td>
												<td></td>							
											</tr>
										</tbody>			
									</table>

									<table className="table-layout receipt-confirm">
										<thead>
											<tr>
												<th colSpan="5">PRODUCT RECEIPT CONFIRMATION</th>
											</tr>
										</thead>

										<tbody>
											<tr>
												<td colSpan="5" className="sub-head">
													I CONFIRM THAT THE CONTENTS OF THIS SHIPMENT MATCH IN WEIGHT AND COUNT AS INDICATED ABOVE. <br/>
													I AGREE TO TAKE CUSTODY OF ALL ITEMS AS INDICATED RECEIVED ABOVE – AND WHICH ARE NOT CIRCLED. <br/>
													THE PRODUCTS CIRCLED ABOVE ARE REJECTED FOR DELIVERY AND REMAIN IN THE CUSTODY OF THE DISTRIBUTOR FOR RETURN TO THE SHIPPER AS INDICATED ON THIS FORM AND ALL ATTACHED PRODUCT DETAILS SHEET(S).
												</td>							
											</tr>

											<tr className="last-row">
												<td className="person-receiving">NAME OF PERSON RECEIVING AND/OR REJECTING PRODUCT: </td>
												<td className="second"></td>							
												<td className="third"></td>							
												<td className="forth">PHONE NUMBER:</td>				
												<td></td>							
											</tr>

											<tr className="last-row">
												<td className="person-receiving">SIGNATURE OF PERSON RECEIVING AND/OR REJECTING PRODUCT: </td>
												<td className="second">{signature!=null ? <img src={REST_API_BASE_URL+"uploads/sign/"+signature.sign_img} style={{height:"36px"}} />:""}</td>							
												<td className="third"></td>								
												<td className="forth">DATE SIGNED:</td>				
												<td></td>							
											</tr>
											
										</tbody>			
									</table>				
								</div>
							</div>
						</div>
					</div>

					{additional_pages}

				</div>
			
			</div>
		);
  }
};
