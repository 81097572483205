import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";

import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";

class DocModal extends Component{
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
	
	this.state = {
		 modalOpenAdd: false,	
		 textQuillStandart: "",
	};
	
  }
	
  handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd,
		  
		});
	};
  onChange = e => {
    switch (e.target.name) {
      case 'profile_pic':
		if(e.target.files[0]){
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
		}else{
			e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
      break;
      default:
        e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
     }
  };


	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
      }
	  
  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		 
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Create New Document Request
		  </ModalHeader>
		  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			
					<Row className="mt-2">
					<Colxx lg="12" className="mb-2">	
					<AvGroup>
					  <Label>Document Name</Label>
					  <AvField 
						name="suggestion" 
						type="text" 
						rows="2"
						validate={{
							  required: {
								value: true,
								errorMessage: "Please enter agreement"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The message must have atleast 2 characters"
							  }
							}}
						/>
					</AvGroup>
					</Colxx>
				</Row> 
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="customer.create" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default DocModal;
