import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import { NavLink } from "react-router-dom";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import AddJobModal from "../../../containers/myjobs/AddJobModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class Alljobs extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			folders:[],
			modalOpenAdd:false,
		};
	}

	componentDidMount() {
		var user_id = localStorage.getItem('user_id');
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
	    var formData = new FormData();
		formData.append('user_id',user_id);
        formData.append('facility_id',facility_id);

		axios.post(REST_API_END_POINT+'task/get-all-folders',formData).then((res)=>{
		 	var data = res.data;
		    var folders=data.folders;
				
		 	this.setState({ 
			 	folders,
			});			
		})	
	}
	
	toggleModalAdd = () => {
		this.setState({
			modalOpenAdd: !this.state.modalOpenAdd
		});
	};
  
	render() {
	   	
		var folders=this.state.folders;
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
		
		return (
			<Fragment>
				<Row>
				  <Colxx xxs="12">
					<Breadcrumb heading="menu.all-folders" match={this.props.match} />
					<Button																		 
						  color="primary"
						  size="md"
						  className="float-right"
						  style={{minWidth:'130px'}}
						  onClick={this.toggleModalAdd}
						>
						Add	Job						  
					</Button>
					<Separator className="mb-5" />
				  </Colxx>
				</Row>	
				<Row className=" ">
					<Colxx xxs="12">
						<div id="api_message"></div>
					</Colxx>
				</Row>
				
				<Row className="mb-2">
					{folders.map((row,index)=>{
						return (<Colxx xxs="3" key={index} className="mb-3" >		
								<NavLink to={"/app/jobs/sub-folders/"+row.id} > 
									<Card className="p-4 job-folders">
										<h5 className="mb-0"><i className="fas fa-folder mr-2" /> {row.name}</h5>
									</Card>
								</NavLink>
							</Colxx>)
						}
					)}	
				</Row>	
				<AddJobModal
						modalOpen={this.state.modalOpenAdd}
						toggleModal={this.toggleModalAdd}
						dataListRender = {this.dataListRender}
						facility_id = {facility_id}
					/>
			</Fragment>
		);
	}
}
