import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, CardImg, Badge, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import queryString from 'query-string';
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import Breadcrumb from "../../containers/navs/Breadcrumb";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import { REST_API_END_POINT,themeColor } from "../../constants/defaultValues";
import moment from 'moment';
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';

export default class PaymentHistory extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			blocking: true,
			payments: []
		};
	}
		
	componentDidMount() {
		this.setState({ blocking: true });
		axios
		  .get(
			`${REST_API_END_POINT+'admin/payment-history'}`
		  )
		  .then(res => {
			return res.data;
		  })
		  .then(data => {
				if(data.status==1){
					this.setState({
						payments: data,
						blocking: false
					});
				}else{
					this.setState({ blocking: false	});
				}
		  });
	}
	 
		
	render() {
		let data = this.state.payments;
				
		const dataTableColumns = [
			{
				Header: "Invoice #",
				accessor: "invoice_no",
				Cell: props => props.value
			},
			{
				Header: "Subscriber Name",
				accessor: "subscriber_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Plan",
				accessor: "subscription_name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Date",
				accessor: "timestamp",
				Cell: props => moment(props.value*1000).format("MM/DD/YYYY")
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: props => "$"+props.value
			},
			{
				Header: "Transaction ID",
				accessor: "transaction_id",
				Cell: props => props.value
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props =>{ if(parseInt(props.value)==1){ return "Success" }else{ return "Declined" } }
			},
			{
				Header: "Gateway",
				accessor: "payment_gateway",
				Cell: props => props.value
			}
		];	
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.payment-history" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row>			  
										<Colxx xxs="12">
											<div className="mb-4 dropdown-edit">					
												<ReactTable
													data={data}
													columns={dataTableColumns}
													defaultPageSize={10}
													filterable={false}
													showPageJump={false}
													PaginationComponent={DataTablePagination}
													showPageSizeOptions={true}
													defaultSorted={[{id: "id",asc: true}]}
												/>
											</div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
