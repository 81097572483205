import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../helpers/IntlMessages";

import { REST_API_END_POINT,dataEncryptKey } from "../../constants/defaultValues";
import axios from 'axios';
import swal from 'sweetalert';
import SimpleCrypto from "simple-crypto-js";
import { getCookie, setCookie } from "../../helpers/Utils";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

const onClickHandler = (facility_id) => {
	var form_data = new FormData();
	form_data.append("facility_id",facility_id);
	
	axios.post(REST_API_END_POINT+'register/get-facility-data',form_data).then((data)=>{					
		if(data.data.status==1){
			localStorage.setItem('facility_id', dataCrypto.encrypt(facility_id));
			localStorage.setItem('facility_data', dataCrypto.encrypt(data.data.facility_data));
			setCookie('pos_customer_id',null);
			setCookie('cart',null);
			setCookie('currency_code',null);
			setCookie('bogo_code',null);
			setCookie('payment_data',null);
			setCookie('change_data',null);
			localStorage.removeItem('shopCartCoupon');
			localStorage.removeItem('shopCartCouponCode');
			localStorage.removeItem('shopCartItems');
			window.location.reload();
		}					
	}).catch(e => console.log("Addition failed , Error ", e));
}

const TopnavEasyAccess = () => {
	
	var user_data = localStorage.getItem('user_data');
	var facilities = [];
	if(typeof user_data!='undefined' && user_data!=null){
		user_data = JSON.parse(user_data);
		facilities = user_data.facilities;
	}
	
	var facility_id = localStorage.getItem('facility_id');
	if(facility_id!=null){
		facility_id =  dataCrypto.decrypt(facility_id);
	}
	
	if(facilities.length>1){
	  return (
		<div className="position-relative d-inline-block">
		  <UncontrolledDropdown className="dropdown-menu-right">
			<DropdownToggle className="header-icon" color="empty">
			  <i className="simple-icon-home" />
			</DropdownToggle>
			<DropdownMenu
			  className="position-absolute mt-3"
			  right
			  id="iconMenuDropdown"
			>
			{facilities.map(row=>{
				return (<a key={row.id} className={"icon-menu-item cursor-pointer "+(facility_id==row.id ? "active-facility":"")} onClick={()=>{ onClickHandler(row.id) }}>
					<i className="simple-icon-home d-block" />{" "}
					{row.name}
				</a>)
			})}
			  
			</DropdownMenu>
		  </UncontrolledDropdown>
		</div>
	  );
	}else{
		return "";
	}
};

export default TopnavEasyAccess;
