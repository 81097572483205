import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,FormGroup,
  InputGroup,Card, Table, 
  InputGroupAddon,Input, Badge
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import ReactTable from "react-table";
import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/customer/ItemDropdown";
import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from 'moment';
var dataCrypto = new SimpleCrypto(dataEncryptKey);
 
class HarvestTableModal extends Component{
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		
		this.state = {
			modalOpenAdd: false,
			search: '',
			selectRoom:[],
			selectTable:[],
			rooms:"",
			room_id:null,
			table_id:null,
			plant:[],
			cure_options:[],
			plant_list_name:[],
			strain_id:[],
			strain_name:[],
			plant_ids:[],
			room_id:[],
			table_id:[],	
		};	
	}
	
	handleSubmit(event, errors, values) {
	    var msg="";
		var success_msg="";
		var failure_msg="";
		
		if (errors.length === 0) {
			
			document.getElementById("update-harvest").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("harvestForm"));
			formData.append('facility_id',facility_id);
			formData.append('current_user_id',user_id);
		    formData.append('plant_list_name',this.state.plant_list_name);
			formData.append('strain_id',this.state.strain_id);
			formData.append('strain_name',this.state.strain_name);
			formData.append('plant_ids',this.state.plant_ids);
			formData.append('room_id',this.state.room_id);
		
			
			axios.post(REST_API_END_POINT+'growhouse/table-harvest-plants',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==0){
					swal("Success",data.msg,"success").then(()=>{
						this.props.toggleModal();
						this.props.dataListRender();
						document.getElementById("update-harvest").disabled=false;
					});
				}else{
					let strain_name=data.strain_name;
					let strain_id=data.strain_id;
					let plant_ids=data.plant_ids;

					this.setState({
						plant_ids,
						strain_id:strain_id,
						strain_name:strain_name,
						plants : data.plants,
						wet_weight:""
					});
					
					document.getElementById("update-harvest").disabled=false;
				}

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-harvest").disabled=false;
			});

		}
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		
		var msg="";
		var success_msg="";
		var failure_msg="";
		var rooms=[];
		var tables=[];
		var strain_id=[];
		var plant_list_name=[];
		var cureoptions=[];
		var strain_name=[];
		var plant_ids=[];
		var room_id=[];
		var table_id=[];
        if(this.props.modalOpen){
					
            if (prevProps.table_ids !== this.props.table_ids && this.props.table_ids!=0) {
				var cure_options=[];
				var products=[];
				var rooms=[];
				var strain_id=[];
				var strain_name=[];
				var tables=[];
				var plants =[];
				
				var user_id = localStorage.getItem('user_id');
				var facility_id = localStorage.getItem('facility_id');
				facility_id =  dataCrypto.decrypt(facility_id);
				var formData = new FormData();
				
				formData.append('current_user_id',user_id);
				formData.append('facility_id',facility_id);	
				formData.append('type',5);
				formData.append('table_ids',this.props.table_ids)
				formData.append('room_id',this.props.room_id);
				
					
				axios.post(REST_API_END_POINT+'growhouse/create-table-plants-list',formData).then((res)=>{
					var data = res.data;
					
					plants = data.plants;
					
					let status=data.status;
					
					var msg =data.msg;
					
					if(status==0){
						swal(msg);
						this.props.toggleModal();
						return false;
					}
	
					plant_list_name = data.plant_list_name;	   
					strain_id = data.strain_id;	   
					strain_name = data.strain_name;	   
					plant_ids = data.plant_ids;	   
					room_id = data.room_id;	   
					table_id = data.table_id;
					
					this.setState({
						cure_options:cure_options,
						plant_list_name:plant_list_name,
						strain_id:strain_id,
						strain_name:strain_name,
						plant_ids:plant_ids,
						room_id:room_id,
						table_id:table_id,
						plants
					});
				})	
				.catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		}
	}  

	render() {
	  
		let data = this.state.plants
		
		
		if (this.state.search) {
			data = data.filter(row => {
				return row.barcode.toLowerCase().includes(this.state.search.toLowerCase()) || row.strain.toLowerCase().includes(this.state.search.toLowerCase())|| row.phase.toLowerCase().includes(this.state.search.toLowerCase()) 
			})
		}
	
		if(this.state.strain_name!=null){
			var strain_name=this.state.strain_name;
		}else{
			var strain_name;
		}	  
	  
		var {selectTable} = this.state;  
	  
		if(selectTable.length==0){
			selectTable = this.props.tables;
		}
		
		const dataTableColumns = [			  
			{
				Header: "Barcode",
				accessor: "barcode",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Strain",
				accessor: "strain",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Phase",
				accessor: "phase",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Birth Date",
				accessor: "birth_date",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Time in Room",
				accessor: "time_in_room",
				Cell: props => <p className="text-muted">{props.value}</p>
			}
		];
		
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  wrapClassName="modal-right"
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Harvest Plants
			</ModalHeader>
			
			
			  <ModalBody>
				<Row>
					<Colxx xxs="12">
						<Label>Strain: <strong>{strain_name ? ((strain_name!=null)?strain_name:''):'' }</strong></Label>
					</Colxx>
				</Row>
				<AvForm
					id="harvestForm"
					className="av-tooltip tooltip-right-bottom"
					onSubmit={this.handleSubmit}
				  >
					<Row className="mt-2">	
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Wet Weight<span className="text-danger">*</span></Label>
								<AvField
									id="wet_weight"
									name="wet_weight"
									type="number"
									min="0"
									value={this.state.wet_weight}
									onChange={(e)=>{ this.setState({ wet_weight:e.target.value }) }}
									validate={{
										required: {
											value: true,
											errorMessage: "This field is required."
										}
									}}
								/>
							</AvGroup>
						</Colxx>											
					</Row>
					<Row className="mt-4">					
						<Colxx lg="12">
							<Button
								  id="update-harvest"
								  color="primary"
								  size="sm"
								  className="mr-2"							  
								>
								Harvest							  
							</Button>										
						</Colxx>
					</Row>
				</AvForm>	
				<Card className="p-4 mt-4">			
						<div className="mb-4 dropdown-edit">
								<FormGroup row>
									<Colxx lg="6">
										<Input
										  type="text"
										  name="table_search"
										  id="table_search"
										  placeholder="Search"
										  value={this.state.search}
										  onChange={e => this.setState({search: e.target.value})}
										/>
									</Colxx>
								</FormGroup>			
							<ReactTable
							  data={data}
							  columns={dataTableColumns}
							  defaultPageSize={10}
							  filterable={false}
							  showPageJump={false}
							  PaginationComponent={DataTablePagination}
							  showPageSizeOptions={true}
							  defaultSorted={[{id: "send_on",desc: true}]}
							/>
						</div>
				</Card>	
			  </ModalBody>			
		</Modal>
	  );
  }
};

export default HarvestTableModal;
