import React, { Component, Fragment } from "react";
import { Row, Card, Button, CardBody,  FormGroup, Input,  DropdownItem,CustomInput, Badge, DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import uniqueId from 'lodash/uniqueId';
import { Draggable, Droppable } from 'react-drag-and-drop'

import ReactTable from "react-table";
import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import AddNewRoomModal from "../../../containers/growhouse/AddNewRoomModal";
import EditNewRoomModal from "../../../containers/growhouse/EditNewRoomModal";
import AddTableModal from "../../../containers/growhouse/AddTableModal";
import TooltipItemContent from "../../../components/common/TooltipItemContent";

var dataCrypto = new SimpleCrypto(dataEncryptKey);


export default class Growhouse extends Component {
	
	constructor(props) {
		super(props);
		
		this.toggleSelectAll = this.toggleSelectAll.bind(this); //bind the function if it invoked to another component
		this.dataListRender = this.dataListRender.bind(this);
		
		this.state = {		 		 
			modalOpenAdd: false,
			modalOpenEdit: false,
			modalOpenTable: false,
			search: '',
			max:10,
			grid_view:true,
			rooms:[],
			user_privileges:[]
		};
	}
	
	toggleView =(grid_view) => {
		this.setState({
			grid_view
		});
	};
	
	toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	
	toggleModalEdit = (e,room_id=0) => {
		this.setState({
			room_id: parseInt(room_id),
			modalOpenEdit: !this.state.modalOpenEdit
		});
	};
	
	toggleModalTable = () => {
		this.setState({
			modalOpenTable: !this.state.modalOpenTable
		});
	};
  
    toggleSelectAll() {
        let newSelected = [];
        if (this.state.selectAll === 0) {
            this.state.rooms.forEach(x => {
                newSelected.push(x.id);
            });
        }
		this.setState({
            selectedIds: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    componentDidMount(){
	   this.dataListRender();
	   
		var user_id=localStorage.getItem('user_id');
		var formData = new FormData();
		formData.append('user_id',user_id);
		
		axios.post(REST_API_END_POINT+'/dashboard/get-user-privileges',formData).then((res)=>{
			if(res.data.status==1){
				var user_privileges = res.data.user_privileges;
				this.setState({
					user_privileges
				});
			}
		}).catch(e => { 
			console.log("Addition failed , Error ", e); 
		});
	   
	}
	
    dataListRender() {		
		var rooms=[];	
		var plant_sizes=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		axios.post(REST_API_END_POINT+'growhouse/room-by-facility',formData).then((res)=>{
			var data = res.data;
			
			if(data.rooms){
				rooms=data.rooms;
				let max=data.max;
				
				rooms = rooms.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				this.setState({
			        rooms,
					max,
					selectAll:0,
					selectedIds:[]
				});
			}else{
				this.setState({
					rooms: [],
					selectAll:0,
					selectedIds:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
		
	deleteItem = (e,selectedIds,status)  => {
		var msg="";
		var success_msg="";
		var failure_msg="";
        var msg_word="room";
		
		if(selectedIds.length<1){
			swal("Warning","Please select at least one room","warning");
			return;
		}
		
		if(selectedIds.length>1){
			msg_word="rooms";
		}
		
        if(status===1){
            msg="Activate the "+msg_word+"?";
            success_msg="The "+msg_word+" has been activated.";
            failure_msg="Failed to activate "+msg_word;
        }else if(status===2){
            msg="Delete the "+msg_word+"?";
            success_msg="The "+msg_word+" has been deleted.";
            failure_msg="Failed to delete "+msg_word;
        }else{
            msg="Deactivate the "+msg_word+"?";
            success_msg="The "+msg_word+" has been deactivated.";
            failure_msg="Failed to deactivate "+msg_word;
        }
        swal({
          title: "Are you sure?",
          text: msg,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
		.then((willDelete) => {
			if (willDelete) {
				document.getElementById("delete-room").disabled=true;
			
				if(selectedIds.length){
					var user_id = localStorage.getItem('user_id');
					var facility_id = localStorage.getItem('facility_id');
					facility_id =  dataCrypto.decrypt(facility_id);
					
					var formData = new FormData();
					formData.append('room_ids',JSON.stringify(selectedIds));
					formData.append('status',status);
					formData.append('user_id',user_id);
					formData.append('facility_id',facility_id);
					
					axios.post(REST_API_END_POINT+'growhouse/delete-rooms',formData).then((res)=>{
						var data = res.data;
					
						document.getElementById("delete-room").disabled=false;
						
						var api_message=document.getElementById("api_message");
						if(data.status==1){
							api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+success_msg+"</span></div>";
						}else{
							api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+failure_msg+"</span></div>";   
						}
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove(); 
							});
						}, 5000);
						this.dataListRender();
						
					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("delete-room").disabled=false;
					});
				}
			}else{
				swal("Your operation is canceled!");
			}
		});
	}
	
    activeItem=(e,room_id)=>{		
	    let selectedIds = this.state.selectedIds;		
	    let selectAll = this.state.selectAll;		
		if(e.target.checked){
			selectedIds.push(room_id);
			
		}else{
		  var x=selectedIds.indexOf(room_id);
		  if(x>=0){
				selectedIds.splice(x,1);
		  }
		}
		if(selectedIds.length!=0 && selectedIds.length==this.state.rooms.length){
			selectAll=1;
		}else{
			selectAll=0;
		}
		this.setState({selectedIds,selectAll});
	}
	
	onRoomDrop=(data,e)=>{
		if(this.state.user_privileges.indexOf(8)!==-1){
			var position = e.target.getAttribute('data-position');
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			if(position!=null){
				var formData = new FormData();
				formData.append('room_id',data.rooms);
				formData.append('position',position);
				formData.append('user_id',user_id);
				formData.append('facility_id',facility_id);
				
				axios.post(REST_API_END_POINT+'growhouse/update-room-position',formData).then((res)=>{
					var data = res.data;
					
					var api_message=document.getElementById("api_message");
					if(data.status==1){
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					}else{
						api_message.innerHTML="<div class=\"rounded alert alert-danger alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";   
					}
					
					window.setTimeout(function() {
						JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
							JQUERY_MODULE.$(this).remove(); 
						});
					}, 5000);
					if(data.status==1){
						this.dataListRender();
					}
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});
			}
		}else{
			swal("Warning!","You don't have permission to perform this action");
		}
	}
	
	render() {
		let data = this.state.rooms
		if (this.state.search) {
			data = data.filter(row => {
				return row.name.toLowerCase().includes(this.state.search.toLowerCase()) || row.created_at.toLowerCase().includes(this.state.search.toLowerCase()) 
			})
		}
		
		const { max } =this.state;
		
		const dataTableColumns = [		
			{
				Header: <CustomInput type="checkbox" id="select_all" onChange={this.toggleSelectAll} checked={this.state.selectAll===1} />,
				accessor: "id",
				width:50,
				sortable: false,
				Cell: ( rowInfo )=> {
					return ( <FormGroup>
						<CustomInput type="checkbox" id={"rooms_"+rowInfo.original.id} onChange={event =>this.activeItem(event,rowInfo.original.id) } checked={this.state.selectedIds.includes(rowInfo.original.id)} />
						</FormGroup> );
				}
			},
			{
				Header: "Name",
				accessor: "name",
				Cell: props => <p className="list-item-heading">{props.value}</p>
			},
			{
				Header: "Room No.",
				accessor: "position",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Table Count",
				accessor: "table_count",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Created At",
				accessor: "created_at",
				Cell: props => <p className="text-muted">{props.value}</p>
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: props => <Badge color={parseInt(props.value)==1?"success":"danger"} pill>{parseInt(props.value)===1?"Active":"Inactive"}</Badge>
			},	  	  
			{
				Header: "Options",
				accessor: "id",
				Cell: props => <div className="action-btns p-0">
					<ItemDropdown item={<DropdownMenu >
						<DropdownItem className="cursor-pointer p-0">
							<NavLink to={"/app/growhouse/room/"+props.value} className="d-block py-2 px-4">		
								<span className="d-inline-block">View</span>
							</NavLink>					
						</DropdownItem>
						{this.state.user_privileges.indexOf(8)!==-1 ? <DropdownItem className="cursor-pointer" onClick={(e)=> this.toggleModalEdit(e,props.value) }>
							<span className="d-inline-block">Edit</span>								
						</DropdownItem>:""}
						{this.state.user_privileges.indexOf(9)!==-1 ? <Fragment>
							{props.original.status==1 ? <DropdownItem className="cursor-pointer" onClick={event =>this.deleteItem(event,[props.value],0)} >
								<span className="d-inline-block"  >Deactivate</span>
							</DropdownItem>:<DropdownItem className="cursor-pointer" onClick={event =>this.deleteItem(event,[props.value],1)} >
								<span className="d-inline-block" >Activate</span>
							</DropdownItem>}
							<DropdownItem className="cursor-pointer" onClick={event =>this.deleteItem(event,[props.value],2)}>
								<span className="d-inline-block" >Delete</span>
							</DropdownItem>
						</Fragment>:""}
					</DropdownMenu>} />
				</div>
			}
		];
		
		var GridContent=[];
		let i;
		
		let j=0; 		
		for(i=1;i<=max;i++){
			if(j<data.length && i==data[j]['position']){				
				let room_id = data[j]['id'];
				let room_name = data[j]['name'];
				let room_status = data[j]['status'];
				GridContent.push(<Card className="mb-3 mr-3 growroom-width" key={uniqueId()}>
					<TooltipItemContent key={'tip'+room_id} item={{
									  placement: "top",
									  id: 'tip'+room_id,
									  content: <CardBody className="text-center pt-1 pb-1">												                
													<Draggable type="rooms" data={room_id}>
														<FormGroup className="mb-1">
															<CustomInput type="checkbox" id={"room_"+room_id} onChange={event =>this.activeItem(event,room_id) } checked={this.state.selectedIds.includes(room_id)} />
														</FormGroup>
														{room_status==1 ?<span className="plant-count">{data[j]['plant_count']}</span>:""}
														<NavLink to={"/app/growhouse/room/"+room_id} className="d-block">
															<img  alt="Room" src={room_status==1 ? "/assets/img/open-room.png":"/assets/img/closed-room.png"} className="img-responsive p-0" />
														</NavLink>	
													</Draggable>
												</CardBody>,
									  body: <Fragment><strong>{room_name}</strong><br/>Number of Plants : {data[j]['plant_count']}</Fragment>,
									  TipClassName:'bg-theme-color'
									}} id={'tip'+room_id} />
											  
				</Card>);
				j++;
			}else{
				GridContent.push(<Card className="mb-3 mr-3 growroom-width center-content-grow" key={uniqueId()} >
					<CardBody className="text-center pt-1 pb-1 center-position-number">
						<Droppable types={['rooms']} data-position={i} onDrop={this.onRoomDrop.bind(this)}>
							<h1>{i}</h1>
						</Droppable>
					</CardBody>						  
				</Card>);
			}
		}
	   
		return (
			<Fragment>
				<Row>
					<Colxx xxs="12">
						<Breadcrumb heading="menu.all-rooms" match={this.props.match} />
						<Separator className="mb-5" />
					</Colxx>
				</Row>
			
				<Card className="p-4">
					<Row>
						<Colxx xxs="12">
							<div id="api_message"></div>
						</Colxx>
						<Colxx xxs="12">
							<div className="float-sm-right mb-2">
								{" "}{this.state.user_privileges.indexOf(7)!==-1 ? <Button																		 
									  color="primary"
									  size="sm"
									  className="mb-2"
									  onClick={this.toggleModalAdd}
									>
									  Add Room							  
								</Button>:""}{" "}{this.state.user_privileges.indexOf(151)!==-1 ? <Button																		 
									  color="primary"
									  size="sm"
									  className="mb-2"
									  onClick={this.toggleModalTable}
									>
									  Add Table							  
								</Button>:""}{" "}
								{this.state.user_privileges.indexOf(9)!==-1 ? <Fragment><Button
										color="primary"
										size="sm"
										className="mb-2"
										onClick={event =>this.deleteItem(event,this.state.selectedIds,1)}							
									  >
										Activate
									</Button>{" "}<Button
										color="primary"
										size="sm"
										className="mb-2"
										onClick={event =>this.deleteItem(event,this.state.selectedIds,0)}							
									  >
										Deactivate
									</Button>{" "}<Button
										id="delete-room"
										color="primary"
										size="sm"
										className="mb-2"
										onClick={event =>this.deleteItem(event,this.state.selectedIds,2)} 								
									  >
										Delete
									</Button>
								</Fragment>:""}
							</div>
						
							<div className="mb-4 dropdown-edit" style={{minHeight:'400px',display: (!this.state.grid_view ? "none":"block")}}>
								<FormGroup row>
									<Colxx lg="4" xxl="2" className="pr-0 pl-0">
										<div>
											<Button
												color="primary"
												size="sm"
												className={this.state.grid_view ? "":"opacity-list-type-reduce" }
												onClick={event =>this.toggleView(true)}					
											  >
												<i className="fas fa-th-large"></i>
											</Button>{" "}
											<Button
												color="primary"
												size="sm"
												className={!this.state.grid_view ? "":"opacity-list-type-reduce" }
												onClick={event =>this.toggleView(false)}
											  >
												<i className="fas fa-list"></i>
											</Button>
										</div>
									</Colxx>
								</FormGroup>
								<Row className="icon-cards-row mb-2 w-100">
									{GridContent}							
								</Row> 
							</div>
							<div className="mb-4 dropdown-edit" style={this.state.grid_view ? {display:"none"}:{}}>	
								<FormGroup row>
									<Colxx lg="4" xxl="2" className="pr-0 pl-0">
										<div>
											<Button
												color="primary"
												size="sm"
												className={this.state.grid_view ? "":"opacity-list-type-reduce" }
												onClick={event =>this.toggleView(true)}					
											  >
												<i className="fas fa-th-large"></i>
											</Button>{" "}
											<Button
												color="primary"
												size="sm"
												className={!this.state.grid_view ? "":"opacity-list-type-reduce" }
												onClick={event =>this.toggleView(false)}
											  >
												<i className="fas fa-list"></i>
											</Button>
										</div>
									</Colxx>
									<Colxx lg="6">
										<Input
											type="text"
											name="table_search"
											id="table_search"
											placeholder="Search"
											value={this.state.search}
											onChange={e => this.setState({search: e.target.value})}
										/>
									</Colxx>									
								</FormGroup>			
								<ReactTable
									data={data}
									columns={dataTableColumns}
									defaultPageSize={10}
									filterable={false}
									showPageJump={false}
									PaginationComponent={DataTablePagination}
									showPageSizeOptions={true}
									defaultSorted={[{id: "send_on",desc: true}]}
								/>
							</div>					
						</Colxx>
						
						{this.state.modalOpenAdd ? <AddNewRoomModal
							modalOpen={this.state.modalOpenAdd}
							toggleModal={this.toggleModalAdd}
							dataListRender = {this.dataListRender}
						/>:""}
						
						<EditNewRoomModal
							modalOpen={this.state.modalOpenEdit}
							toggleModal={this.toggleModalEdit}
							dataListRender = {this.dataListRender}
							room_id ={this.state.room_id }
						/> 
						{this.state.modalOpenTable ? <AddTableModal
							modalOpen={this.state.modalOpenTable}
							toggleModal={this.toggleModalTable}
							dataListRender = {this.dataListRender}
							rooms={this.state.rooms}
						/>:""}
					</Row>
				</Card>
			</Fragment>
		);
	}
}
