import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
	CustomInput,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Label, Badge,
	Card, CardHeader, Table, Media, InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import moment from 'moment';

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { zeroPad, getCurrencySymbol, getCustomerWord } from "../../helpers/Utils";
import { REST_API_END_POINT, REST_API_BASE_URL, dataEncryptKey, JQUERY_MODULE, CUSTOMER_ORDER_STATUS, BASE_URL } from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import number_format from "number_format-php";
import ViewFileModal from "../../components/common/ViewFileModal";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const customerWord = getCustomerWord();

const quillModules = {
	toolbar: [
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		["bold", "italic", "underline"],
		// dropdown with defaults from theme	
		[
			{ list: "ordered" },
			{ list: "bullet" },
			{ indent: "-1" },
			{ indent: "+1" }
		],

	]
};

const quillFormats = [
	"header",
	"bold",
	"italic",
	"underline",
	"list",
	"bullet",
	"indent",
];

export default class ViewOrderModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			order_data: null,
			facility_data: null,
			replies: [],
			order_payment_splits: [],
			currencies: [],
			textQuillStandart: "",
			showFile: true,
			doc_url: null,
			additionalRequirementsData: [],
		};

		this.dataListRender = this.dataListRender.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.cancelOrder = this.cancelOrder.bind(this);
		this.deleteReply = this.deleteReply.bind(this);
		this.deleteReplyFile = this.deleteReplyFile.bind(this);
		this.toggleModalViewDoc = this.toggleModalViewDoc.bind(this);

	}

	toggleModalViewDoc = (e, doc_url = null) => {
		this.setState({
			modalOpenViewDoc: !this.state.modalOpenViewDoc,
			doc_url
		});
	};


	handleChangeQuillStandart = (textQuillStandart) => {
		this.setState({ textQuillStandart });
	}

	onMultiFileChange = e => {
		switch (e.target.name) {
			case 'files[]':
				if (e.target.files[0]) {
					var filenames_obj = e.target.files;
					var filenames = [];
					for (var i = 0; i < filenames_obj.length; i++) {
						filenames.push(filenames_obj[i].name);
					}
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = filenames.join(',');
				} else {
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML = 'Choose file';
				}
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.modalOpen) {
			if (prevProps.order_id !== this.props.order_id && this.props.order_id != 0) {
				this.dataListRender();
			}
		}
	}

	dataListRender() {
		this.setState({ showFile: false });
		var facility_id = localStorage.getItem('facility_id');
		facility_id = dataCrypto.decrypt(facility_id);
		var formData = new FormData();
		formData.append('order_id', this.props.order_id);
		formData.append('facility_id', facility_id);
		axios.post(REST_API_END_POINT + 'estimate/view-order', formData).then((res) => {

			var data = res.data;
			if (data.status == 1) {
				this.setState({
					order_data: data.order_data,
					facility_data: data.facility_data,
					order_payment_splits: data.order_payment_splits,
					replies: data.replies,
					currencies: data.currencies,
					textQuillStandart: "",
					showFile: true,
					additionalRequirementsData: data.additional_requirements,
				});
			} else {
				this.props.toggleModal();
				swal("Warning!", "There is no such estimate", "warning");
			}
		}).catch(e => {
			console.log("Addition failed , Error ", e);
		});
	}

	handleSubmit(event, errors, values) {

		if (errors.length === 0) {

			if (this.state.textQuillStandart.length == 0) {
				swal("Warning!", "Please enter the note", "warning");
				return;
			}

			document.getElementById("reply-order").disabled = true;

			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id = dataCrypto.decrypt(facility_id);

			var formData = new FormData(document.getElementById("replyForm"));
			formData.append('facility_id', facility_id);
			formData.append('user_id', user_id);
			formData.append('order_id', this.props.order_id);
			formData.append('body', this.state.textQuillStandart);

			axios.post(REST_API_END_POINT + 'estimate/order-reply', formData).then((res) => {
				var data = res.data;

				var api_message = document.getElementById("api_message_documents");
				api_message.innerHTML = "<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>" + data.msg + "</span></div>";

				window.setTimeout(function () {
					JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				}, 5000);

				JQUERY_MODULE.$('.alert .close').on("click", function (e) {
					JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function () {
						JQUERY_MODULE.$(this).remove();
					});
				});

				document.getElementById("reply-order").disabled = false;
				this.form && this.form.reset();
				this.dataListRender();

			}).catch(e => {
				console.log("Addition failed , Error ", e);
				document.getElementById("reply-order").disabled = false;
			});

		}
	}

	cancelOrder = () => {
		var msg = "Cancel this estimate?";
		var success_msg = "The estimate has been canceled.";
		var failure_msg = "Failed to cancel estimate";

		swal({
			title: "Are you sure?",
			text: msg,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					document.getElementById("cancel-view-order").disabled = true;
					var facility_id = localStorage.getItem('facility_id');
					facility_id = dataCrypto.decrypt(facility_id);

					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('order_ids', JSON.stringify([this.props.order_id]));
					formData.append('user_id', user_id);
					formData.append('facility_id', facility_id);

					axios.post(REST_API_END_POINT + 'estimate/cancel-orders', formData).then((res) => {
						var data = res.data;

						document.getElementById("cancel-view-order").disabled = false;
						if (data.status == 1) {
							this.dataListRender();
							swal("Success", success_msg, "success");
						} else {
							swal("Failed", failure_msg, "warning");
						}
						this.props.dataListRender();
					}).catch(e => {
						console.log("Addition failed , Error ", e);
						document.getElementById("cancel-view-order").disabled = false;
					});

				} else {
					swal("Your operation is canceled!");
				}
			});
	}

	deleteReply = (reply_id) => {
		var msg = "Delete this note?";

		swal({
			title: "Are you sure?",
			text: msg,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					var facility_id = localStorage.getItem('facility_id');
					facility_id = dataCrypto.decrypt(facility_id);

					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('reply_id', reply_id);
					formData.append('user_id', user_id);
					formData.append('facility_id', facility_id);

					axios.post(REST_API_END_POINT + 'estimate/delete-reply', formData).then((res) => {
						var data = res.data;
						if (data.status == 1) {
							var replies = this.state.replies;
							replies = replies.filter(r => { return r.ID != reply_id });
							this.setState({ replies })
							swal("Success", data.msg, "success");
						} else {
							swal("Failed", data.msg, "warning");
						}

					}).catch(e => {
						console.log("Addition failed , Error ", e);
					});
				} else {
					swal("Your operation is canceled!");
				}
			});
	}

	deleteReplyFile = (file_id) => {
		var msg = "Delete this file?";

		swal({
			title: "Are you sure?",
			text: msg,
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				if (willDelete) {
					var facility_id = localStorage.getItem('facility_id');
					facility_id = dataCrypto.decrypt(facility_id);

					var user_id = localStorage.getItem('user_id');
					var formData = new FormData();
					formData.append('file_id', file_id);
					formData.append('user_id', user_id);
					formData.append('facility_id', facility_id);

					axios.post(REST_API_END_POINT + 'estimate/delete-reply-file', formData).then((res) => {
						var data = res.data;
						if (data.status == 1) {
							var replies = this.state.replies;
							replies = replies.map(r => {
								r.files = r.files.filter(f => { return f.ID != file_id });
								return r;
							})
							this.setState({ replies })
							swal("Success", data.msg, "success");
						} else {
							swal("Failed", data.msg, "warning");
						}

					}).catch(e => {
						console.log("Addition failed , Error ", e);
					});
				} else {
					swal("Your operation is canceled!");
				}
			});
	}

	render() {
		const { order_data, replies, currencies, facility_data, additionalRequirementsData } = this.state;
		var current_currency_symbol = getCurrencySymbol();
		var sales_tax = 0;
		var sgst_tax = 0;
		var cgst_tax = 0;
		var sub_total = 0;
		var p_discount = 0;
		let is_utgst = false;
		let is_igst = true;
		var status_text = "";
		var status_badge = "";
		if (order_data != null) {
			var current_currency = currencies.find(row => { return row.currency_code == order_data.currency_code });
			if (current_currency != null) {
				//current_currency_symbol = current_currency.currency_symbol;
			}
			const order_status = CUSTOMER_ORDER_STATUS.find(r => { return r.value == order_data.status });
			if (order_status != null) {
				status_text = order_status.label;
				status_badge = <Badge color={order_status.badge} >{order_status.label}</Badge>;
			}
			/*is_igst = order_data.customer_state_id !== facility_data.facility_state_id;
			if(!is_igst){
				is_utgst = order_data.is_utgst=="1";
			}*/
		}
		if (order_data != null) {
			order_data.final.map((item, index) => {
				if (item.filter) {
					item.filter.map(row => {
						sales_tax += parseFloat(row.product_sales_tax);
						sgst_tax += parseFloat(row.product_sgst_tax);
						cgst_tax += parseFloat(row.product_cgst_tax);
						p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

						let line_total = parseFloat(row.unitPrice) * Math.ceil(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

						if (row.variation_id == order_data.discount_variation_id) {
							line_total -= parseFloat(order_data.manual_discount);
						}
						sub_total += parseFloat(row.unitPrice) * Math.ceil(row.product_qty);
					});
				}
			});

		}
		let additionalTotal = 0;
		return (
			<Modal
				isOpen={this.props.modalOpen}
				toggle={this.props.toggleModal}
				wrapClassName="modal-right modal-right-lg"
				size="md"
			>
				<ModalHeader toggle={this.props.toggleModal}>
					View Estimate
				</ModalHeader>

				<ModalBody >
					{order_data != null ? <Fragment>

						<Row>
							<Colxx md="8">
								<div className="view-order-table">
									<div className="font-weight-bold text-uppercase h4">#{moment(order_data.timestamp * 1000).format('YYYY')}/{zeroPad(order_data.order_number, 6)}</div>
									<div className="border-bottom pt-2 mb-3" />
									<Table responsive bordered>
										<thead>
											<tr>
												<th className="mb-2">#</th>
												<th className="mb-2">Item</th>
												<th className="mb-2 ">Size</th>
												<th className="mb-2 text-right">Unit Price</th>
												<th className="mb-2 text-right">SQFT/Quantity</th>
												{/* <th className="mb-2 text-right">Price</th>
												
												{is_igst ? <th className="mb-2 text-right">TAX</th>:<Fragment>
													<th className="mb-2 text-right">{is_utgst ? "UTGST":"SGST"}</th>
													<th className="mb-2 text-right">CGST</th>
												</Fragment>} */}
												{/*<th className="mb-2 text-right">Discount</th>*/}
												<th className="mb-2 text-right">Total</th>
											</tr>
										</thead>
										<tbody>
											{order_data.final.map((row, index) => {
												// sales_tax += parseFloat(row.product_sales_tax);
												// sgst_tax += parseFloat(row.product_sgst_tax);
												// cgst_tax += parseFloat(row.product_cgst_tax);
												// p_discount += parseFloat(row.product_discount) + parseFloat(row.coupon_discount);

												// let line_total = parseFloat(row.unitPrice) * Math.ceil(row.product_qty) + (parseFloat(row.product_excise_tax) + parseFloat(row.product_sales_tax) - parseFloat(row.product_discount) - parseFloat(row.coupon_discount))

												// if (row.variation_id == order_data.discount_variation_id) {
												// 	line_total -= parseFloat(order_data.manual_discount);
												// }
												// sub_total += parseFloat(row.unitPrice) * Math.ceil(row.product_qty);
												return (<>
													<tr>
														<td>{index + 1}</td>
														<td>{row.category_name} </td>
													</tr>
													{row.filter.map((raw) => (
														<tr>
															<td style={{ border: 'none' }}></td>
															<td>{raw.product_name} {raw.variation_name != null ? "(" + raw.variation_name + ")" : ""}</td>
															<td>{raw.sqft == 1 ? <div>{parseInt(raw.length)}*{parseInt(raw.breadth)}*{parseInt(raw.height)}</div> : ""}</td>
															<td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(raw.unitPrice), 2, '.', '')}</td>
															<td className="text-right">{Math.ceil(raw.product_qty)}</td>
															{/* <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat((parseFloat(row.unitPrice)*Math.ceil(row.product_qty)).toFixed(2)),2,'.','')}</td> 
												 {is_igst ? <td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sales_tax),2,'.','')}</td>:<Fragment>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_sgst_tax),2,'.','')}</td>
													<td className="text-right">{current_currency_symbol}{""+number_format(parseFloat(row.product_cgst_tax),2,'.','')}</td>
												</Fragment>}  */}
															{/*<td className="text-right">{current_currency_symbol}{""}{row.variation_id!=order_data.discount_variation_id ? number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount),2,'.',''):number_format(parseFloat(row.product_discount)+parseFloat(row.coupon_discount)+parseFloat(order_data.manual_discount),2,'.','')}</td>*/}
															<td className="text-right">{current_currency_symbol}{"" + number_format(parseFloat(raw.unitPrice) * Math.ceil(raw.product_qty) + (parseFloat(raw.product_excise_tax) + parseFloat(raw.product_sales_tax) - parseFloat(raw.product_discount) - parseFloat(raw.coupon_discount)), 2, '.', '')}</td>
														</tr>
													))}
												</>)
											})}
										</tbody>
									</Table>
									<div className="d-flex flex-column">
										<div className="border-bottom pt-3 mb-2" />
										<Table borderless className="d-flex justify-content-end">
											<tbody>
												{/* <tr>
													<td className="pb-0">Subtotal :</td>
													<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sub_total, 2, '.', '')}</td>
												</tr> */}
												{/* {is_igst ? <tr>
													<td className="pb-0">TAX :</td>
													<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
												</tr> : <Fragment>
													<tr>
														<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
													</tr>
													<tr>
														<td className="pb-0">CGST :</td>
														<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
													</tr>
												</Fragment>} */}
												{/*<tr>
													<td className="pb-0">Discount :</td>
													<td className="text-right pb-0">{current_currency_symbol}{""+number_format(p_discount+parseFloat(order_data.auto_apply_discount)+parseFloat(order_data.coupon_discount)+parseFloat(order_data.manual_discount_value)+parseFloat(order_data.point_discount),2,'.','')}</td>
												</tr>*/}
												{parseFloat(order_data.return_amount) != 0 ? <tr className="font-weight-bold">
													<td className="pb-0">Refund :</td>
													<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.return_amount), 2, '.', '')}</td>
												</tr> : ""}
												<tr className="font-weight-bold">
													<td className="pb-0"> Total :</td>
													<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) - parseFloat(p_discount), 2, '.', '')}</td>
												</tr>
											</tbody>
										</Table>
										{additionalRequirementsData.length > 0 ?
											<>
												<h4>Additional Requirements</h4>
												<Table borderless>
													<thead>
														<tr>
															<th className="mb-2">#</th>
															<th className="mb-2 text-left">Item</th>
															<th className="mb-2 text-left">Quantity</th>
															<th className="mb-2 text-left">Price</th>
															<th className="mb-2 text-left">Amount</th>

														</tr>
													</thead>
													<tbody>
														{additionalRequirementsData.map((row, index) => {
															additionalTotal += parseFloat(row.amount);
															return (<>
																<tr >
																	<td>{index + 1}</td>
																	<td>{row.name} </td>
																	<td>{row.qty}</td>
																	<td>{row.price}</td>
																	<td>{row.amount}</td>

																</tr>


															</>)
														})}
													</tbody>
												</Table>
												<div className="d-flex flex-column">
													<div className="border-bottom pt-3 mb-2" />
													<Table borderless className="d-flex justify-content-end">
														<tbody>
															{/* <tr>
											<td className="pb-0">Subtotal :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(additionalTotal, 2, '.', '')}</td>
										</tr> */}

															{/* {is_igst ? <tr>
											<td className="pb-0">Tax ({order_data.tax_percentage}%) :</td>
											<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sales_tax, 2, '.', '')}</td>
										</tr> : <Fragment>
											<tr>
												<td className="pb-0">{is_utgst ? "UTGST" : "SGST"} :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(sgst_tax, 2, '.', '')}</td>
											</tr>
											<tr>
												<td className="pb-0">CGST :</td>
												<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(cgst_tax, 2, '.', '')}</td>
											</tr>
										</Fragment>} */}

															<tr className="font-weight-bold">
																<td className="pb-0">Total :</td>
																<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
															</tr>
															<tr className="font-weight-bold">
																<td className="pb-0">Grand Total :</td>
																<td className="text-right pb-0">{current_currency_symbol}{"" + number_format(parseFloat(order_data.total) + parseFloat(additionalTotal) - parseFloat(p_discount), 2, '.', '')}</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</> : ""}
										<div className="border-bottom pt-2 mb-3" />
									</div>
									{order_data.delivery_date != 0 ? <h4>Delivery Date  : {moment(order_data.delivery_date * 1000).format('DD-MM-YYYY')}</h4> : ""}
								</div>
							</Colxx>
							<Colxx md="4">
								<Media>
									<Media left className="mr-2">
										<Media object width="50" src={order_data.client_avatar != null ? REST_API_BASE_URL + "uploads/users/" + order_data.client_avatar : REST_API_BASE_URL + "uploads/users/avatar.jpg"} alt={order_data.vendor_name} />
									</Media>
									<Media body>
										<p className="mb-1">{customerWord} Name: {order_data.vendor_name}</p>
										<p className="mb-1">Created: {moment(order_data.timestamp * 1000).format('DD-MM-YYYY')}</p>
										<p className="mb-1">Status: {status_badge}</p>
										{/*order_data.payment_status==1 && order_data.payment_mode!=0 && order_data.payment_mode!="" ? <p className="mb-1">Payment Mode: {order_data.payment_mode}</p>:""*/}
										{order_data.status == 1 && order_data.ap_username != null ? <p className="mb-1">Added By: {order_data.ap_username}</p> : ""}
									</Media>
								</Media>
								<div className="border-bottom pt-2 mb-3" />
								{order_data.status != 3 && order_data.status != 4 && order_data.status != 5 ? <Button size="sm" id="cancel-view-order" color="danger" onClick={this.cancelOrder} >Cancel Estimate</Button> : ""}

							</Colxx>
						</Row>

						{replies.length ? <hr /> : ""}
						{replies.length ? <Row>
							<Colxx md="12">
								<div className="font-weight-bold h5">Notes</div>

								{replies.map(row => {
									return (<Card className="p-3 mb-3">
										<Row>
											<Colxx md="4">
												<Media>
													<Media left className="mr-2">
														<Media object width="50" src={row.profile_pic != "" ? REST_API_BASE_URL + "uploads/users/" + row.profile_pic : REST_API_BASE_URL + "uploads/users/avatar.jpg"} alt={row.username} />
													</Media>
													<Media body>
														<p className="mb-1">{row.username}</p>
														<p className="mb-1">{moment(row.timestamp * 1000).format('MM/DD/YYYY hh:mm a')}</p>
													</Media>
												</Media>
											</Colxx>
											<Colxx md="8">
												<div className="float-right"><Button size="sm" color="danger" className="py-1 px-2" onClick={() => { this.deleteReply(row.ID) }} ><i className="far fa-trash-alt" /></Button></div>
												<div dangerouslySetInnerHTML={{ __html: row.body }} />

												{row.files.length ? <Table hover responsive>
													<tbody>
														{row.files.map((r, index) => {
															return (<tr key={index}>
																<td><p className="name mb-0"><span className="open-document btn-link cursor-pointer" onClick={(e) => { this.toggleModalViewDoc(e, BASE_URL + 'customers/' + r.upload_file_name) }}>{r.upload_file_name}</span></p></td>
																<td>{r.file_size} KB</td>
																{/*<td><Button size="sm" color="danger" className="py-1 px-2" onClick={()=>{ this.deleteReplyFile(r.ID) }} ><i className="far fa-trash-alt" /></Button></td>*/}
															</tr>)
														})}
													</tbody>
												</Table> : ""}
											</Colxx>
										</Row>
									</Card>);
								})}
							</Colxx>
						</Row> : ""}

						<hr />
						<Row>
							<Colxx md="10">
								<div className="font-weight-bold h5">Add Note</div>

								<AvForm
									id="replyForm"
									ref={c => (this.form = c)}
									className="av-tooltip tooltip-right-bottom"
									onSubmit={this.handleSubmit}
								>
									<Row className="mt-3">
										<Colxx lg="12" className="mb-2">
											<AvGroup>
												<Label>Message Body<span className="text-danger">*</span></Label>
												<ReactQuill
													theme="snow"
													name="body"
													value={this.state.textQuillStandart}
													onChange={this.handleChangeQuillStandart}
													placeholder=""
													modules={quillModules}
													formats={quillFormats}
												/>
											</AvGroup>
										</Colxx>
									</Row>
									{this.state.showFile ? <Row>
										<Colxx lg="12" className="mb-2">
											<AvGroup className="error-l-125 error-t-negative">
												<Label>Attach Files</Label>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
													<CustomInput
														type="file"
														name="files[]"
														id="test_document"
														multiple
														onChange={this.onMultiFileChange}
													/>
												</InputGroup>
											</AvGroup>
										</Colxx>
									</Row> : ""}

									<Row className="mt-2 mb-4">
										<Colxx xxs="12">
											<Button id="reply-order" color="primary" >Submit</Button>
										</Colxx>
									</Row>
								</AvForm>
								<div id="api_message_documents"></div>
							</Colxx>
						</Row>
					</Fragment> : ""}
					{this.state.doc_url != null ? <ViewFileModal
						modalOpen={this.state.modalOpenViewDoc}
						toggleModal={this.toggleModalViewDoc}
						doc_url={this.state.doc_url}
					/> : ""}
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
						<IntlMessages id="customer.close" />
					</Button>{" "}
				</ModalFooter>
			</Modal>
		);
	}
};
