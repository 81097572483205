import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle} from "reactstrap";
import { NavLink } from "react-router-dom";
import { Colxx } from "../../components/common/CustomBootstrap";

export default class accountVerified extends Component {
  
  render() {
	var passed = this.props.match.params.passed;
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="9" lg="7" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="form-side">
              <NavLink to={`/`} className="white">
                <span className="logo-single" />
              </NavLink>
			  {passed === 'false' ?
				<Fragment>
					<CardTitle className="mb-4 text-center">
						You have already verified your account
					</CardTitle>
				</Fragment> : (passed === 'invalid' ?
				<Fragment>
					<CardTitle className="mb-4 text-center">
						Invalid verification code.
					</CardTitle>
				</Fragment> :
				<Fragment>
					<CardTitle className="mb-4 text-center">
					   Account has been verified.
					</CardTitle>
					<p className="mb-4 text-center">
						Your account has been verified and activated successfully.
					</p>
				</Fragment>)}
              
			  <p className="text-center mt-sm mb-0">
					Please login to your account {" "}
					<NavLink to={`/user/login`} className="">
					  Login Now!
					</NavLink>
				</p>
            </div>
          </Card>
        </Colxx>
      </Row>
    );
  }
}
