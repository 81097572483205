import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { addToCart } from "../../redux/cart/cartActions";
import ProductGridListSingle from "./product/ProductGridListSingle";

const ProductGrid = ({
  product,
  currency,
  addToCart,
  cartItems,
  sliderClassName,
  spaceBottomClass
}) => {
	return (
        <ProductGridListSingle
            sliderClassName={sliderClassName}
            spaceBottomClass={spaceBottomClass}
            product={product}
            currency={currency}
            addToCart={addToCart}
            cartItem={
              cartItems.filter(cartItem => cartItem.id === product.id)[0]
            }            
            key={product.id}
        /> 
	);
};

ProductGrid.propTypes = {
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  currency: PropTypes.object,
  products: PropTypes.array,
  sliderClassName: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
      item,
      addToast,
      quantityCount,
	  variationId
    ) => {
      dispatch(
        addToCart(
          item,
          addToast,
          quantityCount,
		  variationId
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGrid);
