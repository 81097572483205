import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  CustomInput,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Media,
  InputGroup, InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import moment from 'moment';
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx } from "../../components/common/CustomBootstrap";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

var dataCrypto = new SimpleCrypto(dataEncryptKey);
const quillModules = {
	toolbar: [
	  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
	  ["bold", "italic", "underline"],
	   // dropdown with defaults from theme	
	  [
		{ list: "ordered" },
		{ list: "bullet" },
		{ indent: "-1" },
		{ indent: "+1" }
	  ],    	    
	  
	]
  };
  
  const quillFormats = [
	"header",
	"bold",
	"italic",
	"underline", 
	"list",
	"bullet",
	"indent", 
  ];
  
export default class EditReplyModal extends Component{
	constructor(props) {
		super(props);
        
        this.state = {	
            textQuillStandart:this.props.editBody,
		};	
		this.modalSubmit = this.modalSubmit.bind(this);
       
	 console.log('Props',this.props.enquiry_id);
	 
	}

	// modalSubmit(event, errors, values) {		
	// 	if (errors.length === 0) {			
	// 		document.getElementById("update-account").disabled=true;
			
	// 		var user_id = localStorage.getItem('user_id');
	// 		var facility_id = localStorage.getItem('facility_id');
	// 		facility_id =  dataCrypto.decrypt(facility_id);
			
	// 		var formData = new FormData(document.getElementById("accountForm"));
	// 		formData.append('reply_id',this.props.replyId);
    //         formData.append('body',this.state.textQuillStandart);
	// 		formData.append('facility_id',facility_id);
	// 		formData.append('user_id',user_id);

	// 		// axios.post(REST_API_END_POINT+'account/edit-account',formData).then((res)=>{
	// 			axios.post(REST_API_END_POINT+'enquiries/update-reply',formData).then((res)=>{
	// 			var data = res.data;
				
			
	// 			// if(data.status==1){
	// 			// 	// var api_message=document.getElementById("api_message");
	// 			// 	// api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
					
	// 			// 	window.setTimeout(function() {
	// 			// 		JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
	// 			// 			JQUERY_MODULE.$(this).remove();
	// 			// 		});
	// 			// 	}, 5000);
					
	// 			// 	JQUERY_MODULE.$('.alert .close').on("click", function(e){
	// 			// 		JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
	// 			// 			JQUERY_MODULE.$(this).remove();
	// 			// 		});
	// 			// 	});
	// 			// }
				
	// 			document.getElementById("update-account").disabled=false;
	// 			this.props.toggleModal();
	// 			// this.props.dataListRender();

	// 		}).catch(e =>{
	// 			console.log("Addition failed , Error ", e);
    //             this.props.toggleModal();
	// 			// document.getElementById("update-account").disabled=false;
	// 		});

	// 	}
	// }
		
	modalSubmit() {		
		
			document.getElementById("update-account").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("accountForm"));
			formData.append('reply_id',this.props.replyId);
            formData.append('body',this.state.textQuillStandart);
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);

		
				axios.post(REST_API_END_POINT+'enquiries/update-reply',formData).then((res)=>{
				var data = res.data;
				
			
				
				document.getElementById("update-account").disabled=false;
				this.props.toggleModal();
				this.props.dataListRender(this.props.enquiry_id);

			}).catch(e =>{
				console.log("Addition failed , Error ", e);
                this.props.toggleModal();
				// document.getElementById("update-account").disabled=false;
			});

		
	}
	handleChangeQuillStandart = (textQuillStandart) => {
        this.setState({ textQuillStandart });
    }
	render() {
      
      
        return(  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
			<ModalHeader toggle={this.props.toggleModal}>
				Edit Notes
			</ModalHeader>
			<AvForm
		        id="accountForm"
				className="av-tooltip tooltip-right-bottom"
				// onSubmit={this.modalSubmit}
			  >
				<ModalBody>
                <Row>
						{/* {enquiry_data.status!=8 ? */}
						<Colxx md="12">
								<div className="font-weight-bold h5">Add Note</div>
								
								<AvForm
									id="replyForm"
									ref={c => (this.form = c)}
									className="av-tooltip tooltip-right-bottom"
									onSubmit={this.handleSubmitNote}
								>
									<Row className="mt-3">
										<Colxx lg="12" className="mb-2">
											<AvGroup>
												<Label>Message Body<span className="text-danger">*</span></Label>
												<ReactQuill
													theme="snow"
													name="body"
													value={this.state.textQuillStandart}
													onChange={this.handleChangeQuillStandart}
													placeholder=""
													modules={quillModules}
													formats={quillFormats}
												/>
											</AvGroup>
										</Colxx>
									</Row>
									{/* {this.state.showFile ? <Row>
										<Colxx lg="12" className="mb-2">
											<AvGroup className="error-l-125 error-t-negative">
												<Label>Attach Files</Label>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">Upload</InputGroupAddon>
													<CustomInput
														type="file"
														name="files[]"
														id="test_document"
														multiple 
														onChange={this.onMultiFileChange}
													/>
												</InputGroup>
											</AvGroup>
										</Colxx>
									</Row>:""} */}
									
																	
								</AvForm>
								<div id="api_message_documents"></div>
							</Colxx>
							{/* :""} */}
						</Row>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" id="update-account"  onClick={this.modalSubmit}>
					  <IntlMessages id="customer.update" />
					</Button>
					<Button color="secondary" outline onClick={this.props.toggleModal}>
					  <IntlMessages id="customer.cancel" />
					</Button>{" "}
				</ModalFooter>
			</AvForm>
		</Modal>
	  );
	}
};
