import React, { Component, Fragment } from "react";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,
    DropdownMenu, Table } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import Select from "react-select";
import CustomSelectInput from "../../../components/common/CustomSelectInput";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import { getCookie, setCookie } from "../../../helpers/Utils";

import swal from 'sweetalert';
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../../constants/defaultValues";
import axios from 'axios';
import moment from 'moment';
import SimpleCrypto from "simple-crypto-js";
var dataCrypto = new SimpleCrypto(dataEncryptKey);

const selectSource = [
    { label: "Seed", value: "0", key: 0 },
    { label: "Clone", value: "1", key: 1},
    { label: "Tissue Culture", value: "2", key: 2 }
  ];
 
export default class AddPlantToTable extends Component {
	
	constructor(props) {
		super(props);
	    this.handleSubmit = this.handleSubmit.bind(this);
		this.state = {
			selectSourceType : 	{ label: "Seed", value: "0", key: 0 },
			selectStrainType : null,
			selectGroupType : null,
			selectVendorType : null,
            startDate: null,
			startHarvest: null,
			selectStrain:[],
			selectGroup:[],
			selectVendor:[],
			room_id:0,
			send_data:[],
		};
	}
	
	handleSubmit(event, errors, values) {
		console.log(errors);
		console.log(values);
		if (errors.length === 0) {
		 
		}
    } 
    handleChangeDate = date => {
		this.setState({
		  startDate: date
		});
	};
    handleChangeHarvest = date => {
		this.setState({
		  startHarvest: date
		});
    };
	
	 componentDidMount(){
	   this.dataListRender();
	}

	
    dataListRender() {
		
		var tables=[];	
		var strains=[];	
		var groups=[];	
		var strains=[];	
		var plant_sizes=[];	
		var final_table_ids=[];	
		var table_name=[];	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		
		var table_ids = getCookie('add_to_plant_table_ids','string');
		if(table_ids==null){
			window.location="/app/growhouse";
		}
		formData.append('table_ids',table_ids );
		
		axios.post(REST_API_END_POINT+'growhouse/data-for-add-plants-to-tables',formData).then((res)=>{
			var data = res.data;
			
			table_name=data.tables.name;
					
			if(data.tables){
				tables=data.tables;
				strains=data.strains;
				groups=data.groups;
				
				tables = tables.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				strains = strains.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				groups = groups.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				var selectVendor = data.vendors.map((element,index)=>{
					element.label=element.name;
					element.value=element.id;
					element.key=index;
					return element;
				});
				
				var selectSourceId = data.mother_plants.map((element,index)=>{
					element.label=element.barcode;
					element.value=element.barcode;
					element.key=index;
					return element;
				});
				
				let room_id=0;
				if(tables.length){
					room_id = tables[0].room_id;
				}
				let send_data=[];
				for(let t of tables){
					let table_row={};
					table_row.table_name=t.name;
					table_row.remaining_space=t.remaining_space;
					table_row.table_id=t.id;
					table_row.strain_id=null;
					table_row.source={ label: "Seed", value: "0", key: 0 };
					table_row.qty="";
					table_row.birth_date=null;
					table_row.expected_harvest_date="";
					table_row.group_type=null;
					table_row.vendor=null;
					table_row.source_id=null;
					send_data.push(table_row);
				}
				
				this.setState({
			        tables: tables,
			        selectStrain: strains,
					selectGroup:groups,
					selectVendor,
					selectSourceId,
					selectAll:0,
					selectedIds:[],
					room_id,
					send_data
				});
			}else{
				this.setState({
					tables: tables,
					selectStrain: [],
					selectGroup: [],
					selectVendor: [],
					selectSourceId: [],
					selectAll:0,
					selectedIds:[],
					room_id:0,
					send_data:[]
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
	
	handleSubmit(event, errors, values) {		
		if (errors.length === 0) {
			
			document.getElementById("add-plants-to-table").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData(document.getElementById("plantForm"));
			formData.append('facility_id',facility_id);
			formData.append('user_id',user_id);            
			formData.append('room_id',this.state.room_id);            
			
			axios.post(REST_API_END_POINT+'growhouse/add-plants-to-tables',formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					swal("Success",data.msg,"success").then(()=>{
						window.location="/app/growhouse/room/"+this.state.room_id;
					});
				}else{
					swal("Failed",data.msg,"danger")
				}
				document.getElementById("add-plants-to-table").disabled=false;				
				
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("add-plants-to-table").disabled=false;
			});

		}
	}	
  
    render() {
		
		const { tables, selectStrain, selectGroup, selectVendor, send_data } = this.state; 	
	 
    return (
      <Fragment>
        <Row>
          <Colxx xxs="12">
            <Breadcrumb heading="menu.add-plant-to-table" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>
		<AvForm
			id="plantForm"
			className="av-tooltip tooltip-right-bottom"
			onSubmit={this.handleSubmit}
		  >
		<Card>
		   <Row>			  
			  <Colxx xxs="12">				
					<CardBody className="extra-padd-table-responsive" >						
						<Table bordered responsive >
						  <thead>
							<tr>
							  <th style={{ minWidth: "100px" }}>Table</th>
							  <th style={{ minWidth: "130px" }}>Source</th>
							  <th style={{ minWidth: "130px" }}>Strain</th>
							  <th style={{ minWidth: "100px" }}>Birth Date</th>
							  <th style={{ minWidth: "100px" }}>Expected Harvest Date</th>
							  <th style={{ minWidth: "90px" }}>Quantity</th>
							  <th style={{ minWidth: "130px" }}>Group</th>
							  <th style={{ minWidth: "130px" }}>Vendor</th>
							</tr>
						  </thead>
						  <tbody>
							{send_data.map((row,index)=>{
								return(<tr key={index}>
							  <th scope="row">
							  {row.table_name}
							  <Input type="hidden" name={"table_id["+index+"]"} value={row.table_id} />
							  <Input type="hidden" name={"source_id["+index+"]"} value="" />
							  </th>
							  <td>{" "}<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"source["+index+"]"}
										id={"source_"+index}
										value={row.source}
										options={selectSource}
										onChange={selectSourceType => { 
											var send_data = this.state.send_data;
											send_data = send_data.map(r=>{
												if(row.table_id==r.table_id){
													r.source=selectSourceType;
												}
												return r;
											})
											this.setState({ send_data });
										}}
									/></td>
							  <td>{" "}<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"strain_id["+index+"]"}
										id={"strain_id_"+index}
										value={row.strain_id}
										placeholder="--Select Strain--"
										options={selectStrain}
										onChange={selectStrainType => { 
											var send_data = this.state.send_data;
											send_data = send_data.map(r=>{
												if(row.table_id==r.table_id){
													r.strain_id=selectStrainType;
													
													var harvest_date='';													
													if(r.birth_date!=null && r.strain_id!=null){
														if(r.strain_id.harvest_duration!='0' && r.strain_id.harvest_duration!=''){
															harvest_date = moment(r.birth_date).add(parseInt(r.strain_id.harvest_duration),'w').format("MM/DD/YYYY HH:mm");
														}
													}	
													r.expected_harvest_date = harvest_date;
												}
												return r;
											})
											this.setState({ send_data });
										}}										
									/></td>
								<td><DatePicker
										name={"birth_date["+index+"]"}
										className="p-0"
										selected={row.birth_date}
										onChange={birth_date => { 
											var send_data = this.state.send_data;
											send_data = send_data.map(r=>{
												if(row.table_id==r.table_id){
													r.birth_date=birth_date;
													
													var harvest_date='';													
													if(r.birth_date!=null && r.strain_id!=null){
														if(row.strain_id.harvest_duration!='0' && r.strain_id.harvest_duration!=''){
															harvest_date = moment(r.birth_date).add(parseInt(r.strain_id.harvest_duration),'w').format("MM/DD/YYYY HH:mm");
														}
													}	
													r.expected_harvest_date = harvest_date;
												}
												return r;
											})
											this.setState({ send_data });
										}}	
										placeholderText="Date"
										dateFormat="MM/DD/YYYY HH:mm"
										autoComplete = "off"
										maxDate={moment()}
									  />
									  </td>
								<td><AvField
										name={"expected_harvest_date["+index+"]"}
										id={"expected_harvest_date_"+index}
										value={row.expected_harvest_date}
										type="text"
										readOnly={true}
									/></td>									
								<td><AvGroup>									
										<AvField
											name={"qty["+index+"]"}
											id={"qty_"+index}
											type="number"
											value={row.qty}
											onChange={e => { 
												var send_data = this.state.send_data;
												send_data = send_data.map(r=>{
													if(row.table_id==r.table_id){
														r.qty=e.target.value;
													}
													return r;
												})
												this.setState({ send_data });
											}}
											validate={{
											  required: {
												value: true,
												errorMessage: "This field is required."
											  },
											  min:{
												value:1,
												errorMessage:"Please enter a value greater than or equal to 1"
											  },
											  max:{
												value:row.remaining_space,
												errorMessage:"Please enter a value less than or equal to "+row.remaining_space
											  }
											}}
											
									  />
									</AvGroup></td>
								<td>{" "}<Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"group_type["+index+"]"}
										id={"group_type_"+index}
										value={row.group_type}
										placeholder="--Select Group--"
										options={selectGroup}
										onChange={selectGroupType => { 
											var send_data = this.state.send_data;
											send_data = send_data.map(r=>{
												if(row.table_id==r.table_id){
													r.group_type=selectGroupType;
												}
												return r;
											})
											this.setState({ send_data });
										}}
									/></td>
								<td><Select
										components={{ Input: CustomSelectInput }}
										className="react-select"
										classNamePrefix="react-select"						
										name={"vendor["+index+"]"}
										id={"vendor_"+index}
										value={row.vendor}
										placeholder="--Select Vendor--"
										options={selectVendor}
										onChange={selectVendorType => { 
											var send_data = this.state.send_data;
											send_data = send_data.map(r=>{
												if(row.table_id==r.table_id){
													r.vendor=selectVendorType;
												}
												return r;
											})
											this.setState({ send_data });
										}}
									/></td>
								</tr>)
							})}							
						  </tbody>
						</Table>
						<div className="mt-3">
							<Button color="primary" id="add-plants-to-table">
								<IntlMessages id="customer.submit" />
							</Button>{" "}
							<NavLink to={"/app/growhouse/room/"+this.state.room_id} className="btn btn-outline-secondary ml-2">
								Cancel
							</NavLink>
						</div>
					</CardBody>	
			  </Colxx>
			</Row>
		</Card>
		</AvForm>
      </Fragment>
    );
  }
}
