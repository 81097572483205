import React, { Component, Fragment } from "react";
import { Row, Card, CardHeader, CardTitle, Button, CardBody, CardImg, Badge, FormGroup, CustomInput } from "reactstrap";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import IntlMessages from "../../../helpers/IntlMessages";

import { NavLink } from "react-router-dom";

import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/communication/ItemDropdown";
import ReactTable from "react-table";

import { REST_API_END_POINT,themeColor,dataEncryptKey } from "../../../constants/defaultValues";
import axios from 'axios';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Loader } from 'react-loaders';
import swal from 'sweetalert';

import SimpleCrypto from "simple-crypto-js";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class ContactFormEntries extends Component {
	
	constructor(props) {
		super(props);

		this.state = {
			dataTableColumns: [],
			contact_form: [],
			form_entries: []
		};
		
		this.dataListRender = this.dataListRender.bind(this);
	}
		
	componentDidMount() {
		this.dataListRender();
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
		axios.post(REST_API_END_POINT+'website/contact-form-entries',formData).then((res)=>{ 
			let contact_form = res.data.contact_form;
			let dataTableColumns = [];
			for(let row of contact_form){
				dataTableColumns.push({
					Header: row.label,
					accessor: row.field_name,
					Cell: props => <p className="list-item-heading" dangerouslySetInnerHTML={{ __html: props.value }} />
				});
			}
		
			let form_entries = res.data.data;
			let finalEntries = [];
			for(let entry of form_entries){
				let content = JSON.parse(entry.content);
				let finalEntry={};
				for(let c of content){
					let keyValue = dataTableColumns.find(r=>{ return r.Header.trim()==c.label.trim() || r.accessor.trim()==c.field_name.trim() });
					if(keyValue){
						finalEntry[keyValue.accessor.trim()] = c.value;
					}
				}				
				finalEntries.push(finalEntry);
			}
		
			this.setState({ dataTableColumns, contact_form: res.data.contact_form, form_entries:finalEntries, blocking:false });
		}).catch(e =>{ 
			console.log("Addition failed , Error ", e) 
			this.setState({ blocking:false });
		});
	}
	
	render() {
		let data = this.state.form_entries;
		let dataTableColumns = this.state.dataTableColumns;
		
		return (
			<Fragment>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<Loader active type="ball-scale-ripple-multiple" color={themeColor} />}>   
					<Row>
						<Colxx xxs="12">
							<Breadcrumb heading="menu.contact-form-entries" match={this.props.match} />
							<Separator className="mb-5" />
						</Colxx>
					</Row>
					<Row>          		  
						<Colxx lg="12" className="page-tabs">
							<Card className="mb-4">
								<CardBody>
									<Row>
										<Colxx xxs="12">
											<div id="api_message"></div>
										</Colxx> 
										<Colxx xxs="12">											
											<div className="mb-4 dropdown-edit">					
												<ReactTable
													data={data}
													columns={dataTableColumns}
													defaultPageSize={10}
													filterable={false}
													showPageJump={false}
													PaginationComponent={DataTablePagination}
													showPageSizeOptions={true}
													defaultSorted={[{id: "x",asc: true}]}
												/>
											</div>
										</Colxx>
									</Row>
								</CardBody>
							</Card>
						</Colxx>
					</Row>
				</BlockUi>
			</Fragment>
		);
	}
}
