import React, { Component } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import {
	Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import swal from 'sweetalert';
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

export default class PauseRestartProcessingModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.state={
			restart:false
		}
	}

	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("pause-btn").disabled=true;
			document.getElementById("restart-btn").disabled=true;
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			var formData = new FormData(document.getElementById("pauseRestartForm"));
			formData.append('facility_id',facility_id);
			formData.append('equipment_id',this.props.equipment_id.value);
			
			var req_url = 'processor/pause-processing';
			if(this.state.restart){
				req_url = 'processor/restart-processing';
			}
			
			axios.post(REST_API_END_POINT+req_url,formData).then((res)=>{
				var data = res.data;
				
				if(data.status==1){
					document.getElementById("pause-btn").disabled=false;
					document.getElementById("restart-btn").disabled=false;
					this.props.toggleModal();
					if(this.state.restart){
						this.props.restartProcessing();
						swal("Process has been restarted");
					}else{
						this.props.pauseProcessing();
						swal("Process has been paused");
					}
				}else{
					if(this.state.restart){
						swal("Failed to restart the process");
					}else{
						swal("Failed to pause the process");
					}
					document.getElementById("pause-btn").disabled=false;
					document.getElementById("restart-btn").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("pause-btn").disabled=false;
				document.getElementById("restart-btn").disabled=false;
			});
		}
	}  
 

  render() {	
	  return (
	  
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={this.props.toggleModal}
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
	     	Enter Pause/Restart Reason
		  </ModalHeader>
		  <AvForm
				id="pauseRestartForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
			<ModalBody>
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Pause/Restart Reason<span className="text-danger">*</span></Label>
							<AvField
								id="pause_restart_reason"
								name="pause_restart_reason"
								type="textarea"
								rows="4"
								autoComplete="off"
								validate={{
								  required: {
									value: true,
									errorMessage: "This field is required."
								  },
								}}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
			</ModalBody>
			<ModalFooter className="justify-content-start">
				<Button color="primary" id="pause-btn" >
					<IntlMessages id="customer.pause" />
				</Button>
				<Button type="button" color="primary" id="restart-btn" onClick={(e=>{ this.setState({ restart:true }); document.getElementById('pause-btn').click(); } )} >
					<IntlMessages id="customer.restart" />
				</Button>
				<Button color="secondary" outline onClick={this.props.toggleModal}>
					<IntlMessages id="customer.cancel" />
				</Button>{" "}
			</ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};
