const equipment_types = [
    { label: "Extractor", value:"1", key: 0 },
	//{ label:"CRC", value:"6", key: 1},
	{ label:"Oven", value:"4", key: 1},
	//{ label:"MCP", value:"5", key: 2},
	//{ label:"Evaporator", value:"2", key: 3},
	//{ label:"Distillator", value:"3", key: 4},  
];

export default equipment_types
