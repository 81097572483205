import React, { Component } from "react";
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import {
	Row,Card, CardBody,
  CustomInput,
  Button,
  Modal,Input,
  ModalHeader,
  ModalBody,
  ModalFooter,FormGroup,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import IntlMessages from "../../helpers/IntlMessages";
import DatePicker from "../../components/common/CustomDateTimePicker";
import { Colxx, Separator } from "../../components/common/CustomBootstrap";
import moment from 'moment';
import Switch from "rc-switch";
import "rc-switch/assets/index.css";

import Select from "react-select";
import CustomSelectInput from "../../components/common/CustomSelectInput";

import swal from "sweetalert";
import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";

import {REST_API_END_POINT,dataEncryptKey,JQUERY_MODULE, BASE_URL} from "../../constants/defaultValues";

var dataCrypto = new SimpleCrypto(dataEncryptKey);

class EditStaffModal extends Component{
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeUserrole = this.changeUserrole.bind(this);
		this.addMoreRole = this.addMoreRole.bind(this);
		this.handleRemoveRole = this.handleRemoveRole.bind(this);
		this.DeselectAll = this.DeselectAll.bind(this);
	
		this.state = {		
			startDate: null,
			selectStateType:{ label: "Select State", value:"1", key: 0 },
			selectUserType:{ label:"Lead Budtender", value:"5", key: 3},
			selectRole:[],
			selectState:[],
			selectOnboarding:[],
			Permissions:[],
			AllPermissions:[],
			Facilities:[],
			AllFacilities:[],
			role_val:[],
			onboarding_val:[],
			state_val:[],
			staff:null,
			genderType:false,
			selected_permissions:[],
			facility_ids:[],
			receive_trans_notification:false,
			pay_rate:'',
			staff_roles:[{user_role:null,pay_rate:'0'}],
		};
	
	}
  
	componentDidMount() {		
		var user_roles=[];
		var states=[];
		var Permissions=[];
		var AllPermissions=[];
		var selected_permissions=[];
		var workflows=[];
		var worklocations=[];
		var Facilities=[];
	
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        
		axios.post(REST_API_END_POINT+'staff/get-data-for-add-staff',formData).then((res)=>{
			var data = res.data;
		
			user_roles=data.user_roles;
			states=data.states;
			workflows=data.workflows;
			
			var selectUserType=null;
			
			user_roles = user_roles.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
			
			let staff_roles=[];
			if(user_roles.length){
				staff_roles = [{user_role:user_roles[0],pay_rate:user_roles[0].pay_rate}]
				if(user_roles[0].user_permissions!=''){
					selected_permissions=user_roles[0].user_permissions.split(',');
				}
			}
			
			states = states.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});	
			
			workflows = workflows.map((element,index)=>{
				element.label=element.name;
				element.value=element.id;
				element.key=index;
				return element;
			});
	
			this.setState({
				selectRole:user_roles,
				selectState:states,
				selectOnboarding:workflows,
				Permissions:data.staff_permissions,
				AllPermissions:data.staff_permissions,
				Facilities:data.facilities,
				AllFacilities:data.facilities,
				defaultFaclity:[facility_id],
				selected_permissions,
				staff_roles
			});
			
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
   
	}
  
	handleChangeDate = date => {
		this.setState({
		  startDate: date
		});
	};
	  
	componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.modalOpen){
			if (prevProps.staff_id !== this.props.staff_id && this.props.staff_id!=0) {				
				var formData = new FormData();
				formData.append('staff_id',this.props.staff_id);
				axios.post(REST_API_END_POINT+'staff/get-staff-by-id',formData).then((res)=>{
					
					var data = res.data;					
					var staff=data.staff;
					
					var selectUserType = this.state.selectRole.find(row=>{ return row.id==staff.user_type });
					var startDate = moment(staff.date_of_birth);
					var receive_trans_notification = staff.receive_trans_notification==1;
					var pay_rate = staff.pay_rate;
					var staff_roles = staff.staff_roles.map(row=>{
						row.user_role = this.state.selectRole.find(ro=>{ return ro.id==row.user_role });
						return row;
					});

					this.setState({ 
						staff,
						selected_permissions:staff.permissions,
						facility_ids:staff.facility_ids,
						selectUserType,
						startDate,
						Permissions:[],
						genderType:false,
						receive_trans_notification,
						pay_rate,
						staff_roles
					},()=>{
						this.setState({ 
							Permissions:this.state.AllPermissions,
							genderType:true
						})
					});								
					
				}).catch(e =>{
					console.log("Addition failed , Error ", e);
				});              
                
            }
        }
    }  
	  
	handleSubmit(event, errors, values) {
		
		if (errors.length === 0) {
			
			document.getElementById("update-staff").disabled=true;
			
			let dateOfBirth = document.getElementById("dob").value.trim();
			let errorMessage='';

			if(dateOfBirth.length==0)
			{
				errorMessage+="Date of Birth is empty\n";
			}
			
			let noUserRole = false;
			for(let r of this.state.staff_roles){
				if(r.user_role===null){
					noUserRole=true;
				}
			}
			
			if(noUserRole){
				swal("Warning",'Please select the user role',"warning");
				document.getElementById("update-staff").disabled=false;
				return false;
			}

			if(errorMessage.length!=0)
			{
				swal("Warning",errorMessage,"warning");
				document.getElementById("update-staff").disabled=false;
				return false;
			}
			
			var user_id = localStorage.getItem('user_id');
			var facility_id = localStorage.getItem('facility_id');
			facility_id =  dataCrypto.decrypt(facility_id);
			
			var formData = new FormData();
			formData.append('facility_id',facility_id);
			formData.append('email',values.email);
			formData.append('user_id',this.state.staff.user_id);
			
			axios.post(REST_API_END_POINT+'staff/is-email-exist',formData).then((res)=>{
				var data = res.data;
				
				if(data.status!=1){
					var formData = new FormData(document.getElementById("staffForm"));
					formData.append('staff_id',this.props.staff_id);
					formData.append('facility_id',facility_id);
					formData.append('user_id',user_id);
					formData.append('receive_trans_notification',this.state.receive_trans_notification ? 1:0);
					formData.delete('user_role');
					formData.append('user_role',this.state.staff_roles[0].user_role.value);
					formData.append('staff_roles',JSON.stringify(this.state.staff_roles.map(r=>{ r.user_role=r.user_role.value; return r; })));
					
					axios.post(REST_API_END_POINT+'staff/edit-staff',formData).then((res)=>{
						
						var data = res.data;
						
						var staff=this.state.staff;
						staff.name=values.staff;
						this.setState({ staff });
						
						var api_message=document.getElementById("api_message");
						api_message.innerHTML="<div class=\"rounded alert alert-success alert-dismissible fade show\" role=\"alert\"><button type=\"button\" class=\"close\" aria-label=\"Close\"><span aria-hidden=\"true\">×</span></button><span>"+data.msg+"</span></div>";
						
						window.setTimeout(function() {
							JQUERY_MODULE.$(".alert").fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						}, 5000);
						
						JQUERY_MODULE.$('.alert .close').on("click", function(e){
							JQUERY_MODULE.$(this).parent().fadeTo(500, 0).slideUp(500, function(){
								JQUERY_MODULE.$(this).remove();
							});
						});
						
						document.getElementById("update-staff").disabled=false;
						this.props.toggleModal();
						this.DeselectAll();
						this.props.dataListRender();

					}).catch(e =>{
						console.log("Addition failed , Error ", e);
						document.getElementById("update-staff").disabled=false;
					});
				}else{
					swal("Email already exist! Please use another email");
					document.getElementById("update-staff").disabled=false;
				}
			}).catch(e =>{
				console.log("Addition failed , Error ", e);
				document.getElementById("update-staff").disabled=false;
			});

		}
	}	
	
	changeUserrole(selectUserType,index){
		
		var AllPermissions = this.state.AllPermissions;
		var selected_permissions = [];
		
		let staff_roles = this.state.staff_roles;
		let old_role_permissions=[];
		let new_role_permissions=[];
		
		if(staff_roles.findIndex(r=>{ if(r.user_role!=null){ return r.user_role.value==selectUserType.value; }else{ return false; } })!==-1){
			swal("Warning",'The user role is already selected',"warning");
			return;
		}
		
		if(selectUserType.value=='1' && staff_roles.length>1){
			swal({
			  title: "Are you sure?",
			  text: "Selecting this user role will remove all other user roles",
			  icon: "warning",
			  buttons: true,
			  dangerMode: true,
			})
			.then((willDelete) => {
				if (willDelete) {
					staff_roles = staff_roles.filter((r,ind)=>{ return ind===index });
					staff_roles = staff_roles.map((r,ind)=>{
						let new_role = this.state.selectRole.find(row=>{ return row.id==selectUserType.value });
						if(new_role.user_permissions!=''){
							new_role_permissions = new_role.user_permissions.split(',');
						}
						r.user_role=selectUserType;
						r.pay_rate=new_role.pay_rate;
						return r;
					});
					
					var inputElements = document.querySelectorAll('input[name^=assigned_permissions]');
					for(var i=0; inputElements[i]; ++i){
						if(inputElements[i].checked && old_role_permissions.indexOf(inputElements[i].value)==-1){
							selected_permissions.push(inputElements[i].value);
						}
					}
					for(let permission of new_role_permissions){
						if(selected_permissions.indexOf(permission)==-1){
							selected_permissions.push(permission)
						}
					}
					
					this.setState({
						Permissions:[],
						staff_roles
					},()=>{
						this.setState({
							Permissions:AllPermissions,					
							selected_permissions			
						});
					});
				}else{
					swal("Your operation is canceled!");
				}
			});
			return;
		}
		
		staff_roles = staff_roles.map((r,ind)=>{
			if(r.user_role!=null){
				let old_role = this.state.selectRole.find(row=>{ return row.id==r.user_role.value });
				if(old_role.user_permissions!=''){
					old_role_permissions = [...old_role_permissions,...old_role.user_permissions.split(',')];
				}
			}
			
			if(ind===index){
				let new_role = this.state.selectRole.find(row=>{ return row.id==selectUserType.value });
				
				if(new_role.user_permissions!=''){
					new_role_permissions = new_role.user_permissions.split(',');
				}
		
				r.user_role=selectUserType;
				r.pay_rate=new_role.pay_rate;
			}
			return r;
		});
		
		var inputElements = document.querySelectorAll('input[name^=assigned_permissions]');
		for(var i=0; inputElements[i]; ++i){
			if(inputElements[i].checked && old_role_permissions.indexOf(inputElements[i].value)==-1){
				selected_permissions.push(inputElements[i].value);
			}
		}
		for(let permission of new_role_permissions){
			if(selected_permissions.indexOf(permission)==-1){
				selected_permissions.push(permission)
			}
		}
		let isTransport = selected_permissions.filter(r=>{ return r==110 || r==111 || r==112 }).length>0;
		if(!isTransport){
			this.setState({ receive_trans_notification:false })
		}
		
		this.setState({
			Permissions:[],
			staff_roles
		},()=>{
			this.setState({
				Permissions:AllPermissions,					
				selected_permissions			
			});
		});
		
	}
	
	onChange = e => {
		switch (e.target.name) {
			case 'files[]':
				if(e.target.files[0]){
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML=e.target.files[0].name;
				}else{
					e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
				}
				break;
			default: e.target.parentNode.getElementsByClassName('custom-file-label')[0].innerHTML='Choose file';
		}
	};
	
	DeselectAll(){
		var AllPermissions = this.state.AllPermissions;
		var AllFacilities = this.state.AllFacilities;
		var user_roles = this.state.selectRole;
		var selected_permissions = [];
		if(user_roles.length){
			if(user_roles[0].user_permissions!=''){
				selected_permissions=user_roles[0].user_permissions.split(',');
			}
		}
		
		this.setState({
			Permissions:[],
			Facilities:[],
			facility_ids:[]
		},()=>{
			this.setState({
				Permissions:AllPermissions,		
				facility_ids:AllFacilities,		
				selected_permissions,					
				selectStateType:null,					
				selectOnboardingType:null,					
				startDate:null,
				genderType:false
			});
		});
	}
	
	addMoreRole(row){
		let staff_roles = this.state.staff_roles;
		staff_roles.push({
			user_role:null,
			pay_rate:'0'
		});
		this.setState({ staff_roles });
	}
	
	handleRemoveRole(e,index){
		var staff_roles=this.state.staff_roles;
		let removed_role_permissions = [];
		if(staff_roles[index].user_role!=null){
			let removed_role = this.state.selectRole.find(row=>{ return row.id==staff_roles[index].user_role.value }); ;
			if(removed_role.user_permissions!=''){
				removed_role_permissions = removed_role.user_permissions.split(',');
			}
		}
		staff_roles = staff_roles.filter((r,i)=>{ return i!=index });
		
		var AllPermissions = this.state.AllPermissions;
		var selected_permissions = [];
		
		
		var inputElements = document.querySelectorAll('input[name^=assigned_permissions]');
		for(var i=0; inputElements[i]; ++i){
			if(inputElements[i].checked && removed_role_permissions.indexOf(inputElements[i].value)==-1){
				selected_permissions.push(inputElements[i].value);
			}
		}
		
		let old_role_permissions=[];
		staff_roles = staff_roles.map((r,ind)=>{
			if(r.user_role!=null){
				let old_role = this.state.selectRole.find(row=>{ return row.id==r.user_role.value });
				if(old_role.user_permissions!=''){
					old_role_permissions = [...old_role_permissions,...old_role.user_permissions.split(',')];
				}
			}			
			return r;
		});
		for(let r of old_role_permissions){
			selected_permissions.push(r);
		}
		
		selected_permissions = selected_permissions.filter((x, i, a) => a.indexOf(x) === i)
		let isTransport = selected_permissions.filter(r=>{ return r==110 || r==111 || r==112 }).length>0;
		if(!isTransport){
			this.setState({ receive_trans_notification:false })
		}
		this.setState({
			Permissions:[],
			staff_roles
		},()=>{
			this.setState({
				Permissions:AllPermissions,					
				selected_permissions			
			});
		});
	}
	
  render() {

    const { selectRole,selectState,selectOnboarding,Permissions,Facilities  } = this.state;

	if(this.state.staff!=null){
		var staff=this.state.staff;
	}else{
		var staff;
	}
	const isAdminRole = this.state.staff_roles.filter(r=>{ return r.user_role!=null ? r.user_role.value=="1":false }).length>0;
	const isTransportRole = this.state.selected_permissions.filter(r=>{ return r==110 || r==111 || r==112 }).length>0;
  
	return (
		<Modal
		  isOpen={this.props.modalOpen}
		  toggle={(e)=>{ this.DeselectAll(); this.props.toggleModal(e); }}
		  wrapClassName="modal-right"
		  size="md"
		>
		  <ModalHeader toggle={this.props.toggleModal}>
			Edit Staff
		  </ModalHeader>
		  <AvForm
		        id="staffForm"
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
		  <Row>
		  <Colxx lg="8">
				<Row>
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
						  <Label>Full Name<span className="text-danger">*</span></Label>
						  <AvField
							id="name"
							name="name"
							type="text"
							autoComplete="off"
							value={ staff?((staff.name!=null)?staff.name:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  pattern: {
								value: "^[a-zA-Z ,.'-]+$",
								errorMessage: "Invalid name"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  },
							  maxLength: {
								value: 100,
								errorMessage:
								  "The name must be between 2 and 100 characters"
							  }
							}}
						  />
						</AvGroup>
					</Colxx>					
				</Row>
				<Row>
					<Colxx lg="6">
						<AvGroup className="error-t-negative">
							<Label>Gender<span className="text-danger">*</span></Label>
						</AvGroup>
						{this.state.genderType ? <AvRadioGroup
							inline
							name="gender"
							onChange={(e) => {}}
							validate={{
							  required: {
								value: true,
								errorMessage: "Please select any of the options"
							  }
							}}
							defaultValue={ staff?((staff.gender!=null)?staff.gender:'1'):'1' }
							>							
							<AvRadio customInput label="Male" value="1" />
							<AvRadio customInput label="Female" value="2" />
						</AvRadioGroup >:""}
					</Colxx>
					<Colxx lg="6">
						<Label><IntlMessages id="customer.dob" /><span className="text-danger">*</span></Label>
						<DatePicker
							id="dob"
							name="dob"
							className="mb-5"
							maxDate={moment().subtract(18, "years")}
							selected={this.state.startDate}
							onChange={this.handleChangeDate}
							placeholderText=""
							dateFormat="YYYY-MM-DD"
						  />
					</Colxx>
					
				</Row>
				<Row>
					<Colxx lg="12">
						<Label>Address<span className="text-danger">*</span></Label>	&nbsp;&nbsp;
						<AvGroup className="error-t-negative">
						  <AvField
							id="address"
							name="address"
							type="textarea"
							autoComplete="off"
							value={ staff?((staff.address!=null)?staff.address:''):'' }
							validate={{
								  required: {
									value: true, 
									errorMessage: "This field is required."
								  },
								  minLength: {
									value: 10,
									errorMessage:"Your message must have minimum of 10 characters"
								  },
								}}
							placeholder=""
							rows="3"
						  />
						</AvGroup>					
					</Colxx>					
				</Row>
				{ staff?((staff.identity_proof!='')? <Row>
					<Colxx lg="12">
						<AvGroup className="error-l-125 error-t-negative">
							<Label>Identity Proof</Label>
							<div>
								<Button color="primary" onClick={()=>{
									window.open(BASE_URL+'staffs/'+staff.identity_proof)
								}} >{ staff.identity_proof }</Button>
							</div>
						</AvGroup>
					</Colxx>					
				</Row>:''):'' }
				<Row>
					<Colxx lg="6">
						<AvGroup >
						  <Label><IntlMessages id="customer.phone" /><span className="text-danger">*</span></Label>
						  <AvField
							id="phone"
							name="phone"
							type="text"
							autoComplete="off"
                            value={ staff?((staff.phone!=null)?staff.phone:''):'' }							
                            validate={{
							  required: {
								value: true,
								errorMessage: "This field is required."
							  },
							  number: {
								value: true,
								errorMessage: "Please enter a valid number"
							  },
							  minLength: {
								value: 7,
								errorMessage:
								  "Your name must be between 7 and 15 characters"
							  },
							  maxLength: {
								value: 15,
								errorMessage:
								  "Your name must be between 7 and 15 characters"
							  }
							}}							
						  />
						</AvGroup>
					</Colxx>
					<Colxx lg="6">
						<AvGroup >
						  <Label><IntlMessages id="customer.email" /><span className="text-danger">*</span></Label>
						  <AvField
							id="email"
							name="email"
							type="email"
							autoComplete="off"
							value={ staff?((staff.email_id!=null)?staff.email_id:''):'' }
							validate={{
							  required: {
								value: true,
								errorMessage: "This field is required. "
							  },
							  email: {
								value: true,
								errorMessage: "This field is required."
							  }
							}}
						  />
						</AvGroup>
					</Colxx>
				</Row>
				
				<Row className="d-none">
					<Colxx lg="12">
						<AvGroup className="error-t-negative">
							<Label>Select Onboarding Workflows<span className="text-danger">*</span></Label>
							<Select
								components={{ Input: CustomSelectInput }}
								className="react-select"
								classNamePrefix="react-select"						
								name="workflow_ids[]"
								isMulti
								id="workflow_ids"
								value={this.state.selectOnboardingType}						
								options={selectOnboarding}
								onChange={selectOnboardingType => { this.setState({ selectOnboardingType }) }}
							/>
						</AvGroup>
					</Colxx>					
				</Row>
				
				{this.state.facility_ids.length > 0 ?<Row>
						<Colxx lg="12">
							<AvGroup className="error-t-negative">
								<Label>Work Locations<span className="text-danger">*</span></Label>
							</AvGroup>
							<Row>
								<Colxx lg="6">
									<AvCheckboxGroup
										inline
										name="facility_ids[]"
										className="facilities"
										onChange={(e) => {}}
										validate={{
										  required: {
											value: true,
											errorMessage: "Please select any of the options"
										  }
										}}
										defaultValue={this.state.facility_ids}
									>
										{Facilities.map((row,index)=>{
											return <AvCheckbox customInput label={row.facility_address} value={row.id} key={index} />
										})}					
									</AvCheckboxGroup >
								</Colxx>
							</Row>
						</Colxx>
					</Row>:""}
					<Row className="mb-2">
						<Colxx md="12">
							<h4>Staff Roles</h4>
							{this.state.staff_roles.map((row,index)=>{
								return <Card className="mb-2"><CardBody className="p-2">
									{this.state.staff_roles.length!=1 ? <div className="remove-section float-right" onClick={e=>this.handleRemoveRole(e,index)} ><i className="simple-icon-close"></i></div>:""}
											
									<Row>
										<Colxx md="6">
											<AvGroup className="error-t-negative">
												<Label>User Role<span className="text-danger">*</span></Label>
												<Select
													components={{ Input: CustomSelectInput }}
													className="react-select"
													classNamePrefix="react-select"						
													name="user_role"
													id="user_role"
													value={row.user_role}						
													options={selectRole}
													onChange={(role)=>this.changeUserrole(role,index)}
													required
												/>
											</AvGroup>
										</Colxx>
									
										<Colxx md="6">
											<AvGroup className="error-t-negative">
												<Label>Pay Rate</Label>
												<AvField
													id="pay_rate"
													name="pay_rate"
													type="text"
													autoComplete="off"
													value={row.pay_rate}
													onChange={(e)=>{
														let staff_roles = this.state.staff_roles;
														staff_roles = staff_roles.map((r,ind)=>{
															if(ind===index){
																r.pay_rate = e.target.value;
															}
															return r;
														});
														this.setState({ staff_roles });
													}}
													validate={{
														number: {
															value: true,
															errorMessage: "Please enter a valid number"
														}
													}}
												/>
											</AvGroup>
										</Colxx>					
									</Row>
								</CardBody></Card>
							})}
							{!isAdminRole ? <Button color="primary" type="button" onClick={this.addMoreRole}>Add More Role</Button>:""}
						</Colxx>					
					</Row>
					
					<AvGroup className="error-l-125 error-t-negative d-none">
						<Label>													  
							<Switch
								className="custom-switch custom-switch-primary im-inline-block"
								checked={this.state.receive_trans_notification}
								disabled={!isTransportRole}
								onChange={status => { this.setState({ receive_trans_notification:!this.state.receive_trans_notification }) }}
							/>
							<span className="h6 ml-4">Receive transportation related notifications</span>
						</Label>
					</AvGroup>
				</Colxx>	
				{Permissions.length ? <Colxx lg="4"  >
					<Row> 
						<Colxx lg="12">
							<AvGroup className="error-l-100 tooltip-label-right">
								<Label className="h4 font-weight-bold">Staff Permissions</Label>
								<div className="staff-scroll">								
									<AvCheckboxGroup
										name="assigned_permissions[]"
										className="staff_permissions"
										onChange={e => { 
											var selected_permissions = [];
											var inputElements = document.querySelectorAll('input[name^=assigned_permissions]');
											for(var i=0; inputElements[i]; ++i){
												if(inputElements[i].checked){
													selected_permissions.push(inputElements[i].value);
												}
											}
											this.setState({ selected_permissions });
											let isTransport = selected_permissions.filter(r=>{ return r==110 || r==111 || r==112 }).length>0;
											if(!isTransport){
												this.setState({ receive_trans_notification:false })
											}
										}}
										validate={{
										  required: {
											value: true,
											errorMessage: "Please select any of the options"
										  }
										}}
										defaultValue={this.state.selected_permissions}
										>
										<Row>
										{Permissions.map((row,index)=>{
											return <Colxx lg="12" key={index}><AvCheckbox customInput label={row.upriv_name} value={row.upriv_id} title={row.upriv_desc} /></Colxx>
										})}
										</Row>
									</AvCheckboxGroup >
								
								</div>
							</AvGroup>
						</Colxx>
					</Row>
				</Colxx>:""}
			</Row>	
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" id="update-staff">
			  <IntlMessages id="customer.update" />
			</Button>
			<Button color="secondary" outline onClick={(e)=>{ this.DeselectAll(); this.props.toggleModal(e); }}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>
	  </Modal>
	  );
  }
};

export default EditStaffModal;
