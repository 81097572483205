import React from "react";

class RemoveSection extends React.Component {
	constructor(props) {
		super(props);
		
		// This binding is necessary to make `this` work in the callback
		this.handleClick = this.handleClick.bind(this);
	  }

	  handleClick(e) {
		var element=e.currentTarget.parentNode;
		element.parentNode.removeChild(element);
	  }
	
  render() {
	return (
	  <div className="remove-section float-right" onClick={e=>this.handleClick(e)} ><i className="simple-icon-close"></i></div>
	)};
}
export default RemoveSection;


