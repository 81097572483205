import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, FormGroup,Label,Input,
    DropdownItem,
    DropdownMenu,Card,
  CardBody,CardTitle,
  CardSubtitle,
  CardText,
  CardImg } from "reactstrap";
//import IntlMessages from "../../helpers/IntlMessages";
import { Colxx } from "../../components/common/CustomBootstrap";
import { NavLink } from "react-router-dom";

import DataTablePagination from "../../components/DatatablePagination";
import ItemDropdown from "../../components/communication/ItemDropdown";
import ReactTable from "react-table";
import products from "../../data/products";

class Portfolio extends Component {
	constructor(props) {
		super(props);
		 this.handleSubmit = this.handleSubmit.bind(this);
		  this.products = products.slice(0,15);
		this.state = {
			
		};
	}
	handleSubmit(event, errors, values) {
    console.log(errors);
    console.log(values);
    if (errors.length === 0) {
      this.props.toggleModal();
    }
  }
  
  render() {
	 
    return (
      <Fragment>
	  <AvForm
		className="av-tooltip tooltip-right-bottom"
		onSubmit={this.handleSubmit}
	  >
        <Row>         
          {
			this.products.map((product) => {
			  return (
				<Colxx xxs="12" lg="6" xl="4" className="mb-4" key={product.id}>
				  <Card>
					<div className="position-relative">
					  <NavLink to="#" className="w-40 w-sm-100">
						<CardImg top alt={product.title} src={product.img} title="text" />
					  </NavLink>
					</div>
					<CardBody>
					  <NavLink to="#" className="w-40 w-sm-100">
						<CardSubtitle title="text">{product.title}</CardSubtitle>
					  </NavLink>
					 
					</CardBody>
				  </Card>
				</Colxx>
			  )
			})
		  }
        </Row>				
		</AvForm>			
      </Fragment>
    );
  }
}

export default Portfolio;
