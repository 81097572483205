import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import dashboard from "./dashboard";
import visitors from "./visitors";
import settings from "./settings";

const Visitor = ({ match }) => (
    <div className="dashboard-wrapper">
        <Switch>
		
			<Redirect exact from={`${match.url}/`} to={`${match.url}/visitors`} />
			<Route path={`${match.url}/dashboard`} component={dashboard} />
			<Route path={`${match.url}/visitors`} component={visitors} />
			<Route path={`${match.url}/settings`} component={settings} />
            <Redirect to="/error" />

        </Switch>
    </div>
);
export default Visitor;