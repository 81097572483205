import PropTypes from "prop-types";
import React from "react";
import DealgridList from "./DealgridList";

const DealProducts = ({ products, layout }) => {
  return (
    <div className="shop-bottom-area mt-3">
      <div className={`row ${layout ? layout : ""}`}>
		  {products.length ? <DealgridList products={products} spaceBottomClass="mb-3" />:<div className="no-products-text">No Deals Found</div>}
      </div>
    </div>
  );
};

DealProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array
};

export default DealProducts;
