import React, { Component, Fragment } from "react";
import { Row, Card, CardTitle, Form, Label, Input, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
//import Paginator from 'react-hooks-paginator';
import { ToastProvider } from "react-toast-notifications";

import { loginUser } from "../redux/actions";
import { Colxx } from "../components/common/CustomBootstrap";
import { projectTitle } from "../constants/defaultValues";
import HomeHeader from "../components/common/HomeHeader";
import HomeFooter from "../components/common/HomeFooter";
import DealProducts from "../components/front-end/DealProducts";

import {REST_API_END_POINT,dataEncryptKey } from "../constants/defaultValues";

import axios from 'axios';
import SimpleCrypto from "simple-crypto-js";
import moment from "moment";
import { seo } from "../helpers/Utils";

const dataCrypto = new SimpleCrypto(dataEncryptKey);

class Shop extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		  sortType:'asc', 
		  sortValue:'default',
		  category_id:'',
		  search:'',
		  productCount:0,
		  currentData:[],
		  mix_match_deals:[],
		  uniqueCategories:[]
		};
		this.dataListRender = this.dataListRender.bind(this);
		this.getSortParams = this.getSortParams.bind(this);
		this.sortProducts = this.sortProducts.bind(this);
		this.setCategoryParams = this.setCategoryParams.bind(this);
		this.shopSearchSubmit = this.shopSearchSubmit.bind(this);
		seo({
			title: 'Deals | '+projectTitle,
			metaDescription: 'Shop with us'
		});
	}
  
	componentDidMount() {
		
		document.body.classList.add("background");
		document.body.classList.add("background-main-pages");
		var user_id = localStorage.getItem('user_id');
		if(typeof user_id=='undefined' || user_id==null){
			localStorage.setItem('redirect_url','/shop');
		}
		var facility_id = localStorage.getItem('facility_id');
		if(facility_id){
			this.dataListRender();
		}else{
			window.location='/';
		}
	}
	componentWillUnmount() {
		document.body.classList.remove("background");
		document.body.classList.remove("background-main-pages");
	}
	
	dataListRender() {
		var facility_id = localStorage.getItem('facility_id');
		facility_id =  dataCrypto.decrypt(facility_id);
        var formData = new FormData();
        formData.append('facility_id',facility_id);
        formData.append('user_time',moment().format('HH:mm:00'));
		
		axios.post(REST_API_END_POINT+'front-end/deals',formData).then((res)=>{
			var data = res.data;
			
			if(data.status==1){	
				this.setState({
			        currentData: data.deals,
			        mix_match_deals: data.mixdeals,
			    },()=>{
					this.sortProducts();
				});
			}else{
				this.setState({
					currentData: [],
					mix_match_deals: [],
				});
			}
        }).catch(e =>{
			console.log("Addition failed , Error ", e);
		});
	}
  
	getSortParams = (sortType, sortValue) => {		
		this.setState({ sortType, sortValue },()=>{
			this.sortProducts();
		})
	}
	
	sortProducts = () => {
		const sortValue = this.state.sortValue;
		var currentData = this.state.currentData;
		if(sortValue=='default'){
			currentData = currentData.sort((a, b) => parseInt(a.timestamp) < parseInt(b.timestamp) ? 1 : (parseInt(a.timestamp) > parseInt(b.timestamp) ? -1 : 0))
		}else if(sortValue=='priceLowToHigh'){
			currentData = currentData.sort((a, b) => parseFloat(a.price) > parseFloat(b.price) ? 1 : (parseFloat(a.price) < parseFloat(b.price) ? -1 : 0))
		}else if(sortValue=='priceHighToLow'){
			currentData = currentData.sort((a, b) => parseFloat(a.price) < parseFloat(b.price) ? 1 : (parseFloat(a.price) > parseFloat(b.price) ? -1 : 0))
		}
		currentData = JSON.parse(JSON.stringify(currentData));
		this.setState({ currentData })
	}
	
	setCategoryParams = (category_id) => {
		this.setState({ category_id },()=>{
			this.dataListRender();
		})
	}
	
	shopSearchSubmit(event, errors, values) {
		if(errors.length==0){
			this.setState({ search:values.shop_search },()=>{
				this.dataListRender();
			})
		}
	}

	render() {
		
		return (
			<Fragment>
				<ToastProvider placement="bottom-left">
					<div className="main-pages-layout">
						<main>
							<HomeHeader />
								<div className="shop-area pt-5 pb-5 main-shop-listing">
									<div className="container">
										<div className="row">
											<div className="col-lg-12 order-1 order-lg-2">
												<h2 className="text-center mb-5 font-weight-bold h2">Daily Deals</h2>
												{/* shop page content default */}
												<DealProducts layout={'grid three-column mb-2'} products={this.state.currentData} />
												
												<h2 className="text-center mt-5 mb-5 font-weight-bold h2">Mix and Match Deals</h2>
												{/* shop page content default */}
												<DealProducts layout={'grid three-column mb-2'} products={this.state.mix_match_deals} />
												
											</div>
										</div>
									</div>
								</div>
							<HomeFooter />
						</main>	
					</div>
				</ToastProvider>
			</Fragment>	
		);
	}
}

const mapStateToProps = ({ authUser }) => {
  const { user, loading } = authUser;
  return { user, loading };
};

export default connect(
  mapStateToProps,
  {
    loginUser
  }
)(Shop);
