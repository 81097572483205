import React, { Component, Fragment } from "react";
import { AvForm, AvField, AvGroup } from "availity-reactstrap-validation";
import { Row, Card,CardHeader, CardTitle, Button, CardBody, Nav, NavItem, FormGroup, TabContent, TabPane,Input,
    DropdownItem,
	CustomInput, Badge,ModalBody, Dropdown,
  ModalFooter,
    DropdownMenu } from "reactstrap";
import IntlMessages from "../../../helpers/IntlMessages";
import { Colxx, Separator } from "../../../components/common/CustomBootstrap";
import Breadcrumb from "../../../containers/navs/Breadcrumb";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import DataTablePagination from "../../../components/DatatablePagination";
import ItemDropdown from "../../../components/customer/ItemDropdown";
import IconCards from "../../../containers/ui/IconCards";
import ImageCards from "../../../containers/ui/ImageCards";
import ImageOverlayCard from "../../../containers/ui/ImageOverlayCard";
import ImageCardList from "../../../containers/workflow/ImageCardList";
import TabCardExamples from "../../../containers/ui/TabCardExamples";
import UserCardExamples from "../../../containers/ui/UserCardExamples";
import SwitchExamples from "../../../containers/forms/SwitchExamples";
import Switch from "rc-switch";
import  DataModal from "../../../containers/onboarding/DataModal";
import  BankModal from "../../../containers/onboarding/BankModal";
import  TaxiModal from "../../../containers/onboarding/TaxiModal";
import  PortModal from "../../../containers/onboarding/PortModal";

import  QuestModal from "../../../containers/onboarding/QuestModal";

import  SignModal from "../../../containers/onboarding/SignModal";

import  ActionModal from "../../../containers/onboarding/ActionModal";

import {  ButtonDropdown, DropdownToggle
   
      } from "reactstrap";

import ReactTable from "react-table";
import {

 
  Label
} from "reactstrap";


import data from "../../../data/createworkflow";

export default class Createworkflow extends Component {
	
	constructor(props) {
		super(props);
		this.toggleFirstTab = this.toggleFirstTab.bind(this);
		
		this.state = {
		  activeFirstTab: "1",		 
		 modalOpenAdd: false,
		   modalOpenData: false,
		    modalOpenBank: false,
			modalOpenPort: false,
			modalOpenTax:false,
			modalOpenQuest:false,
			modalOpenSign:false,
			modalOpenAction:false,
		 modalOpenAddResult: false,
		  modalOpenStaff: false,
		  data:data,
		  search: '',
		   isOpenSizing: false,
		};
	}
	toggleBasic = () => {
    this.setState(prevState => ({
      dropdownBasicOpen: !prevState.dropdownBasicOpen
    }));
  };
  
  toggleBasicd = () => {
    this.setState(prevState => ({
      dropdownBasicOpend: !prevState.dropdownBasicOpend
    }));
  };
  
    toggleBasict = () => {
    this.setState(prevState => ({
      dropdownBasicOpent: !prevState.dropdownBasicOpent
    }));
  };
  
  
  toggleModalData = () => {
		this.setState({
		  modalOpenData: !this.state.modalOpenData
		});
	};
  
  
  
  toggleModalAdd = () => {
		this.setState({
		  modalOpenAdd: !this.state.modalOpenAdd
		});
	};
	 toggleModalBank = () => {
		this.setState({
		  modalOpenBank: !this.state.modalOpenBank
		});
	};
	
	 toggleModalTax = () => {
		this.setState({
		  modalOpenTax: !this.state.modalOpenTax
		});
	};
	
	toggleModalPort = () => {
		this.setState({
		  modalOpenPort: !this.state.modalOpenPort
		});
	};
	
	toggleModalQuest = () => {
		this.setState({
		  modalOpenQuest: !this.state.modalOpenQuest
		});
	};
	
		toggleModalSign = () => {
		this.setState({
		  modalOpenSign: !this.state.modalOpenSign
		});
	};
	
	toggleModalAction = () => {
		this.setState({
		  modalOpenAction: !this.state.modalOpenAction
		});
	};
	
	toggleModalEdit = () => {
		this.setState({
		  modalOpenEdit: !this.state.modalOpenEdit
		});
	};
	
  toggleFirstTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeFirstTab: tab
      });
    }
  }
  toggleSizing = () => {
    this.setState(prevState => ({
      isOpenSizing: !prevState.isOpenSizing
    }));
  };   
  
  render() {
	  let data = this.state.data
		if (this.state.search) {
			data = data.filter(row => {
				return row.send_on.includes(this.state.search) || row.recipient.includes(this.state.search) || row.cc.includes(this.state.search) || row.subject.includes(this.state.search)
			})
		}
		
		
		
							
		
		
	const dataTableColumns = [
		
	  
	  
	];
    return (
      <Fragment>
        <Row>
          <Colxx lg="12">
            <Breadcrumb heading="menu.createworkflow" match={this.props.match} />
            <Separator className="mb-5" />
          </Colxx>
        </Row>	
		
		
		<Card className="p-4">
		   <Row>
			  
			  <Colxx xxs="12">
					
					
					
					
			  <AvForm
				className="av-tooltip tooltip-right-bottom"
				onSubmit={this.handleSubmit}
			  >
		  <ModalBody>
			
					<Row className="mt-2">
					<Colxx lg="12" className="mb-2">	
					<AvGroup>
					  <Label>Name</Label>
					  <AvField 
						name="suggestion" 
						type="text" 
						rows="2"
						validate={{
							  required: {
								value: true,
								errorMessage: "Please enter agreement"
							  },
							  minLength: {
								value: 2,
								errorMessage:
								  "The message must have atleast 2 characters"
							  }
							}}
						/>
					</AvGroup>
					</Colxx>
				</Row>				
			
				
				
		
   <Fragment>
        <Row>
          <Colxx xxs="12">
           
          </Colxx>
        </Row>

        <ImageCardList />

      </Fragment>

  <Row>
	 
<Dropdown
                  isOpen={this.state.dropdownBasicOpen}
                  toggle={this.toggleBasic}
                  className="mb-5"
                >
                  <DropdownToggle caret color="" outline>
                    <IntlMessages id="Vendor-facing stage" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem  onClick={this.toggleModalData} >
                      <IntlMessages id="Data Collection" />
					  
					  
					  
					  
                    </DropdownItem>
					
					<DataModal
								modalOpen={this.state.modalOpenData}
								toggleModal={this.toggleModalData}
							  />
					
					
					
					
                    <DropdownItem   onClick={this.toggleModalBank}>
                      <IntlMessages id="Bank Information" />
                    </DropdownItem>
					
					<BankModal
								modalOpen={this.state.modalOpenBank}
								toggleModal={this.toggleModalBank}
							  />
					
					
					
					
					
                    <DropdownItem onClick={this.toggleModalTax} >
                      <IntlMessages id="Tax Information" />
                    </DropdownItem>
					
					
					<TaxiModal
								modalOpen={this.state.modalOpenTax}
								toggleModal={this.toggleModalTax}
							  />
					
					
					 <DropdownItem  onClick={this.toggleModalPort} >
                      <IntlMessages id="Portfolio" />
                    </DropdownItem>
					
					
					
					<PortModal
								modalOpen={this.state.modalOpenPort}
								toggleModal={this.toggleModalPort}
							  />
					
					
					
					
					 <DropdownItem  onClick={this.toggleModalQuest} >
                      <IntlMessages id="Questionnaire"  />
                    </DropdownItem>
					
					
						<QuestModal
								modalOpen={this.state.modalOpenQuest}
								toggleModal={this.toggleModalQuest}
							  />
					
					
					
					 <DropdownItem onClick={this.toggleModalSign} >
                      <IntlMessages id="Sign Agreement" />
                    </DropdownItem>
					
					
					
					
					<SignModal
								modalOpen={this.state.modalOpenSign}
								toggleModal={this.toggleModalSign}
							  />
					
					
                  </DropdownMenu>
                </Dropdown>

    <Dropdown
                  isOpen={this.state.dropdownBasicOpend}
                  toggle={this.toggleBasicd}
                  className="mb-5 ml-4"
                >
                  <DropdownToggle caret color="" outline  >
                    <IntlMessages id="Internal stage" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem   onClick={this.toggleModalData} >
                      <IntlMessages id="Data Collection" />
                    </DropdownItem>
                    <DropdownItem  onClick={this.toggleModalQuest} >
                      <IntlMessages id="Questionnaire" />
                    </DropdownItem>
                 
                  </DropdownMenu>
                </Dropdown>
  
			<Dropdown
                  isOpen={this.state.dropdownBasicOpent}
                  toggle={this.toggleBasict}
                  className="mb-5 ml-4"
				  
                >
                  <DropdownToggle caret color="" outline onClick={this.toggleModalAction} >
                    <IntlMessages id="Action stage" />
                  </DropdownToggle>
                 
                </Dropdown>
				
				<ActionModal
								modalOpen={this.state.modalOpenAction}
								toggleModal={this.toggleModalAction}
							  />
				
		 </Row>
		 <Row className="mb-4">
          <Colxx xxs="6">
              <label>
                <IntlMessages id="Default onboarding workflow" />
              </label>
              <Switch
                className="custom-switch custom-switch-primary"
                checked={this.state.switchCheckedPrimary}
                onChange={switchCheckedPrimary => {
                  this.setState({ switchCheckedPrimary });
                }}
              />
            </Colxx>
        </Row>		
					
				 
				
		  </ModalBody>
		  <ModalFooter>
			<Button color="primary" >
			  <IntlMessages id="customer.save" />
			</Button>
			<Button color="secondary" outline onClick={this.props.toggleModal}>
			  <IntlMessages id="customer.cancel" />
			</Button>{" "}
		  </ModalFooter>
		   </AvForm>		
					
					
						
			 	


				

					
					
					
			  </Colxx>
			</Row>
		</Card>
      </Fragment>
    );
  }
}
