const staffmanagement = [
{
  name:"New One",
  make:"2018",
  model:"New Model",
  licplate:"46578",
  assignedstatus:"assigned",
},{
  name:"New One",
  make:"2017",
  model:"New Model",
  licplate:"9911",
  assignedstatus:"not assigned",
},
]

export default staffmanagement

 